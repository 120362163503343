<ng-container *transloco="let t">
  @if (sdForm) {
    <div>
      <form class="main-container mx-auto flex" [formGroup]="sdForm" (ngSubmit)="submitForm()">
        <div class="form-inner">
          @if (!tacosStrategyName) {
            <div class="card-common mb-3 p-3">
              <h3 class="mb-4">{{ t("sd-strategy-creation.sponsored_display_strategy") }}</h3>
              <div class="content">
                <div class="input-section">
                  <label for="strategyName">{{ t("sd-strategy-creation.strategy_name") }}</label>
                  <input
                    type="text"
                    maxlength="80"
                    class="form-control simple-input"
                    placeholder="Please enter a name for your strategy"
                    id="strategyName"
                    [formControl]="sdForm.controls.strategyName"
                    [class.border-danger]="formSubmitted && sdForm.controls.strategyName.errors"
                    data-testid="strategy-name"
                  />
                  <div class="hint">{{ sdForm.controls.strategyName.value?.length }}/80</div>
                </div>
              </div>
            </div>
          }
          @if (!_tacosStrategy) {
            <div class="card-common mb-3 p-3">
              <h3 class="mb-4">{{ t("sd-strategy-creation.algorithm") }}</h3>
              <div class="content">
                <div class="mb-3 flex">
                  <app-algo-mode-selection
                    class="w-full"
                    (algoModeConfig)="setAlgoModeConfig($event)"
                    (valid)="setAlgoModeConfigValid($event)"
                  ></app-algo-mode-selection>
                </div>
              </div>
            </div>
          }
          <div class="card-common mb-3 p-3">
            <h3 class="mb-4">{{ t("sd-strategy-creation.targetings") }}</h3>
            <div class="content">
              <div class="mb-3 flex">
                <label class="col-sm-3 col-form-label">{{ t("sd-strategy-creation.targeting_type") }}</label>
                <div class="col-sm-9">
                  <ISelect
                    class="w-full min-w-0"
                    [options]="targetingTypeOptions"
                    [selected]="defaultTargetingTypeOption"
                    (selectedChange)="selectTargetingType($event)"
                    data-testid="targeting-type"
                  />
                </div>
              </div>
              @if (sdForm.controls.targetingType.value === SdTargetingType.PRODUCT) {
                <!-- TODO: add the possibility to create / update segments -->
                <div class="mb-3 flex">
                  <label class="col-sm-3 col-form-label">{{ t("common.segment") }}</label>
                  <div class="col-sm-9">
                    <ISelect
                      class="w-full min-w-0"
                      [options]="segmentOptions"
                      [selected]="defaultSegmentOption"
                      (selectedChange)="selectSegment($event)"
                    />
                  </div>
                </div>
              }
              @if (sdForm.controls.targetingType.value === SdTargetingType.REMARKETING) {
                <div class="mb-3 flex">
                  <label class="col-sm-3 col-form-label">{{ t("sd-strategy-creation.event_type") }}</label>
                  <div class="col-sm-9">
                    <ISelect
                      class="w-full min-w-0"
                      [options]="eventTypeOptions"
                      [selected]="defaultEventTypeOption"
                      (selectedChange)="selectEventType($event)"
                    />
                  </div>
                </div>
                <div class="mb-3 flex">
                  <label class="col-sm-3 col-form-label">{{ t("sd-strategy-creation.match_type") }}</label>
                  <div class="col-sm-9">
                    <ISelect
                      class="w-full min-w-0"
                      [options]="matchTypeOptions"
                      [selected]="defaultMatchTypeOption"
                      (selectedChange)="selectMatchType($event)"
                    />
                  </div>
                </div>
                <div class="mb-3 flex">
                  <label class="col-sm-3 col-form-label">{{ t("sd-strategy-creation.lookback") }}</label>
                  <div class="col-sm-9">
                    <ISelect
                      class="w-full min-w-0"
                      [options]="lookbackOptions"
                      [selected]="defaultLookbackOption"
                      (selectedChange)="selectLookback($event)"
                    />
                  </div>
                </div>
                @if (
                  sdForm.controls.audienceExpressionType.invalid &&
                  sdForm.controls.audienceExpressionType.errors &&
                  sdForm.controls.audienceExpressionType.errors["invalidAudience"]
                ) {
                  <div class="alert alert-danger">
                    {{
                      sdForm.controls.audienceExpressionType.errors
                        ? sdForm.controls.audienceExpressionType.errors["invalidAudienceMessage"]
                        : ""
                    }}
                  </div>
                }
              }
            </div>
          </div>
          <div class="card-common mb-3 p-3">
            <h3 class="mb-4">{{ t("sd-strategy-creation.advertised_products") }}</h3>
            <div>
              {{ t("sd-strategy-creation.asin_selected", { count: sdForm.controls.asins.value?.length }) }}
            </div>
            @if (!_tacosStrategy && unavailableAsins() && unavailableAsins()!.size) {
              <IAlert class="my-4" typ="info" [title]="t('common.asin_used_in_tacos_strategies')" [animated]="false">
                <IButton
                  [label]="t('common.display_unavailable_asin')"
                  color="white"
                  size="xs"
                  (onClick)="openAsinUsageModal()"
                />
              </IAlert>
            }

            <div class="h-[45rem]">
              <app-asins-selection
                [selectionModes]="selectionModes"
                [customAsinList]="tacosAsins ?? undefined"
                [asins]="sdForm.controls.asins.value!"
                [accountId]="accountId!"
                [marketplace]="marketplace!"
                [asinEligibility]="asinEligibility"
                [disabledAsins]="unavailableAsins()!"
                [csvExport]="false"
                (onAdd)="addStrategyAsins($event)"
                (onDelete)="deleteStrategyAsins($event)"
              >
              </app-asins-selection>
            </div>
          </div>
        </div>

        <div class="form-panel card-common ml-3">
          <div>
            <h4>{{ t("sd-strategy-creation.new_sponsored_display") }}</h4>
            <p>{{ t("sp-strategy-creation.account_accountgroupname", [accountGroupName]) }}</p>
            <p>{{ t("sd-strategy-creation.marketplace", [accountGroupName]) }}</p>
          </div>
          @if (formSubmitted && sdForm.invalid) {
            <div class="alert alert-danger">
              <span class="py-2">{{ t("sd-strategy-creation.some_of_the_fields_are_invalid") }}</span
              >&nbsp;
              @for (c of invalidFields; track c; let last = $last) {
                <span>{{ c + (last ? "" : ", ") }}</span>
              }
              @if (sdForm.controls.asins.invalid) {
                <div>{{ t("sd-strategy-creation.please_select_at_least_one_asin") }}</div>
              }
            </div>
          }
          <div>
            <IButton
              [label]="t('sd-strategy-creation.create_strategy')"
              type="submit"
              [block]="true"
              class="mb-2"
              testid="btn-create-strategy"
            />
            @if (!_tacosStrategy) {
              <IButton
                [icon]="ICON_ARROW"
                [label]="t('sd-strategy-creation.back_to_sd_strategies')"
                [to]="sdStrategyPageUrl"
                variant="ghost"
                color="gray"
                [block]="true"
              />
            } @else {
              <IButton
                (onClick)="onCancel.emit()"
                [icon]="ICON_ARROW"
                [label]="t('tacos-strategy.back_to_tacos_strategy')"
                variant="ghost"
                color="gray"
                [block]="true"
              />
            }
          </div>
        </div>
      </form>
    </div>
  }
</ng-container>
