<ng-container *transloco="let t">
  <app-spinner [display]="loading" [main]="true"></app-spinner>

  @if (strategyGroup) {
    <div class="mb-3 flex items-center">
      <IButton
        color="gray"
        variant="ghost"
        size="xl"
        [routerLink]="strategyPageUrl"
        tooltipValue="{{ t('sp-strategy-group-page.back_to_sponsored_products_strategy_list') }}"
        label="{{ t('v2-sidebar.sponsored_products') }}"
      />
      <span [classList]="ICON_CHEVRON_DOWN + ' text-xl -rotate-90 mx-2'"></span>
      <app-editable-title
        [value]="strategyGroup.strategyGroupName ?? ''"
        tooltipText="{{ t('sp-strategy-group-page.change_strategy_group_name') }}"
        [disable]="isReadOnly"
        tooltipPosition="below"
        placeholder="{{ t('sp-strategy-group-page.name_strategy_input') }}"
        (valueChange)="changeName($event)"
        ><span>{{ strategyGroup.strategyGroupName }}</span>
      </app-editable-title>
      @if (infoMessageLastUpdate) {
        <div class="pill pill-info ml-4">
          <span [class]="ICON_SYNC + ' mr-2'"></span>
          {{ infoMessageLastUpdate }}
        </div>
      }
    </div>

    <div class="mx-3 mt-5 pb-3">
      <!-- Advertised Products -->
      <div
        class="flex cursor-pointer select-none items-center justify-between border-b py-4"
        (click)="toggleSectionVisibility('products')"
      >
        <div class="flex items-center text-xl">
          <span class="font-medium">{{ t("sd-strategy-creation.advertised_products") }}</span>
          @if (strategyGroup.asins) {
            <span class="ml-2 text-gray-500"> ({{ strategyGroup.asins.length }})</span>
          }
          <span
            class="icon-[material-symbols--chevron-right-rounded] ml-3 text-3xl text-gray-600 duration-75"
            [class.rotate-90]="sectionVisibility.products"
          ></span>
        </div>
      </div>
      <div [hidden]="!sectionVisibility.products" class="max-h-[35rem]">
        <app-strategy-asins
          [asins]="strategyGroupAsins ?? []"
          [accountId]="accountMarketplace?.accountId"
          [marketplace]="accountMarketplace?.marketplace"
          [selectable]="true"
          [addProductsBtn]="true"
          [deletable]="true"
          [movable]="false"
          (onDelete)="deleteProducts()"
          (selectedProducts)="updateProductSelection($event)"
          (addProducts)="openAddProductModal()"
          [strategiesPerAsin]="strategiesPerAsin"
          [csvExport]="true"
          [withDisplayMode]="true"
          csvExportFileName="{{ strategyGroup.strategyGroupName }}"
          [asinEligibility]="asinEligibility"
          [isReadOnly]="isReadOnly"
        />
      </div>
      <!-- Blacklist -->
      @if ((strategyGroup.blacklist?.length ?? 0) > 0) {
        <div
          class="flex cursor-pointer select-none items-center justify-between border-b py-4"
          (click)="toggleSectionVisibility('blacklist')"
        >
          <div class="flex items-center text-xl">
            <span class="font-medium">{{ t("common.blacklist") }}</span>
            <span class="ml-2 text-gray-500"> ({{ strategyGroup.blacklist?.length }})</span>
            <span
              class="icon-[material-symbols--chevron-right-rounded] ml-3 text-3xl text-gray-600 duration-75"
              [class.rotate-90]="sectionVisibility.blacklist"
            ></span>
          </div>

          <IButton
            label="{{ t('common.edit') }}"
            color="white"
            icon="icon-[material-symbols--edit-square-outline]"
            [disabled]="isReadOnly"
            (onClick)="openBlackListCreationModal(false)"
            tooltipValue="Edit Blacklist"
          />
        </div>
        <div [hidden]="!sectionVisibility.blacklist">
          <app-blacklist-view [blacklist]="strategyGroup.blacklist ?? []"></app-blacklist-view>
          @if (strategyGroup.blacklist && strategyGroup.blacklist.length >= maxKwTargetingByStrategy) {
            <p class="text-warning">
              {{ t("sp-strategy-group-page.you_have_reached_the_max_number_of_blacklist_items") }}
            </p>
          }
        </div>
      }

      <!-- brand defense strategies -->
      @if (strategyGroup.brandStrategies.length > 0) {
        <div
          class="flex cursor-pointer select-none items-center justify-between border-b py-4"
          (click)="toggleSectionVisibility('brandStrategies')"
        >
          <div class="flex items-center text-xl">
            <span class="font-medium">{{ t("sp-strategy-group-page.brand_defense_strategies") }}</span>
            <span class="ml-2 text-gray-500"> ({{ strategyGroup.brandStrategies.length }})</span>
            <span
              class="icon-[material-symbols--chevron-right-rounded] ml-3 text-3xl text-gray-600 duration-75"
              [class.rotate-90]="sectionVisibility.brandStrategies"
            ></span>
          </div>
          <IButton
            label="{{ t('common.add') }}"
            color="white"
            icon="icon-[material-symbols--add-rounded]"
            [disabled]="isReadOnly"
            (onClick)="openSubStrategyCreationPage(StrategyType.BRAND)"
            tooltipValue="{{ t('sp-strategy-group-page.add_a_new_brand_defense_strategy') }}"
          />
        </div>
        <div [hidden]="!sectionVisibility.brandStrategies" class="ag-theme-quartz">
          <ag-grid-angular
            class="max-h-64"
            [ngStyle]="{ 'height.px': brandStrategyTableHeight }"
            [gridOptions]="brandStrategyGridOptions"
            [rowData]="strategyGroup.brandStrategies"
            [detailRowAutoHeight]="true"
            [animateRows]="true"
            [rowDragManaged]="true"
            #brandStrategies
          ></ag-grid-angular>
        </div>
      }
      <!-- main keyword (aka focus) strategies-->
      @if (strategyGroup.keywordStrategies && strategyGroup.keywordStrategies.length > 0) {
        <div
          class="flex cursor-pointer select-none items-center justify-between border-b py-4"
          (click)="toggleSectionVisibility('keywordStrategies')"
        >
          <div class="flex items-center text-xl">
            <span class="font-medium">{{ t("sp-strategy-group-page.focus_strategies") }}</span>
            <span class="ml-2 text-gray-500"> ({{ strategyGroup.keywordStrategies.length }})</span>
            <span
              class="icon-[material-symbols--chevron-right-rounded] ml-3 text-3xl text-gray-600 duration-75"
              [class.rotate-90]="sectionVisibility.keywordStrategies"
            ></span>
          </div>
          <IButton
            label="{{ t('common.add') }}"
            color="white"
            icon="icon-[material-symbols--add-rounded]"
            [disabled]="isReadOnly"
            (onClick)="openSubStrategyCreationPage(StrategyType.KEYWORD)"
            tooltipValue="{{ t('sp-strategy-group-page.add_a_new_focus_strategy') }}"
          />
        </div>
        <div [hidden]="!sectionVisibility.keywordStrategies" class="ag-theme-quartz">
          <ag-grid-angular
            class="max-h-64"
            [ngStyle]="{ 'height.px': keywordStrategyTableHeight }"
            [gridOptions]="mainKeywordStrategyGridOptions"
            [rowData]="strategyGroup.keywordStrategies"
            [detailRowAutoHeight]="true"
            [animateRows]="true"
            [rowDragManaged]="true"
            #mainKeywordStrategies
          ></ag-grid-angular>
        </div>
      }

      <div
        class="flex cursor-pointer select-none items-center justify-between border-b py-4"
        (click)="toggleSectionVisibility('productStrategies')"
      >
        <div class="flex items-center text-xl">
          <span class="font-medium">{{ t("sp-strategy-group-page.main_strategies") }}</span>
          <span class="ml-2 text-gray-500"> ({{ strategyGroup.productStrategies.length }})</span>
          <span
            class="icon-[material-symbols--chevron-right-rounded] ml-3 text-3xl text-gray-600 duration-75"
            [class.rotate-90]="sectionVisibility.productStrategies"
          ></span>
        </div>
        <IButton
          label="{{ t('common.add') }}"
          color="white"
          icon="icon-[material-symbols--add-rounded]"
          [disabled]="isReadOnly"
          (onClick)="openSubStrategyCreationPage(StrategyType.PRODUCT)"
          tooltipValue="{{ t('sp-strategy-group-page.add_a_new_main_strategy') }}"
        />
      </div>
      <div [hidden]="!sectionVisibility.productStrategies" class="ag-theme-quartz">
        <ag-grid-angular
          class="max-h-64"
          [ngStyle]="{ 'height.px': productStrategyTableHeight }"
          [gridOptions]="productStrategyGridOptions"
          [rowData]="strategyGroup.productStrategies"
          [detailRowAutoHeight]="true"
          [animateRows]="true"
          #productStrategies
        ></ag-grid-angular>
      </div>

      @if (
        (strategyGroup.blacklist?.length ?? 0) === 0 ||
        strategyGroup.brandStrategies.length === 0 ||
        strategyGroup.keywordStrategies.length === 0
      ) {
        <div
          class="flex cursor-pointer select-none items-center justify-between border-b py-4"
          (click)="toggleSectionVisibility('advanced')"
        >
          <div class="flex items-center text-xl">
            <span class="font-medium">{{ t("sp-strategy-group-page.advanced") }}</span>
            <span
              class="icon-[material-symbols--chevron-right-rounded] ml-3 text-3xl text-gray-600 duration-75"
              [class.rotate-90]="sectionVisibility.advanced"
            ></span>
          </div>
        </div>

        @if (sectionVisibility.advanced) {
          <div class="flex gap-2 pt-2">
            @if ((strategyGroup.blacklist?.length ?? 0) === 0) {
              <IButton
                color="white"
                label="{{ t('sp-strategy-group-page.create_blacklist') }}"
                [disabled]="isReadOnly"
                (onClick)="openBlackListCreationModal(true)"
              />
            }
            @if (strategyGroup.brandStrategies.length === 0) {
              <IButton
                color="white"
                label="{{ t('sp-strategy-group-page.create_brand_defense_strategy') }}"
                [disabled]="isReadOnly"
                (onClick)="openSubStrategyCreationPage(StrategyType.BRAND)"
              />
            }
            @if (strategyGroup.keywordStrategies.length === 0) {
              <IButton
                color="white"
                label="{{ t('sp-strategy-group-page.create_focus_strategy') }}"
                [disabled]="isReadOnly"
                (onClick)="openSubStrategyCreationPage(StrategyType.KEYWORD)"
              />
            }
          </div>
        }
      }

      <div
        class="flex cursor-pointer select-none items-center justify-between border-b py-4"
        (click)="toggleSectionVisibility('stats')"
      >
        <div class="flex items-center text-xl">
          <span class="font-medium">{{ t("sp-strategy-group-page.stats") }}</span>
          <span
            class="icon-[material-symbols--chevron-right-rounded] ml-3 text-3xl text-gray-600 duration-75"
            [class.rotate-90]="sectionVisibility.stats"
          ></span>
        </div>
      </div>
      @if (sectionVisibility.stats) {
        <div>
          <app-overview-grid
            gridKey="strategyGroupStats"
            [hiddenColumns]="hiddenColumns"
            [dataFilter]="strategyGroupStatsFilter"
            [noStrategyLinks]="true"
          ></app-overview-grid>
        </div>
      }

      <div
        class="flex cursor-pointer select-none items-center justify-between border-b py-4"
        (click)="toggleSectionVisibility('activities')"
      >
        <div class="flex items-center text-xl">
          <span class="font-medium">{{ t("sp-strategy-group-page.activites") }}</span>
          <span
            class="icon-[material-symbols--chevron-right-rounded] ml-3 text-3xl text-gray-600 duration-75"
            [class.rotate-90]="sectionVisibility.activities"
          ></span>
        </div>
      </div>
      @if (sectionVisibility.activities) {
        <div style="height: 30rem" class="flex flex-col pt-2">
          @if (dateRange) {
            <p class="text-gray-700">
              {{ t("sp-strategy-group-page.activities_registered_within_selected_date_range") }}
              <b>{{
                (dateRange[0] | date: "shortDate" : undefined : user?.locale) +
                  " to " +
                  (maxActivitiesDate! | date: "shortDate" : undefined : user?.locale)
              }}</b>
            </p>
          }
          <app-activity-component
            class="flex-1"
            [activityFilter]="activityFilter"
            gridConfigStorageKey="strategyGroupActivities"
          ></app-activity-component>
        </div>
      }
    </div>

    <ng-template #advertisedProductsModal>
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{ t("sd-strategy-creation.advertised_products") }}</h4>
      </div>

      <div class="modal-body h-[70vh] p-0">
        <div class="h-full">
          <app-strategy-asins
            [asins]="focusedStrategy?.asins ?? []"
            [accountId]="accountMarketplace?.accountId"
            [marketplace]="accountMarketplace?.marketplace"
            [selectable]="!isReadOnly"
            [addProductsBtn]="!isReadOnly"
            [deletable]="!isReadOnly"
            [movable]="!isReadOnly && focusedStrategy?.strategyType === StrategyType.PRODUCT"
            (move)="moveToOtherStrategy($event)"
            (selectedProducts)="updateProductSelection($event)"
            (addProducts)="addProductModal()"
            (onDelete)="removeProductsFromSubstrat($event, focusedStrategy!)"
            [csvExport]="true"
            [withDisplayMode]="true"
            [withBulkFilter]="true"
            csvExportFileName="{{ strategyGroup.strategyGroupName }}_{{ focusedStrategy?.strategyName }}"
            [asinEligibility]="asinEligibility"
            [isReadOnly]="isReadOnly"
            class="h-full w-full"
          />
        </div>
      </div>
      <div class="modal-footer">
        <IButton label="{{ t('common.close') }}" color="gray" variant="soft" (onClick)="modalRef && modalRef.hide()" />
      </div>
    </ng-template>

    <ng-template #editProductsModal>
      <div class="h-[70vh]">
        <div class="modal-body h-full">
          <app-asins-selection
            class="h-full"
            [accountId]="accountMarketplace!.accountId"
            [marketplace]="accountMarketplace!.marketplace"
            [csvExport]="false"
            [asins]="focusedStrategy!.asins"
            [customAsinList]="
              !focusedStrategy || focusedStrategy.strategyType !== StrategyType.PRODUCT
                ? strategyGroupAsinsStr
                : undefined
            "
            [selectionModes]="productSelectionModes!"
            [asinEligibility]="asinEligibility"
            (onAdd)="addProductsToSubStrat($event, focusedStrategy!)"
            (onDelete)="removeProductsFromSubstrat($event, focusedStrategy!)"
          />
        </div>
        <div class="modal-footer">
          <IButton
            label="{{ t('common.close') }}"
            color="gray"
            variant="soft"
            (onClick)="modalRef2 && modalRef2.hide()"
          />
        </div>
      </div>
    </ng-template>

    <ng-template #tacticsModal>
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          {{ focusTacticType === TacticType.BLACKLIST ? t("common.blacklist") : t("sp-strategy-group-page.tactic") }}
        </h4>
      </div>

      <div class="modal-body max-h-[70vh] overflow-auto p-0">
        <div class="sticky top-0 z-50 flex items-center border-b border-gray-300 bg-white p-3">
          <input
            class="simple-input form-control mr-2 flex-1"
            type="text"
            placeholder="{{ t('sp-strategy-group-page.search_by_segment_name') }}"
            [(ngModel)]="tacticSearchInput"
          />
          <IButton
            [label]="t('sp-strategy-group-page.new_tactic_label', { tactic: focusTacticType === TacticType.BLACKLIST })"
            icon="icon-[material-symbols--add-rounded]"
            (onClick)="createTactic()"
          />
        </div>
        <div class="p-3">
          @for (t of focusedTactics ?? [] | searchTextFilter: tacticAccessValue : tacticSearchInput; track t) {
            <app-tactic
              (tacticDeleted)="tacticDeleted($event)"
              [showKW]="true"
              [showProducts]="!focusedStrategy?.disableProductSegment || t.tacticType === 'BLACKLIST'"
              [tactic]="t"
              [segment]="getTacticSegment(t)"
              [strategy]="focusedStrategy"
            />
          } @empty {
            <p class="text-center text-gray-700">
              {{ t("sp-strategy-group-page.no_tactic_label", { tactic: focusTacticType === TacticType.BLACKLIST }) }}
            </p>
          }
        </div>
      </div>
      <div class="modal-footer">
        <IButton label="{{ t('common.close') }}" color="gray" variant="soft" (onClick)="modalRef && modalRef.hide()" />
      </div>
    </ng-template>
  }

  <ng-template #dailyBudgetModalRef>
    <div class="modal-header">
      <h4 class="modal-title pull-left">
        {{ focusedStrategy?.name }} - {{ t("sp-strategy-group-page.average_daily_budget") }}
      </h4>
    </div>
    <div class="modal-body">
      <div class="input-block">
        <label> {{ t("sp-strategy-group-page.average_daily_budget") }}</label>
        <div class="flex items-center">
          <input
            type="number"
            class="simple-input form-control"
            [value]="focusedStrategy?.dailyBudget ?? ''"
            [min]="getDailyBudgetLowerBound()"
            [step]="1"
            [disabled]="isReadOnly"
            (input)="focusedDailyBudget = +getValueFromInputEvent($event)"
          />
          <div class="font-sm ml-2 font-medium">{{ currencySymbol }}</div>
          @if (focusedStrategy && focusedStrategy.algoMode !== AlgoMode.PRODUCT_LAUNCH) {
            <IButton
              [icon]="ICON_TRASH_O"
              class="ml-1"
              color="gray"
              variant="ghost"
              [square]="true"
              tooltipValue="{{ t('sp-strategy-group-page.delete_daily_budget') }}"
              (onClick)="deleteDailyBudget()"
            />
          }
        </div>
        <span
          class="input-block__hint text-danger mt-2"
          *ngIf="focusedDailyBudget && focusedDailyBudget < getDailyBudgetLowerBound()"
        >
          {{ focusedDailyBudget | intInputErrorPipe: getDailyBudgetLowerBound() : undefined }}
        </span>
      </div>
    </div>
    <div class="modal-footer">
      <IButton label="{{ t('common.cancel') }}" color="gray" variant="soft" (onClick)="modalRef && modalRef.hide()" />
      <IButton
        class="w-2/4"
        label="{{ t('common.save') }}"
        [tooltipValue]="isInValidDailyBudget() ? t('sp-strategy-group-page.budget_is_invalid') : ''"
        [disabled]="isInValidDailyBudget()"
        (onClick)="changeDailyBudget()"
        [block]="true"
      />
    </div>
  </ng-template>
</ng-container>
