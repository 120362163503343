import { Component } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { AccountMarketplace, OrganizationAmazonUserAccess } from "@front/m19-api-client";
import { AccountMarketplaceService } from "@front/m19-services";

@UntilDestroy()
@Component({
  templateUrl: "./renew-amazon-access-modal.component.html",
})
export class RenewAmazonAccessModalComponent {
  amazonUserAccess!: OrganizationAmazonUserAccess[];
  organizationId!: number;
  accountMarketplace!: AccountMarketplace;
  canLoginWithAmazon!: boolean;
  loading!: boolean;

  constructor(
    private bsModalRef: BsModalRef,
    private accountMarketplaceService: AccountMarketplaceService,
    private toasterService: ToastrService,
  ) {}

  renew(): void {
    this.accountMarketplaceService.addAmazonUser(
      this.organizationId,
      this.accountMarketplace,
      () => {
        this.loading = false;
        this.toasterService.success("Access succesfully renewed", "Login with Amazon");
        this.bsModalRef.hide();
      },
      (errorMessage) => {
        this.loading = false;
        this.toasterService.error(errorMessage, "Login with Amazon error");
      },
      () => {
        this.loading = true;
      },
    );
  }

  close() {
    this.bsModalRef.hide();
  }
}
