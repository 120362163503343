import { Component, EventEmitter, Input, OnInit, Output, signal } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { CampaignType, Marketplace, Strategy, StrategyAsin } from "@front/m19-api-client";
import { StrategyEx, StrategyGroupEx } from "@front/m19-models";
import { StrategyService } from "@front/m19-services";
import { Utils } from "@front/m19-utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { forkJoin, of } from "rxjs";
import { catchError, map, switchMap, take, tap } from "rxjs/operators";
import {
  BulkImportService,
  StrategyBulkOperations,
  StrategyCreation,
  StrategyUpdate,
  StrategyUploadResult,
} from "./bulk-import.service";
import { AsinLabel, ProductListDetailsPopupComponent } from "./product-list-detail-popup.component";

@UntilDestroy()
@Component({
  selector: "app-strategy-bulk-upload-report-modal",
  templateUrl: "./strategy-bulk-upload-report-modal.component.html",
  styleUrls: ["./strategy-bulk-upload-report-modal.component.scss"],
})
export class StrategyBulkUploadReportModalComponent implements OnInit {
  @Input()
  public strategyBulkOperations!: StrategyBulkOperations;
  @Input()
  public accountId!: string;
  @Input()
  public marketplace!: Marketplace;
  @Input()
  public organizationId!: number;
  @Input()
  public campaignType!: CampaignType;
  @Output()
  public bulkOperationResult = new EventEmitter<StrategyUploadResult>();
  @Output()
  public uploadCancelled = new EventEmitter<void>();

  readonly genericBulkCsvFields = this.bulkImportService
    .getBulkConfig(CampaignType.SP)
    .filter((f) => f.field != "asins" && f.field != "strategyId");

  creationColumns: (keyof Strategy | "select")[] = [];
  updateColumns: (keyof Strategy | "select")[] = [];
  uploading = signal(false);
  uploadProgress = signal(0);
  uploadTotal = signal(0);

  readonly selectedStrategyCreation = new Map<number, StrategyCreation>();
  readonly selectedStrategyUpdate = new Map<number, StrategyUpdate>();
  readonly creationsDataSource = new MatTableDataSource<StrategyCreation>([]);
  readonly updateDataSource = new MatTableDataSource<StrategyUpdate>([]);
  readonly faSquare = faSquare;
  readonly faCheckedSquare = faCheckSquare;

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private bulkImportService: BulkImportService,
    private strategyService: StrategyService,
  ) {}

  ngOnInit(): void {
    this.creationsDataSource.data = this.strategyBulkOperations.creations;
    this.updateDataSource.data = this.strategyBulkOperations.updates;
    this.strategyBulkOperations.creations.forEach((c) => this.selectedStrategyCreation.set(c.lineIndex, c));
    this.strategyBulkOperations.updates.forEach((c) => this.selectedStrategyUpdate.set(c.lineIndex, c));
    const bulkConfig = this.bulkImportService.getBulkConfig(this.campaignType);
    this.creationColumns = ["select", ...bulkConfig.filter((s) => s.field != "strategyId").map((f) => f.field)];
    this.updateColumns = ["select", ...bulkConfig.map((f) => f.field)];
  }

  isStrategyCreationSelected(creation: StrategyCreation): boolean {
    return this.selectedStrategyCreation.has(creation.lineIndex);
  }

  isStrategyUpdateSelected(update: StrategyUpdate): boolean {
    return this.selectedStrategyUpdate.has(update.lineIndex);
  }

  toggleStrategyCreation(creation: StrategyCreation): void {
    if (this.selectedStrategyCreation.has(creation.lineIndex)) {
      this.selectedStrategyCreation.delete(creation.lineIndex);
    } else {
      this.selectedStrategyCreation.set(creation.lineIndex, creation);
    }
  }

  toggleStrategyUpdate(update: StrategyUpdate): void {
    if (this.selectedStrategyUpdate.has(update.lineIndex)) {
      this.selectedStrategyUpdate.delete(update.lineIndex);
    } else {
      this.selectedStrategyUpdate.set(update.lineIndex, update);
    }
  }

  showProductDetails(asins: StrategyAsin[], event: MouseEvent): void {
    event.preventDefault();
    const modalOptions: ModalOptions = {
      initialState: {
        asins: asins.map((a) => a.asin),
        accountId: this.accountId,
        marketplace: this.marketplace,
      },
    };
    this.modalService.show(ProductListDetailsPopupComponent, modalOptions);
  }

  showProductDetailsForUpdate(update: StrategyUpdate, event: MouseEvent): void {
    event.preventDefault();
    const asins = new Set<string>();
    const labels = new Map<string, AsinLabel>();
    for (const asin of update.updatedFields.asinsToAdd) {
      asins.add(asin);
      labels.set(asin, AsinLabel.NEW);
    }
    for (const asin of update.updatedFields.asinsToDelete) {
      asins.add(asin);
      labels.set(asin, AsinLabel.DELETED);
    }
    for (const asin of update.strategyToUpdate.asins!) {
      asins.add(asin.asin);
    }
    const modalOptions: ModalOptions = {
      initialState: {
        asins: Array.from(asins),
        accountId: this.accountId,
        marketplace: this.marketplace,
        asinLabels: labels,
      },
    };
    this.modalService.show(ProductListDetailsPopupComponent, modalOptions);
  }

  cancel(): void {
    this.uploadCancelled.emit();
    this.bsModalRef.hide();
  }

  uploadData(): void {
    const errors: string[] = [];
    this.uploading.set(true);
    this.uploadTotal.set(this.selectedStrategyCreation.size + this.selectedStrategyUpdate.size);
    this.uploadProgress.set(0);
    const creation =
      this.selectedStrategyCreation.size === 0
        ? of([] as StrategyEx[])
        : forkJoin(
            Array.from(this.selectedStrategyCreation.values()).map((strategyCreation) => {
              return (
                strategyCreation.strategyGroup
                  ? this.strategyService
                      .createStrategyGroup(
                        this.organizationId,
                        strategyCreation.strategyGroup,
                        strategyCreation.strategy,
                      )
                      .pipe(map((sg: StrategyGroupEx) => sg.productStrategies[0] as Strategy))
                  : this.strategyService.createStrategy(strategyCreation.strategy, this.organizationId)
              ).pipe(
                switchMap((s: Strategy) => {
                  // update the next monthly budget if set
                  if (strategyCreation.strategy.nextMonthlyBudget) {
                    const currentMonth = Utils.formatMonthForApi(s.today!);
                    return this.strategyService.updateStrategyMonthlyBudget(
                      s.accountId,
                      s.marketplace,
                      s.strategyId!,
                      strategyCreation.strategy.monthlyBudget!,
                      strategyCreation.strategy.nextMonthlyBudget,
                      currentMonth,
                    );
                  }
                  return of(s);
                }),
                catchError((err: string[] | string) => {
                  if (typeof err == "string") {
                    errors.push(`Impossible to create strategy ${strategyCreation.strategy.name}: ${err}`);
                  } else {
                    err.forEach((e) =>
                      errors.push(`Impossible to create strategy ${strategyCreation.strategy.name}: ${e}`),
                    );
                  }
                  return of(null);
                }),
                take(1),
                tap(() => this.uploadProgress.update((v) => v + 1)),
              );
            }),
          );
    const update =
      this.selectedStrategyUpdate.size === 0
        ? of([] as StrategyEx[])
        : forkJoin(
            Array.from(this.selectedStrategyUpdate.values()).map((strategyUpdate) => {
              return this.strategyService.updateStrategy(strategyUpdate.updatedFields).pipe(
                switchMap((s: Strategy) => {
                  if (strategyUpdate.updatedFields.algoMode) {
                    return this.strategyService.switchStrategyAlgoMode(
                      this.accountId,
                      this.marketplace,
                      s,
                      strategyUpdate.updatedFields.algoMode,
                      strategyUpdate.updatedFields.acosTarget!,
                      strategyUpdate.updatedFields.suggestedBid!,
                      strategyUpdate.updatedFields.dailyBudget!,
                      strategyUpdate.updatedFields.monthlyBudget!,
                    );
                  }
                  return of(s);
                }),
                switchMap((s: Strategy) => {
                  if (strategyUpdate.updatedFields.monthlyBudget || strategyUpdate.updatedFields.nextMonthlyBudget) {
                    const currentMonth = Utils.formatMonthForApi(s.today!);
                    return this.strategyService.updateStrategyMonthlyBudget(
                      s.accountId,
                      s.marketplace,
                      s.strategyId!,
                      strategyUpdate.updatedFields.monthlyBudget ?? s.monthlyBudget!,
                      strategyUpdate.updatedFields.nextMonthlyBudget ?? s.nextMonthlyBudget!,
                      currentMonth,
                    );
                  }
                  return of(s);
                }),
                catchError((err: string) => {
                  errors.push(`Impossible to update strategy ${strategyUpdate.strategyToUpdate.name}: ${err}`);
                  return of(null);
                }),
                take(1),
                tap(() => this.uploadProgress.update((v) => v + 1)),
              );
            }),
          );

    forkJoin([creation, update])
      .pipe(untilDestroyed(this))
      .subscribe(([created, updated]) => {
        this.bulkOperationResult.emit({
          created: created.filter((s): s is Strategy => !!s),
          updated: updated.filter((s): s is Strategy => !!s),
          errors: errors,
        });

        this.bsModalRef.hide();
      });
  }
}
