<ng-container *transloco="let t">
  <div
    (click)="$event.stopPropagation(); openInfo(product.asin)"
    [ngClass]="getActiveStrategies(product.asin) > 0 ? 'positive' : 'zero'"
    data-toggle="tooltip"
    data-placement="bottom"
    class="number pointer mx-auto"
    matTooltip="{{ t('inventory-table.number_activated_strategies', { count: getActiveStrategies(product.asin) }) }}"
  >
    {{ getActiveStrategies(product.asin) }}
  </div>
</ng-container>
