import { ColDef, GridApi, GridOptions, GridReadyEvent, ICellRendererParams } from "@ag-grid-community/core";
import { TitleCasePipe } from "@angular/common";
import { Component, effect, Input, input, OnInit } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import {
  AccountMarketplace,
  AccountType,
  AlgoMode,
  Brand,
  CampaignType,
  Currency,
  Marketplace,
  SbCreative,
  SbCreativeType,
  StrategyAsin,
  StrategyStateEnum,
  StrategyType,
  TacosStrategyGroup,
  TacosStrategyGroupStateEnum,
  TacticType,
} from "@front/m19-api-client";
import { Marketplaces, SegmentEx, StrategyEx, StrategyGroupEx } from "@front/m19-models";
import {
  AccountSelectionService,
  AuthService,
  SbStrategiesService,
  SegmentService,
  SpStrategiesService,
  StrategyService,
} from "@front/m19-services";
import { ModalRef, ModalService } from "@front/m19-ui";
import { Utils } from "@front/m19-utils";
import { TranslocoService } from "@jsverse/transloco";
import {
  ActionButton,
  ActionButtonsComponent,
} from "@m19-board/insights/overview/action-buttons/action-buttons.component";
import { AlgoTargetRendererComponent } from "@m19-board/insights/overview/algo-target-renderer/algo-target-renderer.component";
import { CampaignTypeBadgeComponent } from "@m19-board/shared/campaign-type-badge/campaign-type-badge.component";
import {
  ConfirmPopupData,
  ConfirmPopupV2Component,
} from "@m19-board/shared/confirm-popup-v2/confirm-popup-v2.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TacosStrategiesService } from "libs/m19-services/src/lib/m19-services/tacos-strategies.service";
import { ToastrService } from "ngx-toastr";
import { combineLatest, filter, map, Observable, switchMap, take } from "rxjs";
import { StrategyLinkComponent } from "../strategy-link/strategy-link.component";
import { StrategyStatusFilter } from "../strategy-list/strategy-list.component";
import { TacticStrategyRendererComponent } from "./tactic-strategy-renderer.component";
import { TargetingsRendererComponent } from "./targetings-renderer.component";
import { WarningRendererComponent } from "./warning-renderer.component";
import { Strategy } from "@floating-ui/dom";

export interface StrategyLine extends StrategyEx {
  creativesProduct?: SbCreative[];
  creativesVideo?: SbCreative[];
  brandAssetUrl?: string;
}

export interface StrategyGroupLine extends StrategyGroupEx {
  creativesProduct?: SbCreative[];
  creativesVideo?: SbCreative[];
  brandAssetUrl?: string;
}

@UntilDestroy()
@Component({
  selector: "app-strategy-grid",
  templateUrl: "./strategy-grid.component.html",
  styleUrls: ["./strategy-grid.component.scss"],
  providers: [TitleCasePipe],
})
export class StrategyGridComponent implements OnInit {
  gridOptions: GridOptions = {
    rowHeight: 50,
    defaultColDef: {
      suppressHeaderMenuButton: true,
      sortable: false,
      resizable: false,
      suppressMovable: true,
    },
    pagination: true,
    paginationPageSize: 100,
    paginationPageSizeSelector: [10, 20, 50, 100],
    isExternalFilterPresent: () => this.isExternalFilterPresent(),
    doesExternalFilterPass: (node: any) => this.doesExternalFilterPass(node),
    getRowId: (params: any) => {
      if (isStrategyEx(params.data)) return "strat_" + params.data.strategyId;
      if (isTacosStrategyGroup(params.data)) return "tacos_" + params.data.tacosStrategyGroupId;
      if (isStrategyGroupEx(params.data)) return "sg_" + params.data.strategyGroupId;
      return "strat_" + params.data.strategies[0].strategyId;
    },
  };
  gridApi?: GridApi;

  // Common column definitions for sb sd and sp
  BasecolDef: ColDef[] = [
    {
      field: "name",
      headerValueGetter: () => this.translocoService.translate("activity-service.strategy"),
      flex: 1,
      sortable: false,
      cellClass: "ag-base-cell w-full",
      cellRendererSelector: (params: any) => {
        return {
          component: StrategyLinkComponent,
          params: {
            strategyId: isStrategyEx(params.data) ? params.data.strategyId : params.data.strategies[0].strategyId,
            withCampaignState: true,
            target: "_self",
            strategyGroupName: isStrategyEx(params.data) ? undefined : params.data.strategyGroupName,
            strategyGroupState: isStrategyEx(params.data)
              ? undefined
              : this.hasLiveStrategies(params.data as StrategyGroupEx)
                ? StrategyStateEnum.ENABLED
                : StrategyStateEnum.PAUSED,
          },
        };
      },
    },
    {
      field: "target",
      headerValueGetter: () => this.translocoService.translate("strategy-table.target"),
      flex: 0.5,
      cellRendererSelector: (params: any) => {
        if (params.data.strategyId > 0 || params.data.strategies.length === 1) {
          const strat = isStrategyEx(params.data) ? params.data : params.data.strategies[0];
          return {
            component: AlgoTargetRendererComponent,
            params: {
              strategy: strat,
              locale: this.locale,
              currency: this.currency(),
              readonly: this.isReadOnly,
              addLabel: true,
              organizationId: this.organizationId,
            },
          };
        }
        return undefined;
      },
      cellClass: "ag-base-cell",
    },
    {
      field: "warning",
      headerValueGetter: () => this.translocoService.translate("strategy-grid.informations"),
      cellRendererSelector: (params: any) => {
        return {
          component: WarningRendererComponent,
          params: {
            promoBoostActivated$: this.promoBoostActivated$,
          },
        };
      },
      flex: 0.3,
      cellClass: "ag-base-cell",
    },
    {
      field: "asins",
      headerValueGetter: () => this.translocoService.translate("common.asins"),
      valueFormatter: (params: any) => {
        const strat = params.data;
        return this.translocoService.translate("strategy-table.asins", { count: strat.asins.length });
      },
      flex: 0.4,
      cellClass: "ag-base-cell",
    },
  ];

  // Column definiton for Tacos Strategies
  TacosColDef: ColDef[] = [
    {
      field: "name",
      headerValueGetter: () => this.translocoService.translate("common.name"),
      flex: 1,
      cellClass: "ag-base-cell w-full",
      cellRendererSelector: (params: any) => {
        const tacosStrat = params.data as TacosStrategyGroup;
        return {
          component: StrategyLinkComponent,
          params: {
            strategyId: tacosStrat.spStrategyId,
            withCampaignState: true,
            target: "_self",
            strategyGroupState: tacosStrat.state,
          },
        };
      },
    },
    {
      field: "tacosTarget",
      headerValueGetter: () => this.translocoService.translate("strategy-table.target"),
      flex: 1,
      cellRendererSelector: (params: any) => {
        return {
          component: AlgoTargetRendererComponent,
          params: {
            strategy: params.data,
            locale: this.locale,
            currency: this.currency,
            readonly: this.isReadOnly,
            addLabel: true,
            organizationId: this.organizationId,
            tacosId: params.data.tacosStrategyGroupId,
          },
        };
      },
      cellClass: "ag-base-cell w-full",
    },
    {
      field: "numberOfStrategies",
      headerValueGetter: () => this.translocoService.translate("common.startegies"),
      cellClass: "ag-base-cell w-full",
      flex: 0.5,
      cellRendererSelector: (params: any) => {
        const tacosStrategy = params.data as TacosStrategyGroup;
        const sp = tacosStrategy.spStrategyId != undefined;
        const sb = tacosStrategy.sbStrategyId != undefined;
        const sd = tacosStrategy.sdStrategyId != undefined;
        return {
          component: CampaignTypeBadgeComponent,
          params: {
            campaignType: undefined,
            tacosSpStrategyId: tacosStrategy.spStrategyId,
          },
        };
      },
    },
  ];

  // Pause and remove button
  pauseRemoveAction: ColDef = {
    flex: 0.2,
    cellRendererSelector: (params: any) => {
      const strat = params.data;
      const actionButtons: ActionButton[] = [];
      const subItems: ActionButton[] = [];
      if (isStrategyEx(strat) || isTacosStrategyGroup(strat)) {
        actionButtons.push({
          title:
            strat.state === "PAUSED"
              ? this.translocoService.translate("strategy-grid.enable")
              : this.translocoService.translate("strategy-page.pause"),
          onClick: (params: any) => {
            this.changeStatus(strat);
          },
          icon: strat.state === "PAUSED" ? "icon-[mdi--play]" : "icon-[mdi--pause]",
          tooltip:
            strat.state === "PAUSED"
              ? this.translocoService.translate("strategy-grid.enable_this_strategy")
              : this.translocoService.translate("strategy-grid.disable_this_strategy"),
        });
      } else if (strat.strategies.length === 1) {
        const firstStrategy = strat.strategies[0];
        actionButtons.push({
          title:
            firstStrategy.state === "PAUSED"
              ? this.translocoService.translate("strategy-grid.enable")
              : this.translocoService.translate("strategy-page.pause"),
          onClick: (params: any) => {
            this.changeStatus(firstStrategy);
          },
          icon: firstStrategy.state === "PAUSED" ? "icon-[mdi--play]" : "icon-[mdi--pause]",
          tooltip:
            firstStrategy.state === "PAUSED"
              ? this.translocoService.translate("strategy-grid.enable_this_strategy")
              : this.translocoService.translate("strategy-grid.disable_this_strategy"),
        });
      } else {
        if (this.hasLiveStrategies(strat)) {
          actionButtons.push({
            title: this.translocoService.translate("strategy-page.pause"),
            onClick: (params: any) => {
              this.changeStatus(strat);
            },
            icon: "icon-[mdi--pause]",
            tooltip: this.translocoService.translate("strategy-grid.disable_this_strategy"),
          });
        } else {
          actionButtons.push({
            title: this.translocoService.translate("strategy-grid.enable"),
            onClick: (params: any) => {
              this.changeStatus(strat);
            },
            icon: "icon-[mdi--play]",
            tooltip: this.translocoService.translate("strategy-grid.enable_this_strategy"),
          });
        }
      }

      actionButtons.push({
        title: this.translocoService.translate("common.delete"),
        onClick: (params: any) => {
          this.deleteStrategy(strat);
        },
        icon: "icon-[mdi--delete]",
        tooltip: this.translocoService.translate("strategy-grid.delete_this_strategy"),
        testid: `delete-strat-${strat.name ?? strat.strategyGroupName}`,
      });

      return {
        component: ActionButtonsComponent,
        params: {
          actionButtons,
        },
      };
    },
    cellClass: "ag-base-cell",
  };

  // Specific column definition for SD
  sdTargeting: ColDef = {
    field: "targetings",
    headerValueGetter: () => this.translocoService.translate("sd-strategy-creation.targetings"),
    flex: 0.5,
    cellRendererSelector: (params: any) => {
      return {
        component: TargetingsRendererComponent,
        params: {
          targetings: params.data.audienceTargetings,
          sdProductTactics: this.getSdProductTactics(params.data),
        },
      };
    },
    cellClass: "ag-base-cell",
  };

  // Specific column definition for SB
  sbAdLines: ColDef[] = [
    {
      field: "brand",
      headerValueGetter: () => this.translocoService.translate("common.brand"),
      flex: 0.3,
      cellClass: "ag-base-cell",
      valueGetter: (params) => {
        const strat = params.data as StrategyLine;
        if (this.accountMarketplace?.accountType == AccountType.VENDOR) {
          return strat.creativesProduct?.[0]?.brandName ?? strat.creativesVideo?.[0]?.brandName ?? "";
        }
        const firstProduct = strat.creativesProduct?.[0]?.brandEntityId;
        const firstVideo = strat.creativesVideo?.[0]?.brandEntityId;
        return (
          this.brands.find((b) => b.brandEntityId === firstProduct || b.brandEntityId === firstVideo)?.brandName ?? ""
        );
      },
    },
    {
      field: "adType",
      headerValueGetter: () => this.translocoService.translate("sb-form-asins.ad_line"),
      flex: 1,
      cellClass: "ag-base-cell",
      cellRenderer: (params: ICellRendererParams) => {
        const strat = params.data as StrategyLine;
        const typeList: SbCreativeType[] = strat.creativesProduct!.map((x) => x.creativeType);
        typeList.push(...strat.creativesVideo!.map((x) => x.creativeType));
        const type = Array.from(new Set(typeList));
        if (type.length == 1) {
          return `<div>${typeList.filter((t) => t == type[0]).length + " " + this.translocoService.translate(this.getCreativeTypeStr(type[0]))}</div>`;
        }
        if (type.length == 2) {
          return `<div>${typeList.filter((t) => t == type[0]).length + " " + this.translocoService.translate(this.getCreativeTypeStr(type[0]))}
          | ${typeList.filter((t) => t == type[1]).length + " " + this.translocoService.translate(this.getCreativeTypeStr(type[1]))}</div>`;
        }
        if (type.length == 3) {
          return `
        <div class="flex flex-col">
          <span class="truncate">${typeList.filter((t) => t == type[0]).length + " " + this.translocoService.translate(this.getCreativeTypeStr(type[0]))}
          | ${typeList.filter((t) => t == type[1]).length + " " + this.translocoService.translate(this.getCreativeTypeStr(type[1]))}
          | ${typeList.filter((t) => t == type[2]).length + " " + this.translocoService.translate(this.getCreativeTypeStr(type[2]))}</span>
          </div>`;
        }
        if (type.length == 4) {
          return `
        <div class="flex flex-col">
          <span class="truncate">${typeList.filter((t) => t == type[0]).length + " " + this.translocoService.translate(this.getCreativeTypeStr(type[0]))}
          | ${typeList.filter((t) => t == type[1]).length + " " + this.translocoService.translate(this.getCreativeTypeStr(type[1]))}
          | ${typeList.filter((t) => t == type[2]).length + " " + this.translocoService.translate(this.getCreativeTypeStr(type[2]))}
          | ${typeList.filter((t) => t == type[3]).length + " " + this.translocoService.translate(this.getCreativeTypeStr(type[3]))}</span>
          </div>`;
        }
        return "";
      },
    },
  ];

  // Specific column definition for SP
  spTatics: ColDef = {
    field: "tactic_strategies",
    headerValueGetter: () => this.translocoService.translate("strategy-table.tactics_strategies"),
    flex: 1,
    cellRendererSelector: (params: any) => {
      return {
        component: TacticStrategyRendererComponent,
        params: {
          strat: params.data,
          segmentIndex: this.segmentIndex,
        },
      };
    },
    cellClass: "ag-base-cell",
  };

  colDef: ColDef[] = [];

  //Data Displayed in the ag grid
  dataSource: (StrategyLine | StrategyGroupLine | TacosStrategyGroup)[] = [];

  organizationId: number = 0;
  promoBoostActivated$?: Observable<boolean>;
  private strategies: StrategyLine[] = [];
  private strategyGroups: StrategyGroupLine[] = [];

  private accountMarketplace?: AccountMarketplace;
  private isReadOnly = false;
  @Input() campaignType?: CampaignType | "TacosStrategy";

  locale: string = "";
  currency = toSignal<Currency>(
    this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(
      map((am) => Marketplaces[am.marketplace].currency),
    ),
  );

  strategyPage = "";
  strategyPagePrefix: string = "";
  strategyGroupPagePrefix: string = "";
  segmentIndex: Map<number, SegmentEx> = new Map();

  @Input() marketplace?: Marketplace;
  externalSearchFilter = input("");
  externalStatusFilter = input<StrategyStatusFilter>(StrategyStatusFilter.ALL);

  brands: Brand[] = [];

  constructor(
    private sbStrategiesService: SbStrategiesService,
    private spStrategiesService: SpStrategiesService,
    private tacosStrategiesService: TacosStrategiesService,
    private segmentService: SegmentService,
    private modalService: ModalService,
    private accountSelectionService: AccountSelectionService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private strategyService: StrategyService,
    private translocoService: TranslocoService,
  ) {
    effect(() => {
      this.externalSearchFilter();
      this.externalStatusFilter();
      this.applyExternalFilter();
    });
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) => this.segmentService.getSegments(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((segments) => {
        this.segmentIndex = segments;
      });
    this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.accountMarketplace = am;
    });
  }

  ngOnInit(): void {
    // Building the column definition based on the campaign type
    switch (this.campaignType) {
      case CampaignType.SP:
        this.strategyPage = "sponsored-product";
        this.colDef = [...this.BasecolDef, this.spTatics, this.pauseRemoveAction];
        break;

      case CampaignType.SD:
      case CampaignType.SDR:
        this.strategyPage = "sponsored-display";
        this.colDef = [...this.BasecolDef, this.sdTargeting, this.pauseRemoveAction];
        break;

      case CampaignType.SB:
        this.strategyPage = "sponsored-brands";
        this.colDef = [...this.BasecolDef, ...this.sbAdLines, this.pauseRemoveAction];
        break;
      case "TacosStrategy":
        this.strategyPage = "tacos-strategy";
        this.colDef = [...this.TacosColDef, this.pauseRemoveAction];
        break;
    }

    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.locale = user.locale;
      if (user?.uiVersion > 0) {
        this.strategyPagePrefix = "/advertising/";
        this.strategyGroupPagePrefix = "/advertising/sponsored-product/strategy-group/";
      }
    });

    this.accountSelectionService.singleAccountMarketplaceSelection$.subscribe((am) => {
      this.organizationId = am.resourceOrganizationId!;
    });
    // For SB campaigns, we need to fetch the strategies, creatives and brand assets
    if (this.campaignType == CampaignType.SB) {
      this.accountSelectionService.singleAccountMarketplaceSelection$
        .pipe(
          switchMap((am) => {
            return combineLatest([
              this.sbStrategiesService.getSBStrategies(am.accountId, am.marketplace),
              this.sbStrategiesService.getSbCreativesPerStrategy(am.accountId, am.marketplace),
              this.sbStrategiesService.getSbCreativeBrandAssets(am.accountId, am.marketplace),
              this.sbStrategiesService.getBrands(am.accountId, am.marketplace),
            ]);
          }),
          untilDestroyed(this),
        )
        .subscribe(([sbStrats, sbCreatives, brandAssets, brands]) => {
          // Filter out TACOS strategies
          this.brands = brands;
          const strats: StrategyLine[] = Array.from(sbStrats.values())
            .map((s) => {
              return new StrategyEx(s);
            })
            .filter((x) => x.algoMode !== AlgoMode.TACOS_TARGET);
          for (const strat of strats) {
            const creatives = sbCreatives.get(strat.strategyId) ?? [];
            strat.creativesVideo = creatives.filter((x) => x.creativeType == SbCreativeType.video);
            strat.creativesProduct = creatives.filter((x) => x.creativeType == SbCreativeType.productCollection);
            strat.asins = this.getSBAsins(strat);
            if (strat.creativesProduct!.length > 0) {
              const brandAsset = brandAssets.get((strat as StrategyLine).creativesProduct![0].creativeId);
              strat.brandAssetUrl = brandAsset?.logoAsset?.url ?? brandAsset?.videoAsset?.url;
            }
          }
          // Sort the strategies and assign them to the dataSource
          this.strategies = strats;
          this.dataSource = this.sortStrategies(this.strategies);
        });
    } else {
      // For SP, SD, and Tacos campaigns, we only need to fetch the strategies
      // TODO: based on the campaign type we do not need to fetch all that data
      this.accountSelectionService.singleAccountMarketplaceSelection$
        .pipe(
          switchMap((am) =>
            combineLatest([
              this.campaignType == CampaignType.SP
                ? this.spStrategiesService.getSPStrategies(am.accountId, am.marketplace)
                : this.strategyService.getStrategyIndex(am.accountId, am.marketplace),
              this.spStrategiesService.getStrategyGroups(am.accountId, am.marketplace),
              this.tacosStrategiesService.getTacosStrategyIndex(am.accountId, am.marketplace),
            ]),
          ),
          untilDestroyed(this),
        )
        .pipe(untilDestroyed(this))
        .subscribe(([strategies, strategyGroups, tacosStrategies]) => {
          // only display legacy strategy type
          const tmpToStrategyEx = Array.from(strategies.values()).map((s) => {
            return new StrategyEx(s);
          });
          let tacosStrategiesList = Array.from(tacosStrategies.values());
          this.strategyGroups = Array.from(strategyGroups.values()).map((sg) => {
            return sg as StrategyGroupEx;
          });
          if (this.campaignType == "TacosStrategy") {
            // case tacos we want to keep all the strategies
            this.strategies = [...this.sortStrategies(tmpToStrategyEx)];
            tacosStrategiesList = this.sortTacosStrategies(tacosStrategiesList);
          } else {
            this.strategies = [
              ...this.sortStrategies(
                tmpToStrategyEx.filter(
                  (x) =>
                    x.strategyType == StrategyType.LEGACY && // only display legacy strategy type
                    x.campaignType == this.campaignType &&
                    x.algoMode !== AlgoMode.TACOS_TARGET,
                ),
              ),
            ].filter((s) => {
              // hide SP strategies that are used in Tacos strategies
              if (this.campaignType == CampaignType.SP) {
                return !tacosStrategiesList.some((t) => t.spStrategyId === s.strategyId!);
              }
              return true;
            });
          }
          if (this.campaignType == CampaignType.SP) {
            // For SP campaigns, we need to sort with startegy group too
            // sort the active and then the paused, with startegy groups first
            this.dataSource = [...this.strategies, ...this.strategyGroups].sort((a, b) => this.compareStrategies(a, b));
          } else if (this.campaignType == "TacosStrategy") {
            // Only display the tacos strategies
            this.dataSource = Array.from(new Set(tacosStrategiesList));
          } else {
            // For SD campaigns, we only need to display the strategies
            this.dataSource = this.strategies;
          }
          this.gridApi?.updateGridOptions({ rowData: this.dataSource });
          this.gridApi?.refreshCells({ force: true });
        });
    }
    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
  }

  hasLiveStrategies(strategyGroup: StrategyGroupEx): boolean | undefined {
    return strategyGroup?.strategies?.some((s) => s.state === StrategyStateEnum.ENABLED);
  }

  // Change the status of a strategy
  changeStatus(s: StrategyEx | StrategyGroupEx | TacosStrategyGroup) {
    if (isTacosStrategyGroup(s)) {
      // TACOS case
      const tacosStrategy = s as TacosStrategyGroup;
      this.tacosStrategiesService
        .updateStrategyGroupState(
          this.accountMarketplace!.accountId,
          this.accountMarketplace!.marketplace,
          tacosStrategy.tacosStrategyGroupId!,
          tacosStrategy.state == TacosStrategyGroupStateEnum.PAUSED
            ? TacosStrategyGroupStateEnum.ENABLED
            : TacosStrategyGroupStateEnum.PAUSED,
        )
        .subscribe({
          next: () => {
            this.toastrService.success(
              tacosStrategy.state === TacosStrategyGroupStateEnum.PAUSED
                ? this.translocoService.translate("strategy-grid.strategy_activated")
                : this.translocoService.translate("strategy-grid.strategy_paused"),
            );
          },
          error: (err) => {
            this.toastrService.error(err, this.translocoService.translate("strategy-grid.strategy_state_update_error"));
          },
        });
      return;
    } else if (!isStrategyEx(s) && isStrategyGroupEx(s) && s.strategies?.length != 1) {
      // Cant change the status of a strategy group with more than 1 strategy
      this.toastrService.error(
        this.translocoService.translate("strategy-grid.strategy_group_cant_be_enabled_or_disabled"),
        this.translocoService.translate("strategy-grid.strategy_group_status"),
      );
      return;
    }
    // Case sp sb sd not strategy group
    const strategy = isStrategyEx(s) ? s : s.strategies![0];
    const newState = strategy.state == StrategyStateEnum.ENABLED ? StrategyStateEnum.PAUSED : StrategyStateEnum.ENABLED;

    //Update the state of the strategy
    this.strategyService
      .updateStrategyState(
        this.accountMarketplace!.accountId,
        this.accountMarketplace!.marketplace,
        this.organizationId,
        strategy.strategyId!,
        newState,
      )
      .subscribe({
        next: () => {
          this.toastrService.success(
            newState === StrategyStateEnum.ENABLED
              ? this.translocoService.translate("strategy-grid.strategy_activated")
              : this.translocoService.translate("strategy-grid.strategy_paused"),
          );
        },
        error: (err) => {
          this.toastrService.error(err, this.translocoService.translate("strategy-grid.strategy_state_update_error"));
        },
      });
  }

  deleteStrategy(strategy: StrategyEx | StrategyGroupEx | TacosStrategyGroup) {
    const strategyName = this.getStrategyName(strategy);
    const modalRef = this.openStrategyDeleteModal(strategyName);
    if (isStrategyEx(strategy)) {
      modalRef
        .afterClosed()
        .pipe(
          take(1),
          filter((confirmed) => !!confirmed),
          switchMap(() => this.strategyService.deleteStrategy(strategy as StrategyEx, this.organizationId)),
        )
        .subscribe({
          next: () => {
            this.toastrService.success(
              this.translocoService.translate("sp-strategy-group-page.strategy_successfully_deleted", [strategyName]),
              this.translocoService.translate("strategy-grid.strategy_deleted"),
            );
          },
          error: (err) => {
            this.toastrService.error(
              this.translocoService.translate("strategy-grid.error_deleting_strategy_strategyname_err", [
                strategyName,
                err,
              ]),
              this.translocoService.translate("strategy-grid.strategy_deletion_error"),
            );
          },
        });
    } else if (isStrategyGroupEx(strategy)) {
      modalRef
        .afterClosed()
        .pipe(
          take(1),
          filter((confirmed) => !!confirmed),
          switchMap(() =>
            this.strategyService.deleteStrategyGroup(
              strategy.accountId!,
              strategy.marketplace!,
              this.organizationId,
              strategy.strategyGroupId!,
            ),
          ),
        )
        .subscribe({
          next: () => {
            this.toastrService.success(
              this.translocoService.translate("sp-strategy-group-page.strategy_successfully_deleted", [strategyName]),
              this.translocoService.translate("strategy-grid.strategy_deleted"),
            );
          },
          error: (err) => {
            this.toastrService.error(
              this.translocoService.translate("strategy-grid.error_deleting_strategy_strategyname_err", [
                strategyName,
                err,
              ]),
              this.translocoService.translate("strategy-grid.strategy_deletion_error"),
            );
          },
        });
    } else {
      modalRef
        .afterClosed()
        .pipe(
          take(1),
          filter((confirmed) => !!confirmed),
          switchMap(() =>
            this.tacosStrategiesService.deleteTacosStrategyGroup(
              strategy.accountId!,
              strategy.marketplace!,
              this.organizationId,
              strategy,
            ),
          ),
        )
        .subscribe({
          next: () => {
            this.toastrService.success(
              this.translocoService.translate("sp-strategy-group-page.strategy_successfully_deleted", [strategyName]),
              this.translocoService.translate("strategy-grid.strategy_deleted"),
            );
          },
          error: (err) => {
            this.toastrService.error(
              this.translocoService.translate("strategy-grid.error_deleting_strategy_strategyname_err", [
                strategyName,
                err,
              ]),
              this.translocoService.translate("strategy-grid.strategy_deletion_error"),
            );
          },
        });
    }
  }

  private openStrategyDeleteModal(strategyName: string): ModalRef<boolean> {
    return this.modalService.openModal<ConfirmPopupData, boolean>(ConfirmPopupV2Component, {
      modalTitle: this.translocoService.translate("activity-service.strategy_deletion"),
      data: {
        message: this.translocoService.translate("strategy-grid.are_you_sure_you_want_to_delete_strategy", [
          strategyName,
        ]),
        confirmLabel: this.translocoService.translate("common.delete"),
      },
    });
  }

  private getStrategyName(strategy: StrategyEx | StrategyGroupEx | TacosStrategyGroup): string {
    if (isStrategyEx(strategy)) {
      return strategy.name!;
    } else if (isStrategyGroupEx(strategy)) {
      return strategy.strategyGroupName!;
    } else {
      const sp = this.strategies.find((s) => s.strategyId === strategy.spStrategyId);
      return sp?.name ?? "";
    }
  }

  getSdProductTactics(strategy: StrategyEx): SegmentEx[] {
    return strategy.tactics
      .filter((t) => t.tacticType == TacticType.LEGACY)
      .map((t) => this.segmentIndex.get(t.segmentId)!);
  }

  isExternalFilterPresent(): boolean {
    return this?.externalSearchFilter() !== "" || this?.externalStatusFilter() !== undefined;
  }

  doesExternalFilterPass(node: any): boolean {
    const strat = node.data;
    if (!this?.isExternalFilterPresent()) {
      return true;
    }
    let search = true;
    let status = true;
    if (this?.externalSearchFilter() !== "") {
      if (strat.tacosStrategyGroupId) {
        search =
          (strat as TacosStrategyGroup).spStrategy?.name
            ?.toLowerCase()
            .includes(this?.externalSearchFilter().toLowerCase()) ?? false;
      } else if (strat.strategyId > 0) {
        search = strat.strategyName.toLowerCase().includes(this?.externalSearchFilter().toLowerCase());
      } else {
        search = strat.strategyGroupName.toLowerCase().includes(this?.externalSearchFilter().toLowerCase());
      }
    }
    if (this?.externalSearchFilter() !== "") {
      if (strat.tacosStrategyGroupId) {
        const spStrat = this.strategies.find((s) => s.strategyId === (strat as TacosStrategyGroup).spStrategyId);
        search = (search || spStrat?.name?.toLowerCase().includes(this?.externalSearchFilter().toLowerCase())) ?? false;
      } else if (strat.strategyId > 0) {
        search =
          search ||
          strat.asins.some((asin: StrategyAsin) => {
            return this?.externalSearchFilter() == asin.asin;
          });
      } else {
        search =
          search ||
          (strat as StrategyGroupEx).asins.some((asin: string) => {
            return this?.externalSearchFilter() == asin;
          });
      }
    }
    if (this?.externalStatusFilter() !== StrategyStatusFilter.ALL) {
      if (strat.strategyId > 0) {
        if (this?.externalStatusFilter() === StrategyStatusFilter.ON) {
          status = strat.state === StrategyStateEnum.ENABLED;
        } else {
          status = strat.state === StrategyStateEnum.PAUSED;
        }
      } else {
        if (this?.externalStatusFilter() === StrategyStatusFilter.ON) {
          status = this.hasLiveStrategies(strat)!;
        } else {
          status = !this.hasLiveStrategies(strat)!;
        }
      }
    }
    return search && status;
  }

  applyExternalFilter() {
    this?.gridApi?.onFilterChanged();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  private getState(s: StrategyEx | StrategyGroupEx) {
    if (isStrategyEx(s)) return s.state;
    return this.hasLiveStrategies(s) ? StrategyStateEnum.ENABLED : StrategyStateEnum.PAUSED;
  }
  private getName(s: StrategyEx | StrategyGroupEx): string {
    if (isStrategyEx(s)) return s.name ?? "";
    return s.strategyGroupName ?? "";
  }

  private compareStrategies(a: StrategyEx | StrategyGroupEx, b: StrategyEx | StrategyGroupEx) {
    const stateA = this.getState(a);
    const stateB = this.getState(b);
    const nameA = this.getName(a);
    const nameB = this.getName(b);
    if (stateA === stateB) {
      if (isStrategyEx(b) && b.defaultStrategy) {
        return -1;
      }
      if (isStrategy(a) && a.defaultStrategy) {
        return 1;
      }
      return this.getName(a).localeCompare(this.getName(b));
    }
    return stateA === StrategyStateEnum.ENABLED ? -1 : 1;
  }

  private compareTacos(a: TacosStrategyGroup, b: TacosStrategyGroup) {
    const nameA = this.strategies.find((s) => s.strategyId === a.spStrategyId)?.name ?? "";
    const nameB = this.strategies.find((s) => s.strategyId === b.spStrategyId)?.name ?? "";
    if (a.state == b.state) {
      return Utils.strCompare(nameA, nameB);
    }
    return a.state!.localeCompare(b.state!);
  }

  private sortStrategies(strategies: StrategyEx[]) {
    return strategies.sort((a, b) => this.compareStrategies(a, b));
  }

  private sortTacosStrategies(strategies: TacosStrategyGroup[]) {
    return strategies.sort((a, b) => this.compareTacos(a, b));
  }

  private getCreativeTypeStr(creativeType: SbCreativeType): string {
    switch (creativeType) {
      case SbCreativeType.productCollection:
        return "creative-display.product_collection";
      case SbCreativeType.video:
        return "sb-form-creative.video";
      case SbCreativeType.brandVideo:
        return "sb-form-ad-format.brand_video";
      case SbCreativeType.storeSpotlight:
        return "sb-form-ad-format.store_spotlight";
    }
  }

  private getSBAsins(strat: StrategyLine): StrategyAsin[] {
    const creativeProducts = strat.creativesProduct;
    const creativeVideos = strat.creativesVideo;
    const asins = new Set<string>();
    if (creativeProducts) {
      for (const creative of creativeProducts) {
        for (const creativeAsins of creative.creativeAsins!) {
          if (creativeAsins.asin1) asins.add(creativeAsins.asin1);
          if (creativeAsins.asin2) asins.add(creativeAsins.asin2);
          if (creativeAsins.asin3) asins.add(creativeAsins.asin3);
        }
      }
    }
    if (creativeVideos) {
      for (const creative of creativeVideos) {
        for (const creativeAsins of creative.creativeAsins!) {
          if (creativeAsins.asin1) asins.add(creativeAsins.asin1);
          if (creativeAsins.asin2) asins.add(creativeAsins.asin2);
          if (creativeAsins.asin3) asins.add(creativeAsins.asin3);
        }
      }
    }
    return Array.from(asins.values()).map((asin) => ({
      asin,
    }));
  }
}

function isStrategyEx(row: StrategyEx | StrategyGroupEx | TacosStrategyGroup): row is StrategyEx {
  return (row as StrategyEx).strategyId > 0;
}

function isStrategyGroupEx(row: StrategyEx | StrategyGroupEx | TacosStrategyGroup): row is StrategyGroupEx {
  return (row as StrategyGroupEx).strategyGroupId ? true : false;
}

function isTacosStrategyGroup(row: StrategyEx | StrategyGroupEx | TacosStrategyGroup): row is TacosStrategyGroup {
  return (row as TacosStrategyGroup).tacosStrategyGroupId ? true : false;
}

function isStrategy(row: StrategyLine | StrategyGroupEx): row is StrategyLine {
  return (row as StrategyLine).strategyId > 0;
}
