import { Injectable } from '@angular/core';
import {
  AccountMarketplace,
  AdStats,
  AllVendorInventory,
  Currency,
  DailyAdSpendFee,
  DailyVendorAllSales,
  DailyVendorAsinAllSales,
  DspStats,
  GetDailyAsinStatsRequest,
  Marketplace,
  StatsApi,
  Strategy,
  StrategyStateEnum,
} from '@front/m19-api-client';
import { AccountGroup } from '@front/m19-models/AccountGroup';
import { AdStatsEx } from '@front/m19-models/AdStatsEx';
import { CurrencyStat } from '@front/m19-models/CurrencyStat';
import { Marketplaces } from '@front/m19-models/MarketplaceEx';
import {
  AccountMarketplaceDataCache,
  accountMarketplaceKey,
  AccountMarketplaceKey,
  addAdStats,
  catchAjaxError,
  Comparison,
  convertDspStatsToCurrency,
  convertToCurrency,
  GroupBy,
  marketplaceToCurrencyRate,
  merge2dAdStats,
  mergeSeveralDates,
  mergeVendorAdStatsEx,
  mergeVendorTrafficAndInventoryDailyAsinStats,
  TimeSeriesCache,
  toInventoryAdStats,
  toVendorAsinSalesAdStats,
  Utils,
} from '@front/m19-utils';
import { Moment } from 'moment-timezone';
import { BehaviorSubject, combineLatest, concat, forkJoin, Observable, of } from 'rxjs';
import { distinct, distinctUntilChanged, filter, map, shareReplay, switchMap, toArray } from 'rxjs/operators';
import { AccountSelectionService, UserSelectionService } from '.';

@Injectable({
  providedIn: 'root',
})
export class StatsApiClientService {
  /**
   * @deprecated: used by old dashboard
   */
  public readonly globalDataByDate$: Observable<AdsStatsWithPreviousPeriod>;

  // Stats by sb creative
  public readonly dailySbCreativeStats$: Observable<AdStatsData>;
  public readonly previousPeriodDailySbCreativeStats$: Observable<AdStatsData>;

  public readonly strategyConfigHistory$: Observable<Strategy[]>;

  public readonly lastSevenDaysStatsPerStrategy$: Observable<Map<number, AdStatsEx>> = new Observable<
    Map<number, AdStatsEx>
  >();

  /**
   * @deprecated: used by old dashboard
   */
  private readonly globalDataByDateCache$: AccountMarketplaceWithOptionsDataCache2<AdStatsEx[], string[]>;
  /**
   * @deprecated: used by old dashboard
   */
  private readonly globalDataByDatesPreviousPeriodCache$: AccountMarketplaceWithOptionsDataCache2<
    AdStatsEx[],
    DateRangeWithDateInterval
  >;
  private readonly refreshGlobalData = new BehaviorSubject<void>(void 0);
  // cache campaign stats per accountMarketPlace
  private readonly campaignHourlyStatsCache: AccountMarketplaceDataCache<AdStatsEx[], string[]>;

  // cache Query stats per account-marketplace
  private readonly queryStatsCache: AccountMarketplaceDataCache<
    AdsStatsWithPreviousPeriod,
    DateRangeWithPeriodCamparison
  >;

  // cache ASIN stats per account-marketplace
  private readonly dailyAsinStatsCache: AccountMarketplaceDataCache<
    AdsStatsWithPreviousPeriod,
    DateRangeWithPeriodCamparison
  >;

  // cache skuOrderStats per account-marketplace
  private readonly sellerAsinOrderStatsCache: AccountMarketplaceDataCache<Map<string, AsinOrderStats>, number>;

  // cache last 12 weeks data in map index by accountId:marketplace:useSourceMetrics
  private readonly last12WeeksAsinDataCache: Map<string, Observable<AdStatsEx[]>> = new Map();

  // cache DSP stats per dspAdvertiserId
  private readonly dspLineItemsStatsCache: DspDataCache<DspStats[], string[]>;
  private readonly dspLineItemsStatsPreviousPeriodCache: DspDataCache<DspStats[], string[]>;

  // global max value
  public static readonly maxComp = 92;

  constructor(
    private statsService: StatsApi,
    private userSelectionService: UserSelectionService,
    private accountSelectionService: AccountSelectionService,
  ) {
    this.globalDataByDateCache$ = new AccountMarketplaceWithOptionsDataCache2(
      this.userSelectionService.dateRange$,
      (account, marketplace, useSourcingMetrics, context) =>
        this.requestGlobalDailyStatsByMarketplace(account, marketplace, context, undefined, useSourcingMetrics),
      (c1, c2) => Utils.compareDateRange(c1, c2),
    );

    this.globalDataByDatesPreviousPeriodCache$ = new AccountMarketplaceWithOptionsDataCache2(
      combineLatest([this.userSelectionService.dateRange$, this.userSelectionService.periodComparison$]).pipe(
        map(([dateRange, periodComparison]) => {
          if (!periodComparison?.period) return { dateRange: undefined, dateIntervalInDays: undefined };
          const dateIntervalInDays = this.getDateIntervalInDays(periodComparison?.period);
          if (dateIntervalInDays > StatsApiClientService.maxComp)
            return { dateRange: undefined, dateIntervalInDays: undefined };
          const dateRangeGap = this.getDateIntervalInDays([periodComparison?.period[0], dateRange[0]]);
          return {
            dateRange: periodComparison?.period,
            dateIntervalInDays: dateRangeGap,
          };
        }),
      ),
      (account, marketplace, useSourcingMetrics, context) =>
        this.requestGlobalDailyStatsByMarketplace(
          account,
          marketplace,
          context.dateRange!,
          context.dateIntervalInDays,
          useSourcingMetrics,
        ),
      (c1, c2) =>
        Utils.compareDateRange(c1.dateRange!, c2.dateRange!) && c1.dateIntervalInDays == c2.dateIntervalInDays,
    ) as AccountMarketplaceWithOptionsDataCache2<AdStatsEx[], DateRangeWithDateInterval>;

    // daily stats on all marketplaces
    /**
     * @deprecated: used by old dashboard
     */
    const globalDataByDateFull$: Observable<AdStatsData> = combineLatest([
      accountSelectionService.accountGroupSelection$,
      userSelectionService.dateRange$,
      this.refreshGlobalData,
    ]).pipe(
      switchMap(([accountGroup, dateRange, _]) =>
        this.requestGlobalDailyStats(accountGroup, dateRange).pipe(
          map((data) => ({
            accountGroup,
            dateRange,
            data,
          })),
        ),
      ),
    );

    const previousPeriodGlobalDataByDateFull$ = combineLatest([
      accountSelectionService.accountGroupSelection$,
      userSelectionService.dateRange$,
      userSelectionService.periodComparison$.pipe(
        map(
          (
            x:
              | {
                  type: Comparison;
                  period: string[] | undefined;
                }
              | undefined,
          ) => x?.period,
        ),
      ),
      this.refreshGlobalData,
    ]).pipe(
      switchMap(
        ([accountGroup, dateRange, periodComparison, _]: [AccountGroup, string[], string[] | undefined, void]) => {
          if (!periodComparison) return of({ accountGroup: accountGroup, dateRange: dateRange, data: [] });
          const dateIntervalInDays = this.getDateIntervalInDays(periodComparison);
          if (dateIntervalInDays > StatsApiClientService.maxComp)
            return of({ accountGroup: accountGroup, dateRange: dateRange, data: [] });
          const dateRangeGap = this.getDateIntervalInDays([periodComparison[0], dateRange[0]]);
          return this.requestGlobalDailyStats(accountGroup, periodComparison, dateRangeGap).pipe(
            map((data) => ({ accountGroup, dateRange, data })),
          );
        },
      ),
    );

    const globalDataByDateConst$ = this.filterMarketplaceAndConvertCurrency(globalDataByDateFull$);
    const previousPeriodGlobalDataByDate$ = this.filterMarketplaceAndConvertCurrency(
      previousPeriodGlobalDataByDateFull$,
    );
    this.globalDataByDate$ = combineLatest([globalDataByDateConst$, previousPeriodGlobalDataByDate$]).pipe(
      filter(([d, p]) => {
        // assert global data and comparison data are done on the same projections
        return areCoherent(d, p);
      }),
      map(([d, p]) => ({
        data: d.data,
        previousPeriodData: p.data,
      })),
    );

    this.dailySbCreativeStats$ = this.convertCurrencyData(
      combineLatest([accountSelectionService.singleAccountMarketplaceSelection$, userSelectionService.dateRange$]).pipe(
        switchMap(([accountMarketplace, dateRange]) => {
          // split the requests in 15 days periods
          const params = splitPeriod(dateRange[0], dateRange[1], 15).map((dr) => ({
            accountId: accountMarketplace.accountId,
            minDate: dr.minDate,
            maxDate: dr.maxDate,
            marketplace: accountMarketplace.marketplace,
          }));

          const stats = params.map((p) => this.statsService.getDailySbCreativeStats(p));
          // concat for sequential calls
          return concat(...stats).pipe(
            toArray(), // aggregate the observable responses to an array,
            map((a) => ({ data: a.flat(), accountMarketplace, dateRange })),
          );
        }),
      ),
    );

    this.previousPeriodDailySbCreativeStats$ = this.convertCurrencyData(
      combineLatest([
        accountSelectionService.singleAccountMarketplaceSelection$,
        userSelectionService.dateRange$,
        userSelectionService.periodComparison$.pipe(
          map(
            (
              x:
                | {
                    type: Comparison;
                    period: string[] | undefined;
                  }
                | undefined,
            ) => x?.period,
          ),
        ),
      ]).pipe(
        switchMap(([accountMarketplace, dateRange, periodComparison]) => {
          if (!periodComparison) return of({ dateRange: [], data: [] });

          const dateIntervalInDays = this.getDateIntervalInDays(dateRange) + 1;
          if (dateIntervalInDays > StatsApiClientService.maxComp) return of({ dateRange: [], data: [] });

          return statsService
            .getDailySbCreativeStats({
              accountId: accountMarketplace.accountId,
              minDate: periodComparison[0],
              maxDate: periodComparison[1],
              marketplace: accountMarketplace.marketplace,
            })
            .pipe(
              map((data) => {
                return {
                  data: data,
                  accountMarketplace: data,
                  dateRange: dateRange,
                } as unknown as AdStatsData;
              }),
            );
        }),
      ),
    );

    this.lastSevenDaysStatsPerStrategy$ = accountSelectionService.singleAccountMarketplaceSelection$.pipe(
      switchMap((am) => this.convertCurrency(this.requestLastSevenDaysCampaignStats(am), am.marketplace)),
      map((data) => groupBy(data, (line) => line.strategyId!)),
      shareReplay(1),
    );

    this.strategyConfigHistory$ = combineLatest([
      accountSelectionService.singleAccountMarketplaceSelection$,
      userSelectionService.dateRange$,
    ]).pipe(
      switchMap(([accountMarketplace, dateRange]) =>
        statsService.getStrategyConfigurationHistory({
          accountId: accountMarketplace.accountId,
          minDate: dateRange[0],
          maxDate: dateRange[1],
          marketplace: accountMarketplace.marketplace,
        }),
      ),
      shareReplay(1), // use to cache latest value
    );

    this.campaignHourlyStatsCache = new AccountMarketplaceDataCache<AdStatsEx[], string[]>(
      (account, marketplace, dateRange) => this.requestHourlyCampaignStats(account, marketplace, dateRange),
      Utils.compareDateRange,
    );

    this.dailyAsinStatsCache = new AccountMarketplaceDataCache(
      (accountId, marketplace, context) =>
        this.requestDailyAsinStats(
          accountId,
          marketplace,
          context.dateRange,
          context.periodComparison,
          context.useSourcingMetrics,
        ),
      (c1, c2) =>
        Utils.compareDateRange(c1.dateRange, c2.dateRange) &&
        Utils.compareDateRange(c1.periodComparison, c2.periodComparison) &&
        c1.useSourcingMetrics === c2.useSourcingMetrics,
    );

    this.queryStatsCache = new AccountMarketplaceDataCache(
      (accountId, marketplace, context) =>
        this.requestQueryStats(accountId, marketplace, context.dateRange, context.periodComparison),
      (c1, c2) =>
        Utils.compareDateRange(c1.dateRange, c2.dateRange) &&
        Utils.compareDateRange(c1.periodComparison, c2.periodComparison),
    );
    this.sellerAsinOrderStatsCache = new AccountMarketplaceDataCache(
      (accountId, marketplace, today) => this.requestSellerAsinOrderStats(accountId, marketplace, today),
      (t1, t2) => Utils.formatDateForApi(new Date(t1)) == Utils.formatDateForApi(new Date(t2)),
    );
    this.dspLineItemsStatsCache = new DspDataCache(
      (dspAdvertiserId, accountId, markeplace, dateRange) =>
        this.requestDspLineItemsStats(accountId, markeplace, dspAdvertiserId, dateRange),
      this.userSelectionService.dateRange$,
      Utils.compareDateRange,
    );
    this.dspLineItemsStatsPreviousPeriodCache = new DspDataCache(
      (dspAdvertiserId, accountId, markeplace, dateRange) =>
        this.requestDspLineItemsStats(accountId, markeplace, dspAdvertiserId, dateRange).pipe(
          map((data) => {
            const dateRange = this.userSelectionService.getPreviousDateRangeStr();
            if (!dateRange) return data;
            return data;
          }),
        ),
      this.userSelectionService.periodComparison$.pipe(map((x) => x?.period ?? [])),
      Utils.compareDateRange,
    );
  }

  private getDateIntervalInDays(dateRange: string[]) {
    return Utils.getDateIntervalInDays(dateRange);
  }

  public getDailyAdSpendFee(): Observable<DailyAdSpendFee[]> {
    return this.statsService.getDailyAdSpendFee();
  }

  /**** PUBLIC INTERFACE ****/
  // The following observable provide aggregated stats
  // on the time range, currency, account and markeplace
  // selected by the user
  /**
   * @deprecated: used by old dashboard
   */
  public getGlobalDataStats(
    accountId: string,
    marketplace: Marketplace,
    useSourcingMetrics?: boolean,
  ): Observable<AdStatsEx[]> {
    return this.convertCurrency(
      this.globalDataByDateCache$.get(accountId, marketplace, useSourcingMetrics!),
      marketplace,
    );
  }

  /**
   * @deprecated: used by old dashboard
   */
  public getPreviousPeriodGlobalDataStats(
    accountId: string,
    marketplace: Marketplace,
    useSourcingMetrics?: boolean,
  ): Observable<AdStatsEx[]> {
    return this.convertCurrency(
      this.globalDataByDatesPreviousPeriodCache$.get(accountId, marketplace, useSourcingMetrics!)!,
      marketplace,
    );
  }

  public getDailyStatsPerAsin(accountId: string, marketplace: Marketplace, asins: string[]): Observable<AdStatsEx[]> {
    if (asins.length == 0) {
      return of([]);
    }
    return this.convertCurrency(
      this.userSelectionService.dateRange$.pipe(
        distinct(),
        switchMap((dr) => this.requestDailyAsinStatsForAsins(accountId, marketplace, dr, asins)),
      ),
      marketplace,
    );
  }

  public getStrategyTargetHistory(strategyId: number, currency: Currency): Observable<StrategyTargetHistory[]> {
    return this.strategyConfigHistory$.pipe(
      map((history) => indexStrategyByDate(history.filter((h) => h.strategyId === strategyId))),
      map((h: Map<string, Strategy>) => {
        const res: StrategyTargetHistory[] = [];
        for (const [date, strategy] of h) {
          const rate = marketplaceToCurrencyRate(strategy.marketplace, currency);
          res.push({
            date,
            acosTarget: strategy.acosTarget,
            minDailySpend: strategy.minDailySpend ? rate * strategy.minDailySpend : undefined,
            dailyBudget: strategy.dailyBudget ? rate * strategy.dailyBudget : undefined,
            monthlyBudget: strategy.monthlyBudget ? rate * strategy.monthlyBudget : undefined,
            computedDailyBudget: strategy.computedDailyBudget ? rate * strategy.computedDailyBudget : undefined,
            tacosTarget: strategy.tacosTarget ? rate * strategy.tacosTarget : undefined,
          });
        }
        return res;
      }),
    );
  }

  public getHourlyCampaignStats(accountId: string, marketplace: Marketplace): Observable<AdStatsEx[]> {
    return this.convertCurrency(
      this.userSelectionService.dateRange$.pipe(
        distinct(),
        switchMap((dr) => this.campaignHourlyStatsCache.get(accountId, marketplace, dr)),
      ),
      marketplace,
    );
  }

  public getLast12WeeksAsinStats(
    accountId: string,
    marketplace: Marketplace,
    useSourcingMetrics = false,
  ): Observable<AdStatsEx[]> {
    const key = `${accountId}:${marketplace}:${useSourcingMetrics}`;
    if (this.last12WeeksAsinDataCache.has(key)) {
      return this.last12WeeksAsinDataCache.get(key)!;
    }
    const isVendor = accountId.startsWith('ENTITY');
    const params = {
      accountId: accountId,
      minDate: Utils.formatDateForApiFromToday(-84),
      maxDate: Utils.formatDateForApiFromToday(-1),
      marketplace: marketplace,
    };
    const sources: Observable<AdStatsEx[]>[] = [
      isVendor
        ? this.statsService
            .getDailyVendorAsinAllSales(params)
            .pipe(map((x: DailyVendorAllSales[]) => toVendorAsinSalesAdStats(x, useSourcingMetrics)))
        : this.statsService.getDailySellerAsinAllSales(params),
      this.statsService.getDailyAsinStats(params),
      this.statsService
        .getDailyAsinTraffic(params)
        .pipe(map((data: AdStats[]) => data.map((d: AdStats) => ({ ...d, accountId, marketplace })))),
    ];

    const obs = this.convertCurrency(
      forkJoin(sources as [Observable<AdStatsEx[]>, Observable<AdStatsEx[]>, Observable<AdStatsEx[]>]).pipe(
        map(([allSales, asinStats, trafficStats]: [AdStatsEx[], AdStatsEx[], AdStatsEx[]]) =>
          allSales.concat(asinStats, trafficStats),
        ),
      ),
      marketplace,
    ).pipe(shareReplay(1));
    this.last12WeeksAsinDataCache.set(key, obs);
    return obs;
  }

  public getQueryStats(accountId: string, marketplace: Marketplace): Observable<AdsStatsWithPreviousPeriod> {
    return combineLatest([this.userSelectionService.dateRange$, this.userSelectionService.periodComparison$]).pipe(
      distinct(),
      switchMap(([dateRange, periodComparison]) =>
        // juste dateRange
        this.queryStatsCache.get(accountId, marketplace, {
          dateRange: dateRange,
          periodComparison: periodComparison?.period,
        }),
      ),
    );
  }

  public getDailyAsinsStats(
    accountId: string,
    marketplace: Marketplace,
    currency: Currency,
    useSourcingMetrics: boolean,
    dateRange: Moment[],
    periodComparison: string[] | undefined,
  ): Observable<AdsStatsWithPreviousPeriod> {
    return this.dailyAsinStatsCache
      .get(accountId, marketplace, {
        dateRange: dateRange.map((m) => Utils.formatMomentDate(m)),
        periodComparison: periodComparison,
        useSourcingMetrics: useSourcingMetrics,
      })
      .pipe(map((stats) => this.convertCurrencyWithPreviousPeriod(stats, currency, marketplace)));
  }

  public getSellerAsinOrderStats(accountId: string, marketplace: Marketplace): Observable<Map<string, AsinOrderStats>> {
    const today = Date.now();
    return this.sellerAsinOrderStatsCache.get(accountId, marketplace, today);
  }

  public reloadCacheForAccountMarketplace(accountId: string, marketplace: Marketplace, useSourcingMetrics: boolean) {
    this.globalDataByDateCache$.reload({ accountId, marketplace, useSourcingMetrics });
    this.globalDataByDatesPreviousPeriodCache$.reload({ accountId, marketplace, useSourcingMetrics });
    this.refreshGlobalData.next();
  }

  public getDspLineItemsStats(dspAdvertiserId: string, accountId: string, marketplace: Marketplace) {
    return this.convertDspCurrency(this.dspLineItemsStatsCache.get(dspAdvertiserId, accountId, marketplace)).pipe(
      shareReplay(1),
    );
  }

  public getDspLineItemsStatsPreviousPeriod(dspAdvertiserId: string, accountId: string, marketplace: Marketplace) {
    return this.convertDspCurrency(
      this.dspLineItemsStatsPreviousPeriodCache.get(dspAdvertiserId, accountId, marketplace),
    ).pipe(shareReplay(1));
  }

  public getDailyVendorInventory(params: GetStatsParams): Observable<AdStatsEx[]> {
    return forkJoin([
      this.statsService.getDailyAsinTraffic(params),
      this.statsService
        .getDailyVendorInventory(params)
        .pipe(map((x: AllVendorInventory[]) => toInventoryAdStats(x, !!params.useSourcingMetrics))),
    ]).pipe(
      map(([trafficStats, inventoryStats]) => {
        // merge traffic and inventory stats to compute soroos
        return mergeVendorTrafficAndInventoryDailyAsinStats(trafficStats, inventoryStats);
      }),
      map((data) => mergeVendorAdStatsEx(data, GroupBy.AsinDate)),
    );
  }

  /**
   * @deprecated: used by old dashboard
   */
  private requestGlobalDailyStats(
    accountGroup: AccountGroup,
    dateRange: string[],
    dateIntervalInDays?: number,
  ): Observable<AdStatsEx[]> {
    if (!accountGroup || accountGroup.getAccountMarketplaces().filter((am) => am.hasAccessToAdvertising).length == 0)
      return of([]);

    const stats = accountGroup
      .getAccountMarketplaces()
      .filter((am) => am.hasAccessToAdvertising)
      .map((am: AccountMarketplace) =>
        dateIntervalInDays
          ? this.globalDataByDatesPreviousPeriodCache$.get(am.accountId, am.marketplace, am.useSourcingMetrics!)
          : this.globalDataByDateCache$.get(am.accountId, am.marketplace, am.useSourcingMetrics!),
      );
    return combineLatest(stats).pipe(map((data) => data.flat()));
  }

  /**
   * @deprecated: used by old dashboard
   */
  private requestGlobalDailyStatsByMarketplace(
    accountId: string,
    marketplace: Marketplace,
    dateRange: string[],
    dateInterval?: number,
    useSourcingMetrics?: boolean,
  ): Observable<AdStatsEx[]> {
    if (!dateRange) return of([]);
    const params = {
      accountId: accountId,
      minDate: dateRange[0],
      maxDate: dateRange[1],
      marketplace: marketplace,
    };
    const stats: Observable<AdStatsEx[]>[] = [
      this.statsService.getDailyStats(params),
      accountId.startsWith('ENTITY') ? of([]) : this.statsService.getDailySellerAllSales(params),
      // on traffic stats, add accountId/marketplace to the data elements
      this.statsService
        .getDailyTraffic(params)
        .pipe(map((data) => data.map((d) => ({ ...d, accountId, marketplace })))),
      accountId.startsWith('ENTITY')
        ? this.statsService
            .getDailyVendorAllSales(params)
            .pipe(map((x: DailyVendorAllSales[]) => toVendorAsinSalesAdStats(x, useSourcingMetrics!)))
        : of([]),
    ];
    return forkJoin(stats).pipe(map((data) => data.flat()));
  }

  private callDailyAsinStats(isVendor: boolean, params: GetStatsParams[]): Observable<AdStatsEx[]> {
    const stats = isVendor
      ? params.map((p) => this.callDailyAsinStatsForVendor(p))
      : params.map((p) => this.callDailyAsinStatsForSeller(p));

    return concat(...stats).pipe(
      toArray(),
      map((data) => merge2dAdStats(data, GroupBy.AsinDate)),
    );
  }

  private callDailyAsinStatsForSeller(params: GetStatsParams) {
    return forkJoin([
      this.statsService.getDailyAsinStats(params),
      this.statsService.getDailyAsinTraffic(params),
      this.statsService.getDailySellerAsinAllSales(params),
    ]).pipe(map(([a, b, c]) => a.concat(b, c)));
  }

  private callDailyAsinStatsForVendor(params: GetStatsParams) {
    return forkJoin([
      this.statsService.getDailyAsinStats(params),
      this.statsService.getDailyAsinTraffic(params),
      this.statsService
        .getDailyVendorAsinAllSales(params)
        .pipe(map((x: DailyVendorAsinAllSales[]) => toVendorAsinSalesAdStats(x, !!params.useSourcingMetrics))),
      this.statsService.getVendorNetProductMargin(params),
    ]).pipe(map(([a, b, c, d]) => a.concat(b, c, d)));
  }

  private requestDailyAsinStats(
    accountId: string,
    marketplace: Marketplace,
    dateRange: string[],
    periodComparison?: string[],
    useSourcingMetrics?: boolean,
  ): Observable<AdsStatsWithPreviousPeriod> {
    const dateIntervalInDays = this.getDateIntervalInDays(dateRange);
    // reduce + concat = flatMap (which is not supported with this ES version)
    const params = splitPeriod(dateRange[0], dateRange[1], 15).map((dr) => ({
      accountId: accountId,
      minDate: dr.minDate,
      maxDate: dr.maxDate,
      marketplace: marketplace,
      useSourcingMetrics: useSourcingMetrics,
    }));

    // concat for sequential calls
    const stats$: Observable<AdStatsEx[]> = this.callDailyAsinStats(accountId.startsWith('ENTITY'), params);

    let previousPeriodStats$: Observable<AdStatsEx[]> = of([]);

    if (periodComparison) {
      const dateGap = this.getDateIntervalInDays([periodComparison[0], dateRange[0]]);
      if (dateIntervalInDays <= StatsApiClientService.maxComp) {
        const previousPeriodParams = splitPeriod(periodComparison[0], periodComparison[1], 15).map((dr) => ({
          accountId: accountId,
          minDate: dr.minDate,
          maxDate: dr.maxDate,
          marketplace: marketplace,
        }));

        previousPeriodStats$ = this.callDailyAsinStats(accountId.startsWith('ENTITY'), previousPeriodParams);
      }
    }

    return combineLatest([stats$, previousPeriodStats$]).pipe(map(([s, p]) => ({ data: s, previousPeriodData: p })));
  }

  private requestDailyAsinStatsForAsins(
    accountId: string,
    marketplace: Marketplace,
    dateRange: string[],
    asins: string[],
  ): Observable<AdStatsEx[]> {
    // batch calls of 300 ASINs
    const chunkSize = 300;
    const chunks: string[][] = [];
    for (let i = 0; i < asins.length; i += chunkSize) {
      const chunk = asins.slice(i, i + chunkSize);
      chunks.push(chunk);
    }

    const params = chunks.flatMap((chunk) => ({
      accountId: accountId,
      minDate: dateRange[0],
      maxDate: dateRange[1],
      marketplace: marketplace,
      asin: chunk,
    }));

    return this.callDailyAsinStats(accountId.startsWith('ENTITY'), params);
  }

  private requestHourlyCampaignStats(
    accountId: string,
    marketplace: Marketplace,
    dateRange: string[],
    dateInterval?: number,
  ): Observable<AdStatsEx[]> {
    // reduce + concat = flatMap (which is not supported with this ES version)
    const params = splitPeriod(dateRange[0], dateRange[1], 15).map((dr) => ({
      accountId: accountId,
      minDate: dr.minDate,
      maxDate: dr.maxDate,
      marketplace: marketplace,
    }));

    const stats = params.map((p) => this.statsService.getHourlyCampaignStats(p));
    // concat for sequential calls
    return concat(...stats).pipe(
      toArray(), // aggregate the observable responses to an array
      map((data) => data.flat()),
    );
  }

  private requestLastSevenDaysCampaignStats(accountMarketPlace: AccountMarketplace): Observable<AdStatsEx[]> {
    return this.statsService.getDailyPlacementStats({
      accountId: accountMarketPlace.accountId,
      minDate: Utils.formatDateForApiFromToday(-8),
      maxDate: Utils.formatDateForApiFromToday(-1),
      marketplace: accountMarketPlace.marketplace,
    });
  }

  private convertCurrency(data$: Observable<AdStatsEx[]>, marketplace: Marketplace): Observable<AdStatsEx[]> {
    return combineLatest([data$, this.userSelectionService.selectedCurrency$]).pipe(
      map(([data, currency]) => convertToCurrency(data, currency, marketplace)!),
      shareReplay(1),
    );
  }

  private convertDspCurrency(data$: Observable<DspStats[]>): Observable<DspStats[]> {
    return combineLatest([data$, this.userSelectionService.selectedCurrency$]).pipe(
      map(([data, currency]) => convertDspStatsToCurrency(data, currency)!),
      shareReplay(1),
    );
  }

  private convertCurrencyData(data$: Observable<AdStatsData>): Observable<AdStatsData> {
    return combineLatest([data$, this.userSelectionService.selectedCurrency$]).pipe(
      map(([data, currency]) => ({ ...data, data: convertToCurrency(data.data, currency)!, currency: currency })),
      shareReplay(1),
    );
  }

  private convertCurrencyWithPreviousPeriod(
    data: AdsStatsWithPreviousPeriod,
    currency: Currency,
    marketplace: Marketplace,
  ): AdsStatsWithPreviousPeriod {
    return {
      data: convertToCurrency(data.data, currency, marketplace)!,
      previousPeriodData: convertToCurrency(data.previousPeriodData, currency, marketplace)!,
    };
  }

  private filterMarketplaceAndConvertCurrency(data$: Observable<AdStatsData>): Observable<AdStatsData> {
    const dataLocalCurrency$ = combineLatest([data$, this.accountSelectionService.accountMarketplacesSelection$]).pipe(
      map(([data, am]) => {
        return { ...data, data: data.data.filter((x) => am.some((a) => a.marketplace == x.marketplace)) };
      }),
    );
    return this.convertCurrencyData(dataLocalCurrency$);
  }

  private requestQueryStats(
    accountId: string,
    marketplace: Marketplace,
    dateRange: string[],
    periodComparison: string[] | undefined,
  ) {
    const stats = splitPeriod(dateRange[0], dateRange[1], 15).map((dr) =>
      this.statsService.getQueryAsinStats({
        accountId: accountId,
        marketplace: marketplace,
        minDate: dr.minDate,
        maxDate: dr.maxDate,
      }),
    );
    let previousPeriodStats: Observable<AdStatsEx[]>[] = [of([])];
    if (periodComparison) {
      const dateIntervalInDays = this.getDateIntervalInDays(dateRange);
      if (dateIntervalInDays <= StatsApiClientService.maxComp) {
        previousPeriodStats = splitPeriod(periodComparison[0], periodComparison[1], 15).map((dr) =>
          this.statsService.getQueryAsinStats({
            accountId: accountId,
            marketplace: marketplace,
            minDate: dr.minDate,
            maxDate: dr.maxDate,
          }),
        );
      }
    }
    const adStats$ = this.convertCurrency(
      // concat for sequential calls
      concat(...stats).pipe(
        toArray(), // aggregate the observable responses to an array
        // reduce + concat = flatMap
        map((stat) =>
          stat
            .reduce((acc, s) => acc.concat(s), [])
            .map((a) => {
              a.marketplace = marketplace;
              return a;
            }),
        ),
      ),
      marketplace,
    ).pipe(
      map(
        // reaggregate data per asin/query
        (stats) => statGroupByKey(stats, (d) => `${d.asin}_${d.queryType}_${d.query}`, mergeSeveralDates, false),
      ),
    );
    const previousAdStats$ = this.convertCurrency(
      // concat for sequential calls
      concat(...previousPeriodStats).pipe(
        toArray(), // aggregate the observable responses to an array
        // reduce + concat = flatMap
        map((stat) =>
          stat
            .reduce((acc, s) => acc.concat(s), [])
            .map((a) => {
              a.marketplace = marketplace;
              return a;
            }),
        ),
      ),
      marketplace,
    ).pipe(
      map(
        // reaggregate data per asin/query
        (stats) => statGroupByKey(stats, (d) => `${d.asin}_${d.queryType}_${d.query}`, mergeSeveralDates, false),
      ),
    );
    return combineLatest([adStats$, previousAdStats$]).pipe(map(([s, p]) => ({ data: s, previousPeriodData: p })));
  }

  private requestSellerAsinOrderStats(
    accountId: string,
    marketplace: Marketplace,
    today: number,
  ): Observable<Map<string, AsinOrderStats>> {
    const maxDate = Utils.formatDateForApi(new Date(today));
    const lastWeek = Utils.formatDateForApi(new Date(today - 7 * 24 * 60 * 60 * 1000));
    const lastMonth = Utils.formatDateForApi(new Date(today - 30 * 24 * 60 * 60 * 1000));

    return this.statsService
      .getDailySellerAsinAllSales({
        accountId: accountId,
        marketplace: marketplace,
        maxDate,
        minDate: lastMonth,
      })
      .pipe(
        map((stats: AdStatsEx[]) => {
          const map = new Map<string, AsinOrderStats>();
          for (const stat of stats) {
            let acc = map.get(stat.asin!);
            if (acc === undefined) {
              acc = new AsinOrderStats(stat.asin!);
              map.set(stat.asin!, acc);
            }
            acc.orders30d += stat.allOrderedUnits!;
            if (stat.date! >= lastWeek) acc.orders7d += stat.allOrderedUnits!;
          }
          return map;
        }),
      );
  }

  private requestDspLineItemsStats(
    accountId: string,
    marketplace: Marketplace,
    dspAdvertiserId: string,
    dateRange: string[],
  ): Observable<DspStats[]> {
    if (dateRange.length < 2) {
      return of([]);
    }
    return this.statsService
      .getDailyDspLineItemStats({
        accountId,
        minDate: dateRange[0],
        maxDate: dateRange[1],
        marketplace,
        dspAdvertiserId,
      })
      .pipe(
        catchAjaxError(),
        map((data) => {
          // set marketplace currency
          const currency = Marketplaces[marketplace].currency;
          return data.map((d) => ({ ...d, currency }));
        }),
      );
  }
}

export type StrategyTargetHistory = {
  date?: string;
  acosTarget?: number;
  minDailySpend?: number;
  dailyBudget?: number;
  monthlyBudget?: number;
  computedDailyBudget?: number;
  tacosTarget?: number;
};

export type AdStatsWithTargetHistory = AdStatsEx & StrategyTargetHistory;

export function groupBy<K>(data: AdStatsEx[], key: (line: AdStatsEx) => K, order?: K[]): Map<K, AdStatsEx> {
  const map = new Map<K, AdStatsEx>();
  if (order) for (const k of order) map.set(k, {}); // init item with empty stats in initial order
  for (const line of data) {
    const k = key(line);
    const stat = map.get(k);
    if (!stat) map.set(k, { ...line });
    else addAdStats(stat, line);
  }
  return map;
}

export function arrayGroupBy<K>(data: AdStatsEx[], key: (line: AdStatsEx) => K): Map<K, AdStatsEx[]> {
  const map = new Map<K, AdStatsEx[]>();
  for (const line of data) {
    const k = key(line);
    const stat = map.get(k);
    if (!stat) map.set(k, [line]);
    else stat.push(line);
  }
  return map;
}

// TODO use a comparator instead of a string key
export function statGroupByKey<T extends CurrencyStat>(
  data: T[],
  key: (x: T) => string,
  sumFunction: (x: T, y: T) => T,
  sort = true,
): T[] {
  const dataByKey: Map<string, T> = new Map();

  data.forEach((d) => {
    const dataKey: string = key(d);
    const existing: T = dataByKey.get(dataKey)!;
    if (existing) sumFunction(existing, d);
    else dataByKey.set(dataKey, { ...d });
  });

  const res = Array.from(dataByKey.values());
  if (sort) return res.sort((a, b) => Utils.strOrderCompare(key(a), key(b)));
  return res;
}

class DateRange {
  public readonly minDate: string;
  public readonly maxDate: string;

  constructor(minDate: string, maxDate: string) {
    this.minDate = minDate;
    this.maxDate = maxDate;
  }
}

/**
 * Split a date range in smaller date range of a given period
 * @param minDate Min date of the range in format `YYYY-MM-DD`
 * @param maxDate Max date of the range in format `YYYY-MM-DD`
 * @param period Period in days
 * @returns List of sub date ranges
 */
export function splitPeriod(minDate: string, maxDate: string, period: number): DateRange[] {
  // split a date range in smaller date range of a given period
  const result: DateRange[] = [];
  let max = Utils.toMoment(maxDate);
  const min = Utils.toMoment(minDate);
  let intermediate = Utils.toMoment(maxDate);
  intermediate = intermediate.subtract(period, 'day');
  intermediate = intermediate < min ? min : intermediate;
  while (intermediate > min) {
    result.push(new DateRange(Utils.formatMomentDate(intermediate), Utils.formatMomentDate(max)));
    max = intermediate.clone();
    max = max.subtract(1, 'day');
    intermediate = intermediate.subtract(period + 1, 'day');
    intermediate = intermediate < min ? min : intermediate;
  }
  result.push(new DateRange(Utils.formatMomentDate(min), Utils.formatMomentDate(max)));
  return result;
}

export interface AdStatsData {
  accountGroup?: AccountGroup;
  dateRange: string[];
  data: AdStatsEx[];
  currency?: Currency;
  marketplaces?: Map<Marketplace, boolean>;
  accountMarketplace?: AccountMarketplace;
}

export class AsinOrderStats {
  public orders7d: number;
  public orders30d: number;

  constructor(public readonly asin: string) {
    this.orders7d = 0;
    this.orders30d = 0;
  }
}

export type AdsStatsWithPreviousPeriod = {
  data: AdStatsEx[];
  previousPeriodData: AdStatsEx[];
};

function areCoherent(first: AdStatsData, other: AdStatsData) {
  return (
    first.accountGroup!.id == other.accountGroup!.id &&
    first.dateRange[0] == other.dateRange[0] &&
    first.dateRange[1] == other.dateRange[1] &&
    first.currency == other.currency &&
    ((first.marketplaces == undefined && other.marketplaces == undefined) ||
      (first.marketplaces!.size == other.marketplaces!.size &&
        Array.from(first.marketplaces!.keys()).every((k) => first.marketplaces!.get(k) == other.marketplaces!.get(k))))
  );
}

type DateRangeWithDateInterval = { dateRange: string[]; dateIntervalInDays: number; useSourcingMetrics?: boolean };
type DateRangeWithPeriodCamparison = {
  dateRange: string[];
  periodComparison: string[] | undefined;
  useSourcingMetrics?: boolean;
};

/**** PUBLIC UTILITY FUNCTIONS ****/

export function indexStrategyByDate(data: Strategy[]): Map<string, Strategy> {
  const map = new Map<string, Strategy>();
  for (const line of data) {
    if (line.state == StrategyStateEnum.ENABLED) map.set(line.date!, { ...line });
  }
  return map;
}

type AccountMarketplaceWithOptionsCacheKey = {
  accountId: string;
  marketplace: Marketplace;
  useSourcingMetrics: boolean;
};

function accountMarketplaceWithOptionsCacheKey(
  accountId: string,
  marketplace: Marketplace,
  useSourcingMetrics: boolean,
) {
  return `${accountId}:${marketplace}:${useSourcingMetrics}`;
}

/**
 * A convenient class to cache data
 * It holds a "context" state which will trigger a reload of keys when it changes
 */
export abstract class SimpleDataCache<K, T, C> {
  // cache the observables
  private readonly cache: Map<string, Observable<T>> = new Map();
  // used to trigger reload of a specific key
  private readonly reload$: Map<string, BehaviorSubject<void>> = new Map();

  protected abstract serializeKey(key: K): string;

  protected abstract loadKey(key: K, context: C): Observable<T>;

  protected abstract compareContext(c1: C, c2: C): boolean;

  public constructor(protected context: Observable<C>) {}

  public getFromCache(key: K): Observable<T> {
    const keyStr = this.serializeKey(key);
    if (this.cache.has(keyStr)) {
      return this.cache.get(keyStr)!;
    }
    const reloadKey$ = new BehaviorSubject<void>(void 0);
    this.reload$.set(keyStr, reloadKey$);
    const obs = combineLatest([
      this.context.pipe(distinctUntilChanged((a, b) => this.compareContext(a, b))),
      reloadKey$,
    ]).pipe(
      switchMap(([context]) => this.loadKey(key, context)),
      shareReplay(1), // cache the last value
    );
    this.cache.set(keyStr, obs);
    return obs;
  }

  public reload(key: K) {
    const keyStr = this.serializeKey(key);
    if (!this.cache.has(keyStr) || !this.reload$.has(keyStr)) {
      return;
    }
    this.reload$.get(keyStr)!.next();
  }
}

interface GetStatsParams extends GetDailyAsinStatsRequest {
  useSourcingMetrics?: boolean;
}

/**
 * A convenient class to cache data per account marketplace.
 * It holds a "context" state which will invalidate the whole cache when it changes
 */
class AccountMarketplaceWithOptionsDataCache2<T, C> extends SimpleDataCache<
  AccountMarketplaceWithOptionsCacheKey,
  T,
  C
> {
  constructor(
    context: Observable<C>,
    private keyLoader: (
      account: string,
      marketplace: Marketplace,
      useSourcingMetrics: boolean,
      context: C,
    ) => Observable<T>,
    private contextComparer: (c1: C, c2: C) => boolean,
  ) {
    super(context);
  }

  protected serializeKey({
    accountId,
    marketplace,
    useSourcingMetrics,
  }: AccountMarketplaceWithOptionsCacheKey): string {
    return accountMarketplaceWithOptionsCacheKey(accountId, marketplace, useSourcingMetrics);
  }

  protected loadKey(
    { accountId, marketplace, useSourcingMetrics }: AccountMarketplaceWithOptionsCacheKey,
    context: C,
  ): Observable<T> {
    return this.keyLoader(accountId, marketplace, useSourcingMetrics, context);
  }

  protected compareContext(c1: C, c2: C): boolean {
    return this.contextComparer(c1, c2);
  }

  public get(accountId: string, marketplace: Marketplace, useSourcingMetrics: boolean): Observable<T> {
    return super.getFromCache({ accountId, marketplace, useSourcingMetrics });
  }
}

class DspDataCache<T, C> extends SimpleDataCache<
  { dspAdvertiserId: string; accountId: string; marketplace: Marketplace },
  T,
  C
> {
  constructor(
    private keyLoader: (
      dspAdvertiserId: string,
      accountId: string,
      marketplace: Marketplace,
      context: C,
    ) => Observable<T>,
    context: Observable<C>,
    private contextComparer: (c1: C, c2: C) => boolean,
  ) {
    super(context);
  }

  protected serializeKey(key: { dspAdvertiserId: string; accountId: string; marketplace: Marketplace }): string {
    return key.dspAdvertiserId;
  }

  protected loadKey(
    key: { dspAdvertiserId: string; accountId: string; marketplace: Marketplace },
    context: C,
  ): Observable<T> {
    return this.keyLoader(key.dspAdvertiserId, key.accountId, key.marketplace, context);
  }

  protected compareContext(c1: C, c2: C): boolean {
    return this.contextComparer(c1, c2);
  }

  public get(dspAdvertiserId: string, accountId: string, marketplace: Marketplace): Observable<T> {
    return super.getFromCache({ dspAdvertiserId, accountId, marketplace });
  }
}
