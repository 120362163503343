import { Component, Input } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faStar as faEmptyStar } from "@fortawesome/free-regular-svg-icons";
import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";

enum Star {
  Empty = 0,
  Half = 1,
  Full = 2,
}

@Component({
  selector: "app-review-score",
  templateUrl: "./review-score.component.html",
  standalone: true,
  imports: [FontAwesomeModule],
})
export class ReviewScoreComponent implements ICellRendererAngularComp {
  readonly faStar = faStar;
  readonly faEmptyStar = faEmptyStar;
  readonly faHalfStar = faStarHalfAlt;

  readonly Star = Star;
  stars?: Star[];
  _score?: number;

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.score = params.value;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  @Input()
  public set score(value: number) {
    this._score = value;
    this.stars = Array<Star>(this.score).fill(Star.Empty);
    if (value < 0 || value > 5) {
      return;
    }
    const x = Math.round(value * 2);
    const numberOfStars = Math.floor(x / 2);
    const halfStar = x % 2 == 1;
    for (let i = 0; i < 5; i++) {
      if (i < numberOfStars) {
        this.stars[i] = Star.Full;
      } else if (i == numberOfStars && halfStar) {
        this.stars[i] = Star.Half;
      } else {
        this.stars[i] = Star.Empty;
      }
    }
  }
}
