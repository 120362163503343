import {
  AlgoMode,
  AudienceTargeting,
  CampaignType,
  Marketplace,
  Strategy,
  StrategyAsin,
  StrategyKeywords,
  StrategyStateEnum,
  StrategyTactic,
  StrategyType,
  Targeting,
} from '../api-client';

export type AlgoTarget = {
  strategyId: number;
  strategyName: string;
  accountId: string;
  marketplace: Marketplace;
  algorithm: AlgoMode;
  acosTarget?: number;
  dailyBudget?: number;
  suggestedBid?: number;
  monthlyBudget?: number;
  nextMonthlyBudget?: number;
  tacosTarget?: number;
  today?: string;
};

export const MaxPriority = Number.MAX_SAFE_INTEGER;
export const MinPriority = Number.MIN_SAFE_INTEGER;

export class StrategyEx implements Strategy {
  constructor(strategy: Strategy) {
    this.accountId = strategy.accountId;
    this.marketplace = strategy.marketplace;
    this.strategyId = strategy.strategyId!;
    this.asins = strategy.asins!;
    this.topOfSearchRankings = strategy.topOfSearchRankings!;
    this.acosTarget = strategy.acosTarget!;
    this.state = strategy.state;
    this.campaignType = strategy.campaignType;
    this.algoMode = strategy.algoMode;
    this.algorithm = strategy.algoMode;
    this.suggestedBid = strategy.suggestedBid!;
    this.dailyBudget = strategy.dailyBudget;
    this.monthlyBudget = strategy.monthlyBudget;
    this.nextMonthlyBudget = strategy.nextMonthlyBudget;
    this.today = strategy.today;
    this.minDailySpend = strategy.minDailySpend;
    this.autoAlgoExplorationEnabled = !strategy.disableOtherQueries;
    this.autoTargetCampainEnabled = !strategy.disableAutoSegment;
    this.productTargetingEnabled = !strategy.disableProductSegment;
    this.defaultStrategy = strategy.defaultStrategy;
    this.name = strategy.name;
    this.strategyLabel = strategy.strategyLabel;
    this.primeDayBoost = strategy.primeDayBoost!;
    this.activatePrimeDayBoost = false;
    this.tactics = strategy.tactics;
    this.audienceTargetings = strategy.audienceTargetings;
    this.daypartingPauseHour = strategy.daypartingPauseHour!;
    this.daypartingReactivationHour = strategy.daypartingReactivationHour!;
    this.brandEntityId = strategy.brandEntityId!;
    this.strategyType = strategy.strategyType!;
    this.strategyGroupId = strategy.strategyGroupId;
    this.targetings = strategy.targetings!;
    this.priority = strategy.priority;
    this.strategyName = this.name ?? 'Unnamed Strategy';
    // round to 2 decimals
    this.computedDailyBudget = Math.round((strategy.computedDailyBudget! + Number.EPSILON) * 100) / 100;
    this.asinIsolation = strategy.asinIsolation!;
    this.constraint = strategy.constraint;
  }
  accountId: string;
  algorithm: string;
  marketplace: Marketplace;
  strategyId: number;
  asins: StrategyAsin[];
  topOfSearchRankings: StrategyKeywords[];
  acosTarget: number;
  tacosTarget?: number;
  state: StrategyStateEnum;
  campaignType: CampaignType;
  algoMode: AlgoMode;
  suggestedBid: number;
  dailyBudget?: number;
  monthlyBudget?: number;
  nextMonthlyBudget?: number;
  today?: string;
  minDailySpend?: number;
  autoTargetCampainEnabled: boolean;
  autoAlgoExplorationEnabled: boolean;
  productTargetingEnabled: boolean;
  defaultStrategy: boolean;
  name?: string;
  strategyName: string; // required for algoTarget change
  strategyLabel?: string;
  primeDayBoost: number;
  activatePrimeDayBoost: boolean;
  constraint?: number;
  tactics: Array<StrategyTactic>;
  audienceTargetings: Array<AudienceTargeting>;
  daypartingPauseHour: number | null;
  daypartingReactivationHour: number | null;
  brandEntityId: string;
  strategyType: StrategyType;
  strategyGroupId?: number;
  targetings: Targeting[];
  priority?: number;
  computedDailyBudget?: number;
  asinIsolation: boolean;

  get disableOtherQueries() {
    return !this.autoAlgoExplorationEnabled;
  }

  get disableAutoSegment() {
    return !this.autoTargetCampainEnabled;
  }

  get disableProductSegment() {
    return !this.productTargetingEnabled;
  }

  public compareState(other: StrategyEx): number {
    return this.state <= other.state ? -1 : 1;
  }
}

export const AlgoModeStr: { [key in AlgoMode]: { description: string; title: string; shortDescription: string } } = {
  [AlgoMode.PRODUCT_LAUNCH]: {
    description: 'Force Product Visibility',
    title: 'Constant Bid',
    shortDescription: 'algo-mode-selection.constant_bid',
  },
  [AlgoMode.ACOS_TARGET]: {
    description: 'Optimize Sales with ACOS Target',
    title: 'ACOS Target',
    shortDescription: 'algo-mode-selection.acos_target',
  },
  [AlgoMode.MONTHLY_BUDGET_TARGET]: {
    description: 'Optimize Sales with Monthly Budget Target',
    title: 'Monthly Budget Target',
    shortDescription: 'algo-mode-selection.monthly_budget_target',
  },
  [AlgoMode.TACOS_TARGET]: {
    description: 'Optimize Sales with TACOS Target',
    title: 'TACOS Target',
    shortDescription: 'algo-mode-selection.tacos_target',
  },
};

export const StrategyTypeStr: { [key in StrategyType]: string } = {
  [StrategyType.LEGACY]: '',
  [StrategyType.PRODUCT]: 'Main',
  [StrategyType.BRAND]: 'Brand defense',
  [StrategyType.KEYWORD]: 'Focus',
};
