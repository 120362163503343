import { Clipboard } from "@angular/cdk/clipboard";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AsinService } from "@front/m19-services";
import { BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { forkJoin, of, switchMap } from "rxjs";
import { Marketplace } from "@front/m19-api-client";
import { TranslocoService } from "@jsverse/transloco";

interface ItemToValidate {
  asin: string;
  title: string;
  brand: string;
}

@UntilDestroy()
@Component({
  selector: "app-selling-partner-integration-validation-modal",
  templateUrl: "./selling-partner-integration-validation-modal.component.html",
  styleUrls: ["./selling-partner-integration-validation-modal.component.scss"],
})
export class SellingPartnerIntegrationValidationModalComponent implements OnInit {
  @Input({ required: true })
  accountId!: string;

  @Input({ required: true })
  accountName!: string;

  @Input({ required: true })
  marketplace!: Marketplace;

  @Output()
  validated = new EventEmitter<void>();

  @Output()
  rejected = new EventEmitter<void>();

  dataSource = new MatTableDataSource<ItemToValidate>([]);
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  brands: string[] = [];
  loading = true;

  readonly faSearch = faSearch;
  readonly faCopy = faCopy;
  readonly displayedColumns = ["productImg", "productTitle", "productBrand"];

  constructor(
    private modalService: BsModalService,
    private alertService: ToastrService,
    private asinService: AsinService,
    private clipboard: Clipboard,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.asinService
      .getVendorAsinsForValidation(this.accountId, this.marketplace)
      .pipe(
        untilDestroyed(this),
        switchMap((offers) => {
          if (!offers || offers.length == 0) {
            return of([]);
          }
          return forkJoin(
            offers.map((offer) => this.asinService.getProductWithMarketplace(offer.asin!, this.marketplace)),
          );
        }),
      )
      .subscribe((products) => {
        this.dataSource.data = products.map((p) => ({ asin: p.asin!, title: p.title!, brand: p.brand! }));
        this.brands = Array.from(new Set(products.map((p) => p.brand!).filter((b) => !!b && b.length > 0)));
        this.loading = false;
      });
    this.dataSource.filterPredicate = (data, filter) => {
      const regexp = new RegExp(filter, "i");
      return regexp.test(data.asin) || regexp.test(data.title);
    };
    this.dataSource.sortingDataAccessor = (item, property): string | number => {
      if (property == "productImg") return item.asin;
      if (property == "productTitle") return item.title;
      if (property == "productBrand") return item.brand;
      return 0;
    };
  }

  filterCatalog(filter: string) {
    this.dataSource.filter = filter.trim().toUpperCase();
  }

  validate() {
    this.validated.emit();
    this.modalService.hide();
  }

  reject() {
    this.rejected.emit();
    this.modalService.hide();
  }

  closeModal() {
    this.modalService.hide();
  }

  copyToClipboard(str: string) {
    this.clipboard.copy(str);
    this.alertService.success(this.translocoService.translate("common.copied_to_clipboard"));
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
