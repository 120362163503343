import { NgModule } from "@angular/core";
import { NotificationCardSwitchComponent } from "@m19-board/notifications/notification-card-switch.component";
import { NotificationCardComponent } from "@m19-board/notifications/notification-card.component";
import { TranslocoDirective } from "@jsverse/transloco";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { MatTooltip } from "@angular/material/tooltip";
import { DatePipe, NgClass } from "@angular/common";
import { IBadgeComponent, IButtonComponent } from "@front/m19-ui";
import {
  NotificationCardBidderIssueComponent,
  NotificationCardCampaignOverlapComponent,
  NotificationCardDeletedBrandAssetComponent,
  NotificationCardStrategyOverlapComponent,
  NotificationInvalidSellingPartnerAccessComponent,
} from "@m19-board/notifications/notification-cards.component";
import { StrategyLinkModule } from "@m19-board/strategies/strategy-link/strategy-link.module";

@NgModule({
  declarations: [
    NotificationCardSwitchComponent,
    NotificationCardComponent,
    NotificationCardBidderIssueComponent,
    NotificationCardCampaignOverlapComponent,
    NotificationCardDeletedBrandAssetComponent,
    NotificationInvalidSellingPartnerAccessComponent,
    NotificationCardStrategyOverlapComponent,
  ],
  exports: [
    NotificationCardSwitchComponent,
    NotificationCardComponent,
    NotificationCardBidderIssueComponent,
    NotificationCardCampaignOverlapComponent,
    NotificationCardDeletedBrandAssetComponent,
    NotificationInvalidSellingPartnerAccessComponent,
    NotificationCardStrategyOverlapComponent,
  ],
  imports: [
    TranslocoDirective,
    FaIconComponent,
    MatTooltip,
    NgClass,
    IBadgeComponent,
    DatePipe,
    IButtonComponent,
    StrategyLinkModule,
  ],
})
export class NotificationsModule {}
