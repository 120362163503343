<div class="modal-primary" *transloco="let t">
  <div class="modal-header">
    <h3 class="modal-title text-center">
      {{ t("sd-strategy-creation.asin_selected", { count: asins.length }) }}
    </h3>

    <a><h3 class="pull-right" aria-hidden="true" (click)="ref.hide()">&times;</h3></a>
  </div>
  <div class="modal-body">
    @if (asins.length !== 0) {
      <div class="pg-container card-perso collapse-card left-child right-child card">
        <div class="card-perso-header collapse-card-header top-child card-header header pb-0 pl-1 pr-1">
          <span class="w-full">
            <span class="search-bar input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <fa-icon [icon]="faSearch"></fa-icon>
                </span>
              </div>
              <input
                type="text"
                class="search-bar simple-input-prepend form-control"
                placeholder="{{ t('drag-drop-asins.search_by_asin_or_product_title') }}"
                (input)="setFilter(getValueFromInputEvent($event))"
              />
            </span>
          </span>
        </div>
        <div class="pg-asins-list card-perso-body bottom-child card-body mb-3 mt-2 pl-1 pr-1">
          <app-spinner [display]="loading"></app-spinner>
          @for (asin of shownAsins; track asin) {
            <div>
              <div class="asin card-perso left-child right-child border-shadow card mb-2">
                <div class="asin card-perso-header top-child bottom-child card-header w-full pb-2 pl-3 pt-2">
                  <span class="picture-content">
                    <a [href]="asin | amazonAsinUrlPipe: marketplace" target="_blank">
                      <app-product-thumbnail
                        [asin]="asin"
                        [marketplace]="marketplace"
                        [smallImg]="true"
                      ></app-product-thumbnail>
                    </a>
                  </span>
                  <p class="w-85 mb-2 inline-block truncate pl-3" matTooltip="{{ productTitles![asin] }}">
                    <a class="!text-gray-800" [href]="asin | amazonAsinUrlPipe: marketplace" target="_blank">
                      {{ asin }} - {{ productTitles![asin] }}
                    </a>
                  </p>
                  @if (asinLabels.has(asin)) {
                    <IBadge [label]="asinLabels.get(asin)" size="xs" />
                  }
                </div>
              </div>
            </div>
          }
        </div>
        @if (filteredAsins.length > itemsShown) {
          <mat-paginator
            class="[&_*]:!text-sm"
            [pageSizeOptions]="[]"
            [hidePageSize]="true"
            [pageSize]="itemsShown"
            [pageIndex]="pageEvent.pageIndex"
            [length]="filteredAsins.length"
            (page)="changeContent($event)"
            showFirstLastButtons
          />
        }
      </div>
    }
    @if (asins.length === 0) {
      <p class="default-message m-2">
        {{ t("product-list-detail-popup.no_asin_to_display") }}
        <ng-content select="[noAsin]"></ng-content>
      </p>
    }
  </div>
</div>
