import { Component } from "@angular/core";
import { AuthService } from "@front/m19-services";
import { DefaultLayoutComponent } from "@m19-board/containers/default-layout";
import { V2LayoutComponent } from "@m19-board/containers/v2-layout/v2-layout.component";

@Component({
  selector: "app-layout-selector",
  template: `@if (uiVersion === 0) {
      <app-default-layout></app-default-layout>
    } @else {
      <app-v2-layout></app-v2-layout>
    }`,
  imports: [DefaultLayoutComponent, V2LayoutComponent],
  standalone: true,
})
export class LayoutSelectorComponent {
  uiVersion = 0;

  // Add component logic here
  constructor(authService: AuthService) {
    authService.loggedUser$.subscribe((user) => {
      this.uiVersion = user.uiVersion;
    });
  }
}
