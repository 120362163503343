import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  CampaignType,
  CreateStrategyGroupRequest,
  Marketplace,
  Strategy,
  StrategyStateEnum,
  StrategyType,
} from "@front/m19-api-client";
import { AsinService, Constant, SpStrategiesService, StrategyService } from "@front/m19-services";
import { ICON_UPLOAD } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";
import { firstValueFrom } from "rxjs";
import {
  BulkError,
  BulkImportService,
  isStrategyBulkError,
  StrategyBulkOperations,
  StrategyCreation,
  StrategyUpdate,
} from "./bulk-import.service";

import { Catalog, isUpdating, StrategyEx, StrategyGroupEx } from "@front/m19-models";

@UntilDestroy()
@Component({
  selector: "app-strategy-bulk-upload-modal",
  templateUrl: "./strategy-bulk-upload-modal.component.html",
  styleUrls: ["./strategy-bulk-upload-modal.component.scss"],
})
export class StrategyBulkUploadModalComponent implements OnInit {
  @Input()
  accountId: string | undefined;

  @Input()
  marketplace: Marketplace | undefined;

  @Input()
  organizationId: number | undefined;

  @Input()
  campaignType: CampaignType | undefined;

  @Input()
  strategies: StrategyEx[] = [];

  @Input()
  bulkData: string | undefined;

  @Output()
  public strategyBulkOperations = new EventEmitter<StrategyBulkOperations>();

  textAreaPlaceholder = "";
  fileUploadError = "";
  readonly ICON_UPLOAD = ICON_UPLOAD;
  private catalog: Catalog | undefined;
  private strategyGroupIndex: Map<number, StrategyGroupEx> = new Map();

  constructor(
    public bsModalRef: BsModalRef,
    private asinService: AsinService,
    private bulkImportService: BulkImportService,
    private strategyService: StrategyService,
    private spStrategyService: SpStrategiesService,
  ) {}

  ngOnInit(): void {
    this.asinService
      .getCatalog(this.accountId!, this.marketplace!)
      .pipe(untilDestroyed(this))
      .subscribe((c) => (this.catalog = c));
    this.textAreaPlaceholder = this.bulkImportService.getTextAreaPlaceholderText(this.campaignType!);

    this.spStrategyService
      .getStrategyGroups(this.accountId!, this.marketplace!)
      .pipe(untilDestroyed(this))
      .subscribe((strategyGroupIndex) => {
        this.strategyGroupIndex = strategyGroupIndex;
      });
  }

  isUploadButtonDisabled(content: string): boolean {
    return !content || content.trim().length === 0;
  }

  private getStrategyIndex() {
    const strategyIndex = new Map<number, StrategyEx>();
    for (const s of this.strategies) {
      strategyIndex.set(s.strategyId, s);
    }
    return strategyIndex;
  }

  private getStrategyGroupIndex() {
    const strategyGroupIndex = new Map<number, StrategyGroupEx>();
    // copy of strategy group objects to avoid modification of the original ones
    for (const g of this.strategyGroupIndex.values()) {
      strategyGroupIndex.set(g.strategyGroupId!, { ...g });
    }
    return strategyGroupIndex;
  }

  // TODO: use async/await here to ease the reading of the code
  // we await the result of the checkStrategyUpdate function
  async uploadStrategies(input: string): Promise<void> {
    const errors: BulkError[] = [];
    const creations: StrategyCreation[] = [];
    const updates: StrategyUpdate[] = [];
    const rows = input.split(/[\n]+/);
    const strategyIndex = this.getStrategyIndex();
    const strategyGroupIndex = this.getStrategyGroupIndex();
    let lineIndex = 0;
    let liveStrategyCounter = this.strategies.filter((s) => s.state == StrategyStateEnum.ENABLED).length;
    const liveStrategyLimit = this.strategyService.getLiveStrategiesLimit(
      this.accountId!,
      this.marketplace!,
      this.organizationId!,
    );
    for (const row of rows) {
      const trimmedRow = row.trim();
      if (trimmedRow === "") {
        // discard empty row
        continue;
      }
      lineIndex++;
      const strategyCsvRow = this.bulkImportService.parseCsvRow(trimmedRow.split(/\t/), this.campaignType!);
      if (!strategyCsvRow) {
        errors.push({ lineIndex: lineIndex, csvRow: trimmedRow, errors: ["Line is not properly formatted"] });
        continue;
      }
      if (strategyCsvRow.strategyId === undefined) {
        errors.push({ lineIndex: lineIndex, csvRow: trimmedRow, errors: ["Strategy id is not an integer"] });
        continue;
      }
      if (strategyCsvRow.strategyId === 0) {
        // strategy creation
        const strategy = {
          accountId: this.accountId,
          marketplace: this.marketplace,
          campaignType: this.campaignType,
          defaultStrategy: false,
          tactics: [],
          ...strategyCsvRow,
        } as Strategy;
        let strategyGroupCreation: Required<CreateStrategyGroupRequest> | undefined = undefined;

        // specific code for SP and strategy group logic
        if (this.campaignType == CampaignType.SP) {
          const res = this.checkSpStrategyCreation(strategy, lineIndex, trimmedRow, strategyGroupIndex);
          if (isStrategyBulkError(res)) {
            errors.push(res);
            continue;
          } else {
            strategyGroupCreation = res;
          }
        }

        // check ASINs are in catalog
        if (strategy.campaignType == CampaignType.SP || strategy.campaignType == CampaignType.SD) {
          const inexistingAsins = this.checkAsinsAreInCatalog(strategy.asins!.map((a) => a.asin));
          if (inexistingAsins.length > 0) {
            errors.push({
              lineIndex: lineIndex,
              csvRow: trimmedRow,
              errors: [`Some ASINs are not in the catalog: ${inexistingAsins.join(", ")}`],
            });
            continue;
          }
        }

        // check strategy creation parameters
        const checkErrors = await firstValueFrom(
          this.strategyService.checkStrategyCreation(this.organizationId!, strategy),
        );
        if (checkErrors.length > 0) {
          errors.push({
            lineIndex: lineIndex,
            csvRow: trimmedRow,
            errors: checkErrors,
          });
          continue;
        }
        // check live strategy limit
        if (strategy.state == StrategyStateEnum.ENABLED) {
          liveStrategyCounter++;
          if (liveStrategyCounter >= liveStrategyLimit) {
            errors.push({
              lineIndex: lineIndex,
              csvRow: trimmedRow,
              errors: [
                "Cannot activate a new strategy as the limit of live strategies has been reached (please contact us)",
              ],
            });
            continue;
          }
        }
        creations.push({
          lineIndex: lineIndex,
          csvRow: trimmedRow,
          strategy: strategy,
          strategyGroup: strategyGroupCreation,
        });
      } else {
        // strategy update
        if (!strategyIndex.has(strategyCsvRow.strategyId)) {
          errors.push({ lineIndex: lineIndex, csvRow: trimmedRow, errors: ["Strategy does not exist for this id"] });
          continue;
        }
        // check if duplicated strategy update
        if (updates.find((u) => u.strategyToUpdate.strategyId == strategyCsvRow.strategyId)) {
          errors.push({
            lineIndex: lineIndex,
            csvRow: trimmedRow,
            errors: [`Duplicated update on strategy with id ${strategyCsvRow.strategyId}`],
          });
          continue;
        }
        if (!strategyCsvRow.state) {
          errors.push({
            lineIndex: lineIndex,
            csvRow: trimmedRow,
            errors: [`Invalid strategy status`],
          });
          continue;
        }
        const strategyToUpdate = strategyIndex.get(strategyCsvRow.strategyId)!;
        const strategyUpdateParams = this.bulkImportService.getStrategyUpdateParams(
          strategyCsvRow,
          strategyToUpdate,
          this.organizationId!,
        );
        // check if something to update
        if (!isUpdating(strategyUpdateParams)) {
          errors.push({
            lineIndex: lineIndex,
            csvRow: trimmedRow,
            errors: ["No fields to update on the strategy"],
          });
          continue;
        }
        if (strategyToUpdate.campaignType == CampaignType.SP) {
          // specific checks for campaign belongin to a strategy group
          // for LEGACY strategies, no specific checks are needed
          // for PRODUCT strategies, checks that there is no product overlap or brand/kw strat running on deleted ASINs
          if (strategyToUpdate.strategyType == StrategyType.PRODUCT) {
            const strategyGroup = strategyGroupIndex.get(strategyToUpdate.strategyGroupId!)!;
            if (strategyUpdateParams.asinsToAdd.length > 0) {
              const asinsInStrategyGroup = new Set(
                strategyGroup.strategies!.flatMap((s) => s.asins!.map((a) => a.asin)),
              );
              if (strategyUpdateParams.asinsToAdd.some((a) => asinsInStrategyGroup.has(a))) {
                errors.push({
                  lineIndex: lineIndex,
                  csvRow: trimmedRow,
                  errors: ["Some ASINs are already in the strategy group"],
                });
                continue;
              }
            }
            if (strategyUpdateParams.asinsToDelete.length > 0) {
              const asinsInBrandOrKwStrategies = new Set(
                strategyGroup
                  .strategies!.filter(
                    (s) => s.strategyType == StrategyType.BRAND || s.strategyType == StrategyType.KEYWORD,
                  )
                  .flatMap((s) => s.asins!.map((a) => a.asin)),
              );
              if (strategyUpdateParams.asinsToDelete.some((a) => asinsInBrandOrKwStrategies.has(a))) {
                errors.push({
                  lineIndex: lineIndex,
                  csvRow: trimmedRow,
                  errors: [
                    "Some deleted ASINs are in use by other focus or brand defense strategies in the strategy group",
                  ],
                });
                continue;
              }
            }
            const strategyGroupStrat = {
              ...strategyGroup.strategies!.find((s) => s.strategyId == strategyToUpdate.strategyId),
            };
            strategyGroupStrat.asins = strategyGroupStrat
              .asins!.map((a) => ({ ...a })) // clone
              .filter((a) => !strategyUpdateParams.asinsToDelete.includes(a.asin))
              .concat(strategyUpdateParams.asinsToAdd.map((a) => ({ asin: a })));
          }
          // for BRAND or KEYWORD strategies, checks that added ASINs are in the strategy group
          if (
            strategyToUpdate.strategyType == StrategyType.BRAND ||
            strategyToUpdate.strategyType == StrategyType.KEYWORD
          ) {
            const strategyGroup = strategyGroupIndex.get(strategyToUpdate.strategyGroupId!)!;
            if (strategyUpdateParams.asinsToAdd.length > 0) {
              const asinsInStrategyGroup = new Set(
                strategyGroup.strategies!.flatMap((s) => s.asins!.map((a) => a.asin)),
              );
              if (strategyUpdateParams.asinsToAdd.some((a) => !asinsInStrategyGroup.has(a))) {
                errors.push({
                  lineIndex: lineIndex,
                  csvRow: trimmedRow,
                  errors: ["Some ASINs are not in the strategy group"],
                });
                continue;
              }
            }
            const strategyGroupStrat = {
              ...strategyGroup.strategies!.find((s) => s.strategyId == strategyToUpdate.strategyId),
            };
            strategyGroupStrat.asins = strategyGroupStrat
              .asins!.map((a) => ({ ...a })) // clone
              .filter((a) => !strategyUpdateParams.asinsToDelete.includes(a.asin))
              .concat(strategyUpdateParams.asinsToAdd.map((a) => ({ asin: a })));
          }
        }

        // check added ASINs
        if (strategyToUpdate.campaignType == CampaignType.SP || strategyToUpdate.campaignType == CampaignType.SD) {
          const inexistingAsins = this.checkAsinsAreInCatalog(strategyUpdateParams.asinsToAdd);
          if (inexistingAsins.length > 0) {
            errors.push({
              lineIndex: lineIndex,
              csvRow: trimmedRow,
              errors: [`Some ASINs are not in the catalog: ${inexistingAsins.join(", ")}`],
            });
            continue;
          }
        }
        // check strategy update parameters
        const checkErrors = await firstValueFrom(
          this.strategyService.checkStrategyUpdate(strategyUpdateParams, strategyIndex),
        );
        if (checkErrors.length > 0) {
          errors.push({
            lineIndex: lineIndex,
            csvRow: trimmedRow,
            errors: checkErrors,
          });
          continue;
        }

        // check live strategy limit
        if (
          strategyToUpdate.state != StrategyStateEnum.ENABLED &&
          strategyUpdateParams.state == StrategyStateEnum.ENABLED
        ) {
          liveStrategyCounter++;
          if (liveStrategyCounter > liveStrategyLimit) {
            errors.push({
              lineIndex: lineIndex,
              csvRow: trimmedRow,
              errors: [
                "Cannot activate a new strategy as the limit of live strategies has been reached (please contact us)",
              ],
            });
            continue;
          }
        }
        updates.push({
          lineIndex: lineIndex,
          csvRow: trimmedRow,
          strategyToUpdate: strategyToUpdate,
          updatedFields: strategyUpdateParams,
        });
      }
    }
    this.strategyBulkOperations.next({
      bulkData: input,
      creations: creations,
      updates: updates,
      errors: errors,
    });
    this.bsModalRef.hide();
  }

  private checkAsinsAreInCatalog(asins: string[]) {
    // TODO: support parent ASIN
    const inexistingAsins: string[] = [];
    for (const asin of asins) {
      if (!this.catalog!.contains(asin)) {
        inexistingAsins.push(asin);
      }
    }
    return inexistingAsins;
  }

  private checkSpStrategyCreation(
    strategy: Strategy,
    lineIndex: number,
    trimmedRow: string,
    strategyGroupIndex: Map<number, StrategyGroupEx>,
  ): Required<CreateStrategyGroupRequest> | BulkError {
    // creation of legacy strategies is not allowed
    if (strategy.strategyType == StrategyType.LEGACY) {
      return {
        lineIndex: lineIndex,
        csvRow: trimmedRow,
        errors: ["Creation of legacy strategies is no longer allowed. Create a PRODUCT strategy instead"],
      };
    }
    // for PRODUCT strategies (aka Main strategies)
    if (strategy.strategyType == StrategyType.PRODUCT) {
      // if strategyGroupId == 0, create a new strategy group
      if (!strategy.strategyGroupId) {
        return {
          accountId: this.accountId!,
          marketplace: this.marketplace!,
          strategyGroupName: strategy.name!,
        };
      }
      // if strategyGroupId > 0, ensure it exists and that there is no ASIN overlap
      else {
        const strategyGroup = strategyGroupIndex.get(strategy.strategyGroupId);
        if (!strategyGroup) {
          return {
            lineIndex: lineIndex,
            csvRow: trimmedRow,
            errors: ["Strategy group does not exist for this id"],
          };
        }
        const asinsInGroup = new Set(strategyGroup.strategies!.flatMap((s) => s.asins!.map((a) => a.asin)));
        if (strategy.asins!.some((a) => asinsInGroup.has(a.asin))) {
          return {
            lineIndex: lineIndex,
            csvRow: trimmedRow,
            errors: ["Some ASINs are already in the strategy group"],
          };
        }
        strategyGroup.strategies!.push(strategy);
      }
    }
    // for BRAND or KEYWORD strategies
    else {
      // strategyGroup creation is not allowed
      if (strategy.strategyGroupId == 0) {
        return {
          lineIndex: lineIndex,
          csvRow: trimmedRow,
          errors: ["Strategy group should be > 0 for BRAND (brand defense) or KEYWORD (focus) strategies"],
        };
      }
      // ensure strategy group exists
      const strategyGroup = strategyGroupIndex.get(strategy.strategyGroupId!);
      if (!strategyGroup) {
        return {
          lineIndex: lineIndex,
          csvRow: trimmedRow,
          errors: ["Strategy group does not exist for this id"],
        };
      }
      // check ASINs are in the strategy group
      const asinsInGroup = new Set(strategyGroup.strategies!.flatMap((s) => s.asins!.map((a) => a.asin)));
      if (strategy.asins!.some((a) => !asinsInGroup.has(a.asin))) {
        return {
          lineIndex: lineIndex,
          csvRow: trimmedRow,
          errors: ["Some ASINs are not in the strategy group"],
        };
      }
      // check that we have less than 10 kw strategies and less than 5 brand defense strategies
      if (strategy.strategyType == StrategyType.BRAND) {
        if (
          strategyGroup.strategies!.filter((s) => s.strategyType == StrategyType.BRAND).length >=
          Constant.maxBrandDefenseStrategiesByStrategyGroup
        ) {
          return {
            lineIndex: lineIndex,
            csvRow: trimmedRow,
            errors: [
              `The same strategy group cannot have more than ${Constant.maxBrandDefenseStrategiesByStrategyGroup} brand defense strategies`,
            ],
          };
        }
      } else if (strategy.strategyType == StrategyType.KEYWORD) {
        if (
          strategyGroup.strategies!.filter((s) => s.strategyType == StrategyType.KEYWORD).length >=
          Constant.maxKeywordStrategiesByStrategyGroup
        ) {
          return {
            lineIndex: lineIndex,
            csvRow: trimmedRow,
            errors: [
              `The same strategy group cannot have more than ${Constant.maxKeywordStrategiesByStrategyGroup} focus strategies`,
            ],
          };
        }
      }
      strategyGroup.strategies!.push(strategy);
    }
    return {
      accountId: this.accountId!,
      marketplace: this.marketplace!,
      strategyGroupName: strategy.name!,
    };
  }

  handleKeydown(event: KeyboardEvent) {
    // prevent focus switch when pressing tab
    if (event.key == "Tab") {
      event.preventDefault();
      const target = event.target as HTMLTextAreaElement;
      const start = target.selectionStart;
      const end = target.selectionEnd;
      target.value = target.value.substring(0, start) + "\t" + target.value.substring(end);
      target.selectionStart = target.selectionEnd = start + 1;
    }
  }

  onCsvUpload(files: FileList | null): void {
    if (files && files.length > 0) {
      const csvFile = files[0];
      if (csvFile.type !== "text/csv" && csvFile.type !== "application/vnd.ms-excel") {
        this.fileUploadError = `${csvFile.name} is not a CSV file`;
        return;
      }
      const reader: FileReader = new FileReader();
      reader.readAsText(csvFile);
      reader.onload = () => {
        const csv: string = reader.result as string;
        this.uploadStrategies(csv);
      };
    }
  }
}
