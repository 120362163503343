import { NgTemplateOutlet } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { BrandAsset, MediaType } from "@front/m19-api-client";
import { SegmentConfigType } from "@front/m19-models";
import { IButtonComponent, IInputComponent } from "@front/m19-ui";
import { LandingPage } from "@m19-board/strategies/sb-strategy-form/sb-strategy-form.component";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { ICON_EYE, ICON_IMPORT } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";

enum MediaInputTab {
  MEDIA,
  UPLOAD_NEW,
}

@UntilDestroy()
@Component({
  selector: "app-media-input",
  templateUrl: "./media-input.component.html",
  standalone: true,
  styleUrls: ["./media-input.component.scss"],
  imports: [IButtonComponent, IInputComponent, NgTemplateOutlet, TranslocoRootModule],
})
export class MediaInputComponent implements OnInit {
  @ViewChild("mediaSelectionModal") mediaSelectionModal!: TemplateRef<any>;
  @ViewChild("mediaPreviewModal") mediaPreviewModal!: TemplateRef<any>;

  readonly ICON_IMPORT = ICON_IMPORT;
  readonly ICON_EYE = ICON_EYE;

  MAX_BRAND_LOGO_SIZE = 1000000;
  MAX_CUSTOM_IMAGE_SIZE = 5000000;
  MAX_VIDEO_SIZE = 300000000;
  AUTHORIZED_VIDEO_SIZES = [
    [1920, 1080],
    [1280, 720],
    [3840, 2160],
  ];

  _mediaType: MediaType = MediaType.brandLogo;

  @Input() testId?: string;

  @Input() set mediaType(type: MediaType) {
    this._mediaType = type;
    this.media = this.isVideoType() ? (document.createElement("video") as HTMLVideoElement) : new Image();
  }

  @Input() withUploadMedia = true;

  @Input() set medias(m: BrandAsset[]) {
    this._medias = m;
    this.filteredMedias = m;
  }

  _medias?: BrandAsset[];

  @Input() set savedMedia(m: BrandAsset) {
    this._savedMedia = m;
    this.selectedMedia = m;
  }

  _savedMedia?: BrandAsset;

  @Input() selectedMedia?: BrandAsset;
  @Output() mediaSaved = new EventEmitter<BrandAsset>();
  @Input() removable: boolean = false;
  @Input() resetFile: Subject<void> = new Subject<void>();

  @Output() mediaDeleted = new EventEmitter<void>();

  @Output() toUpload = new EventEmitter<File>();
  @Output() isValidMediaFile = new EventEmitter<boolean>();

  modalRef?: BsModalRef;
  previewModalRef?: BsModalRef;

  selectedTab: MediaInputTab = MediaInputTab.MEDIA;

  isValidMedia?: boolean;
  invalidReason = "";

  mediaFile?: File; // new file
  media?: HTMLImageElement | HTMLVideoElement; // new file (element for display)
  newFile?: File; // new saved file, displayed in input and ready to be uploaded

  // media can be previewed from the input, or in the modal for video type
  mediaToPreview?: BrandAsset;

  mediaNameControl = new FormControl("");
  filteredMedias?: BrandAsset[];

  constructor(private modalService: BsModalService) {}

  ngOnInit(): void {
    this.mediaNameControl.valueChanges.subscribe((v: string | null) => {
      this.filteredMedias = this._medias?.filter((m: BrandAsset) => {
        const regexp = new RegExp(v!, "i");
        return regexp.test(m.name!);
      });
    });

    this.resetFile.pipe(untilDestroyed(this)).subscribe(() => (this.newFile = undefined));
  }

  openMediaSelectionModal() {
    this.modalRef = this.modalService.show(this.mediaSelectionModal, {
      class: "modal-lg modal-dialog-centered",
    });
  }

  openMediaToPreview(media: BrandAsset | undefined) {
    // media is undefined means we preview a new file
    this.mediaToPreview = media;
    this.previewModalRef = this.modalService.show(this.mediaPreviewModal, { class: "modal-lg modal-dialog-centered" });
  }

  saveMedia(media: BrandAsset | undefined) {
    this.setIsValidMediaFile(undefined);
    this.invalidReason = "";
    this.mediaFile = undefined;
    this.newFile = undefined;
    this.mediaSaved.emit(media);
    this.modalRef?.hide();
  }

  saveNewFile(file: File | undefined) {
    this.newFile = file;
    this.toUpload.emit(file);
    this.modalRef?.hide();
  }

  setIsValidMediaFile(isValid: boolean | undefined) {
    this.isValidMedia = isValid;
    this.isValidMediaFile.emit(isValid);
  }

  onNewMedia(files: FileList | null) {
    if (files && files.length > 0) {
      this.invalidReason = "";
      this.selectedMedia = undefined;
      this.isValidMedia = true;
      this.mediaFile = files[0];
      if (!this.isValidFileSize(this.mediaFile.size)) {
        this.media!.src = "";
        this.invalidReason += " Invalid File size.";
        this.setIsValidMediaFile(false);
        return;
      }

      if (!this.isValidFileType(this.mediaFile.type)) {
        this.media!.src = "";
        this.invalidReason += " Invalid File type.";
        this.setIsValidMediaFile(false);
        return;
      }

      const reader = new FileReader();
      // read as dataURL to render and check resolution
      reader.readAsDataURL(this.mediaFile);
      reader.onload = () => {
        if (this.isVideoType()) {
          (this.media as HTMLVideoElement).onloadedmetadata = (event) => {
            const isValid = this.checkMediaResolution(this.media!);
            this.setIsValidMediaFile(isValid);
            if (!isValid) {
              const video: HTMLVideoElement = this.media as HTMLVideoElement;
              this.invalidReason +=
                " Invalid resolution (" +
                video.videoWidth +
                "x" +
                video.videoHeight +
                ") or duration (" +
                video.duration +
                ")";
            }
          };
          (this.media as HTMLVideoElement).onerror = (event) => {
            // not able to read video on client browser (codec issue)
            // let's try with amazon
            this.setIsValidMediaFile(true);
            this.toUpload.emit(this.mediaFile);
          };
          this.media!.src = reader.result as string;
          (this.media as HTMLVideoElement).load();
        } else {
          this.media!.src = reader.result as string;
          this.media!.onload = () => {
            const isValid = this.checkMediaResolution(this.media!);
            this.setIsValidMediaFile(isValid);
            if (!isValid) {
              this.invalidReason += " Invalid resolution (" + this.media!.width + "x" + this.media!.height + ").";
            }
          };
        }
      };
    }
  }

  checkMediaResolution(media: HTMLVideoElement | HTMLImageElement): boolean {
    switch (this._mediaType) {
      case MediaType.brandLogo:
        return media.width >= 400 && media.height >= 400;

      case MediaType.customImage:
        return media.width >= 1200 && media.height >= 628;

      case MediaType.videoAsset: {
        const video: HTMLVideoElement = media as HTMLVideoElement;
        return this.hasValidResolution(video) && video.duration >= 6 && video.duration <= 46;
      }
    }
    return false;
  }

  private hasValidResolution(video: HTMLVideoElement): boolean {
    for (const authorizedSize of this.AUTHORIZED_VIDEO_SIZES) {
      if (
        (authorizedSize[0] === video.videoWidth && authorizedSize[1] === video.videoHeight) ||
        (authorizedSize[0] === video.videoHeight && authorizedSize[1] === video.videoWidth)
      ) {
        return true;
      }
    }

    return false;
  }

  isValidFileSize(size: number): boolean {
    switch (this._mediaType) {
      case MediaType.brandLogo:
        return size <= this.MAX_BRAND_LOGO_SIZE;

      case MediaType.customImage:
        return size <= this.MAX_CUSTOM_IMAGE_SIZE;

      case MediaType.videoAsset:
        return size <= this.MAX_VIDEO_SIZE;

      default:
        return true;
    }
  }

  isValidFileType(type: string) {
    switch (this._mediaType) {
      case MediaType.brandLogo:
      case MediaType.customImage:
        return ["image/gif", "image/jpeg", "image/png"].includes(type);
      case MediaType.videoAsset:
        return type.startsWith("video/");
      default:
        return true;
    }
  }

  getInvalidImageMessage(): string {
    if (this._mediaType === MediaType.brandLogo) {
      return (
        "Image size must be smaller than 1MB, and its resolution must be a minimum of 400 pixels by 400 pixels. " +
        this.invalidReason
      );
    } else if (this._mediaType === MediaType.customImage) {
      return (
        "Image size must be smaller than 5MB, and its resolution must be a minimum of 1200 pixels by 628 pixels. " +
        this.invalidReason
      );
    } else if (this._mediaType === MediaType.videoAsset) {
      return (
        "Video size must be smaller than 300MB, its resolution must be 1920x1080, 1280x720 or 3840x2160 and its duration must be between 6 and 45 seconds." +
        this.invalidReason
      );
    }
    return "";
  }

  isVideoType(): boolean {
    return this._mediaType === MediaType.videoAsset;
  }

  removeFile() {
    this.mediaDeleted.emit();
  }

  protected readonly LandingPage = LandingPage;
  protected readonly SegmentType = SegmentConfigType;
  protected readonly MediaInputTab = MediaInputTab;
  protected readonly MediaType = MediaType;
}
