<ng-container *transloco="let t">
  <div class="flex h-full w-full flex-col rounded-md border border-gray-200 bg-white">
    <div class="flex w-full border-b border-gray-200 p-4">
      <div class="flex flex-1 items-center justify-between gap-4">
        <div class="flex flex-1 items-center gap-4">
          <ICheckbox
            [value]="isAllSelected()"
            [indeterminate]="isCheckboxIndeterminate()"
            (valueChange)="selectAllChange()"
            [disabled]="filteredProductList().size === 0"
            [matTooltip]="checkboxAllTooltip()"
          />
          <IInput
            [placeholder]="t('strategy-asins.search_by_asin')"
            size="sm"
            class="flex-1"
            [icon]="SEARCH_ICON"
            (valueChange)="searchFilter.set($event)"
          />
        </div>
        <div class="flex items-center gap-2">
          <app-filter [filters]="filters()" (filterChange)="onFilterChange($event)" />
          @if (csvExport()) {
            <IButton [label]="t('common.export')" size="sm" color="white" />
          }
          <IButton label="Bulk" size="sm" color="white" (onClick)="openBulkAsinModal()" />
          <IButton
            [label]="
              !groupByParentAsin()
                ? t('product-tracker.group_by_parent_asin')
                : t('product-tracker.split_by_child_asin')
            "
            size="sm"
            color="white"
            (onClick)="groupByParentAsin.set(!groupByParentAsin())"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-1 flex-col divide-y divide-gray-100 overflow-y-auto">
      @if (!isLoading() && !noCatalog()) {
        @for (product of paginatedProductList() | keyvalue; track product.key.asin) {
          @if (groupByParentAsin() && product.key.isParent) {
            <ng-container
              [ngTemplateOutlet]="productRow"
              [ngTemplateOutletContext]="{ product: product.key }"
            ></ng-container>
          }
          <div
            [ngClass]="{
              'pl-4': groupByParentAsin(),
              'grid transition-all duration-200': groupByParentAsin(),
            }"
            [ngStyle]="{
              'grid-template-rows': groupByParentAsin() && openParentAsin() === product.key.asin ? '1fr' : '0fr',
            }"
          >
            <div [ngClass]="{ 'overflow-hidden': groupByParentAsin() }">
              @for (child of product.value; track child.asin) {
                <ng-container
                  [ngTemplateOutlet]="productRow"
                  [ngTemplateOutletContext]="{ product: child }"
                ></ng-container>
              }
            </div>
          </div>
        } @empty {
          <div class="flex flex-1 flex-col items-center justify-center">
            <div class="flex flex-col gap-2">
              <div class="flex size-8 items-center justify-center rounded-md bg-gray-100 p-2">
                <span class="icon-[mdi--magnify] h-full w-full text-gray-400"></span>
              </div>
              <span class="text-sm text-neutral-500">{{
                t("selling-partner-integration-validation-modal.no_product_has_been_found")
              }}</span>
            </div>
          </div>
        }
      } @else {
        <div class="flex flex-1 flex-col items-center justify-center">
          @if (noCatalog()) {
            <div class="flex flex-col gap-2">
              <div class="flex size-14 items-center justify-center rounded-md bg-gray-100 p-2">
                <span class="icon-[mdi--box-variant] h-full w-full text-gray-400"></span>
              </div>
              <h4 class="m-0 text-2xl font-bold text-gray-800">
                {{ t("selling-partner-integration-validation-modal.no_product_has_been_found") }}
              </h4>
              <span class="text-sm text-neutral-500">
                {{ t("strategy-asins.no_catalog_product_for_marketplace") }}
              </span>
            </div>
          } @else {
            <app-spinner size="s" [display]="true" [main]="false" type="default" />
          }
        </div>
      }
    </div>
    <div class="flex w-full items-center justify-between border-t border-gray-200 px-4 py-2">
      <div class="flex flex-row items-center gap-2">
        <span
          (click)="onlySelectedFilter.set(!onlySelectedFilter())"
          class="cursor-pointer text-sm text-gray-700 hover:text-gray-900"
          matTooltip="Filter selected products"
          >{{ t("common.selected", { count: selectedProductList().length }) }}</span
        >
      </div>

      @if (filteredProductLength() > DEFAULT_PAGE_SIZE) {
        <mat-paginator
          class="[&_*]:!text-sm"
          [pageSizeOptions]="[]"
          [hidePageSize]="true"
          [pageSize]="DEFAULT_PAGE_SIZE"
          [pageIndex]="pagination().pageIndex"
          [length]="filteredProductLength()"
          (page)="pagination.set($event)"
          showFirstLastButtons
        />
      }
    </div>
  </div>

  <ng-template #productRow let-product="product">
    <div
      class="group flex w-full cursor-pointer items-center gap-4 px-4 py-2"
      [ngClass]="{
        'bg-gray-50': product.isSelected,
        'hover:bg-gray-50': !product.isSelected && !product.isDisabled,
        '!cursor-not-allowed opacity-75': product.isDisabled,
      }"
      (click)="onProductClick(product)"
    >
      @if (groupByParentAsin() && product.isParent) {
        <button
          type="button"
          class="flex items-center justify-center rounded-md p-0 duration-75 hover:bg-gray-100"
          [ngClass]="{ '-rotate-90': openParentAsin() !== product.asin }"
          (click)="$event.stopPropagation(); setOpenParentAsin(product.asin)"
        >
          <span [class]="CHEVRON_RIGHT_ICON" class="size-6 text-gray-400"></span>
        </button>
      }
      <div>
        <ICheckbox
          [value]="product.isSelected"
          [disabled]="product.isDisabled"
          [indeterminate]="product.isIndeterminate"
        />
      </div>
      <div
        class="relative flex size-10 items-center justify-center rounded-md duration-150"
        [ngClass]="[
          product.image
            ? 'border border-gray-200 p-1 shadow-sm'
            : 'bg-gray-100 p-2 group-hover:ring-1 group-hover:ring-gray-200',
        ]"
      >
        @if (product.isParent) {
          <div
            class="absolute -right-2 -top-1 z-50 flex size-5 items-center justify-center rounded-full bg-gray-800 text-white"
          >
            <span class="text-xs font-medium">{{ product.childAsins?.length }}</span>
          </div>
        }
        @if (product.image) {
          <img [src]="product.image" class="size-9 rounded-md" />
        } @else {
          <span class="icon-[mdi--package-variant-closed] size-8 text-gray-400"></span>
        }
      </div>
      <div class="flex flex-1 flex-col truncate">
        <span class="select-none truncate text-sm">{{ product.title ?? "--" }}</span>
        <div class="flex flex-row items-center gap-2 text-sm">
          <span class="text-gray-500">{{ product.asin }}</span>
          @if (product.brand) {
            <span class="text-gray-500">•</span>
            <span class="text-gray-500">{{ product.brand }}</span>
          }
          @if (product.ineligibleReason) {
            <span class="text-gray-500">•</span>
            <span class="text-orange-500" [matTooltip]="product.ineligibleReason">{{ t("common.ineligible") }}</span>
          }
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
