import { formatDate } from '@angular/common';
import {
  ChartDataset,
  ChartOptions,
  ChartTypeRegistry,
  LegendElement,
  LegendItem,
  ScaleChartOptions,
  ScaleOptions,
  ScaleOptionsByType,
} from 'chart.js';
import { AnnotationOptions, AnnotationPluginOptions } from 'chartjs-plugin-annotation';
import { Moment } from 'moment-timezone';
import { Currency } from '../api-client';
import { TranslocoService } from '@jsverse/transloco';
import {
  AggregationFunction,
  composeAggregationFunctions,
  DateAggregation,
  ScaleConfiguration,
  ScaleConfigurationInput,
  ScaleUtils,
  Utils,
} from '@front/m19-utils';
import { Metric } from '@front/m19-metrics';
import { CurrencyStat } from '@front/m19-models/CurrencyStat';

type WithAlignedDate<T> = T & { alignedDate: string };

function alignDate<T extends { date?: string }>(stat: T, dateIntervalInDays: number): T & { alignedDate: string } {
  let date = Utils.toMoment(stat.date!);
  date = date.add(dateIntervalInDays, 'days');
  const alignedStat = stat as T & { alignedDate: string };
  alignedStat.alignedDate = Utils.formatMomentDate(date);
  return alignedStat;
}

export class DataSet<T extends CurrencyStat> {
  public locale = 'en';
  public currency!: Currency;
  public labels: string[] = [];
  public chartDataSet: ChartDataset[] = [];
  public lineChartOptions: ChartOptions;
  public nbDays = 1;
  public metricsOnSameScale: Metric<T>[][] = [];
  public tickNumbers = 5;
  public maintainAspectRatio = true;
  public hoverCallback!: (idx: number) => void;
  public resizeCallback!: (width: number, height: number) => void;

  private minDate!: Moment;
  private maxDate!: Moment;
  private dateIntervalInDays!: number;
  private previousFormattedDates: string[] = [];
  private aggregatedData: Map<string, T> = new Map();
  private previousAggregatedData: Map<string, T> | undefined;

  private readonly fontSize = 12;
  private readonly sumFunction: (x: T, y: T) => T;

  private static readonly UNFOCUS_TRANSPARENCY = 10;
  private static readonly PAST_PERIOD_TRANSPARENCY = 50;

  constructor(
    private aspectRatio: number,
    private defaultMetrics: Metric<T>[],
    aggFunctions: AggregationFunction[],
    private translocoService: TranslocoService,
  ) {
    this.sumFunction = composeAggregationFunctions(aggFunctions);
    this.lineChartOptions = this.buildLineChartBaseOptions(this.defaultMetrics);
    this.chartDataSet = [
      {
        data: [],
        fill: false,
        clip: 5,
        yAxisID: this.defaultMetrics[0].id,
      },
    ];
  }

  public buildDataSet(
    data: T[],
    metrics: Metric<T>[],
    dateAggregation: DateAggregation,
    dateRange: { minDate: string; maxDate: string },
    previousPeriodData?: { data: T[]; period: string[] },
    eventAnnotations?: DataSetEventAnnotation[],
  ) {
    this.minDate = Utils.toMoment(dateRange.minDate);
    this.maxDate = Utils.toMoment(dateRange.maxDate);

    let alignedPreviousPeriodData: WithAlignedDate<T>[] | undefined = undefined;

    if (previousPeriodData?.period) {
      const comparedMaxDate = Utils.toMoment(previousPeriodData.period[1]);
      this.dateIntervalInDays = this.maxDate.diff(comparedMaxDate, 'days');
      alignedPreviousPeriodData = previousPeriodData.data.map((d) => alignDate(d, this.dateIntervalInDays));
    } else {
      this.dateIntervalInDays = 0;
    }

    const { aggData, previousPeriodAggData } = this.aggregateData(dateAggregation, data, alignedPreviousPeriodData);

    this.aggregatedData = aggData;
    this.previousAggregatedData = previousPeriodData ? previousPeriodAggData : undefined;
    this.renderDataset(eventAnnotations, metrics, dateAggregation);
  }

  private aggregateData(
    dateAggregation: DateAggregation,
    data: T[],
    previousPeriodData: WithAlignedDate<T>[] | undefined,
  ) {
    let aggData: Map<string, T> = new Map();
    let previousPeriodAggData: Map<string, T> = new Map();
    switch (dateAggregation) {
      case DateAggregation.hourly:
        aggData = Utils.sumBy(data, (x) => x.hour!.toString(), this.sumFunction);
        break;
      case DateAggregation.daily:
        aggData = Utils.sumBy(data, (x) => x.date!, this.sumFunction);

        if (previousPeriodData) {
          previousPeriodAggData = Utils.sumBy<WithAlignedDate<T>>(
            previousPeriodData,
            (x) => x.alignedDate,
            (a, b) => {
              const r = this.sumFunction(a, b) as WithAlignedDate<T>;
              r.alignedDate = a.alignedDate ?? b.alignedDate;
              return r;
            },
          );
        }
        break;
      case DateAggregation.weekly:
        aggData = Utils.sumBy(data, (x) => Utils.roundDateByWeekMoment(this.minDate, x.date!), this.sumFunction);

        if (previousPeriodData) {
          previousPeriodAggData = Utils.sumBy<WithAlignedDate<T>>(
            previousPeriodData,
            (x) => Utils.roundDateByWeekMoment(this.minDate, x.alignedDate!),
            (a, b) => {
              const r = this.sumFunction(a, b) as WithAlignedDate<T>;
              r.alignedDate = a.alignedDate ?? b.alignedDate;
              return r;
            },
          );
        }
        break;
      case DateAggregation.monthly:
        aggData = Utils.sumBy(data, (x) => Utils.roundDateByMonthMoment(this.minDate, x.date!), this.sumFunction);

        if (previousPeriodData) {
          previousPeriodAggData = Utils.sumBy<WithAlignedDate<T>>(
            previousPeriodData,
            (x) => Utils.roundDateByMonthMoment(this.minDate, x.alignedDate!),
            (a, b) => {
              const r = this.sumFunction(a, b) as WithAlignedDate<T>;
              r.alignedDate = a.alignedDate ?? b.alignedDate;
              return r;
            },
          );
        }
        break;
    }
    return { aggData, previousPeriodAggData };
  }

  public buildHourlyDataSet(
    data: Map<string, T>,
    metrics: Metric<T>[],
    daypartingPauseHour?: number,
    dayPartingReactivationHour?: number,
  ) {
    const annotations: AnnotationOptions[] = [];

    if (daypartingPauseHour != undefined && dayPartingReactivationHour != undefined) {
      if (daypartingPauseHour > dayPartingReactivationHour) {
        annotations.push({
          type: 'box',
          backgroundColor: 'rgba(188, 170, 164, 0.2)',
          borderWidth: 0,
          xMax: daypartingPauseHour,
          xMin: 23,
        });
        annotations.push({
          type: 'box',
          backgroundColor: 'rgba(188, 170, 164, 0.2)',
          borderWidth: 0,
          xMax: dayPartingReactivationHour,
          xMin: 0,
        });
      } else {
        annotations.push({
          type: 'box',
          backgroundColor: 'rgba(188, 170, 164, 0.2)',
          borderWidth: 0,
          xMax: dayPartingReactivationHour,
          xMin: daypartingPauseHour,
        });
      }
    }
    const annotation: AnnotationPluginOptions = {
      common: {
        drawTime: 'beforeDraw',
      },
      annotations: annotations,
    };

    this.labels = [];
    this.chartDataSet = [];
    this.lineChartOptions = this.buildLineChartBaseOptions(metrics, annotation);
    const scaleConfigurations = this.buildScaleConfigurations(metrics, data, undefined);

    this.buildChartDatasets(metrics, scaleConfigurations);

    for (let h = 0; h < 24; h++) {
      const str = h.toString();
      this.labels.push(str);

      for (let i = 0; i < metrics.length; i++) {
        this.chartDataSet[i].data.push(metrics[i].value(data.get(str) as T)!);
      }
    }
  }

  public renderDataset(
    eventAnnotations: DataSetEventAnnotation[] | undefined,
    metrics: Metric<T>[],
    dateAggregation: DateAggregation,
  ) {
    let annotation: AnnotationPluginOptions | undefined = undefined;
    if (eventAnnotations && eventAnnotations.length > 0) {
      annotation = {
        clip: false,
        annotations: eventAnnotations.flatMap((e) => this.toAnnotationOptions(e, metrics)),
      };
    }

    this.labels = [];
    this.previousFormattedDates = [];
    this.chartDataSet = [];
    this.lineChartOptions = this.buildLineChartBaseOptions(metrics, annotation);

    const scaleConfigurations = this.buildScaleConfigurations(
      metrics,
      this.aggregatedData,
      this.previousAggregatedData,
    );

    this.buildChartDatasets(metrics, scaleConfigurations);

    if (this.previousAggregatedData) {
      for (let i = 0; i < metrics.length; i++) {
        this.chartDataSet.push(this.buildChartDataset(metrics[i], true));
      }
    }

    const lastValue: Map<Metric<T>, number> = new Map();
    const lastValuePreviousPeriod: Map<Metric<T>, number> = new Map();
    for (
      let date: Moment | undefined = this.minDate, i = 0;
      date && date <= this.maxDate;
      i++, date = Utils.incMomentDate(this.minDate.clone(), dateAggregation, i)
    ) {
      const str = Utils.formatMomentDate(date);
      const formattedDate = formatDate(str, 'd MMM', this.locale);
      this.labels.push(formattedDate);
      const previousDate = date.clone().subtract(this.dateIntervalInDays, 'days');
      this.previousFormattedDates.push(formatDate(previousDate.toDate(), 'shortDate', this.locale));

      for (let i = 0; i < metrics.length; i++) {
        const value = metrics[i].value(this.aggregatedData.get(str) as T);
        if (metrics[i].keepLastValue && value) {
          lastValue.set(metrics[i], value);
        }
        if (metrics[i].keepLastValue) {
          this.chartDataSet[i].data.push(lastValue.get(metrics[i])!);
        } else {
          this.chartDataSet[i].data.push(value!);
        }
      }
      if (this.previousAggregatedData) {
        for (let i = 0; i < metrics.length; i++) {
          const value = metrics[i].value(this.previousAggregatedData.get(str) as T);
          if (metrics[i].keepLastValue && value) {
            lastValuePreviousPeriod.set(metrics[i], value);
          }
          if (metrics[i].keepLastValue) {
            this.chartDataSet[metrics.length + i].data.push(lastValuePreviousPeriod.get(metrics[i])!);
          } else {
            this.chartDataSet[metrics.length + i].data.push(value!);
          }
        }
      }
    }
  }

  private buildChartDatasets(metrics: Metric<T>[], scaleConfigurations: Map<Metric<T>, ScaleConfiguration>) {
    for (let i = 0; i < metrics.length; i++) {
      const metric = metrics[i];

      const scaleConfig = scaleConfigurations.get(metric);

      const dataSet = this.buildChartDataset(metric);

      const scaleOptions: ScaleOptions = {
        beginAtZero: true,
        ticks: {
          callback: (value) => {
            return metric.format(value as number, this.locale, this.currency, '1.0-1');
          },
          color: metric.color,
          padding: 5,
          stepSize: scaleConfig?.tickSpacing,
          font: {
            size: this.fontSize,
          },
          display: metric.tickDisplay,
        },
        min: scaleConfig?.min,
        max: scaleConfig?.max,
        position: i > 0 ? 'right' : 'left',
        grid: {
          drawTicks: false,
          drawOnChartArea: i == 0, // do not display grid lines for right axes
        },
        border: {
          display: false,
        },
        title: {
          display: false,
        },
        reverse: metric.reverseAxis,
      };

      if (this.lineChartOptions.scales) this.lineChartOptions.scales[metric.id] = scaleOptions;

      this.chartDataSet.push(dataSet);
    }
  }

  private buildChartDataset(metric: Metric<T>, previousPeriod = false): ChartDataset {
    const color = previousPeriod
      ? DataSet.addTransparency(metric.color, DataSet.PAST_PERIOD_TRANSPARENCY)
      : metric.color;
    return {
      data: [],
      spanGaps: metric.spanGaps,
      fill: false,
      label: this.translocoService ? this.translocoService.translate('metrics.' + metric.id + '_title') : metric.title,
      yAxisID: metric.id,
      borderColor: color,
      backgroundColor: color,
      tension: metric.graphTension,
      clip: 5,
      pointRadius: (ctx: any) => {
        if (!ctx.dataset || !ctx.dataset.data) {
          return 0;
        }
        // some logic to display a point when there is an isolated datapoint
        if (ctx.dataset.data.length <= 1) {
          return 3;
        }
        if (
          (ctx.dataIndex == 0 || !isFinite(ctx.dataset.data[ctx.dataIndex - 1])) &&
          (ctx.dataIndex == ctx.dataset.data.length - 1 || !isFinite(ctx.dataset.data[ctx.dataIndex + 1]))
        ) {
          return 3;
        }
        return 0;
      },
      pointStyle: 'circle',
      pointBorderWidth: 0,
      pointBackgroundColor: color,
      pointHoverRadius: 5,
      pointHoverBorderWidth: 0,
      pointHoverBackgroundColor: color,
      borderDash: previousPeriod ? [10, 5] : metric.graphBorderDash,
      stepped: metric.stepped,
    };
  }

  private buildScaleConfigurations(
    metrics: Metric<T>[],
    aggData: Map<string, T>,
    previousAggData?: Map<string, T>,
  ): Map<Metric<T>, ScaleConfiguration> {
    const result = new Map<Metric<T>, ScaleConfiguration>();
    const values = Array.from(aggData.values()).concat(previousAggData ? Array.from(previousAggData.values()) : []);
    // compute metric classes based on metrics with same scale
    const metricClasses: Metric<T>[][] = [];
    for (const metric of metrics) {
      const sameScaleMetrics = this.metricsOnSameScale.find((m) => m.includes(metric));
      if (sameScaleMetrics) {
        const metricClass = metricClasses.find((m) => m.findIndex((m2) => sameScaleMetrics.includes(m2)) > -1);
        if (metricClass) {
          metricClass.push(metric);
        } else {
          metricClasses.push([metric]);
        }
      } else {
        metricClasses.push([metric]);
      }
    }

    const scaleConfigInputs: ScaleConfigurationInput[] = [];
    const metricIndex: Metric<T>[] = [];
    // get min/max from aggData + previous agg data for each metric class
    for (const metricClass of metricClasses) {
      let min = metricClass[0].minAtZero ? 0 : undefined;
      let max = metricClass[0].maxAt ?? undefined;
      for (const metric of metricClass) {
        for (const d of values) {
          const v = metric.value(d)!;
          if (!isFinite(v)) {
            continue;
          }
          if (min === undefined || v < min) {
            min = v;
          }
          if (max === undefined || v > max) {
            max = v;
          }
        }
      }
      if (min === undefined) {
        min = 0;
      }
      if (max === undefined || min >= max) {
        max = min + 1;
      }
      for (const metric of metricClass) {
        scaleConfigInputs.push({ minPoint: min, maxPoint: max });
        metricIndex.push(metric);
      }
    }
    const configs = ScaleUtils.computeScaleConfiguration(this.tickNumbers, scaleConfigInputs);
    for (let i = 0; i < metricIndex.length; i++) {
      result.set(metricIndex[i], configs[i]);
    }
    return result;
  }

  private buildLineChartBaseOptions(metrics: Metric<T>[], annotation?: AnnotationPluginOptions): ChartOptions {
    const scalesOptions: ScaleChartOptions = {
      scales: {
        xAxis: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
            maxTicksLimit: 9,
            maxRotation: 0,
            font: {
              size: this.fontSize,
            },
          } as any,
        } as ScaleOptionsByType,
      },
    };
    for (let i = 0; i < metrics.length; i++) {
      (scalesOptions.scales as any)[metrics[i].id] = {
        position: i == 0 ? 'left' : 'right',
        title: {
          display: false,
        },
      };
    }

    return {
      responsive: true,
      aspectRatio: this.aspectRatio,
      maintainAspectRatio: this.maintainAspectRatio,
      scales: scalesOptions.scales,
      hover: {
        mode: 'index',
        intersect: false,
      },
      onHover: (event, elements) => {
        if (elements && elements.length > 0 && elements[0].index !== undefined) {
          this.onHoverGraph(elements[0].index);
        } else {
          this.onHoverGraph(undefined);
        }
      },
      onResize: (chart, size) => {
        this.onResize(size.width, size.height);
      },
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            usePointStyle: true,
            filter: (item) => {
              // do not display legend for previous period data
              return item.datasetIndex !== undefined && item.datasetIndex < metrics.length;
            },
            font: {
              size: this.fontSize,
            },
            generateLabels: (chart) => {
              return metrics.map((m) => {
                return {
                  text: this.translocoService?.translate(`metrics.${m.id}_title`),
                  pointStyle: 'line',
                  lineDash: m.graphBorderDash,
                  strokeStyle: m.color,
                  hidden: false,
                  index: metrics.indexOf(m),
                  datasetIndex: metrics.indexOf(m),
                  lineWidth: 4,
                };
              });
            },
          },
          onHover: (e, legendItem, legend) => {
            this.hoverLegend(legend, metrics, legendItem);
          },
          onLeave: (e, legendItem, legend) => {
            this.leaveLegend(legend, metrics, legendItem);
          },
          onClick: (e, legendItem, legend) => {
            this.clickLegend(legend, metrics, legendItem);
          },
        },
        tooltip: {
          mode: 'index',
          intersect: false,
          displayColors: true,
          callbacks: {
            label: (tooltipItem) => {
              let prependPrevious = '';
              let metricIndex = tooltipItem.datasetIndex;
              if (metricIndex >= metrics.length) {
                metricIndex = metricIndex - metrics.length;
                // get previous date
                const formattedDate = this.previousFormattedDates[tooltipItem.dataIndex];
                prependPrevious = `(Previous ${formattedDate}) `;
              }
              return (
                prependPrevious +
                metrics[metricIndex].format(
                  tooltipItem.raw as number,
                  this.locale,
                  this.currency,
                  (tooltipItem.raw as number) < 10 ? '1.0-2' : undefined,
                ) +
                ' ' +
                this.translocoService?.translate(`metrics.${metrics[metricIndex].id}_title`)
              );
            },
            labelColor: (tooltipItem) => {
              const metricIndex = tooltipItem.datasetIndex;
              const baseColor =
                metrics[metricIndex >= metrics.length ? metricIndex - metrics.length : metricIndex].color;
              return {
                borderWidth: 2,
                borderRadius: 2,
                backgroundColor: baseColor,
                borderColor: baseColor,
              };
            },
          },
        },
        annotation: annotation ?? {},
      },
    } as ChartOptions;
  }

  public static addTransparency(color: string, tranparency: number = DataSet.PAST_PERIOD_TRANSPARENCY): string {
    // color is assumed to have no alpha channel
    if (color.startsWith('rgb')) {
      const index = color.length - 2;
      color = color.substring(0, index) + tranparency / 100 + color.substring(index + 1);
    } else if (color.startsWith('#')) {
      color += Math.floor(2.56 * tranparency).toString(16); // append alpha chanel in Hex
    } else if (color.startsWith('hsl') || color.startsWith('hsv')) {
      const index = color.length - 1;
      color = color.substring(0, index) + ',' + tranparency / 100 + ')';
    }
    return color;
  }

  private onHoverGraph(xIndex?: number) {
    if (this.hoverCallback && xIndex) {
      this.hoverCallback(xIndex);
    }
  }

  private onResize(width: number, height: number) {
    if (this.resizeCallback) {
      this.resizeCallback(width, height);
    }
  }

  private hoverLegend(legend: LegendElement<keyof ChartTypeRegistry>, metrics: Metric<T>[], legendItem: LegendItem) {
    for (let i = 0; i < legend.chart.data.datasets.length; i++) {
      if (i % metrics.length === legendItem.datasetIndex) {
        legend.chart.data.datasets[i].borderWidth = 5;
      } else {
        // add alpha channel to colors of other metrics
        // and hide previous data lines of other metrics
        if (i < metrics.length) {
          const color = DataSet.addTransparency(metrics[i].color, DataSet.UNFOCUS_TRANSPARENCY);
          legend.chart.data.datasets[i].borderColor = color;
        } else if (i % metrics.length != legendItem.datasetIndex) {
          legend.chart.data.datasets[i].borderWidth = 0;
        }
      }
    }
    legend.chart.update();
  }

  private leaveLegend(legend: LegendElement<keyof ChartTypeRegistry>, metrics: Metric<T>[], legendItem: LegendItem) {
    for (let i = 0; i < legend.chart.data.datasets.length; i++) {
      if (i % metrics.length === legendItem.datasetIndex) {
        legend.chart.data.datasets[i].borderWidth = undefined;
      } else {
        // remove alpha channel to colors of other lines
        // and diplay previous data lines of other metrics
        if (i < metrics.length) {
          legend.chart.data.datasets[i].borderColor = metrics[i].color;
        } else if (i % metrics.length != legendItem.datasetIndex) {
          legend.chart.data.datasets[i].borderWidth = undefined;
        }
      }
    }
    legend.chart.update();
  }

  private clickLegend(legend: LegendElement<keyof ChartTypeRegistry>, metrics: Metric<T>[], legendItem: LegendItem) {
    for (let i = 0; i < legend.chart.data.datasets.length; i++) {
      if (i % metrics.length === legendItem.datasetIndex) {
        legend.chart.data.datasets[i].hidden = !legend.chart.data.datasets[i].hidden;
      }
    }
    legend.chart.update();
  }

  private toAnnotationOptions(eventAnnotation: DataSetEventAnnotation, metrics: Metric<T>[]): AnnotationOptions[] {
    const date = formatDate(eventAnnotation.date, 'd MMM', this.locale);
    const noIcon = !eventAnnotation.iconUrls || eventAnnotation.iconUrls.length == 0;
    // formatDate(eventAnnotation.date, "shortDate", this.locale);
    const lineAnnotation: AnnotationOptions = {
      id: `line${date}`,
      type: 'line',
      scaleID: 'xAxis',
      value: date,
      borderColor: eventAnnotation.color ?? 'red',
      borderWidth: 3,
      drawTime: 'beforeDatasetsDraw',
      label: {
        drawTime: 'afterDatasetsDraw',
        position: noIcon ? '0%' : '20%',
        content: eventAnnotation.label,
        display: false,
        z: 10000,
      },
      enter: (ctx) => {
        if (ctx.element.label) ctx.element.label.options.display = true;
        return true;
      },
      leave: (ctx) => {
        if (ctx.element.label) ctx.element.label.options.display = false;
        return true;
      },
    };
    if (noIcon) {
      return [lineAnnotation];
    }
    const iconAnnotation: AnnotationOptions = {
      id: `label${date}`,
      type: 'label',
      content: getIconCanvas(eventAnnotation.iconUrls!),
      drawTime: 'beforeDatasetsDraw',
      position: {
        x: 'center',
        y: 'start',
      },
      xValue: date,
      yValue: (ctx) =>
        metrics[0].reverseAxis ? ctx.chart.scales[metrics[0].id].min : ctx.chart.scales[metrics[0].id].max,
      yAdjust: -10,
      enter: (ctx) => {
        (
          (ctx.chart.options.plugins!.annotation!.annotations as AnnotationOptions[]).find(
            (a) => a.id == `line${date}`,
          ) as any
        )['label'].display = true;
        ctx.chart.update();
      },
      leave: (ctx) => {
        (
          (ctx.chart.options.plugins!.annotation!.annotations as AnnotationOptions[]).find(
            (a) => a.id == `line${date}`,
          ) as any
        )['label'].display = false;
        ctx.chart.update();
      },
    };
    return [lineAnnotation, iconAnnotation];
  }
}

export interface DataSetEventAnnotation {
  color?: string;
  label: string[];
  date: Date;
  iconUrls?: string[];
}

function getIconCanvas(iconUrls: string[]) {
  const size = 25;
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = iconUrls.length * size;
  const ctx = canvas.getContext('2d');

  for (let i = 0; i < iconUrls.length; i++) {
    const img = new Image();
    img.onload = function () {
      if (ctx) ctx.drawImage(img as unknown as CanvasImageSource, 0, i * size, size, size);
    };
    img.src = iconUrls[i];
  }
  return canvas;
}
