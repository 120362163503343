import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, EventEmitter, HostBinding, model, Output } from '@angular/core';
import { Params, RouterLink } from '@angular/router';
import { twJoin, twMerge } from 'tailwind-merge';
import { MatTooltip } from '@angular/material/tooltip';

export const booleanInput = (value: boolean | string) => (typeof value === 'string' ? value === '' : value);

@Component({
  selector: 'IButton',
  standalone: true,
  imports: [NgTemplateOutlet, RouterLink, NgClass, MatTooltip],
  templateUrl: './ibutton.component.html',
})
export class IButtonComponent implements ICellRendererAngularComp {
  label = model<string>();
  type = model<'button' | 'submit'>('button');
  size = model<'xs' | 'sm' | 'md' | 'lg' | 'xl'>('md');
  variant = model<'solid' | 'ghost' | 'soft' | 'outline' | 'link'>('solid');
  color = model<'main' | 'green' | 'red' | 'orange' | 'white' | 'gray'>('main');
  borderOutline = model(false);
  to = model<string>(); // router link
  href = model<string>(); // href link
  target = model<'_blank' | '_parent' | '_self' | '_top'>('_self');
  icon = model<string>();
  tooltipValue = model<string>();
  queryParams = model<Params | null>();
  queryParamsHandling = model<'merge' | 'preserve' | ''>('');
  testid = model<string>();

  spaceBetween = model(false);
  loading = model(false);
  padded = model(true);
  square = model(false);
  block = model(false);
  disabled = model(false);
  iconOnHover = model(false);
  trailing = model(false);
  rounded = model(false);

  @Output() onClick = new EventEmitter<MouseEvent>();
  clickAction: () => void = () => {}; // used for ag-grid

  @HostBinding('class') get HeadingClass() {
    return this.block() ? 'block' : 'inline-flex';
  }

  isSquare = computed(() => this.square() || (this.icon() && !this.label()));

  sizeConfig = computed(() => {
    let config = this.block() ? 'w-full flex justify-center items-center' : 'inline-flex items-center';

    config = twJoin(config, this.textConfig());

    if (!this.padded()) {
      return config;
    }

    switch (this.size()) {
      case 'xs':
        return twJoin(config, 'py-1', this.isSquare() ? 'px-1' : 'px-1.5');
      case 'sm':
        return twJoin(config, 'gap-x-1.5 py-1.5', this.isSquare() ? 'px-1.5' : 'px-2.5');
      case 'md':
        return twJoin(config, 'gap-x-2 py-2', this.isSquare() ? 'px-2' : 'px-3');
      case 'lg':
        return twJoin(config, 'gap-x-2.5 py-2.5', this.isSquare() ? 'px-2.5' : 'px-3');
      case 'xl':
        return twJoin(config, 'gap-x-2.5 py-2.5', this.isSquare() ? 'px-2.5' : 'px-3.5');
      default:
        return '';
    }
  });

  textConfig = computed(() => {
    switch (this.size()) {
      case 'xs':
        return 'text-xs';
      case 'sm':
        return 'text-sm';
      case 'md':
        return 'text-sm';
      case 'lg':
        return 'text-sm';
      case 'xl':
        return 'text-base';
      default:
        return '';
    }
  });

  buttonConfig = computed(() => {
    let config =
      'focus:outline-0 focus-visible:outline-0 disabled:cursor-not-allowed disabled:opacity-75 aria-disabled:cursor-not-allowed aria-disabled:opacity-75 flex-shrink-0 font-medium duration-100 group/button';

    let ringConfig: string = '';
    if (this.color() === 'white') {
      if (this.variant() === 'solid') {
        config = twMerge(
          config,
          'shadow-sm text-gray-900 dark:text-white bg-white hover:bg-gray-50 disabled:bg-white aria-disabled:bg-white dark:bg-gray-900 dark:hover:bg-gray-800 dark:disabled:bg-gray-900 dark:aria-disabled:bg-gray-900 ',
        );
        ringConfig =
          'ring-1 ring-inset ring-gray-300 dark:ring-gray-700 focus-visible:ring-2 focus-visible:ring-main-500 dark:focus-visible:ring-main-400';
      }
    } else if (this.color() === 'gray') {
      if (this.variant() === 'ghost') {
        config = twMerge(
          config,
          'text-gray-700 dark:text-gray-200 hover:text-gray-900 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-gray-700',
        );
        ringConfig =
          'focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-main-500 dark:focus-visible:ring-main-400';
      }
    } else
      switch (this.variant()) {
        case 'solid':
          config = twMerge(
            config,
            `shadow-sm hover:text-white text-white dark:text-gray-900 bg-${this.color()}-500 hover:bg-${this.color()}-600 disabled:bg-${this.color()}-500 aria-disabled:bg-${this.color()}-500 dark:bg-${this.color()}-400 dark:hover:bg-${this.color()}-500 dark:disabled:bg-${this.color()}-400 dark:aria-disabled:bg-${this.color()}-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${this.color()}-500 dark:focus-visible:outline-${this.color()}-400`,
          );
          break;
        case 'outline':
          config = twMerge(
            config,
            `hover:text-${this.color()}-500 text-${this.color()}-500 dark:text-${this.color()}-400 hover:bg-${this.color()}-50 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-${this.color()}-950 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent`,
          );
          ringConfig = `ring-1 ring-inset ring-current focus-visible:ring-2 focus-visible:ring-${this.color()}-500 dark:focus-visible:ring-${this.color()}-400`;
          break;
        case 'ghost':
          config = twMerge(
            config,
            `border-0 bg-transparent text-${this.color()}-500 hover:text-${this.color()}-500 dark:text-${this.color()}-400 hover:bg-${this.color()}-50 disabled:bg-transparent aria-disabled:bg-transparent dark:hover:bg-${this.color()}-950 dark:disabled:bg-transparent dark:aria-disabled:bg-transparent`,
          );
          ringConfig = `focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-${this.color()}-500 dark:focus-visible:ring-${this.color()}-400`;
          break;
        case 'link':
          config = twMerge(
            config,
            `text-${this.color()}-500 hover:text-${this.color()}-600 disabled:text-${this.color()}-500 aria-disabled:text-${this.color()}-500 dark:text-${this.color()}-400 dark:hover:text-${this.color()}-500 dark:disabled:text-${this.color()}-400 dark:aria-disabled:text-${this.color()}-400 underline-offset-4 hover:underline`,
          );
          ringConfig = `focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-${this.color()}-500 dark:focus-visible:ring-${this.color()}-400`;
          break;
        case 'soft':
          config = twMerge(
            config,
            `ring-1 ring-inset ring-current text-${this.color()}-500 hover:text-${this.color()}-500 dark:text-${this.color()}-400 bg-${this.color()}-50 hover:bg-${this.color()}-100 disabled:bg-${this.color()}-50 aria-disabled:bg-${this.color()}-50 dark:bg-${this.color()}-950 dark:hover:bg-${this.color()}-900 dark:disabled:bg-${this.color()}-950 dark:aria-disabled:bg-${this.color()}-950`,
          );
          ringConfig = `focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-${this.color()}-500 dark:focus-visible:ring-${this.color()}-400`;
          break;
      }

    if (this.borderOutline()) {
      config = twMerge(config, `border border-main-500 dark:border-main-400`);
    } else {
      config = twMerge(config, ringConfig);
    }

    config = twMerge(config, this.rounded() ? 'rounded-full' : 'rounded-md');

    return twMerge(config, this.sizeConfig());
  });

  iconConfig = computed(() => {
    let size = '';
    switch (this.size()) {
      case 'xs':
        size = 'h-4 w-4';
        break;
      case 'sm':
        size = 'h-[1.1rem] w-[1.1rem]';
        break;
      case 'md':
      case 'lg':
        size = 'h-5 w-5';
        break;
      case 'xl':
        size = 'h-6 w-6';
        break;
    }
    if (this.iconOnHover()) {
      size = twJoin(size, 'opacity-0 group-hover/button:opacity-100');
    }
    return twJoin(size, this.icon());
  });

  handleClick(event: MouseEvent) {
    if (this.clickAction) {
      this.clickAction();
    }
    this.onClick.emit(event);
  }

  agInit(params: ICellRendererParams<any, any, any> & ButtonProps): void {
    this.setProps(params);
  }

  refresh(params: ICellRendererParams<any, any, any> & ButtonProps): boolean {
    this.setProps(params);
    return true;
  }

  private setProps(props: ButtonProps) {
    if (props.label) this.label.set(props.label);
    if (props.type) this.type.set(props.type);
    if (props.size) this.size.set(props.size);
    if (props.variant) this.variant.set(props.variant);
    if (props.color) this.color.set(props.color);
    if (props.to) this.to.set(props.to);
    if (props.href) this.href.set(props.href);
    if (props.target) this.target.set(props.target);
    if (props.icon) this.icon.set(props.icon);
    if (props.tooltipValue) this.tooltipValue.set(props.tooltipValue);
    if (props.queryParams) this.queryParams.set(props.queryParams);
    if (props.queryParamsHandling) this.queryParamsHandling.set(props.queryParamsHandling);
    if (props.spaceBetween) this.spaceBetween.set(props.spaceBetween);
    if (props.square) this.square.set(props.square);
    if (props.block) this.block.set(props.block);
    if (props.iconOnHover) this.iconOnHover.set(props.iconOnHover);
    if (props.padded) this.padded.set(props.padded);
    if (props.disabled) this.disabled.set(props.disabled);
    if (props.loading) this.loading.set(props.loading);
    if (props.trailing) this.trailing.set(props.trailing);
    if (props.clickAction) this.clickAction = props.clickAction;
  }
}

type ButtonProps = {
  label: string;
  disabled: boolean;
  type: 'button' | 'submit';
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  variant: 'solid' | 'ghost' | 'soft' | 'outline' | 'link';
  color: 'main' | 'green' | 'red' | 'orange' | 'white' | 'gray';
  to: string;
  href: string;
  target: '_blank' | '_parent' | '_self' | '_top';
  icon: string;
  tooltipValue: string;
  queryParams: Params | null;
  queryParamsHandling: 'merge' | 'preserve' | '';
  trailing: boolean;
  square: boolean;
  block: boolean;
  iconOnHover: boolean;
  padded: boolean;
  loading: boolean;
  spaceBetween: boolean;
  clickAction: () => void;
};
