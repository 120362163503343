import { Component, Input, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Marketplace } from "@front/m19-api-client";
import { Catalog, ProductGroupEx, SegmentConfigType, SegmentEx } from "@front/m19-models";
import { BsModalRef } from "ngx-bootstrap/modal";
import { AsinService } from "@front/m19-services";

@UntilDestroy()
@Component({
  selector: "app-product-group-detail-popup",
  templateUrl: "./product-group-detail-popup.component.html",
  styleUrls: ["./product-group-detail-popup.component.scss"],
})
export class ProductGroupDetailsPopupComponent implements OnInit {
  @Input()
  group!: ProductGroupEx | SegmentEx;
  @Input()
  marketplace!: Marketplace;

  productTitles?: { [key: string]: string };
  filteredAsins: string[] = [];
  shownAsins: string[] = [];
  loading = true;

  private filter = new RegExp("", "i");
  private catalog?: Catalog;
  readonly faSearch = faSearch;
  readonly itemsShown = 15;
  pageEvent = new PageEvent();

  constructor(
    public ref: BsModalRef,
    private asinService: AsinService,
  ) {}

  ngOnInit() {
    this.productTitles = {};
    this.searchInProductList();

    this.asinService
      .getCatalog(this.group.accountId, this.marketplace)
      .pipe(untilDestroyed(this))
      .subscribe((catalog) => {
        this.productTitles = {};
        if (catalog) {
          this.catalog = catalog;
          if (this.group instanceof ProductGroupEx) {
            for (const asin of this.group.items) {
              this.productTitles[asin] = this.catalog.asinOffers.get(asin)?.title ?? "";
            }
          } else {
            if (this.group.segmentType === SegmentConfigType.ProductSegment) {
              for (const asin of this.group.items.map((item) => item.targetingValue)) {
                this.productTitles[asin] = this.catalog.asinOffers.get(asin)?.title ?? "";
              }
            }
          }
        }
        this.searchInProductList();
        this.loading = false;
      });
  }

  setFilter(query: string): void {
    this.filter = new RegExp(query, "i");
    this.searchInProductList();
  }

  changeContent(event: PageEvent) {
    this.pageEvent = event;
    this.shownAsins = this.filteredAsins.slice(
      event.pageIndex * event.pageSize,
      (event.pageIndex + 1) * event.pageSize,
    );
  }

  private searchInProductList(): void {
    if (this.group instanceof ProductGroupEx) {
      this.filteredAsins = this.group.items.filter(
        (asin) => this.filter.test(asin) || this.filter.test(this.productTitles![asin]),
      );
    } else {
      this.filteredAsins = this.group.items
        .map((item) => item.targetingValue)
        .filter((asin) => this.filter.test(asin) || this.filter.test(this.productTitles![asin]));
    }

    this.shownAsins = this.filteredAsins.slice(0, this.itemsShown);
  }

  getName(group: ProductGroupEx | SegmentEx) {
    if (group instanceof ProductGroupEx) return group.productGroupName;
    else return group.name;
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
