import { AgGridAngular } from "@ag-grid-community/angular";
import {
  CellClickedEvent,
  ColDef,
  ColGroupDef,
  ColumnRowGroupChangedEvent,
  GetRowIdParams,
  GridOptions,
  ICellRendererParams,
  IRowNode,
  ITooltipParams,
} from "@ag-grid-community/core";
import { Component, Input, OnInit, ViewChild } from "@angular/core";

import {
  AccountMarketplace,
  AccountType,
  AlgoMode,
  CampaignType,
  CostOfGoods,
  Currency,
  InventoryConfig,
  Marketplace,
  Strategy,
  StrategyStateEnum,
} from "@front/m19-api-client";
import {
  getBasicGridOptions,
  getFilteredLeafNodes,
  PARENT_ASIN_COL_NAME,
  ProductGridData,
} from "@front/m19-grid-config";
import {
  ACOS,
  AD_CONVERSIONS,
  AD_SALES,
  CLICK_THROUGH_RATE,
  CLICKS,
  CONVERSION_RATE,
  COST,
  CPC,
  IMPRESSIONS,
  Metric,
  MetricRegistry,
  ROAS,
} from "@front/m19-metrics";
import {
  AdStatsEx,
  BrandingFilter,
  Catalog,
  Marketplaces,
  OrderStats,
  ProductGroupEx,
  StrategyEx,
} from "@front/m19-models";
import {
  AccountSelectionService,
  AsinService,
  AuthService,
  CatalogBrandService,
  DataSet,
  DataSetEventAnnotation,
  ProductGroupService,
  SbStrategiesService,
  SdStrategiesService,
  SpStrategiesService,
  StrategyService,
  UserSelectionService,
} from "@front/m19-services";
import { IBadgeComponent, IButtonComponent, Option } from "@front/m19-ui";
import {
  AggregationFunction,
  DateAggregation,
  emptyOrderStat,
  mergeAdStatsWithOrderStats,
  mergeAllStats,
  MetricsSelectorLocalStorageKey,
  Utils,
} from "@front/m19-utils";
import { TranslocoService } from "@jsverse/transloco";
import { CogsUpdateModalComponent } from "@m19-board/catalog/cog-update-modal/cogs-upload-modal.component";
import {
  ACTIONS_COL_ID,
  exportGridCsv,
  getCsvFileName,
  getMetricsColDef,
  IMAGE_COL_ID,
} from "@m19-board/grid-config/grid-config";
import { ActionButtonsComponent } from "@m19-board/insights/overview/action-buttons/action-buttons.component";
import { MetricEvoComponent } from "@m19-board/metric-evo/metric-evo.component";
import { StrategyStats } from "@m19-board/models/Metric";
import {
  APP_RATIO,
  BUY_BOX_RATE,
  COST_OF_GOODS,
  FBA_GLOBAL_REIMBURSEMENT,
  NET_PURE_PRODUCT_MARGIN,
  ORDER_GLOBAL_ADV,
  ORDER_GLOBAL_FEE,
  ORDER_GLOBAL_MARGIN,
  ORDER_GLOBAL_NET_SALES,
  PAGE_VIEWS,
  PROFIT,
  PROMOTION,
  REFUNDED_SALES,
  SESSIONS,
  SPONSORED_SALES_SHARE,
  TACOS,
  TOTAL_CONVERSION_RATE,
  TOTAL_ORDERS,
  TOTAL_SALES,
  VENDOR_CUSTOMER_RETURNS,
  VENDOR_SHIPPED_COGS,
} from "@m19-board/models/MetricsDef";
import { AsinLinkComponent } from "@m19-board/product-view/asin-link.component";
import { ProductThumbnailComponent } from "@m19-board/product-view/product-thumbnail.component";
import { ProfitDetailsComponent } from "@m19-board/profit-board/profit-details/profit-details.component";
import { ProfitService } from "@m19-board/profit-board/profit-service";
import { ChartData, ChartRendererComponent } from "@m19-board/shared/chart-renderer/chart-renderer.component";
import { Filter } from "@m19-board/shared/filter/filter.component";
import { ICON_CHART_LINE, ICON_CLOSE, ICON_EDIT_O, ICON_LIST } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, delay, filter, Observable, of, switchMap } from "rxjs";
import { startWith } from "rxjs/operators";
import { ActivityEventType, ActivityService } from "../activities/activity.service";
import { actionColumnProperties, CurrencyColumn, ImageColumn } from "../grid-config/grid-columns";
import { DefaultAdStatsMetricsWithSameScale } from "../models/DataSet";
import { ProductDetailsComponent } from "./product-details/product-details.component";
import { AsinFilter } from "@m19-board/shared/filter/asinFilter";

const CHART_METRICS_VENDOR: Metric<AdStatsEx>[] = [
  AD_SALES,
  AD_CONVERSIONS,
  COST,
  ACOS,
  CLICKS,
  IMPRESSIONS,
  CPC,
  CONVERSION_RATE,
  ROAS,
  PAGE_VIEWS,
  CLICK_THROUGH_RATE,
  TOTAL_SALES,
  TOTAL_ORDERS,
  TACOS,
  SPONSORED_SALES_SHARE,
  VENDOR_SHIPPED_COGS,
  VENDOR_CUSTOMER_RETURNS,
];

const CHART_ASIN_VENDOR_METRICS: Metric<AdStatsEx>[] = CHART_METRICS_VENDOR.concat([NET_PURE_PRODUCT_MARGIN]);
const DEFAULT_GRID_METRICS = new Set([
  TOTAL_SALES,
  TOTAL_ORDERS,
  TACOS,
  AD_SALES,
  COST,
  AD_CONVERSIONS,
  ACOS,
  CONVERSION_RATE,
  CLICK_THROUGH_RATE,
  CPC,
  ROAS,
  SPONSORED_SALES_SHARE,
]);

const CHART_METRICS_SELLER: Metric<OrderStats>[] = [
  TOTAL_SALES,
  TOTAL_ORDERS,
  TACOS,
  AD_SALES,
  AD_CONVERSIONS,
  COST,
  ACOS,
  CLICKS,
  IMPRESSIONS,
  CPC,
  CONVERSION_RATE,
  ROAS,
  SPONSORED_SALES_SHARE,
  SESSIONS,
  PAGE_VIEWS,
  APP_RATIO,
  BUY_BOX_RATE,
  TOTAL_CONVERSION_RATE,
  CLICK_THROUGH_RATE,
  ORDER_GLOBAL_FEE,
  COST_OF_GOODS,
  PROMOTION,
  PROFIT,
  ORDER_GLOBAL_MARGIN,
  REFUNDED_SALES,
];

const CHART_DETAILS_METRICS: Metric<OrderStats>[] = [
  ORDER_GLOBAL_NET_SALES,
  ORDER_GLOBAL_ADV,
  ORDER_GLOBAL_FEE,
  COST_OF_GOODS,
  FBA_GLOBAL_REIMBURSEMENT,
  PROFIT,
  ORDER_GLOBAL_MARGIN,
];

@UntilDestroy()
@Component({
  selector: "app-sales-advertising",
  templateUrl: "./sales-advertising.component.html",
  styleUrls: ["./sales-advertising.component.scss"],
})
export class SalesAdvertisingComponent implements OnInit {
  readonly localStorageKey = MetricsSelectorLocalStorageKey.salesAndAdvertising;
  readonly ICON_CLOSE = ICON_CLOSE;
  readonly ICON_CHART = ICON_CHART_LINE;
  readonly MetricsSelectorLocalStorageKey = MetricsSelectorLocalStorageKey;

  @Input({ required: true })
  accountType!: AccountType;

  dataLoading = false;
  totalData: OrderStats = { advertising: 0, fee: 0, netSales: 0, promotion: 0, fbaGlobalReimbursement: 0 };
  previousTotalData: OrderStats = { advertising: 0, fee: 0, netSales: 0, promotion: 0, fbaGlobalReimbursement: 0 };
  dataByAsin: Map<string, (OrderStats & AdStatsEx)[]> = new Map(); // Stats by ASIN
  previousDataByAsin: Map<string, (OrderStats & AdStatsEx)[]> = new Map();

  selectedMetrics: Metric<OrderStats | AdStatsEx>[] = [AD_SALES, COST];

  dateAggregation = DateAggregation.daily;

  asinStratMap: Map<string, Strategy[]> = new Map();
  stratAsinMap: Map<number, string[]> = new Map();

  currency = Currency.EUR;
  locale = "fr";
  productGroups: ProductGroupEx[] = [];
  strategies: StrategyEx[] = [];
  marketplace?: Marketplace;
  accGroup?: string;
  accountId?: string;
  isReadOnly = false;

  comparePeriods?: string[];
  isGlobalChartHidden = false;

  private readonly gridKeyPrefix = "salesAdvertisingGrid2";
  chartMetrics?: Metric<StrategyStats>[] | Metric<OrderStats>[];

  displayEventAnnotation = false;
  disableEventAnnotation = false;
  events: DataSetEventAnnotation[] | undefined = undefined;

  eventAnnotations$: Observable<DataSetEventAnnotation[]> = this.activityService.activityEventsAnnotations$.pipe(
    filter(() => this.displayEventAnnotation),
    startWith([]),
  );
  allEventAnnotationTypes: Option<ActivityEventType>[] = this.activityService.allActivityEventTypesOptions;

  allUsers$: Observable<Option<string>[]> = this.activityService.allUsersOptions$;

  allStrategies$: Observable<Option<StrategyEx>[]> = this.activityService.allStrategiesOptions$;

  isGroupByParent = false;

  dateRange: string[] = [];

  catalog?: Catalog;
  parentAsinTitles?: Map<string, string>;
  inventoryConfig?: InventoryConfig;

  // Ag grid
  @ViewChild("grid") grid!: AgGridAngular;

  gridData?: ProductGridData[];
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  public columnDefs: ColDef[] = [];

  gridOptions?: GridOptions;

  globalDataset: DataSet<OrderStats> = this.initDataset();
  asinDataset = this.initDataset();

  modalData$ = new BehaviorSubject<ChartData<OrderStats> | null>(null);
  modalNode?: IRowNode<ProductGridData>;

  filter$ = new BehaviorSubject<Set<string> | null>(null);
  filter: Set<string> | null = null;

  // cogs update
  private cogsHistoryByAsin: Map<string, CostOfGoods[]> = new Map();

  customField1Values: Option<string>[] = [];
  customField2Values: Option<string>[] = [];
  customFiel1dMap: Map<string, string[]> = new Map();
  customField2Map: Map<string, string[]> = new Map();
  brandTrafficOptions: Option<BrandingFilter>[] = [];

  protected readonly Number = Number;

  asinFilterTags = new AsinFilter(this.translocoService);
  filters: Filter<StrategyEx | ProductGroupEx | BrandingFilter | string>[] = this.asinFilterTags.getFilterTags();

  constructor(
    private authService: AuthService,
    private productGroupService: ProductGroupService,
    private userSelectionService: UserSelectionService,
    private accountSelection: AccountSelectionService,
    private toasterService: ToastrService,
    private strategyService: StrategyService,
    private asinService: AsinService,
    private modalService: BsModalService,
    private activityService: ActivityService,
    private profitService: ProfitService,
    private catalogBrandService: CatalogBrandService,
    private translocoService: TranslocoService,
    private spStrategiesService: SpStrategiesService,
    private sbStrategiesService: SbStrategiesService,
    private sdStrategiesService: SdStrategiesService,
  ) {}

  ngOnInit(): void {
    this.globalDataset.metricsOnSameScale = DefaultAdStatsMetricsWithSameScale;
    this.asinDataset.metricsOnSameScale = DefaultAdStatsMetricsWithSameScale;

    this.columnDefs = this.getColumnDefs();
    this.gridOptions = this.getGridOptions();
    this.isGlobalChartHidden = !this.userSelectionService.getUserChartDisplayedPreference(this.localStorageKey);

    combineLatest([this.authService.loggedUser$, this.userSelectionService.selectedCurrency$])
      .pipe(untilDestroyed(this))
      .subscribe({
        next: ([user, currency]: [any, Currency]) => {
          this.locale = user.locale;
          this.currency = currency;
          this.asinDataset.locale = user.locale;
          this.asinDataset.currency = currency;

          if (this.globalDataset) {
            this.globalDataset.locale = user.locale;
            this.globalDataset.currency = currency;
          }
        },
      });

    this.accountSelection.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));

    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am: AccountMarketplace) => {
          this.marketplace = am.marketplace;
          this.chartMetrics = this.getChartMetrics();
          this.accountId = am.accountId;
          this.accGroup = am.accountGroupName;
          return combineLatest([
            this.spStrategiesService.getSPStrategiesPerAsin(am.accountId, am.marketplace),
            this.sbStrategiesService.getSBStrategiesPerAsin(am.accountId, am.marketplace),
            this.sdStrategiesService.getSDStrategiesPerAsin(am.accountId, am.marketplace),
            this.asinService.getCatalog(am.accountId, am.marketplace),
          ]);
        }),
        switchMap(([spAsinStratIndex, sbAsinStratIndex, sdAsinStratIndex, catalog]) => {
          if (catalog) {
            this.catalog = catalog;
            this.parentAsinTitles = new Map();
          }
          const customField1Values = new Set<string>();
          const customField2Values = new Set<string>();
          for (const product of catalog.products) {
            if (product.customField1) customField1Values.add(product.customField1);
            if (product.customField2) customField2Values.add(product.customField2);
          }
          this.customField1Values = Array.from(customField1Values).map((v) => ({ label: v, value: v }));
          this.customField2Values = Array.from(customField2Values).map((v) => ({ label: v, value: v }));
          // map custom field values to asin
          this.customFiel1dMap.clear();
          this.customField2Map.clear();
          for (const product of catalog.products) {
            if (product.customField1) {
              if (!this.customFiel1dMap.has(product.customField1)) {
                this.customFiel1dMap.set(product.customField1, []);
              }
              this.customFiel1dMap.get(product.customField1)!.push(product.asin!);
            }

            if (product.customField2) {
              if (!this.customField2Map.has(product.customField2)) {
                this.customField2Map.set(product.customField2, []);
              }
              this.customField2Map.get(product.customField2)!.push(product.asin!);
            }
          }
          this.asinFilterTags.setCustomField1Values(this.customField1Values);
          this.asinFilterTags.setCustomField2Values(this.customField2Values);
          return of([spAsinStratIndex, sbAsinStratIndex, sdAsinStratIndex]);
        }),
      )
      .subscribe(([spAsinStratIndex, sbAsinStratIndex, sdAsinStratIndex]) => {
        this.asinStratMap.clear();
        this.stratAsinMap = new Map();
        for (const [asin, strats] of [...spAsinStratIndex, ...sbAsinStratIndex, ...sdAsinStratIndex]) {
          if (this.asinStratMap.has(asin)) {
            this.asinStratMap.get(asin)!.push(...strats);
          } else {
            this.asinStratMap.set(asin, [...strats]);
          }
          for (const s of strats) {
            if (!this.stratAsinMap.has(s.strategyId!)) {
              this.stratAsinMap.set(s.strategyId!, []);
            }
            this.stratAsinMap.get(s.strategyId!)!.push(asin);
          }
        }
      });

    combineLatest([
      this.filter$,
      this.userSelectionService.selectedDateRange$,
      this.userSelectionService.periodComparison$,
      this.accountSelection.singleAccountMarketplaceSelection$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([_f, _dr, comp, _am]) => {
        this.dataLoading = true;
        this.grid?.api.showLoadingOverlay();
        this.comparePeriods = comp?.period;
      });

    combineLatest([this.profitService.orderStatsByAsinDateWithDailyAsinStats$, this.filter$, this.eventAnnotations$])
      .pipe(untilDestroyed(this))
      .subscribe(([stats, filter, events]) => {
        this.filter = filter;
        this.events = events;

        const orderStats = Array.from(stats.orderStatsByAsinDate.values()).flatMap((x) => Array.from(x.values()));

        const previousOrderStats = Array.from(stats.previousOrderStatsByAsinDate.values()).flatMap((x) =>
          Array.from(x.values()),
        );

        this.totalData = this.computeTotalData(stats.dailyAsinStats.data, orderStats, filter);
        this.previousTotalData = this.computeTotalData(
          stats.dailyAsinStats.previousPeriodData,
          previousOrderStats,
          filter,
        );

        this.dataByAsin = this.computeDataByAsin(stats.dailyAsinStats.data, orderStats);

        this.previousDataByAsin = this.computeDataByAsin(stats.dailyAsinStats.previousPeriodData, previousOrderStats);

        this.buildDataset(this.events);

        this.setGridData();
        if (!this.grid?.api.isDestroyed()) {
          this.grid?.api.redrawRows();
          this.grid?.api.onFilterChanged();
          this.grid?.api.refreshCells({ force: true }); // force change detection
          this.grid?.api.hideOverlay();
        }
        if (this.modalNode) {
          this.modalData$.next(this.getModalData(this.modalNode));
        }

        this.dataLoading = false;
      });

    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am: AccountMarketplace) =>
          combineLatest([
            this.productGroupService.getProductGroups(am.accountId, am.marketplace),
            this.strategyService.getStrategyIndex(am.accountId, am.marketplace),
            this.catalogBrandService.getBrandingFilters(am.accountId, am.marketplace),
          ]),
        ),
        untilDestroyed(this),
      )
      .subscribe(([pgs, strategies, brandingFilters]) => {
        this.strategies = Array.from(strategies.values()).map((s) => new StrategyEx(s));
        this.productGroups = pgs;
        this.brandTrafficOptions = brandingFilters.map((b) => ({ label: b.name ?? "", value: b }));
        this.asinFilterTags.setProductGroups(this.productGroups);
        this.asinFilterTags.setStrategies(this.strategies);
        this.asinFilterTags.setBrandTrafficOptions(this.brandTrafficOptions);
      });

    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((accountMarketplace) =>
          this.asinService.getCostOfGoods(accountMarketplace.accountId, accountMarketplace.marketplace),
        ),
        untilDestroyed(this),
      )
      .subscribe((cogs) => {
        this.cogsHistoryByAsin.clear();
        for (const [asin, asinCogs] of cogs) {
          const cogsHistory = this.cogsHistoryByAsin.get(asin) ?? [];
          cogsHistory.push(
            ...asinCogs.map(([startDate, costOfGoods]) => ({
              asin,
              startDate,
              costOfGoods,
            })),
          );
          this.cogsHistoryByAsin.set(asin, cogsHistory);
        }
      });
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) => this.asinService.getInventoryConfig(am.accountId, am.marketplace)),
        delay(100), //force asynchronous reload
        untilDestroyed(this),
      )
      .subscribe((config) => {
        this.inventoryConfig = config;
        // refresh grid and header names
        this.grid?.api?.refreshHeader();
        this.grid?.api?.refreshToolPanel();
        // update filter chips
        this.filters[3].label = this.inventoryConfig.customField1Name ?? "Custom Field 1";
        this.filters[4].label = this.inventoryConfig.customField2Name ?? "Custom Field 2";
      });
  }

  toggleGlobalChartDisplay(hide: boolean) {
    this.isGlobalChartHidden = hide;
    this.userSelectionService.setUserChartDisplayedPreference(this.localStorageKey, !this.isGlobalChartHidden);
  }

  private initDataset(): DataSet<OrderStats> {
    return new DataSet<OrderStats>(3, [AD_SALES, COST], [AggregationFunction.mergeAllStats], this.translocoService);
  }

  // Merge and sum AdStats and OrderStats
  private computeTotalData(dailyStats: AdStatsEx[], ordersStats: OrderStats[], filter: Set<string> | null): OrderStats {
    const mergedStats = [...dailyStats, ...ordersStats].filter((s) =>
      AsinFilter.asinMatchesFilter(s.asin!, filter),
    ) as (OrderStats & AdStatsEx)[];
    return mergeAdStatsWithOrderStats(mergedStats);
  }

  private computeDataByAsin(
    dailyStats: AdStatsEx[],
    ordersStats: OrderStats[],
  ): Map<string, (OrderStats & AdStatsEx)[]> {
    const result: Map<string, (OrderStats & AdStatsEx)[]> = new Map();
    const mergedStats = [...dailyStats, ...ordersStats];
    for (const stat of mergedStats) {
      Utils.insertInArrayMap(result, stat.asin, stat);
    }
    return result;
  }

  // Aggregate data by ASIN and create array from map
  private setGridData() {
    const aggDataArray: OrderStats[] = [];

    for (const [asin, stats] of this.dataByAsin) {
      const aggData = mergeAdStatsWithOrderStats(stats);
      aggDataArray.push({ ...aggData, asin });
    }

    const dataByProducts = new Map(this.catalog?.products.map((x) => [x.asin, x]));

    this.gridData = aggDataArray.map((x: OrderStats) => ({
      ...x,
      customField1: dataByProducts.get(x.asin)?.customField1,
      customField2: dataByProducts.get(x.asin)?.customField2,
    }));
    this.grid?.api.updateGridOptions({ rowData: this.gridData });
  }

  private buildDataset(events?: DataSetEventAnnotation[]) {
    const data = Array.from(this.dataByAsin.values())
      .flat()
      .filter((d) => AsinFilter.asinMatchesFilter(d.asin!, this.filter));
    const previousData = Array.from(this.previousDataByAsin.values())
      .flat()
      .filter((d) => AsinFilter.asinMatchesFilter(d.asin!, this.filter));

    const dateRange: string[] = this.userSelectionService.getDateRangeStr();

    this.globalDataset?.buildDataSet(
      data,
      this.selectedMetrics,
      this.dateAggregation,
      { minDate: dateRange[0], maxDate: dateRange[1] },
      this.comparePeriods ? { data: previousData, period: this.comparePeriods } : undefined,
      this.displayEventAnnotation && this.dateAggregation === DateAggregation.daily ? events : undefined,
    );
  }

  toggleEventAnnotationDisplay(displayEvents: boolean) {
    this.displayEventAnnotation = displayEvents;
    this.buildDataset(this.events);
  }

  selectMetrics(metrics: Metric<AdStatsEx>[]) {
    this.selectedMetrics = metrics;
    this.globalDataset.renderDataset(this.events, this.selectedMetrics, this.dateAggregation);
  }

  selectAggreation(aggregation: DateAggregation) {
    this.dateAggregation = aggregation;
    this.disableEventAnnotation = this.dateAggregation !== DateAggregation.daily;
    this.buildDataset(this.events);
  }

  private getStrategies(asin: string): Strategy[] | undefined {
    if (!this.asinStratMap) return undefined;
    const strategyIds: Set<number> = new Set();
    const result: Strategy[] = [];
    if (this.asinStratMap.has(asin)) {
      this.asinStratMap.get(asin)!.forEach((s) => {
        if (!strategyIds.has(s.strategyId!)) {
          strategyIds.add(s.strategyId!);
          result.push(s);
        }
      });
    }
    // also get child ASIN strategies
    if (this.catalog && (this.catalog.parentAsins.get(asin)?.length ?? 0) > 0) {
      this.catalog.parentAsins.get(asin)?.forEach((child) =>
        (this.asinStratMap.get(child) ?? []).forEach((s) => {
          if (!strategyIds.has(s.strategyId!)) {
            strategyIds.add(s.strategyId!);
            result.push(s);
          }
        }),
      );
    }
    return result;
  }

  // Computes previous data for grouped nodes
  private getPreviousNodeData(node: IRowNode): OrderStats | undefined {
    if (!this.comparePeriods) return undefined;
    let previousData: OrderStats = emptyOrderStat("", "", this.currency);

    getFilteredLeafNodes(node).forEach((c: IRowNode) => {
      const childPreviousData = mergeAdStatsWithOrderStats(this.previousDataByAsin.get(c.data.asin) ?? []);

      if (childPreviousData) {
        previousData = mergeAllStats(previousData, childPreviousData);
      }
    });

    return previousData;
  }

  applyFilter(filters: Filter<ProductGroupEx | Strategy | BrandingFilter | string>[]) {
    this.filter$.next(AsinFilter.filterChanged(filters, this.stratAsinMap, this.customFiel1dMap, this.customField2Map));
  }

  exportGridCsv(): void {
    const fileName = getCsvFileName("sales_advertising_stats_", this.accGroup, this.marketplace, this.dateRange);
    const columnKeys: string[] = this.grid?.api
      .getAllDisplayedColumns()
      .map((c) => c.getColId())
      .filter((c) => c !== IMAGE_COL_ID && !c.startsWith(ACTIONS_COL_ID))
      .concat(["currency"]);
    // set customField header name
    this.grid.api.getColumnDef("CustomField1")!.headerName = this.inventoryConfig?.customField1Name ?? "CustomField1";
    this.grid.api.getColumnDef("CustomField2")!.headerName = this.inventoryConfig?.customField2Name ?? "CustomField2";

    exportGridCsv(this.grid?.api, { fileName, columnKeys, skipColumnGroupHeaders: true });
  }

  private getGridFilteredNodes(): IRowNode<ProductGridData>[] {
    const nodes: IRowNode[] = [];
    this.grid?.api.forEachNodeAfterFilterAndSort((node) => {
      if (!node.group) nodes.push(node);
    });
    return nodes;
  }

  private openChartModal(node: IRowNode<ProductGridData>) {
    this.modalNode = node;
    const modalData = this.getModalData(node);
    this.modalData$.next(modalData);

    this.modalService.show(ChartRendererComponent<OrderStats>, {
      initialState: {
        marketplace: this.marketplace,
        dataset: this.asinDataset,
        asin: (node.group ? node.key : node.data?.asin) ?? "",
        metrics: this.getAsinChartMetrics(),
        chartData$: this.modalData$,
        localStorageKey: MetricsSelectorLocalStorageKey.salesAndAdvertising,
      },
      class: "modal-xxl modal-dialog-centered",
    });
  }

  private getModalData(node: IRowNode<ProductGridData>): ChartData<OrderStats & AdStatsEx> {
    const targetedNodes = getFilteredLeafNodes(node, this.grid.api);

    const data: (OrderStats & AdStatsEx)[] = [];
    const previousData: (OrderStats & AdStatsEx)[] = [];

    targetedNodes.forEach((n) => {
      data.push(...this.dataByAsin.get(n.data.asin)!);
      if (this.comparePeriods) {
        const stats = this.previousDataByAsin.get(n.data.asin);
        if (stats) previousData.push(...stats);
      }
    });
    const totalData = mergeAdStatsWithOrderStats(data);
    const totalPreviousData = mergeAdStatsWithOrderStats(previousData);

    return { data, totalData, previousData, totalPreviousData };
  }

  openInfo(asin: string) {
    const modalOptions: ModalOptions = {
      initialState: {
        strategies: this.filterActiveStrategies(this.getStrategies(asin)),
        asinnumber: asin,
        marketplace: this.marketplace,
      },
      class: "modal-dialog-centered",
    };
    this.modalService.show(ProductDetailsComponent, modalOptions);
  }

  showUpdateCogsModal(asin: string) {
    const ref = this.modalService.show(CogsUpdateModalComponent, {
      initialState: {
        asin: asin,
        marketplace: this.marketplace,
        locale: this.locale,
        currency: Marketplaces[this.marketplace!].currency,
        cogsHistory:
          this.cogsHistoryByAsin.get(asin)?.map((c) => ({ startDate: c.startDate!, costOfGoods: c.costOfGoods! })) ??
          [],
      },
      class: "modal-xxl",
    });
    ref.content?.valueChange
      .pipe(
        switchMap((newCogsHistory) => {
          if (newCogsHistory === "deletion") {
            return this.asinService.setCostOfGoods(this.accountId!, this.marketplace!, asin, []);
          }
          const cogsHistory = newCogsHistory.map((c) => ({
            ...c,
            accountId: this.accountId,
            marketplace: this.marketplace,
            asin: asin,
          }));
          return this.asinService.setCostOfGoods(this.accountId!, this.marketplace!, asin, cogsHistory);
        }),
      )
      .subscribe({
        next: () => {
          this.toasterService.success("Cost of goods updated");
        },
        error: (err) => {
          this.toasterService.error(err, "Failed to update cost of goods");
        },
      });
  }

  private getColumnDefs(): (ColDef | ColGroupDef)[] {
    return [
      {
        headerName: this.translocoService.translate("sales-advertising.product_info", {}, "en"),
        headerValueGetter: () => this.translocoService.translate("sales-advertising.product_info"),
        marryChildren: true, // prevent column group break
        pinned: "left",
        lockPinned: true,
        children: [
          {
            ...ImageColumn,
            headerName: this.translocoService.translate("catalog-page.image", {}, "en"),
            headerValueGetter: () => this.translocoService.translate("catalog-page.image"),
            cellRendererSelector: (params) => {
              if (!params.value) return undefined;
              else {
                return {
                  component: ProductThumbnailComponent,
                  params: {
                    asin: params.value,
                    smallImg: true,
                    marketplace: this.marketplace,
                    customSizeClass: "size-16",
                  },
                };
              }
            },
          },
          {
            headerName: this.translocoService.translate("common.product", {}, "en"),
            headerValueGetter: () => this.translocoService.translate("common.product"),
            pinned: "left",
            colId: "asin_col",
            field: "asin",
            floatingFilter: true,
            filter: "agTextColumnFilter",
            cellRendererSelector: (params) => {
              if (params.node.group) return undefined;
              return {
                component: AsinLinkComponent,
                params: {
                  asin: params.value,
                  marketplace: this.marketplace,
                },
              };
            },
          },
          {
            field: "asin",
            headerName: this.translocoService.translate("common.brand", {}, "en"),
            headerValueGetter: () => this.translocoService.translate("common.brand"),
            colId: "brand_col",
            pinned: "left",
            floatingFilter: true,
            enableRowGroup: true,
            valueGetter: (params) => {
              return this.catalog?.getBrand(params.node?.group ? params.node.key : params.data.asin);
            },
            keyCreator: (params) => {
              return params.value?.toLocaleLowerCase(); // this is to group by brand regardless of case
            },
            cellRendererSelector: (params) => {
              if (params.node.isRowPinned() || !params.value) return undefined;
              return {
                component: IBadgeComponent,
                params: {
                  label: params.value,
                  color: "gray",
                },
              };
            },
            cellClass: "sensitive-data",
            filter: "agSetColumnFilter",
            filterParams: {
              valueFormatter: (params: any) => params.value,
            },
          },
          {
            field: "asin",
            chartDataType: "excluded",
            colId: "stratCol",
            pinned: "left",
            headerName: this.translocoService.translate("product-details.active_strategies", {}, "en"),
            headerValueGetter: () => this.translocoService.translate("product-details.active_strategies"),
            headerTooltip: this.translocoService.translate("product-details.active_strategies"),
            enableRowGroup: true,
            filter: "agNumberColumnFilter",
            valueGetter: (params) => {
              if (
                params.node?.isRowPinned() ||
                (params.node?.group && params.node.rowGroupColumn?.getColId() !== PARENT_ASIN_COL_NAME)
              )
                return undefined;
              const strategies = this.getStrategies(params.node?.group ? params.node.key : params.data.asin);
              return this.filterActiveStrategies(strategies)?.length ?? 0;
            },
            cellRendererSelector: (params) => {
              if (params.value === undefined) return undefined;
              return {
                component: IBadgeComponent,
                params: {
                  label: params.value + "",
                  rounded: true,
                  variant: params.value > 0 ? "solid" : "outline",
                },
              };
            },
            cellClass: "text-center cursor-pointer",
            onCellClicked: (e: CellClickedEvent) => {
              this.openInfo(e.node.group ? e.node.key : e.data.asin);
            },
          },
          {
            headerName: this.translocoService.translate("common.title", {}, "en"),
            headerValueGetter: (params) => this.translocoService.translate("common.title"),
            pinned: "left",
            maxWidth: 400,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            valueGetter: (params) =>
              (params.node?.group ? this.parentAsinTitles : this.catalog?.getProductTitles())?.get(
                params.node?.group ? params.node.key : params.data.asin,
              ) ?? "-",
            tooltipValueGetter: (p: ITooltipParams) => p.value,
            cellClass: "sensitive-data",
          },
          {
            colId: "CustomField1",
            field: "customField1",
            headerValueGetter: () =>
              this.inventoryConfig?.customField1Name
                ? Utils.titleCase(this.inventoryConfig.customField1Name)!
                : this.translocoService.translate("custom-field-edition.custom_field_1")!,
            pinned: "left",
            hide: true,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            enableRowGroup: true,
          },
          {
            colId: "CustomField2",
            field: "customField2",
            headerValueGetter: () =>
              this.inventoryConfig?.customField2Name
                ? Utils.titleCase(this.inventoryConfig?.customField2Name)!
                : this.translocoService.translate("custom-field-edition.custom_field_2")!,
            pinned: "left",
            hide: true,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            enableRowGroup: true,
          },
          {
            field: "parentAsin",
            colId: PARENT_ASIN_COL_NAME,
            headerName: this.translocoService.translate("product360.parent_asin", {}, "en"),
            headerValueGetter: () => this.translocoService.translate("product360.parent_asin"),
            hide: true,
            pinned: "left",
            enableRowGroup: true,
            valueGetter: (params) => {
              return (
                this.catalog?.getParentAsin(params.node?.group ? params.node.key : params.data.asin) ??
                params.data?.asin
              );
            },
            cellClass: "sensitive-data",
          },
        ],
      },
      ...getMetricsColDef(this.getAsinChartMetrics(), true).map((def) => ({
        ...def,
        headerName: this.translocoService.translate(`${def.headerName}`, {}, "en"),
        headerValueGetter: () => {
          return this.translocoService.translate(`${def.headerName}`);
        },
        children: def.children.map((c: ColDef) => {
          const m = MetricRegistry.get(c.colId!)!;
          const visible = DEFAULT_GRID_METRICS.has(m);

          return {
            ...c,
            hide: !visible,
            headerName: this.translocoService.translate(`metrics.${c.colId}_title`, {}, "en"),
            headerValueGetter: () => this.translocoService.translate(`metrics.${m.id}_title`),
            cellRenderer: m.id === COST_OF_GOODS.id ? IButtonComponent : MetricEvoComponent,
            cellRendererParams: (params: ICellRendererParams) => {
              if (m.id === COST_OF_GOODS.id) {
                return {
                  label: m.format(params.data ?? params.node.aggData, this.locale, this.currency),
                  variant: "ghost",
                  color: "gray",
                  icon: !params.node.group && !params.node.isRowPinned() ? ICON_EDIT_O : undefined,
                  iconOnHover: true,
                  disabled: this.isReadOnly || params.node.group || params.node.isRowPinned(),
                  clickAction: () => this.showUpdateCogsModal(params.node.data.asin),
                };
              }
              return {
                ...c.cellRendererParams(params),
                previousData: params.node.isRowPinned() ? undefined : this.getPreviousNodeData(params.node),
                currency: this.currency,
                locale: this.locale,
              };
            },
          };
        }),
      })),

      {
        ...actionColumnProperties<StrategyEx, string>(),
        cellRenderer: ActionButtonsComponent,
        cellRendererParams: (params: any) => {
          const actionButtons = [
            {
              icon: ICON_CHART_LINE,
              tooltipValue: `Display ${params.node.isRowPinned() ? "total" : ""} ASIN chart with timeline events`,
              color: "white",
              onClick: () => {
                this.openChartModal(params.node);
              },
            },
          ];
          if (this.accountType !== AccountType.VENDOR) {
            actionButtons.push({
              color: "white",
              icon: ICON_LIST,
              tooltipValue: "Show Profit Details",
              onClick: () => {
                this.modalService.show(ProfitDetailsComponent, {
                  initialState: {
                    asin: params.node.data?.asin,
                    marketplace: this.marketplace,
                    metrics: CHART_DETAILS_METRICS,
                    data: params.node.group ? params.node.aggData : params.node.data,
                    totalData: this.totalData,
                    locale: this.locale,
                    currency: this.currency,
                  },
                  class: "modal-dialog-centered modal-primary",
                });
              },
            });
          }

          return { actionButtons };
        },
      },

      {
        ...CurrencyColumn,
        valueGetter: () => this.currency,
      },
    ];
  }

  private getChartMetrics() {
    return this.accountType == AccountType.VENDOR ? CHART_METRICS_VENDOR : CHART_METRICS_SELLER;
  }

  private getAsinChartMetrics(): Metric<OrderStats>[] {
    return this.accountType == AccountType.VENDOR ? CHART_ASIN_VENDOR_METRICS : CHART_METRICS_SELLER;
  }

  toggleParentAsin() {
    this.isGroupByParent = !this.isGroupByParent;
    this.grid?.api.setRowGroupColumns(this.isGroupByParent ? [PARENT_ASIN_COL_NAME] : []);
  }

  onColumnRowGroupChanged(event: ColumnRowGroupChangedEvent<OrderStats>) {
    this.isGroupByParent = !!(event.column?.isRowGroupActive() && event.column.getColId() === PARENT_ASIN_COL_NAME);
  }

  restoreDefaultColumns() {
    this.grid.api?.resetColumnState();
    this.grid.api?.autoSizeAllColumns();
  }

  private filterActiveStrategies(strategies: Strategy[] | undefined): Strategy[] {
    return (
      strategies?.filter((x) => {
        if (x.state == StrategyStateEnum.ENABLED) {
          if (x.algoMode === AlgoMode.TACOS_TARGET) {
            return x.campaignType === CampaignType.SP;
          }
          return true;
        }
        return false;
      }) ?? []
    );
  }

  private getGridOptions(): GridOptions {
    const commonOptions = getBasicGridOptions(this.gridKeyPrefix + this.accountType, true);
    return {
      ...commonOptions,
      pagination: true,
      paginationPageSize: 10,
      paginationPageSizeSelector: [10, 25, 50, 100],
      columnDefs: this.getColumnDefs(),
      rowGroupPanelShow: "always",
      context: { componentParent: this },
      autoGroupColumnDef: { pinned: "left" },
      showOpenedGroup: false,
      defaultColDef: this.defaultColDef,
      enableCharts: true,
      groupDisplayType: "singleColumn",
      rowDragManaged: false,
      debounceVerticalScrollbar: true, // see https://www.ag-grid.com/angular-data-grid/scrolling-performance/#debounce-vertical-scroll
      suppressRowHoverHighlight: true,
      isExternalFilterPresent: () => true,
      doesExternalFilterPass: (node) => AsinFilter.asinMatchesFilter(node.data.asin, this.filter$.value),
      onModelUpdated: (params) => {
        const totalRenderedData: OrderStats = this.getGridFilteredNodes().reduce(
          (acc, curr) => mergeAllStats(acc, curr.data!) as OrderStats,
          emptyOrderStat("", "", this.currency ?? Currency.EUR),
        );
        params.api.setGridOption("pinnedBottomRowData", [totalRenderedData]);
      },
      getRowId: (params: GetRowIdParams<ProductGridData>) => params.data.asin ?? "",
      getGroupRowAgg: (params) => {
        const targetedNodes: IRowNode<ProductGridData>[] = [];

        params.nodes.forEach((n) => {
          getFilteredLeafNodes(n).forEach((c: IRowNode<ProductGridData>) => {
            targetedNodes.push(c);
          });
        });

        return targetedNodes.reduce(
          (acc, curr) => {
            return mergeAllStats(acc, curr.data!) as OrderStats;
          },
          emptyOrderStat("", "", this.currency ?? Currency.EUR),
        );
      },
    };
  }
}
