import { Component } from "@angular/core";
import { ICellRendererParams } from "@ag-grid-community/core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { StrategyEx, StrategyGroupEx } from "@front/m19-models";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AccountMarketplace, AlgoMode, StrategyType } from "@front/m19-api-client";
import { StrategyTypeStr } from "@front/m19-models";
import { AccountSelectionService, SpStrategiesService, StrategyService } from "@front/m19-services";
import { switchMap } from "rxjs";
import { ToastrService } from "ngx-toastr";
@UntilDestroy()
@Component({
  selector: "app-switch-boost",
  styleUrls: ["./strategy-boost-grid.component.scss"],
  template: `<div class="w-full">
    <mat-slide-toggle
      color="primary"
      [checked]="strategy.activatePrimeDayBoost"
      (toggleChange)="toggleBoost(strategy)"
      [disabled]="
        strategy.algoMode !== AlgoMode.ACOS_TARGET || isReadOnly || (strategy.dailyBudget && strategy.dailyBudget > 0)
      "
    >
    </mat-slide-toggle>
  </div>`,
})
export class SwitchRendererComponent implements ICellRendererAngularComp {
  strategy!: StrategyEx;
  accountMarketplace!: AccountMarketplace;
  isReadOnly: boolean = false;
  AlgoMode = AlgoMode;
  toggleBoost!: (strategy: StrategyEx) => void;
  constructor(
    private strategyService: StrategyService,
    private toastr: ToastrService,
  ) {}
  agInit(
    params: any & {
      strategy: StrategyEx;
      accountMarketplace: AccountMarketplace;
      isReadOnly: boolean;
      toggleBoost: (strategy: StrategyEx) => void;
    },
  ): void {
    this.strategy = params.data;
    this.isReadOnly = params.isReadOnly;
    this.accountMarketplace = params.accountMarketplace;
    this.toggleBoost = params.toggleBoost;
  }

  refresh(params: ICellRendererParams) {
    return true;
  }

  // toggleBoost(strategy: StrategyEx): void {
  //   let primeDayBoost;
  //   if (!strategy.activatePrimeDayBoost) {
  //     strategy.activatePrimeDayBoost = true;
  //     primeDayBoost = 25;
  //   } else {
  //     strategy.activatePrimeDayBoost = false;
  //     primeDayBoost = null;
  //   }
  //   this.strategyService
  //     .updatePrimeDayBoost(
  //       this.accountMarketplace.accountId,
  //       this.accountMarketplace.marketplace,
  //       this.accountMarketplace.resourceOrganizationId!,
  //       strategy.strategyId,
  //       primeDayBoost ?? undefined,
  //     )
  //     .subscribe({
  //       next: () => {
  //         this.toastr.success("Prime Day boost updated");
  //       },
  //       error: (error) => {
  //         this.toastr.error(error.message);
  //       },
  //     });
  // }
}
