@if ((data$ | async)?.length) {
  <div class="dates-row table-line flex items-center px-2" *transloco="let t">
    @for (day of dates$ | async; track day; let i = $index) {
      <div class="header table-unit shrink-0">
        <p class="m-0 text-gray-700">{{ formatDate(day) }}</p>
      </div>
    }
  </div>
}
<div style="visibility: hidden">.</div>
@for (p of data$ | async; track p) {
  @if ((groupByParent$ | async) && !(p.childrenRow?.length === 1 && p.childrenRow![0]!.asin === p.asin)) {
    <div
      class="table-line flex items-center px-2"
      [class.selected]="(graphAsins$ | async) === p.asin"
      [class.both-row]="rankOption === RankOption.BOTH"
      *transloco="let t"
    >
      <ng-container *ngTemplateOutlet="tableRow; context: { data: p }"></ng-container>
    </div>
  }
  @for (d of p.childrenRow; track d) {
    <div>
      <div
        class="table-line flex items-center px-2"
        [class.selected]="(graphAsins$ | async) === d.asin"
        [class.both-row]="rankOption === RankOption.BOTH"
        *transloco="let t"
      >
        <ng-container *ngTemplateOutlet="tableRow; context: { data: d }"></ng-container>
      </div>
    </div>
  }
}

<ng-template #tableRow let-d="data">
  @if (!(hiddenAsins$ | async)?.has(d.asin)) {
    @for (day of dates$ | async; track day) {
      <div
        [ngClass]="'gradient-' + (d.timeline![day] | getRank: rankOption)?.bucket"
        class="table-unit shrink-0"
        [class.both-cell]="rankOption === RankOption.BOTH"
        [matTooltip]="formatDate(day, true)"
        *transloco="let t"
      >
        @if (rankOption !== RankOption.BOTH) {
          <div>
            {{ (d.timeline![day] | getRank: rankOption)?.rank | number: "1.0-0" }}
          </div>
        } @else {
          <span class="org-rank">
            {{ ((d.timeline![day] | getRank: RankOption.ORGANIC)?.rank | number: "1.0-0") ?? "-" }}
          </span>
          <span class="sp-rank ml-2">{{
            ((d.timeline![day] | getRank: RankOption.SPONSORED)?.rank | number: "1.0-0") ?? "-"
          }}</span>
        }
      </div>
    }
  }
</ng-template>
