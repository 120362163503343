import { AgGridAngular } from "@ag-grid-community/angular";
import {
  GetRowIdParams,
  GridOptions,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "@ag-grid-community/core";
import { formatDate } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import {
  AccountMarketplace,
  EntityIdType,
  EntityType,
  History,
  HistoryActionEnum,
  HistoryApi,
  SbCreative,
  Strategy,
  TacosStrategyGroup,
  User,
} from "@front/m19-api-client";
import { getBasicGridOptions, SIDE_BAR_NO_PIVOT } from "@front/m19-grid-config";
import { Marketplaces, SbCreativeBrandAssets, SegmentEx, SegmentTypeDec, StrategyGroupEx } from "@front/m19-models";
import {
  AccountSelectionService,
  AuthService,
  SbStrategiesService,
  SegmentService,
  SpStrategiesService,
  StrategyService,
  TacosStrategiesService,
  UserSelectionService,
} from "@front/m19-services";
import { Translation, TranslocoService } from "@jsverse/transloco";
import { LinkComponent } from "@m19-board/shared/link/link.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import moment from "moment-timezone";
import { combineLatest, Subject } from "rxjs";
import { filter, switchMap, tap } from "rxjs/operators";
import { StrategyLinkComponent } from "../../strategies/strategy-link/strategy-link.component";
import { ActivityCommentComponent } from "../activity-comment/activity-comment.component";
import { ActivityService, EntityTypeFormats } from "../activity.service";

export type ActivityFilter = {
  primaryId?: number;
  primaryType: EntityIdType;
};

@UntilDestroy()
@Component({
  selector: "app-activity-component",
  templateUrl: "./activity.component.html",
  styleUrls: ["./activity.component.scss"],
  standalone: true,
  imports: [AgGridAngular],
})
export class ActivityComponent implements OnInit {
  private _activityFilter: ActivityFilter[] = [];
  @Input() set activityFilter(activityFilter: ActivityFilter[]) {
    this._activityFilter = activityFilter;
    this.reloadHistory$.next();
  }

  get activityFilter(): ActivityFilter[] {
    return this._activityFilter;
  }

  @Input() hideEntityColumn = false;
  @Input() gridConfigStorageKey = "activities";

  // state
  user?: User;
  accountMarketplace?: AccountMarketplace;

  data: History[] = new Array<History>();
  reloadHistory$: Subject<void> = new Subject();

  // mapping
  segmentIndex: Map<number, SegmentEx> = new Map();
  strategyIndex: Map<number, Strategy> = new Map();
  creativeIndex: Map<number, SbCreative> = new Map();
  brandAssetIndex: Map<number, SbCreativeBrandAssets> = new Map();
  strategyGroupIndex: Map<number, StrategyGroupEx> = new Map();
  tacosStrategyIndex: Map<number, TacosStrategyGroup> = new Map();
  minDate?: string;
  maxDate?: string;
  loading = true;

  isReadOnly = false;

  // grid configuration

  gridOptions!: GridOptions<History>;

  readonly noRowsOverlay: string =
    '<span style="padding: 10px; border: 2px solid #ccc; background: #fff;">No activity for the selected date range</span>';
  private translations: Translation = null!;

  constructor(
    private historyService: HistoryApi,
    private userSelectionService: UserSelectionService,
    private authService: AuthService,
    private segmentService: SegmentService,
    private sbStrategyService: SbStrategiesService,
    private accountSelectionService: AccountSelectionService,
    private activityService: ActivityService,
    private translocoService: TranslocoService,
    private spStrategyService: SpStrategiesService,
    private tacosStrategyService: TacosStrategiesService,
    private strategyService: StrategyService,
  ) {
    this.translations = translocoService.getTranslation("en");
  }

  ngOnInit(): void {
    this.gridOptions = this.buildGridOptions(this.gridConfigStorageKey, this.hideEntityColumn);

    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((x) => (this.user = x));
    this.accountSelectionService.noAccountGroupSelected$.pipe(untilDestroyed(this)).subscribe(() => {
      this.loading = false;
    });
    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) => this.strategyService.getStrategyIndex(am.accountId, am.marketplace)),
        filter((s) => !!s && s.size > 0),
        untilDestroyed(this),
      )
      .subscribe((strategyIndex) => {
        this.strategyIndex = strategyIndex;
        this.reloadHistory$.next();
      });
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) => this.segmentService.getSegments(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((segmentIndex) => {
        this.segmentIndex = segmentIndex;
        this.reloadHistory$.next();
      });

    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) => this.tacosStrategyService.getTacosStrategyIndex(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((tacosStrategyIndex) => {
        this.tacosStrategyIndex = tacosStrategyIndex;
        this.reloadHistory$.next();
      });

    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) => this.sbStrategyService.getSbCreativesIndex(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((sbCreativeIndex) => {
        this.creativeIndex = sbCreativeIndex;
        this.reloadHistory$.next();
      });
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) => this.spStrategyService.getStrategyGroups(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((strategyGroupIndex) => {
        this.strategyGroupIndex = strategyGroupIndex;
        this.reloadHistory$.next();
      });
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) => this.sbStrategyService.getSbCreativeBrandAssets(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((brandAssetIndex) => {
        this.brandAssetIndex = brandAssetIndex;
        this.reloadHistory$.next();
      });

    combineLatest<[string[], AccountMarketplace, void]>([
      this.userSelectionService.dateRange$,
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      this.reloadHistory$,
    ])
      .pipe(
        untilDestroyed(this),
        tap((_) => {
          this.data = [];
          this.loading = true;
          return _;
        }),
        switchMap(([dateRange, accountMarketplaceSelection]) => {
          // TODO: use moment JS here
          const maxDatePickerDate = new Date();
          // Because of stats, maxDatePicker is upperbound to yesterday, but for history we need today
          maxDatePickerDate.setDate(maxDatePickerDate.getDate() - 1);
          this.minDate = dateRange[0];
          this.maxDate = dateRange[1];
          if (this.maxDate == maxDatePickerDate.toISOString().substring(0, 10)) {
            maxDatePickerDate.setDate(maxDatePickerDate.getDate() + 1);
            this.maxDate = maxDatePickerDate.toISOString().substring(0, 10);
          }
          this.accountMarketplace = accountMarketplaceSelection;

          return this.historyService.getHistory({
            accountId: this.accountMarketplace.accountId,
            marketplace: this.accountMarketplace.marketplace,
            minDate: this.minDate,
            maxDate: this.maxDate,
            historyKey: this.activityFilter,
          });
        }),
      )
      .subscribe((x: History[]) => {
        this.loading = false;
        this.data = x
          .filter(
            (h) =>
              h.accountId == this.accountMarketplace?.accountId &&
              h.marketplace == this.accountMarketplace?.marketplace,
          )
          .sort((a, b) => b.timestamp.localeCompare(a.timestamp)); // from recent to old
      });
    this.reloadHistory$.next();
  }

  private getPrimaryEntityValue(params: ValueGetterParams<History>) {
    switch (params.data?.entityType) {
      case EntityType.Strategy:
      case EntityType.Tactic:
      case EntityType.AudienceTargeting:
      case EntityType.SbCreative: {
        let id = params.data.primaryId;
        if (params.data?.primaryId && params.data.entityType == EntityType.SbCreative) {
          id = this.creativeIndex.get(params.data.primaryId)?.strategyId ?? 0;
        } else if (params.data.entityType == EntityType.AudienceTargeting) {
          id = JSON.parse(params.data?.newValue ?? params.data.oldValue ?? "")["strategyId"];
        }
        if (id && !this.strategyIndex.get(id)) {
          return {
            id,
            name: this.getDeletedStrategyName(id),
            component: "deletedStrategyLink",
            params: {
              strategyId: id,
            },
          };
        }
        return {
          id,
          name: this.strategyIndex.get(id!)?.name,
          component: StrategyLinkComponent,
          params: {
            strategyId: id,
            withCampaignType: true,
          },
        };
      }
      case EntityType.Segment: {
        if (!this.segmentIndex.has(params.data.primaryId!)) {
          return { id: params.data.primaryId, name: "Deleted Segment" };
        }
        const segment = this.segmentIndex.get(params.data.primaryId!)!;
        const name = `[${SegmentTypeDec[segment.segmentType]}] ${segment.name}`;
        return { id: params.data.primaryId, name };
      }

      case EntityType.StrategyGroup: {
        if (!params.data.primaryId || !this.strategyGroupIndex.has(params.data.primaryId!)) {
          return { id: params.data.primaryId, name: "Deleted Strategy Group" };
        }
        const name = this.strategyGroupIndex.get(params.data.primaryId)?.strategyGroupName;
        return {
          id: params.data.primaryId,
          name,
          component: LinkComponent,
          params: {
            routerLink: "/strategies/strategy-group/sponsored-product/" + params.data.primaryId,
            target: "_blank",
            content: name,
            queryParamsHandling: "merge",
          },
        };
      }

      case EntityType.TacosStrategyGroup: {
        if (!params.data.primaryId || !this.tacosStrategyIndex.has(params.data.primaryId!)) {
          return { id: params.data.primaryId, name: "Deleted Tacos Strategy" };
        }
        const relatedSp = this.strategyIndex.get(this.tacosStrategyIndex.get(params.data.primaryId!)!.spStrategyId!);
        return {
          id: params.data.primaryId,
          name: relatedSp?.name,
          component: StrategyLinkComponent,
          params: {
            strategyId: params.data.primaryId,
          },
        };
      }

      case EntityType.AccountMarketplace: {
        // normally account name and marketplace are the selected ones
        const marketplace = Marketplaces[this.accountMarketplace!.marketplace];
        return {
          id: params.data.primaryId,
          name: `${this.accountMarketplace!.accountGroupName} ${marketplace.flag} ${this.accountMarketplace!.marketplace} - ${marketplace.name}`,
        };
      }
      default:
        return { id: params.data?.primaryId, name: params.data?.entityType + " " + params.data?.primaryId };
    }
  }

  private getSecondaryEntityValue(params: ValueGetterParams<History>) {
    switch (params.data?.entityType) {
      case EntityType.SbCreative: {
        // display creative name
        if (!params.data.primaryId || !this.brandAssetIndex.has(params.data.primaryId)) {
          return {
            id: params.data.primaryId,
            name: "Deleted Creative",
          };
        }
        return {
          id: params.data.primaryId,
          name: this.brandAssetIndex.get(params.data.primaryId)!.creativeTitle,
        };
      }
      case EntityType.Tactic: {
        if (!params.data.secondaryId || !this.segmentIndex.has(params.data.secondaryId)) {
          return { id: params.data.primaryId, name: "Deleted Segment" };
        }
        const segment = this.segmentIndex.get(params.data.secondaryId)!;
        const name = `[${SegmentTypeDec[segment.segmentType]}] ${segment.name}`;
        return { id: params.data.secondaryId, name };
      }
      default:
        return undefined;
    }
  }

  getDeletedStrategyName(primaryId: number): string {
    const strategy = this.data.find((x: History) => {
      return x.action == HistoryActionEnum.delete && x.primaryId == primaryId;
    });
    return strategy && strategy.oldValue ? JSON.parse(strategy.oldValue)["name"] + " (Deleted)" : "Deleted Strategy";
  }

  private buildGridOptions(localStorageKey: string, hideEntityColumn: boolean): GridOptions<History> {
    return {
      ...getBasicGridOptions(localStorageKey),
      getRowId: (params: GetRowIdParams) => params.data.id,
      sideBar: SIDE_BAR_NO_PIVOT,
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        useValueFormatterForExport: true,
      },
      components: {
        deletedStrategyLink: (params: any) => {
          return this.getDeletedStrategyName(params.strategyId);
        },
        text: (params: any) => {
          return params.text;
        },
      },
      columnDefs: [
        {
          headerName: this.translocoService.translate("activity.date_and_time"),
          filter: "agDateColumnFilter",
          floatingFilter: true,
          valueGetter: (params) => {
            return moment.utc(params.data?.timestamp).toDate();
          },
          valueFormatter: (params) => {
            return formatDate(params.value, "short", this.user?.locale ?? "");
          },
          filterValueGetter: (params: ValueGetterParams) => {
            const d = moment.utc(params.data.timestamp).toDate();
            return new Date(d.toDateString());
          },
        },
        {
          headerName: this.translocoService.translate("activity.user"),
          field: "userName",
          floatingFilter: true,
          filter: "agMultiColumnFilter",
        },
        {
          headerName: this.translocoService.translate("dsp-stats.creativeType"),
          field: "entityType",
          floatingFilter: true,
          valueFormatter: (params: ValueFormatterParams<History, EntityType>) =>
            this.translocoService.translate(EntityTypeFormats[params.value!]),
          filter: "agSetColumnFilter",
          filterParams: {
            valueFormatter: (params: ValueFormatterParams<History, EntityType>) =>
              this.translocoService.translate(EntityTypeFormats[params.value!]),
          },
        },
        {
          headerName: this.translocoService.translate("activity.entity"),
          hide: hideEntityColumn,
          floatingFilter: true,
          valueGetter: (params) => this.getPrimaryEntityValue(params),
          filter: "agMultiColumnFilter",
          valueFormatter: (
            params: ValueFormatterParams<
              History,
              {
                id: number;
                name: string;
              }
            >,
          ) => params.value?.name ?? "",
          filterParams: {
            filters: [
              // TODO: add a agTextFilter
              {
                filter: "agSetColumnFilter",
                filterParams: {
                  keyCreator: (params: ValueFormatterParams<History, { id: number; name: string }>) => params.value?.id,
                  valueFormatter: (params: ValueFormatterParams<History, { id: number; name: string }>) =>
                    params.value?.name,
                },
              },
            ],
          },
          cellRendererSelector: (params) => {
            if (params.value.component) {
              return {
                component: params.value.component,
                params: params.value.params,
              };
            }
            return {
              component: "text",
              params: { text: params.value.name },
            };
          },
        },
        {
          headerName: this.translocoService.translate("activity.sub_type"),
          floatingFilter: true,
          valueGetter: (params) => this.getSecondaryEntityValue(params),
          filter: "agMultiColumnFilter",
          valueFormatter: (
            params: ValueFormatterParams<
              History,
              {
                id: number;
                name: string;
              }
            >,
          ) => params.value?.name ?? "",
          filterParams: {
            filters: [
              // TODO: add a agTextFilter
              {
                filter: "agSetColumnFilter",
                filterParams: {
                  keyCreator: (params: ValueFormatterParams<History, { id: number; name: string }>) => params.value?.id,
                  valueFormatter: (params: ValueFormatterParams<History, { id: number; name: string }>) =>
                    params.value?.name,
                },
              },
            ],
          },
          cellRendererSelector: (params) => {
            if (!params.value) {
              return undefined;
            }
            if (params.value.component) {
              return {
                component: params.value.component,
                params: params.value.params,
              };
            }
            return {
              component: "text",
              params: { text: params.value.name },
            };
          },
        },
        {
          headerName: this.translocoService.translate("activity.property_changed"),
          floatingFilter: true,
          field: "property",
          valueFormatter: (params) => {
            const s = this.activityService.formatHistoryProperty(params?.data);
            if (this.translations[s] != undefined) {
              return this.translocoService.translate(s);
            }
            return s;
          },
          filterValueGetter: (params) =>
            this.translocoService.translate(this.activityService.formatHistoryProperty(params?.data)),
          filter: "agSetColumnFilter",
        },
        {
          headerName: this.translocoService.translate("activity.old_value"),
          field: "oldValue",
          valueFormatter: (params) => {
            const s = this.activityService.formatHistoryValue(
              params?.data,
              "oldValue",
              this.user?.locale ?? "",
              Marketplaces[this.accountMarketplace!.marketplace].currency,
            );
            if (this.translations[s] != undefined) {
              return this.translocoService.translate(s);
            }
            return s;
          },
        },
        {
          headerName: this.translocoService.translate("activity.new_value"),
          field: "newValue",
          valueFormatter: (params) => {
            const s = this.activityService.formatHistoryValue(
              params.data,
              "newValue",
              this.user?.locale ?? "",
              Marketplaces[this.accountMarketplace!.marketplace].currency,
            );

            if (this.translations[s] != undefined) {
              return this.translocoService.translate(s);
            }
            return s;
          },
        },
        {
          headerName: this.translocoService.translate("activity.comments"),
          field: "comment",
          cellRendererSelector: (params: ICellRendererParams) => ({
            component: ActivityCommentComponent,
            params: {
              readonly: this.isReadOnly,
              commentUpdate: (comment: string) => {
                params.data.comment = comment;
              },
            },
          }),
        },
      ],
    };
  }
}
