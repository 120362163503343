import { NgStyle } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "app-integer-value",
  templateUrl: "./integer-value.component.html",
  styleUrls: ["./integer-value.component.scss"],
  standalone: true,
  imports: [FormsModule, NgStyle],
})
export class IntegerValueComponent implements OnInit {
  @Input() min: number | null | undefined = null;
  @Input() testid: string = "";
  @Input() disabled = false;
  @Input() max: number | null | undefined = null;

  @Input() step: number | null = null;

  @Input({ required: true }) value!: number;

  @Input() size?: number;

  @Input() unit?: string;

  @Output() isFocused = new EventEmitter<boolean>();

  @Output() onChange = new EventEmitter<number>();

  ngOnInit() {
    if (this.max === null) this.max = undefined;
    if (this.min === null) this.min = undefined;
    if (this.step === null) this.step = 1;
  }

  checkkey(key: KeyboardEvent) {
    return key.keyCode <= 57 && key.keyCode >= 48;
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
