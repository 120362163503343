import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  input,
  OnInit,
  Output,
  output,
  signal,
  viewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { ActivityEventFiltersComponent } from "@m19-board/activity-event-filters.component";
import { AgGridAngular } from "@ag-grid-community/angular";
import { DateAggreationComponent } from "@m19-board/shared/switch-button/date-aggregation-switch-button.component";
import { ExportButtonComponent } from "@m19-board/shared/ui/export-buttons/export-button.component";
import { Filter, FilterComponent } from "@m19-board/shared/filter/filter.component";
import { IButtonComponent, ICardComponent, Option } from "@front/m19-ui";
import { StatsOverlayComponent } from "@m19-board/overlay/stats-overlay.component";
import { AdStatsEx, BrandingFilter, CurrencyStat, OrderStats, ProductGroupEx, StrategyEx } from "@front/m19-models";
import { MetricSelectorComponent } from "@m19-board/insights/metric-selector/metric-selector.component";
import { TranslocoModule } from "@jsverse/transloco";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";
import { Metric } from "@front/m19-metrics";
import { ICON_CHART_LINE, ICON_CLOSE } from "@m19-board/utils/iconsLabels";
import { ActivityEventType, ActivityService } from "@m19-board/activities/activity.service";
import { Observable } from "rxjs";
import { DateAggregation, MetricsSelectorLocalStorageKey } from "@front/m19-utils";
import { DataSet, UserSelectionService } from "@front/m19-services";
import { ColDef, ColumnRowGroupChangedEvent, GridOptions } from "@ag-grid-community/core";
import { PARENT_ASIN_COL_NAME } from "@front/m19-grid-config";
import { BaseChartDirective } from "ng2-charts";

@Component({
  selector: "app-stats",
  standalone: true,
  imports: [
    CommonModule,
    ActivityEventFiltersComponent,
    AgGridAngular,
    DateAggreationComponent,
    ExportButtonComponent,
    FilterComponent,
    IButtonComponent,
    StatsOverlayComponent,
    MetricSelectorComponent,
    TranslocoModule,
    ICardComponent,
    MatSlideToggle,
    SpinnerComponent,
    BaseChartDirective,
  ],
  templateUrl: "./stats.component.html",
})
export class StatsComponent<T> implements OnInit, AfterViewInit {
  private readonly activityService = inject(ActivityService);
  private readonly userSelectionService = inject(UserSelectionService);

  protected allUsers$: Observable<Option<string>[]> = this.activityService.allUsersOptions$;
  protected allStrategies$: Observable<Option<StrategyEx>[]> = this.activityService.allStrategiesOptions$;
  protected allEventAnnotationTypes: Option<ActivityEventType>[] = this.activityService.allActivityEventTypesOptions;

  metricsConfig = input.required<StatsComponentMetricConfig<T>>();
  gridConfig = input.required<StatsComponentGridConfig<T>>();
  dataset = input.required<DataSet<CurrencyStat>>();

  filters = input<Filter<ProductGroupEx | StrategyEx | BrandingFilter | string>[] | undefined>(undefined);
  dateAggregation = input.required<DateAggregation>();
  withDateAggregation = input<boolean>(true);
  localStorageKey = input.required<MetricsSelectorLocalStorageKey>();
  dataLoading = input<boolean>(false);
  withEventAnnotation = input<boolean>(false);
  displayEventAnnotation = input<boolean>(false);

  _grid = viewChild.required<AgGridAngular>("grid");

  isGlobalChartHidden = signal(false);
  isGroupByParent = signal(false);
  disableEventAnnotation = signal(false);

  metricsSelect = output<Metric<AdStatsEx>[]>();
  filterChange = output<Filter<ProductGroupEx | StrategyEx | BrandingFilter | string>[]>();
  dateAggregationChange = output<DateAggregation>();
  displayEventAnnotationChange = output<boolean>();
  grid = output<AgGridAngular>();

  @Output()
  csvExport = new EventEmitter<void>();

  ngOnInit() {
    this.isGlobalChartHidden.set(!this.userSelectionService.getUserChartDisplayedPreference(this.localStorageKey()));
  }

  ngAfterViewInit() {
    this.grid.emit(this._grid());
  }

  applyFilter(filters: Filter<ProductGroupEx | StrategyEx | BrandingFilter | string>[]) {
    this.filterChange.emit(filters);
  }

  selectMetrics(metrics: Metric<AdStatsEx>[]) {
    this.metricsSelect.emit([...metrics]);
  }

  toggleEventAnnotationDisplay(checked: boolean) {
    this.displayEventAnnotationChange.emit(checked);
  }

  changeDateAggregation(dateAggregation: DateAggregation) {
    this.disableEventAnnotation.set(dateAggregation !== DateAggregation.daily);
    this.dateAggregationChange.emit(dateAggregation);
  }

  toggleGlobalChartDisplay(hide: boolean) {
    this.isGlobalChartHidden.set(hide);
    this.userSelectionService.setUserChartDisplayedPreference(this.localStorageKey(), !this.isGlobalChartHidden());
  }

  toggleParentAsin() {
    this.isGroupByParent.update((groupByParent) => !groupByParent);
    this._grid().api.setRowGroupColumns(this.isGroupByParent() ? [PARENT_ASIN_COL_NAME] : []);
  }

  restoreDefaultColumns() {
    this._grid().api?.resetColumnState();
    this._grid().api?.autoSizeAllColumns();
  }

  onCsvExport() {
    this.csvExport.emit();
  }

  onColumnRowGroupChanged(event: ColumnRowGroupChangedEvent<OrderStats>) {
    this.isGroupByParent.set(!!(event.column?.isRowGroupActive() && event.column.getColId() === PARENT_ASIN_COL_NAME));
  }

  protected readonly ICON_CLOSE = ICON_CLOSE;
  protected readonly ICON_CHART_LINE = ICON_CHART_LINE;
}

export interface StatsComponentMetricConfig<T> {
  data?: CurrencyStat;
  previousData?: CurrencyStat;
  metrics: Metric<AdStatsEx>[];
}

export interface StatsComponentGridConfig<T> {
  columnDefs: ColDef[];
  gridData?: T[];
  gridOptions: GridOptions;
}
