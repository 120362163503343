<ng-container *transloco="let t">
  <div class="modal-header bg-primary">
    <h4 class="modal-title pull-left">{{ t("add-admin-modal.add_admin") }}</h4>
    <a>
      <h4 class="modal-title pull-right" (click)="modalService.hide()">&times;</h4>
    </a>
  </div>
  <div class="modal-body m-4">
    <form id="email-form" name="email-form" [formGroup]="emailForm">
      <div class="input-section w-80">
        <label>{{ t("common.email") }}</label>
        <input
          type="email"
          id="email"
          name="email"
          class="simple-input form-control"
          placeholder="{{ t('manage-authorized-users-modal.please_enter_the_email_of_the_user') }}"
          formControlName="email"
          [ngClass]="{
            'border-danger': email.invalid && (!email.untouched || email.dirty),
          }"
        />
      </div>
      @if (email.errors?.["userDoesNotExist"]) {
        <div class="invalid-feedback">{{ t("add-admin-modal.ask_user_to_register") }}</div>
      }
      @if (email.errors?.["email"] || email.errors?.["pattern"]) {
        <div class="invalid-feedback">{{ t("register.email_invalid_error") }}</div>
      }
    </form>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modalService.hide()">{{ t("common.cancel") }}</button>
      <button class="btn btn-primary" (click)="addAdmin()" [disabled]="email.invalid">{{ t("common.add") }}</button>
    </div>
  </div>
</ng-container>
