import { Component, OnInit } from "@angular/core";
import { ICellRendererParams } from "@ag-grid-community/core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICON_BOOST } from "@m19-board/utils/iconsLabels";
import { NotificationBidderIssueEx, NotificationEx, SbCreativeBrandAssets, StrategyEx } from "@front/m19-models";
import { faClock, faWarning } from "@fortawesome/free-solid-svg-icons";
import { CampaignType, M19Status, NotificationType } from "@front/m19-api-client";
import { combineLatest, map, Observable, of } from "rxjs";
import { FblModeSize } from "../fbl-mode/fbl-mode.component";
import { TranslocoService } from "@jsverse/transloco";
import { AsinService, InventoryService, NotificationService, SbStrategiesService } from "@front/m19-services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Utils } from "@front/m19-utils";

@UntilDestroy()
@Component({
  templateUrl: "./warning-renderer.component.html",
  styleUrls: ["./strategy-grid.component.scss"],
})
export class WarningRendererComponent implements ICellRendererAngularComp, OnInit {
  strat!: StrategyEx;
  ICON_BOOST = ICON_BOOST;
  faClock = faClock;
  CampaignType = CampaignType;
  promoBoostActivated$!: Observable<boolean>;
  faultyReason: string | undefined;
  FblModeSize = FblModeSize;
  faWarning = faWarning;
  atLeastOne!: boolean;

  constructor(
    private translocoService: TranslocoService,
    private asinService: AsinService,
    private inventoryService: InventoryService,
    private notifications: NotificationService,
    private sbStrategyService: SbStrategiesService,
  ) {}

  agInit(params: any): void {
    this.strat = params.data;
    this.promoBoostActivated$ = params.promoBoostActivated$;
  }

  ngOnInit(): void {
    combineLatest([
      this.asinService.getInventoryRules(this.strat.accountId, this.strat.marketplace),
      this.inventoryService.getSellerInventoryStats(this.strat.accountId, this.strat.marketplace),
      this.asinService.getCatalog(this.strat.accountId, this.strat.marketplace),
      this.notifications.getNotifications$.pipe(
        map((x: NotificationEx[]) =>
          x
            .filter((n) => n.type === NotificationType.BIDDER_ISSUE)
            .filter((n) => n.strategyId === this.strat!.strategyId),
        ),
      ),
      this.strat.campaignType === CampaignType.SB
        ? this.sbStrategyService
            .getSbCreativesPerStrategy(this.strat.accountId, this.strat.marketplace)
            .pipe(map((x) => x.get(this.strat!.strategyId)))
        : of([]),
      this.strat.campaignType === CampaignType.SB
        ? this.sbStrategyService.getSbCreativeBrandAssets(this.strat.accountId, this.strat.marketplace)
        : of(new Map<number, SbCreativeBrandAssets>()),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([rules, stats, catalog, notifs, sbCreatives, sbCreativeBrandAssets]) => {
        if (!rules || !stats || !catalog) {
          return;
        }
        this.faultyReason = undefined;
        this.atLeastOne = false;

        // first check bidder issue
        const bidderIssue = notifs
          .filter((n) => n.type === NotificationType.BIDDER_ISSUE)
          .find((n) => n.strategyId === this.strat!.strategyId) as NotificationBidderIssueEx;
        if (bidderIssue && bidderIssue.warningType !== M19Status.IGNORED_REJECTED) {
          this.atLeastOne = true;
          this.faultyReason = bidderIssue.warningMessage;
          return;
        }

        // then creative issue
        for (const crea of sbCreatives ?? []) {
          const { status } = Utils.getSBFaultyReasons(
            crea,
            sbCreativeBrandAssets.get(crea.creativeId) ?? { creativeTitle: "" },
            catalog,
            rules,
            stats,
            this.translocoService,
            notifs.find((n) => n.subStrategyId === crea.creativeId) as NotificationBidderIssueEx,
          );
          if (status) {
            this.faultyReason = "Ads delivery issue: " + Utils.m19StatusToReadableStr(status);
            this.atLeastOne = true;
            break;
          }
        }
      });
  }

  refresh(params: ICellRendererParams) {
    return true;
  }

  isDayPartingEnabled(strategy: StrategyEx): boolean {
    return strategy.daypartingPauseHour != null && strategy.daypartingReactivationHour != null;
  }

  getDayPartingToolTip(strategy: StrategyEx): string {
    return (
      "Strategy paused from " + strategy.daypartingPauseHour + ":00 to " + strategy.daypartingReactivationHour + ":00"
    );
  }

  getTooltip(strategy: StrategyEx): string {
    let tooltip = "";
    if (this.strat.disableOtherQueries && this.strat.campaignType !== CampaignType.SD) {
      tooltip += this.translocoService.translate("strategy-info-cell.ai-powered_targeting_is_deactivated");
    }
    if (this.atLeastOne) {
      tooltip += "\n" + this.faultyReason;
    }
    return tooltip;
  }
}
