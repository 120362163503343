import { CommonModule } from "@angular/common";
import { Component, computed, input } from "@angular/core";
import { StrategyStateEnum, TacosStrategyGroupStateEnum } from "@front/m19-api-client";
import { IBadgeComponent } from "@front/m19-ui";
import { ICON_PAUSE, ICON_PLAY } from "@m19-board/utils/iconsLabels";

@Component({
  selector: "state-badge",
  standalone: true,
  imports: [CommonModule, IBadgeComponent],
  template: ` <IBadge [label]="label()" [color]="color()" [icon]="icon()" variant="soft" /> `,
})
export class StateBadgeComponent {
  state = input<StrategyStateEnum | TacosStrategyGroupStateEnum | undefined>();

  label = computed(() => {
    if (this.state() === TacosStrategyGroupStateEnum.ENABLED) return "ACTIVE";
    else return "PAUSED";
  });

  icon = computed(() => {
    if (this.state() === TacosStrategyGroupStateEnum.ENABLED) return ICON_PLAY;
    else return ICON_PAUSE;
  });

  color = computed(() => {
    if (this.state() === TacosStrategyGroupStateEnum.ENABLED) return "green";
    else return "orange";
  });
}
