import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BillingService } from "@front/m19-services";
import { BsModalRef } from "ngx-bootstrap/modal";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";

@Component({
  templateUrl: "./coupon-modal.component.html",
  imports: [TranslocoRootModule],
  standalone: true,
})
export class CouponModalComponent {
  @Input({ required: true })
  organizationId!: number;

  couponCode = "";

  @Output()
  couponSuccess = new EventEmitter<void>();

  @Output()
  couponError = new EventEmitter<string>();

  constructor(
    private billingService: BillingService,
    public bsModalRef: BsModalRef,
  ) {}

  setCouponCode(couponCode: string) {
    this.couponCode = couponCode;
  }

  submit() {
    this.billingService.setCoupon(this.organizationId, this.couponCode).subscribe({
      next: () => {
        this.couponSuccess.emit();
        this.bsModalRef.hide();
      },
      error: (e) => {
        this.couponError.emit(e);
        this.bsModalRef.hide();
      },
    });
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
