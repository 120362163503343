import { Component, Input } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CampaignType, Strategy } from "@front/m19-api-client";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { StrategyEx } from "@front/m19-models";

interface IFbl {
  name: string;
  src: string;
  title: string;
}

export enum FblModeSize {
  small = "sm",
  large = "lg",
}

@Component({
  selector: "app-fbl-mode",
  standalone: true,
  imports: [MatTooltipModule, TranslocoRootModule],
  templateUrl: "./fbl-mode.component.html",
  styleUrls: ["./fbl-mode.component.scss"],
})
export class FblModeComponent implements ICellRendererAngularComp {
  static readonly fblMode: Record<number, IFbl> = {
    1: { name: "ACOS", src: "assets/img/fbl_icons/target.svg", title: "fbl-mode.acos_target_management" },
    2: { name: "BUDGET", src: "assets/img/fbl_icons/budget.svg", title: "fbl-mode.budget_management" },
    3: {
      name: "Min daily spend",
      src: "assets/img/fbl_icons/rocket.svg",
      title: "fbl-mode.min_daily_spend_management",
    },
    4: {
      name: "Not enough stats",
      src: "assets/img/fbl_icons/no_data.svg",
      title: "fbl-mode.the_algorithm_has_not_enough_stats_to_optimize_your_strategy",
    },
    8: {
      name: "Delivery issue",
      src: "assets/img/fbl_icons/delivery_warning.svg",
      title: "fbl-mode.delivery_issue",
    },
  };

  strategy_: StrategyEx | undefined;
  constraint: any;

  @Input() set strategy(strategy: Strategy) {
    this.strategy_ = strategy as StrategyEx;
    this.constraint = FblModeComponent.fblMode[this.strategy_.constraint!];
  }

  size_ = 0;

  @Input() set size(size: FblModeSize) {
    if (size == FblModeSize.small) this.size_ = 25;
    else this.size_ = 30;
  }

  agInit(params: ICellRendererParams & { strategy: StrategyEx; size: FblModeSize }): void {
    this.strategy = params.strategy;
    this.size = params.size;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }
}
