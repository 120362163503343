import { AgGridAngular } from "@ag-grid-community/angular";
import {
  ColDef,
  GridOptions,
  HeaderValueGetterParams,
  ICellRendererParams,
  ValueFormatterParams,
} from "@ag-grid-community/core";
import { CommonModule } from "@angular/common";
import { Component, computed, inject, OnInit, Signal, signal, TemplateRef, ViewChild } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ActivatedRoute, RouterModule } from "@angular/router";
import {
  AccessLevel,
  CampaignType,
  EntityIdType,
  SbAsins,
  Strategy,
  StrategyAsin,
  StrategyStateEnum,
  TacosStrategyGroupStateEnum,
  UpdateTacosStrategyGroupRequest,
} from "@front/m19-api-client";
import {
  ACOS,
  AD_CONVERSIONS,
  AD_SALES,
  CLICKS,
  CONVERSION_RATE,
  COST,
  CPC,
  IMPRESSIONS,
  Metric,
  MetricCategory,
  ROAS,
} from "@front/m19-metrics";
import { AdStatsEx } from "@front/m19-models";
import {
  AccountSelectionService,
  AdsStatsWithPreviousPeriod,
  AdStatsWithTargetHistory,
  AsinService,
  AuthService,
  DataSet,
  groupBy,
  SbStrategiesService,
  StatsApiClientService,
  StrategyService,
  StrategyTargetHistory,
  TacosStrategiesService,
  UserSelectionService,
} from "@front/m19-services";
import { IAlertComponent, IButtonComponent, IInputComponent, ModalRef, ModalService } from "@front/m19-ui";
import { addAdStats, AggregationFunction, DateAggregation, Utils } from "@front/m19-utils";
import { TranslocoService } from "@jsverse/transloco";
import { ActivityComponent, ActivityFilter } from "@m19-board/activities/activity/activity.component";
import { getMetricsColDef } from "@m19-board/grid-config/grid-config";
import { MetricSelectorComponent } from "@m19-board/insights/metric-selector/metric-selector.component";
import { TACOS, TARGET_TACOS, TOTAL_ORDERS, TOTAL_SALES } from "@m19-board/models/MetricsDef";
import { StateBadgeComponent } from "@m19-board/shared/state-badge/state-badge.component";
import { StrategyInfoBlocComponent } from "@m19-board/shared/strategy-info-bloc/strategy-info-bloc.component";
import {
  TacosSbAsinsCheckComponent,
  TacosSbAsinsCheckData,
} from "@m19-board/shared/tacos-sb-asins-check/tacos-sb-asins-check.component";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { ICON_ADD, ICON_CHEVRON_DOWN, ICON_PAUSE, ICON_PLAY } from "@m19-board/utils/iconsLabels";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Moment } from "moment-timezone";
import { ToastrService } from "ngx-toastr";
import { combineLatest, filter, map, of, switchMap, take } from "rxjs";
import { SdStrategyCreationComponent } from "../../sponsored-display/sd-manager/sd-strategy-creation/sd-strategy-creation.component";
import { SbStrategyFormComponent } from "../sb-strategy-form/sb-strategy-form.component";
import { AsinsSelectionComponent } from "../strategy-asins/asins-selection.component";
import { StrategyAsinsComponent } from "../strategy-asins/strategy-asins.component";
import { BaseChartDirective } from "ng2-charts";

enum SECTIONS {
  PRODUCTS = "products",
  CAMPAIGNS = "campaigns",
  STATS = "stats",
  ACTIVITIES = "activities",
}

@UntilDestroy()
@Component({
  selector: "tacos-strategy-page",
  standalone: true,
  imports: [
    CommonModule,
    StrategyInfoBlocComponent,
    TranslocoRootModule,
    IButtonComponent,
    SpinnerComponent,
    RouterModule,
    StrategyAsinsComponent,
    AgGridAngular,
    IInputComponent,
    IAlertComponent,
    AsinsSelectionComponent,
    MatTooltipModule,
    MetricSelectorComponent,
    StateBadgeComponent,
    ActivityComponent,
    SbStrategyFormComponent,
    SdStrategyCreationComponent,
    BaseChartDirective,
  ],
  templateUrl: "./tacos-strategy-page.component.html",
})
export class TacosStrategyPageComponent implements OnInit {
  readonly ICON_CHEVRON_D = ICON_CHEVRON_DOWN;
  readonly ICON_PLAY = ICON_PLAY;
  readonly ICON_PAUSE = ICON_PAUSE;
  readonly ICON_PLUS = ICON_ADD;

  readonly localStorageKey = "tacos-strategy-page";
  readonly METRICS = [
    TOTAL_SALES,
    TOTAL_ORDERS,
    TACOS,
    AD_SALES,
    AD_CONVERSIONS,
    COST,
    ACOS,
    CLICKS,
    IMPRESSIONS,
    CPC,
    CONVERSION_RATE,
    ROAS,
  ];

  readonly TacosStrategyGroupState = TacosStrategyGroupStateEnum;
  readonly StrategyState = StrategyStateEnum;
  readonly SECTIONS = SECTIONS;
  readonly CampaignType = CampaignType;
  private readonly modalService = inject(ModalService);
  private readonly authService = inject(AuthService);
  private readonly accountSelection = inject(AccountSelectionService);
  private readonly tacosStrategiesService = inject(TacosStrategiesService);
  private readonly route = inject(ActivatedRoute);
  private readonly userSelectionService = inject(UserSelectionService);
  private readonly translocoService = inject(TranslocoService);
  private readonly toastrService = inject(ToastrService);
  private readonly strategyService = inject(StrategyService);
  private readonly asinService = inject(AsinService);
  private readonly statsService = inject(StatsApiClientService);
  private readonly sbStrategiesService = inject(SbStrategiesService);

  private currency = toSignal(this.userSelectionService.selectedCurrency$);
  private locale = toSignal(this.authService.loggedUser$.pipe(map((user) => user.locale)));
  readonly am = toSignal(this.accountSelection.singleAccountMarketplaceSelection$);
  readonly isReadOnly = toSignal(
    this.accountSelection.singleAccountMarketplaceSelection$.pipe(map((am) => am.accessLevel === AccessLevel.READ)),
  );

  activityFilter = computed<ActivityFilter[]>(() => {
    return [{ primaryType: EntityIdType.tacosStrategyGroupId, primaryId: this.tacosStrategy()!.tacosStrategyGroupId! }];
  });

  private readonly tacosStrategy$ = this.accountSelection.singleAccountMarketplaceSelection$.pipe(
    switchMap((am) =>
      combineLatest([
        this.route.paramMap,
        this.tacosStrategiesService.getTacosStrategyIndex(am.accountId, am.marketplace),
      ]),
    ),
    map(([params, tacosStrategyIndex]) => tacosStrategyIndex.get(Number(params.get("id")))),
  );

  private strategyTargetHistory = toSignal(
    combineLatest([this.tacosStrategy$, this.userSelectionService.selectedCurrency$]).pipe(
      switchMap(([tacosStrategy, currency]) =>
        this.statsService.getStrategyTargetHistory(tacosStrategy!.spStrategyId!, currency!),
      ),
    ),
  );

  readonly tacosStrategies$ = this.tacosStrategy$.pipe(
    switchMap((tacosStrategy) =>
      this.tacosStrategiesService.getTacosStrategies(
        tacosStrategy!.accountId!,
        tacosStrategy!.marketplace!,
        tacosStrategy!.tacosStrategyGroupId!,
      ),
    ),
  );

  readonly tacosStrategy = toSignal(this.tacosStrategy$);

  readonly spStrategy = toSignal<Strategy | undefined>(
    this.tacosStrategies$.pipe(map((strategies) => strategies.spStrategy)),
  );

  readonly sbStrategy = toSignal<Strategy | undefined>(
    this.tacosStrategies$.pipe(map((strategies) => strategies.sbStrategy)),
  );
  readonly isSbPaused = computed(() => this.tacosStrategy()?.sbSpendRepartition === 0);

  readonly sdStrategy = toSignal<Strategy | undefined>(
    this.tacosStrategies$.pipe(map((strategies) => strategies.sdStrategy)),
  );
  readonly isSdPaused = computed(() => this.tacosStrategy()?.sdSpendRepartition === 0);

  readonly sbAsins = toSignal<StrategyAsin[] | undefined>(
    this.tacosStrategies$.pipe(
      map((strategies) => {
        if (!strategies.sbAsins) return [];
        // remove duplicates
        return [...new Set(strategies.sbAsins.map((asin) => asin.asin!))].map((asin) => ({ asin }));
      }),
    ),
  );

  readonly sbCreative = toSignal(
    this.accountSelection.singleAccountMarketplaceSelection$.pipe(
      switchMap((am) =>
        combineLatest([
          this.tacosStrategies$.pipe(map((strategies) => strategies.sbStrategy)),
          this.sbStrategiesService.getSbCreativesPerStrategy(am.accountId, am.marketplace),
        ]),
      ),
      map(([sbStrategy, sbCreatives]) => (sbStrategy ? sbCreatives.get(sbStrategy!.strategyId!)?.[0] : undefined)),
    ),
  );

  loading = signal(false);
  modalLoading = signal(false);

  usedAsins = toSignal(
    this.accountSelection.singleAccountMarketplaceSelection$.pipe(
      switchMap((am) => this.tacosStrategiesService.getUsedAsins(am.accountId, am.marketplace)),
    ),
  );

  sectionOrder = [SECTIONS.PRODUCTS, SECTIONS.CAMPAIGNS, SECTIONS.STATS, SECTIONS.ACTIVITIES];
  sectionState: { [key in SECTIONS]: boolean } = {
    [SECTIONS.PRODUCTS]: true,
    [SECTIONS.CAMPAIGNS]: true,
    [SECTIONS.STATS]: false,
    [SECTIONS.ACTIVITIES]: false,
  };

  periodComparison = toSignal(this.userSelectionService.periodComparison$, { requireSync: true });
  selectedDateRange = toSignal<Moment[]>(this.userSelectionService.selectedDateRange$, { requireSync: true });

  private minDate = computed(() => Utils.formatMomentDate(this.selectedDateRange()![0]));
  private maxDate = computed(() => Utils.formatMomentDate(this.selectedDateRange()![1]));
  selectedMetrics = signal<Metric<AdStatsWithTargetHistory>[]>([TOTAL_SALES, AD_SALES]);

  private readonly allStrategyStats = signal<AdsStatsWithPreviousPeriod | undefined>(undefined);

  readonly totalStats = computed(() => {
    if (!this.allStrategyStats()) return {};
    return this.allStrategyStats()!.data.reduce((acc, curr) => {
      addAdStats(acc, curr);
      return acc;
    }, {} as AdStatsEx);
  });

  readonly previousTotalStats = computed(() => {
    if (!this.allStrategyStats()) return {};
    return this.allStrategyStats()!.previousPeriodData.reduce((acc, curr) => {
      addAdStats(acc, curr);
      return acc;
    }, {} as AdStatsEx);
  });

  rowStats = computed(() => {
    if (!this.allStrategyStats()) return [];
    return Array.from(this.getStrategyStats(this.allStrategyStats()!.data).values());
  });

  previousPeriodRowStats = computed<Map<CampaignType | undefined, AdStatsEx>>(() => {
    if (!this.allStrategyStats()) return new Map();
    return this.getStrategyStats(this.allStrategyStats()!.previousPeriodData);
  });

  private readonly _dataset = new DataSet(
    3,
    this.selectedMetrics(),
    [AggregationFunction.mergeAdStatsWithTargetHistory],
    this.translocoService,
  );

  builtDataSet = computed(() => {
    if (!this.currency()) return undefined;
    this._dataset.currency = this.currency()!;

    this._dataset.buildDataSet(
      [...(this.allStrategyStats()?.data ?? []), ...(this.strategyTargetHistory() ?? [])],
      this.selectedMetrics(),
      DateAggregation.daily,
      {
        minDate: this.minDate(),
        maxDate: this.maxDate(),
      },
      this.periodComparison()
        ? { data: this.allStrategyStats()?.previousPeriodData ?? [], period: this.periodComparison()?.period ?? [] }
        : undefined,
    );
    return this._dataset;
  });

  tacosTargetInput = signal<number | undefined>(undefined);
  strategyNameInput = signal<string | undefined>(undefined);

  @ViewChild("targetModal") targetModal!: TemplateRef<any>;
  @ViewChild("editNameModal") editNameModal!: TemplateRef<any>;
  @ViewChild("editAsinsModal") editAsinsModal!: TemplateRef<any>;

  catalog = toSignal(
    this.accountSelection.singleAccountMarketplaceSelection$.pipe(
      switchMap((am) => this.asinService.getCatalog(am.accountId, am.marketplace)),
    ),
  );

  ineligibleAsins: Signal<string[]> = computed(() => {
    if (!this.catalog() || !this.spStrategy()?.asins) return [];
    const eligibility = this.catalog()!.getSPEligibility();
    return this.spStrategy()!
      .asins!.filter((asin) => !eligibility.get(asin.asin!)?.status)
      .map((asin) => asin.asin!);
  });

  createSbStrategy = signal(false);
  createSdStrategy = signal(false);

  gridOptions = signal<GridOptions>({
    columnDefs: [
      {
        headerName: this.translocoService.translate("strategy-page.strategy_type", {}, "en"),
        field: "campaignType",
        valueFormatter: (params: ValueFormatterParams<AdStatsEx>) => {
          if (params.value == CampaignType.SP) return "Sponsored Products";
          if (params.value == CampaignType.SB) return "Sponsored Brands";
          if (params.value == CampaignType.SD) return "Sponsored Display";
          return "";
        },
      },
      ...getMetricsColDef<AdStatsEx, AdStatsEx>(
        this.METRICS.filter((m) => m.category !== MetricCategory.SALES_STATS),
      ).map(({ headerName, ...def }) => ({
        ...def,
        headerName: this.translocoService.translate(`metrics.${def.groupId}_title`, {}, "en"),
        headerValueGetter: (params: HeaderValueGetterParams) =>
          this.translocoService.translate(`metrics.${params.column?.getColId()}_title`),
        cellRendererParams: (params: ICellRendererParams<AdStatsEx>) => {
          return {
            ...(def as ColDef<AdStatsEx>).cellRendererParams(params),
            currency: this.currency(),
            locale: this.locale(),
            previousData:
              this.periodComparison() && params.data
                ? this.previousPeriodRowStats().get(params.data.campaignType)
                : undefined,
          };
        },
      })),
    ],
  });

  modalRef: ModalRef<void> | undefined;

  ngOnInit() {
    this._dataset.metricsOnSameScale = [
      [AD_SALES, COST],
      [TACOS, TARGET_TACOS],
    ];

    combineLatest([
      this.accountSelection.singleAccountMarketplaceSelection$,
      this.userSelectionService.dateRange$,
      this.userSelectionService.periodComparison$,
      this.route.paramMap,
    ])
      .pipe(
        switchMap(([am, dr, periodComparison, params]) =>
          this.tacosStrategiesService.getStats(am, dr[0], dr[1], periodComparison?.period, Number(params.get("id"))),
        ),
      )
      .subscribe((stats: AdsStatsWithPreviousPeriod) => {
        this.allStrategyStats.set(stats);
      });
  }

  private getStrategyStats(allStats: AdStatsEx[]): Map<CampaignType | undefined, AdStatsEx> {
    const grouped: Map<CampaignType | undefined, AdStatsEx> = groupBy(allStats, (s: AdStatsEx) => s.campaignType);
    grouped.delete(undefined);
    return grouped;
  }

  public selectMetric(metrics: Metric<StrategyTargetHistory>[]) {
    if (metrics.includes(TACOS)) {
      metrics = metrics.includes(TARGET_TACOS) ? metrics : [...metrics, TARGET_TACOS];
    } else {
      metrics = metrics.filter((m) => m !== TARGET_TACOS);
    }
    this.selectedMetrics.set([...metrics]);
  }

  hideModal() {
    this.modalRef?.close();
  }

  openTargetModal() {
    this.modalRef = this.modalService.openModal(this.targetModal, {
      modalTitle: this.translocoService.translate("algo-mode-selection.tacos_target"),
    });
  }

  updateTacosTarget() {
    this.modalLoading.set(true);
    if (this.tacosTargetInput() && this.tacosStrategy()) {
      if (this.tacosTargetInput()! <= 0 || this.tacosTargetInput()! > 100) {
        this.toastrService.error(
          this.translocoService.translate("tacos-strategy-page.tacos_target_must_be_between_1_and_100"),
        );
        return;
      }

      const updateParams: UpdateTacosStrategyGroupRequest = {
        accountId: this.am()!.accountId,
        marketplace: this.am()!.marketplace,
        tacosTarget: this.tacosTargetInput()! / 100,
        tacosStrategyGroupId: this.tacosStrategy()!.tacosStrategyGroupId!,
      };

      this.tacosStrategiesService.updateTacosStrategyGroup(updateParams).subscribe((_) => {
        this.modalLoading.set(false);
        this.toastrService.success(this.translocoService.translate("tacos-strategy-page.tacos_target_updated"));
        this.hideModal();
        this.tacosTargetInput.set(undefined);
      });
    }
  }

  openEditNameModal() {
    this.modalRef = this.modalService.openModal(this.editNameModal, {
      modalTitle: this.translocoService.translate("sp-strategy-group-page.edit_strategy_name"),
    });
  }

  updateStrategyName() {
    if (!this.strategyNameInput()) return;
    if (this.strategyNameInput() && this.spStrategy()) {
      this.modalLoading.set(true);
      this.tacosStrategiesService
        .updateTacosStrategyName({
          accountId: this.am()!.accountId,
          marketplace: this.am()!.marketplace,
          tacosStrategyGroupId: this.tacosStrategy()!.tacosStrategyGroupId!,
          name: this.strategyNameInput()!,
        })
        .subscribe((_) => {
          this.toastrService.success(this.translocoService.translate("sp-strategy-group-page.strategy_name_updated"));
          this.modalLoading.set(false);
          this.hideModal();
          this.strategyNameInput.set(undefined);
        });
    }
  }

  updateStrategyGroupState(state: TacosStrategyGroupStateEnum) {
    this.tacosStrategiesService
      .updateTacosStrategyGroup({
        accountId: this.am()!.accountId,
        marketplace: this.am()!.marketplace,
        tacosStrategyGroupId: this.tacosStrategy()!.tacosStrategyGroupId!,
        state,
      })
      .subscribe((_) => {
        this.toastrService.success(this.translocoService.translate("tacos-strategy-page.strategy_group_state_updated"));
      });
  }

  updateStrategyState(campaignType: CampaignType) {
    const newState = this.isSbPaused() ? TacosStrategyGroupStateEnum.ENABLED : TacosStrategyGroupStateEnum.PAUSED;

    this.tacosStrategiesService
      .updateTacosStrategyGroup({
        accountId: this.am()!.accountId,
        marketplace: this.am()!.marketplace,
        tacosStrategyGroupId: this.tacosStrategy()!.tacosStrategyGroupId!,
        campaignType,
        state: newState,
      })
      .subscribe(() => {
        this.toastrService.success(this.translocoService.translate("tacos-strategy-page.strategy_group_state_updated"));
      });
  }

  openEditAsinsModal() {
    this.modalRef = this.modalService.openModal(this.editAsinsModal, {
      modalTitle: this.translocoService.translate("product-group-page.edit_asins"),
    });
  }

  updateAsins(asins: StrategyAsin[], mode: "add" | "remove" = "add") {
    const asinsToUpdate = asins.map((a) => a.asin);

    if (mode === "add") {
      this.strategyService.addAsinsToStrategy(this.spStrategy()!, asinsToUpdate).subscribe(() => {
        this.toastrService.success(this.translocoService.translate("tacos-strategy-page.asins_successfully_added"));
      });
      if (this.sdStrategy()) {
        this.strategyService.addAsinsToStrategy(this.sdStrategy()!, asinsToUpdate).subscribe(() => {
          this.toastrService.success(this.translocoService.translate("tacos-strategy-page.asins_successfully_added"));
        });
      }
    } else {
      if (this.sbStrategy() && this.sbAsins()?.some((asin) => asinsToUpdate.includes(asin.asin!))) {
        const ref = this.modalService.openModal<TacosSbAsinsCheckData, Array<SbAsins>>(TacosSbAsinsCheckComponent, {
          modalTitle: "Impacted SB Ad Lines",
          data: {
            sbStrategyId: this.sbStrategy()!.strategyId!,
            asinsToRemove: asinsToUpdate,
          },
        });

        ref
          .afterClosed()
          .pipe(
            take(1),
            filter((result): result is SbAsins[] => !!result),
            switchMap((result) => this.sbStrategiesService.updateSbCreativeClusterAsync(this.sbCreative()!, result)),
            switchMap(() => this.deleteAsinsFromSpAndSd(asinsToUpdate)),
          )
          .subscribe(() => {
            this.toastrService.success(
              this.translocoService.translate("tacos-strategy-page.asins_successfully_removed"),
            );
          });
      } else {
        this.deleteAsinsFromSpAndSd(asinsToUpdate).subscribe(() => {
          this.toastrService.success(this.translocoService.translate("tacos-strategy-page.asins_successfully_removed"));
        });
      }
    }
  }

  private deleteAsinsFromSpAndSd(asins: string[]) {
    return this.strategyService
      .deleteAsinsFromStrategy(this.spStrategy()!, asins)
      .pipe(
        switchMap(() =>
          this.sdStrategy() ? this.strategyService.deleteAsinsFromStrategy(this.sdStrategy()!, asins) : of(undefined),
        ),
      );
  }

  toggleSectionState(section: string, value: boolean) {
    this.sectionState[section as SECTIONS] = value;
  }

  addSbStrategy() {
    this.createSbStrategy.set(true);
  }

  addSdStrategy() {
    this.createSdStrategy.set(true);
  }
}
