import { Component, Input } from "@angular/core";
import { Currency, Marketplace, QueryType } from "@front/m19-api-client";

import { Metric } from "@front/m19-metrics";
import { QueryStats } from "../models/Metric";

@Component({
  selector: "app-top-queries-list",
  templateUrl: "./top-queries-list.component.html",
  styleUrls: ["./top-queries-list.component.scss"],
})
export class TopQueriesListComponent {
  @Input({ required: true })
  loadingStatus!: boolean;
  @Input({ required: true })
  metric!: Metric<QueryStats>;
  @Input({ required: true })
  data!: QueryStats[];
  @Input({ required: true })
  currency!: Currency;
  @Input({ required: true })
  locale!: string;
  @Input()
  marketplace?: Marketplace;

  get noData() {
    return this.data.length === 0;
  }

  isProductQueryType(stat: QueryStats) {
    return stat.queryType === QueryType.PRODUCT;
  }
}
