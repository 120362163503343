import { Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AuthService, OrganizationAccountGroupService } from "@front/m19-services";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ModalAddAccountGroupComponent } from "./add-account-group-pop-up/add-account-group.popup.component";
import { BoardType, LayoutSelectorService } from "@m19-board/layout-selector.service";
import { TranslocoModule } from "@jsverse/transloco";
import { IButtonComponent } from "@front/m19-ui";
import { MatTooltip } from "@angular/material/tooltip";

@UntilDestroy()
@Component({
  selector: "app-link-your-account-button",
  templateUrl: "./link-your-account-button.component.html",
  standalone: true,
  imports: [IButtonComponent, TranslocoModule, MatTooltip],
})
export class LinkYourAccountButtonComponent implements OnInit {
  display = false;

  private organizationId?: number;

  constructor(
    private accountGroupService: OrganizationAccountGroupService,
    private modalService: BsModalService,
    private authService: AuthService,
    private layoutSelectorService: LayoutSelectorService,
  ) {}

  ngOnInit(): void {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.organizationId = user.ownedOrganizationId;
    });

    this.accountGroupService.allOrganizationAccountGroups$.pipe(untilDestroyed(this)).subscribe((organizations) => {
      const accountGroups = organizations?.flatMap((o) => o.accountGroups);
      this.display = !accountGroups || accountGroups.length === 0;
    });
  }

  openAddAccountGroupModal(): void {
    // canLoginWithAmazon is overriden for whitelabel parent ppc board organization
    const canLoginWithAmazonGlobal =
      this.layoutSelectorService.getBoardType() === BoardType.M19 ||
      this.layoutSelectorService.getBoardType() === BoardType.WHITELABEL_SELFSERVICE;
    const modalOptions: ModalOptions = {
      class: "modal-primary modal-lg",
      initialState: {
        organizationId: this.organizationId,
        canLoginWithAmazon: canLoginWithAmazonGlobal,
      },
    };
    this.modalService.show(ModalAddAccountGroupComponent, modalOptions);
  }
}
