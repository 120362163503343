import { Injectable } from "@angular/core";
import { AccountState } from "@front/m19-api-client";
import { Metric } from "@front/m19-metrics";
import { Utils } from "@front/m19-utils";
import { ExportToCsv } from "export-to-csv";

export class CsvExportParams {
  prefix?: string;
  accountGroupName?: string;
  marketplace?: string;
  dateRange?: string[];
  fieldSeparator?: string;
  quoteStrings?: string;
}

export interface CsvField<T> {
  get header(): string;

  value(data: T): string;
}

export function constantField<T>(header: string, constantValue: string) {
  return new ConstantField<T>(header, constantValue);
}

export function simpleField<T>(field: keyof T & string, name?: string) {
  return new SimpleField<T>(field, name);
}

export function metricField<T>(metric: Metric<T>, name?: string) {
  return new MetricCsvField(metric, name);
}

export function fieldExtractor<T>(header: string, extractor: (data: T) => string) {
  return new FieldExtractor(header, extractor);
}

export class MetricCsvField<T> implements CsvField<T> {
  private readonly _header: string;

  constructor(
    private readonly metric: Metric<T>,
    header?: string,
  ) {
    const h = header ?? metric.title.replaceAll("&", "and");
    this._header = Utils.toCamelCase(h);
  }

  get header(): string {
    return this._header;
  }

  value(data: T): string {
    return this.metric.valueForCsv(data);
  }
}

export class ConstantField<T> implements CsvField<T> {
  private readonly _header: string;
  private readonly constantValue: string;

  constructor(header: string, constantValue: string) {
    this._header = header.charAt(0).toUpperCase() + header.slice(1);
    this.constantValue = constantValue;
  }

  get header(): string {
    return this._header;
  }

  value(): string {
    return this.constantValue;
  }
}

export class SimpleField<T> implements CsvField<T> {
  private readonly _header: string;

  constructor(
    private readonly field: keyof T & string,
    header?: string,
  ) {
    // ensure start with upper case
    const h = header ?? field;
    this._header = h.charAt(0).toUpperCase() + h.slice(1);
  }

  get header(): string {
    return this._header;
  }

  value(data: T): string {
    if ("" + data[this.field] === AccountState.BIDDER_ON) return "Full Automation";
    else if ("" + data[this.field] === AccountState.DOWNLOADER_ON) return "Pull Stats";
    return "" + data[this.field];
  }
}

export class FieldExtractor<T> implements CsvField<T> {
  private readonly _header: string;

  constructor(
    header: string,
    private readonly valueExtractor: (data: T) => string,
  ) {
    // ensure start with upper case
    this._header = header.charAt(0).toUpperCase() + header.slice(1);
  }

  get header(): string {
    return this._header;
  }

  value(data: T): string {
    return this.valueExtractor(data);
  }
}

@Injectable()
export class CsvExportService {
  exportCsv<T>(csvExportParams: CsvExportParams, data: T[], fields?: CsvField<T>[]) {
    const filename = this.buildFilename(csvExportParams);
    const options = {
      fieldSeparator: csvExportParams.fieldSeparator ?? ",",
      quoteStrings: csvExportParams.quoteStrings ?? '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: filename,
    };
    const csvData = fields ? this.formatData(data, fields) : data;
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(csvData);
  }

  buildFilename(params: CsvExportParams) {
    let res = params.prefix;
    if (params.accountGroupName) {
      res += `_${params.accountGroupName}`;
    }
    if (params.marketplace) {
      res += `_${params.marketplace}`;
    }
    if (params.dateRange) {
      res += `_${params.dateRange.join("_")}`;
    }
    return res;
  }

  private formatData<T>(data: T[], fields: CsvField<T>[]) {
    return data.map((d) => {
      const res: any = {};
      for (const f of fields) {
        res[f.header] = f.value(d) ?? "";
      }
      return res;
    });
  }
}
