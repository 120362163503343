@if (isTest || isAdminLogin()) {
  <div class="flex w-full justify-between bg-orange-700 px-4 text-white" *transloco="let t">
    <div class="flex gap-6">
      @if (isTest) {
        <span class="font-bold">Demo environment</span>
        @if (isAdminLogin()) {
          <span class="font-bold">|</span>
        }
      }
      @if (isAdminLogin()) {
        <span>Logged via Admin Board</span>
      }
    </div>
    <a (click)="printDebugInfo()">Debug Info</a>
  </div>
}
@if (globalWarning$ | async) {
  <div class="border-sha w-full rounded-sm bg-red-100 px-4 py-1 text-red-700">
    {{ globalWarning$ | async }}
  </div>
}
<div class="border-sha w-full rounded-sm bg-orange-100 px-4 py-1 text-sm text-orange-700" *transloco="let t">
  <div>{{ t("default-layout.migration_warning") }}</div>
  <a (click)="useNewUi(); $event.preventDefault()" class="text-sm">{{
    t("default-layout.switch_to_the_new_interface_now")
  }}</a>
</div>
<header class="navbar">
  <div class="nav-sub flex w-full min-w-0 justify-between" *transloco="let t">
    <div class="flex items-center">
      <a
        class="navbar-brand h-14"
        [routerLink]="agencyBoardEligible ? '/super-board' : '/dashboard'"
        queryParamsHandling="merge"
        ><img
          [src]="navbarBrandFull.src"
          [height]="navbarBrandFull.height"
          [alt]="navbarBrandFull.alt"
          class="navbar-brand-full sensitive-data"
      /></a>
      <app-account-selector [hidden]="!displayAccountSelector()" scope="dashboard"></app-account-selector>

      @if (organizations && organizations.length && selectedOrganizations() && displayOrgSelector()) {
        <div class="flex min-w-0 max-w-72">
          <IMultiSelect
            [options]="organizations"
            [selected]="selectedOrganizations()"
            (selectedChange)="selectOrganization($event)"
            class="sensitive-data mr-3"
            searchable
            withSelectAll
            popperWidth="300px"
            by="id"
            [searchAttributes]="['label', 'id']"
          >
            <ng-template #labelSlot>
              <span class="truncate">
                {{
                  t("default-layout.selected_organizations", {
                    count: selectedOrganizations().length,
                    organization: selectedOrganizations()[0].value.organizationName,
                  })
                }}
              </span>
            </ng-template>
          </IMultiSelect>
        </div>
      }
    </div>

    @if (displaySearchTrendsMarketplaceSelector()) {
      <app-search-trends-marketplace-selector></app-search-trends-marketplace-selector>
    }

    <div class="flex items-center">
      @if (boardType === BoardType.M19 || boardType === BoardType.WHITELABEL_SELFSERVICE) {
        <app-link-your-account-button class="mx-1 mt-1"></app-link-your-account-button>
      }
      @if (boardType === BoardType.M19 || boardType === BoardType.WHITELABEL_SELFSERVICE) {
        <app-registration-button></app-registration-button>
      }
      @if (displayAccountSelector() && hasInvalidToken()) {
        <button
          class="btn btn-warning mx-1 h-full"
          matTooltip="{{ t('default-layout.login_tooltip', [logInPlaceForAPIGrantAccess()]) }}"
          (click)="requestGrant()"
        >
          {{ t("default-layout.grant_access") }}
        </button>
      }
      @if (displayAccountSelector() && hasPendingVendorIntegration()) {
        <button
          class="btn btn-warning mx-1 h-full"
          matTooltip="{{ t('default-layout.validate_seeling_partner_api') }}"
          (click)="validateAccountIntegration()"
        >
          {{ t("default-layout.validate_catalog") }}
        </button>
      }
    </div>

    <div class="nav-sub flex justify-end px-2" style="flex: initial !important">
      <ul class="nav navbar-nav">
        <app-currency-selection></app-currency-selection>

        <li class="nav-item d-md-down-none mx-1">
          <app-date-range-selection></app-date-range-selection>
        </li>

        <li class="nav-item mx-0" #notificationsCtn>
          <div class="relative">
            <IButton
              [icon]="ICON_BELL"
              tooltipValue="{{ t('common.notifications') }}"
              (onClick)="openNotifications($event)"
              variant="ghost"
            />
            @if (notificationsCount > 0) {
              <span class="absolute right-0 top-0 rounded-full bg-red-500 px-1 text-xs text-white">
                {{ notificationsCount > 99 ? "99+" : notificationsCount }}
              </span>
            }
          </div>

          @if (notificationsOpened) {
            <div class="notif-ctn scrollbar">
              <div class="notif-ctn-header relative flex justify-center">
                <span>{{ t("v2-layout.notifications", { count: notifications.length }) }}</span>
              </div>
              <div class="scrollbar notif-box-ctn relative">
                @for (notification of notifications; track notification) {
                  <app-notification-card-switch
                    [notification]="notification"
                    [small]="true"
                  ></app-notification-card-switch>
                }
                @if (!notifications.length) {
                  <span class="no-notif block">{{ t("default-layout.no_notifications") }}</span>
                }
                @if (notificationsCount > 50) {
                  <span class="mb-3 font-bold">…</span>
                }
              </div>
              <div class="show-more-ctn relative flex items-center">
                <button
                  class="btn btn-primary show-more-btn"
                  routerLink="/notifications-center"
                  queryParamsHandling="merge"
                  (click)="notificationsOpened = false"
                >
                  {{ t("common.show_more") }}
                </button>
              </div>
            </div>
          }
        </li>
        <li class="nav-item mx-0">
          <div class="settings-menu">
            <IButton
              [icon]="ICON_SETTINGS"
              tooltipValue="{{ t('common.settings') }}"
              variant="ghost"
              [matMenuTriggerFor]="settingsMenu"
            />
            <mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
              <ul class="flex flex-col">
                @for (item of PROFILE_ITEMS; track item) {
                  @if (
                    !item.supportedBoards ||
                    item.supportedBoards.includes(boardType) ||
                    (item.label === "Billing" && isBillingOwnerOfActiveSubscription)
                  ) {
                    <li class="dd-item p-0">
                      @if (item.href) {
                        <a [attr.href]="item.href" [attr.target]="item.target" class="px-2 py-1">
                          <span [class]="item.icon + ' mr-2 text-xl text-gray-700'"></span>
                          {{ item.label }}
                        </a>
                      } @else {
                        <a
                          [routerLink]="item.routerLink"
                          [queryParamsHandling]="item.queryParamsHandling"
                          class="px-2 py-1"
                          (click)="item.onClick ? item.onClick() : null"
                        >
                          <span [class]="item.icon + ' text-gray-7000 mr-2 text-xl'"></span>
                          {{ item.label }}
                        </a>
                      }
                    </li>
                  }
                }
              </ul>
            </mat-menu>
          </div>
        </li>
        <li class="nav-item mx-0">
          <IButton
            [icon]="ICON_QUESTION"
            tooltipValue="{{ t('common.help') }}"
            [href]="boardType === BoardType.M19 ? 'https://help.m19.com/' : whitelabelContactUsLink"
            target="_blank"
            variant="ghost"
            [square]="true"
          />
        </li>
      </ul>
    </div>
  </div>
</header>

<div class="app-body overflow-auto">
  <app-sidebar class="sidebar" [class.closed]="!sideBarOpened" [betaTest]="betaTest"></app-sidebar>

  <!-- Main content -->
  <main class="main" style="min-width: 1200px">
    <!-- Breadcrumb -->
    <div class="h-full p-8">
      <router-outlet></router-outlet>
    </div>
    <!-- /.container-fluid -->
  </main>
</div>
