<div class="int-input" *transloco="let t">
  <div class="flex items-center">
    @if (label) {
      <label class="col-form-label mr-2" for="intinput">{{ label }}</label>
    }
    <div [ngStyle]="{ 'width.rem': size }">
      <div
        class="input-group"
        (focusin)="onFocused.emit(true)"
        (focusout)="onFocused.emit(false)"
        (click)="$event.stopPropagation()"
      >
        <input
          [disabled]="disabled"
          type="number"
          inputmode="numeric"
          class="simple-input-append form-control pb-1 pl-2 pr-2 pt-1 text-center"
          [ngStyle]="{ 'width.rem': inputSize }"
          [value]="value"
          (keypress)="checkkey($event)"
          (input)="input($event)"
          [min]="min"
          [max]="max"
          [step]="step"
          (keydown.enter)="sendValue()"
          id="intinput"
          (click)="$event.preventDefault()"
        />
        <div class="input-group-append ml-1 gap-x-1">
          @if (value !== oldvalue) {
            <IButton
              variant="soft"
              [icon]="ICON_CHECK"
              tooltipValue="{{ t('common.confirm') }}"
              (onClick)="sendValue(); $event.preventDefault()"
              [disabled]="
                value !== undefined && ((min !== undefined && value < min) || (max !== undefined && value > max))
              "
              color="green"
            />
          }
          @if (value !== oldvalue) {
            <IButton
              variant="soft"
              [icon]="ICON_CLOSE"
              tooltipValue="{{ t('common.undo') }}"
              (onClick)="resetValue(); $event.preventDefault()"
              color="red"
            />
          }
          @if (trash && !!oldvalue) {
            <IButton
              variant="soft"
              [icon]="ICON_TRASH"
              tooltipValue="{{ t('common.delete') }}"
              (onClick)="delete(); $event.preventDefault()"
              color="red"
            />
          }
        </div>
      </div>
    </div>
  </div>
  @if (value !== undefined && min !== undefined && max !== undefined && (value < min || value > max)) {
    <small class="form-text danger mb-1">
      {{ value | intInputErrorPipe: min : max }}
    </small>
  }
  @if (value !== oldvalue && warningMessage && warningMinThreshold && (!value || value <= warningMinThreshold)) {
    <small class="form-text dark-warning mb-1">
      {{ warningMessage }}
    </small>
  }
</div>
