import {
  ApplicationRef,
  ClassProvider,
  ComponentRef,
  ConstructorProvider,
  createComponent,
  EmbeddedViewRef,
  EnvironmentInjector,
  ExistingProvider,
  FactoryProvider,
  inject,
  Injectable,
  InjectionToken,
  Injector,
  StaticClassProvider,
  TemplateRef,
  Type,
  TypeProvider,
  ValueProvider,
  ViewContainerRef,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IModalComponent } from '../components/imodal/imodal.component';

export type ComponentType<T> = {
  new (...args: any[]): T;
};

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private appRef = inject(ApplicationRef);
  private environmentInjector = inject(EnvironmentInjector);

  private containerRef!: ViewContainerRef;

  registerContainerRef(viewContainerRef: ViewContainerRef) {
    this.containerRef = viewContainerRef;
  }

  openModal<InputDataType, ResultType, T = any>(
    content: TemplateRef<any>,
    modalOptions: ModalOptions<InputDataType>,
  ): ModalRef<ResultType, TemplateRef<any>>;

  openModal<InputDataType, ResultType, T = any>(
    content: ComponentType<T>,
    modalOptions: ModalOptions<InputDataType>,
  ): ModalRef<ResultType, ComponentType<T>>;

  openModal<InputDataType, ResultType, T = any>(
    content: ComponentType<T> | TemplateRef<any>,
    modalOptions: ModalOptions<InputDataType>,
  ): ModalRef<ResultType, TemplateRef<any> | ComponentType<T>> {
    const onclose = () => {
      if (modal.location.nativeElement.parentElement) {
        modal.location.nativeElement.parentElement.removeChild(modal.location.nativeElement);
        this.appRef.detachView(modal.hostView);
      }

      modal.destroy();
      view.destroy();
    };

    const dialogRef = new ModalRef<ResultType, ComponentType<T> | TemplateRef<any>>(content, onclose);

    const context = {
      $implicit: dialogRef,
    };

    let projectableNodes: Node[][] | undefined;

    let view: EmbeddedViewRef<any> | ComponentRef<any>;
    if (content instanceof TemplateRef) {
      view = this.containerRef.createEmbeddedView(content, context) || content.createEmbeddedView(context);
      projectableNodes = [view.rootNodes];
    } else {
      view = this.containerRef.createComponent(content, {
        environmentInjector: this.environmentInjector,
        injector: Injector.create({
          providers: [
            {
              provide: ModalRef,
              useValue: dialogRef,
            },
            {
              provide: MODAL_DATA,
              useValue: modalOptions.data,
            },
            ...(modalOptions.providers ?? []),
          ],
        }),
      });
      projectableNodes = [[view.location.nativeElement]];
    }

    const modal: ComponentRef<IModalComponent> = createComponent(IModalComponent, {
      elementInjector: Injector.create({
        providers: [
          {
            provide: ModalRef,
            useValue: dialogRef,
          },
        ],
      }),
      environmentInjector: this.environmentInjector,
      projectableNodes: projectableNodes,
    });

    modal.instance.title = modalOptions.modalTitle;
    modal.instance.titleIcon = modalOptions.modalTitleIcon;
    modal.instance.fullScreen = !!modalOptions.fullScreen;
    modal.instance.closeable = modalOptions.closeable != false;
    modal.instance.position = modalOptions.position ?? 'center';

    dialogRef.component = modal.instance;

    this.containerRef.element.nativeElement.appendChild(modal.location.nativeElement);
    this.appRef.attachView(modal.hostView);

    return dialogRef;
  }
}

export class ModalRef<ResultType, Ref extends Type<any> | TemplateRef<any> = Type<any> | TemplateRef<any>> {
  public ref: Ref;
  public component?: IModalComponent;

  private afterClosedSubject = new Subject<ResultType>();
  private afterCancelledSubject = new Subject<void>();

  private readonly onClose: () => void;

  constructor(ref: Ref, onClose: () => void) {
    this.ref = ref;
    this.onClose = onClose;
  }

  close(result?: ResultType): void {
    this.onClose();

    if (result !== undefined) {
      this.closeWithResult(result);
    } else {
      this.closeWithCancel();
    }
    this.afterClosedSubject.complete();
    this.afterCancelledSubject.complete();
  }

  private closeWithResult(result: ResultType): void {
    this.afterClosedSubject.next(result);
  }

  private closeWithCancel(): void {
    this.afterCancelledSubject.next();
  }

  afterClosed(): Observable<ResultType> {
    return this.afterClosedSubject.asObservable();
  }

  afterCancelled(): Observable<void> {
    return this.afterCancelledSubject.asObservable();
  }
}

export const MODAL_DATA = new InjectionToken('MODAL_DATA');

export interface ModalOptions<T> {
  modalTitle?: string;
  modalTitleIcon?: string;
  fullScreen?: boolean;
  adaptiveWidth?: boolean;
  closeable?: boolean;
  data?: T;
  position?: ModalPosition;
  providers?: (
    | any[]
    | ValueProvider
    | ExistingProvider
    | StaticClassProvider
    | ConstructorProvider
    | FactoryProvider
    | TypeProvider
    | ClassProvider
  )[];
}

export type ModalPosition = 'center' | 'center-top';
