<ng-container *transloco="let t">
  @if ((strat.creativesProduct?.length ?? 0) === 0 && (strat.creativesVideo?.length ?? 0) === 0) {
    <div class="flex">
      <p class="my-auto text-center">{{ t("strategy-table.no_creatives_associated_with_this_strategy") }}.</p>
    </div>
  }
  @if (strat.creativesProduct && strat.creativesProduct.length !== 0) {
    <div class="flex items-center pr-1">
      @if (strat.brandAssetUrl) {
        <img class="brandlogo sensitive-data pr-3" [src]="strat.brandAssetUrl" />
      } @else {
        <div class="flex flex-col justify-center pr-3">
          <fa-icon
            [icon]="faImage"
            class="asset-alt mb-1 text-center"
            matTooltip="{{ t('creative-display.no_preview_available') }}"
          ></fa-icon>
        </div>
      }
      <div class="flex flex-col">
        <div class="my-auto">{{ strat.creativesProduct[0].headline }}</div>
        @if (strat.creativesProduct.length + (strat.creativesVideo?.length ?? 0) > 1) {
          <small>
            {{ t("strategy-table.n_more", [strat.creativesProduct.length + (strat.creativesVideo?.length ?? 0) - 1]) }}
            {{ strat.creativesVideo!.length !== 0 ? t("strategy-table.incl_video") : "" }}
          </small>
        }
      </div>
    </div>
  }
  @if (strat.creativesProduct!.length === 0 && strat.creativesVideo!.length !== 0) {
    <div class="flex">
      <fa-icon [icon]="faVideo" class="video-icon my-auto" size="3x"></fa-icon>
      <p class="my-auto ml-4">{{ t("sb-card.nbvideo_video_ad_lines", [strat.creativesVideo!.length]) }}</p>
    </div>
  }
</ng-container>
