import { CurrencyPipe, PercentPipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  AccountSelectionService,
  StatsApiClientService,
  StrategyService,
  UserSelectionService,
} from "@front/m19-services";
import { SwitchInputComponent } from "@m19-board/shared/switch-input/switch-input.component";
import moment, { MomentZone } from "moment-timezone";
import { filter, Observable, switchMap, tap } from "rxjs";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { ToastrService } from "ngx-toastr";
import { AdStatsEx, Marketplaces, StrategyEx } from "@front/m19-models";
import { AccountMarketplace } from "@front/m19-api-client";
import { addAdStats, Utils } from "@front/m19-utils";

const DEFAULT_PAUSE_HOUR = 22;
const DEFAULT_REACTIVATION_HOUR = 8;
const HOURS = Array.from(Array(24).keys());

@UntilDestroy()
@Component({
  standalone: true,
  imports: [SwitchInputComponent, PercentPipe, CurrencyPipe, FormsModule, TranslocoRootModule],
  selector: "app-day-parting-inputs",
  templateUrl: "./day-parting-inputs.component.html",
  styleUrls: ["./day-parting-inputs.component.scss"],
})
export class DayPartingInputsComponent implements OnInit {
  enabledDayParting?: boolean;
  totalAdSalesOnPeriod?: number;
  totalCostOnPeriod?: number;
  totalAdSales?: number;
  totalCost?: number;
  dataByHour?: Map<number, AdStatsEx>;
  allStratData: AdStatsEx[] = [];
  readonly HOURS = HOURS;
  timeZone: MomentZone | null = null;
  organizationId!: number;

  @Input({ required: true }) strategy$!: Observable<StrategyEx | undefined>;
  strategy?: StrategyEx;

  @Input() daypartingPauseHour?: number;
  @Input() daypartingReactivationHour!: number;
  @Input() locale?: string;
  currencyCode?: string;
  @Input() placeholderPause?: number;
  @Input() placeholderReactivation?: number;
  @Input() saveButtonPresent?: boolean;
  @Input() placeholderSwitch?: boolean;
  @Input() disabled = false;

  constructor(
    private accountMarketplaceSelection: AccountSelectionService,
    private userSelectionService: UserSelectionService,
    private statsService: StatsApiClientService,
    private strategyService: StrategyService,
    private taostrService: ToastrService,
  ) {
    this.accountMarketplaceSelection.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.organizationId = am.resourceOrganizationId!;
    });
  }

  ngOnInit(): void {
    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((x) => (this.currencyCode = x));

    this.accountMarketplaceSelection.singleAccountMarketplaceSelection$
      .pipe(
        tap((am: AccountMarketplace) => (this.timeZone = moment.tz.zone(Marketplaces[am.marketplace].timeZone))),
        untilDestroyed(this),
        switchMap((am) => this.statsService.getHourlyCampaignStats(am.accountId, am.marketplace)),
      )
      .subscribe((x) => {
        this.allStratData = x;
        // aggregate by hour
        const data: AdStatsEx[] = this.allStratData.filter(
          (x) => x.strategyId != undefined && x.strategyId === this.strategy?.strategyId,
        );
        this.dataByHour = new Map<number, AdStatsEx>();
        for (const stat of data) {
          Utils.insertInMap(this.dataByHour, stat.hour, stat, addAdStats);
        }
        this.computeSavings();
      });

    this.strategy$
      .pipe(
        untilDestroyed(this),
        filter((s) => !!s),
      )
      .subscribe((strat) => {
        this.strategy = strat;
        this.enabledDayParting =
          this.strategy?.daypartingPauseHour != null && this.strategy.daypartingReactivationHour != null;
        this.daypartingPauseHour =
          this.strategy?.daypartingPauseHour != null ? this.strategy.daypartingPauseHour : DEFAULT_PAUSE_HOUR;
        this.daypartingReactivationHour =
          this.strategy?.daypartingReactivationHour != null
            ? this.strategy.daypartingReactivationHour
            : DEFAULT_REACTIVATION_HOUR;

        // aggregate by hour
        const data: AdStatsEx[] = this.allStratData.filter(
          (x) => x.strategyId != undefined && x.strategyId === this.strategy?.strategyId,
        );
        this.dataByHour = new Map<number, AdStatsEx>();
        for (const stat of data) {
          Utils.insertInMap(this.dataByHour, stat.hour, stat, addAdStats);
        }
        this.computeSavings();
      });

    if (this.placeholderPause != undefined) this.daypartingPauseHour = this.placeholderPause;
    if (this.placeholderReactivation != undefined) this.daypartingReactivationHour = this.placeholderReactivation;
    if (this.placeholderSwitch != undefined) this.enabledDayParting = this.placeholderSwitch;
    if (this.placeholderPause != undefined || this.placeholderReactivation != undefined) this.computeSavings();
  }

  private computeSavings(): void {
    this.totalCost = 0;
    this.totalAdSales = 0;
    this.totalCostOnPeriod = 0;
    this.totalAdSalesOnPeriod = 0;

    this.dataByHour?.forEach((val) => {
      this.totalCost! += val.cost ?? 0;
      this.totalAdSales! += val.adSales ?? 0;
      if (Utils.isInHourInterval(val.hour!, this.daypartingPauseHour!, this.daypartingReactivationHour)) {
        this.totalCostOnPeriod! += val.cost ?? 0;
        this.totalAdSalesOnPeriod! += val.adSales ?? 0;
      }
    });
  }

  switchDisableDayParting(enable: boolean) {
    if (this.daypartingPauseHour === this.daypartingReactivationHour) return;
    this.computeSavings();
    this.enabledDayParting = enable;
    if (!this.saveButtonPresent) {
      this.pushDayPartingPref();
    }
  }

  pushDayPartingPref(): void {
    if (!this.enabledDayParting) {
      this.strategyService
        .disableDayParting(
          this.strategy!.accountId,
          this.strategy!.marketplace,
          this.organizationId,
          this.strategy!.strategyId,
        )
        .subscribe({
          next: () => {
            this.taostrService.success("Day parting disabled");
          },
          error: (error) => {
            this.taostrService.error(error.error.message);
          },
        });
    } else {
      this.strategyService
        .updateStrategyHoursDayParting(
          this.strategy!.accountId,
          this.strategy!.marketplace,
          this.organizationId,
          this.strategy!.strategyId,
          this.daypartingPauseHour!,
          this.daypartingReactivationHour,
        )
        .subscribe({
          next: () => {
            this.taostrService.success("Day parting updated");
          },
          error: (error) => {
            this.taostrService.error(error.error.message);
          },
        });
    }
  }

  getTimezone(): string | undefined {
    return this.timeZone?.abbr(moment.now());
  }

  getTimezonUtcOffset(): string {
    return Utils.getTimezoneOffset(this.timeZone?.name);
  }
}
