import { Injectable } from "@angular/core";
import { combineLatest, defaultIfEmpty, forkJoin, map, Observable, of, ReplaySubject, switchMap, tap } from "rxjs";
import {
  ProductTrackerRow,
  TrackedSearchTerm,
} from "../product-tracker/product-tracker-views/product-tracker.component";
import {
  AccountSelectionService,
  AsinService,
  KeywordTrackingService,
  SearchTermAsinRanks,
  UserSelectionService,
} from "@front/m19-services";
import {
  AccountMarketplace,
  KeywordTrackerConfig,
  ProductTrackerConfig,
  SearchTermAsinRank,
} from "@front/m19-api-client";
import { Catalog, ProductEx } from "@front/m19-models";

export type ProductTrackerData = {
  data: ProductTrackerRow[];
  parentData: Map<string, ProductTrackerRow>;
};

@Injectable()
export class ProductTrackerService {
  private searchTermRanksCache: Map<string, Observable<TrackedSearchTerm[]>> = new Map();
  private accountMarketplace?: AccountMarketplace;
  private catalog?: Catalog;

  public readonly productTrackerData$: Observable<ProductTrackerData>;

  constructor(
    private keywordTrackingService: KeywordTrackingService,
    private accountSelection: AccountSelectionService,
    private asinService: AsinService,
    private userSelectionService: UserSelectionService,
  ) {
    this.productTrackerData$ = this.accountSelection.singleAccountMarketplaceSelection$.pipe(
      tap((am: AccountMarketplace) => {
        this.accountMarketplace = am;
        this.searchTermRanksCache.clear();
      }),
      switchMap((am: AccountMarketplace) =>
        combineLatest<[KeywordTrackerConfig[], ProductEx[], ProductEx[]]>([
          this.keywordTrackingService.getKeywordTrackerConfig(am.accountId, am.marketplace, am.resourceOrganizationId!),
          this.getCatalogData(am),
          this.getTrackedProductData(am),
        ]),
      ),
      map(
        ([keywordTrackerConfig, catalogProducts, trackedProducts]: [
          KeywordTrackerConfig[],
          ProductEx[],
          ProductEx[],
        ]) => {
          const data: ProductTrackerRow[] = [];
          const dataByParentAsin: Map<string, ProductTrackerRow> = new Map();

          // build tracked search-term array by ASIN
          const searchTermsByAsin = this.buildSearchTermsByAsinMap(keywordTrackerConfig);

          const asins = new Set<string>();
          // Catalog products
          for (const catalogProduct of catalogProducts) {
            if (asins.has(catalogProduct.asin!)) {
              continue;
            }
            asins.add(catalogProduct.asin!);

            const parentAsin = this.catalog?.getParentAsin(catalogProduct.asin!);

            const row = {
              ...catalogProduct,
              inCatalog: true,
              trackedSearchTerms: searchTermsByAsin.get(catalogProduct.asin!) ?? [],
              price: catalogProduct.priceInCent ? catalogProduct.priceInCent / 100 : undefined,
              reviewScore: catalogProduct.reviewScoreTenth ? catalogProduct.reviewScoreTenth / 10 : undefined,
              parent: parentAsin && parentAsin !== catalogProduct.asin,
            };

            if (!this.catalog?.isParentAsin(catalogProduct.asin!)) {
              data.push({ ...row, parentAsin: parentAsin });
            } else {
              dataByParentAsin.set(catalogProduct.asin!, row);
            }
          }

          for (const trackedProduct of trackedProducts) {
            if (asins.has(trackedProduct.asin!)) {
              continue;
            }
            asins.add(trackedProduct.asin!);
            const hasParentAsin =
              trackedProduct.parentAsins && trackedProduct.parentAsins.length > 0
                ? trackedProduct.parentAsins[0]
                : undefined;
            const row = {
              ...trackedProduct,
              inCatalog: false,
              trackedSearchTerms: searchTermsByAsin.get(trackedProduct.asin!) ?? [],
              price: trackedProduct.priceInCent ? trackedProduct.priceInCent / 100 : undefined,
              reviewScore: trackedProduct.reviewScoreTenth ? trackedProduct.reviewScoreTenth / 10 : undefined,
              parent: true,
            };

            if (hasParentAsin) {
              data.push({ ...row, parentAsin: hasParentAsin });

              const parentData = dataByParentAsin.get(hasParentAsin);
              if (!parentData)
                dataByParentAsin.set(hasParentAsin, {
                  childAsins: [row.asin!],
                  trackedSearchTerms: [],
                  inCatalog: false,
                });
              else
                dataByParentAsin.set(hasParentAsin, {
                  ...parentData,
                  childAsins: [...parentData.childAsins!, row.asin!],
                });
            }
          }

          // parent ASIN : merge children keywords
          for (const [p, row] of dataByParentAsin) {
            const parentMergedKeywords = this.mergeChildKeywords(row.childAsins!, data);
            dataByParentAsin.set(p, { ...row, trackedSearchTerms: parentMergedKeywords });
          }

          return { data: data, parentData: dataByParentAsin };
        },
      ),
    );
  }

  private mergeChildKeywords(childAsins: string[], childData: ProductTrackerRow[]): string[] {
    if (!childData || !childAsins) return [];
    const keywords = new Set<string>();
    for (const c of childAsins) {
      const rowData: ProductTrackerRow | undefined = childData.find((d) => d.asin === c);
      rowData?.trackedSearchTerms?.forEach((k) => keywords.add(k));
    }

    return Array.from(keywords);
  }

  private buildSearchTermsByAsinMap(trackConfig: KeywordTrackerConfig[]): Map<string, string[]> {
    const resultMap = new Map<string, string[]>();
    for (const trackedKeyword of trackConfig) {
      for (const asin of trackedKeyword.topAsins ?? []) {
        if (!resultMap.has(asin)) {
          resultMap.set(asin, []);
        }
        resultMap.get(asin)?.push(trackedKeyword.searchTerm!);
      }
    }
    return resultMap;
  }

  private getCatalogData(am: AccountMarketplace): Observable<ProductEx[]> {
    return this.asinService.getCatalog(am.accountId, am.marketplace).pipe(
      tap((catalog: Catalog) => (this.catalog = catalog)),
      switchMap((catalog: Catalog) => {
        const pCalls: Observable<ProductEx>[] = Array.from(catalog.parentAsins.keys()).map((p) =>
          this.asinService.getProductWithMarketplace(p, am.marketplace),
        );
        if (pCalls.length === 0) {
          return of([]);
        }
        return forkJoin(pCalls);
      }),
      map((parentProduct: ProductEx[]) => parentProduct.concat(this.catalog!.products)),
    );
  }

  private getTrackedProductData(am: AccountMarketplace): Observable<ProductEx[]> {
    return this.keywordTrackingService.getProductTrackerConfig(am.accountId, am.marketplace).pipe(
      switchMap((pConfig: ProductTrackerConfig[]) => {
        const productsCalls: Observable<ProductEx>[] = pConfig.length
          ? pConfig.map((p) => this.asinService.getProductWithMarketplace(p.asin!, am.marketplace))
          : [];
        return forkJoin(productsCalls).pipe(defaultIfEmpty([]));
      }),
    );
  }

  getSearchTermRanks(row?: ProductTrackerRow): Observable<TrackedSearchTerm[]> {
    if (!row) return of([]);
    if (this.searchTermRanksCache.has(row.asin!)) {
      return this.searchTermRanksCache.get(row.asin!)!;
    }

    const ranks$ = new ReplaySubject<TrackedSearchTerm[]>(1);
    this.searchTermRanksCache.set(row.asin!, ranks$);

    if (!row.trackedSearchTerms || row.trackedSearchTerms.length === 0) {
      ranks$.next([]);
      return ranks$;
    }

    const targetedAsins =
      row.childAsins && row.childAsins.length > 0 ? new Set<string>(row.childAsins) : new Set<string>([row.asin!]);

    const rowRanks$: Observable<TrackedSearchTerm[]> = this.keywordTrackingService
      .getKeywordTrackingTimelines(row.trackedSearchTerms, this.accountMarketplace!.marketplace)
      .pipe(
        switchMap((searchTermAsinRanks: SearchTermAsinRanks[]) => {
          return this.userSelectionService.dateRange$.pipe(
            map((dateRange) => {
              const minDate: Date = new Date(dateRange[0]);
              minDate.setHours(0, 0, 0);
              const maxDate: Date = new Date(dateRange[1]);
              maxDate.setHours(23, 59, 59);
              const minDateTs = minDate.getTime() / 1000;
              const maxDateTs = maxDate.getTime() / 1000;
              const ranksBySearchTerm = new Map<string, SearchTermAsinRank[]>();
              for (const searchTermAsinRank of searchTermAsinRanks) {
                for (const [asin, timeline] of searchTermAsinRank.asinRanks.entries()) {
                  if (!targetedAsins.has(asin)) {
                    continue;
                  }
                  if (!ranksBySearchTerm.has(searchTermAsinRank.searchTerm)) {
                    ranksBySearchTerm.set(searchTermAsinRank.searchTerm, []);
                  }
                  ranksBySearchTerm
                    .get(searchTermAsinRank.searchTerm!)!
                    .push(...timeline.filter((rank) => rank.timestamp! >= minDateTs && rank.timestamp! <= maxDateTs));
                }
              }
              const result: TrackedSearchTerm[] = [];
              for (const [searchTerm, timeline] of ranksBySearchTerm.entries()) {
                result.push({ searchTerm, timeline: timeline.sort((a, b) => a.timestamp! - b.timestamp!) });
              }
              return result;
            }),
          );
        }),
      );

    this.searchTermRanksCache.set(row.asin!, rowRanks$);

    return rowRanks$;
  }

  getCatalog(): Catalog {
    return this.catalog!;
  }
}
