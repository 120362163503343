import { Component } from "@angular/core";
import { ICellRendererParams } from "@ag-grid-community/core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICON_BOOST } from "@m19-board/utils/iconsLabels";
import { AlgoMode, CampaignType, StrategyTactic, TacticType } from "@front/m19-api-client";
import { faImage, faVideo } from "@fortawesome/free-solid-svg-icons";
import { StrategyEx, StrategyGroupEx } from "@front/m19-models";
import { TranslocoService } from "@jsverse/transloco";

@Component({
  templateUrl: "./tactic-strategy-renderer.component.html",
  styleUrls: ["./strategy-grid.component.scss"],
})
export class TacticStrategyRendererComponent implements ICellRendererAngularComp {
  strat: any;
  ICON_BOOST = ICON_BOOST;
  faImage = faImage;
  faVideo = faVideo;
  CampaignType = CampaignType;
  AlgoModes = AlgoMode;
  segmentIndex: any;

  constructor(private translocoService: TranslocoService) {}

  agInit(params: any): void {
    this.strat = params.strat;
    this.segmentIndex = params.segmentIndex;
  }

  refresh(params: ICellRendererParams) {
    return true;
  }

  getTactics(strategy: StrategyEx | StrategyGroupEx): StrategyTactic[] {
    return (strategy as StrategyEx).strategyId > 0
      ? (strategy as StrategyEx).tactics
      : ((strategy as StrategyGroupEx).strategies![0].tactics as StrategyTactic[]);
  }

  getTacticsLabel(strategy: StrategyEx | StrategyGroupEx): string {
    const tactics = this.getTactics(strategy);
    const numBlackList = tactics.filter((t) => t.tacticType == TacticType.BLACKLIST).length;
    const numLegacy = tactics.filter((t) => t.tacticType == TacticType.LEGACY).length;
    if (numBlackList > 0 && numLegacy > 0) {
      return (
        this.translocoService.translate("tactic-strategy-renderer.numblacklist_blacklist_tactics", {
          count: numBlackList,
        }) +
        " | " +
        this.translocoService.translate("tactic-strategy-renderer.numlegacy_legacy_tactic", { count: numLegacy })
      );
    }
    if (numBlackList > 0) {
      return this.translocoService.translate("tactic-strategy-renderer.numblacklist_blacklist_tactics", {
        count: numBlackList,
      });
    }
    if (numLegacy > 0) {
      return this.translocoService.translate("tactic-strategy-renderer.numlegacy_legacy_tactic", { count: numLegacy });
    } else {
      return "";
    }
  }
}
