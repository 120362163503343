import { Injectable } from '@angular/core';
import {
  AudienceExpressionType,
  AudienceMatchType,
  AudienceTargeting,
  CampaignType,
  Marketplace,
  Strategy,
  StrategyApi,
} from '@front/m19-api-client';
import { StrategyUpdateParams } from '@front/m19-models/StrategyUpdateParams';
import { AccountMarketplaceLazyReadOnlyCache, catchAjaxError } from '@front/m19-utils';
import { map, Observable, of, shareReplay, switchMap, take, throwError } from 'rxjs';
import { StrategyCache } from './strategy.cache';
import { CampaignTypeStrategiesService } from './strategy.service';

@Injectable({
  providedIn: 'root',
})
export class SdStrategiesService implements CampaignTypeStrategiesService {
  private readonly sdStrategyPerAsinsCache = new AccountMarketplaceLazyReadOnlyCache<
    Observable<Map<string, Strategy[]>>
  >((accountId, marketplace) => this.loadStrategiesPerAsin(accountId, marketplace));

  private readonly sdStrategiesCache = new AccountMarketplaceLazyReadOnlyCache<Observable<Map<number, Strategy>>>(
    (accountId, marketplace) => this.loadStrategies(accountId, marketplace),
  );

  constructor(
    private strategyCache: StrategyCache,
    private strategyApi: StrategyApi,
  ) {}

  public getSDStrategies(accountId: string, marketplace: Marketplace): Observable<Map<number, Strategy>> {
    return this.sdStrategiesCache.getValue(accountId, marketplace);
  }

  public getSDStrategiesPerAsin(accountId: string, marketplace: Marketplace): Observable<Map<string, Strategy[]>> {
    return this.sdStrategyPerAsinsCache.getValue(accountId, marketplace);
  }

  checkStrategyUpdate(strategyUpdateParams: StrategyUpdateParams): Observable<string[]> {
    // no speicifc checks for SD strategies
    return of([]);
  }

  checkStrategyCreation(strategy: Strategy): Observable<string[]> {
    // no specific checks for SB strategy creation
    return of([]);
  }

  public addRemarketingAudienceToSdStrategy(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    audienceExpressionType: AudienceExpressionType,
    audienceMatchType: AudienceMatchType,
    lookback: number,
  ): Observable<AudienceTargeting> {
    return this.strategyApi
      .addAudienceTargeting({
        accountId: accountId,
        marketplace: marketplace,
        strategyId: strategyId,
        expressionType: audienceExpressionType,
        matchType: audienceMatchType,
        lookback: lookback,
      })
      .pipe(
        catchAjaxError('Error creating Remarketing targeting: '),
        map((response) => {
          const audience = response.entity as AudienceTargeting;
          // update the strategy cache
          this.strategyCache.strategyListCache.update({ accountId, marketplace }, (strategies) => {
            const strategy = strategies.get(strategyId);
            if (strategy && !strategy.audienceTargetings.includes(audience)) {
              strategy.audienceTargetings.push(audience);
            }
            return strategies;
          });
          return audience;
        }),
      );
  }

  public removeRemarketingAudienceFromSdStrategy(
    accountId: string,
    marketplace: Marketplace,
    strategyId: number,
    audienceTargetId: number,
  ): Observable<void> {
    return this.sdStrategiesCache.getValue(accountId, marketplace)!.pipe(
      take(1),
      switchMap((strategies) => {
        const strategy = strategies.get(strategyId);
        if (!strategy) {
          return throwError(() => 'Strategy not found');
        }
        const toDelete = strategy.audienceTargetings.find((x) => x.audienceTargetId == audienceTargetId);
        if (toDelete == undefined) {
          return throwError(() => 'Audience not found');
        }
        return this.strategyApi
          .deleteAudienceTargeting({
            accountId,
            marketplace,
            strategyId,
            audienceTargetId,
          })
          .pipe(catchAjaxError('Error deleting Remarketing targeting: '));
      }),
      map(() => {
        // update the strategy cache
        this.strategyCache.strategyListCache.update({ accountId, marketplace }, (strategies) => {
          const strategy = strategies.get(strategyId);
          strategy?.audienceTargetings.splice(
            strategy.audienceTargetings.findIndex((x) => x.audienceTargetId == audienceTargetId),
            1,
          );
          return strategies;
        });
        return void 0;
      }),
    );
  }

  /* Loaders */

  private loadStrategies(accountId: string, marketplace: Marketplace): Observable<Map<number, Strategy>> {
    return this.strategyCache.strategyListCache.get({ accountId, marketplace }).pipe(
      map((strategies) => {
        const sdStrategies = new Map<number, Strategy>();
        for (const strategy of strategies.values()) {
          if (strategy.campaignType === CampaignType.SD) {
            sdStrategies.set(strategy.strategyId!, strategy);
          }
        }
        return sdStrategies;
      }),
      shareReplay(1),
    );
  }

  private loadStrategiesPerAsin(accountId: string, marketplace: Marketplace): Observable<Map<string, Strategy[]>> {
    // vendor accounts have no all other product strategies so no need to load the catalog
    return this.strategyCache.strategyListCache.get({ accountId, marketplace }).pipe(
      map((strategies) => {
        const strategiesPerAsin = new Map<string, Strategy[]>();
        for (const strategy of strategies.values()) {
          if (strategy.campaignType !== CampaignType.SD) {
            continue;
          }
          for (const strategyAsin of strategy.asins ?? []) {
            const asin = strategyAsin.asin!;
            if (!strategiesPerAsin.has(asin!)) {
              strategiesPerAsin.set(asin!, []);
            }
            strategiesPerAsin.get(asin)!.push(strategy);
          }
        }
        return strategiesPerAsin;
      }),
    );
  }
}
