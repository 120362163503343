import { NgModule } from "@angular/core";
import { StrategyLinkComponent } from "@m19-board/strategies/strategy-link/strategy-link.component";
import { TranslocoDirective } from "@jsverse/transloco";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { CampaignTypeBadgeComponent } from "@m19-board/shared/campaign-type-badge/campaign-type-badge.component";
import { RouterLink } from "@angular/router";
import { MatTooltip } from "@angular/material/tooltip";
import { NgClass, NgStyle } from "@angular/common";

@NgModule({
  declarations: [StrategyLinkComponent],
  exports: [StrategyLinkComponent],
  imports: [TranslocoDirective, FaIconComponent, CampaignTypeBadgeComponent, RouterLink, MatTooltip, NgStyle, NgClass],
})
export class StrategyLinkModule {}
