import { CurrencyPipe, DatePipe, NgClass } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { MatRadioModule } from "@angular/material/radio";
import { AccountMarketplace, AlgoMode, Marketplace } from "@front/m19-api-client";
import { AlgoModeStr } from "@front/m19-models";
import { AccountSelectionService, AuthService, CurrencyService, RankOption } from "@front/m19-services";
import { DecimalInputComponent } from "@m19-board/shared/decimal-input/decimal-input.component";
import { IntegerValueComponent } from "@m19-board/shared/integer-value/integer-value.component";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { AmazonTimezoneService } from "@m19-board/utils/amazonTimezoneService";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import moment from "moment-timezone";
import { algorithmValidator, dailyBudgetLowerBound } from "../../../utils/algoValidators";

export type AlgoModeConfig = {
  algoMode: AlgoMode;
  acosTarget: number;
  dailyBudget: number;
  monthlyBudget: number;
  suggestedBid: number;
  tacosTarget?: number;
  isValid?: boolean;
};

export type AlgoModeConfigValid = {
  isValid: boolean;
  errors: ValidationErrors | null;
};

@UntilDestroy()
@Component({
  selector: "app-algo-mode-selection",
  templateUrl: "./algo-mode-selection.component.html",
  styleUrls: ["../../../strategies/strategies/strategy-styles.scss"],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    IntegerValueComponent,
    MatRadioModule,
    DecimalInputComponent,
    CurrencyPipe,
    DatePipe,
    NgClass,
    TranslocoRootModule,
  ],
})
export class AlgoModeSelectionComponent implements OnInit {
  readonly AlgoMode = AlgoMode;
  readonly AlgoModeStr = AlgoModeStr;
  AlgoModeStrKey = {
    [AlgoMode.PRODUCT_LAUNCH]: {
      description: "algo-mode-selection.force_product_visibility",
      shortDescription: "algo-mode-selection.constant_bid",
    },
    [AlgoMode.ACOS_TARGET]: {
      description: "algo-mode-selection.optimize_sales_with_acos_target",
      shortDescription: "algo-mode-selection.acos_target",
    },
    [AlgoMode.MONTHLY_BUDGET_TARGET]: {
      description: "algo-mode-selection.optimize_sales_with_monthly_budget_target",
      shortDescription: "algo-mode-selection.monthly_budget_target",
    },
    [AlgoMode.TACOS_TARGET]: {
      description: "algo-mode-selection.optimize_sales_with_tacos_target",
      shortDescription: "algo-mode-selection.tacos_target",
    },
  };
  readonly form = new FormGroup({
    algoMode: new FormControl<AlgoMode>(AlgoMode.ACOS_TARGET, [Validators.required]),
    acosTarget: new FormControl<number | undefined>(undefined),
    monthlyBudget: new FormControl<number | undefined>(undefined),
    suggestedBid: new FormControl<number | undefined>(undefined),
    dailyBudget: new FormControl<number | undefined>(undefined),
  });

  @Input()
  selectedAlgoModeConfig?: Partial<AlgoModeConfig>;

  @Input()
  vertical = false;

  @Input()
  suggestedAcosTarget?: number;

  @Input()
  disabled = false;

  @Input()
  today?: string;

  @Output()
  algoModeConfig = new EventEmitter<Partial<AlgoModeConfig>>();

  @Output()
  valid = new EventEmitter<AlgoModeConfigValid>();

  accountId?: string;
  marketplace?: Marketplace;
  minAllowedBid?: number;
  currencySymbol?: string;
  currencyCode?: string;
  dailyBudgetLowerBound?: number;
  endOfCurrentMonth?: string;
  locale?: string;
  initAlgoMode: Partial<AlgoModeConfig> | undefined;

  constructor(
    private accountSelection: AccountSelectionService,
    private currencyService: CurrencyService,
    private amazonTimezoneService: AmazonTimezoneService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.initAlgoMode = this.selectedAlgoModeConfig;
    if (this.today) {
      this.endOfCurrentMonth = moment(this.today, "YYYY-MM-DD").endOf("month").format("YYYY-MM-DD");
    }
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.locale = user.locale;
    });

    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((am: AccountMarketplace) => {
        this.accountId = am.accountId;
        this.marketplace = am.marketplace;
        this.minAllowedBid = am.minBid;
        this.currencySymbol = this.currencyService.getCurrencySymbolFromMarketplace(this.marketplace);
        this.currencyCode = this.currencyService.getCurrencyCode(this.marketplace);
        this.form.setValidators(algorithmValidator(this.currencyCode!, this.minAllowedBid!) as unknown as ValidatorFn);
        if (this.selectedAlgoModeConfig) {
          this.form.controls.algoMode.setValue(this.selectedAlgoModeConfig.algoMode!);
          this.form.controls.acosTarget.setValue(this.selectedAlgoModeConfig.acosTarget);
          this.form.controls.monthlyBudget.setValue(this.selectedAlgoModeConfig.monthlyBudget);
          this.form.controls.suggestedBid.setValue(this.selectedAlgoModeConfig.suggestedBid);
          this.form.controls.dailyBudget.setValue(this.selectedAlgoModeConfig.dailyBudget);
        } else {
          this.toggleAlgorithm(AlgoMode.ACOS_TARGET);
        }
        if (!this.today) {
          this.endOfCurrentMonth = this.amazonTimezoneService
            .getNow(this.marketplace)
            .endOf("month")
            // remove the time and tz info - if we do not do this and convert to Date, the Date will be converted to the browser's timezone
            // see: https://github.com/m19-dev/main-repo/issues/5322
            .format("YYYY-MM-DD");
        }
      });
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.algoModeConfig.emit({ ...value, isValid: this.form.valid } as Partial<AlgoModeConfig>);
      this.valid.emit({
        isValid: this.form.valid,
        errors: this.form.errors,
      });
    });
  }

  toggleAlgorithm(algo: string) {
    this.form.controls.algoMode.setValue(algo as AlgoMode);
    if (algo === AlgoMode.PRODUCT_LAUNCH) {
      this.dailyBudgetLowerBound = dailyBudgetLowerBound(this.currencyCode!);
      this.form.controls.dailyBudget.setValue(dailyBudgetLowerBound(this.currencyCode!));
      this.form.controls.suggestedBid.setValue(this.minAllowedBid);
      this.form.controls.acosTarget.setValue(undefined);
      this.form.controls.monthlyBudget.setValue(undefined);
    } else if (algo === AlgoMode.ACOS_TARGET) {
      this.form.controls.dailyBudget.setValue(undefined);
      this.form.controls.suggestedBid.setValue(undefined);

      // suggest acos target if it is not the initial algo mode
      if (this.initAlgoMode?.algoMode !== AlgoMode.ACOS_TARGET) {
        this.form.controls.acosTarget.setValue(this.suggestedAcosTarget);
      } else {
        this.form.controls.acosTarget.setValue(this.initAlgoMode.acosTarget!);
      }
      this.form.controls.monthlyBudget.setValue(undefined);
    } else {
      this.form.controls.dailyBudget.setValue(undefined);
      this.form.controls.suggestedBid.setValue(undefined);
      this.form.controls.acosTarget.setValue(undefined);
      this.form.controls.monthlyBudget.setValue(undefined);
    }
  }

  protected readonly RankOption = RankOption;
}
