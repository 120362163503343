import { formatDate } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Currency, UpdateUserRequest, User } from "@front/m19-api-client";
import { AuthService, CurrencyService } from "@front/m19-services";
import { TranslocoService } from "@jsverse/transloco";
import { I18nService } from "@m19-board/services/i18n.service";
import { ICON_CHEVRON_DOWN, ICON_EMAIL, ICON_USER } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { ChangePasswordComponent } from "./change-password/change-password.component";

@UntilDestroy()
@Component({
  selector: "app-profile-settings",
  templateUrl: "./profile-settings.component.html",
})
export class ProfileSettingsComponent implements OnInit {
  user: User | undefined;

  readonly ICON_USER = ICON_USER;
  readonly ICON_EMAIL = ICON_EMAIL;
  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;

  readonly locales: Record<string, string> = {
    "de-DE": "German (DE)",
    "de-CH": "German (CH)",
    "en-US": "English (US)",
    "en-GB": "English (GB)",
    "en-CA": "English (CA)",
    "en-AU": "English (AU)",
    "es-ES": "Spanish (ES)",
    "fr-FR": "French (FR)",
    "fr-CA": "French (CA)",
    "fr-CH": "French (CH)",
    "it-IT": "Italian (IT)",
    "it-CH": "Italian (CH)",
    "ja-JP": "Japanese (JP)",
  };

  readonly languages: Record<string, string> = {
    en: "English",
    fr: "French",
    ja: "Japanese",
  };

  isLoading = false;

  form = new FormGroup({
    userName: new FormControl<string>("", Validators.required),
    email: new FormControl<string>("", [Validators.required, Validators.email]),
    defaultCurrency: new FormControl<Currency>(Currency.EUR),
    locale: new FormControl<keyof typeof this.locales>("en-US", Validators.required),
    language: new FormControl<keyof typeof this.languages>("en"),
  });

  constructor(
    private authService: AuthService,
    public currencyService: CurrencyService,
    private modalService: BsModalService,
    private toasterService: ToastrService,
    public translocoService: TranslocoService,
    private i18nService: I18nService,
  ) {}

  ngOnInit(): void {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = { ...user };
      this.form.patchValue(user);
    });
  }

  saveUser(): void {
    if (this.form.invalid) return;
    this.isLoading = true;

    const updateRequest: UpdateUserRequest = {};
    const currentLang = this.user?.language;

    updateRequest.email = this.form.controls.email.value ?? undefined;
    updateRequest.userName = this.form.controls.userName.value ?? undefined;
    updateRequest.locale = this.form.controls.locale.value ?? "en-US";
    updateRequest.defaultCurrency = this.form.controls.defaultCurrency.value ?? Currency.USD;
    updateRequest.language = this.form.controls.language.value ?? "en";

    this.authService.updateUserV2(updateRequest).subscribe({
      next: () => {
        this.toasterService.success("User settings successfully updated");
        this.isLoading = false;

        if (this.form.controls.language.value !== currentLang) {
          this.i18nService.changeLanguageInLocalStorage(this.form.controls.language.value!);
          window.location.reload();
        }
      },
      error: (error: string) => {
        this.isLoading = false;
        this.toasterService.error("Error updating user settings: " + error, "User settings update error");
      },
    });
  }

  dateExample(locale: string) {
    return formatDate(Date.now(), "shortDate", locale);
  }

  getInitials(): string {
    return this.user?.userName?.charAt(0).toUpperCase() ?? "";
  }

  changePassword() {
    const modalOptions: ModalOptions = {
      initialState: {
        user: this.user,
      },
      class: "modal-primary",
    };
    this.modalService.show(ChangePasswordComponent, modalOptions);
  }

  changeLanguage(key: any) {
    this.form.controls.language.setValue(key);
  }

  changeLocale(key: string) {
    this.form.controls.locale.setValue(key);
  }

  readonly Currency = Currency;
}
