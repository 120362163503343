<div
  class="target-card card-perso collapse-card border-shadow left-child right-child card"
  [class.selectable-hover]="!isReadOnly"
  *transloco="let t"
>
  @if (defaultTactic) {
    <ICard>
      <ng-template #body>
        <IBadge color="gray" label="{{ t('sd-targeting.product_page') }}" class="ml-2" />

        <span class="ml-5"> {{ t("sd-targeting.ai-powered_targeting") }}</span>

        <span
          class="icon-delete-append line-height-1 float-right"
          [class.disabled]="isReadOnly || isLastTargeting"
          matTooltip="{{ t('sd-targeting.delete_targeting') }}"
          (click)="!isReadOnly && disableAutoAlgoExploration(); $event.stopPropagation()"
        >
          <fa-icon [icon]="faTrash" class="font-xl"></fa-icon>
        </span>
      </ng-template>
    </ICard>
  }
  @if (tactic) {
    <ICard>
      <ng-template #body>
        <IBadge
          color="gray"
          [label]="
            tactic.tacticType === TacticType.BLACKLIST
              ? t('sd-targeting.blacklist_product_pages')
              : t('sd-targeting.product_page')
          "
          class="ml-2"
        />
        <span class="ml-5">{{ segment?.name }}</span>

        <span
          class="icon-delete-append line-height-1 float-right"
          [class.disabled]="isReadOnly || (tactic.tacticType !== TacticType.BLACKLIST && isLastTargeting)"
          matTooltip="{{ t('sd-targeting.delete_targeting') }}"
          (click)="!isReadOnly && removeTacticFromStrategy(); $event.stopPropagation()"
        >
          <fa-icon [icon]="faTrash" class="font-xl"></fa-icon>
        </span>
        <fa-icon
          [icon]="faPencil"
          (click)="editSegment()"
          matTooltip="{{ t('sd-targeting.update_tactic_segment') }}"
          size="lg"
          class="float-right mr-2"
        ></fa-icon>
        <span class="counter float-right"> #products: {{ nbProductTargeting() }} </span>
      </ng-template>
    </ICard>
  }
  @if (audience) {
    <ICard>
      <ng-template #body>
        <IBadge
          data-testid="sd-targeting-audience"
          color="gray"
          label="{{ t('sd-targeting.remarketing') }}"
          class="ml-2"
        />

        <span class="ml-5"
          >{{
            t("sd-targeting.last_days", [
              audience.expressionType | titlecase,
              supportedAudienceMatchType[audience.matchType!],
              audience.lookback,
            ])
          }}
        </span>
        <span
          class="icon-delete-append line-height-1 float-right"
          [class.disabled]="isReadOnly || isLastTargeting"
          matTooltip="{{ t('sd-targeting.delete_targeting') }}"
          (click)="!isReadOnly && removeAudienceFromStrategy(); $event.stopPropagation()"
        >
          <fa-icon [icon]="faTrash" class="font-xl"></fa-icon>
        </span>
      </ng-template>
    </ICard>
  }
</div>
