import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AsinService } from "@front/m19-services";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Marketplace } from "@front/m19-api-client";
import { Catalog } from "@front/m19-models";

export enum AsinLabel {
  NEW = "New",
  DELETED = "Deleted",
}

@UntilDestroy()
@Component({
  selector: "app-product-list-detail-popup",
  templateUrl: "./product-list-detail-popup.component.html",
  styleUrls: ["./product-list-detail-popup.component.scss"],
})
export class ProductListDetailsPopupComponent implements OnInit {
  @Input()
  asins!: string[];
  @Input()
  accountId!: string;
  @Input()
  marketplace!: Marketplace;
  @Input()
  asinLabels: Map<string, AsinLabel> = new Map();

  @Output() closed = new EventEmitter<void>();

  productTitles?: { [key: string]: string };
  filteredAsins: string[] = [];
  shownAsins: string[] = [];
  loading = true;

  private filter = new RegExp("", "i");
  private catalog?: Catalog;
  readonly faSearch = faSearch;
  readonly itemsShown = 15;

  pageEvent = new PageEvent();

  constructor(
    public ref: BsModalRef,
    private asinService: AsinService,
  ) {}

  ngOnInit(): void {
    this.productTitles = {};
    this.searchInProductList();

    this.asinService
      .getCatalog(this.accountId, this.marketplace)
      .pipe(untilDestroyed(this))
      .subscribe((catalog) => {
        this.productTitles = {};
        if (catalog) {
          this.catalog = catalog;
          for (const asin of this.asins) {
            this.productTitles[asin] = this.catalog.asinOffers.get(asin)?.title ?? "";
          }
        }
        this.searchInProductList();
        this.loading = false;
      });
  }

  setFilter(query: string): void {
    this.filter = new RegExp(query, "i");
    this.searchInProductList();
  }

  changeContent(event: PageEvent): void {
    this.pageEvent = event;
    this.shownAsins = this.filteredAsins.slice(
      event.pageIndex * event.pageSize,
      (event.pageIndex + 1) * event.pageSize,
    );
  }

  private searchInProductList(): void {
    this.filteredAsins = this.asins.filter(
      (asin) => this.filter.test(asin) || this.filter.test(this.productTitles![asin]),
    );
    this.shownAsins = this.filteredAsins.slice(0, this.itemsShown);
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
