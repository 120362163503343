import { Component, OnInit, signal, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort, SortDirection } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import {
  AccountSelectionService,
  AsinService,
  AuthService,
  KeywordTrackingService,
  SegmentService,
} from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { TranslocoService } from "@jsverse/transloco";
import { CsvExportService, fieldExtractor, simpleField } from "@m19-board/services/csv-export.service";
import { KeywordTrackerService } from "@m19-board/services/keyword-tracker.service";
import { ICON_ADD, ICON_ARROW_RIGHT, ICON_TRASH_O } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { combineLatest, Observable, switchMap, take, tap } from "rxjs";
import { ConfirmPopupComponent } from "../shared/confirm-popup/confirm-popup.component";
import { StopKeywordTrackingModalComponent } from "./stop-tracking-modal/stop-keyword-tracking-modal.component";
import {
  AccountMarketplace,
  KeywordTrackerConfig,
  KeywordTrackingFrequency,
  Marketplace,
  ProductTrackerConfig,
  User,
} from "@front/m19-api-client";
import { Catalog, SegmentConfigType, SegmentEx } from "@front/m19-models";

export type KeywordTrackerRow = {
  searchTerm: string;
  sfr: number;
  top10: number;
  top25: number;
  frequency: KeywordTrackingFrequency;
  top10Asins: string[];
  strategyIds: number[];
};

export function rankOrInfinity(rank: number, sortDirection: SortDirection) {
  if (rank === undefined || rank === null) return sortDirection === "asc" ? Infinity : -Infinity;
  return rank;
}

export enum KeywordTrackerPageQueryParams {
  segment = "segment",
}

@UntilDestroy()
@Component({
  templateUrl: "./keyword-tracker-page.component.html",
  styleUrls: ["./keyword-tracker-page.component.scss"],
})
export class KeywordTrackerPageComponent implements OnInit {
  readonly ICON_ADD = ICON_ADD;
  readonly ICON_TRASH = ICON_TRASH_O;
  readonly ICON_ARROW = ICON_ARROW_RIGHT;

  // selected account
  accountId?: string;
  locale?: string;
  marketplace?: Marketplace;
  organizationId?: number;
  accountMarketplace?: AccountMarketplace;
  uiVersion = 0;

  // multiple delete
  bulkDeleteMode = false;
  searchTermToDelete: Set<string> = new Set();
  isAllSelected = false;

  // data
  data?: KeywordTrackerRow[];
  catalog?: Catalog;
  productTrackerConfig?: ProductTrackerConfig[];

  segmentOptions: Option<SegmentEx>[] = [];
  selectedSegments = signal<Option<SegmentEx>[]>([]);

  // mat table
  datasource = new MatTableDataSource<KeywordTrackerRow>([]);
  data$?: Observable<KeywordTrackerRow[]>;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  // state
  filter = "";
  segmentFilter: Set<SegmentEx> = new Set();
  isReadOnly = false;
  loading = false;
  segments?: SegmentEx[];

  constructor(
    private accountSelection: AccountSelectionService,
    private keywordTrackingService: KeywordTrackingService,
    private asinService: AsinService,
    private segmentService: SegmentService,
    private modalService: BsModalService,
    private csvExportService: CsvExportService,
    private route: ActivatedRoute,
    private router: Router,
    private keywordTrackerService: KeywordTrackerService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((u: User) => {
      this.locale = u.locale;
      this.uiVersion = u.uiVersion ?? 0;
    });
    this.loading = true;
    this.isAllSelected = false;
    this.searchTermToDelete.clear();
    // get catalog + get tracked products
    // get tracked kw
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        tap((am: AccountMarketplace) => {
          this.loading = true;
          this.accountId = am.accountId;
          this.marketplace = am.marketplace;
          this.organizationId = am.resourceOrganizationId;
          this.accountMarketplace = am;
          this.data = [];
        }),
        switchMap((am: AccountMarketplace) =>
          combineLatest<[Catalog, KeywordTrackerRow[], ProductTrackerConfig[], Map<number, SegmentEx>, ParamMap]>([
            this.asinService.getCatalog(am.accountId, am.marketplace),
            this.keywordTrackerService.keywordTrackerData$,
            this.keywordTrackingService.getProductTrackerConfig(am.accountId, am.marketplace),
            this.segmentService.getSegments(am.accountId, am.marketplace),
            this.route.queryParamMap,
          ]),
        ),
      )
      .subscribe(([catalog, kwTrackerConfig, productTrackerConfig, segmentIndex, params]) => {
        this.data = kwTrackerConfig;
        this.segmentFilter = new Set();
        this.segments = Array.from(segmentIndex.values()).filter(
          (s) => s.segmentType == SegmentConfigType.KeywordSegment,
        );
        this.segmentOptions = this.segments.map((s) => ({ label: s.name, value: s, segmentId: s.segmentId }));

        const selectedSegmentsParams = params.getAll(KeywordTrackerPageQueryParams.segment);
        if (selectedSegmentsParams && selectedSegmentsParams.length > 0) {
          for (const id of selectedSegmentsParams) {
            const selectedSegment = segmentIndex.get(parseInt(id));
            if (selectedSegment && selectedSegment.segmentType == SegmentConfigType.KeywordSegment) {
              this.segmentFilter.add(selectedSegment);
            }
          }
          this.selectedSegments.set(
            Array.from(this.segmentFilter).map((s) => ({ label: s.name, value: s, segmentId: s.segmentId })),
          );
        }
        this.keywordTrackerService.setKeywordTrackerData(this.data);
        this.updateData();
        this.catalog = catalog;
        this.productTrackerConfig = productTrackerConfig;
        this.loading = false;
        this.setPagination(this.datasource.data);
      });
    this.accountSelection.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));

    this.datasource.filterPredicate = (row, filter) => {
      if (filter) {
        const regexp = new RegExp(filter, "i");
        return regexp.test(row.searchTerm);
      }
      return true;
    };
  }

  sortData(sort: Sort) {
    const data = this.data?.slice();
    if (data && (!sort.active || sort.direction === "")) {
      // Idle sort acts as ascending sort
      this.datasource.data = data.sort((a, b) => this.compare(a.sfr, b.sfr, true));
      return;
    }

    this.datasource.data = this.datasource.filteredData.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      return this.compare(
        a[sort.active as keyof KeywordTrackerRow] as number,
        b[sort.active as keyof KeywordTrackerRow] as number,
        isAsc,
      );
    });
  }

  private compare(a: number | string, b: number | string, isAsc: boolean) {
    if (!a) a = +Infinity;
    if (!b) b = +Infinity;
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  setPagination(tableData: KeywordTrackerRow[]) {
    this.datasource = new MatTableDataSource<any>(tableData);
    this.datasource.paginator = this.paginator;
    this.data$ = this.datasource.connect();
  }

  toggleKeywordToDelete(kw: string) {
    if (!this.searchTermToDelete.has(kw)) this.searchTermToDelete.add(kw);
    else this.searchTermToDelete.delete(kw);

    this.isAllSelected = this.allSelected();

    this.bulkDeleteMode = this.searchTermToDelete.size > 0;
  }

  allSelected(): boolean {
    const dataSet = new Set(this.datasource.data.map((d) => d.searchTerm));
    return dataSet.size === this.searchTermToDelete.size && [...dataSet].every((v) => this.searchTermToDelete.has(v));
  }

  selectAllToDelete() {
    if (!this.isAllSelected) {
      this.searchTermToDelete = new Set<string>(this.datasource.filteredData.map((d) => d.searchTerm));
    } else {
      this.searchTermToDelete.clear();
    }
    this.isAllSelected = !this.isAllSelected;
  }

  handleBulkDelete() {
    if (!this.bulkDeleteMode) {
      this.bulkDeleteMode = true;
      return;
    }
    if (this.searchTermToDelete.size === 0) return;

    const toDelete: KeywordTrackerConfig[] = [...this.searchTermToDelete].map((s) => ({
      accountId: this.accountId,
      marketplace: this.marketplace,
      organizationId: this.organizationId,
      searchTerm: s,
    }));

    const plural = toDelete.length > 1 ? "s" : "";

    const confirmModal = this.modalService.show(ConfirmPopupComponent, {
      initialState: {
        title: this.translocoService.translate("common.confirm_deletion"),
        message: `Do you confirm stop tracking the keyword${plural} selected (${toDelete.length})?`,
      },
    });

    confirmModal.content?.confirm
      .pipe(
        take(1),
        untilDestroyed(this),
        switchMap((_) =>
          this.keywordTrackingService.removeKeywordTrackingConfig(
            this.accountId!,
            this.marketplace!,
            this.organizationId!,
            toDelete,
          ),
        ),
      )
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("keyword-tracker-page.stopped_tracking_kw", [toDelete.length]),
            this.translocoService.translate("keyword-tracker-page.tracking_keyword_stopped"),
          );
        },
        error: (e) => {
          this.toastrService.error(
            this.translocoService.translate("keyword-tracker-page.error_when_stopping_tracking_kw", [
              toDelete.length,
              e,
            ]),
            this.translocoService.translate("keyword-tracker-page.tracking_keyword_stop_error"),
          );
        },
        complete: () => {
          this.cancelBulkDelete();
        },
      });
  }

  cancelBulkDelete() {
    this.bulkDeleteMode = false;
    this.searchTermToDelete.clear();
  }

  private updateData() {
    if (this.segmentFilter && this.segmentFilter.size > 0) {
      const filteredData = [];
      for (const row of this.data ?? []) {
        for (const segment of this.segmentFilter.values()) {
          if (segment.matchQuery(row.searchTerm)) {
            filteredData.push(row);
            break;
          }
        }
      }
      this.datasource.data = filteredData;
    } else {
      this.datasource.data = this.data ?? [];
    }
  }

  stopTracking(searchTerm: string) {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.accountId,
        marketplace: this.marketplace,
        organizationId: this.organizationId,
        searchTerm: searchTerm,
      },
      class: "modal-lg modal-dialog modal-dialog-centered",
    };
    this.modalService.show(StopKeywordTrackingModalComponent, modalOptions);
  }

  changeFilter() {
    this.datasource.filter = this.filter;
  }

  addNewKeyword() {
    this.keywordTrackerService.openAddKwTrackingModal(this.accountId!, this.marketplace!, this.organizationId!);
  }

  exportAsCsv() {
    this.csvExportService.exportCsv(
      {
        prefix: "keyword_tracker",
        accountGroupName: this.accountMarketplace?.accountGroupName,
        marketplace: this.marketplace,
      },
      this.datasource.filteredData,
      [
        simpleField("searchTerm"),
        simpleField("top10"),
        simpleField("top25"),
        simpleField("frequency", "trackingFrequency"),
        fieldExtractor("topOfSearchRankingsOptimization", (r) => ((r.strategyIds?.length ?? 0) > 0).toString()),
      ],
    );
  }

  toggleTrackingFrequency(row: KeywordTrackerRow) {
    const frequency =
      row.frequency === KeywordTrackingFrequency.hourly
        ? KeywordTrackingFrequency.daily
        : KeywordTrackingFrequency.hourly;
    this.keywordTrackingService
      .updateKeywordTrackerConfig({
        accountId: this.accountId,
        marketplace: this.marketplace,
        organizationId: this.organizationId,
        searchTerm: row.searchTerm,
        frequency: frequency,
      })
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("keyword-tracker-page.tracking_frequency", [row.searchTerm, frequency]),
            this.translocoService.translate("keyword-tracker-page.tracking_frequency_updated"),
          );
        },
        error: (e) => {
          this.toastrService.error(
            this.translocoService.translate("keyword-tracker-page.error_updating_tracking_frequency", [
              row.searchTerm,
              e,
            ]),
            this.translocoService.translate("keyword-tracker-page.tracking_frequency_update_error"),
          );
        },
      });
  }

  setSegmentsFilter(options: Option<SegmentEx>[]) {
    const filter = options.map((o) => o.value);
    this.segmentFilter = new Set(filter);
    this.selectedSegments.set(options);
    this.updateData();
    this.updateQueryParams();
  }

  handleRowClick(searchTerm: string) {
    if (!this.bulkDeleteMode) {
      this.router
        .navigate([(this.uiVersion == 0 ? "/keyword-tracker/" : "/keyword-center/keyword-tracker/") + searchTerm], {
          queryParamsHandling: "merge",
        })
        .then();
    } else {
      this.toggleKeywordToDelete(searchTerm);
    }
  }

  private updateQueryParams() {
    const queryParams = {
      [KeywordTrackerPageQueryParams.segment]: Array.from(this.segmentFilter.values()).map((s) => s.segmentId),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
    });
  }

  protected readonly KeywordTrackingFrequency = KeywordTrackingFrequency;
}
