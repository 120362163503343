import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { IButtonComponent } from '../ibutton/ibutton.component';
import { ModalPosition, ModalRef } from '../../services/modal.service';
import { NgClass } from '@angular/common';

@Component({
  selector: 'IModal',
  standalone: true,
  imports: [IButtonComponent, NgClass],
  templateUrl: './imodal.component.html',
  styleUrls: ['./imodal.component.scss'],
  animations: [
    trigger('overlayAnimation', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition('void <=> *', animate(100)),
    ]),
  ],
})
export class IModalComponent implements OnInit, OnDestroy {
  private readonly modalOpenClass: string[] = ['overflow-hidden', 'relative', 'top-0', 'right-0', 'left-0', 'bottom-0'];

  @ViewChild('modalContent') modalContent!: ElementRef;
  @ViewChild('element', { static: true }) element!: ElementRef<HTMLElement>;

  title?: string;
  titleIcon?: string;
  fullScreen: boolean = false;
  closeable: boolean = true;
  position: ModalPosition = 'center';

  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();

  dialogRef = inject(ModalRef);

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key == 'Escape') {
      this.closeModal();
    }
  }

  constructor() {
    const mousedownEvent = fromEvent<MouseEvent>(document, 'mousedown');

    mousedownEvent.subscribe((event: MouseEvent) => {
      const clickedInside = this.modalContent.nativeElement.contains(event.target);
      if (!clickedInside) {
        this.closeModal();
      }
    });
  }

  ngOnInit(): void {
    document.body.classList.add(...this.modalOpenClass);
  }

  ngOnDestroy() {
    document.body.classList.remove(...this.modalOpenClass);
  }

  closeModal(): void {
    if (!this.closeable) return;
    this.closeEvent.emit();
    if (this.dialogRef) this.dialogRef.close();
  }
}
