<ng-container *transloco="let t">
  <div [classList]="['flex flex-col ' + (!reviewMode && 'h-full')]">
    <div class="flex-1 p-5">
      <h4 class="text-xl font-medium">{{ t("sb-form-algo.strategy_algorithm") }}</h4>
      @if (!reviewMode) {
        <p class="m-0 text-gray-500">{{ t("sb-form-algo.choose_an_algorithm_for_this_strategy") }}</p>
      }

      <div class="mt-5 w-full">
        <app-algo-mode-selection
          [vertical]="true"
          [disabled]="reviewMode"
          [selectedAlgoModeConfig]="algoConfig!"
          (algoModeConfig)="newAlgoConfig($event)"
          (valid)="isValid = $event.isValid"
        />
      </div>
    </div>

    @if (!reviewMode) {
      <div class="flex justify-end border-t border-gray-300 bg-white p-4">
        <div class="flex gap-x-2">
          <IButton
            (onClick)="onFormPrevious.emit(); $event.preventDefault()"
            label="{{ t('common.previous') }}"
            variant="soft"
            color="gray"
            icon="icon-[material-symbols--chevron-left-rounded]"
          />
          <IButton
            [disabled]="!isValid"
            label="{{ t('common.next') }}"
            (onClick)="submitForm(true)"
            icon="icon-[material-symbols--chevron-right-rounded]"
            [trailing]="true"
            testid="next-button"
          />
        </div>
      </div>
    }
  </div>
</ng-container>
