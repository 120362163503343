<ng-container *transloco="let t">
  <app-notification-card
    [notification]="notification"
    [small]="small"
    helplink="https://help.m19.com/old-campaigns-running-in-parallel-with-m19-strategies"
  >
    <span title>
      {{ t("common.strategy") }}
      <b
        ><app-strategy-link
          [strategyId]="strategyId"
          [withCampaignType]="false"
          [withCampaignState]="false"
          class="inline-block"
        ></app-strategy-link
      ></b>
      {{ t("notification-card-campaign-overlap.overlaping_campaigns") }}
    </span>
    <span action>
      @if (asins.length > 1) {
        <span>{{ t("common.asins") }}</span>
      }

      @if (asins.length === 1) {
        <span>{{ t("common.asin") }} </span>
      }
      @for (asin of asins; track asin; let isLast = $last) {
        <span>
          <span class="break-all"
            ><b
              ><a href="{{ getAsinLink(asin) }}" target="_blank">{{ asin }}</a></b
            >{{ isLast ? "" : ", " }}
          </span>
        </span>
      }
      @if (asins.length > 1) {
        <span>{{ t("notification-card-campaign-overlap.are_present") }} </span>
      }
      @if (asins.length === 1) {
        <span>{{ t("notification-card-campaign-overlap.is_present") }} </span>
      }
      <b
        ><app-strategy-link
          [strategyId]="strategyId"
          [withCampaignType]="false"
          [withCampaignState]="false"
          class="inline-block"
        ></app-strategy-link
      ></b>
      {{ t("notification-card-campaign-overlap.in_non_operated_campaign", [overlappingCampaigns.length]) }}
      @for (overlappingCampaign of overlappingCampaigns; track overlappingCampaign; let isLast = $last) {
        <span>
          <b>{{ overlappingCampaign }}</b
          >{{ isLast ? "" : ", " }}</span
        >
      }
      {{ t("notification-card-campaign-overlap.make_sure_not_advertised") }}
    </span>
    <span titleSmall>
      {{ t("common.strategy") }}
      <b
        ><app-strategy-link
          [strategyId]="strategyId"
          [withCampaignType]="false"
          [withCampaignState]="false"
          class="inline-block"
        ></app-strategy-link
      ></b>
      {{ t("notification-card-campaign-overlap.overlaping_campaigns") }}
    </span>
    <span actionSmall>
      {{ t("notification-card-campaign-overlap.asins_also_in_campaigns") }}
      @for (overlappingCampaign of overlappingCampaigns; track overlappingCampaign; let isLast = $last) {
        <span>
          <b>{{ overlappingCampaign }}</b
          >{{ isLast ? "" : ", " }}</span
        >
      }
      {{ t("notification-card-campaign-overlap.make_sure_not_advertised") }}
    </span>
  </app-notification-card>
</ng-container>
