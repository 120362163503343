import { Component } from "@angular/core";
import { StatsApiClientService } from "@front/m19-services";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { AccountApi, AccountMarketplace, Response } from "@front/m19-api-client";

@Component({
  templateUrl: "./vendor-metrics-popup.component.html",
})
export class ModalUpdateVendorMetricsComponent {
  accountMarketplace!: AccountMarketplace;

  constructor(
    public bsModalRef: BsModalRef,
    private accountApi: AccountApi,
    private toasterService: ToastrService,
    private statsApi: StatsApiClientService,
  ) {}

  updateVendorMetrics(useSourcing: boolean) {
    this.accountMarketplace.useSourcingMetrics = useSourcing;
    this.accountApi
      .updateAccountMarketplace({
        accountId: this.accountMarketplace.accountId,
        marketplace: this.accountMarketplace.marketplace,
        useSourcingMetrics: useSourcing,
      })
      .subscribe(
        (response: Response) => {
          response.code == 200
            ? this.toasterService.success(
                (useSourcing ? "Sourcing" : "Manufacturing") +
                  " successfully selected for " +
                  this.accountMarketplace.accountName,
              )
            : this.toasterService.error(response.message);

          this.statsApi.reloadCacheForAccountMarketplace(
            this.accountMarketplace.accountId,
            this.accountMarketplace.marketplace,
            !!this.accountMarketplace.useSourcingMetrics,
          );
        },
        (error) => this.toasterService.error(error),
      );
  }
}
