import { Component, Input } from "@angular/core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-dropdown-toggle-icon",
  templateUrl: "./dropdown-toggle-icon.component.html",
  styleUrls: ["./dropdown-toggle-icon.component.scss"],
})
export class DropdownToggleIconComponent {
  @Input({ required: true })
  expand!: boolean;

  @Input()
  iconClass?: string;

  readonly faChevron = faChevronRight;

  public iconClassArray() {
    return this.iconClass ? [this.iconClass] : [];
  }
}
