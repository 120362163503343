<ng-container *transloco="let t">
  @if (!loading() && tacosStrategy() && !createSbStrategy() && !createSdStrategy()) {
    <div class="container mx-auto min-h-full rounded-lg bg-white px-10 py-6">
      <div class="flex flex-col gap-2">
        <a
          [routerLink]="['/advertising/tacos-strategies']"
          queryParamsHandling="merge"
          class="inline-flex items-center gap-2 text-sm text-gray-500 underline-offset-4 hover:text-gray-700"
        >
          <span [class]="ICON_CHEVRON_D + ' h-4 w-4 rotate-90'"></span>
          TACOS</a
        >
        <div class="flex items-end gap-4">
          @if (spStrategy(); as sp) {
            <span (click)="openEditNameModal()" class="text-2xl font-medium text-gray-800">{{ sp.name }}</span>
            <IButton
              color="white"
              icon="icon-[mdi--pencil]"
              size="xs"
              tooltip="Edit Name"
              (onClick)="openEditNameModal()"
            />
          } @else {
            <div class="mt-1 h-8 w-56 animate-pulse rounded-md bg-gray-200"></div>
          }
          @if (tacosStrategy()) {
            <div class="flex items-center gap-2">
              <state-badge [state]="tacosStrategy()!.state" />
              <IButton
                color="white"
                [icon]="tacosStrategy()!.state === TacosStrategyGroupState.ENABLED ? ICON_PAUSE : ICON_PLAY"
                size="xs"
                [matTooltip]="tacosStrategy()!.state === TacosStrategyGroupState.ENABLED ? 'Pause' : 'Enable'"
                (onClick)="
                  updateStrategyGroupState(
                    tacosStrategy()!.state === TacosStrategyGroupState.ENABLED
                      ? TacosStrategyGroupState.PAUSED
                      : TacosStrategyGroupState.ENABLED
                  )
                "
              />
            </div>
          }
        </div>
      </div>

      <div class="mb-5 mt-5 flex w-full gap-2 border-b border-gray-200 pb-5">
        <strategy-info-bloc (onClick)="openTargetModal()">
          <div title class="text-sm uppercase text-gray-500">{{ t("strategy-table.target") }}</div>
          @if (tacosStrategy()) {
            <div body class="ml-0.5 font-medium">{{ tacosStrategy()!.tacosTarget! * 100 | number: "1.0-1" }} %</div>
          } @else {
            <div body class="mt-1 h-6 w-14 animate-pulse rounded-md bg-gray-200"></div>
          }
        </strategy-info-bloc>
      </div>

      <div class="flex flex-col gap-5">
        @for (section of sectionOrder; track section) {
          <button
            class="w-full p-0 text-left font-medium text-gray-800"
            [id]="section"
            type="button"
            (click)="toggleSectionState(section, !sectionState[section])"
            [attr.aria-expanded]="section"
            [attr.aria-controls]="section"
          >
            <div class="flex items-center gap-2">
              <span
                [class]="ICON_CHEVRON_D + ' h-5 w-5 text-gray-500 duration-150'"
                [ngClass]="sectionState[section] ? 'rotate-0' : '-rotate-90'"
              ></span>
              @if (section === SECTIONS.PRODUCTS) {
                {{ t("traffic-stats.advertised_product") }}
              } @else if (section === SECTIONS.CAMPAIGNS) {
                {{ t("common.startegies") }}
              } @else if (section === SECTIONS.STATS) {
                Stats
              } @else if (section === SECTIONS.ACTIVITIES) {
                {{ t("v2-sidebar.activities") }}
              }
            </div>
          </button>
          <div
            [id]="section"
            role="region"
            class="grid overflow-hidden text-sm text-slate-600 transition-all duration-150 ease-in-out"
            [ngClass]="sectionState[section] ? 'mt-5 grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'"
          >
            <div class="overflow-hidden">
              @if (section === SECTIONS.PRODUCTS) {
                @if (ineligibleAsins().length > 0) {
                  <div class="alert alert-warning" role="alert">
                    <div>
                      {{ t("sd-page.ineligible_asins", { count: ineligibleAsins().length }) }}
                    </div>
                    <div>
                      {{ ineligibleAsins().length > 1 ? t("common.asins") : t("common.asin") }}:
                      {{ ineligibleAsins().slice(0, 20).join(", ") }}{{ ineligibleAsins().length > 20 ? ", …" : "" }}
                    </div>
                  </div>
                }

                @if (am()) {
                  <div class="mb-2 flex justify-end">
                    <IButton
                      color="white"
                      icon="icon-[mdi--plus]"
                      size="sm"
                      [label]="t('sp-substrategy.add_products')"
                      [disabled]="!!isReadOnly()"
                      (onClick)="openEditAsinsModal()"
                    />
                  </div>
                  <div class="max-h-[30rem] overflow-y-auto">
                    <app-strategy-asins
                      [asins]="spStrategy()?.asins ?? []"
                      [isReadOnly]="false"
                      [csvExport]="true"
                      [accountId]="am()!.accountId"
                      [marketplace]="am()!.marketplace"
                      [selectable]="!isReadOnly()"
                      [deletable]="!isReadOnly()"
                      [movable]="false"
                      (onDelete)="updateAsins($event, 'remove')"
                    />
                  </div>
                }
              } @else if (section === SECTIONS.CAMPAIGNS) {
                <div class="w-full">
                  <IAlert class="w-full" [title]="t('tacos-strategy.strategies_not_supported')" type="info" />
                  <!-- @if (!sbStrategy()) {
                    <IButton
                      color="white"
                      [label]="t('tacos-strategy.add_sb_strategy')"
                      [icon]="ICON_PLUS"
                      size="sm"
                      (onClick)="addSbStrategy()"
                      class="mt-2"
                    />
                  }
                  @if (!sdStrategy()) {
                    <IButton
                      color="white"
                      [label]="t('tacos-strategy.add_sd_strategy')"
                      [icon]="ICON_PLUS"
                      size="sm"
                      (onClick)="addSdStrategy()"
                      class="mt-2"
                    />
                  } -->
                </div>
                <table class="mt-2 w-full">
                  <thead>
                    <tr>
                      <th class="border-b border-gray-200 py-3 text-left text-xs uppercase text-gray-500">TYPE</th>
                      <th class="border-b border-gray-200 py-3 text-left text-xs uppercase text-gray-500">PRODUCT</th>
                      <th class="border-b border-gray-200 py-3 text-left text-xs uppercase text-gray-500"></th>
                    </tr>
                  </thead>
                  <tbody>
                    @if (spStrategy(); as sp) {
                      <tr>
                        <td class="border-b border-gray-200 py-3">
                          <span class="text-sm font-medium text-gray-600">Sponsored Products</span>
                        </td>

                        <td class="border-b border-gray-200 py-3 text-left font-medium text-gray-800">
                          {{ sp.asins?.length }}
                        </td>
                        <td class="border-b border-gray-200 py-3"></td>
                      </tr>
                    } @else {
                      <tr>
                        <td colspan="5" class="py-3 text-left">
                          <div class="h-6 w-full animate-pulse rounded-md bg-gray-200"></div>
                        </td>
                      </tr>
                    }

                    @if (sbStrategy(); as sb) {
                      <tr>
                        <td class="border-b border-gray-200 py-3">
                          <span class="text-sm font-medium text-gray-600">Sponsored Brands</span>
                        </td>

                        <td class="border-b border-gray-200 py-3 text-left font-medium text-gray-800">
                          {{ sbAsins()?.length ?? "-" }}
                        </td>
                        <td class="border-b border-gray-200 py-3">
                          <IButton
                            color="white"
                            [icon]="isSbPaused() ? ICON_PLAY : ICON_PAUSE"
                            [tooltipValue]="isSbPaused() ? 'Enable' : 'Pause'"
                            size="xs"
                            (onClick)="updateStrategyState(CampaignType.SB)"
                          />
                        </td>
                      </tr>
                    }
                    @if (sdStrategy(); as sd) {
                      <tr>
                        <td class="border-b border-gray-200 py-3">
                          <span class="text-sm font-medium text-gray-600">Sponsored Display</span>
                        </td>

                        <td class="border-b border-gray-200 py-3 text-left font-medium text-gray-800">
                          {{ sd.asins?.length }}
                        </td>
                        <td class="border-b border-gray-200 py-3">
                          <IButton
                            color="white"
                            [icon]="isSdPaused() ? ICON_PLAY : ICON_PAUSE"
                            [tooltipValue]="isSdPaused() ? 'Enable' : 'Pause'"
                            size="xs"
                            (onClick)="updateStrategyState(CampaignType.SD)"
                          />
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              } @else if (section === SECTIONS.STATS) {
                <app-metric-selector
                  [data]="totalStats()"
                  [previousPeriodData]="previousTotalStats()"
                  [localStorageKey]="localStorageKey"
                  [pageMetrics]="METRICS"
                  (chartMetricsChanges)="selectMetric($event)"
                />
                <div class="py-4">
                  @if (builtDataSet(); as ds) {
                    <canvas
                      baseChart
                      class="chart"
                      [datasets]="ds.chartDataSet"
                      type="line"
                      [labels]="ds.labels"
                      [options]="ds.lineChartOptions"
                    >
                    </canvas>
                  }
                  <div class="ag-theme-quartz h-[30rem] w-full">
                    <ag-grid-angular [gridOptions]="gridOptions()" [rowData]="rowStats()" class="h-full w-full" />
                  </div>
                </div>
              } @else if (section === SECTIONS.ACTIVITIES) {
                <div class="ag-theme-quartz h-[30rem]">
                  <app-activity-component
                    [activityFilter]="activityFilter()"
                    [hideEntityColumn]="true"
                    gridConfigStorageKey="strategyActivities"
                  />
                </div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  } @else if (loading()) {
    <div class="flex h-full items-center justify-center">
      <app-spinner [display]="true" [main]="false" size="m" type="default" />
    </div>
  }

  @if (createSbStrategy()) {
    <app-sb-strategy-form
      (onCancel)="createSbStrategy.set(false)"
      (onStrategyCreated)="createSbStrategy.set(false)"
      [tacosStrategy]="tacosStrategy()"
    />
  } @else if (createSdStrategy()) {
    <app-sd-strategy-creation
      (onCancel)="createSdStrategy.set(false)"
      [tacosStrategy]="tacosStrategy()"
      [tacosStrategyName]="spStrategy()?.name"
      (sdTacosStrategyCreated)="createSdStrategy.set(false)"
    />
  }

  <ng-template #targetModal>
    <div class="w-[20vw]">
      <div class="py-5">
        <IInput
          [label]="'Target'"
          [placeholder]="'Target'"
          [type]="'number'"
          [min]="0"
          [max]="100"
          [value]="tacosStrategy()?.tacosTarget! * 100 | number: '1.0-1'"
          [autoFocus]="true"
          [trailing]="true"
          icon="icon-[mdi--percent]"
          (valueChange)="tacosTargetInput.set(+$event)"
        />
      </div>
      <div class="mt-4 flex justify-end gap-2">
        <IButton color="white" [label]="'Cancel'" (onClick)="hideModal()" />
        <IButton [label]="'Save'" (onClick)="updateTacosTarget()" [loading]="modalLoading()" />
      </div>
    </div>
  </ng-template>

  <ng-template #editNameModal>
    <div class="w-[20vw]">
      <div class="py-5">
        <IInput
          [label]="'Name'"
          [placeholder]="'Name'"
          [value]="spStrategy()?.name!"
          [autoFocus]="true"
          [trailing]="true"
          (valueChange)="strategyNameInput.set($event)"
        />
      </div>
      <div class="mt-4 flex justify-end gap-2">
        <IButton color="white" [label]="'Cancel'" (onClick)="hideModal()" />
        <IButton [label]="'Save'" (onClick)="updateStrategyName()" [loading]="modalLoading()" />
      </div>
    </div>
  </ng-template>

  <ng-template #editAsinsModal>
    <div class="flex h-[70vh] w-[80vw] flex-col">
      @if (am()) {
        <app-asins-selection
          class="flex-1"
          [accountId]="am()!.accountId"
          [marketplace]="am()!.marketplace"
          [asins]="spStrategy()?.asins ?? []"
          [disabledAsins]="usedAsins()!"
          (onAdd)="updateAsins($event)"
          (onDelete)="updateAsins($event, 'remove')"
        />
      }
    </div>
  </ng-template>
</ng-container>
