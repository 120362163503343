<ng-container *transloco="let t">
  <div class="modal-header bg-primary">
    <h4 class="modal-title pull-left">
      {{ t("manage-authorized-access-modal.authorized_user", { name: organizationName, new: isNewUser }) }}
    </h4>
    <a>
      <h4 class="modal-title pull-right" (click)="modalService.hide()">&times;</h4>
    </a>
  </div>
  <div class="modal-body m-4">
    @if (userName) {
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">{{ t("common.name") }}</label>
        <div class="col-sm-10">
          <input type="text" class="border-shadow form-control" [value]="userName" disabled />
        </div>
      </div>
    }
    <form id="email-form" name="email-form" [formGroup]="emailForm">
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">{{ t("manage-authorized-access-modal.email") }}</label>
        <div class="col-sm-10">
          <input
            type="email"
            id="email"
            name="email"
            class="border-shadow form-control"
            placeholder="{{ t('manage-authorized-users-modal.please_enter_the_email_of_the_user') }}"
            formControlName="email"
            [ngClass]="{
              'is-invalid': email.invalid && (!email.untouched || email.dirty),
            }"
          />
          @if (email.invalid && (email.dirty || !email.untouched)) {
            @if (email.errors?.["required"]) {
              <div class="invalid-feedback">{{ t("manage-authorized-access-modal.email_is_required") }}</div>
            }
            @if (email.errors?.["email"] || email.errors?.["pattern"]) {
              <div class="invalid-feedback">{{ t("register.email_invalid_error") }}</div>
            }
            @if (email.errors?.["userDoesNotExist"]) {
              <div class="invalid-feedback">
                {{ t("manage-authorized-access-modal.register_at") }}
                <a [href]="getRegisterUrl()" (click)="copyToClipboard(); $event.preventDefault()">{{
                  getRegisterUrl()
                }}</a>
              </div>
            }
          } @else {
            <div class="invalid-feedback" style="visibility: hidden">Hidden message for constant space</div>
          }
        </div>
      </div>
    </form>

    <div class="flex justify-between">
      <span class="search bar pl-0" style="width: 65%; display: inline-block; max-width: 36rem">
        <span class="search-bar input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <fa-icon [icon]="faSearch"></fa-icon>
            </span>
          </div>
          <input
            type="text"
            class="search-bar simple-input-prepend form-control"
            placeholder="{{ t('manage-authorized-access-modal.filter_by_account_name') }}"
            (input)="changeAccountFilter(getValueFromInputEvent($event))"
          />
        </span>
      </span>
    </div>
    <div class="table-container">
      <mat-table
        matSort
        [dataSource]="dataSource"
        class="table-sm table-striped-two table-hover border-shadow mt-4 table"
        #sort="matSort"
      >
        <ng-container matColumnDef="accountName">
          <mat-header-cell *matHeaderCellDef class="table-header text-left" mat-sort-header
            >{{ t("common.account") }}
          </mat-header-cell>
          <mat-cell *matCellDef="let account" class="flex justify-between">
            <span class="truncate-text" [matTooltip]="account.accountName">{{ account.accountName }}</span>
            <IBadge color="main" label="{{ account.marketplace }}" class="ml-1"></IBadge>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="noAccess">
          <mat-header-cell *matHeaderCellDef class="table-header justify-center text-center" mat-sort-header>
            <span class="text-primary pointer m-2">
              <fa-icon
                size="lg"
                matTooltip="{{ t('manage-authorized-access-modal.revoke_access_to_all') }}"
                [icon]="getSetAllIcon(AccessLevel.NO_ACCESS)"
                (click)="setAllAccessLevel(AccessLevel.NO_ACCESS, $event)"
              ></fa-icon>
            </span>
            {{ t("manage-authorized-access-modal.no_access") }}
          </mat-header-cell>
          <mat-cell *matCellDef="let account" class="justify-center">
            <mat-radio-button
              class="radio-button-center my-2"
              color="primary"
              [name]="account.profileId.toString()"
              [checked]="getAccessLevel(account.profileId) === AccessLevel.NO_ACCESS"
              (click)="setAccessLevel(account.profileId, AccessLevel.NO_ACCESS)"
            ></mat-radio-button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="readWriteAccess">
          <mat-header-cell *matHeaderCellDef class="table-header justify-center" mat-sort-header>
            <span class="text-primary pointer m-2">
              <fa-icon
                size="lg"
                matTooltip="{{ t('manage-authorized-access-modal.add_r_w_access_to_all') }}"
                [icon]="getSetAllIcon(AccessLevel.READ_WRITE_ACCESS)"
                (click)="setAllAccessLevel(AccessLevel.READ_WRITE_ACCESS, $event)"
              ></fa-icon>
            </span>
            {{ t("manage-authorized-access-modal.read_write_access") }}
          </mat-header-cell>
          <mat-cell *matCellDef="let account" class="justify-center">
            <mat-radio-button
              class="radio-button-center my-2"
              color="primary"
              [name]="account.profileId.toString()"
              [checked]="getAccessLevel(account.profileId) === AccessLevel.READ_WRITE_ACCESS"
              (click)="setAccessLevel(account.profileId, AccessLevel.READ_WRITE_ACCESS)"
            ></mat-radio-button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="readOnlyAccess">
          <mat-header-cell *matHeaderCellDef class="table-header justify-center" mat-sort-header>
            <span class="text-primary pointer m-2">
              <fa-icon
                size="lg"
                matTooltip="{{ t('manage-authorized-access-modal.add_read_only_access_to_all') }}"
                [icon]="getSetAllIcon(AccessLevel.READ_ONLY)"
                (click)="setAllAccessLevel(AccessLevel.READ_ONLY, $event)"
              ></fa-icon>
            </span>
            {{ t("manage-authorized-access-modal.read_only_access") }}
          </mat-header-cell>
          <mat-cell *matCellDef="let account" class="justify-center">
            <mat-radio-button
              class="radio-button-center my-2"
              color="primary"
              [name]="account.profileId.toString()"
              [checked]="getAccessLevel(account.profileId) === AccessLevel.READ_ONLY"
              (click)="setAccessLevel(account.profileId, AccessLevel.READ_ONLY)"
            ></mat-radio-button>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="statsOnlyAccess">
          <mat-header-cell *matHeaderCellDef class="table-header justify-center" mat-sort-header>
            <span class="text-primary pointer m-2">
              <fa-icon
                size="lg"
                matTooltip="{{ t('manage-authorized-access-modal.add_360_to_all') }}"
                [icon]="getSetAllIcon(AccessLevel.STATS_ONLY)"
                (click)="setAllAccessLevel(AccessLevel.STATS_ONLY, $event)"
              ></fa-icon>
            </span>
            {{ t("manage-authorized-users-modal.360_dashboards_access") }}
          </mat-header-cell>
          <mat-cell *matCellDef="let account" class="justify-center">
            <mat-radio-button
              class="radio-button-center my-2"
              color="primary"
              [name]="account.profileId.toString()"
              [checked]="getAccessLevel(account.profileId) === AccessLevel.STATS_ONLY"
              (click)="setAccessLevel(account.profileId, AccessLevel.STATS_ONLY)"
            ></mat-radio-button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons #paginator></mat-paginator>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modalService.hide()">{{ t("common.cancel") }}</button>
    <button class="btn btn-primary" (click)="save()" [disabled]="!isValid()">{{ t("common.save") }}</button>
  </div>
</ng-container>
