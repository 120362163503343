import { Component } from "@angular/core";
import { ICellRendererParams } from "@ag-grid-community/core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICON_BOOST } from "@m19-board/utils/iconsLabels";
import { StrategyLine } from "./strategy-grid.component";
import { CampaignType } from "@front/m19-api-client";
import { faImage, faVideo } from "@fortawesome/free-solid-svg-icons";

@Component({
  templateUrl: "./adLines-renderer.component.html",
  styleUrls: ["./strategy-grid.component.scss"],
})
export class AdLinesRendererComponent implements ICellRendererAngularComp {
  strat!: StrategyLine;
  ICON_BOOST = ICON_BOOST;
  faImage = faImage;
  faVideo = faVideo;
  CampaignType = CampaignType;

  agInit(params: any): void {
    this.strat = params.data as StrategyLine;
  }

  refresh(params: ICellRendererParams) {
    return true;
  }
}
