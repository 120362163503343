import { Component, inject, Input, OnInit } from "@angular/core";
import { AccountMarketplace, Currency, SbCreative, SbCreativeType, Strategy } from "@front/m19-api-client";
import { getBasicGridOptions, getFilteredLeafNodes } from "@front/m19-grid-config";
import {
  ACOS,
  AD_CONVERSIONS,
  AD_SALES,
  CLICK_THROUGH_RATE,
  CLICKS,
  CONVERSION_RATE,
  COST,
  CPC,
  IMPRESSIONS,
  Metric,
  ROAS,
} from "@front/m19-metrics";
import { AdStatsEx, CreativeTypeStr, SbCreativeBrandAssets, StrategyEx } from "@front/m19-models";
import {
  AccountSelectionService,
  AdStatsData,
  AdStatsWithTargetHistory,
  DataSet,
  groupBy,
  indexStrategyByDate,
  SbStrategiesService,
  StatsApiClientService,
  StrategyTargetHistory,
  UserSelectionService,
} from "@front/m19-services";
import { addAdStats, AggregationFunction, Utils } from "@front/m19-utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { AgGridAngular } from "@ag-grid-community/angular";
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
  ValueGetterParams,
} from "@ag-grid-community/core";
import { actionColumnProperties, CurrencyColumn } from "@m19-board/grid-config/grid-columns";
import { ACTIONS_COL_ID, exportGridCsv, getCsvFileName, getMetricsColDef } from "@m19-board/grid-config/grid-config";
import { toStrategyStats } from "@m19-board/insights/overview/overview-grid.component";
import { StrategyStats } from "@m19-board/models/Metric";
import { IBadgeComponent, IButtonComponent } from "@front/m19-ui";
import { TranslocoService } from "@jsverse/transloco";
import { ChartData, ChartRendererComponent } from "@m19-board/shared/chart-renderer/chart-renderer.component";
import { ICON_CHART_LINE } from "@m19-board/utils/iconsLabels";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { BehaviorSubject, combineLatest, map, switchMap, tap } from "rxjs";
import { DAILY_BUDGET, MIN_DAILY_SPEND, MONTHLY_BUDGET, TARGET_ACOS } from "@m19-board/models/MetricsDef";

@UntilDestroy()
@Component({
  selector: "app-sb-creative-stats-grid",
  standalone: true,
  template: ` <div class="ag-theme-quartz h-full">
    <ag-grid-angular
      class="h-full"
      [gridOptions]="gridOptions"
      [rowData]="gridData"
      rowGroupPanelShow="always"
      (gridReady)="onGridReady($event)"
    />
  </div>`,
  imports: [AgGridAngular],
})
export class SbCreativeStatsGridComponent implements OnInit {
  private readonly statsApiService = inject(StatsApiClientService);
  private readonly userSelectionService = inject(UserSelectionService);
  private readonly sbStrategiesService = inject(SbStrategiesService);
  private readonly accountSelectionService = inject(AccountSelectionService);

  private readonly modalService = inject(BsModalService);

  private readonly METRIC_COLS: Metric<AdStatsEx>[] = [
    AD_SALES,
    AD_CONVERSIONS,
    COST,
    ACOS,
    CLICKS,
    IMPRESSIONS,
    CLICK_THROUGH_RATE,
    CONVERSION_RATE,
    CPC,
    ROAS,
  ];

  @Input() strategy?: StrategyEx;
  @Input() currency?: Currency;
  @Input() locale?: string;

  minDate?: string;
  maxDate?: string;
  creativeDataset?: DataSet<AdStatsWithTargetHistory>;
  byCreativeData: Map<number, AdStatsEx[]> = new Map();
  byCreativePreviousData: Map<number, AdStatsEx[]> = new Map();
  byCreativeTotalData: Map<number, AdStatsEx> = new Map();
  byCreativePreviousTotalData: Map<number, AdStatsEx> = new Map();
  strategyConfigHistory?: Map<string, Strategy>;
  acosTargetHistory: StrategyTargetHistory[] = [];
  gridData?: AdStatsEx[]; // aggregated by creative, to be displayed in the grid
  accountMarketplace?: AccountMarketplace;

  modalFocusedNode?: IRowNode;
  modalData = new BehaviorSubject<ChartData<AdStatsEx> | undefined>(undefined);
  creativeIndex?: Map<number, SbCreative>;
  comparePeriods: string[] | undefined;
  creativeBrandAssets?: Map<number, SbCreativeBrandAssets>;
  creatives: SbCreative[] = [];

  private colDefs: ColDef[] = [
    {
      headerName: "No.",
      field: "creativeId",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      valueGetter: (params) => (params.node?.isRowPinned() ? undefined : `#${params.data.creativeId}`),
    },
    {
      headerName: "Creative",
      field: "creativeId",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      valueGetter: (params) => {
        return this.getCreativeName(params.node!);
      },
    },
    {
      headerName: "Ad Format",
      enableRowGroup: true,
      valueGetter: (params: ValueGetterParams) => {
        const key = params.node?.group ? params.node.key : params.data.creativeId;
        const creative = this.creatives.find((c) => c.creativeId === key);
        return creative ? CreativeTypeStr[creative.creativeType] : undefined;
      },
      cellRendererSelector: (params) => {
        if (params.value)
          return {
            component: IBadgeComponent,
            params: {
              label: params.value,
              color: "gray",
            },
          };
        return undefined;
      },
      filter: true,
      floatingFilter: true,
    },
    ...getMetricsColDef(this.METRIC_COLS).map((col: ColDef) => ({
      ...col,
      cellRendererParams: (params: ICellRendererParams) => {
        return {
          ...col.cellRendererParams(params),
          previousData: this.getNodeTotalStats(params.node, !!this.comparePeriods),
          currency: this.currency,
          locale: this.locale,
        };
      },
    })),
    {
      ...actionColumnProperties<AdStatsEx, string>(),
      cellRenderer: IButtonComponent,
      cellRendererParams: (params: ICellRendererParams) => ({
        color: "white",
        size: "xs",
        icon: ICON_CHART_LINE,
        tooltipValue: "Display " + this.getNodeTitle(params.node.isRowPinned()) + " Stats",
        clickAction: () => {
          this.openCreativeStatsModal(params.node);
        },
      }),
    },
    CurrencyColumn,
  ];

  private gridApi!: GridApi<AdStatsEx>;
  creativeStatsGridKey = "sb-creative-stats-grid";
  gridOptions: GridOptions = {
    ...getBasicGridOptions(this.creativeStatsGridKey, true),
    columnDefs: this.colDefs,
  };

  constructor(private translocoService: TranslocoService) {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) => this.sbStrategiesService.getSbCreativeBrandAssets(am.accountId, am.marketplace)),
      )
      .subscribe((creativeBrandAssets) => {
        this.creativeBrandAssets = creativeBrandAssets;
      });
  }

  ngOnInit(): void {
    this.creativeDataset = new DataSet<AdStatsWithTargetHistory>(
      3,
      [AD_SALES, AD_CONVERSIONS],
      [AggregationFunction.mergeAdStatsWithTargetHistory],
      this.translocoService,
    );
    this.creativeDataset.currency = this.currency!;
    this.creativeDataset.locale = this.locale!;
    this.creativeDataset.metricsOnSameScale = [
      [AD_SALES, COST],
      [ACOS, TARGET_ACOS],
      [COST, MIN_DAILY_SPEND, DAILY_BUDGET, MONTHLY_BUDGET],
    ];

    this.userSelectionService.dateRange$.pipe(untilDestroyed(this)).subscribe((dr: string[]) => {
      this.minDate = dr[0];
      this.maxDate = dr[1];
      this.gridApi?.showLoadingOverlay();
    });

    this.statsApiService.strategyConfigHistory$.pipe(untilDestroyed(this)).subscribe((configHistory: Strategy[]) => {
      this.strategyConfigHistory = new Map();
      this.strategyConfigHistory = indexStrategyByDate(
        configHistory.filter((x) => x.strategyId == this.strategy!.strategyId),
      );
    });
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) => this.sbStrategiesService.getSbCreativesIndex(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((creativeIndex) => {
        this.creativeIndex = creativeIndex;
      });

    this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.accountMarketplace = am;
    });

    this.userSelectionService.periodComparison$.pipe(untilDestroyed(this)).subscribe((pc) => {
      this.comparePeriods = pc?.period;
    });
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) => this.sbStrategiesService.getSbCreativesPerStrategy(am.accountId, am.marketplace)),
      )
      .subscribe((creatives) => {
        this.creatives = creatives.get(this.strategy!.strategyId) ?? [];
      });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;

    combineLatest<[AdStatsData, AdStatsData]>([
      this.statsApiService.dailySbCreativeStats$,
      this.statsApiService.previousPeriodDailySbCreativeStats$,
    ])
      .pipe(
        tap(() => this.gridApi.showLoadingOverlay()),
        untilDestroyed(this),
        map(([currentStats, previousStats]: [AdStatsData, AdStatsData]) => {
          return {
            currentStats: currentStats.data.filter((s: AdStatsEx) => s.strategyId === this.strategy!.strategyId),
            previousStats: previousStats.data.filter((s: AdStatsEx) => s.strategyId === this.strategy!.strategyId),
          };
        }),
      )
      .subscribe(({ currentStats, previousStats }) => {
        this.byCreativeData.clear();
        this.byCreativePreviousData.clear();

        for (const s of currentStats) {
          Utils.insertInArrayMap(this.byCreativeData, s.creativeId, s);
          Utils.insertInMap(this.byCreativeTotalData, s.creativeId, s, addAdStats);
        }

        if (previousStats && previousStats.length > 0) {
          for (const s of previousStats) {
            Utils.insertInArrayMap(this.byCreativePreviousData, s.creativeId, s);
            Utils.insertInMap(this.byCreativePreviousTotalData, s.creativeId, s, addAdStats);
          }
        }
        this.gridData = Array.from(groupBy(currentStats, (s: AdStatsEx) => s.creativeId).values()).map((s, i) => ({
          ...s,
        }));

        this.updateModalData(this.modalFocusedNode!, this.acosTargetHistory ?? []);
      });
  }

  // return the stats array for a given node (creative id)
  // used in graphs
  private getNodeArrayStats(node: IRowNode, previous = false): AdStatsEx[] {
    if (!node) return [];

    const res: AdStatsEx[] = [];
    const source = previous ? this.byCreativePreviousData : this.byCreativeData;

    getFilteredLeafNodes(node, this.gridApi).forEach((n) => {
      const data = source.get(n.data.creativeId);
      if (data) res.push(...data);
    });

    return res;
  }

  private getNodeTotalStats(node: IRowNode, previous = false): AdStatsEx {
    const stats: AdStatsEx[] = this.getNodeArrayStats(node, previous);
    return this.aggregateStats(stats);
  }

  private aggregateStats(stats: AdStatsEx[]): AdStatsEx {
    if (!stats) return {};
    return stats.reduce((acc, s) => addAdStats(acc, s), {} as AdStatsEx);
  }

  private updateModalData(node: IRowNode, targetHistory: StrategyTargetHistory[]) {
    if (!node) return;
    this.modalFocusedNode = node;

    const data = this.getNodeArrayStats(node);

    this.modalData.next({
      // convert to strategy stats to add acos target for footer only
      data: node.isRowPinned()
        ? [...targetHistory, ...data.map((s) => toStrategyStats(s, this.strategy, undefined))]
        : data,
      previousData: this.getNodeArrayStats(node, true),
      totalData: this.getNodeTotalStats(node),
      totalPreviousData: this.getNodeTotalStats(node, true),
    });
  }

  private openCreativeStatsModal(node: IRowNode) {
    this.statsApiService
      .getStrategyTargetHistory(this.strategy!.strategyId, this.currency!)
      .pipe(
        map((targetHistory) => {
          this.acosTargetHistory = targetHistory;

          const additionalMetrics = new Set<Metric<StrategyStats>>();

          // check if we should display daily budget, min daily spend, or monthly budget
          for (const target of targetHistory) {
            if (target.dailyBudget !== undefined && !isNaN(target.dailyBudget)) additionalMetrics.add(DAILY_BUDGET);
            if (target.minDailySpend !== undefined && target.minDailySpend !== 0)
              additionalMetrics.add(MIN_DAILY_SPEND);
            if (target.monthlyBudget !== undefined && !isNaN(target.monthlyBudget))
              additionalMetrics.add(MONTHLY_BUDGET);
          }

          this.updateModalData(node, targetHistory);

          return additionalMetrics;
        }),
        switchMap((additionalMetrics) => {
          const opts: ModalOptions = {
            initialState: {
              title: this.getCreativeName(node),
              metrics: this.METRIC_COLS,
              dataset: this.creativeDataset,
              minDate: this.minDate,
              maxDate: this.maxDate,
              chartData$: this.modalData,
              selectMetricCallback: (metrics: Metric<AdStatsEx>[]) => {
                // only add target acos for footer (total strategy stats)
                if (!node.isRowPinned()) return metrics;
                if ([ACOS, COST].every((i) => metrics.includes(i)))
                  return [...metrics, TARGET_ACOS, ...additionalMetrics];
                if (metrics.includes(ACOS)) {
                  return [...metrics, TARGET_ACOS];
                }
                if (metrics.includes(COST)) {
                  return [...metrics, ...additionalMetrics];
                }
                return metrics;
              },
            },
            class: "modal-xxl modal-primary modal-dialog-centered",
          };

          return this.modalService.show(ChartRendererComponent, opts).onHide!;
        }),
      )
      .subscribe(() => {
        this.modalFocusedNode = undefined;
      });
  }

  private getCreativeName(node: IRowNode) {
    if (node.group) return "";
    if (!this.creativeIndex?.get(node.data.creativeId) && !node.isRowPinned()) return "Deleted Creative";
    const creative = this.creatives.find((c) => c.creativeId === node.data.creativeId);
    const sbCreativeBrandAssets = this.creativeBrandAssets?.get(node.data.creativeId);
    return creative
      ? creative.creativeType !== SbCreativeType.video
        ? creative.headline
        : sbCreativeBrandAssets?.videoAsset?.name
      : this.getNodeTitle(node.isRowPinned());
  }

  private getNodeTitle(isFooter: boolean): string {
    return isFooter ? (this.gridApi.isAnyFilterPresent() ? "Total (filtered)" : "Total Strategy") : "Creative";
  }

  exportCsv() {
    const fileName = getCsvFileName(
      this.strategy?.name + "_creative_stats",
      this.accountMarketplace!.accountGroupName,
      this.accountMarketplace!.marketplace,
      this.userSelectionService.getDateRangeStr(),
    );
    const columnKeys: string[] = this.gridApi
      .getAllDisplayedColumns()
      .map((c) => c.getColId())
      .filter((c) => c !== ACTIONS_COL_ID)
      .concat(["currency"]);

    exportGridCsv(this.gridApi, { fileName, columnKeys, skipColumnGroupHeaders: true });
  }
}
