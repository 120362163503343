import { filter, fromEvent, map, Observable } from 'rxjs';
import { AccountMarketplaceService, AuthService } from '.';
import { Marketplace, User } from '@front/m19-api-client';
import { inject, Injectable } from '@angular/core';
import { Marketplaces } from '@front/m19-models/MarketplaceEx';
import { SELLING_PARTNER_DOMAIN } from '../injection-token/injection-token';

// from https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
const popupCenter = (url: string, title: string, w: number, h: number) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth;
  const height = window.innerHeight;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `,
  );

  if (window['focus']) newWindow!.focus();
};

@Injectable({
  providedIn: 'root',
})
export class SellingPartnerService {
  private readonly redirect_domain = inject(SELLING_PARTNER_DOMAIN);
  private user: User | undefined;

  constructor(
    private accountMarketplaceService: AccountMarketplaceService,
    private authService: AuthService,
  ) {
    this.authService.loggedUser$.subscribe((user) => {
      this.user = user;
    });
  }

  public requestSellingPartnerAccess(
    accountId: string,
    marketplace: Marketplace,
    isVendor: boolean,
    isKDP: boolean,
  ): Observable<string> {
    const requestId = crypto.randomUUID();
    popupCenter(
      this.getConsentUrl(requestId, accountId, marketplace, isVendor, isKDP),
      'Please grant Selling Partner API access to m19',
      760,
      800,
    );
    // log that the popup was opened in the backend for monitoring purposes
    this.logGrantAccessRequest(requestId, accountId, marketplace);

    // receive message from the popup in case of success with the accountId of the Selling Partner grant
    const eventSource = fromEvent<MessageEvent>(window, 'message');
    // for testing purposes uncomment this
    // const eventSource = of({
    //   origin: this.redirect_domain,
    //   data: { type: "sellingPartnerGrant", accountId: accountId, error: null },
    // });
    return eventSource.pipe(
      filter(
        (event) => event.origin == this.redirect_domain && event.data && event.data.type === 'sellingPartnerGrant',
      ),
      map((event) => {
        if (event.data.error) {
          throw new Error(event.data.error);
        }
        this.accountMarketplaceService.updateAccount(event.data.accountId, (x) => {
          x.isValidToken = true;
        });
        return event.data.accountId;
      }),
    );
  }

  private logGrantAccessRequest(requestId: string, accountId: string, marketplace: Marketplace) {
    if (navigator.sendBeacon === undefined) return;
    const toLog: SpGrantAccessMonitoring = {
      requestId,
      accountId,
      marketplace,
      m19UserId: this.user?.userId ?? 0,
    };
    navigator.sendBeacon(`${this.redirect_domain}/oauth/sp`, JSON.stringify(toLog));
  }

  private getSellerCentralUrl(marketplace: Marketplace) {
    return Marketplaces[marketplace]?.sellerCentralUrl;
  }

  private getVendorCentralUrl(marketplace: Marketplace) {
    return Marketplaces[marketplace]?.vendorCentralUrl;
  }

  private getConsentUrl(
    requestId: string,
    accountId: string,
    marketplace: Marketplace,
    isVendor: boolean,
    isKDP: boolean,
  ): string {
    const userId = this.user?.userId ?? 0;
    const params =
      '/apps/authorize/consent?application_id=amzn1.sellerapps.app.e1ca79e8-98f5-4c56-8e87-fa37843a8b1a&version=beta' +
      `&redirect_uri=${this.redirect_domain}/oauth/sp` +
      `&state=${accountId}_${marketplace}_${userId}_${requestId}`;

    if (isVendor && !isKDP) return this.getVendorCentralUrl(marketplace) + params;
    else return this.getSellerCentralUrl(marketplace) + params;
  }
}

type SpGrantAccessMonitoring = {
  requestId: string;
  accountId: string;
  marketplace: Marketplace;
  m19UserId: number;
};
