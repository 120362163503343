import { Component, Input } from "@angular/core";
import { RangeValues } from "../../grid-config/grid-config";

@Component({
  selector: "app-grid-range-values",
  template: `@if (rangeValues) {
    <div class="range-values flex justify-between px-4">
      <div>
        <span class="val">{{ rangeValues.rowCount }} total row{{ (rangeValues.rowCount ?? 0) > 1 ? "s" : "" }}</span>
      </div>
      <div class="values flex">
        <div>
          @if (rangeValues.metric) {
            <span class="val">({{ rangeValues.metric }})</span>
          }
        </div>
        <div>
          Sum : <span class="val">{{ rangeValues.sum }}</span>
        </div>
        <div>
          Mean : <span class="val">{{ rangeValues.mean }}</span>
        </div>
        <div>
          Min : <span class="val">{{ rangeValues.min }}</span>
        </div>
        <div>
          Max : <span class="val">{{ rangeValues.max }}</span>
        </div>
        <div>
          Count : <span class="val">{{ rangeValues.count }}</span>
        </div>
      </div>
    </div>
  }`,
  styleUrls: ["./grid-range-values.component.scss"],
  standalone: true,
  imports: [],
})
export class GridRangeValuesComponent {
  @Input() rangeValues?: RangeValues;
}
