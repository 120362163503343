<div class="table-ctn" *transloco="let t">
  <div class="box-title">{{ t("sov-main-table.brands") }}</div>
  <div class="brands-wrapper">
    @if (!loading) {
      <div class="search-brand-bar flex items-center justify-between">
        <input
          class="px-3 py-3"
          autocomplete="off"
          type="text"
          placeholder="{{ t('sov-main-table.search_brands') }}"
          [formControl]="filter"
        />
        <fa-icon [icon]="faSearch" class="mr-3"></fa-icon>
      </div>
      <div class="brand-list">
        @if (userBrands && userBrands.length) {
          <div class="user-brands">
            @for (b of userBrands; track b) {
              <div
                class="brand-item"
                [class.selected]="isBrandSelected(b) > -1"
                (click)="toggleBrandSelection(b)"
                (mouseenter)="brandMouseEnter(b)"
                (mouseleave)="brandMouseLeave()"
              >
                <div class="index">{{ (allBrands?.indexOf(b) ?? 0) + 1 }}</div>
                <div class="brand-item-name truncate">{{ b }}</div>
                <div class="ml-auto inline-flex items-center">
                  <div
                    class="color-badge ml-3"
                    [style.background-color]="isBrandSelected(b) > -1 ? brandColors?.get(b) : '#cccccc'"
                  ></div>
                  <img
                    src="assets/img/sov-icons/vertical-dots.svg"
                    alt=""
                    class="ml-3 p-1"
                    matTooltip="{{ t('sov-main-table.select_only_this_brand') }}"
                    (click)="toggleBrandSelection(b, true); $event.stopPropagation()"
                  />
                </div>
              </div>
            }
          </div>
        }
        @for (b of filteredBrands; track b) {
          <div
            class="brand-item"
            [class.selected]="isBrandSelected(b) > -1"
            (click)="toggleBrandSelection(b)"
            (mouseenter)="brandMouseEnter(b)"
            (mouseleave)="brandMouseLeave()"
          >
            <div class="index">{{ (allBrands?.indexOf(b) ?? 0) + 1 }}</div>
            <div class="brand-item-name truncate">{{ b }}</div>
            <div class="ml-auto inline-flex items-center">
              <div
                class="color-badge ml-3"
                [style.background-color]="isBrandSelected(b) > -1 ? brandColors?.get(b) : '#cccccc'"
              ></div>
              <img
                src="assets/img/sov-icons/vertical-dots.svg"
                alt=""
                class="ml-3 p-1"
                matTooltip="{{ t('sov-main-table.select_only_this_brand') }}"
                (click)="toggleBrandSelection(b, true); $event.stopPropagation()"
              />
            </div>
          </div>
        }
      </div>
    } @else {
      <div class="flex h-full items-center justify-center">
        <app-spinner [main]="false" size="s" [display]="true" type="default"></app-spinner>
      </div>
    }
  </div>
</div>
