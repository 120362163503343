<ng-container *transloco="let t">
  <div class="modal-header bg-primary">
    <h4 class="modal-title pull-left">{{ t("self-service-transfer.transfer_self_service_to_agency_management") }}</h4>
  </div>
  <div class="modal-body">
    {{
      t("self-service-transfer.account_transfer", [childOrganization.ownerUserName, parentOrganization?.customerName])
    }}
    <div class="card-perso-body bottom-child card-body">
      <table class="table-sm table-hover table-striped mt-2 table">
        <thead>
          <tr>
            <th>{{ t("common.account") }}</th>
            <th>{{ t("common.marketplace") }}</th>
            <th>{{ t("self-service-transfer.target_account_group") }}</th>
          </tr>
        </thead>
        <tbody>
          @for (acc of accountMarketplaces; track acc) {
            <tr>
              <td class="text-left">
                {{ acc.accountName }}
              </td>
              <td>
                <IBadge [label]="acc.marketplace" size="xs" />
              </td>
              <td>
                {{ targetAccountGroupName.get(acc.profileId!) }}
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
    <div>
      @if (canBeTransferred) {
        <ul>
          <li>{{ t("self-service-transfer.self_service_will_be_stopped", [childOrganization.ownerUserName]) }}</li>
          <li>{{ t("self-service-transfer.keep_readonly") }}</li>
          <li>{{ t("self-service-transfer.data_and_setup_tranfered") }}</li>
        </ul>
      } @else {
        <div class="alert alert-warning">
          {{ t("self-service-transfer.account_must_added_to_agency") }}
        </div>
      }
    </div>
  </div>
  <div class="modal-footer">
    <IButton label="{{ t('common.cancel') }}" color="gray" variant="ghost" (click)="bsModalRef.hide()" />
    <IButton
      label="{{ t('self-service-transfer.transfert') }}"
      [disabled]="!canBeTransferred"
      [loading]="loading"
      (click)="submit()"
    />
  </div>
</ng-container>
