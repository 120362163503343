import { Component, ElementRef, OnInit, Renderer2, signal, ViewChild, ViewEncapsulation } from "@angular/core";
import { PRIMARY_OUTLET, Router, RouterLink, RouterOutlet, UrlSegment } from "@angular/router";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import {
  AccountSelectionService,
  AuthService,
  BillingService,
  CurrencyService,
  NotificationService,
  OrganizationAccountGroupService,
  UserSelectionService,
} from "@front/m19-services";
import { IButtonComponent, IMultiSelectComponent, Option } from "@front/m19-ui";
import { TranslocoDirective } from "@jsverse/transloco";
import { BoardType, LayoutSelectorService } from "@m19-board/layout-selector.service";
import { Hotkeys } from "@m19-board/services/hotkeys.service";
import { ObfuscationService } from "@m19-board/services/obfuscation.service";
import { AccountSettingsService } from "@m19-board/settings/account-settings.service";
import { ICON_BELL, ICON_GEAR, ICON_QUESTION } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { combineLatest, Observable } from "rxjs";
import { delay, filter } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { DebugInfoModalComponent } from "./debug-info-modal.component";
import { AccessLevel, AccountMarketplace, AccountSubType, AccountType, Currency, User } from "@front/m19-api-client";
import { NotificationEx, OrganizationAccountGroups } from "@front/m19-models";
import { ToastrService } from "ngx-toastr";
import { AsyncPipe } from "@angular/common";
import { AccountSelectorComponent } from "@m19-board/account-selector/account-selector.component";
import { LinkYourAccountButtonComponent } from "@m19-board/settings/account-settings/account-group/link-your-account-button.component";
import { MatTooltip } from "@angular/material/tooltip";
import { DateRangeSelectionModule } from "@m19-board/date-range-selection/date-range-selection.module";
import { MatMenu, MatMenuTrigger } from "@angular/material/menu";
import { CurrencySelectionComponent } from "@m19-board/currency-selector/currency-selection.component";
import { NotificationsModule } from "@m19-board/notifications/notifications.module";
import { SidebarComponent } from "@m19-board/sidebar/sidebar.component";
import { SearchTrendsMarketplaceSelectorComponent } from "@m19-board/account-selector/search-trends-marketplace-selector.component";
import { RegistrationButtonModule } from "@m19-board/settings/billing-settings/registration-button/registration-button.module";

interface MenuItem {
  label: string;
  icon: string;
  routerLink?: string;
  href?: string;
  target?: string;
  queryParamsHandling?: "merge" | "preserve" | "";
  supportedBoards?: BoardType[];
  onClick?: () => void;
}

@UntilDestroy()
@Component({
  selector: "app-default-layout",
  templateUrl: "./default-layout.component.html",
  styleUrls: ["./default-layout.component.scss"],
  encapsulation: ViewEncapsulation.None,
  imports: [
    TranslocoDirective,
    AsyncPipe,
    RouterLink,
    AccountSelectorComponent,
    IMultiSelectComponent,
    LinkYourAccountButtonComponent,
    MatTooltip,
    DateRangeSelectionModule,
    IButtonComponent,
    MatMenuTrigger,
    MatMenu,
    RouterOutlet,
    CurrencySelectionComponent,
    NotificationsModule,
    SidebarComponent,
    SearchTrendsMarketplaceSelectorComponent,
    RegistrationButtonModule,
  ],
  // For toastr
  standalone: true,
})
export class DefaultLayoutComponent implements OnInit {
  sideBarOpened = false;
  isTest = !environment.production;

  readonly ICON_SETTINGS = ICON_GEAR;
  readonly ICON_QUESTION = ICON_QUESTION;
  readonly ICON_BELL = ICON_BELL;

  readonly faSquare = faSquare;
  readonly faCheckSquare = faCheckSquare;
  readonly BoardType = BoardType;
  readonly releaseNotesUrl = environment.releaseNotesUrl;
  navbarBrandFull = { src: "assets/img/logo_header.png", height: 40, alt: "m19 Logo" };
  navbarBrandMinimized = { src: "assets/img/logo.png", width: 40, height: 40, alt: "m19 logo" };
  boardType!: BoardType;
  whitelabelContactUsLink: string | undefined = undefined;
  isBillingOwnerOfActiveSubscription = false;

  user?: User;
  currency?: Currency;
  locale?: string;
  betaTest = false;
  notifications: NotificationEx[] = [];
  notificationsCount = 0;
  notificationsOpened = false;
  invalidAccountMarketplaces: AccountMarketplace[] = [];
  pendingValidationAccountMarketplaces: AccountMarketplace[] = [];

  organizations: Option<OrganizationAccountGroups>[] = [];
  selectedOrganizations = signal<Option<OrganizationAccountGroups>[]>([]);

  agencyBoardEligible = false;
  globalWarning$: Observable<string>;
  accountMarketplaces?: AccountMarketplace[];
  selectedDateRage?: string[];
  debugInfoModalVisible = false;

  @ViewChild("notificationsCtn") notificationsCtn!: ElementRef;

  readonly PROFILE_ITEMS: MenuItem[] = [
    {
      label: "My profile",
      icon: "icon-[mdi--user-outline]",
      routerLink: "/profile",
      queryParamsHandling: "merge",
    },
    {
      label: "Accounts",
      icon: "icon-[mdi--user-multiple-outline]",
      routerLink: "/accounts",
      queryParamsHandling: "merge",
    },
    {
      label: "User Management",
      icon: "icon-[mdi--user-key-outline]",
      routerLink: "/user-management",
      queryParamsHandling: "merge",
    },
    {
      label: "Billing",
      icon: "icon-[mdi--credit-card-outline]",
      routerLink: "/billing",
      queryParamsHandling: "merge",
      supportedBoards: [BoardType.M19, BoardType.WHITELABEL_SELFSERVICE],
    },
    {
      label: "Data Hub",
      icon: "icon-[mdi--database-outline]",
      routerLink: "/datahub",
      queryParamsHandling: "merge",
    },
    {
      label: "Referral Program",
      icon: "icon-[mdi--gift-outline]",
      href: "https://www.m19.com/get-rewards-for-your-account",
      target: "_blank",
      supportedBoards: [BoardType.M19],
    },
    {
      label: "Release Notes",
      icon: "icon-[mdi--sparkles-outline]",
      href: this.releaseNotesUrl,
      target: "_blank",
    },
    {
      label: "Logout",
      icon: "icon-[mdi--logout]",
      onClick: () => this.logout(),
    },
  ];

  constructor(
    private authService: AuthService,
    private accountGroupService: OrganizationAccountGroupService,
    private userSelectionService: UserSelectionService,
    public currencyService: CurrencyService,
    private router: Router,
    private layoutSelector: LayoutSelectorService,
    private notificationService: NotificationService,
    private renderer: Renderer2,
    private accountSelectionService: AccountSelectionService,
    private billingService: BillingService,
    private accountSettingsService: AccountSettingsService,
    private modalService: BsModalService,
    private hotkeys: Hotkeys,
    private obfuscationService: ObfuscationService,
    private toastrService: ToastrService,
  ) {
    this.globalWarning$ = this.notificationService.globalWarning$;
    this.hotkeys
      .addShortcut({ altKey: "KeyD" })
      .pipe(untilDestroyed(this))
      .subscribe(() => this.printDebugInfo());
    this.hotkeys
      .addShortcut({ altKey: "KeyO" })
      .pipe(untilDestroyed(this))
      .subscribe(() => this.obfuscationService.toggleObfuscationMode(!this.obfuscationService.obfuscation.value));
  }

  ngOnInit(): void {
    this.boardType = this.layoutSelector.getBoardType();
    this.whitelabelContactUsLink = this.layoutSelector.getConctact();
    this.navbarBrandFull = { src: this.layoutSelector.getLogo(), height: 40, alt: "logo" };
    this.navbarBrandMinimized = { src: this.layoutSelector.getSmallIcon(), width: 40, height: 40, alt: "logo" };

    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((x) => (this.currency = x));

    this.billingService.organizationOnwer$.pipe(untilDestroyed(this)).subscribe((o) => {
      // used to display the billing page for billing owner of whitelabel boards
      this.isBillingOwnerOfActiveSubscription = !!o?.hasActiveSubscription();
    });

    combineLatest([
      this.notificationService.getNotifications$,
      this.accountSelectionService.accountGroupSelection$,
      this.accountSelectionService.accountMarketplacesSelection$,
      this.accountGroupService.allOrganizationAccountGroups$,
      this.userSelectionService.selectedOrganizations$,
    ])
      .pipe(
        untilDestroyed(this),
        filter(([n, a]) => n != undefined && a != undefined),
        delay(10), // delay subscription so as to occur after view check
      )
      .subscribe(([notifications, account, accountMarketplaces, organizations, selectedOrgs]) => {
        const accountIds = account.getAccountMarketplaces().map((a) => a.accountId);
        this.notifications = this.filterNotifications(notifications, accountIds, accountMarketplaces);
        this.notificationsCount = this.notifications.length;
        this.notifications = this.notifications.slice(0, 50);
        this.invalidAccountMarketplaces = account
          .getAccountMarketplaces()
          .filter((am) => am.accessLevel != AccessLevel.READ && !am.isValidToken);
        this.pendingValidationAccountMarketplaces = account
          .getAccountMarketplaces()
          .filter(
            (am) =>
              am.accessLevel != AccessLevel.READ &&
              am.accountType == AccountType.VENDOR &&
              am.isValidToken &&
              !am.approvedBy &&
              am.nbAsinsToValidate &&
              am.nbAsinsToValidate > 0,
          );
        if (organizations) {
          this.organizations = organizations.map((o: OrganizationAccountGroups) => ({
            value: o,
            label: o.organizationName,
            id: o.id,
          }));
        }

        // If selected orgnizations is undefined, select all by default
        if (!selectedOrgs) {
          this.userSelectionService.setSelectedOrganizations(this.organizations.map((o) => o.value));
        } else {
          this.selectedOrganizations.set(selectedOrgs.map((o) => ({ value: o, label: o.organizationName, id: o.id })));
        }
        this.agencyBoardEligible =
          !!organizations && organizations.some((o: OrganizationAccountGroups) => o.accountGroups.length > 1);
        this.accountMarketplaces = accountMarketplaces;
      });

    this.renderer.listen("window", "click", (e: PointerEvent) => {
      if ((e.target as HTMLElement).isConnected && !this.notificationsCtn.nativeElement.contains(e.target)) {
        this.notificationsOpened = false;
      }
    });

    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user;
      this.locale = user.locale;
      this.betaTest = !!user.betaTester;
    });
    this.userSelectionService.dateRange$.pipe(untilDestroyed(this)).subscribe((dr) => {
      this.selectedDateRage = dr;
    });
  }

  selectOrganization(options: Option<OrganizationAccountGroups>[]) {
    this.organizations = options;
    this.selectedOrganizations.set(options);
    this.userSelectionService.setSelectedOrganizations(options.map((o) => o.value));
  }

  private filterNotifications(
    notifications: NotificationEx[],
    accountIds: string[],
    accountMarketplaces: AccountMarketplace[],
  ) {
    const result = [];
    for (const notification of notifications) {
      if (notification.dismissed) {
        continue; // do not display dismissed notif
      }
      if (notification.isCritical) {
        result.push(notification); // display critical notif
        continue;
      }
      if (
        notification.marketplace &&
        accountMarketplaces.findIndex(
          (am) => am.accountId == notification.accountId && am.marketplace == notification.marketplace,
        ) > -1
      ) {
        // display notification of the selected marketplaces
        result.push(notification);
        continue;
      }
      if (!notification.marketplace && accountIds.includes(notification.accountId)) {
        // display notification of the selected marketplaces
        result.push(notification);
      }
    }
    return result;
  }

  hasInvalidToken() {
    return this.invalidAccountMarketplaces.length > 0;
  }

  logInPlaceForAPIGrantAccess() {
    return this.invalidAccountMarketplaces[0].accountType == AccountType.SELLER ? "Seller Central" : "Vendor Central";
  }

  hasPendingVendorIntegration() {
    return this.pendingValidationAccountMarketplaces.length > 0;
  }

  requestGrant() {
    if (this.invalidAccountMarketplaces.length == 1) {
      const am = this.invalidAccountMarketplaces[0];
      this.accountSettingsService.grantSellingPartnerAccess(
        am.accountId,
        am.marketplace,
        am.accountType!,
        am.accountSubType === AccountSubType.KDP_AUTHOR,
      );
    } else {
      // TODO: expand account groups with invalid access
      this.router.navigate(["accounts"]);
    }
  }

  validateAccountIntegration() {
    // display popup for first account requiring validation
    if (this.pendingValidationAccountMarketplaces.length == 0) {
      return;
    }
    this.accountSettingsService.validateIntegration(this.pendingValidationAccountMarketplaces[0]);
  }

  isAdminLogin(): boolean {
    return this.authService.isAdminLogin();
  }

  printDebugInfo() {
    // prevent modal to open again if already shown
    if (this.debugInfoModalVisible) {
      return;
    }
    this.debugInfoModalVisible = true;
    const modalOptions: ModalOptions = {
      initialState: {
        user: this.user,
        accountMarketplaces: this.accountMarketplaces,
        dateRange: this.selectedDateRage,
      },
      class: "modal-lg",
    };
    const modalRef = this.modalService.show(DebugInfoModalComponent, modalOptions);
    modalRef.onHidden?.subscribe(() => {
      this.debugInfoModalVisible = false;
    });
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(["login"]);
  }

  displayAccountSelector(): boolean {
    const urlTree = this.router.parseUrl(this.router.url);
    const segments = urlTree?.root?.children[PRIMARY_OUTLET]?.segments;
    if (!this.isAccountSelectorDisplayed(segments)) {
      return false;
    }
    if (this.displaySearchTrendsMarketplaceSelector()) {
      return false;
    }
    return true;
  }

  private isAccountSelectorDisplayed(segments: UrlSegment[]) {
    return (
      segments &&
      segments.length &&
      segments[0].path != "accounts" &&
      segments[0].path != "datahub" &&
      segments[0].path != "super-board" &&
      !(
        segments.length > 1 &&
        segments[0].path == "strategies" &&
        (segments[segments.length - 1].path == "create-sponsored-brands" ||
          segments[segments.length - 1].path == "create-sponsored-products" ||
          segments[segments.length - 1].path == "create-sponsored-display")
      ) &&
      !(
        segments.length > 1 &&
        segments[0].path == "account360" &&
        segments[segments.length - 1].path == "dashboard-creation"
      )
    );
  }

  displayOrgSelector(): boolean {
    const urlTree = this.router.parseUrl(this.router.url);
    const segments = urlTree?.root?.children[PRIMARY_OUTLET]?.segments;
    return segments && !!segments.length && segments[0].path == "super-board";
  }

  displaySearchTrendsMarketplaceSelector(): boolean {
    const urlTree = this.router.parseUrl(this.router.url);
    const segments = urlTree?.root?.children[PRIMARY_OUTLET]?.segments;
    if (segments && segments.length && segments[0].path == "search-trends") {
      return true;
    }
    return false;
  }

  openNotifications(event: MouseEvent) {
    this.notificationsOpened = !this.notificationsOpened;
    event.preventDefault();
  }

  useNewUi() {
    // 1 = new version
    this.authService.setUiVersionPreference(1).subscribe({
      next: () => {
        // reload to home page
        window.location.replace("/dashboard");
      },
      error: (err: string) => {
        this.toastrService.error(err);
      },
    });
  }
}
