import { Component, computed, EventEmitter, Input, OnInit, Output, signal } from "@angular/core";
import { Strategy } from "@front/m19-api-client";
import { BrandingFilter, ProductGroupEx, SegmentConfigType, SegmentEx } from "@front/m19-models";
import {
  AccountSelectionService,
  AsinService,
  CatalogBrandService,
  ProductGroupService,
  SbStrategiesService,
  SdStrategiesService,
  SegmentService,
  SpStrategiesService,
  StrategyService,
} from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { TranslocoService } from "@jsverse/transloco";
import { PALETTE } from "@m19-board/models/Metric";
import { Filter } from "@m19-board/shared/filter/filter.component";
import { FilterType } from "@m19-board/shared/filter/asinFilter";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, switchMap } from "rxjs";

export type TrafficStatFilter<T> = {
  filterValue: T;
  exclude?: boolean;
};

export interface TrafficStatsFilter {
  enableMultiAsin: boolean;
  productSegments: TrafficStatFilter<SegmentEx[]>[];
  keywordSegments: TrafficStatFilter<SegmentEx[]>[];
  advertisedProducts: TrafficStatFilter<string[]>[];
}

export const DefaultTrafficStatsFilter: TrafficStatsFilter = {
  enableMultiAsin: true,
  productSegments: [],
  keywordSegments: [],
  advertisedProducts: [],
};

const Targetings = "sd-strategy-creation.targetings";
const AdvertisedProducts = "sd-strategy-creation.advertised_products";

@UntilDestroy()
@Component({
  selector: "app-traffic-stats-filter",
  templateUrl: "./traffic-stats-filter.component.html",
  styleUrls: ["./traffic-stats-filter.component.scss"],
})
export class TrafficStatsFilterComponent implements OnInit {
  @Input()
  set initSegment(value: SegmentEx) {
    if (value) {
      const filterType =
        value.segmentType === SegmentConfigType.KeywordSegment ? FilterType.KeywordSegment : FilterType.ProductSegment;
      this.initFilterValue.set({
        filterType,
        values: [
          {
            label: value.name ?? "",
            value: value,
          },
        ],
      });
    } else {
      this.initFilterValue.set(undefined);
    }
  }

  @Output()
  filterChange = new EventEmitter<TrafficStatsFilter>();

  filter: TrafficStatsFilter = DefaultTrafficStatsFilter;
  initFilterValue = signal<
    | {
        filterType: FilterType;
        values: Option<SegmentEx | ProductGroupEx | Strategy | BrandingFilter | string>[];
      }
    | undefined
  >(undefined);

  keywordSegmentOptions: Option<SegmentEx>[] = [];
  productSegmentOptions: Option<SegmentEx>[] = [];
  advertisedProductGroupsOptions: Option<ProductGroupEx>[] = [];
  advertisedProductInStrategiesOptions: Option<Strategy>[] = [];
  brandTrafficOptions: Option<BrandingFilter>[] = [];
  customField1Values: Option<string>[] = [];
  customField2Values: Option<string>[] = [];
  customFiel1dMap: Map<string, string[]> = new Map();
  customField2Map: Map<string, string[]> = new Map();
  strategyAsinMap: Map<number, string[]> = new Map();

  readonly filters: Filter<SegmentEx | ProductGroupEx | Strategy | BrandingFilter | string>[] = [
    {
      type: FilterType.KeywordSegment,
      label: this.translocoService.translate("add-keyword-tracking-modal.keyword_segments"),
      tooltip: "Filter traffic on keywords",
      category: this.translocoService.translate(Targetings),
      noValuePlaceholder: "No keyword segment available",
      options: () => this.keywordSegmentOptions,
      color: PALETTE[0],
      excludeModeAllowed: true,
      selectedOptions: [],
    },
    {
      type: FilterType.ProductSegment,
      label: this.translocoService.translate("add-product-tracking-modal.product_segments"),
      tooltip: "Filter traffic on targeted products",
      category: this.translocoService.translate(Targetings),
      noValuePlaceholder: "No product segment available",
      options: () => this.productSegmentOptions,
      color: PALETTE[1],
      excludeModeAllowed: true,
      selectedOptions: [],
    },
    {
      type: FilterType.BrandTraffic,
      label: this.translocoService.translate("widget.modelbranded_traffic"),
      tooltip: "Filter traffic on your brand targetings",
      category: this.translocoService.translate(Targetings),
      noValuePlaceholder: "No brands available",
      options: () => this.brandTrafficOptions,
      color: PALETTE[4],
      excludeModeAllowed: true,
      selectedOptions: [],
    },
    {
      type: FilterType.ProductGroup,
      label: this.translocoService.translate("v2-sidebar.product_groups"),
      tooltip: "Filter traffic redirecting to advertised products of a product group",
      category: this.translocoService.translate(AdvertisedProducts),
      noValuePlaceholder: "No product group available",
      options: () => this.advertisedProductGroupsOptions,
      color: PALETTE[2],
      excludeModeAllowed: true,
      selectedOptions: [],
    },
    {
      type: FilterType.Strategy,
      label: this.translocoService.translate("sales-advertising.strategy_products"),
      tooltip: "Filter traffic redirecting to advertised products of a strategy",
      category: this.translocoService.translate(AdvertisedProducts),
      noValuePlaceholder: "No strategy available",
      options: () => this.advertisedProductInStrategiesOptions,
      color: PALETTE[3],
      excludeModeAllowed: true,
      selectedOptions: [],
    },
    {
      type: FilterType.BrandProducts,
      label: this.translocoService.translate("sov-main-table.brands"),
      tooltip: "Filter traffic redirecting to your brand advertised products",
      category: this.translocoService.translate(AdvertisedProducts),
      noValuePlaceholder: "No brands available",
      options: () => this.brandTrafficOptions,
      color: PALETTE[5],
      excludeModeAllowed: true,
      selectedOptions: [],
    },
    {
      type: FilterType.CustomField1,
      label: this.translocoService.translate("traffic-stats-filter.custom_field_1"),
      tooltip: "Filter traffoc redirecting to product with a specific value in Custom Field 1",
      category: this.translocoService.translate(AdvertisedProducts),
      noValuePlaceholder: "No custom field value available",
      options: () => this.customField1Values,
      color: PALETTE[6],
      excludeModeAllowed: true,
      selectedOptions: [],
    },
    {
      type: FilterType.CustomField2,
      label: this.translocoService.translate("traffic-stats-filter.custom_field_2"),
      tooltip: "Filter traffoc redirecting to product with a specific value in Custom Field 2",
      category: this.translocoService.translate(AdvertisedProducts),
      noValuePlaceholder: "No custom field value available",
      options: () => this.customField2Values,
      color: PALETTE[7],
      excludeModeAllowed: true,
      selectedOptions: [],
    },
  ];

  readonly _filters = computed(() => {
    return this.filters.map((f) => {
      if (f.type === this.initFilterValue()?.filterType) {
        f.selectedOptions = this.initFilterValue()?.values ?? [];
      }
      return f;
    });
  });

  constructor(
    private accountSelection: AccountSelectionService,
    private segmentService: SegmentService,
    private productGroupService: ProductGroupService,
    private catalogBrandService: CatalogBrandService,
    private asinService: AsinService,
    private spStrategyService: SpStrategiesService,
    private sbStrategyService: SbStrategiesService,
    private sdStrategyService: SdStrategiesService,
    private translocoService: TranslocoService,
    private strategyService: StrategyService,
  ) {}

  ngOnInit(): void {
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) =>
          combineLatest<[Map<number, SegmentEx>, Map<number, Strategy>, ProductGroupEx[], BrandingFilter[]]>([
            this.segmentService.getSegments(am.accountId, am.marketplace),
            this.strategyService.getStrategyIndex(am.accountId, am.marketplace),
            this.productGroupService.getProductGroups(am.accountId, am.marketplace),
            this.catalogBrandService.getBrandingFilters(am.accountId, am.marketplace),
          ]),
        ),
      )
      .subscribe(([segmentIndex, strategyIndex, productGroups, brandingFilters]) => {
        const kwSegments = Array.from(segmentIndex.values()).filter(
          (s) => s.segmentType === SegmentConfigType.KeywordSegment,
        );
        const productSegments = Array.from(segmentIndex.values()).filter(
          (s) => s.segmentType === SegmentConfigType.ProductSegment,
        );
        this.keywordSegmentOptions = kwSegments.map(this.toSegmentDropdownOption);
        this.productSegmentOptions = productSegments.map(this.toSegmentDropdownOption);
        this.advertisedProductGroupsOptions = productGroups.map(this.toProductGroupOption);
        this.advertisedProductInStrategiesOptions = Array.from(strategyIndex.values()).map(this.toStrategyOption);
        this.brandTrafficOptions = brandingFilters.map(this.toBrandTrafficOption);

        // reset the filter on account marketplace change
        if (this.filter !== DefaultTrafficStatsFilter) {
          this.initFilterValue.set(undefined);
          this.filter = DefaultTrafficStatsFilter;
          this.filterChange.emit(this.filter);
        }
      });
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) => this.asinService.getInventoryConfig(am.accountId, am.marketplace)),
      )
      .subscribe((config) => {
        if (config.customField1Name) {
          const filter = this._filters().find((f) => f.type === FilterType.CustomField1)!;
          filter.label = config.customField1Name;
        }
        if (config.customField2Name) {
          const filter = this._filters().find((f) => f.type === FilterType.CustomField2)!;
          filter.label = config.customField2Name;
        }
      });
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) => this.asinService.getCatalog(am.accountId, am.marketplace)),
      )
      .subscribe((catalog) => {
        const customField1Values = new Set<string>();
        const customField2Values = new Set<string>();
        for (const product of catalog.products) {
          if (product.customField1) customField1Values.add(product.customField1);
          if (product.customField2) customField2Values.add(product.customField2);
        }
        this.customField1Values = Array.from(customField1Values).map((v) => ({ label: v, value: v }));
        this.customField2Values = Array.from(customField2Values).map((v) => ({ label: v, value: v }));
        // map custom field values to asin
        this.customFiel1dMap.clear();
        this.customField2Map.clear();
        for (const product of catalog.products) {
          if (product.customField1) {
            if (!this.customFiel1dMap.has(product.customField1)) {
              this.customFiel1dMap.set(product.customField1, []);
            }
            this.customFiel1dMap.get(product.customField1)!.push(product.asin!);
          }
          if (product.customField2) {
            if (!this.customField2Map.has(product.customField2)) {
              this.customField2Map.set(product.customField2, []);
            }
            this.customField2Map.get(product.customField2)!.push(product.asin!);
          }
        }
      });
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) =>
          combineLatest([
            this.spStrategyService.getSPStrategiesPerAsin(am.accountId, am.marketplace),
            this.sbStrategyService.getSBStrategiesPerAsin(am.accountId, am.marketplace),
            this.sdStrategyService.getSDStrategiesPerAsin(am.accountId, am.marketplace),
          ]),
        ),
      )
      .subscribe(([spStrategiesPerAsin, sbStrategiesPerAsin, sdStrategiesPerAsin]) => {
        this.strategyAsinMap.clear();
        for (const [asin, strategies] of [...spStrategiesPerAsin, ...sbStrategiesPerAsin, ...sdStrategiesPerAsin]) {
          for (const strategy of strategies) {
            if (!this.strategyAsinMap.has(strategy.strategyId!)) {
              this.strategyAsinMap.set(strategy.strategyId!, []);
            }
            this.strategyAsinMap.get(strategy.strategyId!)!.push(asin);
          }
        }
      });
  }

  enableMultiAsin() {
    this.filter.enableMultiAsin = !this.filter.enableMultiAsin;
    this.filterChange.emit(this.filter);
  }

  applyFilter(filters: Filter<SegmentEx | ProductGroupEx | Strategy | BrandingFilter | string>[]) {
    const keywordSegments: TrafficStatFilter<SegmentEx[]>[] = [];
    const productSegments: TrafficStatFilter<SegmentEx[]>[] = [];
    const advertisedProducts: TrafficStatFilter<string[]>[] = [];
    for (const filter of filters) {
      if (filter.selectedOptions && filter.selectedOptions.length === 0) {
        continue;
      }
      const values = filter.selectedOptions?.map((v) => v.value);
      if (filter.type === FilterType.KeywordSegment) {
        keywordSegments.push({ filterValue: values as SegmentEx[], exclude: filter.exclude });
      } else if (filter.type === FilterType.ProductSegment) {
        productSegments.push({ filterValue: values as SegmentEx[], exclude: filter.exclude });
      } else if (filter.type === FilterType.ProductGroup) {
        const productGroupAsins = new Set((values as ProductGroupEx[]).flatMap((pg) => pg.items));
        advertisedProducts.push({ filterValue: Array.from(productGroupAsins.values()), exclude: filter.exclude });
      } else if (filter.type === FilterType.Strategy) {
        const strategyAsins = new Set((values as Strategy[]).flatMap((s) => this.strategyAsinMap.get(s.strategyId!)!));
        advertisedProducts.push({ filterValue: Array.from(strategyAsins.values()), exclude: filter.exclude });
      } else if (filter.type === FilterType.BrandProducts) {
        const brandAsins = new Set((values as BrandingFilter[]).flatMap((b) => b.asins));
        advertisedProducts.push({ filterValue: Array.from(brandAsins.values()), exclude: filter.exclude });
      } else if (filter.type === FilterType.BrandTraffic) {
        keywordSegments.push({
          filterValue: (values as BrandingFilter[]).map((b) => b.keywordSegment),
          exclude: filter.exclude,
        });
        productSegments.push({
          filterValue: (values as BrandingFilter[]).map((b) => b.productSegment),
          exclude: filter.exclude,
        });
      } else if (filter.type === FilterType.CustomField1) {
        const asins = (values as string[]).flatMap((v) => this.customFiel1dMap.get(v)!);
        advertisedProducts.push({ filterValue: asins, exclude: filter.exclude });
      } else if (filter.type === FilterType.CustomField2) {
        const asins = (values as string[]).flatMap((v) => this.customField2Map.get(v)!);
        advertisedProducts.push({ filterValue: asins, exclude: filter.exclude });
      }
    }
    this.filter = { ...this.filter, keywordSegments, productSegments, advertisedProducts };
    this.filterChange.emit(this.filter);
  }

  private toSegmentDropdownOption(segment: SegmentEx): Option<SegmentEx> {
    return {
      label: segment.name,
      value: segment,
    };
  }

  private toProductGroupOption(productGroup: ProductGroupEx): Option<ProductGroupEx> {
    return {
      label: productGroup.name,
      value: productGroup,
    };
  }

  private toStrategyOption(strategy: Strategy): Option<Strategy> {
    return {
      label: strategy.name ?? "",
      value: strategy,
    };
  }

  private toBrandTrafficOption(brand: BrandingFilter): Option<BrandingFilter> {
    return {
      label: brand.name,
      value: brand,
    };
  }
}
