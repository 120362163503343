import { Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AccountSelectionService, AuthService, SbStrategiesService } from "@front/m19-services";
import { BoardType, LayoutSelectorService } from "@m19-board/layout-selector.service";
import { Observable, of } from "rxjs";
import { Router } from "@angular/router";
import { AccountMarketplace, AccountSubType, AccountType, CampaignType } from "@front/m19-api-client";

@UntilDestroy()
@Component({
  selector: "app-sb-manager",
  templateUrl: "./sb-manager.component.html",
})
export class SbManagerComponent implements OnInit {
  accountMarketplace?: AccountMarketplace;
  m19BoardType = false;
  readonly CampaignType = CampaignType;
  creationUrl = "/strategies/create-sponsored-brands";

  constructor(
    private sbStrategiesService: SbStrategiesService,
    private accountSelectionService: AccountSelectionService,
    private layoutSelectorService: LayoutSelectorService,
    private router: Router,
    private authService: AuthService,
  ) {
    this.authService.loggedUser$.subscribe((user) => {
      if ((user?.uiVersion ?? 0) > 0) {
        this.creationUrl = "/advertising/sponsored-brands/create";
      }
    });
  }

  ngOnInit() {
    this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.accountMarketplace = am;
    });
    this.m19BoardType = this.layoutSelectorService.getBoardType() == BoardType.M19;
  }

  createSponsoredBrands(accountMarketplace: AccountMarketplace) {
    this.router.navigate([this.creationUrl], { queryParamsHandling: "merge" });
  }

  hasBrand(): Observable<boolean> {
    if (!this.accountMarketplace) return of(true);
    else if (this.accountMarketplace.accountType == AccountType.VENDOR) return of(true);
    else
      return this.sbStrategiesService.hasBrands(this.accountMarketplace.accountId, this.accountMarketplace.marketplace);
  }

  isKDP() {
    return this.accountMarketplace?.accountSubType === AccountSubType.KDP_AUTHOR;
  }
}
