import { CurrencyPipe } from "@angular/common";
import { Component, computed, signal } from "@angular/core";
import { MatTooltip } from "@angular/material/tooltip";
import { StrategyEx } from "@front/m19-models";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { ICON_BOOST } from "@m19-board/utils/iconsLabels";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { StrategyType } from "@front/m19-api-client";

@Component({
  selector: "app-strategy-info-cell",
  standalone: true,
  imports: [MatTooltip, TranslocoRootModule],
  templateUrl: "./strategy-info-cell.component.html",
})
export class StrategyInfoCellComponent implements ICellRendererAngularComp {
  strategy = signal<StrategyEx | undefined>(undefined);
  currencyCode = signal<string>("EUR");
  locale = signal<string>("en-US");
  promoBoostActivated = signal<boolean>(false);

  readonly StrategyType = StrategyType;
  readonly ICON_BOOST = ICON_BOOST;

  formattedDailySpend = computed(() => {
    return new CurrencyPipe(this.locale()).transform(
      this.strategy()?.minDailySpend,
      this.currencyCode(),
      "symbol-narrow",
      "1.0-0",
      this.locale(),
    );
  });

  agInit(
    params: ICellRendererParams & {
      strategy: StrategyEx;
      currencyCode: string;
      promoBoostActivated: boolean;
    },
  ): void {
    if (params.strategy) {
      this.strategy.set(params.strategy);
    }
    if (params.currencyCode) {
      this.currencyCode.set(params.currencyCode);
    }
    this.promoBoostActivated.set(params.promoBoostActivated);
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }
}
