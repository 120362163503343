import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, EventEmitter, input, Output } from '@angular/core';
import { Option, SelectPopperComponent } from '../popper/selectpopper.component';
import { TranslocoDirective } from '@jsverse/transloco';

/**
 * ISelect is a wrapper around IMultiSelectComponent
 * It is used to select a single option
 */
@Component({
  selector: 'ISelect',
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, TranslocoDirective],
  templateUrl: './iselect.component.html',
})
export class ISelectComponent<T> extends SelectPopperComponent<T> {
  @Output() selectedChange = new EventEmitter<Option<T>>();

  selected = input<Option<T> | undefined | null>(undefined);

  label = computed(() => {
    return this.selected()?.[this.optionAttribute()];
  });

  hasSelectedOptions = computed(() => {
    return this.selected() !== undefined;
  });

  protected override handleKeyDown(event: KeyboardEvent): void {
    super.handleKeyDown(event, this.filteredOptionsWithCategories());
    if (event.key === 'Enter') {
      event.preventDefault();
      this.selectOption(this.filteredOptionsWithCategories()[this.activeIndex().i].options[this.activeIndex().j]);
    }
  }

  isSelected(option: Option<T>): boolean {
    return this.selected()?.[this._by()] === option[this._by()];
  }

  selectOption(option: Option<T>) {
    if (option.disabled) return;
    this.selectedChange.emit(option);
    this.hideMenu();
  }
}
