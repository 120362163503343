import { Injectable } from "@angular/core";
import { Marketplace } from "@front/m19-api-client";
import { Moment, tz } from "moment-timezone";
import { Marketplaces } from "@front/m19-models";

@Injectable({
  providedIn: "root",
})
export class AmazonTimezoneService {
  public getNow(marketplace: Marketplace): Moment {
    return tz(Marketplaces[marketplace]?.timeZone ?? "Europe/Paris");
  }
}
