import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { AccountApi, AccountMarketplace, AlgoMode, StrategyStateEnum, User } from "@front/m19-api-client";
import { StrategyEx, organizationCompare } from "@front/m19-models";
import { AccountMarketplaceService, AccountSelectionService, AuthService, StrategyService } from "@front/m19-services";
import { Utils } from "@front/m19-utils";
import { ConfirmPopupComponent } from "@m19-board/shared/confirm-popup/confirm-popup.component";
import { AmazonTimezoneService } from "@m19-board/utils/amazonTimezoneService";
import { ICON_IMPORT } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import moment from "moment-timezone";
import { BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { forkJoin, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { BulkImportService } from "../strategy-bulk-upload-modal/bulk-import.service";
import { StrategyBoostBulkReportComponent } from "./strategy-boost-bulk-upload/strategy-boost-bulk-report.component";
import { StrategyBoostBulkUploadComponent } from "./strategy-boost-bulk-upload/strategy-boost-bulk-upload.component";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

@UntilDestroy()
@Component({
  selector: "app-strategy-boost",
  templateUrl: "./strategy-boost.component.html",
  styleUrls: ["./strategy-boost.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class StrategyBoostComponent implements OnInit {
  strategies: StrategyEx[] = [];
  accountMarketplace?: AccountMarketplace;
  filter: string = "";
  user?: User;
  enabled = false;
  isReadOnly = false;

  bsRangeValue?: Date[];
  triggeredByUser = false; // to avoid send API request when initializing

  readonly faSearch = faSearch;
  readonly faCalendar = faCalendar;
  readonly ICON_IMPORT = ICON_IMPORT;

  datePickerConfig?: Partial<BsDaterangepickerConfig>;

  constructor(
    private accountSelectionService: AccountSelectionService,
    private strategyService: StrategyService,
    private authService: AuthService,
    private accountApi: AccountApi,
    private toasterService: ToastrService,
    private accountMarketplaceService: AccountMarketplaceService,
    private modalService: BsModalService,
    private amazonTzService: AmazonTimezoneService,
    private bulkImportService: BulkImportService,
  ) {}

  date = new Date();

  ngOnInit(): void {
    const suggestedDate = new Date();
    suggestedDate.setDate(suggestedDate.getDate() + 5);

    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));

    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((x) => {
      this.user = x;
      const dateFormat = this.getDateFormatString();
      this.datePickerConfig = Object.assign(
        {},
        {
          minDate: this.date,
          showWeekNumbers: false,
          containerClass: "theme-dark-blue",
          rangeInputFormat: dateFormat,
          dateInputFormat: dateFormat,
        },
      );
    });

    this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.triggeredByUser = false;
      this.accountMarketplace = am;
      this.date = this.amazonTzService.getNow(am.marketplace).toDate();
      if (this.accountMarketplace.promoStartDate && this.accountMarketplace.promoEndDate) {
        this.enabled = true;
        this.bsRangeValue = [
          moment(this.accountMarketplace.promoStartDate).toDate(),
          moment(this.accountMarketplace.promoEndDate).toDate(),
        ];
      } else {
        this.enabled = false;
        this.bsRangeValue = [this.date, suggestedDate];
      }
    });
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) => this.strategyService.getStrategyIndex(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((strategyIndex) => {
        this.strategies = [];
        for (const strategy of strategyIndex.values()) {
          const strategyEx = new StrategyEx(strategy);
          strategyEx.activatePrimeDayBoost = strategy.primeDayBoost != null;
          if (strategy.state != StrategyStateEnum.PAUSED) {
            this.strategies.push(strategyEx);
          }
        }
      });
  }

  getDateFormatString() {
    const formatObj = new Intl.DateTimeFormat(this.user!.locale).formatToParts(new Date());
    return formatObj
      .map((obj) => {
        switch (obj.type) {
          case "day":
            return "DD";
          case "month":
            return "MM";
          case "year":
            return "YYYY";
          default:
            return obj.value;
        }
      })
      .join("");
  }

  toggleActivate(e: MatSlideToggleChange) {
    if (!this.enabled) {
      this.update(this.bsRangeValue);
      this.enabled = true;
    } else {
      if (this.date >= this.bsRangeValue![0] && this.date <= this.bsRangeValue![1]) {
        e.source.checked = true;
        const modalOptions: ModalOptions = {
          class: "modal-lg",
          initialState: {
            message:
              "Promo days are currently running. ACOS Target of strategies with boost will go back to their initial values.",
            confirmCta: "Deactivate Promo Days",
            type: "success",
          },
        };
        const modalRef = this.modalService.show(ConfirmPopupComponent, modalOptions);
        const subscription = modalRef.content!.confirm.subscribe(() => {
          this.update();
          subscription.unsubscribe();
          this.enabled = false;
        });
        return;
      } else {
        this.update();
        this.enabled = false;
      }
    }
  }

  update(dates?: (Date | undefined)[]) {
    if (!this.triggeredByUser) {
      this.triggeredByUser = true;
      return;
    }
    if (dates?.length == 2 && this.accountMarketplace) {
      const startDate = Utils.formatDateForApi(dates[0]!);

      const endDate = Utils.formatDateForApi(dates[1]!);

      const obj = {
        accountId: this.accountMarketplace.accountId,
        marketplace: this.accountMarketplace.marketplace,
        promoStartDate: startDate,
        promoEndDate: endDate,
        organizationId: this.accountMarketplace.resourceOrganizationId,
      };
      this.accountApi.updateAccountMarketplace(obj).subscribe((res) => {
        this.accountMarketplaceService.updateAccount(this.accountMarketplace!.accountId, (x) => {
          x.promoStartDate = startDate;
          x.promoEndDate = endDate;
        });
        this.accountSelectionService.updateAccountMarketplace([
          { ...this.accountMarketplace!, promoStartDate: startDate, promoEndDate: endDate },
        ]);
        this.toasterService.success("Promo days have been activated", "Promo Days Updated");
      });
    } else if (!dates) {
      const obj = {
        accountId: this.accountMarketplace!.accountId,
        marketplace: this.accountMarketplace!.marketplace,
        promoStartDate: "null",
        promoEndDate: "null",
        organizationId: this.accountMarketplace!.resourceOrganizationId,
      };
      this.accountApi.updateAccountMarketplace(obj).subscribe({
        next: (res) => {
          this.accountMarketplaceService.updateAccount(this.accountMarketplace!.accountId, (x) => {
            x.promoStartDate = undefined;
            x.promoEndDate = undefined;
          });
          this.accountSelectionService.updateAccountMarketplace([
            { ...this.accountMarketplace!, promoStartDate: undefined, promoEndDate: undefined },
          ]);
          this.toasterService.success("Promo days have been deactivated", "Promo Days Updated");
        },
        error: (err) => this.toasterService.error(err.response.message, "Promo Days Update Error"),
      });
    }
  }

  uploadPromoDays() {
    const ref = this.modalService.show(StrategyBoostBulkUploadComponent, {
      initialState: {
        strategyIndex: new Map(this.strategies.map((s) => [s.strategyId, s])),
      },
      class: "modal-xl",
    });
    ref
      .content!.boostUpdates.pipe(
        untilDestroyed(this),
        switchMap(({ updates, errors }) => {
          if (updates.length == 0) {
            return of({ updates, errors });
          }
          return forkJoin(
            updates.map((update) =>
              this.strategyService
                .updatePrimeDayBoost(
                  this.accountMarketplace!.accountId,
                  this.accountMarketplace!.marketplace,
                  this.accountMarketplace!.resourceOrganizationId!,
                  update.strategyId,
                  update.boost,
                )
                .pipe(take(1)),
            ),
          ).pipe(map(() => ({ updates, errors })));
        }),
      )
      .subscribe({
        next: ({ updates, errors }) => {
          ref.hide();
          this.modalService.show(StrategyBoostBulkReportComponent, {
            initialState: { boostUpdates: updates, errors },
            class: "modal-lg",
          });
        },
        error: (err) => {
          this.toasterService.error(err.response.message, "Promo Days Update Error");
        },
      });
  }

  exportAsCsv() {
    this.bulkImportService.exportPromoDaysBoostCsv(
      this.accountMarketplace!,
      // do not export unboostable strategies
      this.strategies.filter((s) => s.algoMode == AlgoMode.ACOS_TARGET && !s.dailyBudget),
    );
  }
}
