<ng-container *transloco="let t">
  <div class="flex flex-col">
    @if (strategy) {
      <div class="inline-flex items-center justify-start">
        @if (withCampaignState && strategy.state) {
          <span class="icon-prepend mr-2">
            <fa-icon
              [icon]="
                strategyGroupState
                  ? strategyGroupState === 'ENABLED'
                    ? faPlayCircle
                    : faPauseCircle
                  : strategy.state === 'ENABLED'
                    ? faPlayCircle
                    : faPauseCircle
              "
              [ngClass]="
                strategyGroupState
                  ? strategyGroupState === 'ENABLED'
                    ? 'activated'
                    : 'desactivated'
                  : strategy.state === 'ENABLED'
                    ? 'activated'
                    : 'desactivated'
              "
              [matTooltip]="
                strategyGroupState
                  ? strategyGroupState === 'ENABLED'
                    ? t('common.active_startegy')
                    : t('common.paused_strategy')
                  : strategy.state === 'ENABLED'
                    ? t('common.active_startegy')
                    : t('common.paused_strategy')
              "
              size="lg"
            ></fa-icon>
          </span>
        }
        @if (withCampaignType) {
          <app-campaign-type-badge [campaignType]="strategy.campaignType" class="mr-1"></app-campaign-type-badge>
        }
        @if (disableLink) {
          {{ strategyGroupName ?? strategy.name }}
        } @else {
          <a
            [routerLink]="redirectToStrategy()"
            queryParamsHandling="merge"
            [target]="target"
            class="link truncate"
            matTooltip="{{ strategyGroupName ?? strategy.name }}"
            [ngStyle]="{ color: color ?? '' }"
            [attr.data-testid]="'name-' + strategyGroupName"
          >
            {{ strategyGroupName ?? strategy.name }}
          </a>
        }
      </div>
    } @else {
      {{ placeholder }}
    }
  </div>
</ng-container>
