<div class="decimal-input" *transloco="let t">
  <div class="flex items-center">
    @if (label) {
      <label class="col-form-label mr-2">{{ label }}</label>
    }
    <div
      class="input-group"
      (focusin)="isFocused.emit(true)"
      (focusout)="isFocused.emit(false)"
      (click)="$event.stopPropagation()"
      [style.width]="fullWidth ? '100%' : 'unset'"
    >
      <input
        [attr.data-testid]="testid"
        [disabled]="disabled"
        type="number"
        inputmode="numeric"
        step="any"
        class="simple-input-append form-control pb-1 pl-2 pr-2 pt-1 text-center"
        [placeholder]="placeholder"
        [(ngModel)]="value_"
        (ngModelChange)="!withActionButtons && sendValue(value_)"
        [ngStyle]="{ 'width.rem': size }"
        (keypress)="checkKey($event)"
        [min]="min ?? null"
        [max]="max ?? null"
        (keydown.enter)="value_ !== undefined && sendValue(roundValue(value_))"
        (click)="$event.preventDefault()"
      />
      <div class="input-group-append gap-x-1">
        @if ((withActionButtons && value_ !== oldValue && !updateOnlyOnKeypress) || (updateOnlyOnKeypress && typed)) {
          @if (withActionButtons && value_ !== oldValue) {
            @let inputIsInvalid =
              value_ !== undefined && ((min !== undefined && value_ < min) || (max !== undefined && value_ > max));
            <IButton
              type="submit"
              [tooltipValue]="inputIsInvalid ? invalidInputMessage : t('common.confirm')"
              [disabled]="inputIsInvalid"
              (onClick)="value_ !== undefined && sendValue(roundValue(value_)); $event.preventDefault()"
              [icon]="ICON_CHECK"
              color="green"
              variant="soft"
            />
          }
          @if (withActionButtons && value_ !== oldValue) {
            <IButton
              tooltipValue="{{ t('common.undo') }}"
              (onClick)="resetValue(); $event.preventDefault()"
              [disabled]="disabled"
              [icon]="ICON_CLOSE"
              color="red"
              variant="soft"
            />
          }
        }
        @if ((!withActionButtons || value_ === oldValue || (updateOnlyOnKeypress && !typed)) && unit) {
          <span class="input-group-text">{{ unit }}</span>
        }
        @if (withActionButtons && trash && !!oldValue) {
          <IButton
            tooltipValue="{{ t('common.delete') }}"
            (onClick)="delete(); $event.preventDefault()"
            [disabled]="disabled"
            [icon]="ICON_TRASH"
            color="red"
            variant="soft"
          />
        }
      </div>
    </div>
  </div>
</div>
