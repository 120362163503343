<div class="flex flex-wrap gap-2" *transloco="let t">
  <IDropdown
    [showSubContent]="!!selectedFilter()"
    [showContent]="!selectedFilter()"
    (dropdownClosed)="backToFiltersMenu()"
  >
    <div class="relative">
      <IButton
        [label]="t('filter-tags.add_filter')"
        color="white"
        icon="icon-[mdi--filter-variant]"
        size="sm"
        [borderOutline]="hasSelectedFilters()"
      />
      @if (hasSelectedFilters()) {
        <span class="bg-main absolute -right-1 -top-0.5 size-[0.65rem] rounded-full border-2 border-white"></span>
      }
    </div>
    <div ngProjectAs="content">
      <ul class="mb-0 border-b border-neutral-200 p-1 last:border-b-0">
        @for (filter of _filters(); track filter.label) {
          @let isSelected = filter.selectedOptions.length;
          <li
            (click)="selectFilterV2(filter, $event)"
            class="flex cursor-pointer items-center justify-between gap-2 rounded-md px-2 py-1.5 hover:bg-neutral-100"
          >
            <span
              [ngClass]="{
                'font-bold': isSelected,
              }"
              >{{ filter.label }}
            </span>
            @if (isSelected) {
              <span class="inline-flex items-center gap-2">
                <span class="text-gray-500"
                  >({{ t("common.selected", { count: filter.selectedOptions.length }) }} )</span
                >
                <span class="bg-main size-1.5 rounded-full"></span
              ></span>
            }
          </li>
        }
      </ul>
      <div class="flex justify-end">
        <IButton
          (onClick)="clearAllFilters()"
          class="m-1"
          size="xs"
          color="white"
          variant="soft"
          [label]="t('common.clear_all')"
        ></IButton>
      </div>
    </div>
    <div ngProjectAs="sub-content">
      <IMultiSelect
        [options]="selectedFilter()?.options() ?? []"
        [selected]="selectedFilter()?.selectedOptions ?? []"
        [withSelectAll]="!selectedFilter()?.singleValue"
        (selectedChange)="selectedFilter() && selectValue(selectedFilter()!, $event)"
        [placeholder]="t('filter-tags.select_filter', [selectedFilter()?.label])"
        [floating]="false"
        by="value"
        searchable
        rounded
      >
        <ng-template #headerSlot>
          <div class="flex items-center justify-between py-1">
            <IButton
              size="xs"
              color="white"
              variant="link"
              [label]="t('common.back')"
              icon="icon-[material-symbols--chevron-left-rounded]"
              (onClick)="backToFiltersMenu($event)"
            ></IButton>
            <span class="mr-2">{{ selectedFilter()?.label }}</span>
          </div>
          @if (selectedFilter() && selectedFilter()?.excludeModeAllowed) {
            <div class="border-t px-1 py-2">
              <mat-radio-group
                [value]="selectedFilter()!.exclude"
                (change)="excludeFilter(selectedFilter()!, $event.value)"
                class="flex w-full gap-3 [&_.mdc-radio]:!p-0 [&_label]:m-0 [&_label]:ml-2"
              >
                <mat-radio-button disableRipple color="primary" class="radio-button-center" [value]="false">
                  <div class="text-sm">{{ t("filter-tags.include") }}</div>
                </mat-radio-button>
                <mat-radio-button disableRipple color="primary" class="radio-button-center" [value]="true">
                  <div class="text-sm">{{ t("filter-tags.exclude") }}</div>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          }
        </ng-template>
      </IMultiSelect>
    </div>
  </IDropdown>
</div>
