import { combineProducts } from '@front/m19-models/ProductEx';
import { sumDspStats } from './dsp-stats.utils';
import { sumOrderStat } from './order-stats.utils';
import {
  addAdStats,
  addVendorInventoryAdStats,
  mergeAdStatsWithTargetHistory,
  mergeAllStats,
  mergeAllStatsV2,
  mergeSeveralDates,
  mergeSeveralDatesV2,
} from './statsUtils';

export enum AggregationFunction {
  sumAdStats,
  sumOrderStat,
  combineProducts,
  mergeAllStats,
  mergeAllStatsV2,
  mergeSeveralDates,
  mergeSeveralDatesV2,
  sumDspStats,
  mergeAdStatsWithTargetHistory,
  sumVendorInventoryAdStats,
}

export const aggregationMethods: Record<AggregationFunction, (x: any, y: any) => any> = {
  [AggregationFunction.sumAdStats]: addAdStats,
  [AggregationFunction.sumOrderStat]: sumOrderStat,
  [AggregationFunction.combineProducts]: combineProducts,
  [AggregationFunction.mergeAllStats]: mergeAllStats,
  [AggregationFunction.mergeAllStatsV2]: mergeAllStatsV2,
  [AggregationFunction.mergeSeveralDates]: mergeSeveralDates,
  [AggregationFunction.mergeSeveralDatesV2]: mergeSeveralDatesV2,
  [AggregationFunction.sumDspStats]: sumDspStats,
  [AggregationFunction.mergeAdStatsWithTargetHistory]: mergeAdStatsWithTargetHistory,
  [AggregationFunction.sumVendorInventoryAdStats]: addVendorInventoryAdStats,
};

export function composeAggregationFunctions(aggFunctions: AggregationFunction[]) {
  let combinedFunctions: AggFunctionCombinator<any> = AggFunctionCombinator.empty;

  while (aggFunctions.length) {
    const func = aggFunctions.shift();
    if (func === undefined) break;
    combinedFunctions = combinedFunctions.with(aggregationMethods[func]);
  }
  return combinedFunctions.agg;
}

class AggFunctionCombinator<T extends object> {
  constructor(public agg: (x: T, y: T) => T) {}

  with<U extends object>(f: (x: U, y: U) => U): AggFunctionCombinator<T | U> {
    return new AggFunctionCombinator(
      (x: T | U, y: T | U) =>
        ({
          ...this.agg(x as T, y as T),
          ...f(x as U, y as U),
        }) as T | U,
    );
  }

  static empty = new AggFunctionCombinator(() => ({}));
}
