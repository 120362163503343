import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { MatTooltipModule, TooltipPosition } from "@angular/material/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";

@Component({
  selector: "app-custom-icon",
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, MatTooltipModule],
  template: `@if (isButton) {
      <button
        class="btn btn-ghost-secondary"
        [matTooltip]="tooltip"
        [matTooltipPosition]="tooltipPos"
        (click)="onClick && onClick()"
        [disabled]="disabled"
      >
        <ng-container *ngTemplateOutlet="iconContent"></ng-container>
      </button>
    } @else {
      <div [matTooltip]="tooltip" [matTooltipPosition]="tooltipPos">
        <ng-container *ngTemplateOutlet="iconContent"></ng-container>
      </div>
    }
    <ng-template #iconContent>
      @if (faIcon) {
        <fa-icon [icon]="faIcon" size="lg"></fa-icon>
      }
      @if (svgIcon) {
        <img [src]="svgIcon" width="23px" height="23px" />
      }
    </ng-template>`,
})
export class CustomIconComponent implements ICellRendererAngularComp {
  faIcon!: IconDefinition;
  svgIcon!: string;
  tooltip!: string;
  tooltipPos: TooltipPosition = "above";
  isButton = false;
  onClick?: () => void;
  disabled = false;

  agInit(
    params: ICellRendererParams & {
      faIcon: IconDefinition;
      tooltip: string;
      tooltipPos: TooltipPosition;
      svgIcon: string;
      isButton: boolean;
      onClick?: () => void;
      disabled?: boolean;
    },
  ): void {
    this.faIcon = params.faIcon;
    this.svgIcon = params.svgIcon;
    this.tooltip = params.tooltip;
    if (params.tooltipPos) this.tooltipPos = params.tooltipPos;
    this.isButton = params.isButton;
    this.onClick = params.onClick;
    this.disabled = params.disabled ?? false;
  }

  refresh(): boolean {
    return false;
  }
}
