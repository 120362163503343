import { Component, EventEmitter, Input, OnInit, Output, signal } from "@angular/core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { AuthService, BillingService, CurrencyService } from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { ToastrService } from "ngx-toastr";
import { AjaxError } from "rxjs/ajax";
import { COUNTRIES } from "../../../countries";
import { SettingStatus } from "../../../models/Billing";
import { Customer, Response, User } from "@front/m19-api-client";

export type Country = {
  name: string;
  code: string;
  vat_regxp?: string;
  vat_ex?: string;
};

@UntilDestroy()
@Component({
  selector: "app-billing-customer",
  templateUrl: "./billing-customer.component.html",
  styleUrls: ["./billing-customer.component.scss"],
})
export class BillingCustomerComponent implements OnInit {
  user?: User;
  _previousCustomer?: Customer;
  _customer?: Customer;
  countries = COUNTRIES;

  countriesOptions: Option<Country>[] = COUNTRIES.map((c) => ({
    label: c.name,
    value: c,
  }));
  selectedCountry = signal<Option<Country> | undefined>(undefined);

  vatRegxpByCountryCode: Map<string, string>;
  vatExByCountryCode: Map<string, string>;
  lockSave = false;
  faSearch = faSearch;

  @Input({ required: true })
  settingStatus!: SettingStatus;

  @Input()
  wizard = true;

  @Input()
  set customer(x: Customer | undefined) {
    if (!x || !x.customerId) {
      this._customer = {};
      this._previousCustomer = undefined;
    } else {
      this._customer = { ...x };
      this._customer.invoices = x.invoices;
      this._previousCustomer = { ...x };
      this._previousCustomer.invoices = x.invoices;
    }
  }

  @Output() customerChange = new EventEmitter<Customer>();

  @Output() onSave = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();

  constructor(
    private authService: AuthService,
    private billingService: BillingService,
    public currencyService: CurrencyService,
    private toasterService: ToastrService,
  ) {
    this.vatRegxpByCountryCode = new Map();
    this.vatExByCountryCode = new Map();

    this.countries.forEach((c) => {
      if (c.vat_regxp) {
        this.vatRegxpByCountryCode.set(c.code, c.vat_regxp);
        this.vatExByCountryCode.set(c.code, c.vat_ex);
      }
    });
  }

  ngOnInit(): void {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = { ...user };
    });

    this.selectedCountry.set(this.getSelectedCountry());
  }

  private getSelectedCountry(): Option<Country> | undefined {
    const country: Country | undefined = this._customer?.country
      ? COUNTRIES.find((c) => c.name === this._customer?.country)
      : undefined;
    if (!country) return undefined;
    return { label: country.name, value: country };
  }

  selectCountry(country: Option<Country>): void {
    this._customer!.country = country.value.code;
    this.selectedCountry.set(country);
  }

  canEditCountry(): boolean {
    if (this.settingStatus == SettingStatus.EDITION || this.settingStatus == SettingStatus.DISPLAY) {
      return false;
    }
    return !this._previousCustomer;
  }

  vatNumberRequired(): boolean {
    return !!this._customer!.country && this.vatRegxpByCountryCode.has(this._customer!.country);
  }

  private isEmpty(value: string | null | undefined): boolean {
    return value == null || value.length === 0;
  }

  isInvalid(): boolean {
    if (this.isEmpty(this._customer?.name)) {
      return true;
    }
    if (this.isEmpty(this._customer?.country)) {
      return true;
    }
    if (this.isEmpty(this._customer?.addressLine1)) {
      return true;
    }
    if (this.isEmpty(this._customer?.city)) {
      return true;
    }
    if (this.isEmpty(this._customer?.postalCode)) {
      return true;
    }
    if (this.vatNumberRequired() && this.isEmpty(this._customer?.vat)) {
      return true;
    }
    return false;
  }

  validateVatNumber(): boolean {
    if (this.isEmpty(this._customer?.vat)) {
      return true;
    }
    const regex = new RegExp(this.vatRegxpByCountryCode.get(this._customer!.country!)!);
    if (!regex.test(this._customer!.vat!)) {
      this.toasterService.error("Your VAT number is not valid.", "Invalid VAT Number");
    }
    return regex.test(this._customer!.vat!);
  }

  hasState(): boolean {
    return this._customer?.country === "US";
  }

  disableSave(): boolean {
    return (
      this.lockSave || this.isInvalid() || (!this.customerChanged() && this.settingStatus != SettingStatus.REGISTRATION)
    );
  }

  customerChanged(): boolean {
    return !equal(this._previousCustomer, this._customer);
  }

  save(): void {
    this.lockSave = true;
    if (!this.customerChanged()) {
      this.close();
      return;
    }
    if (!this.validateVatNumber()) {
      this.lockSave = false;
      return;
    }
    if (!this.vatNumberRequired()) {
      this._customer!.vat = undefined;
    }
    if (!this.hasState()) {
      this._customer!.state = undefined;
    }
    this._customer!.m19UserId = this.user!.userId;
    this._customer!.m19Email = this.user!.email;

    if (!this._previousCustomer) {
      this.billingService.createCustomer(
        this._customer!,
        this.user!.ownedOrganizationId!,
        (response: Response) => {
          this._customer = response.entity as Customer;
          this.customerChange.emit(this._customer);
          this.toasterService.success("Billing info successfully created");
          this.close();
        },
        (error: AjaxError) => {
          this.toasterService.error(error.response ? error.response.message : "Unknown error", error.name);
          this.cancel();
        },
      );
    } else if (this.customerChanged()) {
      this.billingService.updateCustomer(
        this._customer!,
        this._customer!.vat !== this._previousCustomer.vat,
        (response: Response) => {
          this.toasterService.success("Billing info updated");
          this.customerChange.emit(this._customer);
        },
        (error: AjaxError) => {
          this.toasterService.error(error.response ? error.response.message : "Unknown error", error.name);
        },
      );
      this.close();
    } else {
      this.close();
    }
  }

  isEditionMode(): boolean {
    return (
      this.settingStatus == SettingStatus.EDITION ||
      this.settingStatus == SettingStatus.REGISTRATION ||
      this.settingStatus == SettingStatus.CREATION
    );
  }

  close(): void {
    this.lockSave = false;
    this.onSave.emit();
  }

  cancel(): void {
    this.lockSave = false;
    this.onCancel.emit();
  }

  protected readonly untilDestroyed = untilDestroyed;
}
