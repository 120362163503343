import { formatCurrency, formatPercent } from "@angular/common";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { faPauseCircle, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import {
  AccountSelectionService,
  AdStatsData,
  AdStatsWithTargetHistory,
  AuthService,
  DataSet,
  groupBy,
  indexStrategyByDate,
  SbStrategiesService,
  SegmentService,
  SpStrategiesService,
  StatsApiClientService,
  StatsService,
  StrategyService,
  StrategyTargetHistory,
  TacosStrategiesService,
  UserSelectionService,
} from "@front/m19-services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { AgGridAngular } from "@ag-grid-community/angular";
import {
  ColDef,
  ColGroupDef,
  CsvExportParams,
  GetContextMenuItemsParams,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
  ITooltipParams,
  MenuItemDef,
  ModelUpdatedEvent,
  ShouldRowBeSkippedParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "@ag-grid-community/core";
import { ActivityService } from "@m19-board/activities/activity.service";
import { exportGridCsv, getMetricsColDef, GRAPH_ICON, STATUS_BAR } from "@m19-board/grid-config/grid-config";
import { StrategyStats } from "@m19-board/models/Metric";

import {
  AccountMarketplace,
  AccountState,
  AlgoMode,
  CampaignType,
  Currency,
  SbCreative,
  Strategy,
  StrategyType,
} from "@front/m19-api-client";
import { getBasicGridOptions, SIDE_BAR_NO_PIVOT } from "@front/m19-grid-config";
import {
  ACOS,
  AD_CONVERSIONS,
  AD_SALES,
  CLICK_THROUGH_RATE,
  CLICKS,
  CONVERSION_RATE,
  COST,
  CPC,
  IMPRESSIONS,
  Metric,
  ROAS,
} from "@front/m19-metrics";
import {
  AdStatsEx,
  AlgoModeStr,
  Currencies,
  Marketplaces,
  SegmentEx,
  StrategyEx,
  StrategyGroupEx,
  StrategyTypeStr,
} from "@front/m19-models";
import { IBadgeComponent } from "@front/m19-ui";
import {
  addAdStats,
  AggregationFunction,
  convertToCurrency,
  mergeSeveralDates,
  MetricsSelectorLocalStorageKey,
  Utils,
} from "@front/m19-utils";
import { TranslocoService } from "@jsverse/transloco";
import { CampaignTypeBadgeComponent } from "@m19-board/shared/campaign-type-badge/campaign-type-badge.component";
import { ChartRendererComponent } from "@m19-board/shared/chart-renderer/chart-renderer.component";
import { CustomIconComponent } from "@m19-board/shared/custom-icon/custom-icon.component";
import { LinkComponent } from "@m19-board/shared/link/link.component";
import { FblModeComponent, FblModeSize } from "@m19-board/strategies/fbl-mode/fbl-mode.component";
import { StrategyLinkComponent } from "@m19-board/strategies/strategy-link/strategy-link.component";
import { ICON_CHART_LINE, ICON_LIST } from "@m19-board/utils/iconsLabels";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { combineLatest, map, Observable, of, shareReplay, switchMap } from "rxjs";
import { actionColumnProperties, CurrencyColumn } from "../../grid-config/grid-columns";
import { DAILY_BUDGET, MIN_DAILY_SPEND, MONTHLY_BUDGET, TARGET_ACOS } from "@m19-board/models/MetricsDef";
import { ActionButton, ActionButtonsComponent } from "./action-buttons/action-buttons.component";
import { AlgoTargetRendererComponent } from "./algo-target-renderer/algo-target-renderer.component";
import { StrategyDetailStatsModalComponent } from "./strategy-detail-stats-modal/strategy-detail-stats-modal.component";

export interface GlobalStrategyStats extends StrategyStats {
  algoMode: string;
}

@UntilDestroy()
@Component({
  selector: "app-overview-grid",
  template: ` <div class="ag-theme-quartz" style="height: 42rem">
    <ag-grid-angular class="h-[90%]" [gridOptions]="strategyGridOptions" [statusBar]="STATUS_BAR" />
  </div>`,
})
export class OverviewGridComponent implements OnInit {
  readonly METRIC_COLUMNS: Metric<StrategyStats>[] = [
    AD_SALES,
    AD_CONVERSIONS,
    COST,
    ACOS,
    CLICKS,
    IMPRESSIONS,
    CLICK_THROUGH_RATE,
    CONVERSION_RATE,
    CPC,
    ROAS,
  ];

  @Input()
  gridKey: string = "overviewGrid";
  @Input()
  hiddenColumns: string[] = [];
  @Input()
  dataFilter: (globalStrategyStats: GlobalStrategyStats) => boolean = () => true;
  @Input()
  noStrategyLinks = false;

  locale = "fr";
  currency = Currency.EUR;
  isReadOnly = false;
  accountMarketplace?: AccountMarketplace;

  strategyIndex: Map<number, StrategyEx> = new Map();
  segmentIndex: Map<number, SegmentEx> = new Map();
  strategyGroupIndex: Map<number, StrategyGroupEx> = new Map();

  singleStrategyDataSet?: DataSet<AdStatsEx>;

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  private gridData$?: Observable<StrategyStats[]>;
  private dailyPlacementStats$!: Observable<AdStatsEx[]>;
  private previousDailyPlacementStats$!: Observable<AdStatsEx[]>;

  previousDataMap: Map<number, AdStatsEx> = new Map<number, AdStatsEx>(); // <strategyId, aggPreviousData>
  previousDataArrayMap: Map<number, AdStatsEx[]> = new Map<number, AdStatsEx[]>(); // <strategyId, previousData[]>

  tacosPreviousDataMap: Map<number, AdStatsEx> = new Map<number, AdStatsEx>(); // <tacosStrategyGroupId, previousData>
  tacosPreviousDataArrayMap: Map<number, AdStatsEx[]> = new Map<number, AdStatsEx[]>(); // <tacosStrategyGroupId, previousData[]>

  strategyConfigHistory?: Map<number, Map<string, Strategy>>;

  globalStats?: AdStatsEx[];

  // Map used on parent row to get strategy info
  strategyNameOrSub: Map<number, string> = new Map();

  creativesPerStrategy: Map<number, SbCreative[]> = new Map();

  // Col defs common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    useValueFormatterForExport: true,
  };

  readonly gridComponents = {
    notOperatedStrategyLink: () => {
      return this.translocoService.translate("hourly-page.not_operated_campaigns");
    },
    deletedStrategyLink: () => {
      return this.translocoService.translate("hourly-page.deleted_strategy");
    },
  };

  readonly STATUS_BAR = STATUS_BAR;

  public strategyColumnDefs: (ColDef<GlobalStrategyStats> | ColGroupDef<GlobalStrategyStats>)[] = [
    {
      ...actionColumnProperties<GlobalStrategyStats, string>(),
      cellRendererSelector: (params) => {
        const isTotalRow = params.node.isRowPinned();

        let btns: ActionButton[] = [
          {
            icon: ICON_CHART_LINE,
            tooltip: isTotalRow
              ? this.translocoService.translate("overview-grid.display_total_strategy_graph")
              : this.translocoService.translate("overview-grid.display_strategy_graph"),
            onClick: (params: ICellRendererParams) => {
              this.displayStrategyGraph(params, isTotalRow);
            },
          },
        ];

        if (!isTotalRow && params.data?.strategyId && params.data.strategyId > 0) {
          btns = [
            ...btns,
            {
              icon: ICON_LIST,
              tooltip:
                params.data.campaignType === CampaignType.SB
                  ? this.translocoService.translate("overview-grid.open_tactic_placement_and_creative_stats_details")
                  : params.data.campaignType == CampaignType.SD
                    ? this.translocoService.translate("overview-grid.open_tactic_audience_and_placement_stats_details")
                    : this.translocoService.translate("overview-grid.open_tactic_and_placement_stats_details"),
              onClick: (params: ICellRendererParams) => {
                this.displayStrategyPlacementGrid(params.data.strategyId);
              },
            },
          ];
        }

        return {
          component: ActionButtonsComponent,
          params: {
            actionButtons: btns,
          },
        };
      },
    },
    {
      field: "state",
      headerName: this.translocoService.translate("billing-customer.state", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("billing-customer.state"),
      enablePivot: true,
      pinned: "left",
      filter: "agSetColumnFilter",
      suppressAutoSize: true,
      floatingFilter: true,
      cellRendererSelector: (params) => {
        if (!params.value) return undefined;

        return {
          component: CustomIconComponent,
          params: {
            faIcon: params.value === "ENABLED" ? faPlayCircle : faPauseCircle,
            tooltip:
              params.value === "ENABLED"
                ? this.translocoService.translate("common.active_startegy")
                : this.translocoService.translate("common.paused_strategy"),
            tooltipPos: "left",
          },
        };
      },
      cellClassRules: {
        desactivated: (params) => params.value === "PAUSED",
        activated: (params) => params.value === "ENABLED",
      },
      cellStyle: { textAlign: "center" },
    },
    {
      colId: "campaignType",
      pinned: "left",
      filter: "agSetColumnFilter",
      floatingFilter: true,
      headerName: this.translocoService.translate("overview-grid.ad_type", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("overview-grid.ad_type"),
      cellRendererSelector: (params) => {
        if (params.node.isRowPinned()) return undefined;
        if (!params.value) return undefined;
        return {
          component: CampaignTypeBadgeComponent,
          params: {
            campaignType: params.data?.tacosTarget ? undefined : params.value,
            tacosSpStrategyId: params.data?.tacosTarget ? params.data?.strategyId : undefined,
          },
        };
      },
      valueGetter: (params: ValueGetterParams<GlobalStrategyStats>) => {
        if (params.node?.isRowPinned()) return undefined;
        return params.data?.campaignType;
      },
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: this.translocoService.translate("common.strategy", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("common.strategy"),
      colId: "strategyCol",
      field: "strategyName",
      pinned: "left",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      enablePivot: false,
      width: 150,
      cellRendererSelector: (params) => {
        if (params.node.isRowPinned()) return undefined;
        if (!params.data?.strategyId) return { component: "notOperatedStrategyLink" };
        else if (!this.strategyIndex.get(params.data.strategyId)) return { component: "deletedStrategyLink" };
        return {
          component: StrategyLinkComponent,
          params: { strategyId: params.data.strategyId, withCampaignType: false, disableLink: this.noStrategyLinks },
        };
      },
    },
    {
      headerName: this.translocoService.translate("dsp-stats.creativeType", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("dsp-stats.creativeType"),
      colId: "strategyType",
      pinned: "left",
      filter: "agSetColumnFilter",
      floatingFilter: true,
      hide: true,
      valueGetter: (params: ValueGetterParams<GlobalStrategyStats>) => {
        return params.data?.strategy?.strategyType;
      },
      valueFormatter: (params: ValueFormatterParams<GlobalStrategyStats, StrategyType>) => {
        return params.value == StrategyType.LEGACY ? "" : StrategyTypeStr[params.value!];
      },
      filterValueGetter: (params: ValueGetterParams<GlobalStrategyStats>) => {
        if (!params.data?.strategy?.strategyType) return "-";
        return StrategyTypeStr[params.data.strategy.strategyType] ?? "-";
      },
      cellStyle: { textAlign: "center" },
      cellRendererSelector: (params) => {
        if (params.valueFormatted) {
          return {
            component: IBadgeComponent,
            params: { label: params.valueFormatted, color: "gray", size: "xs" },
          };
        } else {
          return undefined;
        }
      },
    },
    {
      headerName: this.translocoService.translate("overview-grid.strategy_group", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("overview-grid.strategy_group"),
      colId: "strategyGroup",
      pinned: "left",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      width: 150,
      hide: true,
      valueGetter: (params: ValueGetterParams<GlobalStrategyStats>) => {
        if (params.data?.strategy?.strategyGroupId) {
          return this.strategyGroupIndex.get(params.data.strategy.strategyGroupId)?.strategyGroupName;
        }
        return undefined;
      },
      cellRendererSelector: (params) => {
        if (!params.value) {
          return undefined;
        }
        return {
          component: LinkComponent,
          params: {
            routerLink: "/strategies/strategy-group/sponsored-product/" + params.data?.strategy?.strategyGroupId,
            target: "_blank",
            content: params.value,
            queryParamsHandling: "merge",
          },
        };
      },
    },
    {
      headerName: this.translocoService.translate("overview-grid.mode", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("overview-grid.mode"),
      floatingFilter: true,
      pinned: "left",
      colId: "algoMode",
      sortable: false,
      filterValueGetter: (params) => {
        if (!params.data?.strategyId) return "";

        const strategy = this.strategyIndex.get(params.data.strategyId);

        return strategy?.constraint ? FblModeComponent.fblMode[strategy?.constraint]?.name : "";
      },
      cellRendererSelector: (params) => {
        if (!params.data?.strategyId) return undefined;

        const strat = this.strategyIndex.get(params.data.strategyId);
        if (strat) return { component: FblModeComponent, params: { strategy: strat, size: FblModeSize.small } };
        return undefined;
      },
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: this.translocoService.translate("sd-strategy-creation.algorithm", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("sd-strategy-creation.algorithm"),
      floatingFilter: true,
      field: "algorithm",
      pinned: "left",
      tooltipValueGetter: (params: ITooltipParams<GlobalStrategyStats, AlgoMode>) =>
        AlgoModeStr[params.value!]?.description,
      valueFormatter: (params: ValueFormatterParams<GlobalStrategyStats, AlgoMode>) =>
        this.translocoService.translate(AlgoModeStr[params.value!]?.shortDescription),
      filterValueGetter: (params: ValueGetterParams<GlobalStrategyStats>) =>
        params.data?.algorithm
          ? this.translocoService.translate(AlgoModeStr[params.data.algorithm! as AlgoMode]?.shortDescription)
          : "",
    },
    {
      headerName: this.translocoService.translate("strategy-table.target", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("strategy-table.target"),

      filter: false,
      sortable: false,
      colId: "targetAcos",
      pinned: "left",
      cellRenderer: AlgoTargetRendererComponent,
      autoHeight: true,
      cellRendererParams: (params: ICellRendererParams<GlobalStrategyStats>) => {
        return {
          locale: this.locale,
          currency: this.accountMarketplace?.marketplace
            ? Marketplaces[this.accountMarketplace.marketplace].currency
            : Currency.USD,
          readonly: this.isReadOnly,
          minBid: this.accountMarketplace?.minBid,
          averageDailyBudget: params.data?.strategy?.dailyBudget,
          organizationId: this.accountMarketplace?.resourceOrganizationId,
        };
      },
    },

    {
      headerName: this.translocoService.translate("algo-mode-selection.acos_target", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("algo-mode-selection.acos_target"),
      colId: "acosTarget",
      field: "acosTarget",
      // hide this column
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      hide: true,
      valueFormatter: (params: ValueFormatterParams<GlobalStrategyStats, number>) => {
        return params.value ? formatPercent(params.value, this.locale, "1.0-0") : "-";
      },
    },
    {
      headerName: this.translocoService.translate("switch-target-algo-modal.suggested_bid", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("switch-target-algo-modal.suggested_bid"),
      colId: "suggestedBid",
      field: "suggestedBid",
      // hide this column
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      hide: true,
      valueFormatter: (params: ValueFormatterParams<GlobalStrategyStats, number>) => {
        return params.value
          ? formatCurrency(params.value, this.locale, Currencies[this.currency].currencySymbol, this.currency, "1.0-0")
          : "-";
      },
    },
    {
      headerName: this.translocoService.translate("metrics.DAILY_BUDGET_title", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("metrics.DAILY_BUDGET_title"),
      colId: "dailyBudget",
      field: "dailyBudget",
      // hide this column
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      hide: true,
      valueFormatter: (params: ValueFormatterParams<GlobalStrategyStats, number>) => {
        return params.value
          ? formatCurrency(params.value, this.locale, Currencies[this.currency].currencySymbol, this.currency, "1.0-0")
          : "-";
      },
    },
    {
      headerName: this.translocoService.translate("overview-grid.monthly_budget", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("overview-grid.monthly_budget"),
      colId: "monthlyBudget",
      field: "monthlyBudget",
      // hide this column
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      hide: true,
      valueFormatter: (params: ValueFormatterParams<GlobalStrategyStats, number>) => {
        return params.value
          ? formatCurrency(params.value, this.locale, Currencies[this.currency].currencySymbol, this.currency, "1.0-0")
          : "-";
      },
    },
    ...getMetricsColDef<GlobalStrategyStats, GlobalStrategyStats>(this.METRIC_COLUMNS).map(
      ({ headerName, ...def }: ColDef<GlobalStrategyStats>) => ({
        ...def,
        headerName: this.translocoService.translate(`metrics.${def.colId}_title`, {}, "en"),
        headerValueGetter: (params: any) => {
          return this.translocoService.translate(`metrics.${params.colDef.colId}_title`);
        },

        cellRendererParams: (params: ICellRendererParams) => {
          return {
            ...(def as ColDef<GlobalStrategyStats>).cellRendererParams(params),
            previousData: this.getPreviousDataNode(params.node),
            currency: this.currency,
            locale: this.locale,
          };
        },
      }),
    ),
    CurrencyColumn,
  ];

  private commonOptions = getBasicGridOptions(this.gridKey, true, false, true, addAdStats, false);
  public strategyGridOptions: GridOptions = {
    ...this.commonOptions,
    context: { componentParent: this },
    columnDefs: this.strategyColumnDefs.filter((c: ColDef) => !this.hiddenColumns.includes(c.colId!)),
    defaultColDef: this.defaultColDef,
    components: this.gridComponents,
    readOnlyEdit: true,
    sideBar: SIDE_BAR_NO_PIVOT,
    getContextMenuItems: (params) => this.getContextMenuItems(params),
    onModelUpdated: (event: ModelUpdatedEvent) => {
      this.commonOptions.onModelUpdated && this.commonOptions.onModelUpdated(event);
    },
    getRowClass: (params) => {
      if (params.node.isRowPinned()) {
        return "grid-row-footer";
      }
      return "";
    },
    onGridReady: (event: GridReadyEvent) => {
      this.gridData$?.subscribe((data: StrategyStats[]) => {
        event.api.setGridOption("rowData", data);
      });
    },
  };

  constructor(
    private authService: AuthService,
    private statsService: StatsService,
    private statsApiClientService: StatsApiClientService,
    private strategyService: StrategyService,
    private tacosStrategyService: TacosStrategiesService,
    private segmentService: SegmentService,
    private userSelectionService: UserSelectionService,
    private modalService: BsModalService,
    private accountSelectionService: AccountSelectionService,
    private activityService: ActivityService,
    private translocoService: TranslocoService,
    private sbStrategyService: SbStrategiesService,
    private spStrategyService: SpStrategiesService,
  ) {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) => this.sbStrategyService.getSbCreativesPerStrategy(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((creatives) => {
        this.creativesPerStrategy = creatives;
      });
  }

  ngOnInit(): void {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) => this.spStrategyService.getStrategyGroups(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((strategyGroupIndex) => {
        this.strategyGroupIndex = strategyGroupIndex;
      });
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((am: AccountMarketplace) => {
        this.accountMarketplace = am;
      });
    combineLatest([
      this.accountSelectionService.readOnlyMode$,
      this.accountSelectionService.singleAccountMarketplaceSelection$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([isReadOnly, am]) => {
        this.isReadOnly = isReadOnly || am.state !== AccountState.BIDDER_ON;
      });
    this.singleStrategyDataSet = new DataSet<AdStatsWithTargetHistory>(
      3,
      [AD_SALES, COST],
      [AggregationFunction.mergeAdStatsWithTargetHistory],
      this.translocoService,
    );
    this.singleStrategyDataSet.metricsOnSameScale = [
      [AD_SALES, COST],
      [ACOS, TARGET_ACOS],
      [COST, MIN_DAILY_SPEND, DAILY_BUDGET, MONTHLY_BUDGET],
    ];

    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      if (this.singleStrategyDataSet) this.singleStrategyDataSet.locale = user.locale;
      this.locale = user.locale;
    });
    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((currency) => {
      if (this.singleStrategyDataSet) this.singleStrategyDataSet.currency = currency;
      this.currency = currency;
      this.agGrid?.api?.redrawRows();
    });

    this.previousDailyPlacementStats$ = combineLatest([
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      this.userSelectionService.periodComparison$,
    ]).pipe(
      switchMap(([am, pc]) => {
        if (!pc?.period) {
          return of([]);
        }
        return combineLatest([
          this.userSelectionService.selectedCurrency$,
          this.statsService.getDailyPlacementStats(am.accountId, am.marketplace, pc.period[0], pc.period[1]),
        ]).pipe(
          map(([currency, data]) => {
            return convertToCurrency(data, currency)!;
          }),
        );
      }),
      shareReplay(1),
    );
    this.previousDailyPlacementStats$.pipe(untilDestroyed(this)).subscribe((previousData) => {
      this.previousDataMap.clear();
      this.previousDataArrayMap.clear();

      Array.from(groupBy<string>(previousData, (x) => x.strategyId?.toString() ?? "").values()).forEach(
        (d: AdStatsEx) => {
          this.previousDataMap.set(d.strategyId!, d);
        },
      );

      previousData.forEach((d: AdStatsEx) => {
        Utils.insertInArrayMap(this.previousDataArrayMap, d.strategyId, d);
      });

      // Have to redraw rows because we need all the row data and not just the cells
      this.agGrid?.api.redrawRows();
    });
    this.dailyPlacementStats$ = combineLatest([
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      this.userSelectionService.dateRange$,
    ]).pipe(
      switchMap(([am, dr]) =>
        combineLatest([
          this.userSelectionService.selectedCurrency$,
          this.statsService.getDailyPlacementStats(am.accountId, am.marketplace, dr[0], dr[1]),
        ]),
      ),
      map(([currency, data]) => convertToCurrency(data, currency)!),
      shareReplay(1),
    );

    this.gridData$ = this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) =>
          combineLatest([
            this.tacosStrategyService.getTacosStrategyIndex(am.accountId, am.marketplace),
            this.dailyPlacementStats$!,
            this.strategyService.getStrategyIndex(am.accountId, am.marketplace),
            this.segmentService.getSegments(am.accountId, am.marketplace),
          ]),
        ),
      )
      .pipe(
        untilDestroyed(this),
        map(([tacosStrategyIndex, data, strategyIndex, segmentIndex]) => {
          this.segmentIndex = segmentIndex;
          this.strategyIndex = new Map(Array.from(strategyIndex.entries()).map(([k, s]) => [k, new StrategyEx(s)]));

          const tacosStrategies = Array.from(tacosStrategyIndex.values());

          const strategyStats = Array.from(groupBy<string>(data, (x) => x.strategyId?.toString() ?? "").values());

          const globalStrategyStats: GlobalStrategyStats[] = strategyStats
            .map((d: AdStatsEx) => {
              const strategy = this.strategyIndex.get(d.strategyId!);

              // get related tacos strategy
              if (strategy?.algoMode === AlgoMode.TACOS_TARGET) {
                strategy.tacosTarget = tacosStrategies.find(
                  (ts) => strategy.strategyId === ts.spStrategyId,
                )?.tacosTarget;
              }
              const strategyName = d.strategyId
                ? this.strategyIndex.has(d.strategyId)
                  ? this.strategyIndex.get(d.strategyId)!.name
                  : this.translocoService.translate("hourly-page.deleted_strategy")
                : this.translocoService.translate("hourly-page.not_operated_campaigns");
              const strategyStats: StrategyStats = toStrategyStats(d, strategy, strategyName ?? "");

              if (strategy?.strategyId) this.strategyNameOrSub.set(strategy.strategyId, strategyStats?.strategyName);

              return {
                ...strategyStats,
                algoMode: strategy ? FblModeComponent.fblMode[strategy.constraint!]?.name : "",
              };
            })
            .filter((stats) => this.dataFilter(stats))
            .sort((a, b) => (b.adSales ?? 0) - (a.adSales ?? 0));

          return globalStrategyStats;
        }),
      );

    this.statsApiClientService.strategyConfigHistory$
      .pipe(untilDestroyed(this))
      .subscribe((configHistory: Strategy[]) => {
        this.strategyConfigHistory = new Map();
        const strategyIds = new Set(configHistory.map((s) => s.strategyId));
        for (const strategyId of strategyIds.values()) {
          this.strategyConfigHistory.set(
            strategyId!,
            indexStrategyByDate(configHistory.filter((x) => x.strategyId == strategyId)),
          );
        }
      });
  }

  private getPreviousDataNode(node: IRowNode<GlobalStrategyStats>): AdStatsEx | undefined {
    if (node.isRowPinned() || !node.data) {
      return undefined;
    }
    if (node.data.tacosStrategyGroupId) {
      return this.tacosPreviousDataMap.get(node.data.tacosStrategyGroupId);
    }
    return this.previousDataMap.get(node.data.strategyId!);
  }

  private displayStrategyPlacementGrid(strategyId: number) {
    const modalOptions: ModalOptions = {
      initialState: {
        strategy: this.strategyIndex.get(strategyId),
        currency: this.currency,
        locale: this.locale,
      },
      class: "modal-xxl modal-dialog-centered",
    };
    this.modalService.show(StrategyDetailStatsModalComponent, modalOptions);
  }

  getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    const data = params.node?.data;

    const result: (string | MenuItemDef)[] = ["copy", "export", "chartRange"];

    result.push("separator");

    result.push({
      name: this.translocoService.translate("overview-grid.display_strategy_graph"),
      icon: `<img width="16" height="16" src="${GRAPH_ICON}"/>`,

      action: () => {
        this.displayStrategyGraph(params);
      },
    });

    return result;
  };

  private displayStrategyGraph(params: ICellRendererParams | GetContextMenuItemsParams, totalGraph = false) {
    const strategyIds: number[] = [];
    const strategies: Strategy[] = [];
    const additionalMetrics: Set<Metric<StrategyStats>> = new Set();
    if (totalGraph) {
      params.api.forEachNodeAfterFilter((n: IRowNode<GlobalStrategyStats>) => {
        if (n.data?.tacosStrategyGroupId) {
          return;
        }
        if (n.data?.strategyId) {
          strategyIds.push(n.data?.strategyId);
        }
        if (n.data?.strategy) {
          strategies.push(n.data?.strategy);
        }
      });
    } else {
      strategyIds.push(params.node?.data.strategyId);
      if (params.node?.data.strategy) {
        strategies.push(params.node?.data.strategy);
      }
    }

    const chartData$ = combineLatest<[AdStatsEx[], AdStatsEx[], StrategyTargetHistory[]]>([
      this.dailyPlacementStats$!,
      this.previousDailyPlacementStats$!,
      // only display acos target history when displaying one strategy graph
      strategyIds.length === 1
        ? this.statsApiClientService.getStrategyTargetHistory(strategyIds[0], this.currency)
        : of([]),
    ]).pipe(
      map(([dailyPlacementStats, previousPeriodDailyPlacementStats, targetHistory]) => {
        const stats: AdStatsEx[] = dailyPlacementStats.filter((d) => strategyIds.includes(d.strategyId!));

        const previousStats = previousPeriodDailyPlacementStats.filter((d) => strategyIds.includes(d.strategyId!));

        for (const target of targetHistory) {
          if (target.dailyBudget !== undefined && !isNaN(target.dailyBudget)) additionalMetrics.add(DAILY_BUDGET);
          if (target.minDailySpend !== undefined && target.minDailySpend !== 0) additionalMetrics.add(MIN_DAILY_SPEND);
          if (target.monthlyBudget !== undefined && !isNaN(target.monthlyBudget)) additionalMetrics.add(MONTHLY_BUDGET);
        }
        const totalData = stats.reduce((prev, curr) => mergeSeveralDates(prev, curr), {});
        const totalPreviousData = previousStats?.reduce((curr, prev) => mergeSeveralDates(curr, prev), {});
        return {
          data: [...targetHistory, ...stats],
          previousData: previousStats,
          totalData,
          totalPreviousData,
        };
      }),
    );
    const focusedStrategy = totalGraph || strategyIds.length > 1 ? "Total" : params.node?.data.strategyName;
    const modalOpts: ModalOptions = {
      initialState: {
        title: `Strategy Graph - ${focusedStrategy}`,
        dataset: this.singleStrategyDataSet,
        metrics: this.METRIC_COLUMNS,
        localStorageKey: MetricsSelectorLocalStorageKey.overviewDetails,
        selectMetricCallback: (metrics: Metric<AdStatsEx>[]) => {
          if ([ACOS, COST].every((i) => metrics.includes(i))) return [...metrics, TARGET_ACOS, ...additionalMetrics];
          if (metrics.includes(ACOS)) {
            return [...metrics, TARGET_ACOS];
          }
          if (metrics.includes(COST)) {
            return [...metrics, ...additionalMetrics];
          }
          return metrics;
        },
        chartData$,
        withEventAnnotations: true,
        annotations$: this.activityService.getStrategiesActivityEventAnnotation(
          this.accountMarketplace!.accountId!,
          this.accountMarketplace!.marketplace!,
          strategies,
        ),
      },
      class: "modal-xxl modal-dialog-centered",
    };
    this.modalService.show(ChartRendererComponent, modalOpts);
  }

  exportGridCsv(fileName: string): void {
    const exportParams: CsvExportParams = {
      shouldRowBeSkipped: (params: ShouldRowBeSkippedParams) => {
        const strategyName = params.node.data.strategyName;
        return (
          !strategyName ||
          strategyName === this.translocoService.translate("hourly-page.not_operated_campaigns") ||
          strategyName === this.translocoService.translate("hourly-page.deleted_strategy")
        );
      },
      processCellCallback: (params) => {
        if (params.column.getId() === "algoMode") {
          const strategy = this.strategyIndex.get(params.node!.data.strategyId)!;
          return FblModeComponent.fblMode[strategy.constraint!]?.name;
        }
        return params.formatValue(params.value);
      },
      columnKeys: this.agGrid?.api.getColumns()?.filter((c) => {
        if (c.getId() == "targetAcos") {
          return false;
        }
        if (c.getId() == "actions") {
          return false;
        }
        return true;
      }),
      fileName: fileName,
    };

    exportGridCsv(this.agGrid?.api, exportParams);
  }

  restoreDefaultColumns(): void {
    this.agGrid.api?.resetColumnState();
  }
}

export function toStrategyStats(
  adStats: AdStatsEx,
  strategy: StrategyEx | undefined,
  strategyName: string | undefined,
): StrategyStats {
  return {
    ...adStats,
    strategyId: strategy?.strategyId ?? adStats.strategyId,
    subStrategyId: undefined,
    strategyName: strategyName ?? "",
    state: strategy?.state,
    campaignType: strategy?.campaignType,
    dayPartingEnabled: strategy?.daypartingPauseHour != null && strategy?.daypartingReactivationHour != null,
    dayPartingPauseHour: strategy?.daypartingPauseHour,
    dayPartingReactivationHour: strategy?.daypartingReactivationHour,
    algorithm: strategy?.algoMode,
    acosTarget: strategy?.algoMode === AlgoMode.ACOS_TARGET ? strategy?.acosTarget : undefined,
    suggestedBid: strategy?.algoMode === AlgoMode.PRODUCT_LAUNCH ? strategy?.suggestedBid : undefined,
    dailyBudget: strategy?.algoMode === AlgoMode.PRODUCT_LAUNCH ? strategy?.dailyBudget : undefined,
    minDailySpend: strategy?.algoMode === AlgoMode.PRODUCT_LAUNCH ? strategy?.minDailySpend : undefined,
    monthlyBudget: strategy?.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET ? strategy?.monthlyBudget : undefined,
    tacosTarget: strategy?.algoMode === AlgoMode.TACOS_TARGET ? strategy?.tacosTarget : undefined,
    today: strategy?.today,
    nextMonthlyBudget: strategy?.nextMonthlyBudget,
    strategy: strategy,
  };
}
