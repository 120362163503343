import { Component, computed, ContentChild, input, signal, TemplateRef } from '@angular/core';
import { PopperComponent } from './popper.component';
import { booleanInput } from '../ibutton/ibutton.component';
import { TranslocoService } from '@jsverse/transloco';

export interface Option<T> {
  label: string;
  value: T;

  [key: string]: any;

  disabled?: boolean;
  category?: string;
  testid?: string;
}

export function getVoidOption(label?: string, category?: string): Option<undefined> {
  return {
    value: undefined,
    label: label ?? '',
    category: category,
  };
}

export type CategorisedOption<T = any> = { category: string | undefined; options: Option<T>[] };

// Popper class design for select and multiselect
// It share all the common props
@Component({
  standalone: true,
  template: '',
})
export class SelectPopperComponent<T> extends PopperComponent {
  @ContentChild('headerSlot') headerSlot!: TemplateRef<null>;
  @ContentChild('leadingSlot') leadingSlot!: TemplateRef<null>;
  @ContentChild('labelSlot') labelSlot!: TemplateRef<null>;
  @ContentChild('optionSlot') optionSlot!: TemplateRef<any>;

  options = input<Option<T>[]>([]);
  placeholder = input(this.translocoService.translate('common.select'));
  searchPlaceholder = input(this.translocoService.translate('common.search'));
  creatable = input(false);
  withSelectAll = input(false, { transform: booleanInput });
  testid = input<string | undefined>(undefined);
  floating = input(true);

  /**
   * Which attribute to display in the menu
   */
  optionAttribute = input<keyof Option<T>>('label');
  /**
   * Which attribute(s) to search in the menu
   */
  searchAttributes = input<string[] | undefined>(undefined);
  _searchAttributes = computed(() => this.searchAttributes() || [this.optionAttribute()]);
  /**
   *  By which attribute to compare options
   */
  by = input<string | undefined>(undefined);
  _by = computed(() => this.by() || this.optionAttribute() || 'label');

  rounded = input(false, { transform: booleanInput });
  withCategories = input(false, { transform: booleanInput });
  searchable = input(false, { transform: booleanInput });
  trailingIcon = input<string>('icon-[mdi--chevron-down]');
  trailingIconSize = input<'md' | 'sm'>('md');

  search = signal<string>('');

  constructor(protected translocoService: TranslocoService) {
    super();
  }

  filteredOptions = computed(() => {
    return this.options().filter((option) => {
      if (!this.searchable()) return true;
      return this._searchAttributes()!.some((field: keyof Option<T>) => {
        const val = this.getNestedProperty(option, field as string);
        if (val && (typeof val === 'string' || typeof val === 'number')) {
          return val.toString().toLowerCase().includes(this.search().toLowerCase());
        }
        return false;
      });
    });
  });

  filteredOptionsWithCategories = computed<CategorisedOption[]>(() => {
    // returns options grouped by category
    const map = new Map<string | undefined, Option<T>[]>();
    for (const option of this.filteredOptions()) {
      if (!map.has(option.category)) {
        map.set(option.category, []);
      }
      map.get(option.category)?.push(option);
    }
    return Array.from(map.entries()).map(([category, options]) => ({
      category,
      options,
    }));
  });

  activeIndex = signal<{ i: number; j: number }>({ i: -1, j: -1 });

  protected setSearch(event: Event) {
    this.search.set((event.target as HTMLInputElement).value);
  }

  protected handleKeyDown(event: KeyboardEvent, options: CategorisedOption[]) {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      const nextIndex = this.getNextIndex(options, this.activeIndex());
      this.activeIndex.set(nextIndex);
      this.scrollToIndex(nextIndex);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      const previousIndex = this.getPreviousIndex(options, this.activeIndex());
      this.activeIndex.set(previousIndex);
      this.scrollToIndex(previousIndex);
    }
  }

  protected getNextIndex(
    options: CategorisedOption[],
    currentIndex: { i: number; j: number },
  ): { i: number; j: number } {
    if (currentIndex.i === -1 && currentIndex.j === -1) return { i: 0, j: 0 };
    if (currentIndex.j < options[currentIndex.i].options.length - 1) {
      return { i: currentIndex.i, j: currentIndex.j + 1 };
    }

    if (currentIndex.i < options.length - 1) {
      return { i: currentIndex.i + 1, j: 0 };
    }

    return this.withSelectAll() ? { i: -1, j: -1 } : { i: 0, j: 0 };
  }

  protected getPreviousIndex(
    options: CategorisedOption[],
    currentIndex: { i: number; j: number },
  ): { i: number; j: number } {
    if (this.withSelectAll() && currentIndex.i === 0 && currentIndex.j === 0) return { i: -1, j: -1 };

    // return last option
    if (currentIndex.i === -1 && currentIndex.j === -1)
      return {
        i: options.length - 1,
        j: options[options.length - 1].options.length - 1,
      };
    // if j is not first, move to previous
    if (currentIndex.j > 0) {
      return { i: currentIndex.i, j: currentIndex.j - 1 };
    }
    // if j is first, move to previous category
    else if (currentIndex.i > 0) {
      return {
        i: currentIndex.i - 1,
        j: options[currentIndex.i - 1].options.length - 1,
      };
    }
    // if j is first and i is first, wrap around
    return {
      i: options.length - 1,
      j: options[options.length - 1].options.length - 1,
    };
  }

  private scrollToIndex(index: { i: number; j: number }) {
    const optionElement = this.popper.nativeElement.querySelector(`[data-option="${index.i}_${index.j}"]`);
    if (optionElement) {
      // if first option, scroll to the top
      if (index.i === 0 && index.j === 0) this.popper.nativeElement.scrollTop = 0;
      else optionElement.scrollIntoView({ block: 'nearest' });
    }
  }

  private getNestedProperty(obj: any, path: string) {
    return path.split('.').reduce((o, key) => (o ? o[key] : undefined), obj);
  }

  protected override showMenu() {
    super.showMenu();
    this.activeIndex.set({ i: -1, j: -1 });

    if (this.searchable()) {
      // Set timeout as animation duration (100ms)
      setTimeout(() => {
        this.popper.nativeElement.querySelector('input')?.focus({ preventScroll: true });
      }, 100);
    } else {
      setTimeout(() => {
        this.popper.nativeElement.querySelector('ul')?.focus({ preventScroll: true });
      }, 100);
    }

    // Scroll menu to the top
    this.popper.nativeElement.scrollTop = 0;
  }

  protected override hideMenu() {
    super.hideMenu();
    // Clear search input
    if (this.searchable()) {
      (this.popper.nativeElement.querySelector('input') as HTMLInputElement).value = '';
      this.search.set('');
    }
  }
}

export { PopperComponent };
