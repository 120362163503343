<app-stats-overlay>
  <div class="metrics-header">
    @if (accountType === AccountType.VENDOR) {
      <app-metric-selector
        [data]="globalData"
        [previousPeriodData]="previousPeriodGlobalData"
        [pageMetrics]="CHART_METRICS_VENDOR"
        [localStorageKey]="MetricsSelectorLocalStorageKey.dashboard + '_' + accountType"
        (chartMetricsChanges)="selectMainMetrics($event)"
      ></app-metric-selector>
    } @else if (accountType === AccountType.SELLER) {
      <app-metric-selector
        [data]="globalData"
        [previousPeriodData]="previousPeriodGlobalData"
        [pageMetrics]="CHART_METRICS_SELLER"
        [localStorageKey]="MetricsSelectorLocalStorageKey.dashboard + '_' + accountType"
        (chartMetricsChanges)="selectMainMetrics($event)"
      ></app-metric-selector>
    }
  </div>

  <ICard>
    <ng-template #header>
      <div class="mt-2 flex items-center justify-between" *transloco="let t">
        <div class="flex min-w-0 flex-1 items-center px-1 py-2">
          <mat-slide-toggle
            color="primary"
            [checked]="displayEventAnnotation$ | async"
            (change)="toggleEventAnnotation($event)"
            class="mr-4"
            [disabled]="disableEventAnnotation"
            ><span class="events-toggle">{{ t("dashboard.activity_events") }}</span></mat-slide-toggle
          >
          <activity-event-filters
            class="flex-1"
            [displayEventAnnotation]="(displayEventAnnotation$ | async) ?? false"
            [disableEventAnnotation]="!!disableEventAnnotation"
            [allEventAnnotationTypes]="allEventAnnotationTypes"
            [allUsers]="(allUsers$ | async) ?? []"
            [allStrategies]="(allStrategies$ | async) ?? []"
          />
        </div>
        <div class="flex shrink-0 items-center">
          @if (dateAggregation$ | async; as dateAggregation) {
            <app-date-aggreation-switch-button
              class="mx-3"
              [selected]="dateAggregation"
              (dateAggSelected)="selectAggregation($event)"
              [displayTitle]="false"
            ></app-date-aggreation-switch-button>
          }
          <div>
            <app-export-button
              tooltipValue="{{ t('dashboard.export_global_as_csv') }}"
              (export)="downloadFile()"
            ></app-export-button>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #body>
      <div class="chart-wrapper">
        <canvas
          baseChart
          class="chart"
          [datasets]="dataSet.chartDataSet"
          type="line"
          [labels]="dataSet.labels"
          [options]="dataSet.lineChartOptions"
        >
        </canvas>
      </div>
    </ng-template>
  </ICard>
  @if (accountType === AccountType.VENDOR) {
    <app-marketplaces
      class="mb-5"
      [mainMetrics$]="mainSingleMetric$"
      [metrics]="CHART_METRICS_VENDOR"
      [accountType]="AccountType.VENDOR"
    ></app-marketplaces>
  } @else if (accountType === AccountType.SELLER) {
    <app-marketplaces
      class="mb-5"
      [mainMetrics$]="mainSingleMetric$"
      [metrics]="CHART_METRICS_SELLER"
      [accountType]="AccountType.SELLER"
    ></app-marketplaces>
  }
</app-stats-overlay>
