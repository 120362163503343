<div class="relative h-full" *transloco="let t">
  <app-spinner [display]="loading" [main]="true" [logSpinnerTime]="false"></app-spinner>
  @if (!loading && noAccountGroupSetup) {
    <div class="warning-popup">
      <div class="alert alert-primary flex flex-col items-center p-4">
        @if (boardType !== BoardType.WHITELABEL) {
          <span>{{ t("stats-overlay.link_advertising_account") }}</span>
        }
        @if (boardType === BoardType.WHITELABEL) {
          <span>{{ t("hourly-stats-overlay.no_marketplace_linked") }}</span>
        }
        @if (boardType !== BoardType.WHITELABEL) {
          <app-link-your-account-button class="mt-4"></app-link-your-account-button>
        }
      </div>
    </div>
  } @else if (!loading && !noAccountGroupSetup && checkOrganicDataAccess && invalidApiAccess) {
    <div class="warning-popup">
      <div class="alert alert-primary flex flex-col items-center p-4">
        <span>{{ t("stats-overlay.grant_access_organic", [logInPlaceForAPIGrantAccess()]) }}</span>
        @if (accountMarketplace?.accessLevel !== AccessLevel.READ) {
          <button class="btn btn-primary mx-1 mt-4" (click)="requestGrant()">
            {{ t("default-layout.grant_access") }}
          </button>
        }
      </div>
    </div>
  } @else if (
    !loading && !noAccountGroupSetup && (!checkOrganicDataAccess || !invalidApiAccess) && !hasAccessToAdvertising
  ) {
    <div class="warning-popup">
      <div class="alert alert-primary flex flex-col p-4">
        <span class="font-bold">Advertising Access Lost</span>
        <span> {{ t("notification-card-switch.cannot_access_api") }}</span>
      </div>
    </div>
  }
  <div
    [class.preview]="noAccountGroupSetup || (invalidApiAccess && checkOrganicDataAccess) || !hasAccessToAdvertising"
    [style.visibility]="loading ? 'hidden' : 'visible'"
    class="h-full"
  >
    <ng-content></ng-content>
  </div>
</div>
