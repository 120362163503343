import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AccountSelectionService, AuthService, SbStrategiesService } from "@front/m19-services";
import { Observable, of } from "rxjs";
import { isSDAvailable } from "../SDActivation";
import { AccountMarketplace, AccountSubType, AccountType, CampaignType } from "@front/m19-api-client";

@UntilDestroy()
@Component({
  selector: "app-sd-manager",
  templateUrl: "./sd-manager.component.html",
})
export class SdManagerComponent implements OnInit {
  accountMarketplace?: AccountMarketplace;
  readonly CampaignType = CampaignType;
  creationUrl = "/strategies/create-sponsored-display";

  constructor(
    private sbStrategiesService: SbStrategiesService,
    private authService: AuthService,
    private accountSelectionService: AccountSelectionService,
    private router: Router,
  ) {
    this.authService.loggedUser$.subscribe((user) => {
      if ((user?.uiVersion ?? 0) > 0) {
        this.creationUrl = "/advertising/sponsored-display/create";
      }
    });
  }

  ngOnInit(): void {
    this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.accountMarketplace = am;
    });
  }

  createStrategy(): void {
    this.router.navigate([this.creationUrl], {
      queryParamsHandling: "merge",
    });
  }

  isKDP() {
    return this.accountMarketplace?.accountSubType === AccountSubType.KDP_AUTHOR;
  }

  hasSponsoredDisplay() {
    return isSDAvailable(this.accountMarketplace?.marketplace);
  }

  hasBrand(): Observable<boolean> {
    if (!this.accountMarketplace) return of(true);
    else if (this.accountMarketplace.accountType == AccountType.VENDOR) return of(true);
    else
      return this.sbStrategiesService.hasBrands(this.accountMarketplace.accountId, this.accountMarketplace.marketplace);
  }
}
