import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CampaignType } from "@front/m19-api-client";
import { BoardType, LayoutSelectorService, Link } from "@m19-board/layout-selector.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { AuthService } from "@front/m19-services";

@UntilDestroy()
@Component({
  templateUrl: "./strategy-limitation-modal.component.html",
})
export class StrategyLimitationModalComponent implements OnInit {
  @Input()
  liveStrategyLimit!: number;
  @Input()
  campaignType!: CampaignType;

  whiteLabel = false;
  linkToUpgrade: Link | undefined = undefined;

  constructor(
    public bsModalRef: BsModalRef,
    private router: Router,
    private layoutSelectorService: LayoutSelectorService,
    private authenticationService: AuthService,
  ) {}

  ngOnInit(): void {
    this.whiteLabel =
      this.layoutSelectorService.getBoardType() === BoardType.WHITELABEL ||
      this.layoutSelectorService.getBoardType() === BoardType.WHITELABEL_SELFSERVICE;
    this.authenticationService.loggedUser$.pipe(untilDestroyed(this)).subscribe((u) => {
      this.linkToUpgrade = this.layoutSelectorService.getUpgrade(u);
    });
  }

  close(): void {
    this.bsModalRef.hide();
  }

  upgrade(event: MouseEvent): void {
    event.preventDefault();
    this.bsModalRef.hide();
    this.router.navigate(["billing-registration"]);
  }
}
