import { Component, inject } from "@angular/core";
import { AccountSelectionService } from "@front/m19-services";
import { map } from "rxjs";
import { toSignal } from "@angular/core/rxjs-interop";

@Component({
  template: `
    <app-stats-overlay>
      @if (accountType(); as accountType) {
        <app-sales-advertising [accountType]="accountType"></app-sales-advertising>
      }
    </app-stats-overlay>
  `,
})
export class SalesAdvertisingPageComponent {
  private readonly accountSelectionService = inject(AccountSelectionService);
  accountType = toSignal(
    this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(map((am) => am.accountType)),
  );
}
