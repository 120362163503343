import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IButtonComponent } from "@front/m19-ui";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";

@Component({
  templateUrl: "./confirm-popup.component.html",
  standalone: true,
  imports: [IButtonComponent, TranslocoRootModule],
})
export class ConfirmPopupComponent {
  @Input()
  public title!: string;

  @Input()
  public message!: string;

  @Input()
  public cancelCta = "Cancel";

  @Input()
  public confirmCta = "Confirm";
  @Input()
  public type: "danger" | "success" = "danger";

  @Output()
  public confirm = new EventEmitter<void>();

  @Output()
  public cancel = new EventEmitter<void>();

  constructor(public bsModalRef: BsModalRef) {}

  clickOnconfirm() {
    this.confirm.emit();
    this.bsModalRef.hide();
  }

  clickOnCancel() {
    this.cancel.emit();
    this.bsModalRef.hide();
  }

  public static getModalOption(
    title: string,
    message: string,
    cancelCta?: string,
    confirmCta?: string,
    cssClass?: string,
  ): ModalOptions {
    return {
      initialState: {
        title,
        message,
        cancelCta,
        confirmCta,
      },
      class: cssClass,
    };
  }
}
