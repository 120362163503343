/* eslint-disable no-console */
import { ApplicationRef, Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { interval } from "rxjs";

@Injectable({ providedIn: "root" })
export class AppUpdateService {
  constructor(
    private appRef: ApplicationRef,
    private updates: SwUpdate,
  ) {}

  public initUpdateService() {
    this.log("Update service init");
    // do nothing is service worker is disabled
    if (!this.updates.isEnabled) {
      this.log("Update service update disabled");
      return;
    }
    this.log("Update service update enabled");
    // this service uses the service worker to poll for new version updates
    // see https://angular.io/guide/service-worker-communications

    // check for app new version every 30 seconds
    // TODO: best pratice is to allow the app to stabilize first, before starting polling for updates with `interval()`,
    // but in the current setup the app never stabilizes because of some background processed
    // const appIsStable$ = this.appRef.isStable.pipe(
    //   first((isStable) => isStable === true)
    // );
    // const everyMinOnceAppIsStable$ = concat(appIsStable$, everyMin$);
    const every5min$ = interval(5 * 60 * 1000);
    every5min$.subscribe(() => {
      this.log("Check for update");
      try {
        this.updates.checkForUpdate().then((updateFound) => {
          if (updateFound) {
            this.log("A new version is available.");
            // activate the new version once available
            this.updates.activateUpdate();
          } else {
            this.log("Already on the latest version.");
          }
        });
      } catch (err) {
        console.error("Failed to check for updates:", err);
      }
    });

    // register to new version availability
    this.updates.versionUpdates.subscribe((evt) => {
      if (evt.type === "VERSION_READY") {
        this.log("New version detected. Reloading the page...");
        // reload the tab once the new versions is activated
        this.updates.activateUpdate().then(() => document.location.reload());
      }
    });
  }

  private log(msg: string) {
    console.info(
      `%c${new Date().toISOString()}%c %c ${msg}`,
      "background: #666; border-radius:0.5em 0 0 0.5em; padding:0.2em 0em 0.1em 0.5em; color: white; font-weight: bold",
      "background: #666; border-radius:0 0.5em 0.5em 0; padding:0.2em 0.5em 0.1em 0em; color: white;",
      "",
    );
  }
}
