<div class="text-input" *transloco="let t">
  <div class="flex items-center">
    <div class="input-group" (click)="$event.stopPropagation()">
      <input
        type="text"
        [disabled]="disabled"
        class="simple-input-append form-control pb-1 pl-2 pr-2 pt-1 text-center"
        [value]="value"
        (input)="value = getValueFromInputEvent($event)"
        (keydown.enter)="sendValue()"
        id="textinput"
        [maxLength]="maxLength"
        (click)="$event.preventDefault()"
      />
      <div class="input-group-append ml-1 gap-x-1">
        @if (value !== oldvalue) {
          <IButton
            variant="soft"
            [icon]="ICON_CHECK"
            tooltipValue="{{ t('common.confirm') }}"
            (onClick)="sendValue(); $event.preventDefault()"
            [disabled]="maxLength !== undefined && value.length > maxLength"
            color="green"
          />
        }
        @if (value !== oldvalue) {
          <IButton
            variant="soft"
            [icon]="ICON_CLOSE"
            tooltipValue="{{ t('common.undo') }}"
            (onClick)="resetValue(); $event.preventDefault()"
            color="red"
          />
        }
        @if (trash && !!oldvalue) {
          <IButton
            variant="soft"
            [icon]="ICON_TRASH"
            tooltipValue="{{ t('common.delete') }}"
            (onClick)="delete(); $event.preventDefault()"
            color="red"
          />
        }
      </div>
    </div>
    <div class="hint">{{ value.length }}/{{ maxLength }}</div>
  </div>
</div>
