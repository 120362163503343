<div class="relative p-3" *transloco="let t">
  <div *ngIf="loading" class="absolute flex h-full w-full items-center justify-center p-3">
    <app-spinner [display]="true"></app-spinner>
  </div>
  <div [style.visibility]="loading ? 'hidden' : 'visible'">
    <div class="mb-4 flex w-full items-center justify-between">
      <div class="w-1/2">
        <div *ngIf="title">
          <h3>{{ title }}</h3>
        </div>
        <div *ngIf="asin && marketplace">
          <app-product-view [asin]="asin" [marketplace]="marketplace"></app-product-view>
        </div>
      </div>
      <div class="flex flex-auto items-center justify-end">
        <app-date-range-selection></app-date-range-selection>
        <button
          class="btn btn-ghost-secondary custom-btn-dark-hover ml-3"
          matTooltip="{{ t('chart-renderer.close_chart') }}"
          (click)="hideChart()"
        >
          <fa-icon [icon]="faTimes"></fa-icon>
        </button>
      </div>
    </div>
    <div>
      <app-metric-selector
        [data]="totalData"
        [previousPeriodData]="totalPreviousData"
        (chartMetricsChanges)="selectMetrics($event)"
        [pageMetrics]="$any(metrics)"
        [localStorageKey]="localStorageKey"
        [displayMode]="this.splitedDisplayMode ? this.managedUnmanaged : this.total"
        [hasManagedToggle]="manageUnmanagedMetrics"
        (displayModeChange)="selectDisplayMode($event)"
      ></app-metric-selector>
    </div>
    <div class="my-3 flex flex-row justify-between">
      <div class="flex flex-auto items-center">
        <div class="flex flex-auto items-center" *ngIf="withEventAnnotations">
          <mat-slide-toggle
            color="primary"
            [checked]="displayEventAnnotation$ | async"
            (change)="toggleEventAnnotation($event)"
            [disabled]="disableEventAnnotation"
            class="mr-4"
            ><span class="events-toggle">{{ t("chart-renderer.events") }}</span></mat-slide-toggle
          >

          <activity-event-filters
            class="flex-1"
            [displayEventAnnotation]="(displayEventAnnotation$ | async) ?? false"
            [disableEventAnnotation]="disableEventAnnotation"
            [allEventAnnotationTypes]="allEventAnnotationTypes"
            [allUsers]="(allUsers | async) ?? []"
          />
        </div>
        <div class="flex items-center justify-end">
          @if (dateAggregation$ | async; as dateAggregation) {
            <app-date-aggreation-switch-button
              [selected]="dateAggregation"
              (dateAggSelected)="selectAggregation($event)"
              [displayTitle]="false"
            ></app-date-aggreation-switch-button>
          }
        </div>
      </div>
    </div>
    @if (!loading) {
      <canvas
        baseChart
        style="height: 100px"
        class="chart"
        [datasets]="dataset.chartDataSet"
        type="line"
        [labels]="dataset.labels"
        [options]="dataset.lineChartOptions"
      >
      </canvas>
    }
  </div>
</div>
