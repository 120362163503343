import { ColDef, GridApi, GridOptions } from "@ag-grid-community/core";
import { Component, Input } from "@angular/core";
import { AccountMarketplace, AlgoMode } from "@front/m19-api-client";
import { getBasicGridOptions } from "@front/m19-grid-config";
import { StrategyEx, StrategyGroupEx } from "@front/m19-models";
import { AccountSelectionService, SpStrategiesService, StrategyService } from "@front/m19-services";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import moment from "moment-timezone";
import { switchMap } from "rxjs";
import { PercentPipe } from "@angular/common";
import { StrategyNameGroupComponent } from "./strategy-name-group.renderer.component";
import { ToastrService } from "ngx-toastr";
import { SwitchRendererComponent } from "./switch-renderer";
import { OptimizerDescriptionRendererComponent } from "./optimizer-description.renderer.component";

export interface PrimeDayConfig {
  primeDayPeriod: string;
  primeDayAfterPeriod: string;
  primeDayOverDate: string;
}

export const eventName = "Promo Days";

export function primeDayConfig(dates: Date[]): PrimeDayConfig {
  const afterDate = moment(dates[1]).add(1, "days");
  const afterDate2 = moment(dates[1]).add(2, "days");
  const overDate = moment(dates[1]).add(3, "days");

  return {
    primeDayPeriod: "from " + moment(dates[0]).format("MMM Do") + " to " + moment(dates[1]).format("MMM Do"),
    primeDayAfterPeriod: "from " + afterDate.format("MMM Do") + " to " + afterDate2.format("MMM Do"),
    primeDayOverDate: overDate.format("MMM Do"),
  };
}

@UntilDestroy()
@Component({
  selector: "app-strategy-boost-grid",
  templateUrl: "./strategy-boost-grid.component.html",
  styleUrls: ["./strategy-boost-grid.component.scss"],
})
export class StrategyBoostGridComponent {
  colDef: ColDef[] = [
    {
      field: "strategyName",
      headerValueGetter: (params: any) => {
        return this.translocoService.translate("sp-strategy-creation.strategy_name");
      },
      flex: 0.7,
      cellClass: "ag-base-cell",
      cellRendererSelector: (params: any) => {
        return {
          component: StrategyNameGroupComponent,
          params: {
            strategy: params.data,
            strategyGrouId: params.data.strategyGroupId,
            strategyGroupName: this.strategyGroupIndex.get(params.data.strategyGroupId)?.strategyGroupName ?? "",
          },
        };
      },
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "acosTarget",
      headerValueGetter: (params: any) => {
        return this.translocoService.translate("algo-mode-selection.acos_target");
      },
      cellClass: "ag-base-cell",
      valueFormatter: (params: any) => {
        return new PercentPipe("en-US").transform(params.value) ?? "";
      },
      flex: 0.2,
    },
    {
      field: "promoDaysBoost",
      flex: 0.2,
      headerValueGetter: (params: any) => {
        return this.translocoService.translate("v2-sidebar.promo_day");
      },
      cellClass: "ag-base-cell",
      cellRendererSelector: (params: any) => {
        return {
          component: SwitchRendererComponent,
          params: {
            strategy: params.data,
            accountMarketplace: this.accountMarketplace,
            toggleBoost: (strategy: StrategyEx) => {
              let primeDayBoost;
              if (!strategy.activatePrimeDayBoost) {
                strategy.activatePrimeDayBoost = true;
                primeDayBoost = 25;
              } else {
                strategy.activatePrimeDayBoost = false;
                primeDayBoost = null;
              }
              this.strategyService
                .updatePrimeDayBoost(
                  this.accountMarketplace.accountId,
                  this.accountMarketplace.marketplace,
                  this.accountMarketplace.resourceOrganizationId!,
                  strategy.strategyId,
                  primeDayBoost ?? undefined,
                )
                .subscribe({
                  next: () => {
                    this.gridApi?.refreshCells();
                    this.toastr.success("Prime Day boost updated");
                  },
                  error: (error) => {
                    this.toastr.error(error.message);
                  },
                });
            },
          },
        };
      },
    },
    {
      field: "ACOS Optimizer",
      flex: 1,
      cellClass: "actions-button-cell",
      headerValueGetter: (params: any) => {
        return this.translocoService.translate("strategy-boost-table.acos_optimizer");
      },
      cellRendererSelector: (params: any) => {
        const config = primeDayConfig([params.data.startDate, params.data.endDate]);

        return {
          component: OptimizerDescriptionRendererComponent,
          params: {
            strategy: params.data,
            accountMarketplace: this.accountMarketplace,
            isReadOnly: this.isReadOnly,
            config: config,
          },
        };
      },
    },
  ];

  gridOptions: GridOptions = {
    defaultColDef: {
      resizable: false,
    },
    ...getBasicGridOptions("promoDayBoost"),
    rowHeight: 100,
    pagination: true,
    paginationPageSize: 20,
    sideBar: false,
    paginationPageSizeSelector: [10, 20, 30, 50],
  };
  gridApi?: GridApi;
  dataSource: StrategyEx[] = [];

  primeDayPeriod?: string;
  primeDayAfterPeriod?: string;
  primeDayOverDate?: string;
  @Input() set data(data: StrategyEx[]) {
    this.dataSource = data;
  }

  @Input({ required: true }) accountMarketplace!: AccountMarketplace;

  @Input() set dates(dates: Date[]) {
    if (dates?.length === 2) {
      const config = primeDayConfig(dates);
      this.primeDayPeriod = config.primeDayPeriod;
      this.primeDayAfterPeriod = config.primeDayAfterPeriod;
      this.primeDayOverDate = config.primeDayOverDate;
    }
  }

  @Input() isReadOnly = false;

  strategyGroupIndex = new Map<number, StrategyGroupEx>();

  constructor(
    private accountSelectionService: AccountSelectionService,
    private translocoService: TranslocoService,
    private spStrategyService: SpStrategiesService,
    private strategyService: StrategyService,
    private toastr: ToastrService,
  ) {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) => this.spStrategyService.getStrategyGroups(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((strategyGroupIndex) => {
        this.strategyGroupIndex = strategyGroupIndex;
        // this.gridApi?.setGridOption("columnDefs", this.colDef());
      });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
  }
}
