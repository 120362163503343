import { Component, EventEmitter, Output } from "@angular/core";
import { ICellRendererParams } from "@ag-grid-community/core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICON_PAUSE, ICON_PLAY } from "@m19-board/utils/iconsLabels";
import { StrategyEx } from "@front/m19-models";

@Component({
  selector: "app-pause-button-renderer",
  templateUrl: "./targetings-renderer.component.html",
  styleUrls: ["./strategy-grid.component.scss"],
})
export class TargetingsRendererComponent implements ICellRendererAngularComp {
  @Output() clicked = new EventEmitter<void>();
  params: any;
  strat!: StrategyEx;
  sdProductTactics: any;
  ICON_PLAY = ICON_PLAY;
  ICON_PAUSE = ICON_PAUSE;

  agInit(params: ICellRendererParams & { sdProductTactics: any }): void {
    this.strat = params.data;
    this.sdProductTactics = params.sdProductTactics;
  }

  refresh(params: ICellRendererParams) {
    return true;
  }
}
