import { NgClass } from "@angular/common";
import { Component, computed, inject, OnInit, output, signal } from "@angular/core";
import { SbAsins, SbCreative } from "@front/m19-api-client";
import { AccountSelectionService, SbStrategiesService } from "@front/m19-services";
import { IButtonComponent, MODAL_DATA, ModalRef } from "@front/m19-ui";
import { TranslocoModule } from "@jsverse/transloco";
import { switchMap } from "rxjs";

interface AdLineResult {
  asin1: { asin: string | undefined; deleted: boolean };
  asin2: { asin: string | undefined; deleted: boolean };
  asin3: { asin: string | undefined; deleted: boolean };
  isDuplicate: boolean;
  isEmpty: boolean;
}

@Component({
  selector: "tacos-sb-asins-check",
  standalone: true,
  imports: [NgClass, IButtonComponent, TranslocoModule],
  templateUrl: "./tacos-sb-asins-check.component.html",
})
export class TacosSbAsinsCheckComponent implements OnInit {
  private readonly sbStrategiesService = inject(SbStrategiesService);
  private readonly accountSelection = inject(AccountSelectionService);

  protected readonly data = inject<TacosSbAsinsCheckData>(MODAL_DATA);
  private readonly modalRef = inject(ModalRef);

  newSbAsins = output<Array<SbAsins>>();

  adLinesResult = signal<Array<AdLineResult>>([]);

  hasAtLeastOneAdLine = computed(() =>
    this.adLinesResult().some(
      (adLine) =>
        (adLine.asin1.asin && !adLine.asin1.deleted) ||
        (adLine.asin2.asin && !adLine.asin2.deleted) ||
        (adLine.asin3.asin && !adLine.asin3.deleted),
    ),
  );

  noAdLineTooltip = computed(() => (this.hasAtLeastOneAdLine() ? "" : "You must have at least one ad line"));

  loading = signal(false);

  ngOnInit() {
    this.loading.set(true);
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(switchMap((am) => this.sbStrategiesService.getSbCreativesPerStrategy(am.accountId, am.marketplace)))
      .subscribe((sbCreatives) => {
        const sbCreative = sbCreatives.get(this.data.sbStrategyId!);
        this.adLinesResult.set(this.processRemoval(sbCreative![0]!));
        this.loading.set(false);
      });
  }

  private processRemoval(creative: SbCreative) {
    const asinsToRemove = this.data.asinsToRemove;
    const sbAsins = creative.creativeAsins!;

    const res: Array<AdLineResult> = [];

    for (const group of sbAsins) {
      const adLineResult: AdLineResult = {
        asin1: { asin: group.asin1, deleted: asinsToRemove.includes(group.asin1!) },
        asin2: { asin: group.asin2, deleted: asinsToRemove.includes(group.asin2!) },
        asin3: { asin: group.asin3, deleted: asinsToRemove.includes(group.asin3!) },
        isDuplicate: false,
        isEmpty: false,
      };

      const adLineResultAsinToArray = this.convertToStringAsins(adLineResult);
      adLineResult.isDuplicate = res.some((adLine) => {
        if (adLine.isEmpty) return false;
        const asinToArray = this.convertToStringAsins(adLine);

        return asinToArray.every((asin, index) => asin === adLineResultAsinToArray[index]);
      });

      adLineResult.isEmpty =
        (!adLineResult.asin1.asin || adLineResult.asin1.deleted) &&
        (!adLineResult.asin2.asin || adLineResult.asin2.deleted) &&
        (!adLineResult.asin3.asin || adLineResult.asin3.deleted);

      res.push(adLineResult);
    }

    return res;
  }

  private convertToStringAsins(asins: AdLineResult) {
    const res = [];
    if (asins.asin1.asin && !asins.asin1.deleted) res.push(asins.asin1.asin);
    if (asins.asin2.asin && !asins.asin2.deleted) res.push(asins.asin2.asin);
    if (asins.asin3.asin && !asins.asin3.deleted) res.push(asins.asin3.asin);
    return res;
  }

  confirmDeletion() {
    const toSbAsins = this.adLinesResult()
      .filter((group) => !group.isEmpty && !group.isDuplicate)
      .map((group) => {
        const asins = this.convertToStringAsins(group);
        return {
          asin1: asins[0],
          asin2: asins[1],
          asin3: asins[2],
        };
      });
    this.modalRef.close(toSbAsins);
  }

  onCancel() {
    this.modalRef.close();
  }
}

export interface TacosSbAsinsCheckData {
  sbStrategyId: number;
  asinsToRemove: Array<string>;
}
