import { Component, OnInit } from "@angular/core";
import { faPauseCircle } from "@fortawesome/free-regular-svg-icons";
import { Marketplace, Strategy, StrategyStateEnum } from "@front/m19-api-client";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.scss"],
})
export class ProductDetailsComponent implements OnInit {
  strategies: Strategy[] | undefined;
  activeStrategies: Strategy[] | undefined;
  asinnumber: string | undefined;
  marketplace: Marketplace | undefined;
  readonly faPauseCircle = faPauseCircle;

  constructor(public ref: BsModalRef) {}

  ngOnInit() {
    this.activeStrategies = this.strategies?.filter((x) => x.state == StrategyStateEnum.ENABLED) ?? [];
  }
}
