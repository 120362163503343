<ng-container *transloco="let t">
  <div class="card-group border-shadow pointer mb-2" #mainContainer>
    @for (m of metrics; track m; let i = $index) {
      <ng-container
        [ngTemplateOutlet]="usedMetricSlot"
        [ngTemplateOutletContext]="{ index: i, metric: m }"
      ></ng-container>
    }
    <div
      matTooltipPosition="above"
      matTooltip="Add metric"
      class="add-metric-button relative flex flex-col items-center justify-center p-2"
      [matMenuTriggerFor]="ddMenu"
      (menuOpened)="setFocus()"
      (menuClosed)="filter = ''"
      [matMenuTriggerData]="{ index: metrics.length, newMetric: true, toggleMode: true }"
    >
      <fa-icon [icon]="faPlus"></fa-icon>
    </div>
  </div>

  <mat-menu #ddMenu="matMenu" class="w-full">
    <ng-template matMenuContent let-index="index" let-newMetric="newMetric" let-toggleMode="toggleMode">
      <div class="dd-input-container flex items-center" (click)="$event.stopPropagation()" *transloco="let t">
        <input
          type="text"
          class="simple-input form-control"
          placeholder="{{ t('common.search') }}"
          [value]="filter"
          (input)="filter = getValueFromInputEvent($event)"
          (click)="$event.stopPropagation()"
          #search
        />
      </div>
      <div
        *ngIf="hasManagedToggle && toggleMode"
        class="m-2 flex w-fit flex-row items-center justify-center align-middle"
      >
        <app-switch-input
          (click)="$event.stopPropagation()"
          [checked]="this.displayMode === this.managedUnmanaged"
          (onInput)="ToggleButtonManagedTotalDisplay()"
        ></app-switch-input>
        <p class="my-auto">
          <b>{{ t("entry-selector.display_detailed_stats") }}</b>
        </p>
      </div>
      <ul class="p-0" *transloco="let t">
        @for (c of _groupedPageMetrics | keyvalue; track c) {
          @if (showMetricGroup(c.value)) {
            <li class="category dd-item" (click)="$event.stopPropagation()">
              {{ t(c.key) }}
            </li>
            @for (m of c.value; track m) {
              @if (showMetric(m)) {
                <li
                  class="dd-item"
                  matTooltip="{{ t('metrics.' + m.id + '_tooltip') }}"
                  matTooltipPosition="right"
                  (click)="selectUnselectMetric(index, m, $event)"
                >
                  <div class="flex w-full items-center justify-between align-middle">
                    {{ t("metrics." + m.id + "_title") }}
                    @if (isMetricInSelector(m)) {
                      <span class="icon-[mdi--check] mr-3" aria-hidden="true"></span>
                    }
                  </div>
                </li>
              }
            }
          }
        }
      </ul>
    </ng-template>
  </mat-menu>

  <ng-template #usedMetricSlot let-index="index" let-metric="metric">
    <div
      class="card relative overflow-hidden"
      (click)="toggleChartMetric(metric)"
      [class.selected]="chartMetrics.includes(metric) && enableChartMetric"
      *transloco="let t"
    >
      @if (!chartMetrics.includes(metric) && metrics.length > 1) {
        <fa-icon
          [icon]="faDelete"
          class="delete-icon absolute"
          (click)="removeMetricFromSelector(index)"
          matTooltip="Remove metric"
        ></fa-icon>
      }
      <div
        class="card-body p-1 text-center"
        [ngClass]="{
          'border-b-[3px]': chartMetrics.includes(metric) && enableChartMetric,
        }"
        [style.border-bottom-color]="getColorForMetric(metric)"
      >
        @if (metric) {
          <div
            [matMenuTriggerFor]="ddMenu"
            [matMenuTriggerData]="{ index: index, toggleMode: false }"
            (click)="$event.stopPropagation()"
            (menuOpened)="setFocus()"
            (menuClosed)="filter = ''"
          >
            <div
              class="metric-title pointer flex items-center justify-center"
              [matTooltip]="t('metrics.' + metric.id + '_tooltip')"
            >
              <div class="">
                <div class="title">{{ t("metrics." + metric.id + "_title") }}</div>
                <div class="subtitle">{{ metric.subtitle }}</div>
              </div>
              <span [class]="ICON_CHEVRON_DOWN" class="ml-1 text-lg text-gray-700"></span>
            </div>
          </div>
        }
        <div class="relative">
          <div
            class="flex w-full flex-col items-center justify-center min-[2000px]:flex-row"
            [class.preview]="(shouldRequestSellingPartnerGrant$ | async) && metric.requireSellingPartnerAccess"
          >
            <div class="metric-value mb-0 py-1" matTooltipPosition="below">
              <div
                [matTooltip]="getTooltipForMetric(metric)"
                matTooltipClass="whitespace-pre max-w-none text-sm px-4 py-2"
                class="mx-1 flex-1 items-center"
              >
                {{ metric | metricFormatPipe: data : locale : currency : undefined : undefined }}
                @if (this.displayMode !== "Total" && isMetricOperatable(metric)) {
                  <span class="-ml-1 items-center justify-center align-middle">
                    <i class="icon-[mdi--information-outline] scale-75"></i>
                  </span>
                }
              </div>
            </div>
            @if (compareEnabledInPage && showCompare && periodComparison) {
              @if (!loadingPreviousPeriodMetrics) {
                <app-metric-evo
                  class="ml-2"
                  [metric]="metric"
                  [currency]="currency"
                  [locale]="locale"
                  [currentData]="data"
                  [previousData]="previousPeriodData_!"
                  [displayMode]="METRIC_EVO_DISPLAY.BADGE"
                />
              } @else {
                <div class="ml-2 text-center">(-)</div>
              }
            }
          </div>
          @if ((shouldRequestSellingPartnerGrant$ | async) && metric.requireSellingPartnerAccess) {
            <div class="absolute top-0 w-full">
              <button
                class="btn btn-warning btn-sm"
                (click)="requestGrant()"
                matTooltip="Grant access to organic data to view {{ metric.title }} trend"
              >
                {{ t("common.grant_access") }}
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
