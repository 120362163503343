@if (title) {
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ title }}</h4>
  </div>
}

<div class="modal-body" *transloco="let t">
  <div class="input-block">
    <label for="name" class="mr-3">{{ t("common.name") }}</label>
    <input
      id="name"
      type="text"
      class="simple-input form-control"
      [maxlength]="maxLength ?? null"
      [formControl]="inputControl"
    />
    <span class="input-block__hint">{{ inputControl.value.length }}/{{ maxLength }}</span>
  </div>
</div>

<div class="modal-footer" *transloco="let t">
  <IButton label="{{ t('common.cancel') }}" color="gray" variant="soft" (onClick)="bsModalRef.hide()" />
  <IButton class="w-2/4" label="{{ t('common.save') }}" type="submit" (onClick)="update()" [block]="true" />
</div>
