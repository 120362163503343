<ng-container *transloco="let t">
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ t("product-group-deletion.product_group_deletion") }}</h4>
  </div>
  @if (productGroups.length === 1) {
    <div class="modal-body">
      <p>{{ t("product-group-deletion.deletion_confrimation", [productGroups[0].productGroupName]) }}</p>
    </div>
  } @else {
    <div class="modal-body">
      <p>{{ t("product-group-deletion.multiple_deletion_confirmation", [productGroups.length]) }}</p>
    </div>
  }
  <div class="modal-footer">
    <IButton label="{{ t('common.cancel') }}" color="gray" variant="ghost" (onClick)="bsModalRef.hide()" />
    <IButton label="{{ t('common.delete') }}" color="red" (onClick)="delete()" />
  </div>
</ng-container>
