import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AccountMarketplaceService, BillingService, DEFAULT_BILLING_PLANS } from "@front/m19-services";
import { ToastrService } from "ngx-toastr";
import { AjaxError } from "rxjs/ajax";
import { AccountMarketplace, BillingApi, BillingPlan, Organization, Plan, Response } from "@front/m19-api-client";

@UntilDestroy()
@Component({
  selector: "app-selfservice-freemium",
  templateUrl: "./selfservice-freemium.component.html",
  styleUrls: ["selfservice-freemium.component.scss"],
})
export class SelfServiceFreemiumComponent implements OnInit {
  organization?: Organization;
  currentBillingPlan?: BillingPlan;
  freemiumBp?: BillingPlan;

  constructor(
    private router: Router,
    private billingService: BillingService,
    private billingApi: BillingApi,
    private accountMarketplaceService: AccountMarketplaceService,
    private toasterService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.billingService.organizationOnwer$.pipe(untilDestroyed(this)).subscribe((o) => {
      this.organization = o?.organization;
      this.currentBillingPlan = o?.getBillingPlan();
    });
    this.billingApi
      .listBillingPlans({ plans: DEFAULT_BILLING_PLANS })
      .pipe(untilDestroyed(this))
      .subscribe((billingPlans: BillingPlan[]) => {
        this.freemiumBp = billingPlans.filter((bp) => bp.plan == Plan.STARTER)[0];
      });
  }

  activate(organizationResources: AccountMarketplace[]): void {
    this.organization!.billingPlan = this.freemiumBp;
    this.billingService.createSubscription(
      this.organization!,
      undefined,
      (success: Response) => {
        this.toasterService.success("Subscription successfully created");
        this.accountMarketplaceService.activateBidder(
          organizationResources,
          () => this.toasterService.success("Accounts activated"),
          (err) => this.toasterService.error(err, "Accounts activation failure"),
        );

        this.backToBilling();
      },
      (error: AjaxError) => {
        this.backToBilling();
        this.toasterService.error(error.response ? error.response.message : "Unknown error", "Subscription failure");
      },
    );
  }

  backToBilling(): void {
    this.router.navigate(["billing"]);
  }
}
