import { Component, computed, EventEmitter, HostBinding, inject, Input, OnInit, Output, signal } from "@angular/core";
import { faList, faThLarge } from "@fortawesome/free-solid-svg-icons";
import { Catalog, ProductEx, ProductGroupEx, StrategyEx } from "@front/m19-models";
import { AccountSelectionService, AsinService, InventoryService } from "@front/m19-services";
import { Utils } from "@front/m19-utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { CommonModule } from "@angular/common";
import { toSignal } from "@angular/core/rxjs-interop";
import { FormControl, FormsModule } from "@angular/forms";
import { MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { InventoryConfig, Marketplace, StrategyAsin } from "@front/m19-api-client";
import {
  IBadgeComponent,
  IButtonComponent,
  ICheckboxComponent,
  IInputComponent,
  IMultiSelectComponent,
  Option,
} from "@front/m19-ui";
import { TranslocoService } from "@jsverse/transloco";
import { PALETTE } from "@m19-board/models/Metric";
import { CsvExportService, CsvField, fieldExtractor, simpleField } from "@m19-board/services/csv-export.service";
import { AsinItemComponent } from "@m19-board/shared/asin-item/asin-item.component";
import { Filter, FilterComponent } from "@m19-board/shared/filter/filter.component";
import { SwitchButtonComponent, SwitchButtonType } from "@m19-board/shared/switch-button/switch-button.component";
import { ExportButtonComponent } from "@m19-board/shared/ui/export-buttons/export-button.component";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";

import { FilterType } from "@m19-board/shared/filter/asinFilter";
import { ICON_SEARCH } from "@m19-board/utils/iconsLabels";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, switchMap } from "rxjs";
import { ViewTypeEnum } from "../strategy-list/strategy-list.component";

interface ParentAsin extends StrategyAsin {
  childAsin: StrategyAsin[];
}

@UntilDestroy()
@Component({
  selector: "app-strategy-asins",
  templateUrl: "./strategy-asins.component.html",
  standalone: true,
  imports: [
    CommonModule,
    IBadgeComponent,
    TranslocoRootModule,
    IButtonComponent,
    RouterModule,
    ICheckboxComponent,
    IMultiSelectComponent,
    IInputComponent,
    SwitchButtonComponent,
    FilterComponent,
    AsinItemComponent,
    MatPaginatorModule,
    MatTooltipModule,
    ExportButtonComponent,
    FormsModule,
  ],
  styleUrls: ["./strategy-asins.component.scss"],
})
export class StrategyAsinsComponent implements OnInit {
  public SwitchButtonType = SwitchButtonType;
  public ViewTypeEnum = ViewTypeEnum;
  readonly faTable = faThLarge;
  readonly faListAlt = faList;
  readonly ASIN_VIEW_TYPE_KEY = "strategy-asins-view-type";
  readonly ICON_SEARCH = ICON_SEARCH;

  @HostBinding("class") class = "inline-block w-full";
  @HostBinding("style") style = "max-height: inherit";

  asinService = inject(AsinService);

  @Input()
  set asins(asins: StrategyAsin[]) {
    this._asins.set([...asins]);
    this.asins$.next(asins);
  }

  _asins = signal<StrategyAsin[]>([]);

  @Input() withDisplayMode = false;
  displayMode: ViewTypeEnum = ViewTypeEnum.LIST;

  @Input() addProductsBtn = false;

  @Input() onlyAddParent = false;

  @Input() isReadOnly = false;

  @Input() disabledAsins: Set<string> = new Set();

  @Input()
  csvExport = true;

  @Input()
  csvExportFileName?: string;

  @Input()
  accountId?: string;

  @Input()
  marketplace?: Marketplace;

  @Input()
  selectable = false;

  @Input()
  itemsShown = 40;

  @Input()
  deletable = true;

  @Input()
  movable = true;

  @Input() strategiesPerAsin?: Map<string, StrategyEx[]>;

  @Input() displaySeedsCounter = false;

  @Input() withBulkFilter = false;

  private _alreadySelectedAsins: StrategyAsin[] = [];
  get alreadySelectedAsins() {
    return this._alreadySelectedAsins;
  }

  @Input() set alreadySelectedAsins(value: StrategyAsin[]) {
    this._alreadySelectedAsins = value ?? [];
    const alreadySelectedSet = new Set(this._alreadySelectedAsins.map((a) => a.asin));
    this.selected = this.selected.filter((a) => !alreadySelectedSet.has(a.asin));
    this.productSelection = {};
    for (const asin of this.selected) {
      this.productSelection[asin.asin] = true;
    }
  }

  @Input() withCustomFieldFilter = false;

  @Input() allowGroupByParentAsin = false;

  @Input() asinEligibility: Map<string, { status: boolean; reason: string }> = new Map();

  @Output()
  onDelete = new EventEmitter<StrategyAsin[]>();

  @Output()
  move = new EventEmitter<StrategyAsin[]>();

  @Output()
  selectedProducts = new EventEmitter<StrategyAsin[]>();
  @Output() addProducts = new EventEmitter<void>();

  asinOffers?: { [key: string]: ProductEx };
  asins$ = new BehaviorSubject<StrategyAsin[]>([]);
  loading = true;
  selected: StrategyAsin[] = [];
  pausedAsins: { [asin: string]: boolean } = {}; // ASIN paused because of low inventory rules
  private filter = new RegExp("", "i");
  pendingBulkAsins = "";
  bulkAsins = signal("");
  bulkFiltering = signal(false);
  readonly filters: Filter<string | ProductGroupEx>[] = [
    {
      type: FilterType.Bulk,
      label: this.translocoService.translate("sp-substrategy-creation.bulk"),
      tooltip: this.translocoService.translate("strategy-asins.filter_by_asins_list"),
      noValuePlaceholder: this.translocoService.translate("strategy-asins.no_asins"),
      options: () => [{ label: "Filter by ASINs List", value: "Bulk" }],
      excludeModeAllowed: false,
      singleValue: true,
      unique: true,
      selectedOptions: [],
      oneShotSelect: true,
    },
    {
      type: FilterType.CustomField1,
      label: this.translocoService.translate("custom-field-edition.custom_field_1"),
      tooltip: this.translocoService.translate(
        "account360-widget-config.filter_stats_on_asins_with_a_specific_value_in_custom_field_1",
      ),
      noValuePlaceholder: this.translocoService.translate("account360-widget-config.no_custom_field_value_available"),
      options: () => this.customFieldsOptions,
      color: PALETTE[1],
      unique: true,
      excludeModeAllowed: false,
      selectedOptions: [],
    },
    {
      type: FilterType.CustomField2,
      label: this.translocoService.translate("custom-field-edition.custom_field_2"),
      tooltip: this.translocoService.translate(
        "account360-widget-config.filter_stats_on_asins_with_a_specific_value_in_custom_field_2",
      ),
      noValuePlaceholder: this.translocoService.translate("account360-widget-config.no_custom_field_value_available"),
      options: () => this.customFieldsOptions,
      color: PALETTE[2],
      unique: true,
      excludeModeAllowed: false,
      selectedOptions: [],
    },
  ];

  queryControl = new FormControl("");
  groupByParentAsin = signal(false);
  query = toSignal(this.queryControl.valueChanges);
  pageEvent = signal<PageEvent>({
    pageIndex: 0,
    pageSize: this.itemsShown,
    length: 0,
  }); // Page change event
  customField1Name?: string;
  customField2Name?: string;

  $customFieldFilter = signal<{ value: string; field: string }[]>([]);

  // Filter asins based on search bar input
  filteredAsins = computed(() => {
    this.filter = new RegExp(this.query() ?? "", "i");
    const isBulkFiltering = this.bulkFiltering();

    if (!this.asinOffers) return this._asins();

    const bulkFilter = this.getBulkFilter(this.bulkAsins());
    if (isBulkFiltering && bulkFilter.length) {
      return this._asins().filter((asin) => bulkFilter.findIndex((filter) => filter === asin.asin) > -1);
    }
    return (this.groupByParentAsin() ? this.asinsGroupByParentAsin() : this._asins()).filter(
      (asin) =>
        (asin.asin.search(this.filter) !== -1 ||
          (this.asinOffers![asin.asin]?.title && this.asinOffers![asin.asin]?.title?.search(this.filter) != -1)) &&
        (this.$customFieldFilter().length === 0 ||
          this.$customFieldFilter().find(
            (f) => f.value === this.asinOffers![asin.asin]?.customField1 && f.field === this.customField1Name,
          ) ||
          this.$customFieldFilter().find(
            (f) => f.value === this.asinOffers![asin.asin]?.customField2 && f.field === this.customField2Name,
          )),
    );
  });

  shownAsins = computed(() => {
    if (!this.pageEvent()) return this.filteredAsins().slice(0, this.itemsShown);
    const start = this.pageEvent().pageIndex * this.pageEvent().pageSize;
    const end = start + this.pageEvent().pageSize;
    return this.filteredAsins().slice(start, end);
  });

  asinsGroupByParentAsin = computed(() => this.aggregateDataByParentAsin(this._asins()));

  productSelection: { [key: string]: boolean } = {};

  private catalog?: Catalog;

  inventoryConfig?: InventoryConfig;
  customFieldsOptions: Option<string | ProductGroupEx>[] = [];
  selectedCustomFields = signal<Option<string | ProductGroupEx>[]>([]);

  constructor(
    private toasterService: ToastrService,
    private inventoryService: InventoryService,
    private csvExportService: CsvExportService,
    private accountSelectionService: AccountSelectionService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.asinOffers = {};
    if (this.withDisplayMode) this.initDisplayBy();

    this.unselectAllproducts();

    if (!this.selectable) this.deletable = false;

    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) =>
          combineLatest<[StrategyAsin[], Catalog]>([
            this.asins$,
            this.asinService.getCatalog(am.accountId, am.marketplace),
          ]),
        ),
      )
      .subscribe(([asins, catalog]) => {
        if (!asins) return;
        this.asinOffers = {};
        if (catalog) {
          this.catalog = catalog;
          for (const asin of asins) {
            this.asinOffers[asin.asin] = this.catalog.asinOffers.get(asin.asin)!;
          }
        }
        this.unselectAllproducts();
        this.loading = false;
      });

    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) =>
          combineLatest<[InventoryConfig, Catalog]>([
            this.asinService.getInventoryConfig(am.accountId, am.marketplace),
            this.asinService.getCatalog(am.accountId, am.marketplace),
          ]),
        ),
      )
      .subscribe(([inventoryConfig, catalog]) => {
        this.inventoryConfig = inventoryConfig;

        this.customField1Name = this.inventoryConfig.customField1Name ?? "Custom Field 1";
        this.customField2Name = this.inventoryConfig.customField2Name ?? "Custom Field 2";

        const customFields: { value: string; field: string }[] = [];

        catalog.products.forEach((p) => {
          if (
            p.customField1 &&
            customFields.findIndex((f) => f.value === p.customField1 && f.field === this.customField1Name) === -1
          )
            customFields.push({ value: p.customField1, field: this.customField1Name! });
          if (
            p.customField2 &&
            customFields.findIndex((f) => f.value === p.customField2 && f.field === this.customField2Name) === -1
          )
            customFields.push({ value: p.customField2, field: this.customField2Name! });
        });
        this.customFieldsOptions = Array.from(customFields).map(
          (v) =>
            ({
              value: v,
              label: v.value,
            }) as Option<any>,
        );
      });

    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) =>
          combineLatest([
            this.asins$,
            this.inventoryService.getSellerInventoryStats(am.accountId, am.marketplace),
            this.asinService.getInventoryRules(am.accountId, am.marketplace),
          ]),
        ),
      )
      .pipe(untilDestroyed(this))
      .subscribe(([asins, inventoryStats, inventoryRules]) => {
        const asinsSet = new Set(asins?.map((s) => s.asin));
        this.pausedAsins = {};
        if (inventoryRules) {
          for (const stat of inventoryStats) {
            if (asinsSet.has(stat.asin) && inventoryRules.execute(stat.asin, stat).shouldPauseAdvertising) {
              this.pausedAsins[stat.asin] = true;
            }
          }
        }
      });
  }

  selectProduct(asin: string, value: boolean) {
    const strategyAsin: StrategyAsin = { asin: asin };
    if (this.selectable && !this.isAsinSelected(strategyAsin)) {
      this.productSelection[strategyAsin.asin] = value;
      this.selected = this.groupByParentAsin()
        ? this.asinsGroupByParentAsin()
            .filter((asin) => this.productSelection[asin.asin])
            .flatMap((a) => (a.childAsin && a.childAsin.length > 0 && !this.onlyAddParent ? a.childAsin : [a]))
            .filter((asin) => !this.disabledAsins.has(asin.asin))
        : this.asins$.value.filter((asin) => this.productSelection[asin.asin]);
      this.selectedProducts.emit(this.selected);
    }
  }

  selectAllProducts() {
    for (const asin of this.filteredAsins()) {
      if (!this.isDisabled({ ...asin, childAsin: [] })) {
        this.productSelection[asin.asin] = true;
      }
    }
    this.selected =
      this.groupByParentAsin() && !this.onlyAddParent
        ? this.asinsGroupByParentAsin()
            .filter((asin) => this.productSelection[asin.asin])
            .flatMap((a) => (a.childAsin && a.childAsin.length > 0 && !this.onlyAddParent ? a.childAsin : [a]))
        : this.asins$.value.filter((asin) => this.productSelection[asin.asin]);

    this.selectedProducts.emit(this.selected);
  }

  unselectAllproducts() {
    this.selected = [];
    this.productSelection = {};
    this.selectedProducts.emit([]);
  }

  deleteAsins() {
    this.onDelete.emit(this.selected);
  }

  moveAsins(): void {
    this.move.emit(this.selected);
  }

  exportCsv() {
    const fields: CsvField<StrategyAsin>[] = [
      simpleField("asin"),
      fieldExtractor("Title", (data) => this.asinOffers![data.asin]?.title ?? ""),
    ];
    if (this.asinEligibility.size > 0) {
      fields.push(fieldExtractor("IsEligible", (data) => (this.isAsinIneligible(data) ? "No" : "Yes")));
      fields.push(
        fieldExtractor("IneligibilityReason", (data) => (this.isAsinIneligible(data) ? this.reason(data) : "")),
      );
    }

    this.csvExportService.exportCsv({ prefix: this.csvExportFileName + "_asins" }, this.asins$.value, fields);
  }

  alreadySelected(asin: ParentAsin): boolean {
    if (this.groupByParentAsin() && asin.childAsin.length > 0) {
      return asin.childAsin.every((a) => this.alreadySelectedAsins.findIndex((s) => s.asin == a.asin) > -1);
    }
    for (let i = 0; i < this.alreadySelectedAsins.length; i++) {
      if (asin.asin == this.alreadySelectedAsins[i].asin) {
        return true;
      }
    }
    return false;
  }

  isAsinSelected(asin: StrategyAsin) {
    return this.alreadySelectedAsins.find((a) => a.asin === asin.asin) !== undefined;
  }

  isAsinIneligible(asin: StrategyAsin) {
    if (!this.asinEligibility.has(asin.asin)) return false;
    return !(this.asinEligibility.get(asin.asin)?.status ?? false);
  }

  reason(asin: StrategyAsin) {
    if (!this.asinEligibility.has(asin.asin)) return "No longer in catalog";
    return this.asinEligibility.get(asin.asin)?.reason ?? "Ineligible";
  }

  isDisabled(asin: ParentAsin) {
    return (
      // (this.asinEligibility.size > 0 && this.isAsinIneligible(asin)) ||
      // this.pausedAsins[asin.asin]
      this.disabledAsins.has(asin.asin) || this.alreadySelected(asin)
    );
  }

  toggleGroupByParentAsin() {
    this.groupByParentAsin.set(!this.groupByParentAsin());
    this.unselectAllproducts();
    this.$customFieldFilter.set([]);
  }

  private aggregateDataByParentAsin(asins: StrategyAsin[]) {
    if (!this.catalog) {
      return [];
    }
    const result: Map<string, ParentAsin> = new Map();
    for (const asin of asins) {
      const parentAsin = this.catalog.getParentAsin(asin.asin);
      if (!parentAsin) {
        result.set(asin.asin, { asin: asin.asin, childAsin: [] });
        continue;
      }
      // aggregate asins
      const existinsgParentAsin = result.get(parentAsin);
      if (!existinsgParentAsin) {
        const toInsert: { asin: string; childAsin: StrategyAsin[] } = {
          asin: parentAsin,
          childAsin: [],
        };
        toInsert.childAsin.push(asin);
        result.set(parentAsin, toInsert);
        continue;
      }
      existinsgParentAsin.childAsin.push(asin);
    }
    return Array.from(result.values());
  }

  displayBy(viewType: ViewTypeEnum): void {
    this.displayMode = viewType;
    localStorage.setItem(this.ASIN_VIEW_TYPE_KEY, viewType.toString());
  }

  initDisplayBy(): void {
    const viewType: string | null = localStorage.getItem(this.ASIN_VIEW_TYPE_KEY);
    if (viewType) {
      this.displayMode = (ViewTypeEnum as any)[viewType];
    }
  }

  customFieldFilter(values: Option<any>[]) {
    this.$customFieldFilter.set(values.map((v) => v.value));
    this.selectedCustomFields.set(values);
  }

  setFilterValues(filters: Filter<string | ProductGroupEx>[]) {
    filters.forEach((filter) => {
      if (filter.type === FilterType.Bulk) {
        this.toggleBulkFiltering();
      }
      if (filter.type === FilterType.CustomField1) {
        this.customFieldFilter(filter.selectedOptions);
      }
      if (filter.type === FilterType.CustomField2) {
        this.customFieldFilter(filter.selectedOptions);
      }
    });
  }

  filterAsins() {
    this.bulkAsins.set(this.pendingBulkAsins);
  }

  private toggleBulkFiltering() {
    this.bulkFiltering.set(!this.bulkFiltering());
  }

  private getBulkFilter(bulkAsins: string): string[] {
    const asins = bulkAsins.split(/[\s,;\n]+/).map((a) => a.trim().toUpperCase());
    const filter: string[] = [];

    for (const asin of asins) {
      if (asin == "") continue;
      if (!Utils.isValidAsin(asin)) {
        this.toasterService.error(asin + ": " + this.translocoService.translate("strategy-asins.invalid_asin"));
        continue;
      }
      if (this._asins().findIndex((a) => a.asin === asin) == -1) {
        this.toasterService.error(asin + ": " + this.translocoService.translate("strategy-asins.asin_not_in_catalog"));
        continue;
      }
      filter.push(asin);
    }
    if (filter.length == 0) {
      return [];
    }
    return filter;
  }
}
