import { Component } from "@angular/core";
import { ICellRendererParams } from "@ag-grid-community/core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { InventoryRules, InventoryStats, RuleExecutionResultEnum } from "@front/m19-models";
import { BsModalService } from "ngx-bootstrap/modal";
import { formatNumber } from "@angular/common";
import { faPause } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-estimated-days-renderer",
  standalone: true,
  template: `<div class="flex items-center justify-between">
    <span class="icon-[material-symbols--pause] {{ stateVisible }} justify-end text-xl"></span>
    <span>{{ product.estimatedDaysOfStock }}</span>
  </div>`,
})
export class EstimatedDaysRendererComponent implements ICellRendererAngularComp {
  params: any;
  product!: InventoryStats;
  inventoryRules!: InventoryRules;
  locale: string = "US";
  faPause = faPause;
  stateVisible = "";
  agInit(
    params: ICellRendererParams & {
      product: InventoryStats;
      inventoryRules: InventoryRules;
      locale: string;
    },
  ): void {
    this.product = params.product;
    this.inventoryRules = params.inventoryRules;
    this.locale = params.locale;
    this.stateVisible = this.inventoryRules.execute(params.data.asin, params.data as InventoryStats)
      .shouldPauseAdvertising
      ? ""
      : "invisible";
  }

  refresh(params: ICellRendererParams) {
    return true;
  }

  formatNumber(value?: number) {
    return value ? formatNumber(value, this.locale, "1.0-0") : "";
  }
}
