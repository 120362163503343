<ng-container *transloco="let t">
  @if (selectedStoreName) {
    <div
      class="relative mt-1 w-full overflow-hidden rounded-lg bg-gray-50 p-4 text-gray-500 ring-1 ring-inset ring-gray-500 ring-opacity-25"
    >
      <div class="flex items-start gap-3">
        <div class="w-0 flex-1">
          <p class="m-0 text-sm">
            {{ t("store-spotlight-pages.selected_store") }} <span class="font-medium">{{ selectedStoreName }}</span>
          </p>
        </div>
      </div>
    </div>
  }

  @if (!noAsinsFound) {
    <div
      class="cdk-boudary mt-4 flex flex-col divide-y rounded-md border border-gray-200 bg-white"
      cdkDropList
      (cdkDropListDropped)="drop($event)"
    >
      @for (asinControl of asinOrder; track asinControl; let i = $index) {
        <div cdkDrag cdkDragRootElement=".drag-item" cdkDragBoundary=".cdk-boudary" class="flex rounded-md bg-white">
          <div class="flex cursor-move flex-col items-center justify-between border-r p-1" cdkDragHandle>
            <span class="icon-[mdi--drag] text-3xl text-gray-400"> </span>
            <span class="font-medium text-gray-500">{{ i + 1 }}</span>
            <span class="icon-[mdi--drag] text-3xl text-gray-400"> </span>
          </div>
          <ng-template
            [ngTemplateOutlet]="productTemplate"
            [ngTemplateOutletContext]="{ p: asinControl, i }"
          ></ng-template>
        </div>
      }
    </div>
  } @else {
    <div class="relative mt-2 w-full overflow-hidden rounded-lg bg-orange-50 p-4 text-orange-500">
      <div class="tems-start flex gap-3">
        <span class="icon-[material-symbols--error-circle-rounded-outline] size-5 shrink-0"></span>
        <div class="w-0 flex-1">
          <div class="mt-0 text-sm leading-5 opacity-90">
            {{ t("store-spotlight-pages.no_store_page") }}
          </div>
        </div>
      </div>
    </div>
  }

  <ng-template #productTemplate let-p="p" let-i="i">
    <div class="drag-item flex flex-1 flex-col rounded-md">
      <div class="flex items-center justify-between border-b px-2 py-1">
        <a
          class="m-0 text-xs font-medium !text-blue-700 hover:underline"
          [href]="p.controls.storePage.value?.url"
          target="_blank"
        >
          {{ getPageName(p.controls.storePage.value) }}
        </a>
        <IButton
          [matMenuTriggerFor]="pageMenu"
          label="{{ t('store-spotlight-pages.change_page') }}"
          color="gray"
          variant="ghost"
          size="xs"
          icon="icon-[mdi--exchange]"
        />
        <mat-menu #pageMenu="matMenu">
          <div class="h-full overflow-hidden">
            <div class="border-b bg-white p-2" (click)="$event.stopPropagation()">
              <IInput
                icon="icon-[mdi--search]"
                [control]="searchStorePageFilter"
                placeholder="{{ t('store-spotlight-pages.search_by_name') }}"
                autocomplete="off"
                autoFocus
              />
            </div>
            <ul class="flex flex-auto flex-col overflow-y-auto">
              @for (s of _storePagesOptions! | textFilter: filterAccessValue : searchStorePageFilter.value!; track s) {
                <li
                  class="dd-item"
                  (click)="changeStorePage(p, s.value)"
                  [class.disabled]="!s.value.asinList?.length || isPageSelected(s.value)"
                >
                  {{ s.label }}
                </li>
              } @empty {
                <span class="my-2 text-center font-medium text-gray-500">{{
                  t("store-spotlight-pages.no_results_found")
                }}</span>
              }
            </ul>
          </div>
        </mat-menu>
      </div>
      <div class="flex items-center gap-3 p-3">
        <div class="max-w-min rounded-md border p-1">
          <app-product-thumbnail
            [matTooltip]="p.controls.asin.value"
            class="sensitive-data [&_img]:rounded-sm"
            [marketplace]="marketplace!"
            [asin]="p.controls.asin.value"
            customSizeClass="size-16"
          />
        </div>
        <div class="flex-1">
          <IInput [control]="p.controls.title" [counter]="50" label="Title" />
          <IButton
            label="{{ t('store-spotlight-pages.change_image') }}"
            class="mt-1"
            color="gray"
            variant="ghost"
            size="xs"
            icon="icon-[mdi--exchange]"
            (click)="openAsinList(p)"
          />
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #asinModalTemplate>
    <div class="modal-body flex max-h-[60vh] flex-col p-0">
      <div class="border-b p-4">
        <h4 class="m-0 font-medium text-gray-700">
          {{
            focusedAsinControl?.controls?.storePage ? getPageName(focusedAsinControl!.controls!.storePage!.value) : ""
          }}
          <span class="font-normal text-gray-400">{{
            t("store-spotlight-pages.filter_found", { count: filteredAsinList.length })
          }}</span>
        </h4>
      </div>
      <div class="border-b p-4">
        <IInput
          icon="icon-[mdi--search]"
          [control]="searchAsinFilter"
          placeholder="{{ t('store-spotlight-pages.search_by_asin') }}"
          autocomplete="off"
          autoFocus
        />
      </div>
      <div class="flex-auto overflow-y-auto p-3">
        @for (a of filteredAsinList; track a) {
          <div
            class="checkbox-card mb-1"
            [ngClass]="{ 'checkbox-card-selected': isAsinSelectedForPage(a) }"
            (click)="
              focusedAsinControl?.controls &&
                focusedAsinControl!.controls['asin'] &&
                focusedAsinControl!.controls!['asin'].setValue(a)
            "
          >
            <div class="w-100 flex items-center justify-between">
              <app-product-thumbnail [asin]="a" [marketplace]="marketplace!" [smallImg]="true" />
              <div>{{ a }}</div>
              <div [class.checked]="isAsinSelectedForPage(a)" class="radio-btn shrink-0">
                <div></div>
              </div>
            </div>
          </div>
        } @empty {
          <div class="flex h-full items-center justify-center">
            <p class="m-0 text-gray-500">{{ t("store-spotlight-pages.no_products_found") }}</p>
          </div>
        }
      </div>
    </div>
    <div class="flex justify-end !border-t p-4">
      <IButton
        label="{{ t('common.close') }}"
        color="gray"
        variant="soft"
        (click)="storeAsinModal && storeAsinModal.hide()"
      />
    </div>
  </ng-template>
</ng-container>
