import { Component, Input } from "@angular/core";
import { ProductGroupUploadResult } from "@m19-board/strategies/strategy-bulk-upload-modal/bulk-import.service";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-product-group-bulk-upload-result-modal",
  templateUrl: "./product-group-bulk-upload-result-modal.component.html",
})
export class ProductGroupBulkUploadResultModalComponent {
  @Input()
  public uploadResult!: ProductGroupUploadResult;
  @Input()
  public updatedEntity?: string;

  successDetailsVisible = false;
  errorDetailsVisible = false;

  constructor(public bsModalRef: BsModalRef) {}

  toggleSuccessDetails(): void {
    this.successDetailsVisible = !this.successDetailsVisible;
  }

  toggleErrorDetails(): void {
    this.errorDetailsVisible = !this.errorDetailsVisible;
  }
}
