import { Injectable } from '@angular/core';
import { map, Observable, switchMap, take, tap, throwError } from 'rxjs';
import {
  Marketplace,
  MatchType,
  Segment,
  StrategyApi,
  Targeting,
  UpdateSegmentItemsActionEnum,
} from '@front/m19-api-client';
import { Constant } from './constant';
import { AccountMarketplaceWritableCache, catchAjaxError, Utils } from '@front/m19-utils';
import { SegmentEx } from '@front/m19-models/SegmentEx';
import { StrategyEx } from '@front/m19-models/StrategyEx';

@Injectable({
  providedIn: 'root',
})
export class SegmentService {
  private readonly segmentCache = new AccountMarketplaceWritableCache<Map<number, SegmentEx>>(
    (accountId, marketplace) => this.loadSegments(accountId, marketplace),
  );

  constructor(private strategyApi: StrategyApi) {}

  public getSegments(accountId: string, marketplace: Marketplace): Observable<Map<number, SegmentEx>> {
    return this.segmentCache.get({ accountId, marketplace });
  }

  private loadSegments(accountId: string, marketplace: Marketplace): Observable<Map<number, SegmentEx>> {
    return this.strategyApi.listSegments({ accountId, marketplace }).pipe(
      catchAjaxError(),
      map((segments: Segment[]) => {
        const segmentIndex = new Map<number, SegmentEx>();
        for (const segment of segments) {
          segmentIndex.set(segment.segmentId!, new SegmentEx(segment));
        }
        return segmentIndex;
      }),
    );
  }

  private addSegmentToCache(accountId: string, marketplace: Marketplace, segment: SegmentEx) {
    this.segmentCache.update({ accountId, marketplace }, (previous) => {
      previous.set(segment.segmentId, segment);
      return previous;
    });
  }

  public createSegment(accountId: string, marketplace: Marketplace, name: string): Observable<SegmentEx> {
    name = name.trim();
    if (name == '') {
      return throwError(() => 'Segment Name cannot be empty');
    }
    return this.segmentCache.get({ accountId, marketplace }).pipe(
      take(1),
      switchMap((segments) => {
        if (Array.from(segments.values()).find((x) => x.name.toLocaleUpperCase() == name.toLocaleUpperCase())) {
          return throwError(() => `Segment with name "${name}" already exists`);
        }
        if (!Constant.nameRegexp.test(name)) {
          return throwError(
            () => 'Invalid character used, Segment Name can only use characters allowed in Amazon campaign names',
          );
        }
        return this.strategyApi
          .createSegment({ accountId: accountId, marketplace: marketplace, name: name })
          .pipe(catchAjaxError('Error creating Segment ' + name + ': '));
      }),
      map((response) => {
        const segment = response.entity as Segment;
        const newSegment = new SegmentEx(segment);
        this.addSegmentToCache(accountId, marketplace, newSegment);
        return newSegment;
      }),
    );
  }

  public deleteSegment(accountId: string, marketplace: Marketplace, segmentId: number): Observable<void> {
    return this.segmentCache.get({ accountId, marketplace }).pipe(
      take(1),
      switchMap((segments) => {
        const segment = segments.get(segmentId);
        if (!segment) {
          return throwError(() => 'Segment does not exist');
        }
        return this.strategyApi.deleteSegment({
          accountId: segment.accountId,
          marketplace: segment.marketplace,
          segmentId: segment.segmentId,
        });
      }),
      catchAjaxError(),
      tap(() => {
        this.segmentCache.update({ accountId, marketplace }, (previous) => {
          previous.delete(segmentId);
          return previous;
        });
      }),
      map(() => void 0),
    );
  }

  public getAllowedSegmentsForStrategy(strategy: StrategyEx): Observable<SegmentEx[]> {
    const forbiddenSegments = new Set(strategy.tactics.map((x) => x.segmentId));
    return this.segmentCache
      .get({ accountId: strategy.accountId, marketplace: strategy.marketplace })
      .pipe(map((segments) => Array.from(segments.values()).filter((x) => !forbiddenSegments.has(x.segmentId))));
  }

  public deleteItemsFromSegment(
    accountId: string,
    marketplace: Marketplace,
    segmentId: number,
    items: Targeting[],
  ): Observable<SegmentEx> {
    return this.segmentCache.get({ accountId, marketplace }).pipe(
      take(1),
      switchMap((segments) => {
        const segment = segments.get(segmentId);
        if (!segment) {
          return throwError(() => `Error updating Segment: invalid segmentId ${segmentId}`);
        }
        return this.deleteSegmentItemsFromDb(segment, items);
      }),
    );
  }

  public updateSegmentName(
    accountId: string,
    marketplace: Marketplace,
    segmentId: number,
    name: string,
  ): Observable<SegmentEx> {
    return this.segmentCache
      .get({
        accountId,
        marketplace,
      })
      .pipe(
        take(1),
        switchMap((segments) => {
          const segment = segments.get(segmentId);
          if (!segment) {
            return throwError(() => 'Segment does not exist');
          }
          const newName = name.trim();
          if (newName == '') {
            return throwError(() => new Error('Segment Name cannot be empty'));
          }
          if (!Constant.nameRegexp.test(newName)) {
            return throwError(
              () =>
                new Error(
                  'Invalid character used, Segment Name can only use characters allowed in Amazon campaign names',
                ),
            );
          }
          if (Array.from(segments.values()).find((x) => x.name.toLocaleUpperCase() == newName.toLocaleUpperCase())) {
            return throwError(() => new Error('Segment ' + segment.name + ' already exists'));
          }
          return this.strategyApi.updateSegment({
            accountId: segment.accountId,
            marketplace: segment.marketplace,
            segmentId: segment.segmentId,
            name: newName,
          });
        }),
        catchAjaxError(`Error updating segment name ${name}: `),
        map((response) => {
          const segment = response.entity as Segment;
          const modifiedSegment = new SegmentEx(segment);
          this.addSegmentToCache(accountId, marketplace, modifiedSegment);
          return modifiedSegment;
        }),
      );
  }

  private addSegmentItemsInDb(
    accountId: string,
    marketplace: Marketplace,
    segment: SegmentEx,
    segmentItems: Targeting[],
  ): Observable<SegmentEx> {
    return this.strategyApi
      .updateSegmentItems({
        accountId: segment.accountId,
        marketplace: segment.marketplace,
        segmentId: segment.segmentId,
        action: UpdateSegmentItemsActionEnum.ADD,
        targeting: segmentItems,
      })
      .pipe(
        map(() => {
          segment.addItems(segmentItems);
          this.addSegmentToCache(accountId, marketplace, segment);
          return segment;
        }),
        catchAjaxError('Error adding items to Segment ' + segment.name + ': '),
      );
  }

  private deleteSegmentItemsFromDb(segment: SegmentEx, segmentItems: Targeting[]): Observable<SegmentEx> {
    return this.strategyApi
      .updateSegmentItems({
        accountId: segment.accountId,
        marketplace: segment.marketplace,
        segmentId: segment.segmentId,
        action: UpdateSegmentItemsActionEnum.DELETE,
        targeting: segmentItems,
      })
      .pipe(
        catchAjaxError('Error removing items from Segment ' + segment.name + ': '),
        map(() => {
          const dic: Record<MatchType, any> = {
            [MatchType.asinSameAs]: {},
            [MatchType.exact]: {},
            [MatchType.phrase]: {},
          };
          for (const item of segmentItems) {
            dic[item.matchType][item.targetingValue] = true;
          }

          // @ts-ignore
          segment.items = segment.items.filter((x) => !dic[x.matchType][x.targetingValue]); // keep items not in dic;
          return segment;
        }),
      );
  }

  public addAsinsToSegment(
    accountId: string,
    marketplace: Marketplace,
    segmentId: number,
    asins: string[],
  ): Observable<SegmentEx> {
    return this.segmentCache
      .get({
        accountId,
        marketplace,
      })
      .pipe(
        take(1),
        switchMap((segments) => {
          const segment = segments.get(segmentId);
          if (!segment) {
            return throwError(() => new Error(`Error updating Segment: invalid segmentId ${segmentId}`));
          }
          const initialSize = segment.items.filter((x) => x.matchType == 'asinSameAs').length;
          let count = 0;
          const items = [];
          for (let asin of asins) {
            if (initialSize + count < Constant.maxAsinBySegment) {
              asin = Utils.normalizeASIN(asin);
              if (asin == '') continue;
              if (!Utils.isValidAsin(asin)) {
                return throwError(() => new Error(asin + ': Invalid ASIN'));
              } else if (segment.items.find((x) => x.targetingValue == asin) != undefined) {
                return throwError(() => new Error(asin + ': Duplicate'));
              } else if (items.find((x) => x.targetingValue == asin) != undefined) {
                return throwError(() => new Error(asin + ': Duplicate'));
              } else {
                items.push({
                  matchType: MatchType.asinSameAs,
                  targetingValue: asin,
                });
                count++;
              }
            } else {
              return throwError(() => new Error('You have reached the maximum number of ' + Constant.maxAsinBySegment));
            }
          }

          return this.addSegmentItemsInDb(accountId, marketplace, segment, items);
        }),
      );
  }

  public addKeywordsToSegment(
    accountId: string,
    marketplace: Marketplace,
    segmentId: number,
    keywordsToAdd: Targeting[],
  ): Observable<SegmentEx> {
    return this.segmentCache
      .get({
        accountId,
        marketplace,
      })
      .pipe(
        take(1),
        switchMap((segments) => {
          const segment = segments.get(segmentId);
          if (!segment) {
            return throwError(() => new Error(`Error updating Segment: invalid segmentId ${segmentId}`));
          }
          const { addedKeywords, errors } = SegmentService.checkKeywords(
            keywordsToAdd,
            segment.items.filter((x) => x.matchType == 'phrase' || x.matchType == 'exact'),
          );
          if (errors.length > 0) {
            return throwError(() => errors.join('. '));
          }
          return this.addSegmentItemsInDb(accountId, marketplace, segment, addedKeywords);
        }),
      );
  }

  public static checkKeywords(
    keywords: Targeting[],
    existingKeywords: Targeting[],
    max = Constant.maxKeywordBySegment,
  ) {
    const initialSize = existingKeywords.length;
    let count = 0;
    const errors: string[] = [];
    const items = [...existingKeywords];
    const addedKeywords = [];
    for (const keyword of keywords) {
      if (initialSize + count < max) {
        const normalizedKeyword = Utils.normalizeKeyword(keyword.targetingValue);
        if (normalizedKeyword == '') continue;
        const reason = Utils.isValidKeyword(normalizedKeyword, keyword.matchType);
        if (reason != '') {
          errors.push(keyword.targetingValue + ': ' + reason);
        } else if (items.find((x) => x.targetingValue == normalizedKeyword) != undefined)
          errors.push(keyword.targetingValue + ': Duplicate');
        else {
          const toAdd = {
            matchType: keyword.matchType,
            targetingValue: normalizedKeyword,
          };
          items.push(toAdd);
          addedKeywords.push(toAdd);
          count++;
        }
      } else {
        errors.push(
          'You have reached the maximum number of ' +
            max +
            ' keywords, the last ' +
            (keywords.length - errors.length - count) +
            ' keyword(s) could not be added.',
        );
        break;
      }
    }
    return {
      keywords: items,
      addedKeywords: addedKeywords,
      errors: errors,
    };
  }
}
