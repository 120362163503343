import { AfterViewInit, Component, ElementRef, OnInit, signal, ViewChild } from "@angular/core";
import { ActivatedRoute, Router, UrlSegment } from "@angular/router";
import { AccountMarketplace, SearchTermRank, User } from "@front/m19-api-client";
import { Option } from "@front/m19-ui";
import { ICON_CHEVRON_DOWN } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, combineLatest, switchMap, tap } from "rxjs";
import { scrollToRight } from "../../../keyword-tracker/keyword-tracking-timeline/keyword-tracking-timeline.component";
import { PALETTE } from "../../../models/Metric";
import { ProductTrackerData, ProductTrackerService } from "../../../services/product-tracker.service";
import { ProductTrackerRow, TrackedSearchTerm } from "../product-tracker.component";
import {
  AccountSelectionService,
  AuthService,
  BrandAnalyticsService,
  RANK_OPTIONS_ARRAY,
  RANK_OPTIONS_DESC,
  RankOption,
} from "@front/m19-services";

@UntilDestroy()
@Component({
  selector: "app-product-tracker-tracked-keywords",
  templateUrl: "./product-tracker-tracked-keywords.component.html",
  styleUrls: ["./product-tracker-tracked-keywords.component.scss"],
})
export class ProductTrackerTrackedKeywordsComponent implements OnInit, AfterViewInit {
  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;
  @ViewChild("datavizScroll") datavizScroll!: ElementRef;
  @ViewChild("asinListScroll") asinListScroll!: ElementRef;

  tableView = true;
  searchTermsOnGraph: string[] = [];
  hoveredSearchTerm?: string;
  searchTermFilter = "";
  productListFilter = "";
  productList: string[] = [];
  searchTermRanks: TrackedSearchTerm[] = [];
  sfrBySearchTerm = new Map<string, number>();
  loading$ = new BehaviorSubject(true);
  locale?: string;
  accountMarketplace?: AccountMarketplace;
  selectedAsin?: string;
  rankOption: RankOption = RankOption.ORGANIC;
  productRow?: ProductTrackerRow;
  productTrackerUrl = "/product-tracker";

  childAsinsOptions = signal<Option<string>[]>([]);

  constructor(
    private productTrackerService: ProductTrackerService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private brandAnalyticsService: BrandAnalyticsService,
    private accountService: AccountSelectionService,
  ) {
    this.authService.loggedUser$.subscribe((user) => {
      if ((user?.uiVersion ?? 0) > 0) {
        this.productTrackerUrl = "/product-center/product-tracker";
      }
    });
  }

  ngOnInit() {
    this.accountService.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((am: AccountMarketplace) => (this.accountMarketplace = am));

    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((u: User) => (this.locale = u.locale));

    combineLatest<[UrlSegment[], ProductTrackerData]>([
      this.activatedRoute.url,
      this.productTrackerService.productTrackerData$,
    ])
      .pipe(
        untilDestroyed(this),
        tap(() => this.loading$.next(true)),
        switchMap(([segments, data]) => {
          const product = segments[1].toString(); // retrieve product from url path
          this.selectedAsin = product;

          this.productList = data.data.map((d) => d.asin!);
          this.productRow = data.data.find((d) => d.asin === product);
          if (!this.productRow) {
            this.productRow = data.parentData.get(product);
            if (!this.productRow) this.router.navigate([this.productTrackerUrl]);
          }

          this.childAsinsOptions.set(this.productRow?.childAsins?.map((a) => ({ label: a, value: a })) ?? []);

          return this.productTrackerService.getSearchTermRanks(this.productRow);
        }),
        switchMap((ranks: TrackedSearchTerm[]) => {
          this.searchTermRanks = ranks;
          return this.brandAnalyticsService.getLastSearchFrequencyRank(
            this.accountMarketplace!.marketplace,
            ranks.map((r) => r.searchTerm),
          );
        }),
      )
      .subscribe((searchFrequencyRanks: SearchTermRank[]) => {
        for (const sfr of searchFrequencyRanks) this.sfrBySearchTerm.set(sfr.searchTerm!, sfr.searchFrequencyRank!);
        this.searchTermRanks = this.sortRanksBySfr(this.searchTermRanks);

        if (this.searchTermRanks.length > 0) {
          if (this.rankOption !== RankOption.BOTH) {
            this.selectSearchTerms(this.searchTermRanks.map((s) => s.searchTerm).slice(0, 5));
            // for 'BOTH' options, select the first one
          } else this.selectOnlySearchTerm(this.searchTermRanks[0].searchTerm);
        } else this.selectSearchTerms([]);

        this.loading$.next(false);
        // Data change scroll
        scrollToRight(this.datavizScroll);
      });
  }

  ngAfterViewInit(): void {
    // Init scroll
    scrollToRight(this.datavizScroll);
  }

  private sortRanksBySfr(ranks: TrackedSearchTerm[]) {
    return ranks.sort(
      (s1, s2) =>
        (this.sfrBySearchTerm.get(s1.searchTerm) ?? +Infinity) - (this.sfrBySearchTerm.get(s2.searchTerm) ?? +Infinity),
    );
  }

  switchToParentView() {
    if (!this.productRow?.parentAsin) return;
    this.navigateToTrackedKeywordsDetails(this.productRow.parentAsin);
  }

  productPassTextFilter(product: string): boolean {
    return product.search(new RegExp(this.productListFilter, "i")) !== -1;
  }

  navigateToTrackedKeywordsDetails(product: string) {
    this.router.navigate([this.productTrackerUrl, product]);
  }

  accessValue(st: TrackedSearchTerm): string {
    return st.searchTerm;
  }

  selectRankOption(rankOption: RankOption) {
    if (rankOption === RankOption.BOTH) this.selectOnlySearchTerm(this.searchTermsOnGraph[0]);
    this.rankOption = rankOption;
  }

  setTableView(tableView: boolean) {
    this.tableView = tableView;
    if (tableView) {
      // Table view scroll
      scrollToRight(this.datavizScroll);
    }
  }

  updateScroll(id: string, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (id === "asinListScroll") {
      this.datavizScroll.nativeElement.scrollTop = this.asinListScroll.nativeElement.scrollTop;
    } else if (id === "datavizScroll") {
      this.asinListScroll.nativeElement.scrollTop = this.datavizScroll.nativeElement.scrollTop;
    }
  }

  // search term list related methods
  toggleSearchTermOnGraph(searchTerm: string) {
    if (this.tableView) return;
    if (this.rankOption === RankOption.BOTH) {
      this.selectOnlySearchTerm(searchTerm);
      return;
    }

    const newSearchTerms = [...this.searchTermsOnGraph];
    const elIndex = newSearchTerms.indexOf(searchTerm);
    if (elIndex > -1) newSearchTerms.splice(elIndex, 1);
    else newSearchTerms.push(searchTerm);

    this.searchTermsOnGraph = newSearchTerms;
  }

  selectOnlySearchTerm(searchTerm: string) {
    if (!searchTerm) this.searchTermsOnGraph = [];
    else this.searchTermsOnGraph = [searchTerm];
  }

  selectSearchTerms(searchTerms: string[]) {
    this.searchTermsOnGraph = searchTerms;
  }

  getSearchTermColor(st: string) {
    return PALETTE[this.searchTermsOnGraph.indexOf(st) + 2];
  }

  isSearchTermOnGraph(searchTerm: string) {
    return this.searchTermsOnGraph.includes(searchTerm);
  }

  setHoveredSearchTerm(searchTerm: string | undefined) {
    if (this.rankOption !== RankOption.BOTH) this.hoveredSearchTerm = searchTerm;
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  protected readonly RankOption = RankOption;
  protected readonly RANK_OPTIONS_ARRAY = RANK_OPTIONS_ARRAY;
  protected readonly RANK_OPTIONS_DESC = RANK_OPTIONS_DESC;
}
