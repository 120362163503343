import { CdkOverlayOrigin } from "@angular/cdk/overlay";
import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { faSquare, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faAngleUp, faCheckSquare, faEllipsisH, faPencilAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import {
  AccountMarketplace,
  MatchType,
  ProductTrackerConfig,
  Strategy,
  StrategyStateEnum,
} from "@front/m19-api-client";
import { Catalog, SegmentConfigType, SegmentEx } from "@front/m19-models";
import {
  AccountSelectionService,
  AsinService,
  AuthService,
  KeywordTrackingService,
  SegmentService,
  StrategyService,
} from "@front/m19-services";
import { TranslocoService } from "@jsverse/transloco";
import { KeywordTrackerPageQueryParams } from "@m19-board/keyword-tracker/keyword-tracker-page.component";
import { CsvExportService, fieldExtractor, simpleField } from "@m19-board/services/csv-export.service";
import { KeywordTrackerService } from "@m19-board/services/keyword-tracker.service";
import { ConfirmPopupComponent } from "@m19-board/shared/confirm-popup/confirm-popup.component";
import { InputModalComponent } from "@m19-board/shared/input-modal/input-modal.component";
import { keywordRankingAvailableFor } from "@m19-board/tracking/KeywordRankingAvailability";
import { ICON_ADD } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, forkJoin, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { ProductTrackerPageQueryParams } from "../product-tracker/product-tracker-views/product-tracker.component";
import { KeywordSegmentModalComponent } from "./keyword-segment-modal.component";
import { ProductSegmentModalComponent } from "./product-segment-modal.component";
import { SegmentDetailStrategiesModalComponent } from "./segment-detail/segment-detail-strategies-modal.component";

const displayedColumnsKw = ["selection", "segmentName", "strategies", "keywordsNb", "keywords", "actions"];
const displayedColumnsProducts = ["selection", "segmentName", "strategies", "productsNb", "products", "actions"];

@UntilDestroy()
@Component({
  selector: "app-segment-manager",
  templateUrl: "./segment-manager.component.html",
  styleUrls: ["./segment-manager.component.scss"],
})
export class SegmentsComponent implements OnInit {
  segments: SegmentEx[] = [];
  segmentIndex?: Map<number, SegmentEx>;
  accountMarketplace?: AccountMarketplace;
  dataSource = new MatTableDataSource<SegmentEx>();
  strategiesBySegmentId: Map<number, Strategy[]> = new Map();
  segmentType$ = new BehaviorSubject(SegmentConfigType.KeywordSegment);
  displayedColumns = displayedColumnsKw;
  isReadOnly = false;
  catalog?: Catalog;
  productTrackerConfig?: ProductTrackerConfig[];
  uiVersion = 0;

  readonly faPlusCircle = faPlusCircle;
  readonly faPencil = faPencilAlt;
  readonly faTrash = faTrashAlt;
  readonly faCheckedSquare = faCheckSquare;
  readonly faSquare = faSquare;
  readonly faEllipsis = faEllipsisH;
  readonly faAngleUp = faAngleUp;
  readonly SegmentType = SegmentConfigType;
  readonly ICON_ADD = ICON_ADD;
  private readonly selectedSegmentId = new Set<number>();
  private readonly expandedSegmentId = new Set<number>();

  productDetailsOrigin?: CdkOverlayOrigin;
  productDetailsAsin: string | undefined = undefined;

  @ViewChild("paginator", { static: false }) set paginator(value: MatPaginator) {
    if (this.dataSource) this.dataSource.paginator = value;
  }

  @ViewChild(MatSort) set sort(matSort: MatSort) {
    if (this.dataSource) this.dataSource.sort = matSort;
  }

  @ViewChild("productDetailsPopup") productDetailsPopup!: ElementRef;

  constructor(
    private accountSelection: AccountSelectionService,
    private segmentService: SegmentService,
    private asinService: AsinService,
    private keywordTrackingService: KeywordTrackingService,
    private modalService: BsModalService,
    private router: Router,
    private toastrService: ToastrService,
    private keywordTrackerService: KeywordTrackerService,
    private csvExportService: CsvExportService,
    private authService: AuthService,
    private translocoService: TranslocoService,
    private strategyService: StrategyService,
  ) {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.uiVersion = user?.uiVersion ?? 0;
    });
  }

  ngOnInit(): void {
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        tap((am) => {
          this.accountMarketplace = am;
        }),
        switchMap((am) =>
          combineLatest([
            this.segmentService.getSegments(am.accountId, am.marketplace),
            this.strategyService.getStrategyIndex(am.accountId, am.marketplace),
          ]),
        ),
      )
      .subscribe(([segments, strategies]) => {
        this.segmentIndex = segments;
        this.segments = Array.from(segments.values());
        this.strategiesBySegmentId = new Map();
        for (const s of strategies.values()) {
          for (const t of s.tactics) {
            if (!this.strategiesBySegmentId.has(t.segmentId)) {
              this.strategiesBySegmentId.set(t.segmentId, []);
            }
            this.strategiesBySegmentId.get(t.segmentId)!.push(s);
          }
        }
        this.dataSource.data = this.segments.filter((s) => s.segmentType == this.segmentType$.value);
        this.selectedSegmentId.clear();
      });
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) => this.asinService.getCatalog(am.accountId, am.marketplace)),
      )
      .subscribe((c) => {
        this.catalog = c;
      });
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) => this.keywordTrackingService.getProductTrackerConfig(am.accountId, am.marketplace)),
      )
      .subscribe((c) => {
        this.productTrackerConfig = c;
      });

    this.accountSelection.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
    this.dataSource.sortingDataAccessor = (item, property): string | number => {
      if (property == "strategies") return this.getActiveStrategies(item);
      if (property == "segmentName") return item.name;
      if (property == "keywordsNb" || property == "productsNb") return item.items.length;
      return 0;
    };
    this.dataSource.filterPredicate = (row, filter) => {
      if (filter) {
        const regexp = new RegExp(filter, "i");
        return regexp.test(row.name) || row.items.findIndex((item) => regexp.test(item.targetingValue)) != -1;
      }
      return true;
    };
    this.segmentType$.pipe(untilDestroyed(this)).subscribe((st) => {
      this.dataSource.data = this.segments.filter((s) => s.segmentType == st);
      this.displayedColumns = st == SegmentConfigType.KeywordSegment ? displayedColumnsKw : displayedColumnsProducts;
    });

    if (this.uiVersion == 0 && this.router.url.startsWith("/segments/keyword-segments")) {
      this.segmentType$.next(SegmentConfigType.KeywordSegment);
    } else if (this.uiVersion > 0 && this.router.url.startsWith("/keyword-center/segments/keyword-segment")) {
      this.segmentType$.next(SegmentConfigType.KeywordSegment);
    } else {
      this.segmentType$.next(SegmentConfigType.ProductSegment);
    }
  }

  selected(segment: SegmentEx) {
    return this.selectedSegmentId.has(segment.segmentId);
  }

  expanded(segment: SegmentEx) {
    return this.expandedSegmentId.has(segment.segmentId);
  }

  toggleSelectAll() {
    const selected = this.allSelected();
    if (selected) {
      this.selectedSegmentId.clear();
    } else {
      for (const s of this.dataSource.filteredData) {
        this.selectedSegmentId.add(s.segmentId);
      }
    }
  }

  allSelected() {
    return this.dataSource.filteredData.every((s) => this.selected(s));
  }

  nbSelected() {
    return this.selectedSegmentId.size;
  }

  select(segment: SegmentEx) {
    if (this.selectedSegmentId.has(segment.segmentId)) {
      this.selectedSegmentId.delete(segment.segmentId);
    } else {
      this.selectedSegmentId.add(segment.segmentId);
    }
  }

  toggleSegmentExpansion(segment: SegmentEx) {
    if (this.expandedSegmentId.has(segment.segmentId)) {
      this.expandedSegmentId.delete(segment.segmentId);
    } else {
      this.expandedSegmentId.add(segment.segmentId);
    }
  }

  setSegmentFilter(filter: string) {
    this.dataSource.filter = filter;
  }

  toggleSegmentType(segmentType: SegmentConfigType) {
    if (this.segmentType$.value == segmentType) {
      return;
    }
    this.segmentType$.next(segmentType);
    if (segmentType == SegmentConfigType.ProductSegment) {
      if (this.uiVersion == 0) {
        this.router.navigate(["segments/product-segments"]);
      } else {
        this.router.navigate(["/keyword-center/segments/product-segments"]);
      }
    } else {
      if (this.uiVersion == 0) {
        this.router.navigate(["segments/keyword-segments"]);
      } else {
        this.router.navigate(["/keyword-center/segments/keyword-segments"]);
      }
    }
  }

  downloadCsv() {
    this.csvExportService.exportCsv(
      {
        prefix: `segments_list`,
        marketplace: this.accountMarketplace!.marketplace,
        accountGroupName: this.accountMarketplace!.accountGroupName,
      },
      this.segments,
      [
        simpleField("segmentId"),
        simpleField("name"),
        fieldExtractor("strategies", (s) => "" + this.getActiveStrategies(s)),
        simpleField("segmentType"),
        fieldExtractor("products", (s) =>
          s.items
            .filter((i) => i.matchType == MatchType.asinSameAs)
            .map((i) => i.targetingValue)
            .join(";"),
        ),
        fieldExtractor("exactKeywords", (s) =>
          s.items
            .filter((i) => i.matchType == MatchType.exact)
            .map((i) => i.targetingValue)
            .join(";"),
        ),
        fieldExtractor("phraseKeywords", (s) =>
          s.items
            .filter((i) => i.matchType == MatchType.phrase)
            .map((i) => i.targetingValue)
            .join(";"),
        ),
      ],
    );
  }

  createNewSegment() {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.accountMarketplace!.accountId,
        marketplace: this.accountMarketplace!.marketplace,
      },
      class: "modal-xl",
    };
    if (this.segmentType$.value == SegmentConfigType.ProductSegment) {
      this.modalService.show(ProductSegmentModalComponent, modalOptions);
    } else if (this.segmentType$.value == SegmentConfigType.KeywordSegment) {
      this.modalService.show(KeywordSegmentModalComponent, modalOptions);
    }
  }

  canSegmentBeDeleted(segment: SegmentEx) {
    // segment can be deleted when there are no strategy using it
    return (
      !this.strategiesBySegmentId.has(segment.segmentId) ||
      this.strategiesBySegmentId.get(segment.segmentId)!.length == 0
    );
  }

  deleteSegment(segment: SegmentEx) {
    this.segmentService
      .deleteSegment(this.accountMarketplace!.accountId, this.accountMarketplace!.marketplace, segment.segmentId)
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("segment-manager.segment_segment_name_successfully_deleted", [
              segment.name,
            ]),
            this.translocoService.translate("segment-manager.segment_deleted"),
          );
        },
        error: (error: string) => {
          this.toastrService.error(
            this.translocoService.translate("segment-manager.error_deleting_segment_segment_name_error", [
              segment.name,
              error,
            ]),
            this.translocoService.translate("segment-manager.segment_deletion_error"),
          );
        },
      });
  }

  deleteSelectedSegments() {
    const toDelete: number[] = [];
    for (const segmentId of this.selectedSegmentId) {
      if (!this.segmentIndex?.has(segmentId)) {
        continue;
      }
      const segment = this.segmentIndex.get(segmentId)!;
      if (!this.canSegmentBeDeleted(segment)) {
        this.toastrService.warning(
          this.translocoService.translate("segment-manager.segment_used_canot_delete", [segment.name]),
          this.translocoService.translate("activity-service.segment_deletion"),
        );
        continue;
      }
      toDelete.push(segmentId);
    }
    if (toDelete.length > 0) {
      forkJoin(
        toDelete.map((segmentId) =>
          this.segmentService.deleteSegment(
            this.accountMarketplace!.accountId,
            this.accountMarketplace!.marketplace,
            segmentId,
          ),
        ),
      ).subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("segment-manager.segments_successfully_deleted"),
            this.translocoService.translate("segment-manager.segment_deleted"),
          );
        },
        error: (error: string) => {
          this.toastrService.error(
            this.translocoService.translate("segment-manager.error_deleting_segment_error", [error]),
            this.translocoService.translate("segment-manager.segment_deletion_error"),
          );
        },
      });
    }
  }

  editSegment(segment: SegmentEx) {
    const segmentType = segment.segmentType;
    if (segmentType == SegmentConfigType.KeywordSegment) {
      const modalOptions: ModalOptions = {
        initialState: {
          segment: segment,
          isReadOnly: this.isReadOnly,
        },
        class: "modal-xl",
      };
      this.modalService.show(KeywordSegmentModalComponent, modalOptions);
    } else if (segmentType == SegmentConfigType.ProductSegment) {
      const modalOptions: ModalOptions = {
        initialState: {
          segment: segment,
          isReadOnly: this.isReadOnly,
        },
        class: "modal-xl",
      };
      this.modalService.show(ProductSegmentModalComponent, modalOptions);
    }
  }

  showActiveStrategies(segment: SegmentEx) {
    const modalOptions: ModalOptions = {
      initialState: {
        segment: segment,
        linkedStrategies: this.strategiesBySegmentId.get(segment.segmentId) ?? [],
      },
      class: "modal-lg modal-dialog modal-dialog-centered",
    };
    this.modalService.show(SegmentDetailStrategiesModalComponent, modalOptions);
  }

  getActiveStrategies(segment: SegmentEx) {
    return (this.strategiesBySegmentId.get(segment.segmentId) ?? []).filter((s) => s.state == StrategyStateEnum.ENABLED)
      .length;
  }

  viewKeywordTracker(segment: SegmentEx) {
    this.router.navigate([this.uiVersion == 0 ? "/keyword-tracker" : "/keyword-center/keyword-tracker"], {
      queryParams: { [KeywordTrackerPageQueryParams.segment]: segment.segmentId },
    });
  }

  viewProductTracker(segment: SegmentEx) {
    this.router.navigate([this.uiVersion == 0 ? "/product-tracker" : "/product-center/product-tracker"], {
      queryParams: { [ProductTrackerPageQueryParams.segment]: segment.segmentId },
    });
  }

  viewTrafficAnalysis(segment: SegmentEx) {
    this.router.navigate([this.uiVersion == 0 ? "/traffic" : "keyword-center/traffic-analysis"], {
      queryParams: { selectedSegment: segment.segmentId },
    });
  }

  canAccessKeywordRanking() {
    return keywordRankingAvailableFor(this.accountMarketplace!.marketplace);
  }

  editSegmentName(segment: SegmentEx) {
    const input = new FormControl<string>(segment.name);
    const ref = this.modalService.show(InputModalComponent, {
      initialState: {
        title: this.translocoService.translate("segment-manager.change_segment_name"),
        inputControl: input,
        maxLength: 80,
      },
      class: "modal-primary",
    });
    ref.content?.emitUpdate
      .pipe(
        untilDestroyed(this),
        switchMap(() => {
          const newName = input.value;
          if (!newName || newName.trim() === "") {
            return of(undefined);
          }
          return this.segmentService.updateSegmentName(
            this.accountMarketplace!.accountId,
            this.accountMarketplace!.marketplace,
            segment.segmentId,
            newName,
          );
        }),
      )
      .subscribe((segment) => {
        if (segment === undefined) {
          this.toastrService.error(
            this.translocoService.translate("segment-manager.segment_name_cannot_be_empty"),
            this.translocoService.translate("common.error"),
          );
          return;
        }
        this.toastrService.success(this.translocoService.translate("segment-manager.segment_name_updated"));
      });
  }

  trackProducts(segment: SegmentEx) {
    if (this.segmentType$.value != SegmentConfigType.ProductSegment) {
      return;
    }

    const ref = this.modalService.show(ConfirmPopupComponent, {
      initialState: {
        title: "Track products",
        message: "Are you sure you want to track these products?",
        confirmCta: `Track ${segment.items.length} product${segment.items.length > 1 ? "s" : ""}`,
        cancelCta: "Cancel",
        type: "success",
      },
      class: "modal-dialog-centered",
    });

    ref.content?.confirm
      .pipe(
        untilDestroyed(this),
        switchMap(() => {
          const newConfig: ProductTrackerConfig[] = [
            ...this.productTrackerConfig!,
            ...segment.items.map((i) => ({
              accountId: segment.accountId,
              marketplace: segment.marketplace,
              asin: i.targetingValue,
            })),
          ];
          return this.keywordTrackingService.addProductTrackerConfig(segment.accountId, segment.marketplace, newConfig);
        }),
      )
      .subscribe((config) => {
        this.productTrackerConfig = config;
        this.toastrService.success(
          this.translocoService.translate("segment-manager.started_tracking_n_new_product", [segment.items.length]),
          this.translocoService.translate("segment-manager.new_product_tracked"),
        );
      });
    ref.content?.cancel.pipe(untilDestroyed(this)).subscribe(() => {
      ref.hide();
    });
  }

  trackKeywords(segment: SegmentEx) {
    if (this.segmentType$.value != SegmentConfigType.KeywordSegment) {
      return;
    }
    this.keywordTrackerService.openAddKwTrackingModal(
      segment.accountId,
      segment.marketplace,
      this.accountMarketplace!.resourceOrganizationId!,
      segment.items.map((i) => i.targetingValue),
    );
  }

  displayProductDetails(trigger: CdkOverlayOrigin, asin: string) {
    this.productDetailsOrigin = trigger;
    this.productDetailsAsin = asin;
  }

  @HostListener("document:mousemove", ["$event"])
  handleMouseMove(event: MouseEvent) {
    // check if still inside product details elements
    if (
      this.productDetailsOrigin &&
      this.productDetailsAsin &&
      !this.productDetailsOrigin.elementRef.nativeElement.contains(event.target) &&
      !this.productDetailsPopup.nativeElement.contains(event.target)
    ) {
      this.hideProductDetails();
    }
  }

  private hideProductDetails() {
    this.productDetailsOrigin = undefined;
    this.productDetailsAsin = undefined;
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
