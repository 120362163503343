<div class="spa flex flex-col">
  @if (targetFormatted) {
    @if (addLabel) {
      <div class="my-auto flex flex-1 flex-row items-center">
        <div class="text-gray-500">{{ getLabel() }}</div>
        <IButton
          [label]="targetFormatted"
          color="gray"
          variant="ghost"
          [trailing]="true"
          [disabled]="readonly"
          [tooltipValue]="getTooltip()"
          (onClick)="openTargetModal(); $event.stopPropagation()"
          [icon]="ICON_EDIT_O"
          [iconOnHover]="true"
        />
      </div>
      @if (secTargetFormatted) {
        <div class="my-auto -mt-3 flex flex-1 flex-row items-center">
          <div class="text-gray-500">{{ getLabelDailyBudget() }}</div>
          <IButton
            [label]="secTargetFormatted"
            color="gray"
            variant="ghost"
            [trailing]="true"
            [disabled]="readonly"
            [tooltipValue]="getTooltip()"
            (onClick)="openTargetModal(); $event.stopPropagation()"
            [icon]="ICON_EDIT_O"
            [iconOnHover]="true"
          />
        </div>
      }
    } @else {
      <IButton
        [label]="targetFormatted + (secTargetFormatted ? '/' + secTargetFormatted : '')"
        color="gray"
        variant="ghost"
        [trailing]="true"
        [disabled]="readonly"
        [tooltipValue]="getTooltip()"
        (onClick)="openTargetModal(); $event.stopPropagation()"
        [icon]="ICON_EDIT_O"
        [iconOnHover]="true"
      />
    }
  }
</div>
