import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, distinctUntilChanged, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { AccountSelectionService } from '.';
import { GetSearchTermRanksAggregationEnum, Marketplace, SearchTermRank, StatsApi } from '@front/m19-api-client';
import { UserSelectionService } from './user.selection.service';
import { DateAggregation } from '@front/m19-utils';

@Injectable({
  providedIn: 'root',
})
export class BrandAnalyticsService {
  constructor(
    private statsService: StatsApi,
    private userSelectionService: UserSelectionService,
    private accountSelectionService: AccountSelectionService,
  ) {
    const m = localStorage.getItem('kwExtractionMarketplace') as Marketplace;
    this.selectedMarketplaceSubject = new BehaviorSubject<Marketplace>(m ? Marketplace[m] : Marketplace.US);
    this.selectedMarketplace$ = this.selectedMarketplaceSubject.asObservable();
  }

  private searchTermsSelection: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public searchTermsSelection$: Observable<string[]> = this.searchTermsSelection.asObservable();
  private aggregation: BehaviorSubject<DateAggregation> = new BehaviorSubject<DateAggregation>(DateAggregation.daily);
  public aggregation$: Observable<DateAggregation> = this.aggregation.asObservable().pipe(distinctUntilChanged());

  private searchRanksMap: Map<string, Observable<number[]>> = new Map<string, Observable<number[]>>();
  private searchTermsAutoCompletionMap: Map<string, Observable<SearchTermRank[]>> = new Map<
    string,
    Observable<SearchTermRank[]>
  >();
  private topSearchTerms: Map<string, Observable<SearchTermRank[]>> = new Map<
    Marketplace,
    Observable<SearchTermRank[]>
  >();

  private readonly selectedMarketplaceSubject: BehaviorSubject<Marketplace>;
  public readonly selectedMarketplace$: Observable<Marketplace>;

  public selectMarketplace(marketplace: Marketplace): void {
    this.selectedMarketplaceSubject.next(marketplace);
    localStorage.setItem('kwExtractionMarketplace', marketplace.toString());
  }

  public initializeSearchTermsSelection(marketplace: Marketplace): void {
    this.searchTermsSelection.next(this.getLocalStorageSearchTermSelection(marketplace));
  }

  public addSearchTerm(searchTerm: string, marketplace: Marketplace) {
    if (this.searchTermsSelection.getValue().includes(searchTerm)) return;
    const currentSelection = this.searchTermsSelection.getValue();
    currentSelection.push(searchTerm);
    this.searchTermsSelection.next(currentSelection);
    this.setLocalStorageSearchTermSelection(currentSelection, marketplace);
  }

  public removeSearchTerm(searchTerm: string, marketplace: Marketplace) {
    const currentSelection = this.searchTermsSelection.getValue().filter((s) => s != searchTerm);
    this.searchTermsSelection.next(currentSelection);
    this.setLocalStorageSearchTermSelection(currentSelection, marketplace);
  }

  public getSearchTermsAutoCompletion(searchTerm: string): Observable<SearchTermRank[]> {
    return this.selectedMarketplace$.pipe(
      switchMap((marketplace) => {
        const key = marketplace + searchTerm;
        if (!this.searchTermsAutoCompletionMap.has(key)) {
          this.searchTermsAutoCompletionMap.set(
            key,
            this.statsService
              .getSearchTermsAutoComplete({
                marketplace: marketplace,
                searchTerm: searchTerm,
              })
              .pipe(shareReplay(1)),
          );
        }
        return this.searchTermsAutoCompletionMap.get(key)!;
      }),
    );
  }

  public getTopSearchTerms(): Observable<SearchTermRank[]> {
    return this.selectedMarketplace$.pipe(
      switchMap((marketplace) => {
        if (!this.topSearchTerms.has(marketplace)) {
          this.topSearchTerms.set(
            marketplace,
            this.statsService
              .getTopSearchTerms({
                marketplace: marketplace,
              })
              .pipe(
                map((s) => s.sort((a, b) => a.searchFrequencyRank! - b.searchFrequencyRank!)),
                shareReplay(1),
              ),
          );
        }
        return this.topSearchTerms.get(marketplace)!;
      }),
    );
  }

  private toApiDateAggregation(aggr: DateAggregation) {
    switch (aggr) {
      case DateAggregation.monthly:
        return GetSearchTermRanksAggregationEnum.monthly;
      default:
        return GetSearchTermRanksAggregationEnum.daily;
    }
  }

  public getSearchRanks(): Observable<[number[][], string[], string[], DateAggregation]> {
    return combineLatest([
      this.searchTermsSelection$,
      this.selectedMarketplace$,
      this.userSelectionService.dateRange$,
      this.aggregation$,
    ]).pipe(
      switchMap(([searchTerms, marketplace, dateRange, aggregation]) => {
        return searchTerms.length == 0
          ? of([])
          : combineLatest(
              searchTerms.map((searchTerm) => {
                const ranksObservableKey = BrandAnalyticsService.getSearchRankMapKey(
                  marketplace,
                  searchTerm,
                  dateRange[0],
                  dateRange[1],
                  aggregation,
                );
                if (!this.searchRanksMap.has(ranksObservableKey)) {
                  this.searchRanksMap.set(
                    ranksObservableKey,
                    this.statsService
                      .getSearchTermRanks({
                        marketplace: marketplace,
                        searchTerm: searchTerm,
                        aggregation: this.toApiDateAggregation(aggregation),
                        minDate: dateRange[0],
                        maxDate: dateRange[1],
                      })
                      .pipe(shareReplay(1)),
                  );
                }
                return this.searchRanksMap.get(ranksObservableKey)!;
              }),
            );
      }),
      withLatestFrom(this.searchTermsSelection$, this.userSelectionService.dateRange$, this.aggregation$),
    );
  }

  public getLastSearchFrequencyRank(marketplace: Marketplace, searchTerms: string[]): Observable<SearchTermRank[]> {
    if (searchTerms.length == 0) {
      return of([]);
    }
    return this.statsService
      .getLastSearchTermRanks({
        marketplace: marketplace,
        requestBody: searchTerms.map((s) => s.toLowerCase()),
      })
      .pipe(
        catchError((error: AjaxError) => {
          return throwError(
            () =>
              'Error get the last search frequency rank: ' + (error.response ? error.response.message : error.message),
          );
        }),
      );
  }

  private setLocalStorageSearchTermSelection(searchTerms: string[], marketplace: Marketplace): void {
    localStorage.setItem('searchTermSelection' + marketplace, JSON.stringify(searchTerms));
  }

  private getLocalStorageSearchTermSelection(marketplace: Marketplace): string[] {
    if (!localStorage.getItem('searchTermSelection' + marketplace)) return [];
    return JSON.parse(localStorage.getItem('searchTermSelection' + marketplace) ?? '');
  }

  public setAggregation(aggregation: DateAggregation) {
    this.aggregation.next(aggregation);
  }

  private static getSearchRankMapKey(
    marketplace: Marketplace,
    searchTerm: string,
    minDate: string,
    maxDate: string,
    aggregation: string,
  ) {
    return marketplace.toString() + searchTerm + minDate + maxDate + aggregation;
  }
}
