import { NgClass, NgTemplateOutlet } from "@angular/common";
import { Component, EventEmitter, inject, Input, OnInit, Output, signal, TemplateRef, ViewChild } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import {
  AccountMarketplace,
  AccountType,
  BrandAsset,
  MediaType,
  PreModeration,
  PreModerationComponent,
  SbCreativeType,
} from "@front/m19-api-client";
import { IButtonComponent, IInputComponent, SpinnerComponent } from "@front/m19-ui";
import { TranslocoService } from "@jsverse/transloco";
import { ConfirmPopupComponent } from "@m19-board/shared/confirm-popup/confirm-popup.component";
import { defaultHeadlineLimit, headlineLimit } from "@m19-board/sponsored-brand/Limits";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { debounceTime, distinctUntilChanged, Observable, Subject } from "rxjs";
import { MediaInputComponent } from "../../../shared/media-input/media-input.component";
import { BRAND_NAME_MAX_LENGTH, FormStepEvent, SbBrandAssets, SbFormStep } from "../sb-strategy-form.component";
import { SbStrategiesService } from "@front/m19-services";

export interface SbCrea {
  brandName: string;
  headline: string;
  brandLogo: BrandAsset | null;
  customImage: BrandAsset | null;
  customImage2: BrandAsset | null;
  customImage3: BrandAsset | null;
  customImage4: BrandAsset | null;
  customImage5: BrandAsset | null;
  video: BrandAsset | null;
}

type PreModerationErrors = {
  headline: string;
  brandLogo: string;
  brandLogoUrl: string;
  customImage: string;
  customImageUrl: string;
};

type FormCreativeType = {
  brandName: FormControl<string | null>;
  headline: FormControl<string | null>;
  brandLogo: FormControl<BrandAsset | null>;
  customImages: FormArray<FormControl<BrandAsset | null>>;
  video: FormControl<BrandAsset | null>;
};

@Component({
  selector: "app-sb-form-creative",
  templateUrl: "./sb-form-creative.component.html",
  standalone: true,
  imports: [
    IButtonComponent,
    NgClass,
    IInputComponent,
    ReactiveFormsModule,
    SpinnerComponent,
    MediaInputComponent,
    TranslocoRootModule,
    NgTemplateOutlet,
  ],
})
export class SbFormCreativeComponent implements OnInit {
  private readonly sbStrategiesService = inject(SbStrategiesService);
  private readonly toastrService = inject(ToastrService);
  private readonly modalService = inject(BsModalService);
  readonly bsModalRef = inject(BsModalRef);

  readonly brandNameRegex =
    /^[- $'%&()*+,./:;<=>`_|{}~0-9a-zA-ZÁÍÑÓÚáéíñóúÄÖßäöüÀÂÆÇÈÉÊËÎÏÌÌÔÙÛÜŒŸàâæçèêëîïìôùûÿœ\\[\]\u0022\u3000-\u309F\u30A0-\u30FF\u4E00-\u9FFF\uFF00-\uFFEF]+$/;
  readonly capsRegex = /^[A-Z0-9ÀÂÆÇÈÉÊËÎÏÔÙÛÜŒŸÁÍÑÓÚ',: ]+$/;
  readonly MAX_CUSTOM_IMAGES = 5;

  @Input() set am(a: AccountMarketplace) {
    if (a) {
      this.isVendor = a.accountType === AccountType.VENDOR;
      this._am = a;
      this.headlineMaxLength = headlineLimit(a.marketplace);
    }
  }

  private _am?: AccountMarketplace;

  @Input() set sbCrea(sbCreative: SbCrea) {
    if (this.reviewMode) {
      this.form.disable();
    }

    this.form.patchValue({
      ...sbCreative,
    });

    this.form.controls.customImages.clear();

    if (sbCreative?.customImage) {
      const customImages = [
        sbCreative.customImage,
        sbCreative.customImage2,
        sbCreative.customImage3,
        sbCreative.customImage4,
        sbCreative.customImage5,
      ];
      customImages.forEach((c) => {
        if (c) this.form.controls.customImages.push(new FormControl<BrandAsset>(c, Validators.required));
      });
    } else if (this._creativeType === SbCreativeType.productCollection) {
      this.form.controls.customImages.push(new FormControl<BrandAsset | null>(null, Validators.required));
    }
  }

  @Input() reviewMode = false;
  @Input() editMode = false;
  @Input() brandEntityId?: string;
  @Input() brandAssets?: SbBrandAssets;

  @Input() set creativeType(t: SbCreativeType) {
    this._creativeType = t;
    if (!this.reviewMode) this.toggleValidators();
  }

  _creativeType?: SbCreativeType;

  @Output() onFormSubmit = new EventEmitter<FormStepEvent<SbCrea>>();
  @Output() onFormPrevious = new EventEmitter<void>();

  @ViewChild("preModerationWarnModal") preModerationWarnModal!: TemplateRef<any>;
  @ViewChild("uploadToAmazonModal") uploadToAmazonModal!: TemplateRef<any>;
  @ViewChild(MediaInputComponent) mediaInputVideo!: MediaInputComponent;

  isVendor = false;

  resetVideoInput = new Subject<void>();

  // Assets
  brandLogos?: BrandAsset[];
  customImages?: BrandAsset[];
  videos?: BrandAsset[];

  // Uploading new file
  fileUploading = false;

  // Pre-moderation
  hasLaunchedPreModeration = signal(false);
  preModeWarning = signal<PreModerationErrors | null>(null);
  preModeLoading = signal(false);

  headlineMaxLength = defaultHeadlineLimit;
  readonly form: FormGroup<FormCreativeType> = new FormGroup({
    brandName: new FormControl({ value: "", disabled: false }, [
      Validators.required,
      Validators.maxLength(BRAND_NAME_MAX_LENGTH),
      this.BandNameValidator(),
    ]),
    headline: new FormControl("", [
      Validators.required,
      Validators.maxLength(this.headlineMaxLength),
      this.HeadLineValidator(),
    ]),
    brandLogo: new FormControl<BrandAsset | null>(null, Validators.required),
    customImages: new FormArray<FormControl<BrandAsset | null>>([], Validators.required),
    video: new FormControl<BrandAsset | null>(null, Validators.required),
  });

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    if (this.editMode) {
      this.form.controls.brandName.disable();
    }
    this.brandLogos = this.brandAssets?.brandLogos?.filter(
      (a) =>
        a.mediaType === MediaType.brandLogo && !a.deleted && (this.isVendor || a.brandEntityId === this.brandEntityId),
    );
    this.customImages = this.brandAssets?.customImages?.filter(
      (a) =>
        a.mediaType === MediaType.customImage &&
        !a.deleted &&
        (this.isVendor || a.brandEntityId === this.brandEntityId),
    );
    this.videos = this.brandAssets?.videos
      ?.filter((a) => a.mediaType === MediaType.videoAsset)
      // vertical video is only supported for brand video
      .filter(
        (v) =>
          this._creativeType !== SbCreativeType.video ||
          (v.width == undefined && v.height == undefined) ||
          v.width! > v.height!,
      )
      .sort((a, b) =>
        a.url && b.url ? (a.name! > b.name! ? 1 : -1) : a.url ? -1 : b.url ? 1 : a.name! > b.name! ? 1 : -1,
      );

    this.form.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      )
      .subscribe((_) => {
        if (this.form.valid) {
          this.submitForm();
        }
      });
  }

  private toggleValidators() {
    const { controls } = this.form;

    const enableControls = (...controlsToEnable: (keyof FormCreativeType)[]) => {
      Object.keys(controls).forEach((control: any) => {
        if (controlsToEnable.includes(control)) {
          (controls as any)[control].enable();
        } else {
          (controls as any)[control].disable();
        }
      });
    };

    switch (this._creativeType) {
      case SbCreativeType.productCollection:
        enableControls("brandName", "headline", "brandLogo", "customImages");
        break;
      case SbCreativeType.brandVideo:
        enableControls("brandName", "headline", "brandLogo", "video");
        break;
      case SbCreativeType.storeSpotlight:
        enableControls("brandName", "headline", "brandLogo");
        break;
      default:
        enableControls("video");
        break;
    }
  }

  preModWall(goNext = false) {
    if (this.preModeWarning()) {
      const ref = this.modalService.show(ConfirmPopupComponent, {
        class: "modal-dialog-centered",
        initialState: {
          title: this.translocoService.translate("sb-form-creative.pre-moderation_warnings"),
          message: this.translocoService.translate("sb-form-creative.are_you_sure_you_want_to_proceed"),
          confirmCta: "Proceed",
          cancelCta: "Cancel",
        },
      });

      ref.content?.confirm.subscribe(() => {
        this.submitForm(goNext);
      });
      ref.content?.cancel.subscribe(() => {
        ref.hide();
      });
    } else {
      this.submitForm(goNext);
    }
  }

  private submitForm(goNext = false) {
    if (this.form.valid) {
      // If not launched pre-moderation and go to next step, launch pre-moderation
      if (!this.hasLaunchedPreModeration() && goNext) {
        this.launchPreModeration();
      } else {
        this.onFormSubmit.emit({
          formData: {
            ...this.form.getRawValue(),
            customImage: this.form.value.customImages ? this.form.value.customImages[0] : null,
            customImage2: this.form.value.customImages ? this.form.value.customImages[1] : null,
            customImage3: this.form.value.customImages ? this.form.value.customImages[2] : null,
            customImage4: this.form.value.customImages ? this.form.value.customImages[3] : null,
            customImage5: this.form.value.customImages ? this.form.value.customImages[4] : null,
          } as SbCrea,
          step: SbFormStep.CREATIVE,
          goNext,
        });
      }
    }
  }

  addCustomImageControl() {
    this.form.controls.customImages.push(new FormControl<BrandAsset | null>(null, Validators.required));
  }

  onCustomImageDelete(indexToRemove: number) {
    this.form.controls.customImages.removeAt(indexToRemove);
  }

  onToUpload(file: File, mediaType: MediaType, formControl: FormControl<string | BrandAsset | null>) {
    this.uploadFile(file, mediaType, formControl);
  }

  private uploadFile(file: File, mediaType: MediaType, formControl: FormControl<string | BrandAsset | null>) {
    const ref = this.modalService.show(this.uploadToAmazonModal, {
      class: "modal-dialog-centered",
      backdrop: "static",
    });
    this.fileUploading = true;

    this.uploadBrandAsset(file, mediaType).subscribe({
      next: (asset: BrandAsset | undefined) => {
        if (asset) this.updateVideoControl(formControl, asset);
        this.fileUploading = false;
        ref.hide();
        this.toastrService.success(
          this.translocoService.translate("sb-form-creative.asset_uploaded_successfully_to_amazon"),
        );
      },
      error: (error) => {
        this.toastrService.error(this.translocoService.translate("sb-form-creative.error_uploading_asset"), error);
        this.fileUploading = false;
        ref.hide();
      },
    });
  }

  private updateVideoControl(formControl: FormControl<string | BrandAsset | null>, asset: BrandAsset) {
    if (!asset) return;

    if (this._creativeType === SbCreativeType.video && asset.height! > asset.width!) {
      this.resetVideoInput.next();
      this.modalService.show(ConfirmPopupComponent, {
        initialState: {
          title: "Vertical videos not supported",
          message: "Vertical videos are not supported for this ad format. Please use a horizontal video instead.",
        },
      });
      return;
    }
    formControl.setValue(asset);
  }

  launchPreModeration() {
    this.preModeLoading.set(true);
    this.sbStrategiesService
      .sbPreModeration(
        this._am!.accountId,
        this._am!.marketplace,
        this.form.controls.headline.value!,
        this.form.controls.brandLogo.value?.url,
        this.form.controls.customImages.value?.map((i) => i!.url!),
      )
      .subscribe((res: PreModeration) => {
        this.hasLaunchedPreModeration.set(true);
        this.preModeWarning.set(this.getPreModerationWarnings(res));
        if (!this.preModeWarning()) this.submitForm(true); // If no pre-moderation, go to next step
        this.preModeLoading.set(false);
      });
  }

  private uploadBrandAsset(file: File, mediaType: MediaType): Observable<BrandAsset | undefined> {
    return this.sbStrategiesService.uploadBrandAsset({
      accountId: this._am!.accountId,
      marketplace: this._am!.marketplace,
      brandEntityId: this.isVendor ? "" : this.brandEntityId!,
      mediaType,
      file,
    });
  }

  private getPreModerationWarnings(res: PreModeration): PreModerationErrors | null {
    const headlineComponent = this.findComponent(res.textComponents!, "headline");
    const brandLogoComponent = this.findComponent(res.imageComponents!, "brandLogo");
    const customImageComponent = this.findComponent(res.imageComponents!, "customImage");

    const preMod: PreModerationErrors = {
      headline: headlineComponent?.policyViolations?.[0]
        ? headlineComponent!.policyViolations[0]!.policyDescription!
        : "",
      brandLogo: brandLogoComponent?.policyViolations?.[0]
        ? brandLogoComponent!.policyViolations[0]!.policyDescription!
        : "",
      brandLogoUrl: brandLogoComponent?.policyViolations?.[0]
        ? brandLogoComponent!.policyViolations[0]!.policyLinkUrl!
        : "",
      customImage: customImageComponent?.policyViolations?.[0]
        ? customImageComponent!.policyViolations[0]!.policyDescription!
        : "",
      customImageUrl: customImageComponent?.policyViolations?.[0]
        ? customImageComponent!.policyViolations[0]!.policyLinkUrl!
        : "",
    };
    return preMod.headline || preMod.brandLogo || preMod.customImage ? preMod : null;
  }

  private findComponent(components: PreModerationComponent[], componentId: keyof PreModerationErrors) {
    return components.find((component) => component.id == componentId);
  }

  private BandNameValidator() {
    return (control: AbstractControl) => {
      if (control.value && !this.brandNameRegex.test(control.value)) {
        return { invalidName: true };
      }
      return null;
    };
  }

  private HeadLineValidator() {
    return (control: AbstractControl) => {
      const headline = control.value;
      if (headline && this.capsRegex.test(headline)) return { capsError: true };
      if (headline && !this.brandNameRegex.test(headline)) return { invalidName: true };

      return null;
    };
  }

  readonly MediaType = MediaType;
  readonly SbCreativeType = SbCreativeType;
  readonly BRAND_NAME_MAX_LENGTH = BRAND_NAME_MAX_LENGTH;
}
