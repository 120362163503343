<ng-container *transloco="let t">
  <div class="modal-header">
    <h4 class="modal-title">{{ t("profile-settings.change_password") }}</h4>
  </div>
  <div class="modal-body">
    <form id="register-form" name="register-form" [formGroup]="passwordForm">
      <fieldset class="col-10 mx-auto">
        <div class="input-group mt-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><fa-icon [icon]="faLock"></fa-icon></span>
          </div>
          <input
            type="password"
            id="oldPasswordControl"
            name="oldPasswordControl"
            class="form-control"
            placeholder="{{ t('change-password.enter_your_current_password') }}"
            formControlName="oldPwd"
            [ngClass]="{
              'is-invalid': oldPwd.invalid && (!oldPwd.untouched || oldPwd.dirty),
            }"
          />
        </div>
        <hr />
        <div class="input-group mt-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><fa-icon [icon]="faLock"></fa-icon></span>
          </div>
          <input
            type="password"
            id="newPassword"
            name="newPassword"
            class="form-control"
            autocomplete="new-password"
            placeholder="{{ t('password-reset.password_field_placeholder') }}"
            formControlName="newPw1"
            [ngClass]="{
              'is-invalid': newPw1.invalid && (!newPw1.untouched || newPw1.dirty),
            }"
          />
        </div>
        @if (newPw1.invalid && (newPw1.dirty || !newPw1.untouched)) {
          @if (newPw1.errors?.["required"]) {
            <div class="invalid-feedback">{{ t("change-password.password_is_required") }}</div>
          }
          @if (newPw1.errors?.["minlength"]) {
            <div class="invalid-feedback">{{ t("change-password.password_must_contain_at_least_8_characters") }}</div>
          }
        }

        <div class="input-group mt-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><fa-icon [icon]="faLock"></fa-icon></span>
          </div>
          <input
            type="password"
            id="newPasswordControl"
            name="newPasswordControl"
            class="form-control"
            autocomplete="new-password"
            placeholder="{{ t('password-reset.password_confirm_placeholder') }}"
            formControlName="newPw2"
            [ngClass]="{
              'is-invalid': newPw2.invalid && (!newPw2.untouched || newPw2.dirty),
            }"
          />
        </div>
        @if (newPw2.invalid && (newPw2.dirty || !newPw2.untouched)) {
          @if (newPw2.errors?.["confirmedValidator"]) {
            <div class="invalid-feedback">{{ t("change-password.passwords_are_different") }}</div>
          }
        }

        <div class="modal-footer mt-4">
          <button type="button" class="btn btn-secondary" (click)="close()">{{ t("common.close") }}</button>
          <button type="submit" class="btn btn-primary" [disabled]="passwordForm.invalid" (click)="updatePassword()">
            {{ t("common.save") }}
          </button>
        </div>
      </fieldset>
    </form>
  </div>
</ng-container>
