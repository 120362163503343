<div class="relative flex min-h-10 rounded-lg bg-gray-100 p-1 text-sm" *transloco="let t">
  @for (item of collection(); track $index) {
    <button
      #tab
      [id]="$index"
      (click)="setTabPosition($index)"
      class="z-50 inline-flex flex-1 items-center justify-center rounded-md px-2 py-1 font-medium text-gray-500"
      [matTooltip]="buttonTooltips()[$index]"
      [ngClass]="{
        'bg-white text-gray-700': activeTabIndex() === $index,
        'hover:text-gray-700': activeTabIndex() !== $index,
      }"
      [attr.data-testid]="testids()[$index]"
    >
      @if (type() === SwitchButtonType.TEXT) {
        <span>{{ t(item) }}</span>
      } @else {
        <span [class]="'text-xl ' + item"></span>
      }
    </button>
  }
</div>
