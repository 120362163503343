import { Component, EventEmitter, Input, OnInit, Output, signal } from "@angular/core";
import { CatalogBrandService } from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";
import { CatalogBrandEx } from "@front/m19-models";

@UntilDestroy()
@Component({
  selector: "app-brand-merge-modal",
  templateUrl: "./brand-merge-modal.component.html",
})
export class BrandMergeModalComponent implements OnInit {
  @Input()
  catalogBrand!: CatalogBrandEx;
  @Output()
  selectedBrand = new EventEmitter<CatalogBrandEx>();
  @Output()
  cancel = new EventEmitter();

  catalogBrands: Option<CatalogBrandEx>[] = [];
  brandSelected = signal<Option<CatalogBrandEx> | undefined>(undefined);

  constructor(
    public bsModalRef: BsModalRef,
    private catalogBrandService: CatalogBrandService,
  ) {}

  ngOnInit(): void {
    this.catalogBrandService
      .getCatalogBrands(this.catalogBrand.accountId!, this.catalogBrand.marketplace!)
      .pipe(untilDestroyed(this))
      .subscribe((brands) => {
        this.catalogBrands = brands
          .filter(
            (brand) =>
              brand.brandId !== this.catalogBrand.brandId &&
              (brand.parentBrandId == undefined || brand.parentBrandId === brand.brandId),
          )
          .map((brand) => ({
            value: brand,
            label: brand.brandAlias ?? "",
          }));
      });
  }

  selectBrand(brand: Option<CatalogBrandEx>) {
    this.brandSelected.set(brand);
  }

  merge() {
    this.selectedBrand.emit(this.brandSelected()?.value);
    this.bsModalRef.hide();
  }

  close() {
    this.cancel.emit();
    this.bsModalRef.hide();
  }
}
