import { Component, OnInit, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { DayPartingInputsComponent } from "@m19-board/strategies/day-parting-inputs/day-parting-inputs.component";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Observable } from "rxjs";
import { StrategyEx } from "@front/m19-models";

@UntilDestroy()
@Component({
  selector: "app-day-parting-pop-up",
  templateUrl: "./day-parting-pop-up.component.html",
  styleUrls: ["./day-parting-pop-up.component.scss"],
})
export class DayPartingPopUpComponent implements OnInit {
  strategy$!: Observable<StrategyEx>;
  locale!: string;
  dayPartingPauseHour!: number;
  dayPartingReactivationHour!: number;
  strategy!: StrategyEx;
  @ViewChild("dayPartingInputsComponent") dayPartingInputsComponent!: DayPartingInputsComponent;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit(): void {
    this.strategy$.pipe(untilDestroyed(this)).subscribe((x) => (this.strategy = x));
  }

  onSave(): void {
    this.dayPartingInputsComponent.pushDayPartingPref();
    this.bsModalRef.hide();
  }
}
