import { Currency, Marketplace, Order } from '@front/m19-api-client';
import { currencyRate, marketplaceToCurrencyRate } from './currency.utils';
import { OrderStats } from '@front/m19-models/OrderStats';
import { sumV2 } from './statsUtils';

function sum(a: number | undefined, b: number | undefined) {
  return a ? (b ? a + b : a) : b ? b : 0;
}

export function sumOrderStat(orderStat: OrderStats, other: OrderStats): OrderStats {
  const sumRes: OrderStats = emptyOrderStat(orderStat.asin!, orderStat.date!, orderStat.currency! ?? other.currency);

  if (orderStat.currency && other.currency && orderStat.currency != other.currency) {
    throw "Can't merge orders with different currency " + orderStat.currency + '!=' + other.currency;
  }
  return sumInPlace(sumRes, orderStat, other);
}

export function sumOrderStatInPlace(orderStat: OrderStats, other: OrderStats): OrderStats {
  if (orderStat.currency && other.currency && orderStat.currency != other.currency) {
    throw "Can't merge orders with different currency " + orderStat.currency + '!=' + other.currency;
  }

  return sumInPlace(orderStat, orderStat, other);
}

export function sumOrderStatInPlaceV2(orderStat: OrderStats, other: OrderStats): OrderStats {
  if (orderStat.currency && other.currency && orderStat.currency != other.currency) {
    throw "Can't merge orders with different currency " + orderStat.currency + '!=' + other.currency;
  }

  return sumInPlaceV2(orderStat as Required<OrderStats>, other);
}

function sumInPlace(result: OrderStats, orderStat: OrderStats, other: OrderStats) {
  result.netSales = sum(orderStat.netSales, other.netSales) as number;
  result.promotion = sum(orderStat.promotion, other.promotion) as number;
  result.fee = sum(orderStat.fee, other.fee) as number;
  result.advertising = sum(orderStat.advertising, other.advertising) as number;

  result.grossSales = sum(orderStat.grossSales, other.grossSales);
  result.refundedSales = sum(orderStat.refundedSales, other.refundedSales);

  result.quantity = sum(orderStat.quantity, other.quantity);
  result.refundedUnits = sum(orderStat.refundedUnits, other.refundedUnits);
  result.sellableReturnUnits = sum(orderStat.sellableReturnUnits, other.sellableReturnUnits);

  result.fbaGlobalReimbursement = sum(orderStat.fbaGlobalReimbursement, other.fbaGlobalReimbursement);
  result.fbaReversalReimbursement = sum(orderStat.fbaReversalReimbursement, other.fbaReversalReimbursement);
  result.fbaReimbursementClawback = sum(orderStat.fbaReimbursementClawback, other.fbaReimbursementClawback);
  result.fbaOtherReimbursement = sum(orderStat.fbaOtherReimbursement, other.fbaOtherReimbursement);

  result.referralFee = sum(orderStat.referralFee, other.referralFee);
  result.fbaFee = sum(orderStat.fbaFee, other.fbaFee);
  result.liquidations = sum(orderStat.liquidations, other.liquidations);
  result.giftWrapFee = sum(orderStat.giftWrapFee, other.giftWrapFee);
  result.otherFee = sum(orderStat.otherFee, other.otherFee);
  result.fbaStorageFee = sum(orderStat.fbaStorageFee, other.fbaStorageFee);
  result.feeAdjustment = sum(orderStat.feeAdjustment, other.feeAdjustment);
  result.returnFees = sum(orderStat.returnFees, other.returnFees);

  result.spAdvertising = sum(orderStat.spAdvertising, other.spAdvertising);
  result.sbAdvertising = sum(orderStat.sbAdvertising, other.sbAdvertising);
  result.sdAdvertising = sum(orderStat.sdAdvertising, other.sdAdvertising);

  result.exportCharge = sum(orderStat.exportCharge, other.exportCharge);

  result.costOfGoods = sum(orderStat.costOfGoods, other.costOfGoods);

  result.profit = sum(orderStat.profit, other.profit);

  result.currency = orderStat.currency;
  result.marketplace = orderStat.marketplace;

  return result;
}

function sumInPlaceV2(orderStat: Required<OrderStats>, other: OrderStats) {
  orderStat.netSales = sumV2(orderStat.netSales, other.netSales) as number;
  orderStat.promotion = sumV2(orderStat.promotion, other.promotion) as number;
  orderStat.fee = sumV2(orderStat.fee, other.fee) as number;
  orderStat.advertising = sumV2(orderStat.advertising, other.advertising) as number;

  orderStat.grossSales = sumV2(orderStat.grossSales, other.grossSales);
  orderStat.refundedSales = sumV2(orderStat.refundedSales, other.refundedSales);

  orderStat.quantity = sumV2(orderStat.quantity, other.quantity);
  orderStat.refundedUnits = sumV2(orderStat.refundedUnits, other.refundedUnits);
  orderStat.sellableReturnUnits = sumV2(orderStat.sellableReturnUnits, other.sellableReturnUnits);

  orderStat.fbaGlobalReimbursement = sumV2(orderStat.fbaGlobalReimbursement, other.fbaGlobalReimbursement);
  orderStat.fbaReversalReimbursement = sumV2(orderStat.fbaReversalReimbursement, other.fbaReversalReimbursement);
  orderStat.fbaReimbursementClawback = sumV2(orderStat.fbaReimbursementClawback, other.fbaReimbursementClawback);
  orderStat.fbaOtherReimbursement = sumV2(orderStat.fbaOtherReimbursement, other.fbaOtherReimbursement);

  orderStat.referralFee = sumV2(orderStat.referralFee, other.referralFee);
  orderStat.fbaFee = sumV2(orderStat.fbaFee, other.fbaFee);
  orderStat.liquidations = sumV2(orderStat.liquidations, other.liquidations);
  orderStat.giftWrapFee = sumV2(orderStat.giftWrapFee, other.giftWrapFee);
  orderStat.otherFee = sumV2(orderStat.otherFee, other.otherFee);
  orderStat.fbaStorageFee = sumV2(orderStat.fbaStorageFee, other.fbaStorageFee);
  orderStat.feeAdjustment = sumV2(orderStat.feeAdjustment, other.feeAdjustment);
  orderStat.returnFees = sumV2(orderStat.returnFees, other.returnFees);

  orderStat.spAdvertising = sumV2(orderStat.spAdvertising, other.spAdvertising);
  orderStat.sbAdvertising = sumV2(orderStat.sbAdvertising, other.sbAdvertising);
  orderStat.sdAdvertising = sumV2(orderStat.sdAdvertising, other.sdAdvertising);

  orderStat.exportCharge = sumV2(orderStat.exportCharge, other.exportCharge);

  orderStat.costOfGoods = sumV2(orderStat.costOfGoods, other.costOfGoods);

  orderStat.profit = sumV2(orderStat.profit, other.profit);

  orderStat.currency = orderStat.currency ?? other.currency;
  orderStat.marketplace = orderStat.marketplace ?? other.marketplace;

  return orderStat;
}

export function currencyRateOrderStat(orderStat: OrderStats, currency: Currency) {
  const rate = currencyRate(orderStat, currency);
  orderStat.currency = currency;

  orderStat.netSales *= rate;
  orderStat.promotion *= rate;
  orderStat.fee *= rate;
  orderStat.advertising *= rate;

  orderStat.grossSales! *= rate;
  orderStat.refundedSales! *= rate;

  orderStat.referralFee! *= rate;
  orderStat.fbaFee! *= rate;
  orderStat.giftWrapFee! *= rate;
  orderStat.otherFee! *= rate;
  orderStat.feeAdjustment! *= rate;
  orderStat.liquidations! *= rate;
  orderStat.returnFees! *= rate;

  orderStat.fbaGlobalReimbursement! *= rate;
  orderStat.fbaReversalReimbursement! *= rate;
  orderStat.fbaReimbursementClawback! *= rate;
  orderStat.fbaOtherReimbursement! *= rate;

  orderStat.spAdvertising! *= rate;
  orderStat.sbAdvertising! *= rate;
  orderStat.sdAdvertising! *= rate;

  orderStat.fbaStorageFee! *= rate;
  orderStat.longTermStorageFee! *= rate;

  orderStat.exportCharge! *= rate;

  orderStat.costOfGoods! *= rate;

  orderStat.profit! *= rate;
}

export function orderToOrderStatsInMarketplace(
  order: Order,
  selectedCurrency: Currency,
  cog: number,
  marketplace: Marketplace,
) {
  const currencyRate_ = marketplaceToCurrencyRate(marketplace, selectedCurrency);
  return getOrderFromOrderStats(order, selectedCurrency, cog, currencyRate_);
}

function getOrderFromOrderStats(
  order: Order,
  selectedCurrency: Currency,
  cog: number,
  currencyRate: number,
): OrderStats {
  // Sales
  const grossSales = order.grossSales! * currencyRate || 0;
  const refundedSales = order.refundedSales! * currencyRate || 0;

  const refundedUnits = order.refundedUnits || 0;
  const sellableReturnUnits = order.sellableReturnUnits || 0;

  const reimbursement = order.reimbursement! * currencyRate || 0;

  // Promotion
  const promotion = order.promotion! * currencyRate || 0;

  // Fee
  const commission = order.commission! * currencyRate || 0;
  const fbaFee = order.fbaFulfillmentFee! * currencyRate || 0;
  const giftWrapCommission = order.giftWrapCommission! * currencyRate || 0;

  const otherFee = order.otherItemFees! * currencyRate || 0;
  const returnFees = order.returnFees! * currencyRate || 0;
  const feeAdjustment = order.feeAdjustment! * currencyRate || 0;

  // Charge
  const exportCharge = order.exportCharge! * currencyRate || 0;

  //net stats
  const netSales = grossSales + refundedSales + promotion; // refunded sales & promotion are negatives
  const fee = commission + fbaFee + giftWrapCommission + otherFee + returnFees + feeAdjustment + exportCharge;
  const costOfGoods = -cog * (order.allOrderedUnits! + refundedUnits - sellableReturnUnits) || 0;
  const profit = netSales + reimbursement + fee + costOfGoods;

  return {
    netSales: netSales,
    promotion: promotion,
    fee: fee,
    advertising: 0,

    // Sales
    grossSales: grossSales,
    refundedSales: refundedSales,

    // FBA
    fbaGlobalReimbursement: reimbursement,
    fbaReversalReimbursement: reimbursement,
    fbaReimbursementClawback: 0,
    fbaOtherReimbursement: 0,

    // refund
    refundedUnits: refundedUnits,
    sellableReturnUnits: sellableReturnUnits,

    // Fee
    referralFee: commission,
    fbaFee: fbaFee,
    giftWrapFee: giftWrapCommission,
    otherFee: otherFee,
    fbaStorageFee: 0,
    returnFees: returnFees,
    feeAdjustment: feeAdjustment,
    exportCharge: exportCharge,

    // Advertising
    spAdvertising: 0,
    sbAdvertising: 0,
    sdAdvertising: 0,

    // Quantity
    // don't call it allOrderedUnits otherwise it will conflict with all sales stats via emptyAdStatExWithOrderStats
    quantity: order.allOrderedUnits,

    // COG
    costOfGoods: costOfGoods,
    profit: profit,

    currency: selectedCurrency,
    asin: order.asin || '',
    marketplace: order.marketplace,
    date: order.date ?? '',
  };
}

export function emptyOrderStat(asin: string | undefined, date: string, currency: Currency): OrderStats {
  return {
    netSales: 0,
    promotion: 0,
    fee: 0,
    advertising: 0,
    asin: asin,
    date: date,
    costOfGoods: 0,
    profit: 0,
    currency: currency,
    grossSales: 0,
    refundedSales: 0,
    fbaGlobalReimbursement: 0, // sum all fba reimbursement
    fbaReversalReimbursement: 0, // REVERSAL_REIMBURSEMENT linked to a sku and an orderId
    fbaReimbursementClawback: 0, // when Amazon takes back the money that they paid you as a reimbursement before
    fbaOtherReimbursement: 0, // global reimbursement (not linked ot an orderId)

    quantity: 0,
    refundedUnits: 0,
    sellableReturnUnits: 0,

    referralFee: 0,
    fbaFee: 0,
    giftWrapFee: 0,
    returnFees: 0,
    feeAdjustment: 0,
    fbaStorageFee: 0,
    exportCharge: 0,

    longTermStorageFee: 0,
    liquidations: 0,
    internationalFreight: 0,

    otherFee: 0,

    spAdvertising: 0,
    sbAdvertising: 0,
    sdAdvertising: 0,
  };
}

export type RequiredOrderStats = Omit<Required<OrderStats>, 'marketplace' | 'sku' | 'hour' | 'currency'>;
