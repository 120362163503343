<div class="relative flex items-start">
  <div class="flex h-5 cursor-pointer items-center">
    <input
      [id]="idValue()"
      [name]="inputName()"
      [checked]="value"
      (change)="updateValue($event)"
      [attr.data-testid]="testid()"
      [disabled]="disabled()"
      [indeterminate]="indeterminate()"
      type="checkbox"
      class="form-checkbox focus-visible:ring-main-300 dark:focus-visible:ring-main-400 text-main-500 dark:text-main-400 focus:ring-main-300 h-5 w-5 cursor-pointer rounded-md border border-gray-300 bg-white shadow-sm duration-150 checked:border-transparent indeterminate:border-transparent focus:ring-4 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-offset-1 focus-visible:ring-offset-white disabled:cursor-not-allowed disabled:opacity-50 dark:border-gray-700 dark:bg-gray-900 dark:checked:border-transparent dark:checked:bg-current dark:indeterminate:border-transparent dark:indeterminate:bg-current dark:focus-visible:ring-offset-gray-900"
    />
    @if (label() || labelSlot) {
      <div class="ms-3 flex flex-col">
        @if (labelSlot) {
          <label [for]="idValue()" class="m-0">
            <ng-container [ngTemplateOutlet]="labelSlot"></ng-container>
          </label>
        } @else {
          <label
            [for]="idValue()"
            class="m-0 cursor-pointer text-sm font-medium text-gray-700 group-hover:text-gray-900 dark:text-gray-200 dark:group-hover:text-gray-100"
            >{{ label() }}</label
          >
        }
      </div>
    }
  </div>
</div>
