<ng-container *transloco="let t">
  <app-keyword-ranking-overlay pageName="share of voice" feature="viewing share of voice data" class="h-full">
    @if (!noData) {
      <div class="sov-wrapper">
        <div class="left-wrapper">
          <div class="left-wrapper-dd">
            <div class="mb-3 inline-flex w-full items-center justify-between">
              <div class="inline-flex items-center">
                <div class="box-title">{{ t("common.keywords") }}</div>
                <IButton
                  [icon]="ICON_ADD"
                  (onClick)="addNewKeyword()"
                  color="main"
                  size="xs"
                  label="{{ t('share-of-voice.add_keyword') }}"
                  class="ml-2"
                  [disabled]="!!isReadonly"
                ></IButton>
              </div>
              <div class="keyword-count-badge">
                {{ allSearchTerms.length }}
              </div>
            </div>

            <ISelect
              [options]="filteredTermsOptions"
              searchable
              [selected]="focusedSearchTermOption"
              (selectedChange)="focusSearchTerm($event)"
            />
          </div>
          <div class="left-wrapper-table">
            <app-sov-main-table
              [sovByBrand]="sovByBrandDate"
              [brandColors]="brandColors"
              [loading]="!!loading"
              (emitHoveredBrand)="hoveredBrands = $event"
              (emitBrandSelection)="setBrandSelection($event)"
            ></app-sov-main-table>
          </div>
        </div>
        <div class="charts-wrapper flex flex-col">
          <div class="flex w-full justify-between">
            <div class="box-title">{{ t("share-of-voice.share_of_voice_report") }}</div>
            <app-export-button
              (export)="exportToCsv()"
              tooltipValue="{{ t('common.export_as_csv') }}"
            ></app-export-button>
          </div>
          <div class="main-box flex flex-col">
            <div class="trend-chart-ctn">
              <app-sov-main-chart
                [loading]="!!loading"
                [sovData]="sovByBrandDate"
                [brandSelection]="brandSelection"
                [hoveredBrands]="hoveredBrands"
                [brandColors]="brandColors"
                (emitSnapshotDate)="snapshotDate = $event"
              ></app-sov-main-chart>
            </div>
            <div class="snapshot-chart-ctn mx-auto w-[90%]">
              <app-sov-pie-chart
                [loading]="!!loading"
                [sovData]="sovByBrandDate"
                [brandSelection]="brandSelection"
                [hoveredBrand]="hoveredBrands"
                [brandsColors]="brandColors"
                [snapshotDate]="snapshotDate"
              ></app-sov-pie-chart>
            </div>
          </div>
        </div>
      </div>
    } @else {
      {{ t("share-of-voice.no_data_found_for_this_marketplace") }}
    }
  </app-keyword-ranking-overlay>
</ng-container>
