import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Marketplace, Targeting } from "@front/m19-api-client";
import { ICON_CLOSE } from "@m19-board/utils/iconsLabels";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-strategy-keyword-modal",
  template: ` <div class="modal-header flex items-center justify-between">
      <h4 class="modal-title pull-left">{{ title }}</h4>
      <span (click)="modalRef.hide()" [class]="[ICON_CLOSE, 'text-white', 'text-lg', 'cursor-pointer']"></span>
    </div>
    <div class="modal-body p-3">
      <app-spinner [display]="loading" class="absolute top-48 flex w-full items-center justify-center"></app-spinner>
      <app-strategy-keyword
        [marketplace]="marketplace"
        [keywords]="keywords"
        [isReadOnly]="isReadOnly || loading"
        (addedKeywords)="addedKeywords.emit($event)"
        (deletedKeywords)="deletedKeywords.emit($event)"
      ></app-strategy-keyword>
    </div>
    <div class="modal-footer flex justify-end p-3" *transloco="let t">
      <IButton label="{{ t('common.close') }}" color="gray" variant="soft" (onClick)="modalRef.hide()" />
    </div>`,
})
export class StrategyKeywordModalComponent {
  @Input()
  title!: string;
  @Input()
  marketplace!: Marketplace;
  @Input()
  keywords: Targeting[] = [];
  @Input() isReadOnly = false;
  @Input() loading = false;

  @Output()
  addedKeywords = new EventEmitter<Targeting[]>();
  @Output()
  deletedKeywords = new EventEmitter<Targeting[]>();

  readonly ICON_CLOSE = ICON_CLOSE;

  constructor(public modalRef: BsModalRef) {}
}
