import { AgGridAngular } from "@ag-grid-community/angular";
import {
  ColDef,
  GridOptions,
  ICellRendererParams,
  RowDragEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from "@ag-grid-community/core";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { ActivatedRoute, Router } from "@angular/router";
import { faPauseCircle, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import {
  AccessLevel,
  AccountMarketplace,
  AlgoMode,
  CampaignType,
  Currency,
  EntityIdType,
  HistoryApi,
  MatchType,
  Strategy,
  StrategyAsin,
  StrategyKeywords,
  StrategyStateEnum,
  StrategyTactic,
  StrategyType,
  TacticType,
  Targeting,
  UpdateStrategyTopOfSearchRankingsActionEnum,
  User,
} from "@front/m19-api-client";
import { getBasicGridOptions } from "@front/m19-grid-config";
import {
  AlgoModeStr,
  getStrategies,
  Marketplaces,
  SegmentConfigType,
  SegmentEx,
  singleStrategyInStrategyGroup,
  StrategyEx,
  StrategyGroupEx,
  StrategyTargetingType,
  StrategyTypeStr,
} from "@front/m19-models";
import {
  AccountSelectionService,
  AsinService,
  AuthService,
  Constant,
  CurrencyService,
  SegmentService,
  SpStrategiesService,
  StrategyService,
  UserSelectionService,
} from "@front/m19-services";
import { IButtonComponent } from "@front/m19-ui";
import { currencyRateToEuro, Utils } from "@front/m19-utils";
import { TranslocoService } from "@jsverse/transloco";
import { ActivityFilter } from "@m19-board/activities/activity/activity.component";
import {
  ActionButton,
  ActionButtonsComponent,
} from "@m19-board/insights/overview/action-buttons/action-buttons.component";
import { AlgoTargetRendererComponent } from "@m19-board/insights/overview/algo-target-renderer/algo-target-renderer.component";
import { GlobalStrategyStats } from "@m19-board/insights/overview/overview-grid.component";
import { KeywordSegmentModalComponent } from "@m19-board/segments/keyword-segment-modal.component";
import { ProductSegmentModalComponent } from "@m19-board/segments/product-segment-modal.component";
import { AlgoStateCellComponent } from "@m19-board/shared/algo-state-cell/algo-state-cell.component";
import { ConfirmPopupComponent } from "@m19-board/shared/confirm-popup/confirm-popup.component";
import {
  SlideToggleParams,
  SlideToggleRendererComponent,
} from "@m19-board/shared/slide-toggle-renderer/slide-toggle-renderer.component";
import { StrategyInfoCellComponent } from "@m19-board/shared/strategy-info-cell/strategy-info-cell.component";
import { StrategyActivityModalComponent } from "@m19-board/strategies/strategies/strategy-activities/strategy-activities-modal.component";
import { StrategyStatsComponent } from "@m19-board/strategies/strategies/strategy-stats/stategy-stats.component";
import { StrategyAsinSelectionMode } from "@m19-board/strategies/strategy-asins/asins-selection.component";
import { SwitchAlgoModeComponent } from "@m19-board/strategies/strategy-page/switch-algo-mode-popup/switch-algo-mode.component";
import { TacticAddPopupComponent } from "@m19-board/strategies/strategy-page/tactic-add-popup.component";
import { keywordRankingAvailableFor } from "@m19-board/tracking/KeywordRankingAvailability";
import {
  ICON_CHART_LINE,
  ICON_CHEVRON_DOWN,
  ICON_COPY_O,
  ICON_EDIT_O,
  ICON_LIST,
  ICON_PAUSE,
  ICON_PLAY,
  ICON_SYNC,
  ICON_TRASH_O,
} from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import moment from "moment-timezone";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import {
  combineLatest,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  ReplaySubject,
  Subscription,
  switchMap,
  take,
  tap,
} from "rxjs";
import { actionColumnProperties } from "../../grid-config/grid-columns";
import { InputModalComponent } from "../../shared/input-modal/input-modal.component";
import {
  StrategyGroupAddProductModalComponent,
  StrategyGroupProductsToAdd,
} from "./add-product/strategy-group-add-product-modal.component";
import { AdvancedSettingsModalComponent } from "./advanced-settings-modal/advanced-settings-modal.component";
import { BlacklistModalComponent, UpdateOperations } from "./blacklist/blacklist-modal.component";
import { StrategyGroupMoveProductModalComponent } from "./move-product/strategy-group-move-product-modal.component";
import { TargetingsModalComponent } from "./targetings-modal/targetings-modal.component";

@UntilDestroy()
@Component({
  templateUrl: "./sp-strategy-group-page.component.html",
})
export class SpStrategyGroupPageComponent implements OnInit {
  readonly ICON_SYNC = ICON_SYNC;

  readonly StrategyType = StrategyType;
  readonly CampaignType = CampaignType;
  readonly TacticType = TacticType;
  readonly AlgoMode = AlgoMode;
  readonly StrategyTypeStr = StrategyTypeStr;
  readonly faPlayCircle = faPlayCircle;
  readonly faPauseCircle = faPauseCircle;
  readonly maxKwTargetingByStrategy = Constant.maxKwTargetingByStrategy;
  readonly ICON_EDIT_O = ICON_EDIT_O;
  readonly ICON_TRASH_O = ICON_TRASH_O;
  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;
  readonly ICON_PLAY = ICON_PLAY;
  readonly ICON_PAUSE = ICON_PAUSE;

  readonly editIconProperties = {
    color: "gray",
    variant: "ghost",
    square: true,
    icon: ICON_EDIT_O,
    iconOnHover: true,
    trailing: true,
  };

  strategyGroup$ = new ReplaySubject<StrategyGroupEx>(1);
  strategyGroupAsins?: StrategyAsin[];
  strategyGroupAsinsStr?: string[];
  loading = true;
  strategyGroup?: StrategyGroupEx;
  isReadOnly = false;
  accountMarketplace?: AccountMarketplace;
  promoBoostActivated = false;
  user?: User;
  strategiesPerAsin: Map<string, StrategyEx[]> = new Map();
  productsToDelete: Set<string> = new Set();
  areAllProductsSelected = false;
  asinEligibility: Map<string, { status: boolean; reason: string }> = new Map();
  // for the activities section
  activityFilter: ActivityFilter[] = [];
  infoMessageLastUpdate: string | undefined = undefined;
  dateRange?: string[];
  maxActivitiesDate?: string;
  currencyCode?: string;
  currencySymbol?: string;

  focusedStrategy?: StrategyEx; // Tactic modal
  focusedDailyBudget?: number;

  focusedAdvertisedProducts?: string[]; // Also for targeting modal

  productSelectionModes?: { selectionMode: StrategyAsinSelectionMode; label: string }[];
  @ViewChild("advertisedProductsModal", { static: false }) advertisedProductsModal!: TemplateRef<any>;
  @ViewChild("editProductsModal", { static: false }) editProductsModal!: TemplateRef<any>;

  focusedTactics?: StrategyTactic[]; // Tactic modal
  segmentIndex: Map<number, SegmentEx> = new Map();
  @ViewChild("tacticsModal", { static: false }) tacticsModal!: TemplateRef<any>;

  @ViewChild("dailyBudgetModalRef", { static: false }) dailyBudgetModalRef!: TemplateRef<any>;

  @ViewChild("advancedSettingsModal", { static: false }) advancedSettingsModal!: TemplateRef<any>;

  modalRef?: BsModalRef;
  modalRef2?: BsModalRef;
  focusTacticType?: TacticType;

  modalSearchInput = new FormControl<string>("");
  tacticSearchInput = "";

  strategies: Strategy[] = [];

  strategyPageUrl = "/strategies/sponsored-product";
  strategyGroupPageUrl = "/strategies/strategy-group/sponsored-product";

  // section expands
  readonly sectionVisibilityLsKey = "sp-strategy-group-page-section-visibility";
  readonly sectionVisibility = {
    products: true,
    blacklist: false,
    brandStrategies: true,
    keywordStrategies: true,
    productStrategies: true,
    stats: false,
    activities: false,
    advanced: false,
  };

  readonly StrategyColumnDefinitions: Record<string, ColDef<StrategyEx>> = {
    state: {
      field: "state",
      headerName: this.translocoService.translate("billing-customer.state", {}, "en"),
      headerValueGetter: (params) => this.translocoService.translate("billing-customer.state"),
      maxWidth: 130,
      cellRendererSelector: (params) => {
        if (!params.value) return undefined;
        return {
          component: AlgoStateCellComponent,
          params: {
            strategy: params.data,
            isReadOnly: this.isReadOnly,
            onStateChange: () => {
              this.strategyService
                .updateStrategyState(
                  this.accountMarketplace!.accountId,
                  this.accountMarketplace!.marketplace,
                  this.accountMarketplace!.resourceOrganizationId!,
                  params.data!.strategyId,
                  params.value === StrategyStateEnum.ENABLED ? StrategyStateEnum.PAUSED : StrategyStateEnum.ENABLED,
                )
                .subscribe({
                  next: () => {
                    this.toastrService.success(
                      this.translocoService.translate("sp-strategy-group-page.strategy_successfully_updated"),
                      this.translocoService.translate("sp-strategy-group-page.strategy_updated"),
                    );
                  },
                  error: (err) => {
                    this.toastrService.error(
                      this.translocoService.translate("sp-strategy-group-page.error_updating_strategy") + err,
                      this.translocoService.translate("sp-strategy-group-page.strategy_update_error"),
                    );
                  },
                });
            },
          },
        };
      },
    },
    strategyName: {
      headerName: this.translocoService.translate("sd-strategy-creation.strategy_name", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("sd-strategy-creation.strategy_name"),
      field: "name",
      cellRenderer: IButtonComponent,
      cellRendererParams: (params: ICellRendererParams) => ({
        label: params.value,
        tooltipValue: this.translocoService.translate("sp-strategy-group-page.edit_strategy_name"),
        ...this.editIconProperties,
        clickAction: () => {
          const strategyNameInput = new FormControl<string>(params.value);
          const ref = this.modalService.show(InputModalComponent, {
            initialState: {
              title: this.translocoService.translate("sp-strategy-group-page.change_strategy_name"),
              inputControl: strategyNameInput,
              maxLength: 80,
            },
            class: "modal-primary modal-dialog-centered",
          });

          ref.content?.emitUpdate
            .pipe(
              switchMap(() => {
                const newName = strategyNameInput.value ?? "";
                const strategy: StrategyEx = params.data;
                if (newName.trim() !== "") {
                  return this.strategyService.updateStrategyName(
                    strategy.accountId,
                    strategy.marketplace,
                    this.accountMarketplace!.resourceOrganizationId!,
                    strategy.strategyId,
                    newName,
                  );
                }
                return of();
              }),
            )
            .subscribe({
              next: () => {
                this.toastrService.success(
                  this.translocoService.translate("sp-strategy-group-page.strategy_name_successfully_updated"),
                  this.translocoService.translate("sp-strategy-group-page.strategy_name_updated"),
                );
              },
              error: (err) => {
                this.toastrService.error(
                  this.translocoService.translate("sp-strategy-group-page.error_updating_strategy_name") + err,
                  this.translocoService.translate("sp-strategy-group-page.strategy_name_update_error"),
                );
              },
            });
        },
      }),
    },
    algo: {
      headerName: this.translocoService.translate("sd-strategy-creation.algorithm", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("sd-strategy-creation.algorithm"),
      field: "algorithm",
      cellRenderer: IButtonComponent,
      cellRendererParams: (params: ICellRendererParams) => ({
        ...this.editIconProperties,
        tooltipValue: this.translocoService.translate("sp-strategy-group-page.change_algorithm"),
        label: this.translocoService.translate(AlgoModeStr[params.value as AlgoMode]?.shortDescription),
        clickAction: () => {
          if (this.isReadOnly) return;
          if (params.data.primeDayBoost) {
            // cannot change algo when prime day boost is active
            this.toastrService.warning(
              this.translocoService.translate("sp-strategy-group-page.cannot_change_algorithm_while_promo_day_boost"),
            );
            return;
          }
          const modalOptions: ModalOptions = {
            initialState: {
              strategy: params.data,
            },
            class: "modal-primary modal-lg modal-dialog-centered",
          };
          this.modalService.show(SwitchAlgoModeComponent, modalOptions);
        },
      }),
    },
    target: {
      headerName: this.translocoService.translate("strategy-table.target", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("strategy-table.target"),
      colId: "target",
      cellRenderer: AlgoTargetRendererComponent,
      cellRendererParams: () => {
        return {
          locale: this.user!.locale,
          currency: this.accountMarketplace?.marketplace
            ? Marketplaces[this.accountMarketplace.marketplace].currency
            : Currency.USD,
          readonly: this.isReadOnly,
          minBid: this.accountMarketplace!.minBid,
          organizationId: this.accountMarketplace!.resourceOrganizationId!,
        };
      },
    },
    products: {
      headerName: this.translocoService.translate("sd-strategy-creation.advertised_products", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("sd-strategy-creation.advertised_products"),
      field: "asins",
      valueFormatter: (params: ValueFormatterParams<StrategyEx, StrategyAsin[]>) => "" + params.value?.length,
      cellRenderer: IButtonComponent,
      cellRendererParams: (params: ICellRendererParams<any>) => ({
        ...this.editIconProperties,
        tooltipValue: "View advertised products",
        label: params.value.length,
        clickAction: () => {
          const products: string[] = params.data.asins.map((a: any) => a.asin!);
          this.focusedStrategy = params.data;
          this.displayAdvertisedProducts(products);
        },
      }),
    },
    targetings: {
      headerName: this.translocoService.translate("sd-strategy-creation.targetings", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("sd-strategy-creation.targetings"),
      field: "targetings",
      cellRenderer: IButtonComponent,
      cellRendererParams: (params: ICellRendererParams<StrategyEx, Targeting[]>) => {
        return {
          ...this.editIconProperties,
          tooltipValue: this.translocoService.translate("sp-strategy-group-page.view_targetings"),
          label: this.formatTargetings(params.value),
          clickAction: () => {
            this.onTargetingsClick(params);
          },
        };
      },
    },
    tosro: {
      headerName: this.translocoService.translate("sp-strategy-group-page.tosro", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("sp-strategy-group-page.tosro"),
      colId: "TOSRO",
      headerTooltip: "Top of Search Rankings Optimizer",
      suppressSizeToFit: true,
      valueGetter: (params) => {
        return params.data?.topOfSearchRankings;
      },
      cellRenderer: SlideToggleRendererComponent,
      cellRendererParams: (params: ICellRendererParams<StrategyEx, StrategyKeywords[], SlideToggleParams>) => {
        const supportedMarketplace = keywordRankingAvailableFor(params.data!.marketplace);
        const hasOneExactTargeting = params.data!.targetings.findIndex((t) => t.matchType == MatchType.exact) >= 0;
        const isDisabled = !supportedMarketplace || this.isReadOnly || !hasOneExactTargeting;

        return {
          isDisabled: isDisabled,
          isChecked: !isDisabled && params.value!.length > 0,
          tooltip: this.getTosroTooltip(supportedMarketplace, hasOneExactTargeting),
          onChange: (event: any) => {
            this.switchOptimizerStatus(params.data!, event);
          },
        };
      },
    },
    info: {
      headerName: this.translocoService.translate("sp-strategy-group-page.info", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("sp-strategy-group-page.info"),
      cellRenderer: StrategyInfoCellComponent,
      cellRendererParams: (params: ICellRendererParams) => ({
        strategy: params.data,
        currencyCode: this.currencyCode,
        locale: this.user?.locale,
        promoBoostActivated: this.promoBoostActivated,
      }),
    },
  };

  private getTosroTooltip(supportedMarketplace: boolean, hasOneExactTargeting: boolean) {
    let res = "";
    if (this.isReadOnly) {
      return this.translocoService.translate("sp-strategy-group-page.you_do_not_have_the_permission_to_activate_tosro");
    } else if (!supportedMarketplace) {
      return this.translocoService.translate("sp-strategy-group-page.tosro_not_supported_for_this_marketplace");
    } else {
      res = this.translocoService.translate("sp-strategy-group-page.activated_keywords_tracked");
    }
    if (!hasOneExactTargeting) {
      res = this.translocoService.translate("sp-strategy-group-page.tosro_only_for_kw");
    }
    return res;
  }

  readonly ActionButtons: Record<string, (strategy: StrategyEx) => ActionButton> = {
    stats: (strategy) => ({
      icon: ICON_CHART_LINE,
      tooltip: this.translocoService.translate("sp-strategy-group-page.display_strategy_stats"),
      onClick: () => {
        this.openStrategyStats(strategy);
      },
    }),
    activities: (strategy) => ({
      icon: ICON_LIST,
      tooltip: this.translocoService.translate("sp-strategy-group-page.display_strategy_activities"),
      onClick: () => {
        this.openStrategyActivities(strategy);
      },
    }),
    delete: (strategy) => ({
      icon: ICON_TRASH_O,
      tooltip: this.translocoService.translate("sp-strategy-group-page.remove_strategy", [
        this.strategyTypeStr(strategy.strategyType),
      ]),
      disabled: this.isReadOnly,
      onClick: () => {
        //  confirmation modal
        const modalOpts: ModalOptions = {
          initialState: {
            title: this.translocoService.translate("activity-service.strategy_deletion"),
            message: this.translocoService.translate(
              "sp-strategy-group-page.are_you_sure_you_want_to_delete_this_strategy",
            ),
          },
        };
        const modalRef = this.modalService.show(ConfirmPopupComponent, modalOpts);
        const sub = modalRef.content?.confirm
          .pipe(
            switchMap(() =>
              this.strategyService.deleteStrategy(strategy, this.accountMarketplace!.resourceOrganizationId!),
            ),
          )
          .subscribe({
            next: () => {
              this.toastrService.success(
                this.translocoService.translate("sp-strategy-group-page.strategy_successfully_deleted", [
                  strategy.name,
                ]),
                this.translocoService.translate("sp-strategy-group-page.strategy_deleted"),
              );
            },
            error: (err) => {
              this.toastrService.error(
                this.translocoService.translate("sp-strategy-group-page.error_deleting", [strategy.name, err]),
                this.translocoService.translate("sp-strategy-group-page.strategy_deletion_error"),
              );
            },
          });
        sub?.add(
          modalRef.content?.cancel.subscribe(() => {
            sub?.unsubscribe();
          }),
        );
      },
    }),
    deleteProductStrategy: (strategy) => ({
      icon: ICON_TRASH_O,
      tooltip: this.translocoService.translate("sp-strategy-group-page.remove_strategy", [
        this.strategyTypeStr(strategy.strategyType),
      ]),
      disabled: this.isReadOnly,
      onClick: () => {
        const impactedStrats = new Map<StrategyEx, string[]>();
        for (const strategyAsin of strategy.asins) {
          for (const strat of this.strategiesPerAsin.get(strategyAsin.asin) ?? []) {
            if (strat.strategyId != strategy.strategyId) {
              if (impactedStrats.has(strat)) {
                impactedStrats.get(strat)!.push(strategyAsin.asin);
              } else {
                impactedStrats.set(strat, [strategyAsin.asin]);
              }
            }
          }
        }
        if (impactedStrats.size == 0) {
          //  confirmation modal
          const modalOpts: ModalOptions = {
            initialState: {
              title: this.translocoService.translate("activity-service.strategy_deletion"),
              message: this.translocoService.translate(
                "sp-strategy-group-page.are_you_sure_you_want_to_delete_this_strategy",
              ),
            },
          };
          const modalRef = this.modalService.show(ConfirmPopupComponent, modalOpts);
          const sub = modalRef
            .content!.confirm.pipe(
              switchMap(() =>
                this.strategyService.deleteStrategy(strategy, this.accountMarketplace!.resourceOrganizationId!),
              ),
            )
            .subscribe({
              next: () => {
                this.toastrService.success(
                  this.translocoService.translate("sp-strategy-group-page.strategy_successfully_deleted", [
                    strategy.name,
                  ]),
                  this.translocoService.translate("sp-strategy-group-page.strategy_deleted"),
                );
              },
              error: (err) => {
                this.toastrService.error(
                  this.translocoService.translate("sp-strategy-group-page.error_deleting", [strategy.name, err]),
                  this.translocoService.translate("sp-strategy-group-page.strategy_deletion_error"),
                );
              },
            });
          sub.add(
            modalRef.content!.cancel.subscribe(() => {
              sub.unsubscribe();
            }),
          );
          return;
        }
        //  confirmation modal
        const modalOpts: ModalOptions = {
          initialState: {
            title: this.translocoService.translate("sp-strategy-group-page.main_strategy_deletion"),
            message: `Products of this strategy are used in ${impactedStrats.size} brand defense or focus ${
              impactedStrats.size > 1 ? "strategies" : "strategy"
            } (${Array.from(impactedStrats.keys())
              .map((s) => `"${s.name}"`)
              .join(", ")}). They will stop running for these products.`,
          },
        };
        const modalRef = this.modalService.show(ConfirmPopupComponent, modalOpts);
        const sub = modalRef
          .content!.confirm.pipe(
            switchMap(() =>
              forkJoin(
                Array.from(impactedStrats.entries()).map(([strat, asins]) =>
                  this.strategyService.deleteAsinsFromStrategy(strat, asins),
                ),
              ),
            ),
            // TODO: also stop strategies if no more ASINs
            switchMap(() =>
              this.strategyService.deleteStrategy(strategy, this.accountMarketplace!.resourceOrganizationId!),
            ),
          )
          .subscribe({
            next: () => {
              this.toastrService.success(
                this.translocoService.translate("sp-strategy-group-page.main_strategy_successfully_deleted", [
                  strategy.name,
                ]),
                this.translocoService.translate("sp-strategy-group-page.strategy_deleted"),
              );
              sub.unsubscribe();
            },
            error: (err) => {
              this.toastrService.error(
                this.translocoService.translate("sp-strategy-group-page.error_deleting_main_strategy", [
                  strategy.name,
                  err,
                ]),
                this.translocoService.translate("sp-strategy-group-page.strategy_deletion_error"),
              );
              sub.unsubscribe();
            },
          });
        sub.add(
          modalRef.content?.cancel.subscribe(() => {
            sub.unsubscribe();
          }),
        );
      },
    }),
    advanced: (strategy) => ({
      icon: "icon-[mdi--ellipsis-horizontal]",
      tooltip: this.translocoService.translate("sp-strategy-group-page.advanced_settings"),
      subItems: [
        ...(strategy.algorithm !== AlgoMode.MONTHLY_BUDGET_TARGET
          ? [
              {
                title: this.translocoService.translate("sp-strategy-group-page.edit_average_daily_budget"),
                disabled: this.isReadOnly || !!strategy.primeDayBoost,
                onClick: () => {
                  this.focusedStrategy = strategy;
                  this.modalRef = this.modalService.show(this.dailyBudgetModalRef, {
                    class: "modal-primary modal-dialog-centered",
                  });
                },
              },
            ]
          : []),
        {
          title: this.translocoService.translate("sp-strategy-group-page.open_advanced_settings"),
          onClick: () => {
            this.focusedStrategy = strategy;

            this.modalRef = this.modalService.show(AdvancedSettingsModalComponent, {
              class: "modal-primary modal-dialog-centered",
              initialState: {
                accountMarketplace: this.accountMarketplace,
                strategy: strategy,
                isReadOnly: this.isReadOnly,
                locale: this.user!.locale,
                currencyCode: this.currencyCode as Currency,
              },
            });

            // this.modalRef = this.modalService.show(this.advancedSettingsModal, {
            //   class: "modal-primary modal-dialog-centered",
            // });
          },
        },
      ],
    }),
    copyStrategyId: (strategy) => ({
      icon: ICON_COPY_O,
      tooltip: this.translocoService.translate("sp-strategy-group-page.copy_strategy_id"),
      onClick: () => {
        navigator.clipboard.writeText(strategy.strategyId.toString());
        this.toastrService.success(
          this.translocoService.translate("sp-strategy-group-page.strategy_id_copied_to_clipboard"),
          this.translocoService.translate("sp-strategy-group-page.strategy_id_copied"),
        );
      },
    }),
  };

  strategyTypeStr(strategy: StrategyType): string {
    if (strategy == StrategyType.BRAND) {
      return this.translocoService.translate("sp-strategy-group-page.brand_defense");
    }
    if (strategy == StrategyType.KEYWORD) {
      return this.translocoService.translate(this.translocoService.translate("sp-strategy-group-page.focus"));
    }
    if (strategy == StrategyType.PRODUCT) {
      this.translocoService.translate("sp-strategy-group-page.main");
    }
    return "";
  }

  // drag handler to manage strategy priority
  rowDragHandler = (strategyType: StrategyType) => (event: RowDragEvent<StrategyEx, any>) => {
    const strategy = event.node.data;
    const index = event.overIndex;
    if (event.overNode?.rowIndex == index) {
      return; // no move
    }
    let priority = 0;
    const strategies = getStrategies(this.strategyGroup!, strategyType);
    if (index == 0) {
      priority = Math.floor((Number.MIN_SAFE_INTEGER + strategies[0].priority!) / 2);
    } else if (index == strategies.length - 1) {
      priority = Math.floor((Number.MAX_SAFE_INTEGER + strategies[index].priority!) / 2);
    } else {
      priority = Math.floor((strategies[index - 1].priority! + strategies[index].priority!) / 2);
    }
    this.strategyService
      .updateStrategyPriority(
        this.accountMarketplace!.accountId,
        this.accountMarketplace!.marketplace,
        this.accountMarketplace!.resourceOrganizationId!,
        strategy!.strategyId,
        priority,
      )
      .pipe(untilDestroyed(this), take(1))
      .subscribe({
        next: () => {
          this.toastrService.success(this.translocoService.translate("sp-strategy-group-page.priority_updated"));
        },
        error: (err: string) => {
          this.toastrService.error(
            err,
            this.translocoService.translate("sp-strategy-group-page.strategy_priority_update_error"),
          );
        },
      });
  };

  // brand defense strategy table

  @ViewChild("brandStrategies", { static: false }) brandStrategiesGrid!: AgGridAngular;

  readonly brandStrategyGridOptions: GridOptions<StrategyEx> = {
    ...getBasicGridOptions("brandStrategies"),
    sideBar: false,
    defaultColDef: {
      flex: 1,
      resizable: true,
    },
    rowDragText: () =>
      this.translocoService.translate("sp-strategy-group-page.drag_strategy_up_or_down_to_modify_its_priority"),
    onRowDragEnd: this.rowDragHandler(StrategyType.BRAND),
    columnDefs: [
      { ...this.StrategyColumnDefinitions["state"]!, rowDrag: true },
      this.StrategyColumnDefinitions["strategyName"]!,
      this.StrategyColumnDefinitions["algo"]!,
      this.StrategyColumnDefinitions["target"]!,
      this.StrategyColumnDefinitions["products"]!,
      this.StrategyColumnDefinitions["targetings"]!,
      this.StrategyColumnDefinitions["tosro"]!,
      this.StrategyColumnDefinitions["info"]!,
      // actions
      {
        ...actionColumnProperties<StrategyEx, string>(),
        cellRendererSelector: (params: ICellRendererParams<StrategyEx>) => {
          const actionButtons: ActionButton[] = [];
          actionButtons.push(this.ActionButtons["advanced"](params.data!));
          actionButtons.push(this.ActionButtons["stats"](params.data!));
          actionButtons.push(this.ActionButtons["activities"](params.data!));
          actionButtons.push(this.ActionButtons["delete"](params.data!));
          if (this.accountMarketplace?.accessLevel == AccessLevel.ADMIN) {
            actionButtons.push(this.ActionButtons["copyStrategyId"](params.data!));
          }
          return {
            component: ActionButtonsComponent,
            params: {
              actionButtons,
            },
          };
        },
      },
    ],
  };
  brandStrategyTableHeight?: number;

  // main keyword strategy table
  @ViewChild("mainKeywordStrategies", { static: false }) mainKeywordStrategiesGrid!: AgGridAngular;

  readonly mainKeywordStrategyGridOptions: GridOptions<StrategyEx> = {
    ...getBasicGridOptions("brandStrategies"),
    sideBar: false,
    defaultColDef: {
      flex: 1,
      resizable: true,
    },
    rowDragText: () =>
      this.translocoService.translate("sp-strategy-group-page.drag_strategy_up_or_down_to_modify_its_priority"),
    onRowDragEnd: this.rowDragHandler(StrategyType.KEYWORD),
    columnDefs: [
      { ...this.StrategyColumnDefinitions["state"], rowDrag: true },
      this.StrategyColumnDefinitions["strategyName"],
      this.StrategyColumnDefinitions["algo"],
      this.StrategyColumnDefinitions["target"],
      this.StrategyColumnDefinitions["products"],
      this.StrategyColumnDefinitions["targetings"],
      this.StrategyColumnDefinitions["tosro"],
      this.StrategyColumnDefinitions["info"],
      // actions
      {
        ...actionColumnProperties<StrategyEx, string>(),
        cellRendererSelector: (params: ICellRendererParams<StrategyEx>) => {
          const actionButtons: ActionButton[] = [];
          actionButtons.push(this.ActionButtons["advanced"](params.data!));
          // actionButtons.push(this.ActionButtons.edit(params.data));
          actionButtons.push(this.ActionButtons["stats"](params.data!));
          actionButtons.push(this.ActionButtons["activities"](params.data!));
          actionButtons.push(this.ActionButtons["delete"](params.data!));
          if (this.accountMarketplace?.accessLevel == AccessLevel.ADMIN) {
            actionButtons.push(this.ActionButtons["copyStrategyId"](params.data!));
          }
          return {
            component: ActionButtonsComponent,
            params: {
              actionButtons,
            },
          };
        },
      },
    ],
  };
  keywordStrategyTableHeight?: number;

  // product strategy table
  @ViewChild("productStrategies", { static: false }) productStrategiesGrid!: AgGridAngular;

  readonly productStrategyGridOptions: GridOptions<StrategyEx> = {
    ...getBasicGridOptions("productStrategies"),
    sideBar: false,
    defaultColDef: {
      flex: 1,
      resizable: true,
    },
    columnDefs: [
      this.StrategyColumnDefinitions["state"],
      this.StrategyColumnDefinitions["strategyName"],
      this.StrategyColumnDefinitions["algo"],
      this.StrategyColumnDefinitions["target"],
      this.StrategyColumnDefinitions["products"],
      {
        headerName: this.translocoService.translate("strategy-page.tactics", {}, "en"),
        headerValueGetter: () => this.translocoService.translate("strategy-page.tactics"),
        valueGetter: (params: ValueGetterParams<StrategyEx>) =>
          params.data?.tactics.filter((t) => t.tacticType == TacticType.LEGACY),
        valueFormatter: (params: ValueFormatterParams<StrategyEx, Targeting[]>) =>
          !params.value || params.value.length == 0 ? "No Tactic" : params.value.length.toString(),
        cellRenderer: IButtonComponent,
        cellRendererParams: (params: ICellRendererParams<StrategyEx>) => ({
          ...this.editIconProperties,
          tooltipValue: "View Tactics",

          label: params.value.length == 0 ? "No Tactic" : params.value.length.toString(),
          clickAction: () => {
            this.focusTacticType = TacticType.LEGACY;
            this.onTacticClick(params);
          },
        }),
      },
      {
        headerName: this.translocoService.translate("common.blacklist", {}, "en"),
        headerValueGetter: () => this.translocoService.translate("common.blacklist"),
        valueGetter: (params: ValueGetterParams<StrategyEx>) =>
          params.data!.tactics.filter((t) => t.tacticType == TacticType.BLACKLIST),
        valueFormatter: (params: ValueFormatterParams<StrategyEx, Targeting[]>) =>
          params.value!.length == 0 ? "" : params.value!.length.toString(),
        cellRenderer: IButtonComponent,
        cellRendererParams: (params: ICellRendererParams) => ({
          ...this.editIconProperties,
          tooltipValue: "View Blacklist",
          label: params.value.length == 0 ? "0" : params.value.length.toString(),
          clickAction: () => {
            this.focusTacticType = TacticType.BLACKLIST;
            this.onTacticClick(params, true);
          },
        }),
      },
      this.StrategyColumnDefinitions["info"],
      {
        ...actionColumnProperties<StrategyEx, string>(),
        cellRendererSelector: (params: ICellRendererParams<StrategyEx>) => {
          const actionButtons: ActionButton[] = [];
          actionButtons.push(this.ActionButtons["advanced"](params.data!));
          actionButtons.push(this.ActionButtons["stats"](params.data!));
          actionButtons.push(this.ActionButtons["activities"](params.data!));
          if (this.strategyGroup!.productStrategies.length > 1) {
            actionButtons.push(this.ActionButtons["deleteProductStrategy"](params.data!));
          }
          if (this.accountMarketplace?.accessLevel == AccessLevel.ADMIN) {
            actionButtons.push(this.ActionButtons["copyStrategyId"](params.data!));
          }
          return {
            component: ActionButtonsComponent,
            params: {
              actionButtons,
            },
          };
        },
      },
    ],
  };
  productStrategyTableHeight?: number;

  // strategy group stats inputs
  hiddenColumns = ["campaignType", "strategyGroup"];
  strategyGroupStatsFilter = (stats: GlobalStrategyStats) =>
    stats.strategy?.strategyGroupId == this.strategyGroup?.strategyGroupId;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private accountMarketplaceSelection: AccountSelectionService,
    private strategyService: StrategyService,
    private asinService: AsinService,
    private authService: AuthService,
    private modalService: BsModalService,
    private userSelectionService: UserSelectionService,
    private currencyService: CurrencyService,
    private historyService: HistoryApi,
    private translocoService: TranslocoService,
    private segmentService: SegmentService,
    private spStrategiesService: SpStrategiesService,
  ) {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      if ((user?.uiVersion ?? 0) > 0) {
        this.strategyPageUrl = "/advertising/sponsored-product";
        this.strategyGroupPageUrl = "/advertising/sponsored-product/strategy-group";
      }
    });
    this.accountMarketplaceSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am) => this.segmentService.getSegments(am.accountId, am.marketplace)),
      )
      .subscribe((segments) => {
        this.segmentIndex = segments;
      });
  }

  ngOnInit(): void {
    this.initSectionVisibility();

    this.accountMarketplaceSelection.promoBoostActivated$.pipe(untilDestroyed(this)).subscribe((b) => {
      this.promoBoostActivated = b;
    });

    this.accountMarketplaceSelection.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));
    this.accountMarketplaceSelection.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am: AccountMarketplace) => this.asinService.getCatalog(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((catalog) => {
        this.asinEligibility = catalog.getSPEligibility();
      });
    combineLatest<[AccountMarketplace, StrategyGroupEx]>([
      this.accountMarketplaceSelection.singleAccountMarketplaceSelection$.pipe(
        tap((am: AccountMarketplace) => {
          this.accountMarketplace = am;
          this.currencyCode = this.currencyService.getCurrencyCode(am.marketplace);
          this.currencySymbol = this.currencyService.getCurrencySymbolFromMarketplace(am.marketplace);
        }),
      ),
      this.strategyGroup$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([accountMarketplace, strategyGroup]) => {
        // compute asin per strategy
        this.strategiesPerAsin = new Map();
        for (const strat of strategyGroup.brandStrategies
          .concat(strategyGroup.keywordStrategies)
          .concat(strategyGroup.productStrategies)) {
          for (const asin of strat.asins) {
            if (this.strategiesPerAsin.has(asin.asin)) {
              this.strategiesPerAsin.get(asin.asin)!.push(strat);
            } else {
              this.strategiesPerAsin.set(asin.asin, [strat]);
            }
          }
        }
      });

    this.route.paramMap
      .pipe(
        switchMap((params) => {
          const id = Number(params.get("id"));
          return this.accountMarketplaceSelection.singleAccountMarketplaceSelection$.pipe(
            switchMap((am) => this.spStrategiesService.getStrategyGroups(am.accountId, am.marketplace)),
            map((strategyGroups) => strategyGroups.get(id)),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe((strategyGroup: StrategyGroupEx | undefined) => {
        // redirect to strategy page if the strategy group cannot be found
        if (!strategyGroup) {
          this.router.navigate([this.strategyPageUrl], { queryParamsHandling: "merge" });
          return;
        }
        // if strategy group contains only one product strategy, route to the substrategy page directly
        if (singleStrategyInStrategyGroup(strategyGroup)) {
          this.router.navigate(
            [this.strategyGroupPageUrl, strategyGroup.strategyGroupId, strategyGroup.productStrategies[0].strategyId],
            { queryParamsHandling: "merge" },
          );
          return;
        }

        this.loading = false;
        this.strategyGroup = strategyGroup;
        this.strategyGroup$.next(strategyGroup);
      });
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user;
    });
    this.strategyGroup$.pipe(untilDestroyed(this)).subscribe((sg) => {
      this.strategyGroupAsins = sg.asins.map((a) => ({ asin: a }));
      this.strategyGroupAsinsStr = sg.asins;
      this.brandStrategyTableHeight = (1 + sg.brandStrategies.length) * 50;
      this.keywordStrategyTableHeight = (1 + sg.keywordStrategies.length) * 50;
      this.productStrategyTableHeight = (1 + sg.productStrategies.length) * 50;
    });

    this.strategyGroup$
      .pipe(
        untilDestroyed(this),
        switchMap((sg) => {
          this.activityFilter = [
            { primaryType: EntityIdType.strategyGroupId, primaryId: sg.strategyGroupId },
            ...sg.brandStrategies
              .concat(sg.keywordStrategies)
              .concat(sg.productStrategies)
              .map((s) => ({ primaryType: EntityIdType.strategyId, primaryId: s.strategyId })),
          ];
          return this.historyService.getHistory({
            accountId: sg.accountId!,
            marketplace: sg.marketplace!,
            minDate: Utils.formatDateForApiFromToday(-2),
            maxDate: Utils.formatDateForApiFromToday(0),
            historyKey: this.activityFilter,
          });
        }),
      )
      .subscribe((history) => {
        if (history.length == 0) {
          this.infoMessageLastUpdate = undefined;
          return;
        }
        const lastUpdate = history
          .filter((a) => a.timestamp)
          .sort((a, b) => b.timestamp!.localeCompare(a.timestamp!))[0].timestamp;

        const nbHoursBeforeNextPush = Utils.estimatedNbHoursBeforeNextPush(
          Marketplaces[this.accountMarketplace!.marketplace].platform,
          this.accountMarketplace!.bidderRuns
            ? this.accountMarketplace!.bidderRuns["SP"]?.bidderRequestTime
            : undefined,
          this.accountMarketplace!.bidderRuns ? this.accountMarketplace!.bidderRuns["SP"]?.lastBidderStart : undefined,
          this.accountMarketplace!.bidderRuns ? this.accountMarketplace!.bidderRuns["SP"]?.lastBidderEnd : undefined,
          lastUpdate!,
        );

        if (nbHoursBeforeNextPush && nbHoursBeforeNextPush > 0) {
          this.infoMessageLastUpdate = this.translocoService.translate("sp-strategy-group-page.time_for_update", {
            count: nbHoursBeforeNextPush,
          });
        } else {
          this.infoMessageLastUpdate = undefined;
        }
      });

    this.userSelectionService.dateRange$.pipe(untilDestroyed(this)).subscribe((r: string[]) => {
      this.dateRange = r;
      // if max date is yesterday, activity data will also include today activities
      if (this.dateRange[1] == moment().subtract(1, "days").format("YYYY-MM-DD")) {
        this.maxActivitiesDate = moment().format("YYYY-MM-DD");
      } else {
        this.maxActivitiesDate = this.dateRange[1];
      }
    });
    this.accountMarketplaceSelection.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) => this.spStrategiesService.getSPStrategies(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((strategies) => {
        this.strategies = Array.from(strategies.values());
      });
  }

  private initSectionVisibility(): void {
    const visibility = localStorage.getItem(this.sectionVisibilityLsKey);
    try {
      const openedSections: Set<string> = new Set(JSON.parse(visibility!) as string[]);
      if (openedSections.size == 0) return; // no config

      Object.keys(this.sectionVisibility).forEach((s) => ((this.sectionVisibility as any)[s] = openedSections.has(s)));
    } catch (e) {
      return;
    }
  }

  toggleSectionVisibility(section: keyof typeof this.sectionVisibility) {
    this.sectionVisibility[section] = !this.sectionVisibility[section];
    const openedSections = Object.keys(this.sectionVisibility).filter((k) => (this.sectionVisibility as any)[k]);
    localStorage.setItem(this.sectionVisibilityLsKey, JSON.stringify(openedSections));
  }

  getDailyBudgetLowerBound(): number {
    if (this.focusedStrategy?.algoMode == AlgoMode.PRODUCT_LAUNCH) {
      if (this.focusedStrategy.suggestedBid) {
        return this.focusedStrategy.suggestedBid * 5;
      }
    }
    if (this.focusedStrategy?.algoMode == AlgoMode.ACOS_TARGET) {
      if (this.focusedStrategy.minDailySpend) {
        return this.focusedStrategy.minDailySpend * 2;
      }
    }
    return Math.ceil(1 / currencyRateToEuro(this.currencyCode as Currency));
  }

  isInValidDailyBudget(): boolean {
    return (
      !this.focusedDailyBudget ||
      this.focusedDailyBudget < 1 ||
      this.focusedDailyBudget < this.getDailyBudgetLowerBound()
    );
  }

  changeDailyBudget(): void {
    if (this.isInValidDailyBudget()) return;
    this.strategyService
      .updateStrategyDailyBudget(
        this.focusedStrategy!.accountId,
        this.focusedStrategy!.marketplace,
        this.accountMarketplace!.resourceOrganizationId!,
        this.focusedStrategy!.strategyId,
        Math.round(this.focusedDailyBudget!),
      )
      .subscribe({
        next: () => {
          this.toastrService.success(this.translocoService.translate("sp-strategy-group-page.daily_budget_updated"));
          this.modalRef?.hide();
        },
        error: (err) => {
          this.toastrService.error(
            err,
            this.translocoService.translate("sp-strategy-group-page.daily_budget_update_error"),
          );
        },
      });
  }

  deleteDailyBudget(): void {
    this.strategyService
      .updateStrategyDailyBudget(
        this.focusedStrategy!.accountId,
        this.focusedStrategy!.marketplace,
        this.accountMarketplace!.resourceOrganizationId!,
        this.focusedStrategy!.strategyId,
        undefined,
      )
      .subscribe({
        next: () => {
          this.toastrService.success(this.translocoService.translate("sp-strategy-group-page.daily_budget_removed"));
          this.modalRef?.hide();
        },
        error: (err) => {
          this.toastrService.error(
            err,
            this.translocoService.translate("sp-strategy-group-page.daily_budget_removal_error"),
          );
        },
      });
  }

  displayAdvertisedProducts(products: string[]) {
    this.focusedAdvertisedProducts = products;
    this.modalRef = this.modalService.show(this.advertisedProductsModal, {
      class: "modal-primary modal-dialog-centered modal-xl",
    });
    this.modalRef.onHide?.subscribe(() => {
      this.modalSearchInput.setValue("");
    });
  }

  addProductModal() {
    if (this.focusedStrategy?.strategyType === StrategyType.PRODUCT) {
      this.productSelectionModes = [
        { selectionMode: StrategyAsinSelectionMode.FromCatalog, label: "sp-substrategy-creation.from_catalog" },
        { selectionMode: StrategyAsinSelectionMode.Bulk, label: "sp-substrategy-creation.from_asin_list" },
        {
          selectionMode: StrategyAsinSelectionMode.FromProductGroups,
          label: "sp-substrategy-creation.from_product_groups",
        },
      ];
    } else {
      this.productSelectionModes = [
        {
          selectionMode: StrategyAsinSelectionMode.FromCustomAsinList,
          label: "sp-strategy-group-page.from_custom_list",
        },
        { selectionMode: StrategyAsinSelectionMode.Bulk, label: "sp-substrategy-creation.from_asin_list" },
      ];
    }

    this.modalRef2 = this.modalService.show(this.editProductsModal, {
      class: "modal-primary modal-dialog-centered modal-xl",
    });
  }

  moveToOtherStrategy(asins: StrategyAsin[]) {
    // we cannot move all asins to a new strategy
    if (asins.length == this.focusedStrategy?.asins.length) {
      this.toastrService.error("You cannot move all products to a new strategy");
      return;
    }

    const modalOpts: ModalOptions = {
      initialState: {
        strategyGroup: this.strategyGroup,
        source: this.focusedStrategy,
      },
      class: "modal-lg",
    };
    const modalRef = this.modalService.show(StrategyGroupMoveProductModalComponent, modalOpts);
    modalRef.content?.target
      .pipe(
        switchMap((target) => {
          if (target.type == "NewProductStrategy") {
            // create a new strategy and delete the strategy from the current one
            return this.strategyService
              .createStrategy(
                { ...target.productStrategyToCreate, asins: asins } as Strategy,
                this.accountMarketplace!.resourceOrganizationId!,
              )
              .pipe(
                switchMap(() =>
                  this.strategyService.deleteAsinsFromStrategy(
                    this.focusedStrategy!,
                    asins.map((a) => a.asin!),
                  ),
                ),
              );
          }
          if (target.type == "NewStrategyGroup") {
            return this.strategyService.moveAsinsToNewStrategyGroup(
              this.accountMarketplace!.resourceOrganizationId!,
              this.accountMarketplace!.accountId,
              this.accountMarketplace!.marketplace,
              this.strategyGroup!,
              target.productStrategyToCreate!,
              asins,
            );
          }
          return this.strategyService.moveAsinsToStrategy(
            asins.map((a) => a.asin!),
            this.focusedStrategy!,
            target.productStrategy!,
          );
        }),
      )
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("sp-strategy-group-page.asins_moved_to_another_main_strategy"),
          );
          this.focusedStrategy!.asins = this.focusedStrategy!.asins.filter((a) => !asins.includes(a));
        },
        error: (error: string) => {
          this.toastrService.error(
            this.translocoService.translate("sp-strategy-group-page.error_moving_asins_error", [error]),
            this.translocoService.translate("sp-strategy-group-page.error_moving_asins"),
          );
        },
      });
  }

  addProductsToSubStrat(toAdd: StrategyAsin[], strategy: StrategyEx) {
    this.strategyService
      .addAsinsToStrategy(
        strategy,
        toAdd.map((a) => a.asin!),
      )
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("sp-strategy-group-page.products_added_to_strategy"),
            this.translocoService.translate("sp-strategy-group-page.products_added"),
          );
          this.focusedStrategy!.asins = [...this.focusedStrategy!.asins, ...toAdd];
          this.focusedAdvertisedProducts = this.focusedStrategy!.asins.map((a) => a.asin!);
        },
        error: (e: string) => {
          this.toastrService.error(
            e,
            this.translocoService.translate("sp-strategy-group-page.error_adding_products_to_strategy"),
          );
        },
      });
  }

  removeProductsFromSubstrat(toRemove: StrategyAsin[], strategy: StrategyEx) {
    if (strategy.strategyType !== StrategyType.PRODUCT) {
      this.strategyService
        .deleteAsinsFromStrategy(
          strategy,
          toRemove.map((a) => a.asin!),
        )
        .subscribe({
          next: () => {
            this.toastrService.success(
              this.translocoService.translate("sp-strategy-group-page.products_removed_from_strategy"),
              this.translocoService.translate("sp-strategy-group-page.products_removed"),
            );
            this.focusedStrategy!.asins = this.focusedStrategy!.asins.filter((a) => !toRemove.includes(a));
            this.focusedAdvertisedProducts = this.focusedStrategy!.asins.map((a) => a.asin);
          },
          error: (e: string) => {
            this.toastrService.error(
              e,
              this.translocoService.translate("sp-strategy-group-page.error_removing_products_from_strategy"),
            );
          },
        });
    } else {
      const toRemoveAsins = toRemove.map((a) => a.asin!);
      const toRemoveSet = new Set<string>(toRemoveAsins);
      const impactedStrats = new Map<StrategyEx, string[]>();
      for (const otherStrat of this.strategyGroup!.brandStrategies.concat(this.strategyGroup!.keywordStrategies)) {
        const intersection = otherStrat.asins.map((x) => x.asin).filter((a) => toRemoveSet.has(a));
        if (intersection.length > 0) {
          impactedStrats.set(otherStrat, intersection);
        }
      }
      if (impactedStrats.size == 0) {
        this.strategyService.deleteAsinsFromStrategy(strategy, toRemoveAsins).subscribe({
          next: () => {
            this.toastrService.success(
              this.translocoService.translate("sp-strategy-group-page.asin_removed_from_strategy"),
              this.translocoService.translate("sp-strategy-group-page.strategy_asin_updated"),
            );
            this.focusedStrategy!.asins = this.focusedStrategy!.asins.filter((a) => !toRemove.includes(a));
          },
          error: (error: string) => {
            this.toastrService.error(
              this.translocoService.translate("sp-strategy-group-page.error_removing_strategy_asin_s_error", [error]),
              this.translocoService.translate("sp-strategy-group-page.strategy_asin_update_error"),
            );
          },
        });
        return;
      }

      const modalOpts: ModalOptions = {
        initialState: {
          title: this.translocoService.translate("sp-strategy-group-page.removing_asins_from_main_strategy"),
          message: `${this.translocoService.translate("sp-strategy-group-page.are_used_in_n_strategy", [impactedStrats?.size])}
          } (${Array.from(impactedStrats.keys())
            .map((s) => `"${s.name}"`)
            .join(
              ", ",
            )}). ${this.translocoService.translate("sp-strategy-group-page.they_will_stop_running_for_these_products")}`,
        },
        class: "modal-dialog-centered",
      };
      const modalRef = this.modalService.show(ConfirmPopupComponent, modalOpts);
      modalRef.content?.confirm
        .pipe(
          switchMap(() =>
            forkJoin(
              Array.from(impactedStrats.entries()).map(([strat, asins]) =>
                this.strategyService.deleteAsinsFromStrategy(strat, asins),
              ),
            ),
          ),
          switchMap(() => this.strategyService.deleteAsinsFromStrategy(strategy, toRemoveAsins)),
          // TODO: also stop strategies if no more ASINs
        )
        .subscribe({
          next: () => {
            this.toastrService.success(
              this.translocoService.translate("sp-strategy-group-page.asin_removed_from_strategy"),
              this.translocoService.translate("sp-strategy-group-page.strategy_asin_updated"),
            );
            this.focusedStrategy!.asins = this.focusedStrategy!.asins.filter((a) => !toRemove.includes(a));
          },
          error: (error: string) => {
            this.toastrService.error(
              this.translocoService.translate("sp-strategy-group-page.error_removing_strategy_asin_s_error", [error]),
              this.translocoService.translate("sp-strategy-group-page.strategy_asin_update_error"),
            );
          },
        });
    }
  }

  onTargetingsClick(params: ICellRendererParams<StrategyEx, Targeting[]>) {
    const strategy = params.data!;

    const targetingType =
      strategy.targetings.length > 0 && strategy.targetings[0].matchType === MatchType.asinSameAs
        ? StrategyTargetingType.PRODUCTS
        : StrategyTargetingType.KEYWORDS;

    this.modalRef = this.modalService.show(TargetingsModalComponent, {
      class: "modal-primary modal-dialog-centered",
      initialState: {
        strategyType: strategy.strategyType,
        targetings: strategy.targetings,
        isReadOnly: this.isReadOnly,
        strategy: strategy,
        strategyGroup: this.strategyGroup,
        targetingType: targetingType,
        accountMarketplace: this.accountMarketplace,
      },
    });

    this.modalRef.onHide?.subscribe(() => {
      this.modalSearchInput.setValue("");
    });

    this.modalRef.content.onIsolationUpdated.subscribe(
      ({ strategy, isolation }: { strategy: StrategyEx; isolation: boolean }) => {
        this.updateStrategyAsinIsolation({ strategy, isolation });
      },
    );
  }

  private updateStrategyAsinIsolation({ strategy, isolation }: { strategy: StrategyEx; isolation: boolean }) {
    if (strategy.strategyType === StrategyType.BRAND) {
      this.strategyGroup!.brandStrategies.find((s) => s.strategyId === strategy.strategyId!)!.asinIsolation = isolation;
      this.brandStrategiesGrid.api.redrawRows();
    } else {
      this.strategyGroup!.keywordStrategies.find((s) => s.strategyId === strategy.strategyId!)!.asinIsolation =
        isolation;
      this.mainKeywordStrategiesGrid.api.redrawRows();
    }
  }

  private formatTargetings(targetings: Targeting[] | null | undefined): string {
    if (!targetings || targetings.length == 0) {
      return "No Targeting";
    }
    const { p, k } = targetings.reduce(
      ({ p, k }, t) => {
        if (t.matchType == MatchType.asinSameAs) {
          p++;
        } else {
          k++;
        }
        return { p, k };
      },
      { p: 0, k: 0 },
    );
    let res = "";
    if (p > 0) {
      res += `${p} Product${p > 1 ? "s" : ""}`;
    }
    if (k > 0) {
      res += p > 0 ? ", " : "";
      res += `${k} Keyword${k > 1 ? "s" : ""}`;
    }
    return res;
  }

  private onTacticClick(params: ICellRendererParams<StrategyEx>, onlyBlacklist = false) {
    this.focusedStrategy = params.data;
    this.focusedTactics = params.value;
    params.value.forEach((t: any) => {
      t.segment = this.segmentIndex.get(t.segmentId);
    });
    this.modalRef = this.modalService.show(this.tacticsModal, {
      class: "modal-primary modal-dialog-centered modal-lg",
    });
    this.modalRef.onHide?.subscribe(() => {
      this.modalSearchInput.setValue("");
    });
  }

  createTactic(): void {
    const allowedSegmentTacticTypes: [SegmentConfigType, TacticType][] = [];
    if (this.focusedStrategy?.campaignType != CampaignType.SD) {
      if (this.focusTacticType == TacticType.LEGACY)
        allowedSegmentTacticTypes.push([SegmentConfigType.KeywordSegment, TacticType.LEGACY]);
      else allowedSegmentTacticTypes.push([SegmentConfigType.KeywordSegment, TacticType.BLACKLIST]);
    }
    if (this.focusedStrategy?.productTargetingEnabled) {
      if (this.focusTacticType == TacticType.LEGACY)
        allowedSegmentTacticTypes.push([SegmentConfigType.ProductSegment, TacticType.LEGACY]);
      else allowedSegmentTacticTypes.push([SegmentConfigType.ProductSegment, TacticType.BLACKLIST]);
    }

    const modalOptions: ModalOptions = {
      initialState: {
        strategy: this.focusedStrategy,
        allowedSegmentTacticTypes: allowedSegmentTacticTypes,
      },
      class: "modal-lg modal-dialog-centered",
    };
    const modalRef = this.modalService.show(TacticAddPopupComponent, modalOptions);
    const subscription = modalRef.content?.segmentCreationRequested
      .pipe(untilDestroyed(this))
      .subscribe((segmentCreationRequest) => {
        const segmentCreationModalOption: ModalOptions = {
          initialState: {
            accountId: this.focusedStrategy!.accountId,
            marketplace: this.focusedStrategy!.marketplace,
          },
          class: "modal-xl modal-dialog-centered",
        };
        if (segmentCreationRequest.segmentType == SegmentConfigType.ProductSegment) {
          const segmentCreationRef = this.modalService.show(ProductSegmentModalComponent, segmentCreationModalOption);
          this.createTacticAfterSegmentCreation(
            subscription!,
            segmentCreationRequest.tacticType,
            segmentCreationRef.content!.segmentCreated,
            segmentCreationRef.content!.segmentEditionCanceled,
          );
        } else {
          const segmentCreationRef = this.modalService.show(KeywordSegmentModalComponent, segmentCreationModalOption);
          this.createTacticAfterSegmentCreation(
            subscription!,
            segmentCreationRequest.tacticType,
            segmentCreationRef.content!.segmentCreated,
            segmentCreationRef.content!.segmentEditionCanceled,
          );
        }
      });
    subscription?.add(
      modalRef.content?.tacticCreated.pipe(untilDestroyed(this)).subscribe((tactic) => {
        this.focusedTactics = [...this.focusedTactics!, tactic];
        subscription?.unsubscribe();
      }),
    );
    subscription?.add(
      modalRef.content!.tacticCreationCancelled.pipe(untilDestroyed(this)).subscribe(() => subscription?.unsubscribe()),
    );
  }

  private createTacticAfterSegmentCreation(
    subscription: Subscription,
    tacticType: TacticType,
    segmentCreated: Observable<SegmentEx>,
    segmentCreationCancelled: Observable<void>,
  ) {
    subscription.add(
      segmentCreated
        .pipe(
          untilDestroyed(this),
          switchMap((s) => {
            return this.strategyService.addTacticToStrategy(
              this.focusedStrategy!.accountId,
              this.focusedStrategy!.marketplace,
              this.focusedStrategy!.strategyId,
              s.segmentId,
              tacticType,
            );
          }),
        )
        .subscribe({
          next: (t: StrategyTactic) => {
            this.toastrService.success(
              this.translocoService.translate("sp-strategy-group-page.tactic_sucessfully_added_to_strategy"),
              this.translocoService.translate("sp-strategy-group-page.tactic_created"),
            );
            this.focusedTactics = [...this.focusedTactics!, t];
            subscription.unsubscribe();
          },
          error: (error) => {
            this.toastrService.error(
              error,
              this.translocoService.translate("sp-strategy-group-page.tactic_creation_error"),
            );
            subscription.unsubscribe();
          },
        }),
    );
    subscription.add(segmentCreationCancelled.pipe(untilDestroyed(this)).subscribe(() => subscription.unsubscribe()));
  }

  changeName(newName: string) {
    this.strategyService
      .updateStrategyGroup({
        accountId: this.strategyGroup!.accountId!,
        marketplace: this.strategyGroup!.marketplace!,
        organizationId: this.accountMarketplace!.resourceOrganizationId!,
        strategyGroupId: this.strategyGroup!.strategyGroupId!,
        strategyGroupName: newName,
      })
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("sp-strategy-group-page.strategy_group_name_updated"),
          );
        },
        error: (e: string) => {
          this.toastrService.error(
            e,
            this.translocoService.translate("sp-strategy-group-page.strategy_group_name_update_eror"),
          );
        },
      });
  }

  updateProductSelection(asins: StrategyAsin[]) {
    this.productsToDelete = new Set(asins.map((a) => a.asin));
    this.areAllProductsSelected = this.allProductsSelected();
  }

  deleteProducts() {
    if (this.productsToDelete.size == 0) {
      return;
    }
    const impactedStrats = new Map<StrategyEx, string[]>();
    for (const asin of this.productsToDelete.values()) {
      for (const strat of this.strategiesPerAsin.get(asin) ?? []) {
        if (impactedStrats.has(strat)) {
          impactedStrats.get(strat)!.push(asin);
        } else {
          impactedStrats.set(strat, [asin]);
        }
      }
    }
    //  confirmation modal
    const modalOpts: ModalOptions = {
      initialState: {
        title: this.translocoService.translate("sp-strategy-group-page.product_deletion"),
        message: `Th${this.productsToDelete.size > 1 ? "ese" : "is"} product${
          this.productsToDelete.size > 1 ? "s are" : " is"
        } used in ${impactedStrats.size}  ${impactedStrats.size > 1 ? "strategies" : "strategy"} (${Array.from(
          impactedStrats.keys(),
        )
          .map((s) => `"${s.name}"`)
          .join(", ")}). ${impactedStrats.size > 1 ? "They" : "It"} will stop running for ${
          this.productsToDelete.size > 1 ? "these products" : "this product"
        }.`,
      },
    };
    const modalRef = this.modalService.show(ConfirmPopupComponent, modalOpts);
    const sub = modalRef.content?.confirm
      .pipe(
        switchMap(() =>
          forkJoin(
            Array.from(impactedStrats.entries()).map(([strat, asins]) =>
              this.strategyService.deleteAsinsFromStrategy(strat, asins),
            ),
          ),
        ),
        // TODO: also stop strategies if no more ASINs
      )
      .subscribe(() => {
        this.productsToDelete.clear();
        this.toastrService.success(
          this.translocoService.translate("sp-strategy-group-page.product_s_removed_from_strategy_group"),
        );
        if (this.focusedStrategy) {
          this.focusedStrategy.asins = this.focusedStrategy.asins.filter((a) => !this.productsToDelete.has(a.asin));
        }
        sub?.unsubscribe();
      });
    sub?.add(
      modalRef.content?.cancel.subscribe(() => {
        sub.unsubscribe();
      }),
    );
  }

  tacticDeleted(segmentId: number) {
    this.focusedTactics = this.focusedTactics?.filter((t) => t.segmentId !== segmentId);
  }

  private allProductsSelected() {
    return this.productsToDelete.size == this.strategyGroup?.asins.length;
  }

  private openStrategyStats(strategy: StrategyEx) {
    const modalOpts: ModalOptions = {
      initialState: {
        strategy,
        isReadOnly: this.isReadOnly,
      },
      class: "modal-xl",
    };
    this.modalService.show(StrategyStatsComponent, modalOpts);
  }

  private openStrategyActivities(strategy: StrategyEx) {
    const modalOpts: ModalOptions = {
      initialState: {
        strategy,
      },
      class: "modal-xl",
    };
    this.modalService.show(StrategyActivityModalComponent, modalOpts);
  }

  openAddProductModal() {
    const modalOptions: ModalOptions = {
      initialState: {
        accountMarketplace: this.accountMarketplace,
        strategyGroup: this.strategyGroup,
      },
      class: "modal-xl modal-dialog-centered",
    };
    const ref = this.modalService.show(StrategyGroupAddProductModalComponent, modalOptions);
    ref
      .content!.newAsins.pipe(
        untilDestroyed(this),
        filter((update): update is StrategyGroupProductsToAdd => update != "CANCEL"),
        take(1),
        switchMap((update: StrategyGroupProductsToAdd) => {
          // product strategy creation
          if (update.type == "NewProductStrategy") {
            const toCreate = update.productStrategyToCreate;
            const reached = this.strategyService.isStrategyLimitReached(
              this.accountMarketplace!.accountId,
              this.accountMarketplace!.marketplace,
              this.accountMarketplace!.resourceOrganizationId!,
              this.strategies,
              CampaignType.SP,
            );
            if (reached) {
              toCreate!.state = StrategyStateEnum.PAUSED;
            }
            return this.strategyService.createStrategy(
              toCreate as Strategy,
              this.accountMarketplace!.resourceOrganizationId!,
            );
          }
          // product strategy update
          return this.strategyService.addAsinsToStrategy(update.productStrategy!, update.asins);
        }),
      )
      .subscribe({
        next: () => {
          this.toastrService.success(this.translocoService.translate("sp-strategy-group-page.products_added"));
        },
        error: (e: string) => {
          this.toastrService.error(e, this.translocoService.translate("sp-strategy-group-page.error_adding_products"));
        },
      });
  }

  openBlackListCreationModal(creation: boolean) {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.accountMarketplace!.accountId,
        marketplace: this.accountMarketplace!.marketplace,
        isReadOnly: this.isReadOnly,
        modalTitle: creation
          ? this.translocoService.translate("sp-strategy-group-page.blacklist_creation")
          : this.translocoService.translate("sp-strategy-group-page.blacklist_update"),
        blacklist: creation ? [] : this.strategyGroup!.blacklist,
      },
      class: "modal-xl",
    };
    const ref = this.modalService.show(BlacklistModalComponent, modalOptions);
    ref
      .content!.updatedBlacklist.pipe(
        untilDestroyed(this),
        filter((update): update is UpdateOperations<Targeting> => update != "CANCEL"),
        switchMap((update: UpdateOperations<Targeting>) =>
          this.spStrategiesService.updateStrategyGroupBlacklist({
            strategyGroupId: this.strategyGroup!.strategyGroupId!,
            accountId: this.accountMarketplace!.accountId,
            marketplace: this.accountMarketplace!.marketplace,
            toAdd: update.toAdd,
            toDelete: update.toDelete,
          }),
        ),
        take(1), // complete after the update (and unsubscribe)
      )
      .subscribe({
        next: () => {
          this.toastrService.success(this.translocoService.translate("sp-strategy-group-page.blacklist_updated"));
        },
        error: (e: string) => {
          this.toastrService.error(
            e,
            this.translocoService.translate("sp-strategy-group-page.strategy_group_blacklist_update_error"),
          );
        },
      });
  }

  openSubStrategyCreationPage(strategyType: StrategyType) {
    this.router.navigate([`${this.strategyGroupPageUrl}/${this.strategyGroup!.strategyGroupId}/create-strategy`], {
      queryParamsHandling: "merge",
      queryParams: {
        strategyType,
      },
    });
  }

  switchOptimizerStatus(strategy: StrategyEx, event: MatSlideToggleChange): void {
    const enable = event.checked;

    const keywords: string[] = enable
      ? strategy.targetings.filter((t) => t.matchType == MatchType.exact).map((t) => t.targetingValue!)
      : strategy.topOfSearchRankings.map((t) => t.keyword!);
    this.spStrategiesService
      .updateTopOfSearchRankings(
        strategy,
        this.accountMarketplace!.resourceOrganizationId!,
        keywords,
        enable ? UpdateStrategyTopOfSearchRankingsActionEnum.ADD : UpdateStrategyTopOfSearchRankingsActionEnum.DELETE,
      )
      .subscribe({
        next: () => {
          if (enable) {
            this.toastrService.success(
              this.translocoService.translate("sp-strategy-group-page.activated_on_strategy", [strategy.name]),
              this.translocoService.translate("sp-strategy-group-page.top_of_search_rankings_optimizer_activated"),
            );
          } else {
            this.toastrService.success(
              this.translocoService.translate("sp-strategy-group-page.dectivated_on_strategy_strategy_name", [
                strategy.name,
              ]),
              this.translocoService.translate("sp-strategy-group-page.top_of_search_rankings_optimizer_deactivated"),
            );
          }
        },
        error: (error) => {
          event.source.toggle();
          this.toastrService.error(
            `Error: ${error}`,
            this.translocoService.translate("sp-strategy-group-page.top_of_search_rankings_optimizer_update_error"),
          );
        },
      });
  }

  strategyAsinAccessValue(asin: StrategyAsin): string {
    return asin.asin;
  }

  tacticAccessValue: (tactic: StrategyTactic) => string = (tactic) => {
    return this.segmentIndex.get(tactic.segmentId)?.name ?? "";
  };

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  getTacticSegment(tactic: StrategyTactic): SegmentEx | undefined {
    return this.segmentIndex.get(tactic.segmentId!);
  }
}
