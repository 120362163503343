import { Injectable } from '@angular/core';
import {
  CampaignType,
  Marketplace,
  MatchType,
  Response,
  Strategy,
  StrategyApi,
  StrategyGroup,
  StrategyGroupApi,
  StrategyStateEnum,
  StrategyType,
  Targeting,
  UpdateStrategyGroupBlacklistActionEnum,
  UpdateStrategyKwTargetingActionEnum,
  UpdateStrategyTopOfSearchRankingsActionEnum,
} from '@front/m19-api-client';
import { ProductGroupEx } from '@front/m19-models/ProductGroupEx';
import { StrategyEx, StrategyTypeStr } from '@front/m19-models/StrategyEx';
import { StrategyGroupBlacklistUpdateParams, StrategyGroupEx } from '@front/m19-models/StrategyGroupEx';
import { StrategyUpdateParams } from '@front/m19-models/StrategyUpdateParams';
import {
  accountMarketplaceKey,
  AccountMarketplaceLazyReadOnlyCache,
  AccountMarketplaceWritableCache,
  catchAjaxError,
} from '@front/m19-utils';
import { combineLatest, map, Observable, of, shareReplay, switchMap, take, throwError } from 'rxjs';
import { AsinService } from './asin.service';
import { Constant } from './constant';
import { StrategyCache } from './strategy.cache';
import { CampaignTypeStrategiesService } from './strategy.service';

@Injectable({
  providedIn: 'root',
})
export class SpStrategiesService implements CampaignTypeStrategiesService {
  private readonly spStrategiesCache = new Map<string, Observable<Map<number, Strategy>>>();
  readonly strategyGroupCache = new AccountMarketplaceWritableCache<Map<number, StrategyGroupEx>>(
    (accountId, marketplace) => this.loadStrategyGroups(accountId, marketplace),
  );

  private readonly spStrategyPerAsinsCache = new AccountMarketplaceLazyReadOnlyCache<
    Observable<Map<string, Strategy[]>>
  >((accountId, marketplace) => this.loadStrategiesPerAsin(accountId, marketplace));

  constructor(
    private strategyCache: StrategyCache,
    private asinService: AsinService,
    private strategyGroupService: StrategyGroupApi,
    private strategyApi: StrategyApi,
  ) {}

  public getSPStrategiesPerAsin(accountId: string, marketplace: Marketplace): Observable<Map<string, Strategy[]>> {
    return this.spStrategyPerAsinsCache.getValue(accountId, marketplace);
  }

  public getSPStrategies(accountId: string, marketplace: Marketplace): Observable<Map<number, Strategy>> {
    const key = accountMarketplaceKey(accountId, marketplace);
    if (!this.spStrategiesCache.has(key)) {
      const spStrategies = this.loadSpStrategies(accountId, marketplace);
      this.spStrategiesCache.set(key, spStrategies);
      // need to update strategy group on sp strategy updates
      spStrategies.subscribe((s) => {
        this.strategyGroupCache.update({ accountId, marketplace }, (groups) =>
          this.buildStrategyGroups(Array.from(groups.values()), s),
        );
      });
    }
    return this.spStrategiesCache.get(key)!;
  }

  public getStrategyGroups(accountId: string, marketplace: Marketplace): Observable<Map<number, StrategyGroupEx>> {
    return this.strategyGroupCache.get({ accountId, marketplace });
  }

  public checkStrategyUpdate(strategyUpdateParams: StrategyUpdateParams): Observable<string[]> {
    return this.getSPStrategies(strategyUpdateParams.accountId, strategyUpdateParams.marketplace).pipe(
      take(1),
      map((strategies) => {
        const strategy = strategies.get(strategyUpdateParams.strategyId)!;
        // check strategy state
        if (strategyUpdateParams.state) {
          if (strategyUpdateParams.state == StrategyStateEnum.ENABLED && strategy.state !== StrategyStateEnum.ENABLED) {
            if ((strategy.asins?.length ?? 0) > ProductGroupEx.MaxProductGroupItems) {
              return [`Cannot activate a strategy with more than ${ProductGroupEx.MaxProductGroupItems} ASINs.`];
            }
            // do not activate all other products for VENDORS
            if (
              strategy.accountId.startsWith('ENTITY') &&
              strategy.campaignType == CampaignType.SP &&
              strategy.defaultStrategy
            ) {
              return ["Vendor accounts cannot activate 'All other products' strategies"];
            }
          }
        }
        // check strategy ASIN modifications
        if (
          (strategyUpdateParams.asinsToAdd.length > 0 || strategyUpdateParams.asinsToDelete.length > 0) &&
          strategy.defaultStrategy
        ) {
          return ['Cannot modify ASIN list for "All Other Products" strategy'];
        }
        return [];
      }),
    );
  }

  public checkStrategyCreation(strategy: Strategy): Observable<string[]> {
    if (
      !strategy.strategyGroupId ||
      (strategy.strategyType !== StrategyType.BRAND && strategy.strategyType !== StrategyType.KEYWORD)
    ) {
      return of([]);
    }
    return this.strategyGroupCache.get({ accountId: strategy.accountId, marketplace: strategy.marketplace }).pipe(
      take(1),
      map((strategyGroups) => {
        const strategyGroup = strategyGroups.get(strategy.strategyGroupId!);
        if (!strategyGroup) {
          return ['Invalid strategy group'];
        }
        const errors: string[] = [];
        // check that we have less than 10 kw strategies and less than 5 brand defense strategies
        if (strategy.strategyType == StrategyType.BRAND) {
          if (strategyGroup.brandStrategies.length >= Constant.maxBrandDefenseStrategiesByStrategyGroup) {
            errors.push(
              `The same strategy group cannot have more than ${Constant.maxBrandDefenseStrategiesByStrategyGroup} brand defense strategies`,
            );
          }
        } else if (strategy.strategyType == StrategyType.KEYWORD) {
          if (strategyGroup.keywordStrategies.length >= Constant.maxKeywordStrategiesByStrategyGroup) {
            errors.push(
              `The same strategy group cannot have more than ${Constant.maxKeywordStrategiesByStrategyGroup} focus strategies`,
            );
          }
        }
        return errors;
      }),
    );
  }

  public addTargetingToStrategy(
    strategy: Strategy,
    targetings: Targeting[],
    organizationId: number,
  ): Observable<Strategy> {
    if (strategy.strategyType != StrategyType.BRAND && strategy.strategyType != StrategyType.KEYWORD) {
      return throwError(() => `Cannot add targetings to ${StrategyTypeStr[strategy.strategyType!]} strategies`);
    }
    if (
      strategy.targetings!.filter((t) => t.matchType === MatchType.asinSameAs).length +
        targetings.filter((t) => t.matchType === MatchType.asinSameAs).length >
      Constant.maxAsinTargetingByStrategy
    ) {
      return throwError(
        () => `Exceeding the limit of ${Constant.maxAsinTargetingByStrategy} product targetings per strategy`,
      );
    }
    if (
      strategy.targetings!.filter((t) => t.matchType === MatchType.exact || t.matchType === MatchType.phrase).length +
        targetings.filter((t) => t.matchType === MatchType.exact || t.matchType === MatchType.phrase).length >
      Constant.maxKwTargetingByStrategy
    ) {
      return throwError(
        () => `Exceeding the limit of ${Constant.maxKwTargetingByStrategy} keyword targetings per strategy`,
      );
    }
    const existing = strategy.targetings!.find(
      (t1) => targetings.findIndex((t2) => t1.matchType == t2.matchType && t1.targetingValue == t2.targetingValue) > -1,
    );
    if (existing) {
      return throwError(
        () =>
          `Unable to add targeting ${existing.targetingValue} [${existing.matchType}] as this targeting is already part of the strategy`,
      );
    }
    return this.strategyApi
      .updateStrategyKwTargeting({
        accountId: strategy.accountId,
        marketplace: strategy.marketplace,
        strategyId: strategy.strategyId!,
        action: UpdateStrategyKwTargetingActionEnum.ADD,
        targeting: targetings,
      })
      .pipe(
        catchAjaxError('Error adding targeting to strategy: '),
        switchMap(() => {
          // if there are some exact kw and if TOSRO is activated on the strategy
          const exactKw = targetings.filter((t) => t.matchType == MatchType.exact).map((t) => t.targetingValue);
          if (exactKw.length > 0 && strategy.topOfSearchRankings && strategy.topOfSearchRankings.length > 0) {
            return this.updateTopOfSearchRankings(
              strategy,
              organizationId,
              exactKw,
              UpdateStrategyTopOfSearchRankingsActionEnum.ADD,
            );
          }
          return of(strategy);
        }),
        switchMap(() =>
          this.strategyCache.strategyListCache.get({
            accountId: strategy.accountId,
            marketplace: strategy.marketplace,
          }),
        ),
        take(1),
        map((strategies) => {
          const modifiedStrat = strategies.get(strategy.strategyId!)!;
          const newTargetings = [...(modifiedStrat.targetings ?? [])];
          for (const targeting of targetings) {
            if (
              newTargetings.findIndex(
                (t1) => t1.matchType == targeting.matchType && t1.targetingValue == targeting.targetingValue,
              ) < 0
            ) {
              newTargetings.push(targeting);
            }
          }
          modifiedStrat.targetings = newTargetings;
          this.strategyCache.strategyListCache.update(
            { accountId: strategy.accountId, marketplace: strategy.marketplace },
            (strategies) => {
              strategies.set(strategy.strategyId!, modifiedStrat);
              return strategies;
            },
          );
          return modifiedStrat;
        }),
      );
  }

  public removeTargetingFromStrategy(
    strategy: Strategy,
    targetings: Targeting[],
    organizationId: number,
  ): Observable<Strategy> {
    if (strategy.strategyType != StrategyType.BRAND && strategy.strategyType != StrategyType.KEYWORD) {
      return throwError(() => `Cannot remove targetings from ${StrategyTypeStr[strategy.strategyType!]} strategies`);
    }
    return this.strategyApi
      .updateStrategyKwTargeting({
        accountId: strategy.accountId,
        marketplace: strategy.marketplace,
        strategyId: strategy.strategyId!,
        action: UpdateStrategyKwTargetingActionEnum.DELETE,
        targeting: targetings,
      })
      .pipe(
        catchAjaxError(),
        switchMap(() => {
          // if there are some exact kw and if TOSRO is activated on the strategy
          const exactKw = targetings.filter((t) => t.matchType == MatchType.exact).map((t) => t.targetingValue);
          if (exactKw.length > 0 && strategy.topOfSearchRankings && strategy.topOfSearchRankings.length! > 0) {
            return this.updateTopOfSearchRankings(
              strategy,
              organizationId,
              exactKw,
              UpdateStrategyTopOfSearchRankingsActionEnum.DELETE,
            );
          }
          return of(strategy);
        }),
        switchMap(() =>
          this.strategyCache.strategyListCache.get({
            accountId: strategy.accountId,
            marketplace: strategy.marketplace,
          }),
        ),
        take(1),
        map((strategies) => {
          const modifiedStrat = strategies.get(strategy.strategyId!)!;
          const newTargetings = (modifiedStrat.targetings ?? []).filter(
            (t1) =>
              targetings.findIndex((t2) => t1.matchType == t2.matchType && t1.targetingValue == t2.targetingValue) < 0,
          );
          for (const targeting of targetings) {
            if (
              newTargetings.findIndex(
                (t1) => t1.matchType == targeting.matchType && t1.targetingValue == targeting.targetingValue,
              ) < 0
            ) {
              newTargetings.push(targeting);
            }
          }
          modifiedStrat.targetings = newTargetings;
          this.strategyCache.strategyListCache.update(
            { accountId: strategy.accountId, marketplace: strategy.marketplace },
            (strategies) => {
              strategies.set(strategy.strategyId!, modifiedStrat);
              return strategies;
            },
          );
          return modifiedStrat;
        }),
      );
  }

  public updateTopOfSearchRankings(
    strategy: Strategy,
    organizationId: number,
    keywords: string[],
    action: UpdateStrategyTopOfSearchRankingsActionEnum,
  ): Observable<Strategy> {
    return this.strategyApi
      .updateStrategyTopOfSearchRankings({
        accountId: strategy.accountId,
        marketplace: strategy.marketplace,
        strategyId: strategy.strategyId!,
        organizationId,
        action,
        requestBody: keywords,
      })
      .pipe(
        catchAjaxError(),
        switchMap(() => {
          if (!strategy.topOfSearchRankings) {
            strategy.topOfSearchRankings = [];
          }
          if (action == UpdateStrategyTopOfSearchRankingsActionEnum.ADD) {
            strategy.topOfSearchRankings.push(...keywords.map((k) => ({ keyword: k })));
          } else {
            strategy.topOfSearchRankings = strategy.topOfSearchRankings.filter((k) => !keywords.includes(k.keyword!));
          }
          // update strategy cache
          this.strategyCache.strategyListCache.update(
            { accountId: strategy.accountId, marketplace: strategy.marketplace },
            (strategies) => {
              const updatedStrategy = strategies.get(strategy.strategyId!)!;
              if (action == UpdateStrategyTopOfSearchRankingsActionEnum.ADD) {
                updatedStrategy.topOfSearchRankings!.push(...keywords.map((k) => ({ keyword: k })));
              } else {
                updatedStrategy.topOfSearchRankings = updatedStrategy.topOfSearchRankings!.filter(
                  (k) => !keywords.includes(k.keyword!),
                );
              }
              return strategies;
            },
          );
          return this.strategyCache.strategyListCache.get({
            accountId: strategy.accountId,
            marketplace: strategy.marketplace,
          });
        }),
        take(1),
        map((strategies) => strategies.get(strategy.strategyId!)!),
      );
  }

  public updateStrategyGroupBlacklist(
    params: StrategyGroupBlacklistUpdateParams,
  ): Observable<StrategyGroupEx | undefined> {
    if (params.toAdd.length == 0 && params.toDelete.length == 0) {
      return this.getStrategyGroups(params.accountId, params.marketplace).pipe(
        map((strategyGroups) => strategyGroups.get(params.strategyGroupId)),
      );
    }
    const blacklistDelete: Observable<Response | undefined> =
      params.toDelete && params.toDelete.length > 0
        ? this.strategyGroupService.updateStrategyGroupBlacklist({
            accountId: params.accountId,
            marketplace: params.marketplace,
            strategyGroupId: params.strategyGroupId,
            targeting: params.toDelete,
            action: UpdateStrategyGroupBlacklistActionEnum.DELETE,
          })
        : of(undefined);

    return blacklistDelete.pipe(
      switchMap(() => {
        return params.toAdd && params.toAdd.length > 0
          ? this.strategyGroupService.updateStrategyGroupBlacklist({
              accountId: params.accountId,
              marketplace: params.marketplace,
              strategyGroupId: params.strategyGroupId,
              targeting: params.toAdd,
              action: UpdateStrategyGroupBlacklistActionEnum.ADD,
            })
          : of(void 0);
      }),
      catchAjaxError('Error updating Strategy Group blacklist: '),
      switchMap(() => {
        this.strategyGroupCache.update(
          { accountId: params.accountId, marketplace: params.marketplace },
          (strategyGroups) => {
            if (!strategyGroups.has(params.strategyGroupId)) {
              return strategyGroups;
            }
            const strategyGroupEx = strategyGroups.get(params.strategyGroupId)!;
            strategyGroupEx.blacklist = strategyGroupEx
              .blacklist!.concat(params.toAdd)
              .filter(
                (t) =>
                  params.toDelete.findIndex((d) => d.matchType == t.matchType && d.targetingValue == t.targetingValue) <
                  0,
              );
            return strategyGroups;
          },
        );

        return this.strategyGroupCache
          .get({ accountId: params.accountId, marketplace: params.marketplace })
          .pipe(map((strategyGroups) => strategyGroups.get(params.strategyGroupId)));
      }),
    );
  }

  /* Loaders */

  private loadSpStrategies(accountId: string, marketplace: Marketplace): Observable<Map<number, Strategy>> {
    if (accountId.startsWith('ENTITY')) {
      // vendor accounts have no all other product strategies so no need to load the catalog
      return this.strategyCache.strategyListCache.get({ accountId, marketplace }).pipe(
        map((strategies) => {
          return new Map([...strategies].filter(([number, strategy]) => strategy.campaignType === CampaignType.SP));
        }),
      );
    }
    return combineLatest([
      this.strategyCache.strategyListCache.get({ accountId, marketplace }),
      // TODO: optimize this to only emit when SP strategies are updated
      this.asinService.getCatalog(accountId, marketplace),
    ]).pipe(
      map(([strategies, catalog]) => {
        const spStrategies = new Map<number, Strategy>();
        let defaultStrategy: Strategy | undefined;
        const defaultStrategyAsins = new Set<string>(catalog.childAsins);
        for (const [strategyId, strategy] of strategies) {
          if (strategy.campaignType !== CampaignType.SP) {
            continue;
          }
          if (strategy.defaultStrategy) {
            defaultStrategy = strategy;
            continue;
          }
          for (const asin of strategy.asins ?? []) {
            defaultStrategyAsins.delete(asin.asin!);
          }
          spStrategies.set(strategyId, strategy);
        }
        if (defaultStrategy) {
          // update strategy asins in strategy for default strategy
          defaultStrategy = { ...defaultStrategy, asins: Array.from(defaultStrategyAsins).map((asin) => ({ asin })) };
          spStrategies.set(defaultStrategy.strategyId!, defaultStrategy);
        }
        return spStrategies;
      }),
      shareReplay(1),
    );
  }

  private loadStrategiesPerAsin(accountId: string, marketplace: Marketplace): Observable<Map<string, Strategy[]>> {
    return this.getSPStrategies(accountId, marketplace).pipe(
      map((strategies) => {
        const strategiesPerAsin = new Map<string, Strategy[]>();
        for (const strategy of strategies.values()) {
          for (const strategyAsin of strategy.asins ?? []) {
            const asin = strategyAsin.asin!;
            if (!strategiesPerAsin.has(asin)) {
              strategiesPerAsin.set(asin, []);
            }
            strategiesPerAsin.get(asin)!.push(strategy);
          }
        }
        return strategiesPerAsin;
      }),
    );
  }

  private loadStrategyGroups(accountId: string, marketplace: Marketplace): Observable<Map<number, StrategyGroupEx>> {
    return combineLatest([
      this.getSPStrategies(accountId, marketplace),
      this.strategyGroupService.listStrategyGroup({ accountId, marketplace }),
    ]).pipe(
      // TODO: optimize this to only emit when SP strategies with strategyGroupId are updated
      map(([strategies, strategyGroups]) => {
        return this.buildStrategyGroups(strategyGroups, strategies);
      }),
    );
  }

  private buildStrategyGroups(
    strategyGroups: StrategyGroup[],
    strategies: Map<number, Strategy>,
  ): Map<number, StrategyGroupEx> {
    const strategiesPerStrategyGroup = new Map<number, Strategy[]>();
    for (const [strategyId, strategy] of strategies) {
      // build SP strategyGroup <-> strategy map
      if (strategy.strategyGroupId) {
        if (strategiesPerStrategyGroup.has(strategy.strategyGroupId)) {
          strategiesPerStrategyGroup.get(strategy.strategyGroupId!)!.push(strategy);
        } else {
          strategiesPerStrategyGroup.set(strategy.strategyGroupId, [strategy]);
        }
      }
    }
    const strategyGroupIndex = new Map<number, StrategyGroupEx>();
    for (const strategyGroup of strategyGroups) {
      const strategyGroupEx = this.buildStrategyGroupEx(
        strategyGroup,
        strategiesPerStrategyGroup.get(strategyGroup.strategyGroupId!) ?? [],
      );
      strategyGroupIndex.set(strategyGroup.strategyGroupId!, strategyGroupEx);
    }
    return strategyGroupIndex;
  }

  public buildStrategyGroupEx(strategyGroup: StrategyGroup, strategies: Strategy[]): StrategyGroupEx {
    const strategyPerTypes: StrategyGroupEx = strategies.reduce(
      (acc: StrategyGroupEx, s) => {
        switch (s.strategyType) {
          case StrategyType.PRODUCT:
            acc.asins.push(...s.asins!.map((p) => p.asin!)!);
            acc.productStrategies.push(new StrategyEx(s));
            break;
          case StrategyType.BRAND:
            acc.brandStrategies.push(new StrategyEx(s));
            break;
          case StrategyType.KEYWORD:
            acc.keywordStrategies.push(new StrategyEx(s));
            break;
        }
        return acc;
      },
      { asins: [], productStrategies: [], brandStrategies: [], keywordStrategies: [] } as unknown as StrategyGroupEx,
    ) as StrategyGroupEx;
    // sort brand and keyword strategies by priority
    strategyPerTypes.brandStrategies.sort((s1, s2) => s1.priority! - s2.priority!);
    strategyPerTypes.keywordStrategies.sort((s1, s2) => s1.priority! - s2.priority!);
    return { ...strategyGroup, strategies, ...strategyPerTypes };
  }
}
