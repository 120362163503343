<div class="p-4" *transloco="let t">
  <div class="card-group">
    <div class="card">
      <div>
        <ol class="c-stepper mt-2">
          @for (s of steps; track s; let i = $index) {
            <li class="c-stepper__item" [class.done]="step > i + 1" [class.active]="step === i + 1">
              <button class="btn btn-ghost-secondary" [class.disabled]="step <= i" (click)="step = i + 1">
                {{ s }}
              </button>
            </li>
          }
        </ol>
      </div>
      <div class="card-body">
        <form>
          <!------------------------------------------------------------- Page 1 ------------------------------------------------------->
          <fieldset [style.display]="step === 1 ? 'block' : 'none'">
            <app-billing-account-selection
              (save)="setOrganizationResources($event); step = 2"
              (cancel)="backToBilling()"
            ></app-billing-account-selection>
          </fieldset>

          <!------------------------------------------------------------- Page 2 ------------------------------------------------------->
          <fieldset [style.display]="step === 2 ? 'block' : 'none'">
            <app-billing-plan-selection
              [organizationResources]="organizationResources!"
              [currentBillingPlan]="currentBillingPlan"
              [organization]="organization!"
              (save)="setBillingPlan($event); goToCustomerOrSubscribeForStarter()"
              (cancel)="setBillingPlan(undefined); step = 1"
            >
            </app-billing-plan-selection>
          </fieldset>
          <!------------------------------------------------------------- Page 3 ------------------------------------------------------->
          <fieldset [style.display]="step === 3 ? 'block' : 'none'">
            <app-billing-customer
              [settingStatus]="SettingStatus.REGISTRATION"
              [customer]="customer!"
              (customerChange)="setCustomer($event)"
              (onSave)="goToPayment()"
              (onCancel)="step = 2"
            >
            </app-billing-customer>
          </fieldset>
          <!------------------------------------------------------------- Page 4 ------------------------------------------------------->
          <fieldset [style.display]="step === 4 ? 'block' : 'none'">
            <app-spinner [display]="loading" [main]="true"></app-spinner>
            <app-billing-registration-payment
              [customer]="customer!"
              [billingPlan]="nextBillingPlan!"
              [locale]="locale!"
              [stripe]="stripeService"
              (cardElement)="setCardElement($event)"
            >
            </app-billing-registration-payment>
            <div class="payment-details__footer mt-3">
              <button
                type="button"
                class="btn btn-ghost-secondary mr-2"
                (click)="step = 3"
                [disabled]="paymentLocked()"
              >
                {{ t("common.previous") }}
              </button>
              <button
                type="button"
                class="btn btn-primary pull-right"
                (click)="payAndSubscribe()"
                [disabled]="paymentLocked()"
              >
                {{ t("billing-registration.proceed_to_payment") }}
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>
