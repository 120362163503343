import { AdStatsEx, Languages, OrderStats } from '../models';
import { Currency } from '../api-client';
import {
  ColDef,
  ColGroupDef,
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnRowGroupChangedEvent,
  ColumnState,
  FirstDataRenderedEvent,
  GetGroupRowAggParams,
  GetMainMenuItemsParams,
  GridApi,
  GridOptions,
  ICellRendererParams,
  IRowNode,
  MenuItemDef,
  ModelUpdatedEvent,
  SideBarDef,
} from '@ag-grid-community/core';
import { addAdStats } from '../utils';
import { AD_SALES, Metric, MetricCategory } from '../metrics';

export interface CellRendererParams {
  locale?: string;
  currency?: Currency;
}

export const PARENT_ASIN_COL_NAME = 'parent_asin_col';

function groupMetricsByCategory<T>(metrics: Metric<T>[]): Map<MetricCategory, Metric<T>[]> {
  const res = new Map<MetricCategory, Metric<T>[]>();
  for (const m of metrics) {
    if (!res.has(m.category)) res.set(m.category, [m]);
    else res.get(m.category)!.push(m);
  }
  // TODO: stop sorting here
  // for (const k of res.keys()) {
  //   res.get(k).sort((a, b) => (a.title > b.title ? 1 : a.title === b.title ? 0 : -1));
  // }
  return res;
}

export function getMetricsColDef<T, TData>(
  metrics: Metric<T>[],
  groupMetric = false,
  cellRenderer: any,
  cellRendererParams: CellRendererParams,
): ColGroupDef<T>[] {
  const columns: ColGroupDef[] = [];

  const byCategory = groupMetricsByCategory(metrics);

  for (const c of byCategory.keys()) {
    const group: ColGroupDef = {
      headerName: c,
      children: [],
    };
    for (const m of byCategory.get(c)!) {
      const child: ColDef = {
        headerName: m.title,
        headerTooltip: m.tooltip,
        colId: m.id,
        enableValue: true,
        enablePivot: true,
        type: 'numericColumn',
        filter: 'agNumberColumnFilter', // Authorize filter on values (greater, equal...)
        valueGetter: (params) => {
          // Here, we set the intrinsic value of the cell.
          // We can get non summable metrics computed by metric.value() method
          // In case of grouping, this is the same process, we just pass "node.aggData" instead of "data"
          const val = m.value(params.data ?? params.node?.aggData);
          return val === undefined || isNaN(val) || !isFinite(val) ? null : m.isPercent ? val * 100 : val;
        },
        // The rendering is done in MetricEvolution component
        // It will handle metric formatting depending on period comparison
        cellRenderer: cellRenderer,
        cellRendererParams: (params: ICellRendererParams) => ({
          metric: m,
          locale: cellRendererParams?.locale,
          currentData: params.data ?? params.node.aggData,
          previousData: undefined as any, // Should be defined in component
          displayValue: true,
          currency: cellRendererParams?.currency,
        }),
        // use for CSV export
        valueFormatter: (params) => {
          return m.valueForCsv(params.data ?? params.node?.aggData);
        },
      };
      group.children.push(child);
    }

    columns.push(group);
  }
  if (!groupMetric) return columns.flatMap((c) => c.children as unknown as ColGroupDef);
  return columns;
}

function GetAgGridLanguageInLocalStorage(): string {
  return localStorage.getItem('agGridLanguage') || 'en';
}

export function getBasicGridOptions(
  gridKey: string | GridKey,
  hasFooterRow?: boolean,
  forceAutofit = false,
  defaultSort = true,
  dataAggFunction: (a: any, b: any) => any = addAdStats,
  autoSizeColumns = true,
): GridOptions {
  return {
    animateRows: true,
    sortingOrder: ['desc', 'asc', null],
    context: {},
    rowDragManaged: true,
    suppressMoveWhenRowDragging: true,
    suppressColumnVirtualisation: true,
    suppressAggFuncInHeader: true,
    showOpenedGroup: true,
    enableRangeHandle: true,
    maintainColumnOrder: true,
    tooltipShowDelay: 100,
    autoSizePadding: 1,
    enableRangeSelection: true,
    localeText: Languages.find((l) => l.value === GetAgGridLanguageInLocalStorage())?.agGridLocale,
    sideBar: { toolPanels: ['columns', 'filters'] } as SideBarDef,
    rowClass: 'tw-h-full',
    getChartToolbarItems: () => ['chartDownload'],
    getGroupRowAgg: (params) => getGroupRowAgg(params),
    rowClassRules: {
      'grid-row-leaf': (params) => !params.node.group && params.node.level !== 0,
      'grid-row-footer': (params) => params.node.isRowPinned(),
    },
    onModelUpdated: (event: ModelUpdatedEvent) => {
      if (hasFooterRow) updateFooterRow(event.api, dataAggFunction);
    },
    onFirstDataRendered: (params: FirstDataRenderedEvent) => {
      if (!forceAutofit) params.api.showLoadingOverlay();

      // clear sort because it can be set form a previous table config
      if (!defaultSort) {
        params.api.applyColumnState({
          defaultState: { sort: null },
        });
      } // default sort desc Ad Sales column if exists
      else if (params.api.getColumn(AD_SALES.id)) {
        params.api.applyColumnState({
          state: [{ colId: AD_SALES.id, sort: 'desc' }],
          defaultState: { sort: null },
        });
      }

      if (forceAutofit) {
        params.api.sizeColumnsToFit();
        return;
      }
      if (!gridKey) return;

      if (autoSizeColumns) {
        params.api.autoSizeAllColumns();
      }
      restoreGridState(gridKey, params.api);

      params.api.hideOverlay();
    },
    onColumnMoved: (params) => saveGridState(gridKey, params),
    onColumnRowGroupChanged: (params) => saveGridState(gridKey, params),
    onColumnVisible: (params) => saveGridState(gridKey, params),
    onColumnPinned: (params) => saveGridState(gridKey, params),
    getMainMenuItems: (params) => getMainMenuItems(params, gridKey),
    onColumnResized: (params) => saveGridState(gridKey, params),
  };
}

export type GridKey = 'salesAdvertisingGrid2' | 'vendorInventoryGrid';

export function saveGridState(
  gridKey: string,
  params: ColumnMovedEvent | ColumnRowGroupChangedEvent | ColumnResizedEvent,
) {
  if (params.source === 'viewportSizeFeature') return;
  // Prevent useless local storage set if event is not finished
  if ('finished' in params && !params['finished']) return;
  const globalState = params.api.getColumnState();
  localStorage.setItem(gridKey + LS_GRID_SUFFIX, JSON.stringify(globalState));
}

export function restoreGridState(gridKey: string, colApi: GridApi) {
  try {
    const globalState: ColumnState[] = getGridConfig(gridKey);
    // Handle old config
    if (globalState) colApi.applyColumnState({ state: globalState, applyOrder: true });
  } catch (e) {
    localStorage.removeItem(gridKey + LS_GRID_SUFFIX);
  }
}

export function getMainMenuItems(params: GetMainMenuItemsParams, gridKey: string | null): (string | MenuItemDef)[] {
  const menu: (string | MenuItemDef)[] = ['pinSubMenu', 'separator', 'autoSizeThis', 'autoSizeAll'];

  menu.push({
    name: 'Autofit All Columns',
    action: () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    },
  });

  menu.push('separator');

  menu.push({
    name: 'Restore columns',
    action: () => {
      setTimeout(() => {
        localStorage.removeItem(gridKey + LS_GRID_SUFFIX);
        params.api.resetColumnState();
        params.api.autoSizeAllColumns();
      });
    },
  });

  menu.push({
    name: 'Reset filter for this column',
    action: () => {
      const colId = params.column.getId();
      const currentFilters = params.api.getFilterModel();
      // no filter for this column
      if (!currentFilters[colId]) return;

      currentFilters[colId] = null;
      params.api.setFilterModel(currentFilters);
    },
  });

  menu.push({
    name: 'Reset filters for all columns',
    action: () => {
      params.api.setFilterModel(null);
    },
  });
  return menu;
}

function getGridConfig(key: string) {
  return JSON.parse(localStorage.getItem(key + LS_GRID_SUFFIX)!);
} // Function given to ag grid to aggregate Adstats on grouping
// Pin footer row of total AdStats
export function updateFooterRow<T>(gridApi: GridApi<T>, dataAggFunction: (a: T, b: T) => T) {
  let totalRenderedData: T = {} as T;

  gridApi.forEachNodeAfterFilter((n: IRowNode) => {
    if (n.level == 0) {
      const usedData = n.group ? n.aggData : n.data;
      totalRenderedData = dataAggFunction(totalRenderedData, usedData);
    }
  });

  gridApi.refreshCells();
  gridApi.setGridOption('pinnedBottomRowData', [totalRenderedData]);
}

export function getGroupRowAgg(params: GetGroupRowAggParams) {
  const result: AdStatsEx = {};
  params.nodes.forEach((node: IRowNode) => {
    const childAgg = getFilteredLeafNodes(node)
      .map((node: IRowNode) => node.data)
      .reduce((prev, curr) => addAdStats(prev, curr), {});
    addAdStats(result, childAgg);
  });
  return result;
}

export const LS_GRID_SUFFIX = '_gridConfig';

export function getFilteredLeafNodes(node: IRowNode, api?: GridApi): IRowNode[] {
  if (node.isRowPinned() && api) {
    const res: IRowNode[] = [];
    api.forEachNodeAfterFilter((n) => {
      if (!n.isRowPinned() && !n.group) res.push(n);
    });
    return res;
  }

  if (!node.group) return [node];
  const results: IRowNode[] = [];

  for (const c of node.childrenAfterFilter || []) {
    if (c.group) results.push(...c.allLeafChildren);
    else results.push(c);
  }

  return results;
}

export const SIDE_BAR_NO_PIVOT: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressPivotMode: true,
        suppressValues: true,
      },
    },
    'filters',
  ],
};

export interface ProductGridData extends OrderStats {
  rowId?: string;
  asinLink?: string;
  brand?: string;
  parentAsin?: string;
  title?: string;
  customField1?: string;
  customField2?: string;
}
