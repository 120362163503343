import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { AccountSelectionService, AsinService, CatalogBrandService } from "@front/m19-services";
import { IButtonComponent, ISelectComponent, Option } from "@front/m19-ui";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";
import { combineLatest, switchMap } from "rxjs";
import { AccountMarketplace, MatchType, StrategyAsin, Targeting } from "@front/m19-api-client";
import { Catalog, CatalogBrandEx } from "@front/m19-models";

export interface SpGroupTargetings {
  keywords: Targeting[];
  products: StrategyAsin[];
}

@UntilDestroy()
@Component({
  selector: "app-from-brand-selection-modal",
  standalone: true,
  imports: [ISelectComponent, IButtonComponent],
  templateUrl: "./from-brand-selection-modal.component.html",
})
export class FromBrandSelectionModalComponent implements OnInit {
  private catalogService = inject(CatalogBrandService);
  private accountService = inject(AccountSelectionService);
  private asinService = inject(AsinService);
  private modalRef = inject(BsModalRef);

  @Input() matchType: MatchType = MatchType.phrase;
  @Output() emitTargetings = new EventEmitter<SpGroupTargetings>();

  keywordsByBrand: Map<string, Set<string>> = new Map();
  productsByBrand: Map<string, Set<string>> = new Map();
  brands: Option<CatalogBrandEx>[] = [];
  selectedBrand: Option<CatalogBrandEx> | undefined;

  selectedTargetings?: SpGroupTargetings;

  ngOnInit(): void {
    this.accountService.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am: AccountMarketplace) =>
          combineLatest([
            this.catalogService.getCatalogBrands(am.accountId, am.marketplace),
            this.asinService.getCatalog(am.accountId, am.marketplace),
          ]),
        ),
      )
      .subscribe(([brands, catalog]: [CatalogBrandEx[], Catalog]) => {
        this.keywordsByBrand = this.getKeywordsByBrand(brands);
        this.productsByBrand = this.getProductsByBrand(brands, catalog);
        this.brands = brands.map((brand: CatalogBrandEx) => ({
          label: brand.brandAlias ?? "",
          value: brand,
        }));
      });
  }

  private getKeywordsByBrand(brands: CatalogBrandEx[]): Map<string, Set<string>> {
    const keywords: Map<string, Set<string>> = new Map();
    for (const b of brands) {
      const existingKeywords = keywords.get(b.brandAlias!) || new Set<string>();
      b.extraSegments?.forEach((s) => {
        existingKeywords.add(s.keyword!);
      });
      existingKeywords.add(b.brandAlias!);
      existingKeywords.add(b.brandName!);
      keywords.set(b.brandAlias!, existingKeywords);

      if (b.parentBrandAlias !== b.brandAlias) {
        const existingKeywords = keywords.get(b.parentBrandAlias!) || new Set<string>();
        const brandAliasKw = keywords.get(b.brandAlias!);
        brandAliasKw?.forEach((kw) => existingKeywords.add(kw));
        keywords.set(b.parentBrandAlias!, existingKeywords);
      }
    }
    return keywords;
  }

  private getProductsByBrand(brands: CatalogBrandEx[], catalog: Catalog): Map<string, Set<string>> {
    const products: Map<string, Set<string>> = new Map();
    for (const b of brands) {
      const existingProducts = products.get(b.brandAlias!) || new Set<string>();
      const asins = catalog.products.filter((a) => a.brand == b.brandName).map((a) => a.asin!);
      asins.forEach((a) => existingProducts.add(a));
      products.set(b.brandAlias!, existingProducts);

      if (b.parentBrandAlias !== b.brandAlias) {
        const existingProducts = products.get(b.parentBrandAlias!) || new Set<string>();
        asins.forEach((a) => existingProducts.add(a));
        products.set(b.parentBrandAlias!, existingProducts);
      }
    }
    return products;
  }

  selectBrand(brand: Option<CatalogBrandEx>) {
    const keywords = this.keywordsByBrand.get(brand.value.brandAlias!);
    const products = this.productsByBrand.get(brand.value.brandAlias!);

    this.selectedTargetings = {
      keywords: Array.from(keywords ?? []).map((k) => ({
        matchType: this.matchType,
        targetingValue: k,
      })),
      products: Array.from(products ?? []).map((p) => ({
        asin: p,
      })),
    };
    this.selectedBrand = brand;
  }

  emit() {
    this.emitTargetings.emit(this.selectedTargetings);
    this.close();
  }

  close() {
    this.modalRef.hide();
  }
}
