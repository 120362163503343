import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { Router, RouterModule } from "@angular/router";
import { AuthService } from "@front/m19-services";
import { IBadgeComponent, IBadgeParams, IButtonComponent } from "@front/m19-ui";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ToastrService } from "ngx-toastr";
import { TranslocoRootModule } from "../../transloco-root.module";

type NavItem = {
  name: string;
  testid: string;
  url: string;
  icon: string;
  badge?: { variant: IBadgeParams["variant"]; text: string; color?: IBadgeParams["color"] };
  disabled?: boolean;
  tooltip: string;
  hidden?: boolean;
  beta?: boolean; // if true, the item is hidden for non-beta testers
};
type NavCategory = { category?: string; drawline: boolean };

@UntilDestroy()
@Component({
  selector: "app-v2-sidebar",
  templateUrl: "./v2-sidebar.component.html",
  standalone: true,
  imports: [CommonModule, IButtonComponent, MatTooltipModule, RouterModule, IBadgeComponent, TranslocoRootModule],
})
export class V2SidebarComponent implements OnInit {
  readonly navItems: (NavItem | NavCategory)[] = [
    {
      category: "v2-sidebar.dashboard",
      drawline: false, // do not draw line on top
    },

    {
      name: "v2-sidebar.home",
      testid: "dashboard-home",
      url: "/dashboard/home",
      icon: "icon-[material-symbols--home-outline-rounded]",
      tooltip: "v2-sidebar.home",
    },
    {
      name: "v2-sidebar.account_360",
      testid: "dashboard-account360",
      url: "/dashboard/account360",
      icon: "icon-[material-symbols--dashboard-outline-rounded]",
      tooltip: "v2-sidebar.account_360",
    },
    {
      name: "v2-sidebar.advertising",
      testid: "dashboard-advertising",
      url: "/dashboard/advertising",
      icon: "icon-[material-symbols--show-chart-rounded]",
      tooltip: "v2-sidebar.advertising",
    },
    {
      name: "v2-sidebar.profit_and_loss",
      testid: "dashboard-profit-and-loss",
      url: "/dashboard/profit-and-loss",
      icon: "icon-[material-symbols--attach-money-rounded]",
      tooltip: "v2-sidebar.profit_and_loss",
    },
    {
      category: "v2-sidebar.advertising",
      drawline: true,
    },
    {
      name: "v2-sidebar.sp",
      testid: "sponsored-product",
      url: "/advertising/sponsored-product",
      icon: "icon-[mdi--target-arrow]",
      tooltip: "v2-sidebar.sponsored_products",
    },
    {
      name: "v2-sidebar.sb",
      testid: "sponsored-brands",
      url: "/advertising/sponsored-brands",
      icon: "icon-[material-symbols--media-link-outline-sharp]",
      tooltip: "v2-sidebar.sponsored_brand",
    },
    {
      name: "v2-sidebar.sd",
      testid: "sponsored-display",
      url: "/advertising/sponsored-display",
      icon: "icon-[mdi--leads-outline]",
      tooltip: "v2-sidebar.sponsored_display",
    },
    {
      name: "metrics.TACOS_title",
      testid: "tacos",
      url: "/advertising/tacos-strategies",
      icon: "icon-[mdi--target-arrow]",
      tooltip: "metrics.TACOS_tooltip",
      beta: true,
      badge: { variant: "subtle", color: "gray", text: "v2-sidebar.beta" },
    },
    {
      name: "v2-sidebar.hourly_stats",
      testid: "hourly-stats",
      url: "/advertising/hourly-stats",
      icon: "icon-[mdi--clock-outline]",
      tooltip: "v2-sidebar.hourly_stats",
    },
    {
      name: "v2-sidebar.promo_day",
      testid: "promo-days-boost",
      url: "/advertising/promo-days-boost",
      icon: "icon-[material-symbols--speed-outline]",
      tooltip: "v2-sidebar.promo_day",
    },
    {
      name: "v2-sidebar.dsp",
      testid: "advertising-dsp",
      url: "/advertising/dsp",
      icon: "icon-[mdi--internet]",
      tooltip: "v2-sidebar.dsp",
    },
    {
      name: "v2-sidebar.amc",
      testid: "advertising-amc",
      url: "/advertising/amc",
      icon: "icon-[material-symbols--lightbulb-outline]",
      badge: { variant: "subtle", color: "gray", text: "common.soon" },
      disabled: true,
      tooltip: "v2-sidebar.amazon_marketing_cloud",
    },
    { category: "v2-sidebar.product_center", drawline: true },
    {
      name: "v2-sidebar.product_360",
      testid: "product360",
      url: "/product-center/product360",
      icon: "icon-[mdi--binoculars]",
      tooltip: "v2-sidebar.product_360",
    },
    {
      name: "v2-sidebar.product_analysis",
      testid: "product-analysis",
      url: "/product-center/product-analysis",
      icon: "icon-[material-symbols--search-insights]",
      tooltip: "v2-sidebar.product_analysis",
    },
    {
      name: "v2-sidebar.product_tracker",
      testid: "product-tracker",
      url: "/product-center/product-tracker",
      icon: "icon-[mdi--spy]",
      tooltip: "v2-sidebar.product_tracker",
    },
    {
      name: "v2-sidebar.inventory",
      testid: "inventory",
      url: "/product-center/inventory",
      icon: "icon-[material-symbols--package-2-outline]",
      tooltip: "v2-sidebar.inventory",
    },
    {
      name: "v2-sidebar.catalog",
      testid: "catalog",
      url: "/product-center/catalog",
      icon: "icon-[mdi--book-open-blank-variant-outline]",
      tooltip: "v2-sidebar.catalog",
    },
    {
      name: "v2-sidebar.product_groups",
      testid: "product-group",
      url: "/product-center/product-group",
      icon: "icon-[mdi--group]",
      tooltip: "v2-sidebar.product_groups",
    },
    {
      name: "v2-sidebar.brand_alias",
      testid: "brand-alias",
      url: "/product-center/brand-alias",
      icon: "icon-[material-symbols--brand-family]",
      tooltip: "v2-sidebar.brand_alias",
    },
    {
      category: "v2-sidebar.keyword_center",
      drawline: true,
    },
    {
      name: "v2-sidebar.traffic_analysis",
      testid: "traffic-analysis",
      url: "/keyword-center/traffic-analysis",
      icon: "icon-[material-symbols--web-traffic]",
      tooltip: "v2-sidebar.traffic_analysis",
    },
    {
      name: "v2-sidebar.segments",
      testid: "segments",
      url: "/keyword-center/segments",
      icon: "icon-[mdi--marketplace-outline]",
      tooltip: "v2-sidebar.segments",
    },
    {
      name: "v2-sidebar.share_of_voice",
      testid: "share-of-voice",
      url: "/keyword-center/share-of-voice",
      icon: "icon-[mdi--chart-arc]",
      tooltip: "v2-sidebar.share_of_voice",
    },
    {
      name: "v2-sidebar.keyword_tracker",
      testid: "keyword-tracker",
      url: "/keyword-center/keyword-tracker",
      icon: "icon-[mdi--podium-gold]",
      tooltip: "v2-sidebar.keyword_tracker",
    },
    {
      name: "v2-sidebar.search_trends",
      testid: "search-trends",
      url: "/keyword-center/search-trends",
      icon: "icon-[material-symbols--screen-search-desktop-outline-sharp]",
      tooltip: "v2-sidebar.search_trends",
    },
    {
      // no category
      drawline: true,
    },
    {
      name: "v2-sidebar.activities",
      testid: "activities",
      url: "/activities",
      icon: "icon-[mdi--clipboard-text-history-outline]",
      tooltip: "v2-sidebar.activities",
    },
  ];

  expanded = true;

  readonly sidebarLocalstorageKey = "sidebar-expanded";

  constructor(
    protected router: Router,
    private authService: AuthService,
    private toasterService: ToastrService,
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem(this.sidebarLocalstorageKey) === "false") {
      this.expanded = false;
    }

    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.navItems.forEach((navItem) => {
        if (this.isNavItem(navItem) && navItem.beta && !user.betaTester) {
          navItem.hidden = true;
        }
      });
    });
  }

  isNavItem(t: NavItem | NavCategory): t is NavItem {
    return (t as NavItem).url !== undefined;
  }

  toggleSidebar() {
    this.expanded = !this.expanded;
    localStorage.setItem(this.sidebarLocalstorageKey, this.expanded.toString());
  }

  useOldUi() {
    // 0 = old version
    this.authService.setUiVersionPreference(0).subscribe({
      next: () => {
        // reload to home page
        window.location.replace("/home");
      },
      error: (err: string) => {
        this.toasterService.error(err);
      },
    });
  }
}
