<ng-container *transloco="let t">
  <app-strategy-overlay>
    @if (strategy) {
      <app-strategy-page [campaignType]="CampaignType.SB" [strategy$]="strategy$">
        <span withSbCreatives>
          @if (strategy) {
            <app-low-inventory-warning
              [accountId]="strategy.accountId"
              [marketplace]="strategy.marketplace"
              [asins]="strategy.asins | map: getAsins"
            ></app-low-inventory-warning>
          }

          <!-- Store Spotlight -->
          @if (strategy) {
            @if (creativesWithoutCustomImage > 0) {
              <div class="alert alert-danger">
                <b>{{ t("sb-page.since_may_31_2024") }}</b
                >, {{ t("sb-page.sponsored_brands_product_no_longer_shown") }}
                <br />
                {{ t("sb-page.please_ensure_that_your_ad_lines_contain_a_custom_image") }}
                <b>{{ t("sb-page.impacted_ad_line", { count: creativesWithoutCustomImage }) }} </b>
              </div>
            }

            <div class="card-perso collapse-card left-child right-child card mb-0">
              <div class="card-perso-header collapse-card-header card-header flex items-center justify-between">
                <div class="flex flex-1 items-center" (click)="adLinesVisible = !adLinesVisible">
                  <span class="icon-prepend float-left">
                    <app-dropdown-toggle-icon [expand]="adLinesVisible"></app-dropdown-toggle-icon>
                  </span>
                  <h5 class="mb-0 w-auto">{{ t("sb-page.ad_lines_all") }}</h5>
                </div>
                <div class="flex items-center justify-end gap-2">
                  @if (creativesLimitSoonReached()) {
                    <div class="alert alert-warning mb-0">
                      {{ t("sb-page.maximum_almost_reached") }}
                    </div>
                  } @else if (creativesLimitReached()) {
                    <div class="alert alert-warning mb-0">{{ t("sb-page.max_reached") }}</div>
                  }
                  <IButton
                    label="{{ t('sb-page.new_ad_line') }}"
                    [tooltipValue]="creativesLimitReached() ? t('sb-page.no_more_than_5_creatives') : ''"
                    [icon]="ICON_ADD"
                    [disabled]="creativesLimitReached()"
                    (click)="newCreative()"
                  />
                </div>
              </div>
              <IAccordion [isOpen]="!!adLinesVisible" [animated]="false">
                <div class="card-perso-body card-body pt-2">
                  <IMultiSelect
                    class="mb-3 inline-block"
                    popperWidth="250px"
                    [options]="creativeTypeFilterOptions"
                    [selected]="selectedCreativeTypes()"
                    placeholder="{{ t('sb-page.creative_type') }}"
                    (selectedChange)="applyCreativeTypeFilter($event)"
                    rounded
                  >
                    <ng-template #leadingSlot>
                      <span
                        class="icon-[mdi--filter-variant] size-5 flex-shrink-0 text-gray-500"
                        [class.text-main-500]="selectedCreativeTypes().length"
                      ></span>
                    </ng-template>
                    <ng-template #labelSlot>
                      <span class="font-medium text-gray-500">{{ t("sb-page.creative_type") }}</span>
                      @for (selected of selectedCreativeTypes(); track selected.value) {
                        <span class="text-sm">
                          {{ selected.label + ($last ? "" : ", ") }}
                        </span>
                      }
                    </ng-template>
                  </IMultiSelect>
                  @for (creative of allCreativesFiltered; track creative) {
                    <app-creative-display
                      [strategy]="strategy"
                      [creative]="creative"
                      [brandAssets]="creativeBrandAssets.get(creative.creativeId)"
                      [disabled]="isReadOnly"
                      [disableDeletion]="creativeCount() <= 1"
                      [customImages]="(customImages$ | async) ?? []"
                    >
                    </app-creative-display>
                  }
                  @if (!allCreativesFiltered.length) {
                    <p>{{ t("sb-page.no_creative_for_the_selected_filter") }}</p>
                  }
                </div>
              </IAccordion>
            </div>
          }
        </span>
        <div withTargetingStats class="card-perso collapse-card left-child right-child card mb-0">
          <div
            class="card-perso-header collapse-card-header top-child card-header"
            (click)="toggleTargetingStatsVisibility()"
          >
            <span class="icon-prepend float-left">
              <app-dropdown-toggle-icon [expand]="targetingStatsVisible"></app-dropdown-toggle-icon>
            </span>
            <h5 class="card-name mb-0">{{ t("sb-page.targeting_stats") }}</h5>
          </div>
          @if (targetingStatsVisible) {
            <div class="card-perso-body bottom-child card-body">
              <div class="card-body left-child right-child">
                <app-targeting-asin-stats [isReadOnly]="isReadOnly" [strategy]="strategy" [withTOSROSwitch]="false" />
              </div>
            </div>
          }
        </div>
      </app-strategy-page>
    }
  </app-strategy-overlay>
</ng-container>
