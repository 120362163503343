import { formatCurrency, formatNumber, formatPercent, getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from '../api-client';
import { UtilsFractile } from '../utils';
import { CurrencyStat } from '@front/m19-models/CurrencyStat';

export enum MetricType {
  SUMMABLE,
  RATIO,
}

export enum SupportedAccountType {
  VENDOR = 'VENDOR',
  SELLER = 'SELLER',
  VENDOR_AND_SELLER = 'VENDOR_AND_SELLER',
}

export enum MetricCategory {
  AD_STATS = 'v2-sidebar.advertising',
  INVENTORY = 'metrics-category.stock',
  SALES_STATS = 'metrics-category.sales_and_traffic',
  PROFIT_STATS = 'metrics-category.profit_analytics',
  GLOBAL_PROFIT_STATS = 'metrics-category.profit_and_loss',
  PRODUCT = 'common.product',
  OTHER = 'metrics.OTHER_FEE_title',
  HIDDEN = 'metrics-category.hidden',
  STRATEGY_CONFIG = 'metrics-category.strategy_configuration',
  DSP = 'v2-sidebar.dsp',
}

export function printCurrency(
  data: number,
  locale: string | undefined,
  currency: string | undefined,
  precision = '1.0-0',
): string {
  return formatCurrency(
    data,
    locale || 'en',
    getCurrencySymbol(currency || Currency.USD.toString(), 'narrow', locale),
    currency,
    precision,
  );
}

export interface Metric<T> {
  id: string;
  title: string;
  category: MetricCategory;
  type: MetricType;
  value: (d: T) => number | undefined;
  valueForCsv: (d: T) => string;
  isPercent: boolean;
  coeff: number;
  supportedAccountType: SupportedAccountType;
  color: string;
  tooltip?: string;
  titleSmall: string;
  inverseColors?: boolean;
  graphTension: number;
  graphBorderDash?: Array<number>;
  stepped: boolean;
  reverseAxis: boolean;
  minAtZero: boolean;
  maxAt?: number;
  requireSellingPartnerAccess: boolean;
  spanGaps: boolean;
  keepLastValue: boolean;
  higherIsBetter: boolean;
  mustApplyEvolutionStyle: boolean;
  tickDisplay: boolean;
  isManagement: boolean;

  getEvolution: (d1: T, d2: T) => number | undefined;
  formatMetricEvolution: (evolution: number, locale: string) => string;

  format: (d: T | number, locale: string, currency: string, precision?: string) => string;
  formatSmall: (d: T | number, locale: string, currency: string) => string;
  compare: (a: T, b: T) => number;
  getGradientBucket: (d: Map<string, T>, hour: string) => number | undefined;
}

function kFormatter(num: number): [number, boolean] {
  return Math.abs(num) > 999 ? [(Math.sign(num) * Math.abs(num)) / 1000, true] : [num, false];
}

export function toFixedIfNecessary(value: string, dp: number) {
  return +parseFloat(value).toFixed(dp);
}

export const MetricRegistry: Map<string, Metric<any>> = new Map();

export abstract class RegisteredMetric<T> implements Metric<T> {
  public readonly id: string;
  public title: string;
  public titleSmall: string;
  public readonly category: MetricCategory;
  public readonly type: MetricType;
  public readonly coeff: number = 1;
  public readonly supportedAccountType: SupportedAccountType = SupportedAccountType.VENDOR_AND_SELLER;
  public readonly color: string;
  public readonly tooltip: string;
  public readonly inverseColors?: boolean;
  public readonly graphTension: number;
  public readonly graphBorderDash?: Array<number>;
  public readonly stepped: boolean;
  public readonly reverseAxis: boolean;
  public readonly minAtZero: boolean;
  public readonly requireSellingPartnerAccess: boolean;
  public readonly spanGaps: boolean;
  public readonly keepLastValue: boolean;
  public readonly higherIsBetter: boolean;
  public readonly mustApplyEvolutionStyle: boolean;
  public readonly isPercent: boolean;
  public readonly tickDisplay: boolean;
  public readonly isManagement: boolean;

  constructor(params: {
    id: string;
    title: string;
    titleSmall?: string;
    type: MetricType;
    category?: MetricCategory;
    color: string;
    coeff?: number;
    supportedAccountType?: SupportedAccountType;
    tooltip?: string;
    inverseColors?: boolean;
    graphTension?: number;
    graphBorderDash?: Array<number>;
    stepped?: boolean;
    reverseAxis?: boolean;
    minAtZero?: boolean;
    requireSellingPartnerAccess?: boolean;
    spanGaps?: boolean;
    keepLastValue?: boolean;
    higherIsBetter?: boolean;
    mustApplyEvolutionStyle?: boolean;
    isPercent?: boolean;
    tickDisplay?: boolean;
    isManagement?: boolean;
  }) {
    this.id = params.id;
    this.title = params.title;
    this.titleSmall = params.titleSmall ?? params.title;
    this.category = params.category ?? MetricCategory.OTHER;
    this.type = params.type;
    this.color = params.color;
    this.coeff = params.coeff ?? 1;
    this.supportedAccountType = params.supportedAccountType ?? SupportedAccountType.VENDOR_AND_SELLER;
    this.tooltip = params.tooltip ?? params.title;
    this.inverseColors = params.inverseColors ? params.inverseColors : false;
    this.graphTension = params.graphTension ?? 0.3;
    this.graphBorderDash = params.graphBorderDash;
    this.stepped = params.stepped ?? false;
    this.reverseAxis = params.reverseAxis ?? false;
    this.minAtZero = params.minAtZero ?? true;
    this.requireSellingPartnerAccess = params.requireSellingPartnerAccess ?? false;
    this.spanGaps = params.spanGaps ?? false;
    this.keepLastValue = params.keepLastValue ?? false;
    this.higherIsBetter = params.higherIsBetter ?? true;
    this.mustApplyEvolutionStyle = params.mustApplyEvolutionStyle ?? true;
    this.isPercent = params.isPercent ?? false;
    this.tickDisplay = params.tickDisplay ?? true;
    this.isManagement = params.isManagement ?? false;

    if (MetricRegistry.has(this.id)) {
      // eslint-disable-next-line no-console
      console.warn(`Metric collision for metric id ${this.id}`);
    }
    MetricRegistry.set(this.id, this);
  }

  public abstract value(d: T): number | undefined;

  public abstract valueForCsv(d: T): string;

  public abstract format(d: T | number, locale: string, currency: string): string;

  public abstract formatSmall(d: T | number, locale: string, currency: string): string;

  public abstract compare(a: T, b: T): number;

  public setTitle(title: string) {
    this.title = title;
    this.titleSmall = title;
  }

  public getEvolution(a: T, b: T): number | undefined {
    if (a == undefined || b == undefined) return undefined;
    const v = this.value(a);
    const w = this.value(b);
    if (v == undefined || w == undefined) return undefined;
    if (w === 0) return undefined;
    return (v - w) / (w > 0 ? w : -w);
  }

  public formatMetricEvolution(evolution: number, locale: string): string {
    if (evolution == 0) return '-';
    return formatPercent(evolution, locale, '1.1');
  }

  public getGradientBucket(d: Map<string, T>, hour: string): number | undefined {
    const quartiles = UtilsFractile.getFractileArray(
      Array.from(d.values()).map((x) => this.value(x)!),
      10,
    );
    const level = UtilsFractile.getFractileBucket(quartiles, this.value(d.get(hour)!)!);
    return this.inverseColors ? 9 - level! : level;
  }
}

export class SummableMetric<T extends CurrencyStat> extends RegisteredMetric<T> {
  public readonly field: keyof T;
  private readonly currency: boolean;
  private readonly childField?: keyof T[keyof T];

  constructor(params: {
    id: string;
    field: keyof T;
    childField?: string;
    title: string;
    category: MetricCategory;
    color: string;
    currency?: boolean;
    coeff?: number;
    supportedAccountType?: SupportedAccountType;
    titleSmall?: string;
    tooltip?: string;
    inverseColors?: boolean;
    graphTension?: number;
    graphBorderDash?: Array<number>;
    requireSellingPartnerAccess?: boolean;
    stepped?: boolean;
    spanGaps?: boolean;
    higherIsBetter?: boolean;
    mustApplyEvolutionStyle?: boolean;
    tickDisplay?: boolean;
    isManagement?: boolean;
  }) {
    super({
      ...params,
      type: MetricType.SUMMABLE,
    });
    this.field = params.field;
    this.childField = params.childField as keyof T[keyof T];
    this.currency = params.currency ?? false;
  }

  public value(d: T): number {
    let t: T | keyof T = d;
    let field: keyof T | keyof T[keyof T] = this.field;
    if (this.childField) {
      t = (d && d[this.field] ? d[this.field] : undefined) as keyof T;
      field = this.childField;
    }

    return t && (t as any)[field] ? (t as any)[field] : 0;
  }

  public valueForCsv(d: T): string {
    const value = this.value(d);
    if (isFinite(value)) {
      return toFixedIfNecessary(value.toString(), 2).toString();
    }
    return '-';
  }

  public compare(d1: T, d2: T) {
    return this.value(d2) - this.value(d1);
  }

  public format(d: number | T, locale: string, currency: string, precision?: string): string {
    const value = d === undefined ? 0 : typeof d === 'number' ? d : this.value(d);
    if (value === undefined) return '';
    return this.currency
      ? printCurrency(value, locale, currency !== undefined ? currency : (d as T).currency!, precision)
      : formatNumber(value, locale, precision ? precision : '1.0-0');
  }

  public formatSmall(d: number | T, locale: string, currency: string): string {
    const [value, isK] = kFormatter(d === undefined ? 0 : typeof d === 'number' ? d : this.value(d));
    if (value === undefined) return '';
    // append a K if the value is > 1000
    if (isK) {
      if (this.currency) {
        const symbol = getCurrencySymbol(currency !== undefined ? currency : (d as T).currency!, 'narrow');
        if (
          printCurrency(value, locale, currency !== undefined ? currency : (d as T).currency!, '1.0-1').startsWith(
            symbol,
          )
        )
          return symbol + formatNumber(value, locale, '1.0-1') + 'k';
        return formatNumber(value, locale, '1.0-1') + 'k' + symbol;
      }
      return formatNumber(value, locale, '1.0-1') + 'k';
    }
    return (
      (this.currency
        ? printCurrency(value, locale, currency !== undefined ? currency : (d as T).currency!, isK ? '1.0-1' : '1.0-0')
        : formatNumber(value, locale, isK ? '1.0-1' : '1.0-0')) + (isK ? 'k' : '')
    );
  }
}

export class RatioMetric<T extends CurrencyStat> extends RegisteredMetric<T> {
  public readonly numerator: Metric<T>;
  public readonly denominator: Metric<T> | undefined;
  private readonly precision: string;
  private readonly currency: boolean;

  constructor(params: {
    id: string;
    numerator: Metric<T>;
    denominator: Metric<T> | undefined;
    title: string;
    titleSmall?: string;
    category: MetricCategory;
    color: string;
    precision?: string;
    coeff?: number;
    currency?: boolean;
    supportedAccountType?: SupportedAccountType;
    tooltip: string;
    inverseColors?: boolean;
    graphTension?: number;
    graphBorderDash?: Array<number>;
    stepped?: boolean;
    requireSellingPartnerAccess?: boolean;
    higherIsBetter?: boolean;
    mustApplyEvolutionStyle?: boolean;
    isPercent?: boolean;
    keepLastValue?: boolean;
    tickDisplay?: boolean;
    isManagement?: boolean;
  }) {
    super({
      ...params,
      type: MetricType.RATIO,
    });
    this.numerator = params.numerator;
    this.denominator = params.denominator;
    this.precision = params.precision ?? '1.1-1';
    this.currency = params.currency ?? false;
  }

  public value(d: T): number | undefined {
    if (!this.denominator) {
      if (this.numerator.value(d)) {
        return this.coeff * this.numerator.value(d)!;
      }
      return undefined;
    }
    return this.denominator.value(d)
      ? this.coeff * (this.numerator.value(d)! / this.denominator.value(d)!)
      : Number.NEGATIVE_INFINITY;
  }

  public valueForCsv(d: T): string {
    const value = this.value(d)!;
    if (isFinite(value)) {
      return this.precision == '1.0-0'
        ? toFixedIfNecessary(value.toString(), this.isPercent ? 2 : 0).toString()
        : toFixedIfNecessary(value.toString(), this.isPercent ? 4 : 2).toString();
    }
    return '-';
  }

  public format(d: number | T, locale: string, currency: string, precision?: string): string {
    return this.formatWithPrecision(d, locale, currency, precision ? precision : this.precision);
  }

  public formatSmall(d: number | T, locale: string, currency: string): string {
    return this.formatWithPrecision(d, locale, currency, '1.0-2');
  }

  private formatWithPrecision(d: number | T, locale: string, currency: string, precision?: string): string {
    if (this.isPercent) {
      return percent(typeof d === 'number' ? d : this.value(d)!, locale, precision);
    }

    const value = d === undefined ? 0 : typeof d === 'number' ? d : this.value(d);
    if (value !== undefined && isFinite(value))
      return this.currency
        ? printCurrency(value, locale, currency !== undefined ? currency : (d as T).currency!, precision)
        : formatNumber(value, locale, precision);
    else return 'N/A';
  }

  public compare(d1: T, d2: T): number {
    if (!this.denominator) {
      return this.numerator.value(d2)! - this.numerator.value(d1)!;
    }

    const den1 = this.denominator.value(d1);
    if (!den1) return 1;
    const den2 = this.denominator.value(d2);
    if (!den2) return -1;
    return this.numerator.value(d2)! * den1 - this.numerator.value(d1)! * den2;
  }

  public override getEvolution(a: T, b: T): number | undefined {
    if (a == undefined || b == undefined) return undefined;
    const v = this.value(a);
    const w = this.value(b);
    if (v == undefined || w == undefined) return undefined;
    if (this.currency) {
      if (w === 0) return undefined;
      return (v - w) / (w > 0 ? w : -w);
    }
    return v - w;
  }

  public override formatMetricEvolution(evolution: number, locale: string): string {
    if (evolution == 0) return '-';
    if (this.currency) {
      return formatPercent(evolution, locale, '1.1');
    }
    if (this.isPercent) {
      return formatNumber(100 * evolution, locale, '1.1-2');
    }
    return formatNumber(evolution, locale, '1.1-2');
  }
}

export function percent(data: number | undefined, locale: string | undefined, precision: string | undefined): string {
  return data !== undefined && isFinite(data) ? formatPercent(data, locale || 'en', precision) : 'N/A';
}

@Pipe({ name: 'metricFormatPipe', standalone: true })
export class MetricFormatPipe<T> implements PipeTransform {
  transform(
    metric: Metric<T>,
    d: T | number,
    locale: string,
    currency: string,
    precision?: string,
    smallMode?: boolean,
    dontDisplayNA?: boolean,
  ): string {
    const res = smallMode ? metric.formatSmall(d, locale, currency) : metric.format(d, locale, currency, precision);
    return dontDisplayNA && res === 'N/A' ? '' : res;
  }
}
