<ng-container *transloco="let t">
  <div class="modal-header flex items-center justify-between">
    <h4 class="modal-title pull-left">{{ t("sd-strategy-creation.targetings") }}</h4>
    <span (click)="modalRef.hide()" [class]="[ICON_CLOSE, 'text-white', 'text-lg', 'cursor-pointer']"></span>
  </div>

  <div class="modal-body flex h-[70vh] flex-col gap-3 p-3">
    <div class="flex items-center rounded-md bg-gray-100 p-3">
      <p class="mb-0 mr-3 text-gray-700">
        <b>{{ t("targetings-modal.asin_level_isolation") }}</b
        >: {{ t("targetings-modal.isolation_definition") }}
      </p>
      <mat-slide-toggle
        color="primary"
        [checked]="strategy?.asinIsolation"
        [disabled]="isReadOnly"
        (change)="updateIsolation($event.checked)"
      />
    </div>
    <div class="flex flex-1 flex-col divide-y rounded-md border pt-3">
      @if (targetingType === StrategyTargetingType.KEYWORDS || strategyType === StrategyType.BRAND) {
        <div class="flex flex-1 flex-col">
          <div class="flex items-center justify-between gap-x-3 p-3 pt-0">
            <div class="flex items-center">
              <span class="text-gray-8000 font-medium">{{ t("targetings-modal.keyword_targetings") }}</span>
              <IBadge [label]="shownKeywords().length.toString()" color="gray" class="ml-2 select-none" />
              @if (strategyType !== StrategyType.BRAND) {
                <IButton
                  label="{{ t('targetings-modal.change_to_product') }}"
                  tooltipValue="{{ t('targetings-modal.update_this_strategy_to_product_targeting') }}"
                  color="white"
                  class="ml-2"
                  size="xs"
                  (onClick)="toggleTargetingType()"
                />
              }
            </div>
            <IButton
              label="{{ t('common.edit') }}"
              color="gray"
              variant="ghost"
              size="xs"
              [icon]="ICON_EDIT"
              (onClick)="editKeywordTargetings()"
            />
          </div>
          <div class="flex w-full items-center border-b px-3 pb-3">
            <IInput
              [icon]="ICON_SEARCH"
              (valueChange)="searchKeywordQuery.set($event)"
              placeholder="{{ t('targetings-modal.search_keywords') }}"
              autocomplete="off"
              autoFocus
              class="flex-1"
            />
          </div>

          <div class="flex flex-auto basis-0 flex-col divide-y divide-gray-100 overflow-auto">
            @for (targeting of shownKeywords(); track targeting.targetingValue) {
              <div class="flex w-full items-center justify-between px-3 py-2">
                <span class="text-sm text-gray-800">{{ targeting.targetingValue }}</span>
                <IBadge
                  [label]="targeting.matchType"
                  [color]="targeting.matchType === MatchType.exact ? 'green' : 'black'"
                  variant="soft"
                  class="ml-2"
                />
              </div>
            } @empty {
              <p class="pt-4 text-center text-sm text-gray-500">
                @if (searchKeywordQuery()) {
                  {{ t("targetings-modal.no_result", [searchKeywordQuery()]) }}
                } @else {
                  {{ t("targetings-modal.no_keywords") }}
                }
              </p>
            }
          </div>
        </div>
      }
      @if (targetingType === StrategyTargetingType.PRODUCTS || strategyType === StrategyType.BRAND) {
        <div class="flex flex-1 flex-col">
          <div
            class="flex items-center justify-between gap-x-3 p-3"
            [ngClass]="{ 'pt-0': strategyType !== StrategyType.BRAND }"
          >
            <div class="flex items-center">
              <span class="text-gray-8000 font-medium">{{ t("targetings-modal.product_targetings") }}</span>
              <IBadge [label]="shownProducts().length.toString()" color="gray" class="ml-2 select-none" />
              @if (strategyType !== StrategyType.BRAND) {
                <IButton
                  label="{{ t('targetings-modal.change_to_keyword') }}"
                  tooltipValue="{{ t('targetings-modal.update_this_strategy_to_keyword_targeting') }}"
                  color="white"
                  class="ml-2"
                  size="xs"
                  (onClick)="toggleTargetingType()"
                />
              }
            </div>
            <IButton
              label="{{ t('common.edit') }}"
              color="gray"
              variant="ghost"
              size="xs"
              [icon]="ICON_EDIT"
              (onClick)="editProductTargetings()"
            />
          </div>
          <div class="flex w-full items-center border-b px-3 pb-3">
            <IInput
              [icon]="ICON_SEARCH"
              (valueChange)="searchProductQuery.set($event)"
              placeholder="{{ t('targetings-modal.search_products') }}"
              autocomplete="off"
              autoFocus
              class="flex-1"
            />
          </div>

          <div class="flex flex-auto basis-0 flex-col divide-y divide-gray-100 overflow-auto">
            @for (targeting of shownProducts(); track targeting.targetingValue) {
              <app-asin-item
                [asin]="targeting.targetingValue"
                [isReadOnly]="true"
                [marketplace]="this.accountMarketplace!.marketplace"
                [actionsButtons]="true"
              />
            } @empty {
              <p class="pt-4 text-center text-sm text-gray-500">
                @if (searchProductQuery()) {
                  {{ t("targetings-modal.no_result", [searchProductQuery()]) }}
                } @else {
                  {{ t("targetings-modal.no_products") }}
                }
              </p>
            }
          </div>
        </div>
      }
    </div>
  </div>
  <div class="modal-footer">
    <IButton label="{{ t('common.close') }}" color="gray" variant="soft" (onClick)="modalRef.hide()" />
  </div>
</ng-container>
