import { formatDate } from "@angular/common";
import { Component, OnInit, Pipe, PipeTransform } from "@angular/core";
import { AccountSelectionService, KeywordTrackingService, UserSelectionService } from "@front/m19-services";
import { Option } from "@front/m19-ui";
import { KeywordTrackerService } from "@m19-board/services/keyword-tracker.service";
import { ICON_ADD } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { map, switchMap, tap } from "rxjs";
import { CsvExportService } from "../services/csv-export.service";
import {
  AccountMarketplace,
  BrandScore,
  BrandTimeline,
  KeywordTrackerConfig,
  Marketplace,
  ShareOfVoiceTimeline,
} from "@front/m19-api-client";
import { Utils } from "@front/m19-utils";

// "All other brands" color for charts
export const otherBrandColor = "#cccccc";
export type SovBrandDateType = Map<string, Map<string, number>>;

@Pipe({
  name: "filterStringListPipe",
})
export class FilterStringListPipe implements PipeTransform {
  transform(items: string[], query: string): string[] {
    if (!items) {
      return [];
    }

    if (!query) {
      return items;
    }

    // convert the searchText to lower case
    query = query.toLowerCase();

    // retrun the filtered array
    return items.filter((item) => {
      if (item) {
        return item.toLowerCase().includes(query);
      }
      return false;
    });
  }
}

@UntilDestroy()
@Component({
  selector: "app-share-of-voice",
  templateUrl: "./share-of-voice.component.html",
  styleUrls: ["./share-of-voice.component.scss"],
})
export class ShareOfVoiceComponent implements OnInit {
  readonly ICON_ADD = ICON_ADD;
  kwDropdownOpen = false;

  allSearchTerms: string[] = [];
  focusedSearchTerm?: string;
  focusedSearchTermOption: Option<string> | undefined;
  sovByBrandDate?: SovBrandDateType;
  brandColors: Map<string, string> = new Map();
  hoveredBrands?: string;
  brandSelection?: string[];
  snapshotDate?: Date;
  loading?: boolean;
  noData = false;
  isReadonly?: boolean;

  accountInfo?: { accountId: string; marketplace: Marketplace; orgId: number };

  filteredTermsOptions: Option<string>[] = [];

  constructor(
    private accountSelectionService: AccountSelectionService,
    private kwTrackerService: KeywordTrackerService,
    private keywordTrackingService: KeywordTrackingService,
    private userSelectionService: UserSelectionService,
    private csvService: CsvExportService,
  ) {}

  ngOnInit(): void {
    this.accountSelectionService.singleAccountMarketplaceSelection$
      .pipe(
        tap((_) => (this.loading = true)),
        switchMap((am: AccountMarketplace) => {
          this.accountInfo = {
            accountId: am.accountId,
            marketplace: am.marketplace,
            orgId: am.resourceOrganizationId!,
          };
          return this.keywordTrackingService.getKeywordTrackerConfig(
            am.accountId,
            am.marketplace,
            am.resourceOrganizationId!,
          );
        }),
        map((config: KeywordTrackerConfig[]) => config.map((c) => c.searchTerm!)),
        switchMap((searchTerms: string[]) => {
          this.allSearchTerms = searchTerms;
          this.filteredTermsOptions = this.allSearchTerms.map((t) => ({ label: t, value: t }));
          this.focusSearchTerm(this.filteredTermsOptions[0]);

          return this.keywordTrackingService.getSOVTimeline(
            this.accountInfo!.accountId,
            this.accountInfo!.marketplace,
            this.accountInfo!.orgId,
            this.focusedSearchTerm,
          );
        }),
      )
      .subscribe((sovTimeline: ShareOfVoiceTimeline[]) => {
        if (sovTimeline.length) {
          this.sovByBrandDate = this.buildBrandSovByDate(sovTimeline[0].timeline!.brandTimelines!);
          this.noData = false;
        } else {
          this.noData = true;
          this.loading = false;
        }
      });
    this.accountSelectionService.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((isReadonly: boolean) => {
      this.isReadonly = isReadonly;
    });
    this.userSelectionService.dateRange$.subscribe((range: string[]) => {
      this.snapshotDate = new Date(range[1]);
    });
  }

  private getSearchTermTimeline(s: string) {
    this.keywordTrackingService
      .getSOVTimeline(this.accountInfo!.accountId, this.accountInfo!.marketplace, this.accountInfo!.orgId, s)
      .subscribe((sovTimeline: ShareOfVoiceTimeline[]) => {
        if (!sovTimeline.length) return;
        this.sovByBrandDate = this.buildBrandSovByDate(sovTimeline[0].timeline!.brandTimelines!);
      });
  }

  private buildBrandSovByDate(brandsTimeline: BrandTimeline[]) {
    const sovByBrandDate = new Map<string, Map<string, number>>();

    brandsTimeline
      .filter((b: BrandTimeline) => b.brand !== "other")
      .forEach((b: BrandTimeline) => {
        this.brandColors.set(b.brand!, this.genBrandColor(b.brand!));
        const sovByDate: Map<string, number> = new Map();
        let lastTimestamp = 0;

        b.scores?.forEach((bScore: BrandScore) => {
          lastTimestamp += bScore.timestampInDay! * 24 * 60 * 60 * 1000;
          const scoreDate = new Date(lastTimestamp);
          scoreDate.setHours(0);

          sovByDate.set(scoreDate.toDateString(), bScore.score!);
        });

        sovByBrandDate.set(b.brand!, sovByDate);
      });

    this.loading = false;
    return sovByBrandDate;
  }

  genBrandColor(brand: string): string {
    return Utils.genColor(brand);
  }

  exportToCsv() {
    const dateRange = this.userSelectionService.getDateRangeStr();

    const dataArray = [];

    for (const [brand, dateSov] of this.sovByBrandDate ?? []) {
      for (const [date, sov] of dateSov) {
        const toDate: Date = new Date(date);
        if (toDate > new Date(dateRange[0]) && toDate < new Date(dateRange[1])) {
          const formatted = formatDate(toDate, "shortDate", "en");
          dataArray.push({ brand: brand, date: formatted, "share of voice": sov });
        }
      }
    }

    this.csvService.exportCsv(
      {
        prefix: "share_of_voice_" + this.focusedSearchTerm,
        dateRange: dateRange,
      },
      dataArray,
    );
  }

  setBrandSelection(b: string[]) {
    this.brandSelection = [...b];
  }

  addNewKeyword() {
    this.kwTrackerService.openAddKwTrackingModal(
      this.accountInfo!.accountId,
      this.accountInfo!.marketplace,
      this.accountInfo!.orgId,
      [],
      " First share of voice data will be available after 24h",
    );

    this.kwDropdownOpen = false;
  }

  focusSearchTerm(s: Option<string>) {
    this.loading = true;
    this.focusedSearchTerm = s.value;
    this.focusedSearchTermOption = s;
    this.getSearchTermTimeline(s.value);
  }
}
