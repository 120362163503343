import { Component, Input, OnInit } from "@angular/core";
import { Currency, StrategyType } from "@front/m19-api-client";
import {
  ACOS,
  AD_CONVERSIONS,
  AD_SALES,
  CLICK_THROUGH_RATE,
  CLICKS,
  CONVERSION_RATE,
  COST,
  CPC,
  IMPRESSIONS,
  Metric,
  ROAS,
} from "@front/m19-metrics";
import {
  AccountSelectionService,
  AdStatsData,
  AdStatsWithTargetHistory,
  AuthService,
  DataSet,
  DataSetEventAnnotation,
  StatsApiClientService,
  StatsService,
  StrategyTargetHistory,
  UserSelectionService,
} from "@front/m19-services";
import {
  AggregationFunction,
  convertToCurrency,
  mergeSeveralDates,
  MetricsSelectorLocalStorageKey,
} from "@front/m19-utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { ActivityService } from "@m19-board/activities/activity.service";
import { StrategyStats } from "@m19-board/models/Metric";

import { AdStatsEx, StrategyEx } from "@front/m19-models";
import { TranslocoService } from "@jsverse/transloco";
import { ChartData } from "@m19-board/shared/chart-renderer/chart-renderer.component";
import { BsModalRef } from "ngx-bootstrap/modal";
import { combineLatest, map, Observable, of, shareReplay, switchMap } from "rxjs";
import { DAILY_BUDGET, MIN_DAILY_SPEND, MONTHLY_BUDGET, TARGET_ACOS } from "@m19-board/models/MetricsDef";

@UntilDestroy()
@Component({
  selector: "app-strategy-stats",
  template: ` <div class="modal-header bg-primary">
      <h4 class="modal-title">Strategy Stats - {{ strategy.name }}</h4>
      <h4 class="text-secondary pointer" (click)="close()">&times;</h4>
    </div>
    <div class="modal-body" style="height: 90vh">
      <mat-tab-group
        animationDuration="0ms"
        [selectedIndex]="selectedTab"
        (selectedIndexChange)="selectedTab = $event; tabChanged = true"
      >
        <mat-tab label="Strategy">
          <app-chart-renderer
            title=""
            [dataset]="dataSet"
            [metrics]="METRIC_COLUMNS"
            [localStorageKey]="localStorageKey"
            [selectMetricCallback]="selectMetricCallback"
            [chartData$]="chartData$"
            [withEventAnnotations]="true"
            [annotations$]="annotations$"
          ></app-chart-renderer>
        </mat-tab>
        <mat-tab label="Targeting" class="py-2">
          @if (tabChanged) {
            <app-targeting-asin-stats
              [isReadOnly]="isReadOnly"
              [strategy]="strategy"
              [withTOSROSwitch]="withTOSROSwitch"
            ></app-targeting-asin-stats>
          }
        </mat-tab>
      </mat-tab-group>
    </div>`,
})
export class StrategyStatsComponent implements OnInit {
  @Input()
  strategy!: StrategyEx;
  @Input()
  isReadOnly = false;

  readonly METRIC_COLUMNS: Metric<AdStatsEx>[] = [
    AD_SALES,
    AD_CONVERSIONS,
    COST,
    ACOS,
    CLICKS,
    IMPRESSIONS,
    CLICK_THROUGH_RATE,
    CONVERSION_RATE,
    CPC,
    ROAS,
  ];
  readonly localStorageKey = MetricsSelectorLocalStorageKey.strategy;
  readonly dataSet: DataSet<AdStatsEx>;
  withTOSROSwitch = false;
  chartData$!: Observable<ChartData<AdStatsEx>>;
  annotations$!: Observable<DataSetEventAnnotation[]>;
  selectedTab = 0;
  // this boolean is to lazy load the Targeting asin stats component (loaded only once tab is changed)
  tabChanged = false;
  additionalCostMetrics: Metric<StrategyStats>[] = [MIN_DAILY_SPEND, DAILY_BUDGET, MONTHLY_BUDGET];

  constructor(
    private authService: AuthService,
    private statsApiService: StatsApiClientService,
    private activityService: ActivityService,
    private userSelectionService: UserSelectionService,
    private bsModalRef: BsModalRef,
    private translocoService: TranslocoService,
    private accountSelectionService: AccountSelectionService,
    private statsService: StatsService,
  ) {
    this.dataSet = new DataSet<AdStatsWithTargetHistory>(
      3,
      [AD_SALES, COST],
      [AggregationFunction.mergeAdStatsWithTargetHistory],
      this.translocoService,
    );
    this.dataSet.metricsOnSameScale = [
      [AD_SALES, COST],
      [ACOS, TARGET_ACOS],
      [COST, MIN_DAILY_SPEND, DAILY_BUDGET, MONTHLY_BUDGET],
    ];
  }

  ngOnInit(): void {
    this.withTOSROSwitch = this.strategy.strategyType == StrategyType.PRODUCT;
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.dataSet.locale = user.locale;
    });
    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((currency) => {
      this.dataSet.currency = currency;
    });
    const dailyPlacementStats$ = combineLatest([
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      this.userSelectionService.dateRange$,
    ]).pipe(
      switchMap(([am, dr]) =>
        combineLatest([
          this.userSelectionService.selectedCurrency$,
          this.statsService.getDailyPlacementStats(am.accountId, am.marketplace, dr[0], dr[1]),
        ]),
      ),
      map(([currency, data]) => convertToCurrency(data, currency)!),
    );
    const previousDailyPlacementStats$ = combineLatest([
      this.accountSelectionService.singleAccountMarketplaceSelection$,
      this.userSelectionService.periodComparison$,
    ]).pipe(
      switchMap(([am, pc]) => {
        if (!pc?.period) {
          return of([]);
        }
        return combineLatest([
          this.userSelectionService.selectedCurrency$,
          this.statsService.getDailyPlacementStats(am.accountId, am.marketplace, pc.period[0], pc.period[1]),
        ]).pipe(
          map(([currency, data]) => {
            return convertToCurrency(data, currency)!;
          }),
        );
      }),
    );
    this.chartData$ = combineLatest([
      dailyPlacementStats$,
      previousDailyPlacementStats$,
      this.userSelectionService.selectedCurrency$.pipe(
        switchMap((currency) => this.statsApiService.getStrategyTargetHistory(this.strategy.strategyId, currency)),
      ),
      this.userSelectionService.selectedCurrency$,
    ]).pipe(
      map(([dailyPlacementStats, previousPeriodDailyPlacementStats, targetHistory, currency]) => {
        const stats = dailyPlacementStats.filter((d) => d.strategyId == this.strategy.strategyId);
        const previousStats = previousPeriodDailyPlacementStats.filter((d) => d.strategyId == this.strategy.strategyId);

        for (const target of targetHistory) {
          if (target.dailyBudget !== undefined && !isNaN(target.dailyBudget))
            this.additionalCostMetrics.push(DAILY_BUDGET);
          if (target.minDailySpend !== undefined && target.minDailySpend !== 0)
            this.additionalCostMetrics.push(MIN_DAILY_SPEND);
          if (target.monthlyBudget !== undefined && !isNaN(target.monthlyBudget))
            this.additionalCostMetrics.push(MONTHLY_BUDGET);
        }

        const totalData = stats.reduce((prev, curr) => mergeSeveralDates(prev, curr), {});
        const totalPreviousData = previousStats?.reduce((curr, prev) => mergeSeveralDates(curr, prev), {});
        return {
          data: [...targetHistory, ...stats],
          previousData: previousStats,
          totalData,
          totalPreviousData,
        };
      }),
    );
    this.annotations$ = this.activityService.getStrategyActivityEventAnnotation(
      this.strategy.accountId,
      this.strategy.marketplace,
      this.strategy,
    );
  }

  selectMetricCallback = (metrics: Metric<any>[]) => {
    const res = [...metrics];
    if (metrics.includes(ACOS)) {
      res.push(TARGET_ACOS);
    }
    if (metrics.includes(COST)) {
      res.push(...this.additionalCostMetrics);
    }
    return res;
  };

  close() {
    this.bsModalRef.hide();
  }
}
