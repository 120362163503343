import { Component, Input } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { KeywordTrackerConfig, Marketplace } from "@front/m19-api-client";
import { KeywordTrackingService } from "@front/m19-services";
import { TranslocoService } from "@jsverse/transloco";

@Component({
  templateUrl: "./stop-keyword-tracking-modal.component.html",
})
export class StopKeywordTrackingModalComponent {
  @Input()
  accountId!: string;
  @Input()
  marketplace!: Marketplace;
  @Input()
  organizationId!: number;
  @Input()
  searchTerm!: string;

  constructor(
    private keywordTrackingService: KeywordTrackingService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService,
    public bsModalRef: BsModalRef,
  ) {}

  delete() {
    const toDelete: KeywordTrackerConfig[] = [
      {
        accountId: this.accountId,
        marketplace: this.marketplace,
        organizationId: this.organizationId,
        searchTerm: this.searchTerm,
      },
    ];
    const subscription = this.keywordTrackingService
      .removeKeywordTrackingConfig(this.accountId, this.marketplace, this.organizationId, toDelete)
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("stop-keyword-tracking-modal.tracking_searchterm_has_been_stopped", [
              this.searchTerm,
            ]),
            this.translocoService.translate("stop-keyword-tracking-modal.keyword_tracking_stopped"),
          );
          this.bsModalRef.hide();
          subscription.unsubscribe();
        },
        error: (e) => {
          this.toastrService.error(
            this.translocoService.translate(
              "stop-keyword-tracking-modal.error_when_stopping_tracking_this_searchterm",
              [this.searchTerm, e],
            ),
            this.translocoService.translate("stop-keyword-tracking-modal.keyword_tracking_stop_error"),
          );
          subscription.unsubscribe();
        },
      });
  }
}
