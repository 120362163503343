import { Component, Input, OnInit } from "@angular/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { DataSet, DataSetEventAnnotation, UserSelectionService } from "@front/m19-services";
import { Metric } from "@front/m19-metrics";
import { Option } from "@front/m19-ui";
import { ActivityEventType, ActivityService } from "@m19-board/activities/activity.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  map,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  Subscription,
  switchMap,
} from "rxjs";
import { DateAggregation, MetricsSelectorLocalStorageKey } from "@front/m19-utils";
import { AdStatsEx } from "@front/m19-models";
import { Marketplace } from "@front/m19-api-client";
import { M19_METRICS } from "@m19-board/agency-board/agency-board.component";

export type ChartData<T> = {
  data: T[];
  previousData?: T[];
  totalData: T;
  totalPreviousData?: T;
};

@UntilDestroy()
@Component({
  selector: "app-chart-renderer",
  templateUrl: "./chart-renderer.component.html",
})
export class ChartRendererComponent<T extends AdStatsEx> implements OnInit {
  readonly faTimes = faTimes;

  @Input({ required: true }) localStorageKey!: MetricsSelectorLocalStorageKey;
  @Input({ required: true }) title!: string;
  @Input({ required: true }) dataset!: DataSet<T>;
  @Input({ required: true }) metrics!: Metric<T>[];
  @Input() asin?: string;
  @Input() marketplace?: Marketplace;
  @Input({ required: true }) chartData$!: Observable<ChartData<T> | null>;
  @Input() annotations$: Observable<DataSetEventAnnotation[]> = of([]);
  @Input({ required: true }) selectMetricCallback!: (metrics: Metric<T>[]) => Metric<T>[];
  @Input() withEventAnnotations = false;
  @Input() manageUnmanagedMetrics = false;

  // state
  totalData: AdStatsEx = {};
  totalPreviousData?: AdStatsEx;
  loading = true;
  public splitedDisplayMode = false;
  dateAggregation$: BehaviorSubject<DateAggregation> = new BehaviorSubject<DateAggregation>(DateAggregation.daily);
  private chartMetrics$ = new ReplaySubject<Metric<AdStatsEx>[]>(1);
  displayEventAnnotation$ = new BehaviorSubject(false);
  disableEventAnnotation = false;
  readonly allEventAnnotationTypes: Option<ActivityEventType>[] = this.activityService.allActivityEventTypesOptions;
  readonly allUsers: Observable<Option<string>[]> = this.activityService.allUsersOptions$;

  public managedUnmanaged = "Managed/Unmanaged";
  public total = "Total";
  displayMode: string = this.total;

  constructor(
    public ref: BsModalRef,
    private userSelectionService: UserSelectionService,
    private activityService: ActivityService,
  ) {}

  ngOnInit(): void {
    this.chartData$.pipe(untilDestroyed(this)).subscribe((chartData) => {
      this.totalData = chartData!.totalData;
      this.totalPreviousData = chartData!.totalPreviousData;
    });
    combineLatest([
      this.chartData$,
      this.chartMetrics$,
      this.userSelectionService.dateRange$,
      this.userSelectionService.periodComparison$.pipe(map((x) => x?.period)),
      this.dateAggregation$,
      this.withEventAnnotations
        ? combineLatest([this.dateAggregation$, this.displayEventAnnotation$]).pipe(
            switchMap(([agg, b]) => {
              if (agg == DateAggregation.daily && b) {
                return this.annotations$;
              }
              return of([]);
            }),
          )
        : this.dateAggregation$.pipe(switchMap((agg) => (agg == DateAggregation.daily ? this.annotations$ : of([])))),
    ])
      .pipe(
        untilDestroyed(this),
        debounceTime(100), // adding debounce to avoid sequential refresh which can cause graph not displaying - see https://github.com/m19-dev/main-repo/issues/7206
      )
      .subscribe(([chartData, metrics, dates, periodComparison, dateAggregation, annotations]) => {
        this.loading = false;
        if (this.splitedDisplayMode) {
          const new_metrics: Metric<AdStatsEx>[] = [];
          metrics.forEach((metric: Metric<AdStatsEx>) => {
            if (M19_METRICS.find((m) => m.key === metric) != undefined) {
              new_metrics.push(M19_METRICS.find((m) => m.key === metric)!.op);
              new_metrics.push(M19_METRICS.find((m) => m.key === metric)!.un);
            } else {
              new_metrics.push(metric);
            }
          });
          metrics = new_metrics;
        }
        this.dataset.buildDataSet(
          chartData!.data,
          metrics,
          dateAggregation,
          { minDate: dates[0], maxDate: dates[1] },
          chartData?.previousData && chartData.previousData.length > 0
            ? {
                data: chartData!.previousData!,
                period: periodComparison!,
              }
            : undefined,
          annotations,
        );
      });
    this.dateAggregation$.pipe(untilDestroyed(this)).subscribe((agg) => {
      this.disableEventAnnotation = agg !== DateAggregation.daily;
    });
  }

  selectMetrics(metrics: Metric<T>[]) {
    const chartMetrics = this.selectMetricCallback ? this.selectMetricCallback(metrics) : metrics;
    this.toggleManagedTotalDisplay(chartMetrics as Metric<AdStatsEx>[]);
  }

  selectAggregation(aggregation: DateAggregation): void {
    this.dateAggregation$.next(aggregation);
  }

  hideChart() {
    this.ref.hide();
  }

  toggleEventAnnotation(change: MatSlideToggleChange): void {
    this.displayEventAnnotation$.next(change.checked);
  }

  toggleManagedTotalDisplay(metrics: Metric<AdStatsEx>[], sub?: Subscription) {
    if (sub) sub.unsubscribe();
    if (this.displayMode === this.total) {
      const newSelected: Set<Metric<AdStatsEx>> = new Set();
      for (const m of metrics) {
        if (
          M19_METRICS.map((metric) => metric.op).includes(m as Metric<AdStatsEx>) ||
          M19_METRICS.map((metric) => metric.un).includes(m as Metric<AdStatsEx>)
        ) {
          newSelected.add(M19_METRICS.find((metric) => metric.op === m || metric.un === m)!.key);
        } else {
          newSelected.add(m);
        }
      }
      this.chartMetrics$.next([...newSelected]);
    } else {
      const newSelected: Metric<AdStatsEx>[] = [];

      for (const m of metrics) {
        if (M19_METRICS.map((metric) => metric.key).includes(m as Metric<AdStatsEx>)) {
          newSelected.push(M19_METRICS.find((metric) => metric.key === m)!.op);
          newSelected.push(M19_METRICS.find((metric) => metric.key === m)!.un);
        } else {
          newSelected.push(m as Metric<AdStatsEx>);
        }
      }
      this.chartMetrics$.next(newSelected);
    }
  }

  selectDisplayMode(displayMode: string) {
    if (!this.manageUnmanagedMetrics) {
      return;
    }
    let metricsList: Metric<AdStatsEx>[] = [];
    const sub = this.chartMetrics$.pipe(shareReplay(1)).subscribe((metrics) => {
      metricsList = metrics;
    });
    this.displayMode = displayMode;

    this.toggleManagedTotalDisplay(metricsList, sub);
  }
}
