import { Component, Input, OnInit } from "@angular/core";
import { CampaignType, EntityIdType, Strategy } from "@front/m19-api-client";
import { SbStrategiesService } from "@front/m19-services";
import { ActivityComponent, ActivityFilter } from "@m19-board/activities/activity/activity.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-strategy-activity-component",
  template: ` <div class="activity-section">
    <app-activity-component
      [activityFilter]="activityFilter"
      [hideEntityColumn]="true"
      gridConfigStorageKey="strategyActivities"
    ></app-activity-component>
  </div>`,
  standalone: true,
  imports: [ActivityComponent],
  styles: [".activity-section {height: 30rem;}"],
})
export class StrategyActivityComponent implements OnInit {
  @Input()
  strategy: Strategy | undefined;

  activityFilter: ActivityFilter[] = [];

  constructor(private sbStrategyService: SbStrategiesService) {}

  ngOnInit() {
    if (!this.strategy) {
      return;
    }
    this.activityFilter = [{ primaryType: EntityIdType.strategyId, primaryId: this.strategy.strategyId }];
    if (this.strategy.campaignType == CampaignType.SB) {
      this.sbStrategyService
        .getSbCreativesPerStrategy(this.strategy.accountId, this.strategy.marketplace)
        .pipe(untilDestroyed(this))
        .subscribe((creatives) => {
          const strategyCreatives = creatives.get(this.strategy!.strategyId!) ?? [];
          this.activityFilter.push(
            ...strategyCreatives.map((c) => ({
              primaryType: EntityIdType.creativeId,
              primaryId: c.creativeId,
            })),
          );
        });
    }
    if (this.strategy.campaignType == CampaignType.SD) {
      this.activityFilter.push(
        ...this.strategy.audienceTargetings.map((a) => ({
          primaryType: EntityIdType.audienceTargetId,
          primaryId: a.audienceTargetId,
        })),
      );
    }
  }
}
