import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AccountMarketplace, BrandAsset, MediaType, SbAsins, SbCreativeType } from "@front/m19-api-client";
import { TacosStrategiesService } from "@front/m19-services";
import { IAlertComponent, IButtonComponent } from "@front/m19-ui";
import { ProductThumbnailComponent } from "@m19-board/product-view/product-thumbnail.component";
import { AsinStrategyUsageComponent } from "@m19-board/shared/asin-strategy-usage/asin-strategy-usage-modal.component";
import { DragDropAsinsComponent } from "@m19-board/shared/drag-drop-asins/drag-drop-asins.component";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { BsModalService } from "ngx-bootstrap/modal";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { SbAdFormat } from "../sb-form-ad-format/sb-form-ad-format.component";
import { StoreSpotlightPagesComponent } from "../sb-spotlight-pages/store-spotlight-pages.component";
import { FormStepEvent, SbFormStep } from "../sb-strategy-form.component";

export interface SbAsinGroup {
  asins: SbAsins[];
}

@Component({
  selector: "app-sb-form-asins",
  templateUrl: "./sb-form-asins.component.html",
  standalone: true,
  imports: [
    IButtonComponent,
    ProductThumbnailComponent,
    StoreSpotlightPagesComponent,
    DragDropAsinsComponent,
    IAlertComponent,
    ReactiveFormsModule,
    MatTooltipModule,
    TranslocoRootModule,
  ],
})
export class SbFormAsinsComponent implements OnInit {
  readonly tacosStrategiesService = inject(TacosStrategiesService);
  readonly modalService = inject(BsModalService);
  readonly SbCreativeType = SbCreativeType;

  @Input({ required: true }) am!: AccountMarketplace;

  @Input() store?: BrandAsset;
  @Input() availableStores?: BrandAsset[];
  stores?: BrandAsset[];

  @Input() creativeType?: SbCreativeType;

  @Input() set sbAsins(i: SbAsinGroup) {
    this.form.patchValue(i);
    if (i) this.asinsAsArray = i.asins.map((a: SbAsins) => [a.asin1!, a.asin2!, a.asin3!]);
  }

  @Input() sbAdFormat?: SbAdFormat;
  @Input() reviewMode = false;
  @Input() tacosStrategyId?: number;

  @Output() onFormSubmit = new EventEmitter<FormStepEvent<SbAsinGroup>>();
  @Output() onFormPrevious = new EventEmitter<void>();

  // used to display asins in review mode
  asinsAsArray?: string[][];

  form = new FormGroup({
    asins: new FormControl<SbAsins[] | null>(null, [Validators.required, this.AsinsValidator()]),
  });

  tacosAsins = toSignal(this.tacosStrategiesService.tacosStrategyByAsins$);

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      )
      .subscribe((_) => {
        if (this.form.valid) {
          this.submitForm();
        }
      });

    this.stores = this.availableStores?.filter((a: BrandAsset) => {
      return a.mediaType === MediaType.storePage && (!this.store || a.brandEntityId === this.store.brandEntityId);
    });
  }

  submitForm(goNext = false): void {
    if (this.form.valid) {
      this.onFormSubmit.emit({ formData: this.form.value as SbAsinGroup, step: SbFormStep.ASINS, goNext });
    }
  }

  openAsinUsageModal() {
    this.modalService.show(AsinStrategyUsageComponent, {
      initialState: {
        am: this.am,
      },
    });
  }

  // Check for duplicate sb ASINs considering the order
  private AsinsValidator() {
    return (control: AbstractControl) => {
      const asins = control.value as SbAsins[];
      if (asins) {
        const asinSet = new Set<string>();
        for (const asin of asins) {
          const key = `${asin.asin1}-${asin.asin2}-${asin.asin3}`;
          if (asinSet.has(key)) {
            return { duplicate: true };
          }
          asinSet.add(key);
        }
      }
      return null;
    };
  }
}
