import { Component, Input, OnInit } from "@angular/core";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { AudienceTargeting, Marketplace, MatchType, StrategyTactic, TacticType } from "@front/m19-api-client";
import { SegmentEx, StrategyEx, SupportedAudienceMatchType } from "@front/m19-models";
import { SdStrategiesService, SegmentService, StrategyService } from "@front/m19-services";
import { TranslocoService } from "@jsverse/transloco";
import { ProductSegmentModalComponent } from "@m19-board/segments/product-segment-modal.component";
import { ModalRemoveTacticFromStrategyComponent } from "@m19-board/strategies/tactic/tactic.component";
import { UntilDestroy } from "@ngneat/until-destroy";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";

/* Modal component to delete the Audience from the Strategy */
@Component({
  templateUrl: "./remove-audience-popup.component.html",
})
export class ModalRemoveAudienceFromStrategyComponent {
  @Input()
  accountId: string | undefined;
  @Input()
  marketplace: Marketplace | undefined;
  @Input()
  strategyId: number | undefined;
  @Input()
  audience: AudienceTargeting | undefined;

  constructor(
    private sdStrategiesService: SdStrategiesService,
    public bsModalRef: BsModalRef,
    private toastrService: ToastrService,
    private translocoService: TranslocoService,
  ) {}

  delete() {
    this.sdStrategiesService
      .removeRemarketingAudienceFromSdStrategy(
        this.accountId!,
        this.marketplace!,
        this.strategyId!,
        this.audience!.audienceTargetId!,
      )
      .subscribe({
        next: () => {
          this.toastrService.success(this.translocoService.translate("sd-targeting.audience_removed"));
          this.bsModalRef.hide();
        },
        error: (error) => {
          this.toastrService.error(error, this.translocoService.translate("sd-targeting.audience_removal_failed"));
        },
      });
    this.bsModalRef.hide();
  }
}

@Component({
  templateUrl: "./remove-ia-powered-targeting-popup.component.html",
})
export class ModalRemoveAIPoweredTargetingFromStrategyComponent {
  accountId!: string;
  strategyId!: number;
  marketplace!: Marketplace;
  organizationId!: number;

  constructor(
    private strategyService: StrategyService,
    public bsModalRef: BsModalRef,
    private toastrService: ToastrService,
    private translocoService: TranslocoService,
  ) {}

  delete() {
    this.strategyService
      .updateStrategyAutoAlgoExploration(this.accountId, this.marketplace, this.organizationId, this.strategyId, false)
      .subscribe({
        next: () => {
          this.toastrService.success(this.translocoService.translate("sd-targeting.ai-powered_targeting_removed"));
          this.bsModalRef.hide();
        },
        error: (error) => {
          this.toastrService.error(error);
        },
      });
  }
}

@UntilDestroy()
@Component({
  selector: "app-sd-targeting",
  templateUrl: "./sd-targeting.component.html",
  styleUrls: ["./sd-targeting.component.scss"],
})
export class SdTargetingComponent implements OnInit {
  readonly faPencil = faPencilAlt;
  readonly faTrash = faTrashAlt;
  readonly TacticType = TacticType;
  readonly supportedAudienceMatchType = SupportedAudienceMatchType;

  @Input() tactic?: StrategyTactic;

  @Input() audience?: AudienceTargeting;

  @Input() strategy?: StrategyEx;

  @Input() organizationId!: number;

  @Input() isReadOnly = false;

  @Input() defaultTactic = false;

  @Input() isLastTargeting?: boolean;

  segment: SegmentEx | undefined;

  constructor(
    private modalService: BsModalService,
    private segmentService: SegmentService,
  ) {}

  ngOnInit(): void {
    if (this.tactic?.segmentId && this.strategy) {
      this.segmentService.getSegments(this.strategy.accountId, this.strategy.marketplace).subscribe((segments) => {
        this.segment = segments.get(this.tactic!.segmentId!);
      });
    }
  }

  nbProductTargeting(): number {
    if (!this.segment) {
      return 0;
    }
    return this.segment.items.filter((x) => x.matchType == MatchType.asinSameAs).length ?? 0;
  }

  editSegment() {
    const modalOptions: ModalOptions = {
      initialState: {
        segment: this.segment,
        isReadOnly: this.isReadOnly,
      },
      class: "modal-xl",
    };
    this.modalService.show(ProductSegmentModalComponent, modalOptions);
  }

  // AI-powered targeting
  disableAutoAlgoExploration() {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.strategy!.accountId,
        strategyId: this.strategy!.strategyId,
        marketplace: this.strategy!.marketplace,
        organizationId: this.organizationId,
      },
      class: "modal-danger",
    };
    this.modalService.show(ModalRemoveAIPoweredTargetingFromStrategyComponent, modalOptions);
  }

  removeTacticFromStrategy() {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.strategy!.accountId,
        marketplace: this.strategy!.marketplace,
        strategyId: this.strategy!.strategyId,
        tactic: this.tactic,
        segment: this.segment,
        isSdTargeting: true,
      },
      class: "modal-danger",
    };
    this.modalService.show(ModalRemoveTacticFromStrategyComponent, modalOptions);
  }

  removeAudienceFromStrategy() {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.strategy!.accountId,
        marketplace: this.strategy!.marketplace,
        strategyId: this.strategy!.strategyId,
        audience: this.audience,
      },
      class: "modal-danger",
    };
    this.modalService.show(ModalRemoveAudienceFromStrategyComponent, modalOptions);
  }
}
