import { AudienceMatchType } from '../api-client';

export enum SdTargetingType {
  PRODUCT_AI_TARGETING = 'Product Page - AI-powered Targeting',
  BLACKLIST_PRODUCT = 'Blacklist Product Pages',
  PRODUCT = 'Product Page',
  REMARKETING = 'Remarketing',
  // AUDIENCE = "AUDIENCE",
}

export const SupportedAudienceMatchType: Record<AudienceMatchType, string | undefined> = {
  [AudienceMatchType.similarProduct]: 'Similar Product',
  [AudienceMatchType.relatedProduct]: 'Related Product',
  [AudienceMatchType.exactProduct]: 'Exact Product',
  [AudienceMatchType.audienceSameAs]: undefined,
  [AudienceMatchType.asinCategorySameAs]: undefined,
};
export const SupportedAudienceLookback = [7, 14, 30, 60, 90];
