import { CDK_DRAG_CONFIG, CdkDragDrop, DragDropModule } from "@angular/cdk/drag-drop";
import { NgClass, NgTemplateOutlet } from "@angular/common";
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrandAsset, Marketplace, SbAsins } from "@front/m19-api-client";
import { IButtonComponent, IInputComponent, Option } from "@front/m19-ui";
import { ProductThumbnailComponent } from "@m19-board/product-view/product-thumbnail.component";
import { AMAZON_HOME_STORE_PAGE_PATH } from "@m19-board/strategies/sb-strategy-form/sb-strategy-form.component";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { distinctUntilChanged, startWith } from "rxjs";

@Pipe({ standalone: true, name: "textFilter" })
export class TextFilterPipe implements PipeTransform {
  transform<T>(array: T[], value: (value: T) => string, filter: string): T[] {
    if (!filter) return array;
    const regex = new RegExp(filter, "i");
    if (typeof value === "function") return array.filter((v) => value(v).search(regex) > -1);
    return array.filter((v) => String(v).search(regex) > -1);
  }
}

export type SpotlightPagesValidation = {
  missingTitle?: number; // index
  missingAsin?: number; // index
};

const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000,
};

interface SpotlightCreativeProduct {
  title: string;
  storePage: BrandAsset;
  asin: string;
}

@Component({
  selector: "app-store-spotlight-pages",
  templateUrl: "./store-spotlight-pages.component.html",
  styleUrls: ["./store-spotlight-pages.component.scss"],
  standalone: true,
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }],
  imports: [
    IButtonComponent,
    ProductThumbnailComponent,
    MatTooltipModule,
    IInputComponent,
    NgClass,
    TextFilterPipe,
    MatMenuModule,
    DragDropModule,
    TranslocoRootModule,
    NgTemplateOutlet,
  ],
})
export class StoreSpotlightPagesComponent implements OnInit {
  private fb = inject(FormBuilder);

  // Available store pages
  @Input() set storePages(s: BrandAsset[]) {
    this._storePages = s;

    // set store pages options without home page
    this._storePagesOptions = s
      .filter((p) => p.name!.split("/").pop() !== AMAZON_HOME_STORE_PAGE_PATH)
      .map((p) => ({ label: this.getPageName(p), value: p }));

    if (s?.length) {
      this.selectedStoreName = s[0].name!.split("/")[0];
    }

    this.initSbAsins();
  }

  _storePages?: BrandAsset[];
  _storePagesOptions?: Option<BrandAsset>[];
  selectedStoreName?: string;

  @Input() marketplace?: Marketplace;

  @Output() emitSbAsins = new EventEmitter<SbAsins[]>();
  @Output() emitValidation = new EventEmitter<SpotlightPagesValidation>();

  storeAsinModal?: BsModalRef;
  searchAsinFilter = new FormControl("");
  filteredAsinList: string[] = [];

  searchStorePageFilter = new FormControl("");
  focusedAsinControl?: FormGroup<{
    storePage: FormControl<BrandAsset | null>;
    asin: FormControl<string>;
  }>;
  noAsinsFound = false;

  form = this.fb.group({
    sbAsin1: this.fb.group({
      title: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(50)]),
      asin: new FormControl<string | null>(null, [Validators.required]),
      storePage: new FormControl<BrandAsset | null>(null, [Validators.required]),
    }),
    sbAsin2: this.fb.group({
      title: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(50)]),
      asin: new FormControl<string | null>(null, [Validators.required]),
      storePage: new FormControl<BrandAsset | null>(null, [Validators.required]),
    }),
    sbAsin3: this.fb.group({
      title: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(50)]),
      asin: new FormControl<string | null>(null, [Validators.required]),
      storePage: new FormControl<BrandAsset | null>(null, [Validators.required]),
    }),
  });

  asinOrder: FormGroup[] = [this.form.controls.sbAsin1, this.form.controls.sbAsin2, this.form.controls.sbAsin3];

  @ViewChild("asinModalTemplate") asinModalTemplate!: TemplateRef<any>;

  constructor(private modalService: BsModalService) {}

  ngOnInit() {
    this.searchAsinFilter.valueChanges.subscribe((_) => {
      this.filteredAsinList =
        this.focusedAsinControl?.controls.storePage?.value?.asinList?.filter((p: string) => this.passAsinFilter(p)) ??
        [];
    });

    // ensure form has not changed
    this.form.valueChanges
      .pipe(
        distinctUntilChanged((a, b) => {
          return JSON.stringify(a) === JSON.stringify(b);
        }),
        startWith(this.form.value),
      )
      .subscribe((_) => {
        this.emitAsins();
      });
  }

  // When no existing creative is provided, we need to initialize the form with some default values
  private initSbAsins() {
    // Take this first 3 pages except HOME page with at least 1 asin
    const pages = this._storePages
      ?.filter((p) => p.name!.split("/").pop() !== AMAZON_HOME_STORE_PAGE_PATH)
      .filter((p) => p.asinList && p.asinList.length > 0)
      .slice(0, 3);
    if (!pages || pages.length === 0) {
      this.noAsinsFound = true;
      return;
    }
    const asins = pages.map((p) => p.asinList![0]);
    this.form.patchValue({
      sbAsin1: { title: this.getPageName(pages[0]), asin: asins[0], storePage: pages[0] },
      sbAsin2: { title: this.getPageName(pages[1]), asin: asins[1], storePage: pages[1] },
      sbAsin3: { title: this.getPageName(pages[2]), asin: asins[2], storePage: pages[2] },
    });
  }

  drop(event: CdkDragDrop<FormGroup>) {
    // swap the two sbAsins in the form
    const [previousAsin, currentAsin] = [this.asinOrder[event.previousIndex], this.asinOrder[event.currentIndex]];
    // make copy
    const tmp = previousAsin.value;

    previousAsin.setValue(currentAsin.value);
    currentAsin.setValue(tmp);
  }

  private emitAsins() {
    const res: SbAsins[] = [
      {
        title1: this.asinOrder[0].controls["title"].value,
        asin1: this.asinOrder[0].controls["asin"].value,
        storePageId1: this.asinOrder[0].controls["storePage"].value.assetId,
        title2: this.asinOrder[1].controls["title"].value,
        asin2: this.asinOrder[1].controls["asin"].value,
        storePageId2: this.asinOrder[1].controls["storePage"].value.assetId,
        title3: this.asinOrder[2].controls["title"].value,
        asin3: this.asinOrder[2].controls["asin"].value,
        storePageId3: this.asinOrder[2].controls["storePage"].value.assetId,
      },
    ];
    this.emitSbAsins.emit(res);
  }

  private patchFormValues(a: SbAsins) {
    const res = this.toSpotlightCreativeProduct(a);
    this.form.patchValue({
      sbAsin1: res[0],
      sbAsin2: res[1],
      sbAsin3: res[2],
    });
  }

  private toSpotlightCreativeProduct(a: SbAsins): SpotlightCreativeProduct[] {
    return [
      { title: a.title1!, asin: a.asin1!, storePage: this._storePages!.find((p) => p.assetId === a.storePageId1)! },
      { title: a.title2!, asin: a.asin2!, storePage: this._storePages!.find((p) => p.assetId === a.storePageId2)! },
      { title: a.title3!, asin: a.asin3!, storePage: this._storePages!.find((p) => p.assetId === a.storePageId3)! },
    ];
  }

  changeStorePage(asinControl: FormGroup, page: BrandAsset) {
    asinControl.controls["storePage"].setValue(page);
    asinControl.controls["asin"].setValue(page.asinList![0]);
    asinControl.controls["title"].setValue(this.getPageName(page));
  }

  getPageName(a: BrandAsset | null): string {
    return a?.name ? a.name.split("/").pop()! : "";
  }

  filterAccessValue(brandAsset: Option<BrandAsset>): string {
    return brandAsset.label;
  }

  isPageSelected(page: BrandAsset): boolean {
    return (
      this.form.controls.sbAsin1.controls.storePage.value!.assetId === page.assetId ||
      this.form.controls.sbAsin2.controls.storePage.value!.assetId === page.assetId ||
      this.form.controls.sbAsin3.controls.storePage.value!.assetId === page.assetId
    );
  }

  openAsinList(asinControl: FormGroup) {
    this.focusedAsinControl = asinControl;
    this.filteredAsinList = asinControl.controls["storePage"].value.asinList;
    this.storeAsinModal = this.modalService.show(this.asinModalTemplate, {
      class: "modal-primary modal-lg modal-dialog-centered",
    });
  }

  isAsinSelectedForPage(asin: string) {
    return this.focusedAsinControl?.controls["asin"].value === asin;
  }

  private passAsinFilter(asin: string) {
    return new RegExp(this.searchAsinFilter.value!, "i").test(asin);
  }
}
