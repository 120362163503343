import {
  AfterViewInit,
  booleanAttribute,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { autoUpdate, computePosition, flip, offset, size } from '@floating-ui/dom';

@Component({
  standalone: true,
  imports: [],
  template: '',
})
export class PopperComponent implements AfterViewInit {
  @ViewChild('popperToggle') popperToggle!: ElementRef<HTMLElement>;
  @ViewChild('popper') popper!: ElementRef<HTMLElement>;

  @Input() popperWidth: string | undefined;
  parentWidthAsDefault = signal(true);
  @Input({ transform: booleanAttribute }) openOnInit = false;

  @Output() popperClose = new EventEmitter<void>();

  private cleanup: (() => void) | undefined;

  @HostListener('window:click', ['$event']) onClick(event: MouseEvent) {
    this.handleDocumentClick(event);
  }

  @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      if (this.isPopperVisible()) this.hideMenu();
    }
  }

  ngAfterViewInit(): void {
    if (this.openOnInit) this.showMenu();
  }

  private handleDocumentClick(event: MouseEvent) {
    const clickedOnToggle = this.popperToggle?.nativeElement.contains(event.target as Node);
    const clickedOnPopper = this.popper?.nativeElement.contains(event.target as Node);

    if (clickedOnToggle) {
      this.togglePopper();
    } else if (!clickedOnPopper && this.isPopperVisible()) {
      this.hideMenu();
    }
  }

  private togglePopper() {
    if (this.isPopperVisible()) {
      this.hideMenu();
    } else {
      this.showMenu();
    }
  }

  protected updateMenu() {
    computePosition(this.popperToggle.nativeElement, this.popper.nativeElement, {
      placement: 'bottom-start',
      middleware: [
        offset(8),
        flip(),
        size({
          apply: ({ elements }) => {
            // sets the popper width to the same width as the reference element if popperWidth is not set
            if (this.parentWidthAsDefault()) {
              this.popper.nativeElement.style.width =
                this.popperWidth ?? `${elements.reference.getBoundingClientRect().width}px`;
            }
          },
        }),
      ],
    }).then(({ x, y }) => {
      Object.assign(this.popper.nativeElement.style, {
        top: `${y}px`,
        left: `${x}px`,
      });
    });
  }

  protected showMenu() {
    if (!this.popper) return;
    this.popper.nativeElement.classList.remove('scale-90', 'opacity-0', 'invisible');
    this.popper.nativeElement.classList.add('scale-100', 'opacity-100', 'visible');

    this.cleanup = autoUpdate(this.popperToggle.nativeElement, this.popper.nativeElement, () => this.updateMenu());
  }

  protected hideMenu() {
    if (!this.popper) return;
    this.popper.nativeElement.classList.remove('scale-100', 'opacity-100', 'visible');
    this.popper.nativeElement.classList.add('scale-90', 'opacity-0', 'invisible');

    if (this.cleanup) this.cleanup();
    this.popperClose.emit();
  }

  private isPopperVisible() {
    return this.popper.nativeElement.classList.contains('visible');
  }
}
