import { NgModule } from "@angular/core";
import { RegistrationButtonComponent } from "@m19-board/settings/billing-settings/registration-button/registration-button.component";
import { IButtonComponent } from "@front/m19-ui";
import { TranslocoDirective } from "@jsverse/transloco";

@NgModule({
  declarations: [RegistrationButtonComponent],
  exports: [RegistrationButtonComponent],
  imports: [IButtonComponent, TranslocoDirective],
})
export class RegistrationButtonModule {}
