import { Component, EventEmitter, Input, Output } from "@angular/core";
import { History } from "@front/m19-api-client";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { ModalActivityCommentComponent } from "../modal-activity-comment/modal-activity-comment.component";
import { ICON_EDIT_O } from "@m19-board/utils/iconsLabels";

@Component({
  template: ` <ng-container *transloco="let t">
    <span (click)="updateHistoryComment()" [ngClass]="{ 'cell-editable': !readonly }">{{ history.comment }}</span>
    @if (!history.comment) {
      <IButton
        [icon]="ICON_EDIT_O"
        color="gray"
        variant="ghost"
        size="xs"
        [square]="true"
        (onClick)="updateHistoryComment()"
        tooltipValue="{{ t('activity-comment.add_a_comment_on_this_activity') }}"
        [disabled]="readonly"
      />
    }
  </ng-container>`,
})
export class ActivityCommentComponent implements ICellRendererAngularComp {
  readonly ICON_EDIT_O = ICON_EDIT_O;

  @Input({ required: true })
  history!: History;
  @Input({ required: true })
  readonly!: boolean;
  @Output()
  commentUpdate = new EventEmitter<string>();

  constructor(
    private modalService: BsModalService,
    private toasterService: ToastrService,
  ) {}

  agInit(params: ICellRendererParams<History> & { readonly: boolean; commentUpdate: (comment: string) => void }) {
    this.history = params.data!;
    this.commentUpdate.subscribe((comment) => params.commentUpdate(comment));
  }

  refresh(): boolean {
    return false;
  }

  updateHistoryComment() {
    if (this.readonly) {
      return;
    }
    const modalOptions: ModalOptions = {
      initialState: {
        history: this.history,
      },
      class: "modal-lg",
    };
    const modalRef = this.modalService.show(ModalActivityCommentComponent, modalOptions);

    const subscription = modalRef.content?.update.subscribe((comment) => {
      this.toasterService.success("Activity update", "Successfully updated activity");
      this.commentUpdate.emit(comment);
      subscription?.unsubscribe();
    });
  }
}
