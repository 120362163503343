import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { formatCurrency, formatPercent } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { AlgoMode, Currency, Strategy, TacosStrategyGroup } from "@front/m19-api-client";
import { AlgoTarget, Currencies, StrategyEx } from "@front/m19-models";
import { TranslocoService } from "@jsverse/transloco";
import { ICON_EDIT_O } from "@m19-board/utils/iconsLabels";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { SwitchTargetAlgoModalComponent } from "../switch-target-algo-modal/switch-target-algo-modal.component";

@Component({
  selector: "app-algo-target-renderer",
  templateUrl: "./algo-target-renderer.component.html",
  styleUrls: ["./algo-target-renderer.component.scss"],
})
export class AlgoTargetRendererComponent implements ICellRendererAngularComp, OnInit {
  readonly ICON_EDIT_O = ICON_EDIT_O;

  targetFormatted: string = "";
  secTargetFormatted: string | undefined;
  private _algoTarget!: AlgoTarget;
  @Input() set algoTarget(t: AlgoTarget | Strategy) {
    this._algoTarget = t as AlgoTarget;
    if (this.currency && this.locale) {
      this.initAlgoTarget(this._algoTarget);
    }
  }

  @Input({ required: true }) locale!: string;

  @Input({ required: true }) currency!: Currency;

  @Input({ required: true }) readonly!: boolean;

  @Input({ required: true }) addLabel!: boolean;

  @Input({ required: true }) organizationId!: number;

  minBid = 0;

  constructor(
    private modalService: BsModalService,
    private translocoService: TranslocoService,
  ) {}

  agInit(
    params: ICellRendererParams<AlgoTarget> & {
      locale: string;
      currency: Currency;
      readonly: boolean;
      minBid: number;
      averageDailyBudget: number;
      addLabel?: boolean;
      strategy?: StrategyEx;
      tacosId?: number;
      organizationId: number;
    },
  ): void {
    this.locale = params.locale;
    if (params.strategy) {
      this._algoTarget = {
        ...params.strategy,
        strategyId: params.tacosId ? params.tacosId : params.strategy.strategyId!,
        strategyName: params.strategy.name!,
        dailyBudget: params.averageDailyBudget || params.strategy.dailyBudget,
        algorithm: params.strategy.tacosTarget ? AlgoMode.TACOS_TARGET : params.strategy.algoMode,
        tacosTarget: params.strategy.tacosTarget,
      };
    } else {
      this._algoTarget = {
        ...params.data!,
        dailyBudget: params.averageDailyBudget || params.data!.dailyBudget,
        algorithm: params.data!.algorithm,
      };
    }
    this.readonly = params.readonly;
    this.currency = params.currency;
    this.addLabel = params.addLabel || false;
    this.minBid = params.minBid;
    this.organizationId = params.organizationId;
  }

  ngOnInit(): void {
    this.initAlgoTarget(this._algoTarget);
  }

  private initAlgoTarget(algoTarget: AlgoTarget) {
    switch (algoTarget.algorithm) {
      case AlgoMode.ACOS_TARGET:
        this.targetFormatted = formatPercent(this._algoTarget!.acosTarget!, this.locale, "1.0-0");
        this.secTargetFormatted = `${
          this._algoTarget.dailyBudget
            ? formatCurrency(
                this._algoTarget.dailyBudget,
                this.locale,
                Currencies[this.currency].currencySymbol,
                this.currency,
                "1.0-0",
              )
            : ""
        }`;
        break;
      case AlgoMode.TACOS_TARGET:
        this.targetFormatted = formatPercent(this._algoTarget!.tacosTarget!, this.locale, "1.0-0");
        break;
      case AlgoMode.PRODUCT_LAUNCH:
        this.targetFormatted = `${formatCurrency(
          this._algoTarget!.suggestedBid!,
          this.locale,
          Currencies[this.currency].currencySymbol,
          this.currency,
          "1.0-2",
        )}`;
        this.secTargetFormatted = `${formatCurrency(
          this._algoTarget!.dailyBudget!,
          this.locale,
          Currencies[this.currency].currencySymbol,
          this.currency,
          "1.0-0",
        )}`;
        break;
      case AlgoMode.MONTHLY_BUDGET_TARGET:
        this.targetFormatted = formatCurrency(
          this._algoTarget!.monthlyBudget!,
          this.locale,
          Currencies[this.currency].currencySymbol,
          this.currency,
          "1.0-0",
        );
        break;
    }
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  openTargetModal(): void {
    const options: ModalOptions = {
      initialState: {
        algoTarget: this._algoTarget,
        locale: this.locale,
        minBid: this.minBid,
        organizationId: this.organizationId,
      },
      class: "modal-primary modal-dialog-centered",
    };
    this.modalService.show(SwitchTargetAlgoModalComponent, options);
  }

  getTooltip(): string {
    switch (this._algoTarget.algorithm) {
      case AlgoMode.ACOS_TARGET:
        if (this._algoTarget.dailyBudget) {
          return this.translocoService.translate("algo-target-renderer.acos_target_with_daily_budget");
        }
        return this.translocoService.translate("algo-target-renderer.acos_target");
      case AlgoMode.PRODUCT_LAUNCH:
        return this.translocoService.translate("algo-target-renderer.suggested_bid");
      case AlgoMode.MONTHLY_BUDGET_TARGET:
        return this.translocoService.translate("metrics.MONTHLY_BUDGET_tooltip");
      case AlgoMode.TACOS_TARGET:
        return this.translocoService.translate("algo-target-renderer.tacos_target");
    }
    return "";
  }

  getLabel(): string {
    switch (this._algoTarget.algorithm) {
      case AlgoMode.ACOS_TARGET:
        return this.translocoService.translate("algo-target-renderer.acos_target");
      case AlgoMode.PRODUCT_LAUNCH:
        return this.translocoService.translate("algo-target-renderer.bid");
      case AlgoMode.MONTHLY_BUDGET_TARGET:
        return this.translocoService.translate("metrics.MONTHLY_BUDGET_tooltip");
      case AlgoMode.TACOS_TARGET:
        return this.translocoService.translate("algo-target-renderer.tacos_target");
    }
    return "";
  }

  getLabelDailyBudget(): string {
    if (this._algoTarget.algorithm === AlgoMode.ACOS_TARGET) {
      if (this._algoTarget.dailyBudget) {
        return this.translocoService.translate("algo-target-renderer.average_daily_budget");
      } else {
        return "";
      }
    } else {
      if (this._algoTarget.algorithm === AlgoMode.PRODUCT_LAUNCH) {
        return this.translocoService.translate("algo-target-renderer.budget");
      }
    }
    return "";
  }
}
