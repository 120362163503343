import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProductGroupEx, StrategyEx, StrategyTypeStr } from "@front/m19-models";
import { Option } from "@front/m19-ui";
import { CAMPAIGN_TYPE_COLOR } from "@m19-board/insights/overview/overview.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AccountType, StrategyType } from "@front/m19-api-client";
import { AccountSelectionService } from "@front/m19-services";

export function isProductGroupEx(
  strategyOrProductGroup: ProductGroupEx | StrategyEx,
): strategyOrProductGroup is ProductGroupEx {
  return (strategyOrProductGroup as StrategyEx).campaignType === undefined;
}

@UntilDestroy()
@Component({
  selector: "app-strategy-product-group-hybrid-dropdown",
  templateUrl: "./strategy-product-group-hybrid-dropdown.component.html",
})
export class StrategyProductGroupHybridDropdownComponent implements OnInit {
  @Input()
  placeholder = "All Strategies";

  @Input() set strategies(strategies: StrategyEx[]) {
    this._strategies = strategies;
    this.toStrategyPgOptions();
  }

  @Input() set productGroups(productGroups: ProductGroupEx[]) {
    this._productGroups = productGroups;
    this.toStrategyPgOptions();
  }

  @Output() selectedStrategyOrProductGroup = new EventEmitter<ProductGroupEx | StrategyEx>();

  _strategies: StrategyEx[] = [];
  _productGroups: ProductGroupEx[] = [];
  accountType: AccountType | undefined;

  ddOptions: Option<StrategyEx | ProductGroupEx | undefined>[] = [];
  selectedOption: Option<StrategyEx | ProductGroupEx | undefined> | undefined;
  readonly CAMPAIGN_TYPE_COLOR = CAMPAIGN_TYPE_COLOR;
  readonly StrategyType = StrategyType;
  readonly StrategyTypeStr = StrategyTypeStr;

  constructor(private accountSelectionService: AccountSelectionService) {}

  ngOnInit(): void {
    this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((am) => {
      // reset to 'All products' when switching marketplace
      this.selectedStrategyOrProductGroup.emit(undefined);
      this.accountType = am.accountType;
    });
  }

  private toStrategyOption(strategyPg: StrategyEx): Option<StrategyEx | ProductGroupEx> {
    return {
      label: strategyPg.name!,
      value: strategyPg,
      id: "S" + strategyPg.strategyId,
    };
  }

  private toPGOption(pg: ProductGroupEx): Option<StrategyEx | ProductGroupEx> {
    return {
      label: pg.name,
      value: pg,
      id: "PG" + pg.productGroupId,
    };
  }

  private toStrategyPgOptions() {
    const allProductsOption: Option<ProductGroupEx | StrategyEx | undefined> = {
      label: this.placeholder,
      value: undefined,
      id: "",
    };

    const ddOptions: Option<StrategyEx | ProductGroupEx>[] = [];
    if (this._productGroups) {
      ddOptions.push(...this._productGroups.map((p) => this.toPGOption(p)));
    }
    if (this._strategies) {
      ddOptions.push(...this._strategies.map((s) => this.toStrategyOption(s)));
    }

    this.ddOptions = [allProductsOption, ...ddOptions];
    this.selectedOption = this.ddOptions[0];
  }

  isStrategyType(strategyOrPg: StrategyEx | ProductGroupEx): boolean {
    return strategyOrPg instanceof StrategyEx;
  }

  selectOption(option: Option<StrategyEx | ProductGroupEx | undefined>) {
    this.selectedStrategyOrProductGroup.emit(option.value);
    this.selectedOption = option;
  }
}
