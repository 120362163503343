import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  input,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
} from "@angular/core";
import { mediaQuery } from "@front/m19-services";
import { IButtonComponent, ISelectComponent, Option } from "@front/m19-ui";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Marketplace } from "@front/m19-api-client";
import { Marketplaces } from "@front/m19-models";
import { KeyValuePipe } from "@angular/common";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@UntilDestroy()
@Component({
  selector: "app-marketplace-selector",
  templateUrl: "./marketplace-selector.component.html",
  imports: [ISelectComponent, KeyValuePipe, IButtonComponent],
  standalone: true,
})
export class MarketplaceSelectorComponent implements OnInit, OnChanges {
  private readonly destroyRef = inject(DestroyRef);

  @Input({ required: true })
  marketplaces!: Marketplace[];

  @Input({ required: true })
  marketplaceSelected: Marketplace | undefined;

  wrapped = input<boolean>(false);

  @Output()
  marketplaceSelectedChange = new EventEmitter<Marketplace>();

  @Output()
  marketplacesSelected = new EventEmitter<Marketplace>();

  dropdownOptions: Option<Marketplace>[] = [];
  selected: Option<Marketplace> | undefined;

  isSmallScreen = signal(false);
  selection: Map<Marketplace, boolean> = new Map();

  readonly Marketplaces = Marketplaces;

  ngOnInit(): void {
    this.buildSelection();
  }

  ngOnChanges(): void {
    this.buildSelection();
  }

  private buildSelection() {
    const width = 1200 + this.selection.size * 50;

    if (!this.wrapped()) {
      mediaQuery(`(max-width: ${width}px)`)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((matches) => {
          this.isSmallScreen.set(matches && this.selection.size > 4);
        });
    }

    this.dropdownOptions = this.marketplaces.map((m: Marketplace) => ({
      label: `${Marketplaces[m].flag} ${m} - ${Marketplaces[m].name}`,
      value: m,
    }));
    this.selected = this.toOption(this.marketplaceSelected ?? this.marketplaces[0]);

    this.marketplaces.forEach((m) => this.selection.set(m, false));
    if (this.marketplaceSelected) {
      this.selection.set(this.marketplaceSelected, true);
    } else {
      this.selection.set(this.marketplaces[0], true);
    }
  }

  toggleMarketplaceOption(option: Option<Marketplace>): void {
    this.selected = option;
    this.toggleMarketplace(option.value);
  }

  toggleMarketplace(marketplace: Marketplace): void {
    this.selected = this.toOption(marketplace);
    for (const k of this.selection.keys()) {
      this.selection.set(k, marketplace == k);
      if (marketplace == k) localStorage.setItem("currentMarketplace", marketplace);
    }
    this.marketplaceSelectedChange.emit(marketplace);
  }

  toOption(marketplace: Marketplace): { label: string; value: Marketplace } {
    return { label: marketplace, value: marketplace };
  }
}
