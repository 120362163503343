import { Component, Input } from "@angular/core";
import { faPauseCircle } from "@fortawesome/free-regular-svg-icons";
import { faExclamationTriangle, faPlayCircle } from "@fortawesome/free-solid-svg-icons";

import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { AjaxError } from "rxjs/ajax";
import { SelfServiceTransferModalComponent } from "./self-service-transfer/self-service-transfer.component";
import {
  AccountMarketplace,
  AccountState,
  AdSpendFeeType,
  BillingPlan,
  BillingPlanFrequencyEnum,
  Currency,
  DailyAdSpendFee,
  KeywordTrackerQuota,
  Organization,
  OrganizationFirstPaymentValidatedEnum,
  Plan,
} from "@front/m19-api-client";
import { AccountGroup, OrganizationAccountGroups } from "@front/m19-models";
import { Utils } from "@front/m19-utils";
import { BillingService, CurrencyService, OrganizationService } from "@front/m19-services";

@Component({
  selector: "app-subscription-card",
  templateUrl: "./subscription-card.component.html",
  styleUrls: ["./subscription-card.component.scss"],
})
export class SubscriptionComponentCardComponent {
  SELF_SERVICE = Plan.SELF_SERVICE;

  planEligibleToPoNumber = new Set([Plan.AGENCY, Plan.ENTERPRISE, Plan.GROWTH, Plan.PROFESSIONAL, Plan.WHITELABEL]);

  _organization?: Organization;
  _billingPlan?: BillingPlan;
  _accountGroups?: AccountGroup[];
  _parentOrganizationWhenOwner: Organization | null | undefined;

  loading = false;

  showAccounts: boolean;
  plan?: Plan;
  adSpendFee: number | undefined = undefined;
  adSpendFeeType: AdSpendFeeType | undefined = undefined;
  flatFee: number | undefined = undefined;
  paymentStatus?: OrganizationFirstPaymentValidatedEnum;
  readonly faPlayCircle = faPlayCircle;
  readonly faWarning = faExclamationTriangle;
  readonly faPauseCircle = faPauseCircle;
  readonly PaymentStatus = OrganizationFirstPaymentValidatedEnum;
  readonly ManagedAdSpendFee = AdSpendFeeType.MANAGED;

  title?: string;
  isManualBillingPlan?: boolean;
  nbAccountMarketplaces?: number;
  nbAccounts?: number;
  accountMarketplaces?: Array<AccountMarketplace>;
  totalAdSpendFees: Map<string, DailyAdSpendFee> = new Map();

  extraAccountMarketplaces = new Array<string>();

  currency?: Currency;
  symbole?: string;

  @Input() locale!: string;

  @Input()
  set organization(o: OrganizationAccountGroups) {
    this._accountGroups = o?.accountGroups;
    this._organization = o?.organization;
    this._parentOrganizationWhenOwner = o?.parentOrganizationWhenOnwer;
    this._billingPlan = o?.organization?.billingPlan;
    this.currency = this._billingPlan?.currency;
    this.symbole = this.currencyService.getCurrencySymbol(this.currency!);
    this.plan = this._billingPlan?.plan;
    this.paymentStatus = o.organization?.firstPaymentValidated;
    this.flatFee = this._billingPlan?.legacyFee?.amount ?? this._billingPlan?.flatFee?.amount;
    this.adSpendFee = this._billingPlan?.adSpendFee?.amount;
    this.adSpendFeeType = this._billingPlan?.adSpendFee?.type;

    this.title = o.planTitle;
    this.isManualBillingPlan = o.isManualBillingPlan;
    this.nbAccountMarketplaces = this.initNbAccountMarketplaces();
    this.nbAccounts = this.initNbAccounts();
    this.accountMarketplaces = this.initAccountMarketplaces();
    this.detectExtraAccountMarketplaces();
  }

  @Input()
  set dailyAdSpendFees(x: DailyAdSpendFee[]) {
    // discard ad spend fee with invoiceId + too old ad spend fee
    const fees =
      x?.filter((x) => !x.stripeInvoiceId && Utils.toMoment(x.date!).isAfter(moment.utc().subtract(1, "month"))) ?? [];

    this.totalAdSpendFees = fees.reduce(function (r, a) {
      if (!a || !a.adSpendFee) return r;
      const key = a.accountId + "_" + a.marketplace;
      if (r.has(key)) {
        r.get(key)!.adSpendFee = r.get(key)!.adSpendFee! + a.adSpendFee;
      } else {
        r.set(key, a);
      }
      return r;
    }, new Map<string, DailyAdSpendFee>());
    this.detectExtraAccountMarketplaces();
  }

  @Input() dailyHourlyKeywords: Map<string, KeywordTrackerQuota> | undefined;

  constructor(
    public currencyService: CurrencyService,
    private billingService: BillingService,
    private organizationService: OrganizationService,
    private toasterService: ToastrService,
    private modalService: BsModalService,
  ) {
    this.showAccounts = false;
  }

  initNbAccountMarketplaces(): number {
    return this._organization && this._accountGroups
      ? this._accountGroups.flatMap((x) => x.resources).filter((x) => x.state == AccountState.BIDDER_ON).length
      : 0;
  }

  initNbAccounts(): number {
    return new Set(
      this._accountGroups!.flatMap((x) => x.resources)
        .filter((x) => x.state == AccountState.BIDDER_ON)
        .map((am) => am.accountId),
    ).size;
  }

  initAccountMarketplaces(): Array<AccountMarketplace> {
    return this._organization && this._accountGroups
      ? this._accountGroups.flatMap((x) => x.resources).filter((x) => x.state == AccountState.BIDDER_ON)
      : new Array<AccountMarketplace>(0);
  }

  hasDiscount(): boolean {
    return !!this._organization?.couponStripeId;
  }

  getDiscountMessage(): string | undefined {
    const price = this._organization?.couponStripeId;
    if (price) return "coupon code used";
    return undefined;
  }

  printFrequency(frequency: BillingPlanFrequencyEnum | undefined): string {
    switch (frequency) {
      case BillingPlanFrequencyEnum.MONTHLY:
        return "/ Month";
      case BillingPlanFrequencyEnum.YEARLY:
        return "/ Year";
      default:
        return "";
    }
  }

  canDisplayPoNumber() {
    return this.planEligibleToPoNumber.has(this.plan!);
  }

  changePoNumber(poNumber: string) {
    this.organizationService.updatePoNumber(
      this._organization!.organizationId!,
      poNumber,
      (success) => {
        this.toasterService.success("PO Number Successfully updated");
      },
      (error: AjaxError) => {
        this.toasterService.error(error.response ? error.response.message : "Unknown error", "PO Number update failed");
      },
    );
  }

  deletePoNumber() {
    this.organizationService.updatePoNumber(
      this._organization!.organizationId!,
      "null",
      (success) => {
        this.toasterService.success("PO Number Successfully deleted");
      },
      (error: AjaxError) => {
        this.toasterService.error(
          error.response ? error.response.message : "Unknown error",
          "PO Number deletion failed",
        );
      },
    );
  }

  detectExtraAccountMarketplaces() {
    // detect self service accounts + account recently stopped (after last invoice)
    // accountMarketplaces only contain accounts in Bidder On of the main organizationId
    this.extraAccountMarketplaces = [];
    if (!this.totalAdSpendFees || !this.accountMarketplaces) return;
    const bidderOnAccountsOfMainOrg = new Set(this.accountMarketplaces.map((a) => a.accountId + "_" + a.marketplace));
    this.extraAccountMarketplaces = Array.from(this.totalAdSpendFees.keys()).filter(
      (a) => !bidderOnAccountsOfMainOrg.has(a),
    );
  }

  upgradeToSelfServiceManagement() {
    this.updateSubscription(Plan.SELF_SERVICE);
  }

  stopPlan() {
    this.loading = true;
    this.billingService.stopSelfServiceSubscription(
      this._organization!.organizationId!,
      (success) => {
        this.toasterService.success("Plan Successfully stopped");
        this.loading = false;
      },
      (error: AjaxError) => {
        this.toasterService.error(
          error.response ? error.response.message : "Unknown error",
          "Subscription update failure",
        );
        this.loading = false;
      },
    );
  }

  downgradeToStarter() {
    this.updateSubscription(Plan.STARTER);
  }

  migrateToParentOrganization() {
    const modalOptions: ModalOptions = {
      initialState: {
        childOrganization: this._organization,
        accountMarketplaces: this.accountMarketplaces,
      },
      class: "modal-lg",
    };
    const modalRef = this.modalService.show(SelfServiceTransferModalComponent, modalOptions);
  }

  updateSubscription(plan: Plan) {
    this.loading = true;
    this.billingService.updateSubscription(
      this._organization!.organizationId!,
      plan,
      (success) => {
        this.toasterService.success("Successfully migrated to:" + plan);
        this.loading = false;
      },
      (error: AjaxError) => {
        this.toasterService.error(
          error.response ? error.response.message : "Unknown error",
          "Subscription update failure",
        );
        this.loading = false;
      },
    );
  }

  getTotalDailyKeywords() {
    let sum = 0;
    this.dailyHourlyKeywords?.forEach((x) => {
      if (x.frequency === "daily") sum += x.keywordNumber!;
    });
    return sum;
  }

  getTotalHourlyKeywords() {
    let sum = 0;
    this.dailyHourlyKeywords?.forEach((x) => {
      if (x.frequency === "hourly") {
        sum += x.keywordNumber!;
      }
    });
    return sum;
  }

  canBeUpgradedToSelfServiceManagement(): boolean {
    return (
      (this._billingPlan?.plan == Plan.STARTER || this._billingPlan?.plan == Plan.TEST) &&
      !!this._parentOrganizationWhenOwner
    );
  }

  canDowngradeToStarter(): boolean {
    return this._billingPlan?.plan == Plan.SELF_SERVICE && !!this._parentOrganizationWhenOwner;
  }

  canMigrateToParentOrganization(): boolean {
    return !!(
      this._billingPlan?.plan === Plan.SELF_SERVICE &&
      !!this._parentOrganizationWhenOwner &&
      this.accountMarketplaces &&
      this.accountMarketplaces.length > 0
    );
  }

  downloadFile(): void {
    const date = moment(moment.now());

    const options = {
      quoteStrings: "",
      fieldSeparator: ";",
      showLabels: true,
      useKeysAsHeaders: true,
      filename: "spending_details_" + date.format("L"),
    };

    const csvExporter = new ExportToCsv(options);

    const data = Array.from(this.totalAdSpendFees.values()).map((acc) => ({
      Account: acc.accountName && acc.accountName.length > 0 ? acc.accountName : acc.accountId,
      MarketPlace: acc.marketplace,
      TotalAdSpend: acc.adSpendFee,
    }));

    csvExporter.generateCsv(data);
  }
}
