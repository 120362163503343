import { Component, Input } from "@angular/core";
import { Params, QueryParamsHandling } from "@angular/router";
import { UntilDestroy } from "@ngneat/until-destroy";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";

type LinkComponentAgGridParams = {
  routerLink: string;
  queryParams?: Params;
  queryParamsHandling?: QueryParamsHandling;
  target: string;
  content: string;
  cssClass?: string; // e.g. "text-color" to set the link to quaternary color
  href?: string;
};

@UntilDestroy()
@Component({
  selector: "app-link",
  template: `<a
    [routerLink]="routerLink"
    [queryParams]="queryParams"
    [target]="target"
    [queryParamsHandling]="queryParamsHandling"
    [ngClass]="cssClass"
    [href]="href"
    class="link"
  >
    {{ content }}
  </a>`,
})
export class LinkComponent implements ICellRendererAngularComp {
  @Input() routerLink!: string;
  @Input() target!: string;
  @Input() queryParams?: Params;
  @Input() content!: string;
  @Input() queryParamsHandling?: QueryParamsHandling;
  @Input() cssClass?: string;
  @Input() href?: string;

  agInit(params: ICellRendererParams & LinkComponentAgGridParams): void {
    this.routerLink = params.routerLink;
    this.target = params.target;
    this.queryParams = params.queryParams;
    this.queryParamsHandling = params.queryParamsHandling;
    this.content = params.content;
    this.cssClass = params.cssClass;
    this.href = params.href;
  }

  refresh(): boolean {
    return false;
  }
}
