import { Injectable } from '@angular/core';
import { AccountMarketplace, AccountMarketplaceTuple, Marketplace, StatsApi } from '@front/m19-api-client';
import {
  AccountMarketplaceLazyReadOnlyCache,
  AggregatedLazyReadOnlyCache,
  getVendorAsinSalesStats,
  TimeSeriesCache,
  Utils,
} from '@front/m19-utils';
import { map, Observable, of } from 'rxjs';
import { AdStatsEx } from '../models';
import { StatsApiClientService } from './stats-api-client.service';

@Injectable({
  providedIn: 'root',
})
export class StatsService {
  private readonly dailyPlacementStatsCache: AccountMarketplaceLazyReadOnlyCache<TimeSeriesCache<AdStatsEx[]>>;
  private readonly sellerDailyAsinAllSalesCache: AccountMarketplaceLazyReadOnlyCache<TimeSeriesCache<AdStatsEx[]>>;
  private readonly adStatsPerAccountMarketplaceCache: AggregatedLazyReadOnlyCache<TimeSeriesCache<AdStatsEx[]>>;
  private readonly salesPerSellerAccountMarketplaceCache: AggregatedLazyReadOnlyCache<TimeSeriesCache<AdStatsEx[]>>;
  private readonly salesPerVendorAccountMarketplaceCache: AggregatedLazyReadOnlyCache<TimeSeriesCache<AdStatsEx[]>>;
  private readonly dailyBrandAsinStatsCache: AccountMarketplaceLazyReadOnlyCache<TimeSeriesCache<AdStatsEx[]>>;

  public constructor(private statsApi: StatsApi) {
    this.dailyPlacementStatsCache = new AccountMarketplaceLazyReadOnlyCache(
      (accountId, markeplace) =>
        new TimeSeriesCache<AdStatsEx[]>((start, end) =>
          this.requestDailyPlacementStats(accountId, markeplace, start, end),
        ),
    );
    this.sellerDailyAsinAllSalesCache = new AccountMarketplaceLazyReadOnlyCache(
      (accountId, markeplace) =>
        new TimeSeriesCache<AdStatsEx[]>((start, end) =>
          this.requestSellerDailyAsinAllSales(accountId, markeplace, start, end),
        ),
    );
    this.adStatsPerAccountMarketplaceCache = new AggregatedLazyReadOnlyCache(
      (accountMarketplaces) =>
        new TimeSeriesCache<AdStatsEx[]>(
          (start, end) =>
            this.requestAdvertisingStatsPerAccountMarketplace(accountMarketplaces as AccountMarketplace[], start, end),
          {
            maxChunkSize: 0, // no chunking
          },
        ),
    );
    this.salesPerSellerAccountMarketplaceCache = new AggregatedLazyReadOnlyCache(
      (accountMarketplaces) =>
        new TimeSeriesCache<AdStatsEx[]>(
          (start, end) =>
            this.requestSalesPerSellerAccountMarketplace(accountMarketplaces as AccountMarketplace[], start, end),
          {
            maxChunkSize: 0, // no chunking
          },
        ),
    );
    this.salesPerVendorAccountMarketplaceCache = new AggregatedLazyReadOnlyCache(
      (accountMarketplaces) =>
        new TimeSeriesCache<AdStatsEx[]>(
          (start, end) => this.requestSalesPerVendorAccountMarketplaces(accountMarketplaces, start, end),
          {
            maxChunkSize: 0, // no chunking
          },
        ),
    );
    this.dailyBrandAsinStatsCache = new AccountMarketplaceLazyReadOnlyCache(
      (accountId, markeplace) =>
        new TimeSeriesCache<AdStatsEx[]>((start, end) =>
          this.requestDailyBrandAsinStats(accountId, markeplace, start, end),
        ),
    );
  }

  public getDailyPlacementStats(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<AdStatsEx[]> {
    return this.dailyPlacementStatsCache
      .getValue(accountId, marketplace)
      .get(minDate, maxDate)
      .pipe(
        map((stats) => {
          return stats.flat();
        }),
      );
  }

  public getSellerDailyAsinAllSales(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<AdStatsEx[]> {
    return this.sellerDailyAsinAllSalesCache
      .getValue(accountId, marketplace)
      .get(minDate, maxDate)
      .pipe(
        map((stats) => {
          return stats.flat();
        }),
      );
  }

  public getPreviousPeriodAllSales(
    accountId: string,
    marketplace: Marketplace,
    dateRange: string[],
    periodComparison: string[],
  ): Observable<AdStatsEx[]> {
    const dateIntervalInDays = Utils.getDateIntervalInDays(dateRange);
    if (dateIntervalInDays <= StatsApiClientService.maxComp) {
      const dateGap = Utils.getDateIntervalInDays([periodComparison[0], dateRange[0]]);
      return this.getSellerDailyAsinAllSales(accountId, marketplace, periodComparison[0], periodComparison[1]);
    }
    return of([]);
  }

  getAdStatsPerAccountMarketplace(
    accountMarketplaces: AccountMarketplace[],
    minDate: string,
    maxDate: string,
  ): Observable<AdStatsEx[]> {
    return this.adStatsPerAccountMarketplaceCache
      .get(accountMarketplaces)
      .get(minDate, maxDate)
      .pipe(
        map((stats) => {
          return stats.flat();
        }),
      );
  }

  getSalesPerSellerAccountMarketplace(
    accountMarketplaces: AccountMarketplace[],
    minDate: string,
    maxDate: string,
  ): Observable<AdStatsEx[]> {
    return this.salesPerSellerAccountMarketplaceCache
      .get(accountMarketplaces)
      .get(minDate, maxDate)
      .pipe(
        map((stats) => {
          return stats.flat();
        }),
      );
  }

  getSalesPerVendorAccountMarketplace(
    accountMarketplaces: AccountMarketplace[],
    minDate: string,
    maxDate: string,
  ): Observable<AdStatsEx[]> {
    return this.salesPerVendorAccountMarketplaceCache
      .get(accountMarketplaces)
      .get(minDate, maxDate)
      .pipe(
        map((stats) => {
          return stats.flat();
        }),
      );
  }

  getDailyBrandAsinStats(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<AdStatsEx[]> {
    return this.dailyBrandAsinStatsCache
      .getValue(accountId, marketplace)
      .get(minDate, maxDate)
      .pipe(
        map((stats) => {
          return stats.flat();
        }),
      );
  }

  /**
   * Data loaders
   */

  private requestDailyPlacementStats(
    accountId: string,
    markeplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<Map<string, AdStatsEx[]>> {
    return this.statsApi
      .getDailyPlacementStats({
        accountId,
        marketplace: markeplace,
        minDate,
        maxDate,
      })
      .pipe(map((stats) => indexByDate(stats)));
  }

  private requestSellerDailyAsinAllSales(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<Map<string, AdStatsEx[]>> {
    return this.statsApi
      .getDailySellerAsinAllSales({ accountId, marketplace, minDate, maxDate })
      .pipe(map((stats) => indexByDate(stats)));
  }

  private requestAdvertisingStatsPerAccountMarketplace(
    accountMarketplaces: AccountMarketplaceTuple[],
    minDate: string,
    maxDate: string,
  ): Observable<Map<string, AdStatsEx[]>> {
    return this.statsApi
      .getAggregatedAdvertisingStats({
        accountMarketplaceTuple: accountMarketplaces,
        minDate,
        maxDate,
      })
      .pipe(map((stats) => indexByDate(stats)));
  }

  private requestSalesPerSellerAccountMarketplace(
    accountMarketplaces: AccountMarketplaceTuple[],
    minDate: string,
    maxDate: string,
  ): Observable<Map<string, AdStatsEx[]>> {
    return this.statsApi
      .getAggregatedSellerAllSales({
        accountMarketplaceTuple: accountMarketplaces,
        minDate,
        maxDate,
      })
      .pipe(map((stats) => indexByDate(stats)));
  }

  private requestSalesPerVendorAccountMarketplaces(
    accountMarketplaces: (AccountMarketplaceTuple & { useSourcingMetrics?: boolean })[],
    minDate: string,
    maxDate: string,
  ): Observable<Map<string, AdStatsEx[]>> {
    return this.statsApi
      .getAggregatedVendorSales({
        accountMarketplaceTuple: accountMarketplaces,
        minDate,
        maxDate,
      })
      .pipe(
        map((stats) =>
          stats.map((s) => {
            const useSourcing = accountMarketplaces.find(
              (a) => a.accountId === s.accountId && a.marketplace === s.marketplace,
            )?.useSourcingMetrics;
            return getVendorAsinSalesStats(s, useSourcing!);
          }),
        ),
        map((stats) => indexByDate(stats)),
      );
  }

  private requestDailyBrandAsinStats(
    accountId: string,
    marketplace: Marketplace,
    minDate: string,
    maxDate: string,
  ): Observable<Map<string, AdStatsEx[]>> {
    return this.statsApi
      .getDailyBrandAsinStats({
        accountId,
        marketplace,
        minDate,
        maxDate,
      })
      .pipe(map((stats) => indexByDate(stats)));
  }
}

function indexByDate<V extends { date?: string }>(stats: V[]): Map<string, V[]> {
  const result = new Map<string, V[]>();
  for (const stat of stats) {
    const date = stat.date!;
    if (!result.has(date)) {
      result.set(date, []);
    }
    result.get(date)!.push(stat);
  }
  return result;
}
