import { Clipboard } from "@angular/cdk/clipboard";
import { CdkConnectedOverlay, CdkOverlayOrigin } from "@angular/cdk/overlay";
import { NgClass, NgTemplateOutlet } from "@angular/common";
import {
  Component,
  computed,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
} from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { MatTooltip } from "@angular/material/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faPauseCircle, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { AsinService, AuthService } from "@front/m19-services";
import { IBadgeComponent, IButtonComponent, ICardComponent, ICheckboxComponent } from "@front/m19-ui";
import { TranslocoService } from "@jsverse/transloco";
import { ViewTypeEnum } from "@m19-board/strategies/strategy-list/strategy-list.component";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { ToastrService } from "ngx-toastr";
import { map, take } from "rxjs";
import { ProductThumbnailComponent } from "../../product-view/product-thumbnail.component";
import { SpinnerComponent } from "../../spinner/spinner.component";
import { Marketplace, StrategyStateEnum } from "@front/m19-api-client";
import { ProductEx, StrategyEx, StrategyTypeStr } from "@front/m19-models";

@Component({
  selector: "app-asin-item",
  standalone: true,
  templateUrl: "./asin-item.component.html",
  styleUrl: "./asin-item.component.scss",
  imports: [
    ProductThumbnailComponent,
    MatTooltip,
    SpinnerComponent,
    FontAwesomeModule,
    NgClass,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    ICardComponent,
    IButtonComponent,
    NgTemplateOutlet,
    IBadgeComponent,
    ICheckboxComponent,
    TranslocoRootModule,
  ],
})
export class AsinItemComponent implements OnInit {
  constructor(private translocoService: TranslocoService) {}

  private asinService = inject(AsinService);
  private clipboard = inject(Clipboard);
  private toasterService = inject(ToastrService);
  private authService = inject(AuthService);

  readonly ViewTypeEnum = ViewTypeEnum;

  @Input() set asin(asin: string) {
    this._asin = asin;
    if (this._marketplace) {
      this.setProduct();
    }
  }

  _asin!: string;

  @Input() set selected(v: boolean) {
    this._selected = v;
    this.cardStyle = this.computeStyle();
  }

  _selected!: boolean;

  @Input() set marketplace(m: Marketplace) {
    this._marketplace = m;
    if (this._asin) {
      this.setProduct();
    }
  }

  _marketplace!: Marketplace;

  @Input() isReadOnly = false;

  @Input() set isSelectable(v: boolean) {
    this._isSelectable = v;
    this.cardStyle = this.computeStyle();
  }

  _isSelectable = false;

  @Input() set disabled(v: boolean) {
    this._disabled = v;
    this.cardStyle = this.computeStyle();
  }

  _disabled = false;

  @Input() isLast = false;
  @Input() isIneligible = false;
  @Input() ineligibilityReason?: string;
  @Input() isPaused = false;
  @Input() actionsButtons = false;
  @Input() viewMode: ViewTypeEnum = ViewTypeEnum.LIST;

  @Input() set activeStrategies(s: StrategyEx[] | undefined) {
    this._activeStrategies.set(s?.filter((str) => str.state === StrategyStateEnum.ENABLED));
  }

  @Output() asinSelected = new EventEmitter<string>();

  product = signal<ProductEx | undefined>(undefined);
  _activeStrategies = signal<StrategyEx[] | undefined>(undefined);
  activeStrategiesMessage = computed(() => {
    if (this._activeStrategies()?.length === 0) return undefined;
    return this.translocoService.translate("asin-item.active_strategy", { count: this._activeStrategies()?.length });
  });

  cardStyle!: string;
  activeStrategiesOrigin?: CdkOverlayOrigin;
  overlayOpened = false;
  @ViewChild("activeStrategiesPopup") activeStrategiesPopup!: ElementRef;

  $product360Url = toSignal(
    this.authService.loggedUser$.pipe(
      map((user) => {
        return (user?.uiVersion ?? 0) > 0 ? "/product-center/product360" : "/product360";
      }),
    ),
    { initialValue: "/product360" },
  );

  ngOnInit(): void {
    this.cardStyle = this.computeStyle();
  }

  private setProduct() {
    this.asinService
      .getProductWithMarketplace(this._asin, this._marketplace)
      .pipe(take(1))
      .subscribe((p: ProductEx) => {
        this.product.set(p);
      });
  }

  computeStyle() {
    let style = "relative group ";
    if (!this._disabled) style = style.concat(" ", "cursor-pointer duration-75 hover:bg-gray-100");
    else return style.concat(" ", "cursor-not-allowed select-none opacity-75");
    if (this._isSelectable && this._selected) {
      style = style.concat(" ", "ring-2 ring-main-300 !bg-main-50");
    }

    return style;
  }

  toggleAsin() {
    if (!this._isSelectable || this.isReadOnly || this._disabled) return;
    this._selected = !this._selected;
    this.asinSelected.emit(this._asin);
  }

  copyToClipboard(str: string | undefined) {
    if (!str) return;
    this.clipboard.copy(str);
    this.toasterService.success(this.translocoService.translate("common.copied_to_clipboard"));
  }

  displayActiveStrategies(trigger: CdkOverlayOrigin) {
    this.activeStrategiesOrigin = trigger;
    this.overlayOpened = true;
  }

  hideActiveStrategies() {
    this.activeStrategiesOrigin = undefined;
    this.overlayOpened = false;
  }

  protected readonly StrategyTypeStr = StrategyTypeStr;
  protected readonly State = StrategyStateEnum;
  protected readonly faPlayCircle = faPlayCircle;
  protected readonly faPauseCircle = faPauseCircle;
}
