import { Component, EventEmitter, Input, Output } from "@angular/core";
import { faEye, faEyeSlash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-search-term-card",
  templateUrl: "./search-term-card.component.html",
  styleUrls: ["./search-term-card.component.scss"],
})
export class SearchTermCardComponent {
  submenu = false;
  submenuHover = false;
  _show = true;
  readonly faTrash = faTrashAlt;
  readonly faEyeSlash = faEyeSlash;
  readonly faEye = faEye;

  @Input() searchTerm!: string;
  @Input() searchVolume: number | null = null;
  @Input() color!: string;
  @Input() withShowHideToggle = true;

  @Output() delete = new EventEmitter<void>();
  @Output() showed = new EventEmitter<boolean>();

  toggleShowSearchTerm(): void {
    this._show = !this._show;
    this.showed.emit(this._show);
  }
}
