<nav
  (mouseenter)="isExpended = true"
  (mouseleave)="isExpended = false"
  data-testid="sidebar-nav"
  class="flex flex-col justify-between"
>
  <ul>
    @for (navItem of navItems | betaTesterFilterPipe: betaTest; track navItem) {
      <li attr.data-testid="sidebar-nav-{{ navItem.name.replaceAll(' ', '_') }}">
        @if (!navItem.children) {
          <a
            [routerLink]="[navItem.url]"
            queryParamsHandling="merge"
            [class.menu-active]="router.url.startsWith(navItem.url)"
            (click)="selectedMenu = undefined"
          >
            <ng-container [ngTemplateOutlet]="getIconRefByUrl(navItem.name)!"></ng-container>
            <span class="item-name ml-3">{{ navItem.name }}</span></a
          >
        } @else {
          <a class="relative" (click)="toggleSubMenu(navItem.url)" [class.menu-active]="selectedMenu === navItem.url">
            @if (navItem.icon) {
              <div class="mr-3">
                <ng-container [ngTemplateOutlet]="getIconRefByUrl(navItem.name)!"></ng-container>
              </div>
            }
            <span class="item-name">{{ navItem.name }}</span>
            <img
              [ngClass]="'caret-' + (navItem.url === displayedMenu && isExpended ? 'opened' : 'closed')"
              class="caret-down"
              src="assets/img/sov-icons/dropdown-arrow.svg"
              alt=""
            />
          </a>
          <ul class="submenu p-0" [class.submenu-openened]="navItem.url === displayedMenu && isExpended">
            @for (subItem of navItem.children | betaTesterFilterPipe: betaTest; track subItem) {
              <li class="item-name flex-row">
                <a
                  [routerLink]="subItem.url"
                  [class.menu-active-sub]="router.url.startsWith(subItem.url)"
                  (click)="selectedMenu = navItem.url"
                  queryParamsHandling="merge"
                >
                  <ng-container [ngTemplateOutlet]="iconPlaceholder"></ng-container>
                  <span>{{ subItem.name }}</span>
                  @if (subItem.badge) {
                    <IBadge [label]="subItem.badge.text" variant="subtle" size="xs" class="ml-2" />
                  }
                </a>
              </li>
            }
          </ul>
        }
      </li>
    }
  </ul>
  <IButton
    icon="icon-[mdi--sail-boat]"
    [label]="isExpended ? 'Try New Interface' : ''"
    class="mb-44 ml-3 w-full"
    (onClick)="useNewUi()"
    [square]="true"
    color="white"
  ></IButton>
</nav>
<ng-template #iconPlaceholder>
  <div class="icon-placeholder"></div>
</ng-template>
<ng-template #iconDashboard>
  <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 10L12 16" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M16 8L16 16" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8 11L8 16" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z"
      stroke-width="2"
    />
  </svg>
</ng-template>
<ng-template #iconStrategies>
  <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Environment / Bulb">
      <path
        id="Vector"
        d="M9 21H15M12 3C8.68629 3 6 5.68629 6 9C6 10.2145 6.36084 11.3447 6.98117 12.2893C7.93507 13.7418 8.41161 14.4676 8.47352 14.5761C9.02428 15.541 8.92287 15.2007 8.99219 16.3096C8.99998 16.4342 9 16.6229 9 17C9 17.5523 9.44772 18 10 18L14 18C14.5523 18 15 17.5523 15 17C15 16.6229 15 16.4342 15.0078 16.3096C15.0771 15.2007 14.9751 15.541 15.5259 14.5761C15.5878 14.4676 16.0651 13.7418 17.019 12.2893C17.6394 11.3447 18.0002 10.2145 18.0002 9C18.0002 5.68629 15.3137 3 12 3Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</ng-template>
<ng-template #iconProducts>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M11.1716 3H5C3.89543 3 3 3.89543 3 5V11.1716C3 11.702 3.21071 12.2107 3.58579 12.5858L10.8787 19.8787C12.0503 21.0503 13.9497 21.0503 15.1213 19.8787L19.8787 15.1213C21.0503 13.9497 21.0503 12.0503 19.8787 10.8787L12.5858 3.58579C12.2107 3.21071 11.702 3 11.1716 3Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <circle cx="7.5" cy="7.5" r="1.5" stroke=""></circle>
    </g>
  </svg>
</ng-template>
<ng-template #iconInsights>
  <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 4V10L5.20285 16.8531C4.27496 18.1786 5.22327 20 6.84131 20H17.1587C18.7767 20 19.725 18.1786 18.7972 16.8531L14 10V4M10 4H14M10 4H8M14 4H16"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-template>
<ng-template #iconTools>
  <div class="tool-icon">
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path
          d="M14.25 10.71 11.57 8l2.26-2.26a2.49 2.49 0 0 0 0-3.53 2.5 2.5 0 0 0-3.53 0l-.89.88L8 4.5 5.28 1.75a1.26 1.26 0 0 0-1.76 0L1.75 3.52a1.25 1.25 0 0 0 0 1.77L4.5 8l-.22.22-.89.88-1.75 3.66a1.25 1.25 0 0 0 1.67 1.67l3.62-1.75.49-.49.39-.39.19-.23 2.68 2.68a1.26 1.26 0 0 0 1.76 0l1.77-1.77a1.25 1.25 0 0 0 .04-1.77zm-2.19-8a1.27 1.27 0 0 1 .89.36 1.25 1.25 0 0 1 0 1.77l-1.77-1.72a1.27 1.27 0 0 1 .88-.36zM2.63 4.4 4.4 2.64l.82.82-.87.88.88.88.88-.88 1 1-1.73 1.81zm.13 8.91 1.57-3.23L6 11.74zm4.17-2.4L5.16 9.14 10.3 4l1.76 1.76zm4.67 2.45-2.68-2.67 1.77-1.77.93.93-.88.88.88.89.89-.89.86.87z"
        ></path>
      </g>
    </svg>
  </div>
</ng-template>
<ng-template #iconActivities>
  <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g id="Complete">
      <g id="edit">
        <g>
          <path
            d="M20,16v4a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V6A2,2,0,0,1,4,4H8"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <polygon
            fill="none"
            points="12.5 15.8 22 6.2 17.8 2 8.3 11.5 8 16 12.5 15.8"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </g>
    </g>
  </svg>
</ng-template>
