<ng-container *transloco="let t">
  <app-notification-card
    [notification]="notification"
    [small]="small"
    helplink="https://help.m19.com/collect-my-mws-token"
  >
    <span title> {{ t("notification-card-selling-partner.grant_organic_data", [account?.accountName]) }} </span>
    <div action>
      {{ t("notification-card-selling-partner.login_to_import_data", [logInPlaceForAPIGrantAccess()]) }}
      <div class="mt-1">
        <IButton label="{{ t('default-layout.grant_access') }}" (click)="requestGrant()" />
      </div>
      <div class="mt-2">
        {{ t("notification-card-deleted-brand-asset.more_details_on_the") }}
        <b
          ><a class="alert-link" href="/accounts" target="_blank">{{
            t("notification-card-selling-partner.account_settings_page")
          }}</a></b
        >.
      </div>
    </div>
    <span titleSmall> {{ t("notification-card-selling-partner.grant_organic_data", [account?.accountName]) }}</span>
    <div actionSmall>
      <IButton
        size="xs"
        label="{{ t('default-layout.grant_access') }}"
        (click)="requestGrant()"
        [matTooltip]="t('notification-card-selling-partner.login_to_import_data', [logInPlaceForAPIGrantAccess()])"
      />
    </div>
  </app-notification-card>
</ng-container>
