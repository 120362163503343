<ng-container *transloco="let t">
  <div class="modal-header flex items-center justify-between">
    <h4 class="modal-title pull-left">{{ t("advanced-settings-modal.advanced_settings") }}</h4>
    <span (click)="modalRef.hide()" [class]="[ICON_CLOSE, 'text-white', 'text-lg', 'cursor-pointer']"></span>
  </div>

  <div class="modal-body flex h-[40vh] flex-col gap-3 divide-y overflow-auto p-3">
    @if (accountMarketplace.customCampaignName !== null) {
      <div>
        <div class="flex items-center justify-between">
          <span class="font-medium text-gray-700">{{ t("advanced-settings-modal.strategy_label") }}</span>
          @if (!showStrategyLabelInput) {
            <IButton
              color="gray"
              variant="ghost"
              [label]="strategy.strategyLabel ?? t('common.add')"
              [disabled]="isReadOnly || !canEditCampaignLabel()"
              [tooltipValue]="
                canEditCampaignLabel()
                  ? ''
                  : t('advanced-settings-modal.you_dont_have_the_permission_to_edit_the_campaign_label')
              "
              [icon]="strategy.strategyLabel ? ICON_EDIT : ICON_ADD"
              (onClick)="showStrategyLabelInput = true"
            />
          } @else {
            <div class="flex items-center gap-2">
              <IButton
                color="gray"
                variant="soft"
                label="{{ t('common.cancel') }}"
                (onClick)="showStrategyLabelInput = false; newStrategyLabel.setValue(strategy.strategyLabel ?? '')"
              />
              <IButton
                label="{{ t('common.save') }}"
                (onClick)="updateStrategyLabel()"
                [disabled]="!newStrategyLabel"
              />
            </div>
          }
        </div>
        @if (showStrategyLabelInput) {
          <div class="mt-3 w-full">
            <IInput
              label="{{ t('advanced-settings-modal.strategy_label') }}"
              placeholder="{{ t('advanced-settings-modal.enter_strategy_label') }}"
              [counter]="80"
              [control]="newStrategyLabel"
              autoFocus
            />
          </div>
        }
      </div>
    }
    @if (focusDayPartingAccess) {
      <div class="pt-3">
        <app-day-parting-inputs
          [disabled]="isReadOnly"
          [strategy$]="strategy$"
          [locale]="locale"
          [daypartingPauseHour]="strategy.daypartingPauseHour!"
          [daypartingReactivationHour]="strategy.daypartingReactivationHour!"
        />
      </div>
    }
    @if (strategy.campaignType === CampaignType.SP && strategy.strategyType === StrategyType.PRODUCT) {
      <div class="flex w-full justify-between pt-3">
        <span class="font-medium text-gray-700">{{ t("advanced-settings-modal.automatic_targeting_campaign") }}</span>

        <app-switch-input
          [disabled]="isReadOnly"
          [checked]="strategy.autoTargetCampainEnabled"
          switchId="disableAutoCampaignSPSwitch"
          (onInput)="toggleAutoTargetCampain(strategy, !strategy.autoTargetCampainEnabled)"
        />
      </div>
    }
    @if (strategy.strategyType === StrategyType.PRODUCT && strategy.campaignType !== CampaignType.SD) {
      <div class="flex w-full justify-between pt-3">
        <span class="font-medium text-gray-700">{{ t("advanced-settings-modal.product_targeting") }}</span>

        <app-switch-input
          [disabled]="isProductTargetingToggleDisabled()"
          [checked]="productTargetingEnabled()"
          switchId="disableAutoCampaignSwitch"
          (onInput)="toggleProductTargeting(strategy, !productTargetingEnabled())"
        />
      </div>
    }
    @if (showAutoAlgoExp()) {
      <div>
        <div class="flex w-full justify-between pt-3">
          <div class="inline-flex">
            @if (!autoAlgoExpEnabled()) {
              <app-disable-auto-warning class="mr-2 inline-block" [campaignType]="strategy.campaignType" />
            }
            <span class="font-medium">{{ t("advanced-settings-modal.ai-powered_targeting") }}</span>
          </div>
          <app-switch-input
            [checked]="autoAlgoExpEnabled()"
            [disabled]="isAutoAlgoToggleDisabled()"
            switchId="disableAutomatedKeywordSwitch"
            (onInput)="switchAutoAlgoExploration(strategy, !autoAlgoExpEnabled())"
          />
        </div>

        @if (!autoAlgoExpEnabled()) {
          <p class="mt-2 text-sm text-orange-500">
            {{ t("advanced-settings-modal.disabled_warning") }}
          </p>
        }
      </div>
    }
    @if (strategy.algoMode === AlgoMode.ACOS_TARGET) {
      <div class="flex justify-between pt-3">
        <label class="font-medium text-gray-700">{{ t("metrics.MIN_DAILY_SPEND_title") }}</label>
        @if (showMinDailySpend || strategy.minDailySpend !== 0) {
          <div class="flex items-center gap-2">
            <span class="text-lg font-medium text-gray-700">{{ currencySymbol }}</span>
            <app-int-input
              [value]="strategy.minDailySpend"
              [min]="minDailyBudgetLowerBound"
              [max]="maxDailySpend"
              [unit]="currencySymbol"
              [size]="14"
              [trash]="true"
              [disabled]="isReadOnly"
              (onNewValue)="updateMinDailySpend($event)"
              (onDelete)="updateMinDailySpend(0)"
            />
          </div>
        } @else {
          <IButton
            label="{{ t('advanced-settings-modal.set_min_daily_spend') }}"
            color="gray"
            variant="ghost"
            icon="icon-[material-symbols--edit-square-outline]"
            (onClick)="showMinDailySpend = true"
          />
        }
      </div>
      @if (strategy.minDailySpend !== 0) {
        <div>
          <small>{{
            t("advanced-settings-modal.min_daily_spend_must_be_lower_than") +
              " " +
              (strategy.dailyBudget && strategy.dailyBudget > 0
                ? (strategy.dailyBudget / 2 | currency: currencyCode : "symbol-narrow" : "1.0-0") +
                  " (half of the Average daily budget) and lower than "
                : " ") +
              (maxDailyBudgetinEuros | currency: currencyCode : "symbol-narrow" : "1.0-0")
          }}</small>
        </div>
      }
    }
  </div>
</ng-container>
