<ng-container *transloco="let t">
  <form [classList]="['flex flex-col ' + (!reviewMode && 'h-full')]" [formGroup]="form" (ngSubmit)="preModWall(true)">
    <div class="overflow-y-auto p-5" [class.h-full]="!reviewMode">
      <h4 class="text-xl font-medium">{{ t("dsp-stats.creative") }}</h4>
      @if (!reviewMode) {
        <p class="m-0 text-gray-500">{{ t("sb-form-creative.first_creative") }}</p>
      }

      @if (hasLaunchedPreModeration()) {
        <div
          data-testid="premoderation-warning"
          class="relative mt-3 w-full overflow-hidden rounded-lg bg-orange-50 p-4 text-orange-500 ring-1 ring-inset ring-orange-500 ring-opacity-25"
          [ngClass]="{ '!bg-green-50 !text-green-500 !ring-green-500': !preModeWarning() }"
        >
          <div class="flex items-start gap-3">
            <span
              class="icon-[mdi--warning-circle-outline]"
              [ngClass]="{ '!icon-[mdi--success-circle-outline]': !preModeWarning() }"
            ></span>
            <div class="w-0 flex-1">
              <p class="m-0 text-sm font-medium leading-4 text-inherit">
                {{
                  preModeWarning()
                    ? t("sb-form-creative.amazon_pre-moderation")
                    : t("sb-form-creative.pre-moderation_successful")
                }}
              </p>
              @if (preModeWarning()) {
                <div class="mt-1 text-sm leading-5 opacity-90">
                  <ul class="mb-0 list-inside space-y-1 p-0">
                    @if (preModeWarning()?.headline) {
                      <li>&nbsp;{{ preModeWarning()?.headline }}</li>
                    }
                    @if (preModeWarning()?.brandLogo) {
                      <li>&nbsp;{{ preModeWarning()?.brandLogo }}</li>
                    }
                    @if (preModeWarning()?.customImage) {
                      <li>{{ preModeWarning()?.customImage }}</li>
                    }
                  </ul>
                </div>
              }
            </div>
          </div>
        </div>
      }

      <div class="mt-5 flex w-full flex-col space-y-4">
        @if (_creativeType !== SbCreativeType.video) {
          <!-- brand name -->
          <IInput
            [control]="form.controls.brandName"
            label="{{ t('sb-form-creative.brand_name') }}"
            placeholder="{{ t('sb-form-creative.brand_name') }}"
            [counter]="BRAND_NAME_MAX_LENGTH"
            autoFocus
            [error]="
              form.controls.brandName.errors && form.controls.brandName.errors['invalidName']
                ? 'Brand Name contains invalid characters'
                : ''
            "
            class="sensitive-data"
            testid="brand-name-input"
          />

          <!-- headline -->
          <IInput
            [control]="form.controls.headline"
            label="{{ t('sb-form-creative.headline') }}"
            placeholder="{{ t('sb-form-creative.headline') }}"
            [counter]="headlineMaxLength"
            [error]="
              form.controls.headline.errors && form.controls.headline.errors['invalidName']
                ? t('sb-form-creative.headline_contains_invalid_characters')
                : form.controls.headline.errors && form.controls.headline.errors['capsError']
                  ? t('sb-form-creative.the_headline_cannot_consist_solely_of_capital_letters')
                  : ''
            "
            testid="headline-input"
          />

          <!-- brand logo / custom image / video -->
          <div [ngClass]="reviewMode ? 'flex gap-x-2' : 'space-y-4'">
            <!-- brand logo -->
            <div class="flex-1">
              <label
                class="m-0 mb-1 block pl-1 text-sm font-medium text-gray-700 after:ms-0.5 after:text-red-500 after:content-['*']"
                >{{ isVendor ? t("sb-form-creative.logo") : t("creative-display.brand_logo") }}</label
              >
              @if (reviewMode) {
                <div class="flex size-40 place-content-center rounded-md border border-gray-300 p-1">
                  <img
                    class="sensitive-data max-h-full max-w-full object-contain"
                    [src]="form.controls.brandLogo.value?.url"
                  />
                </div>
              } @else {
                <div class="flex items-center gap-x-2">
                  <app-media-input
                    class="max-w-full"
                    [mediaType]="MediaType.brandLogo"
                    [medias]="brandLogos!"
                    [savedMedia]="form.controls.brandLogo.value!"
                    (mediaSaved)="form.controls.brandLogo.setValue($event)"
                    (toUpload)="onToUpload($event, MediaType.brandLogo, form.controls.brandLogo!)"
                    testId="brand-logo-input"
                  />
                </div>
              }
            </div>

            @if (this._creativeType === SbCreativeType.productCollection) {
              <!-- custom image -->
              <div class="flex-1">
                <label
                  class="m-0 mb-1 block pl-1 text-sm font-medium text-gray-700 after:ms-0.5 after:text-red-500 after:content-['*']"
                  >{{ t("sb-form-creative.custom_images") }}</label
                >
                @if (reviewMode) {
                  <div class="flex h-40 w-full overflow-x-auto rounded-md border border-gray-300 p-1">
                    @for (customImage of form.controls.customImages.value; track customImage?.assetId) {
                      <img class="sensitive-data mx-1 max-h-full max-w-full object-contain" [src]="customImage?.url" />
                    }
                  </div>
                } @else {
                  <p class="m-0 mb-2 pl-1 text-sm text-gray-500">
                    {{ t("sb-form-creative.add_images_showcase") }}
                  </p>
                  <div class="mb-3 flex grid flex-col items-start gap-y-2">
                    @for (
                      customImageControl of form.controls.customImages.controls;
                      track customImageControl.value;
                      let idx = $index
                    ) {
                      <app-media-input
                        class="max-w-full"
                        [mediaType]="MediaType.customImage"
                        [medias]="customImages!"
                        [savedMedia]="customImageControl.value!"
                        [removable]="form.controls.customImages.controls.length > 1"
                        (mediaSaved)="customImageControl.setValue($event)"
                        (toUpload)="onToUpload($event, MediaType.customImage, customImageControl)"
                        (mediaDeleted)="onCustomImageDelete(idx)"
                        testId="custom-image-input"
                      />
                    }
                  </div>
                  <IButton
                    label="{{ t('sb-form-creative.new_custom_image') }}"
                    variant="soft"
                    color="gray"
                    icon="icon-[material-symbols--add-rounded]"
                    (onClick)="addCustomImageControl()"
                    [disabled]="form.controls.customImages.length >= MAX_CUSTOM_IMAGES"
                  />
                }
              </div>
              <!-- video -->
            } @else if (_creativeType === SbCreativeType.brandVideo) {
              <ng-template [ngTemplateOutlet]="videoInput"></ng-template>
            }
          </div>
        } @else {
          <ng-template [ngTemplateOutlet]="videoInput"></ng-template>
        }
      </div>
    </div>

    @if (!reviewMode) {
      <div class="z-10 flex justify-end border-t border-gray-300 bg-white p-4">
        <div class="flex gap-x-2">
          <IButton
            [label]="editMode ? t('common.close') : t('common.previous')"
            variant="soft"
            color="gray"
            (onClick)="editMode ? bsModalRef.hide() : onFormPrevious.emit(); $event.preventDefault()"
            [icon]="editMode ? undefined : 'icon-[material-symbols--chevron-left-rounded]'"
          />

          @if (hasLaunchedPreModeration()) {
            <IButton
              [loading]="preModeLoading()"
              variant="soft"
              color="orange"
              label="{{ t('sb-form-creative.relaunch_pre-moderation') }}"
              [icon]="preModeLoading() ? undefined : 'icon-[material-symbols--cached-rounded]'"
              (onClick)="launchPreModeration()"
              testid="relaunch-premod-button"
            />
          }
          <IButton
            [disabled]="form.invalid || form.controls.customImages.invalid"
            [loading]="preModeLoading()"
            type="submit"
            label="{{ t('common.next') }}"
            icon="icon-[material-symbols--chevron-right-rounded]"
            [trailing]="true"
            testid="next-button"
          />
        </div>
      </div>
    }
  </form>

  <ng-template #videoInput>
    <div class="w-full">
      <label
        class="m-0 mb-1 block pl-1 text-sm font-medium text-gray-700 after:ms-0.5 after:text-red-500 after:content-['*']"
        >{{ t("sb-form-creative.video") }}</label
      >
      @if (reviewMode) {
        <div class="flex rounded-md border border-gray-300 p-1">
          <video class="h-52 w-full" controls autoplay [muted]="true">
            <source [src]="form.controls.video.value?.url" type="video/mp4" />
          </video>
        </div>
      } @else {
        <div class="flex items-center gap-x-2">
          <app-media-input
            class="max-w-full"
            [mediaType]="MediaType.videoAsset"
            [medias]="videos!"
            [resetFile]="resetVideoInput"
            [savedMedia]="form.controls.video.value!"
            (mediaSaved)="form.controls.video.setValue($event)"
            (toUpload)="onToUpload($event, MediaType.videoAsset, form.controls.video)"
          />
        </div>
      }
    </div>
  </ng-template>

  <ng-template #uploadToAmazonModal>
    <div class="modal-body">
      <div class="mb-2 flex items-center gap-x-2">
        <span class="icon-[mdi--progress-upload] text-3xl text-gray-500"></span>
        <h4 class="m-0">{{ t("sb-form-creative.uploading_to_amazon") }}</h4>
      </div>
      <p class="text-gray-500">{{ t("sb-form-creative.wait_upload") }}</p>
      <ISpinner [display]="true" />
    </div>
  </ng-template>
</ng-container>
