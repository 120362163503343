import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AccountMarketplaceService, AuthService, DEFAULT_BILLING_PLANS } from "@front/m19-services";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { CouponModalComponent } from "../coupon-modal/coupon-modal.component";
import {
  AccountMarketplace,
  AccountType,
  BillingApi,
  BillingPlan,
  BillingPlanFrequencyEnum,
  Currency,
  Organization,
  Plan,
} from "@front/m19-api-client";
import { TranslocoService } from "@jsverse/transloco";

enum BillingPlanDisableReason {
  ENABLED = "Enabled",
  MAX_ACCOUNT_REACHED = "MaxAccountReached",
  MAX_MARKETPLACE_REACHED = "MaxMarketplaceReached",
  ALREADY_SELECTED_PLAN = "AlreadySelectedPlan",
  CONTACT_US_TO_UPGRADE = "ContactUsToUpgrade",
  VENDOR_NOT_ALLOWED_FOR_STARTER = "VendorNotAllowedForStarter",
  STARTER_NOT_AVAILABLE = "StarterNotAvailable",
}

@UntilDestroy()
@Component({
  selector: "app-billing-plan-selection",
  templateUrl: "./billing-plan-selection.component.html",
  styleUrls: ["billing-plan-selection.component.scss"],
})
export class BillingPlanSelectionComponent implements OnInit {
  @Output()
  save = new EventEmitter<BillingPlan>();

  @Output()
  cancel = new EventEmitter();

  @Input({ required: true })
  organization!: Organization;

  @Input({ required: true })
  organizationResources!: AccountMarketplace[];

  @Input({ required: true })
  currentBillingPlan?: BillingPlan;

  readonly supportedCurrencies = [Currency.EUR, Currency.USD];

  readonly BillingPlanDisableReason = BillingPlanDisableReason;
  readonly Plan = Plan;

  allBillingPlans?: BillingPlan[];
  displayedBillingPlans?: BillingPlan[];
  locale?: string;
  selectedCurrency: Currency = this.supportedCurrencies[0];
  selectedPlan?: BillingPlan;

  constructor(
    private billingApi: BillingApi,
    private authService: AuthService,
    private modalService: BsModalService,
    private toastrService: ToastrService,
    private accountMarketplaceService: AccountMarketplaceService,
    private router: Router,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.billingApi
      .listBillingPlans({ plans: DEFAULT_BILLING_PLANS })
      .pipe(untilDestroyed(this))
      .subscribe((billingPlans) => {
        this.allBillingPlans = billingPlans;
        this.displayedBillingPlans = this.allBillingPlans.filter(
          (bp) => bp.plan == Plan.STARTER || (bp.currency == this.selectedCurrency && bp.plan == Plan.PROFESSIONAL),
        );
      });
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((x) => {
      this.locale = x.locale;
    });
  }

  selectCurrency(currency: Currency): void {
    this.selectedCurrency = currency;
    this.displayedBillingPlans = this.allBillingPlans?.filter(
      (bp) => bp.plan == Plan.STARTER || (bp.currency == this.selectedCurrency && bp.plan == Plan.PROFESSIONAL),
    );
    this.selectedPlan = undefined;
  }

  selectPlan(plan: BillingPlan): void {
    this.selectedPlan = plan;
  }

  isPlanDisabled(plan: BillingPlan): BillingPlanDisableReason {
    if (plan.plan == Plan.STARTER && this.organization?.starterPlanBlacklisted) {
      return BillingPlanDisableReason.STARTER_NOT_AVAILABLE;
    }
    if (this.currentBillingPlan?.plan && this.currentBillingPlan?.plan == plan.plan) {
      return BillingPlanDisableReason.ALREADY_SELECTED_PLAN;
    }
    if (this.currentBillingPlan?.plan && this.currentBillingPlan?.plan != Plan.STARTER) {
      return BillingPlanDisableReason.CONTACT_US_TO_UPGRADE;
    }

    const accountLimit = plan.accountLimit ?? +Infinity;
    const marketplaceLimitPerAccount = plan.marketplaceLimitPerAccount ?? +Infinity;
    const marketplacesPerAccount = new Map<string, number>();
    for (const organizationResource of this.organizationResources ?? []) {
      const m = marketplacesPerAccount.get(organizationResource.accountId) ?? 0;
      if (m + 1 > marketplaceLimitPerAccount) {
        return BillingPlanDisableReason.MAX_MARKETPLACE_REACHED; // over marketplace limit
      }
      marketplacesPerAccount.set(organizationResource.accountId, m + 1);
      if (plan.plan == Plan.STARTER && organizationResource.accountType == AccountType.VENDOR) {
        return BillingPlanDisableReason.VENDOR_NOT_ALLOWED_FOR_STARTER; // VENDOR not allowed for STARTER
      }
    }
    if (marketplacesPerAccount.size > accountLimit) {
      return BillingPlanDisableReason.MAX_ACCOUNT_REACHED; // over account limit
    }
    return BillingPlanDisableReason.ENABLED;
  }

  saveSelection(): void {
    this.save.emit(this.selectedPlan);
  }

  cancelSelection(): void {
    this.selectedPlan = undefined;
    this.cancel.emit();
  }

  isValidSelection(): boolean {
    return !!this.selectedPlan;
  }

  printFrequency(frequency: BillingPlanFrequencyEnum | undefined): string {
    switch (frequency) {
      case BillingPlanFrequencyEnum.MONTHLY:
        return "/ Month";
      case BillingPlanFrequencyEnum.YEARLY:
        return "/ Year";
      default:
        return "";
    }
  }

  getBillingPlanDisabledReason(reason: BillingPlanDisableReason) {
    switch (reason) {
      case BillingPlanDisableReason.CONTACT_US_TO_UPGRADE:
        return this.translocoService.translate("billing-plan-selection.contact_us_to_upgrade");
      case BillingPlanDisableReason.MAX_ACCOUNT_REACHED:
        return this.translocoService.translate("billing-plan-selection.too_many_accounts_selected");
      case BillingPlanDisableReason.MAX_MARKETPLACE_REACHED:
        return this.translocoService.translate("billing-plan-selection.too_many_marketplaces_selected");
      case BillingPlanDisableReason.VENDOR_NOT_ALLOWED_FOR_STARTER:
        return this.translocoService.translate(
          "billing-plan-selection.only_seller_accounts_are_possible_for_starter_plan",
        );
      default:
        return undefined;
    }
  }

  openCouponModal() {
    const modalOptions: ModalOptions = {
      initialState: {
        organizationId: this.organization.organizationId,
      },
    };
    const modalRef = this.modalService.show(CouponModalComponent, modalOptions);
    const subscription = modalRef.content?.couponSuccess.subscribe(() => {
      this.toastrService.success(this.translocoService.translate("billing-plan-selection.coupon_code_submitted"));
      this.accountMarketplaceService.activateBidder(
        this.organizationResources,
        () => {
          this.toastrService.success(
            this.translocoService.translate("billing-plan-selection.accounts_successfully_activated"),
          );
          this.router.navigate(["billing"]);
        },
        (err) => {
          this.toastrService.error(
            err,
            this.translocoService.translate("billing-plan-selection.accounts_activation_failure"),
          );
          this.router.navigate(["billing"]);
        },
      );
      subscription?.unsubscribe();
    });
    subscription?.add(
      modalRef.content?.couponError.subscribe((e) => {
        this.toastrService.error(e, this.translocoService.translate("billing-plan-selection.coupon_code_error"));
        subscription?.unsubscribe();
      }),
    );
  }

  protected readonly Currency = Currency;
}
