import { Component, Input, OnInit } from "@angular/core";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { TargetingLimit } from "@m19-board/strategies/target-utils";

@Component({
  selector: "app-target-limit-warning",
  templateUrl: "./target-limit-warning.component.html",
  styleUrls: ["./target-limit-warning.component.scss"],
})
export class TargetLimitWarningComponent implements OnInit {
  @Input()
  targetLimit?: TargetingLimit;

  readonly faWarning = faExclamationTriangle;

  label!: string;

  ngOnInit(): void {
    this.label = this.targetLimit ? this.targetLimit.formatErrorMessage() : "";
  }
}
