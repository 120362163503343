import { Component } from "@angular/core";
import { ICellRendererParams } from "@ag-grid-community/core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { StrategyEx, StrategyGroupEx } from "@front/m19-models";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AccountMarketplace, AlgoMode, StrategyType } from "@front/m19-api-client";
import { AccountSelectionService, SpStrategiesService, StrategyService } from "@front/m19-services";
import { ToastrService } from "ngx-toastr";
import { PrimeDayConfig, eventName } from "./strategy-boost-grid.component";
import { Options } from "nx/src/utils/params";
import { Option } from "@front/m19-ui";
@UntilDestroy()
@Component({
  selector: "app-optimizer-description",
  styleUrls: ["./strategy-boost-grid.component.scss"],
  templateUrl: "./optimizer-description.renderer.component.html",
})
export class OptimizerDescriptionRendererComponent implements ICellRendererAngularComp {
  strategy!: StrategyEx;
  accountMarketplace!: AccountMarketplace;
  isReadOnly: boolean = false;
  AlgoMode = AlgoMode;
  primeDayOverDate?: string;
  eventName = eventName;
  primeDayPeriod?: string;
  primeDayAfterPeriod?: string;

  options: Option<number>[] = [
    {
      value: -50,
      label: "-50%",
      id: 1,
    },
    {
      value: -25,
      label: "-25%",
      id: 2,
    },
    {
      value: 0,
      label: "0%",
      id: 3,
    },
    {
      value: 25,
      label: "+25%",
      id: 4,
    },
    {
      value: +50,
      label: "+50%",
      id: 5,
    },
    {
      value: 100,
      label: "100%",
      id: 6,
    },
  ];

  selectedOption: Option<number> | undefined;
  constructor(
    private strategyService: StrategyService,
    private toastr: ToastrService,
  ) {}
  agInit(
    params: any & {
      strategy: StrategyEx;
      accountMarketplace: AccountMarketplace;
      isReadOnly: boolean;
      config: PrimeDayConfig;
    },
  ): void {
    this.strategy = params.data;
    this.isReadOnly = params.isReadOnly;
    this.accountMarketplace = params.accountMarketplace;
    this.primeDayOverDate = params.config.primeDayOverDate;
    this.primeDayPeriod = params.config.primeDayPeriod;
    this.primeDayAfterPeriod = params.config.primeDayAfterPeriod;
    this.selectedOption = this.options.find((x) => x.value === this.strategy.primeDayBoost);
  }

  refresh(params: ICellRendererParams) {
    return true;
  }

  primeDayBoost(acosTarget: number, primeDayBoost: number): number {
    return acosTarget + acosTarget * (primeDayBoost / 100);
  }

  primeDayDecrease(acosTarget: number): number {
    return acosTarget * 0.75;
  }

  setIntensity(option: Option<number>): void {
    const primeDayBoost = option.value;
    this.strategyService
      .updatePrimeDayBoost(
        this.accountMarketplace.accountId,
        this.accountMarketplace.marketplace,
        this.accountMarketplace.resourceOrganizationId!,
        this.strategy.strategyId,
        primeDayBoost,
      )
      .subscribe({
        next: () => {
          this.toastr.success("Prime Day boost updated");
        },
        error: (error) => {
          this.toastr.error(error.error.message);
        },
      });
  }
}
