<ng-container *transloco="let t">
  <mat-table mat-table [dataSource]="dataSource" class="responsive-table table-striped table-hover text-right">
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef class="flex-half text-left">
        @if (!onlyOneAccount) {
          <span (click)="selectAll()" class="pointer">
            <fa-icon
              [icon]="
                selectedOrganizationResources.size === selectableOrganizationresources?.size
                  ? faCheckedSquare
                  : faSquare
              "
              size="lg"
              class="text-primary"
            ></fa-icon>
          </span>
        }
      </mat-header-cell>
      <mat-cell *matCellDef="let resource" class="flex-half text-left">
        <div>
          <span (click)="select(resource)" class="pointer" [ngClass]="{ disabled: !isSelectable(resource) }">
            <fa-icon
              [icon]="isSelected(resource) ? faCheckedSquare : faSquare"
              size="lg"
              class="text-primary"
            ></fa-icon>
          </span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="account">
      <mat-header-cell *matHeaderCellDef class="text-left">{{ t("common.account") }}</mat-header-cell>
      <mat-cell *matCellDef="let resource" class="text-left">
        {{ resource.accountName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="marketplace">
      <mat-header-cell *matHeaderCellDef class="flex-half">{{ t("common.marketplaces") }}</mat-header-cell>
      <mat-cell *matCellDef="let resource" class="flex-half">
        <IBadge [label]="resource.marketplace" size="xs" />
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="info">
      <mat-header-cell *matHeaderCellDef class="flex-2"></mat-header-cell>
      <mat-cell *matCellDef="let resource" class="flex-2">
        @if (resource.state === BidderOn) {
          <div>This account is part of your current plan.</div>
        }
        @if (alreadyUsedByAnotherOrganization.has(resource)) {
          <div class="alert alert-danger m-0 text-left">
            {{ t("billing-account-selection.accountanother_org_error") }}
          </div>
        }
        @if (!hasFreshStats(resource)) {
          <div class="alert alert-danger m-0 text-left">
            {{ t("billing-account-selection.not_fully_downloaded") }}
          </div>
        }
        @if (!resource.activated && hasFreshStats(resource)) {
          <div class="alert alert-danger m-0 text-left">
            {{ t("billing-account-selection.unable_to_automate") }}
            <a href="https://advertising.amazon.com/" target="_blank">{{ t("common.amazon_advertising_console") }}</a
            >.
          </div>
        }
        @if (!resource.hasAccessToAdvertising && hasFreshStats(resource)) {
          <div class="alert alert-danger m-0 text-left">t('billing-account-selection.access_lost')</div>
        }
      </mat-cell>
    </ng-container>

    <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    [pageSizeOptions]="[10, 50, 100]"
    [pageSize]="10"
    showFirstLastButtons
    style="margin-bottom: 2rem"
    [hidden]="dataSource.data && dataSource.data.length === 0"
  ></mat-paginator>
  <div class="modal-footer">
    <button type="button" class="btn btn-ghost-secondary" (click)="cancelSelection()">{{ t("common.cancel") }}</button>
    <button type="button" class="btn btn-primary" (click)="saveSelection()" [disabled]="!isValidSelection()">
      {{ onlyOneAccount ? t("common.save") : t("common.next") }}
    </button>
  </div>
</ng-container>
