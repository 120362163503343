@if (strategy_?.state === "ENABLED" && constraint) {
  <div *transloco="let t">
    <img
      [src]="constraint.src"
      [width]="size_"
      [height]="size_"
      [matTooltip]="t(constraint.title)"
      matTooltipPosition="below"
    />
  </div>
}
