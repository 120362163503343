import { formatCurrency, formatPercent, getCurrencySymbol } from "@angular/common";
import { NgModule, Pipe, PipeTransform } from "@angular/core";
import { AccountType, AlgoMode, CampaignType, Strategy, StrategyStateEnum } from "@front/m19-api-client";
import { Metric, MetricCategory, MetricType, RegisteredMetric, SupportedAccountType } from "@front/m19-metrics";
import { AdStatsEx, ProductEx } from "@front/m19-models";

export const PALETTE = [
  "#181d4d",
  "#4bae99",
  "#f0ad4e",
  "#7b9fe8",
  "#969696",
  "#8e6ba0",
  "#f9b1c7",
  "#334061",
  "#ed7d24",
  "#becae6",
  "#ed2496",
  "#e6d687",
  "#f72b25",
  "#636dce",
  "#a3d7cc",
  "#f7d6a6",
  "#bccef3",
  "#cacaca",
  "#c6b4cf",
  "#fcd7e3",
  "#8898c0",
  "#f6bd91",
  "#dee4f2",
  "#f691ca",
  "#f2eac3",
  "#fb9491",
  "#6C2F66",
];

export interface StrategyStats extends AdStatsEx {
  strategyName: string;
  state?: StrategyStateEnum;
  campaignType?: CampaignType;
  dayPartingEnabled?: boolean;
  dayPartingPauseHour?: number | null;
  dayPartingReactivationHour?: number | null;
  algorithm?: string;
  acosTarget?: number; // ACOS_TARGET
  suggestedBid?: number; // PRODUCT_LAUNCH
  dailyBudget?: number; // PRODUCT_LAUNCH
  monthlyBudget?: number; // MONTHLY_BUDGET_TARGET
  minDailySpend?: number; // MIN_DAILY_SPEND
  tacosTarget?: number; // TACOS_TARGET
  strategy?: Strategy;
  today?: string; // for monthly budget
  nextMonthlyBudget?: number;
}

export const NON_M19_STRATEGIES = "Not operated Campaigns";

export function toStrategyStats(adStats: AdStatsEx, strategyIndex: Map<number, Strategy>): StrategyStats {
  const strategy = adStats.strategyId ? strategyIndex.get(adStats.strategyId) : undefined;
  return {
    ...adStats,
    subStrategyId: undefined,
    strategyName: adStats.strategyId
      ? strategyIndex.get(adStats.strategyId)?.name
        ? strategyIndex.get(adStats.strategyId)!.name!
        : "Deleted strategy"
      : NON_M19_STRATEGIES,
    state: strategy?.state,
    campaignType: strategy?.campaignType,
    dayPartingEnabled: strategy?.daypartingPauseHour != null && strategy?.daypartingReactivationHour != null,
    dayPartingPauseHour: strategy?.daypartingPauseHour,
    dayPartingReactivationHour: strategy?.daypartingReactivationHour,
    algorithm: strategy?.algoMode,
    acosTarget: strategy?.algoMode === AlgoMode.ACOS_TARGET ? strategy?.acosTarget : undefined,
    suggestedBid: strategy?.algoMode === AlgoMode.PRODUCT_LAUNCH ? strategy?.suggestedBid : undefined,
    dailyBudget: strategy?.algoMode === AlgoMode.PRODUCT_LAUNCH ? strategy?.dailyBudget : undefined,
    monthlyBudget: strategy?.algoMode === AlgoMode.MONTHLY_BUDGET_TARGET ? strategy?.monthlyBudget : undefined,
    strategy: strategy,
  };
}

export interface SubStrategyStats extends AdStatsEx {
  subStrategyName: string;
  strategyName: string;
  state: StrategyStateEnum;
}

export interface QueryStats extends AdStatsEx {
  isAsin: boolean;
  isMultiAsin: boolean;
  isUnknownAsin: boolean;
}

export function toFixedIfNecessary(value: string, dp: number) {
  return +parseFloat(value).toFixed(dp);
}

export function printCurrency(
  data: number | undefined,
  locale: string | undefined,
  currency: string | undefined,
  precision = "1.0-0",
): string {
  return data
    ? formatCurrency(data, locale ?? "", getCurrencySymbol(currency ?? "", "narrow", locale) ?? "", currency, precision)
    : "-";
}

@Pipe({ name: "metricFormatPipe" })
export class MetricFormatPipe<T> implements PipeTransform {
  transform(
    metric: Metric<T>,
    d: T | number,
    locale?: string,
    currency?: string,
    precision?: string,
    smallMode?: boolean,
    dontDisplayNA?: boolean,
  ): string {
    if (!locale || !currency) return "";

    const res = smallMode ? metric.formatSmall(d, locale, currency) : metric.format(d, locale, currency, precision);
    return dontDisplayNA && res === "N/A" ? "" : res;
  }
}

@Pipe({ name: "metricRelativeValuePipe" })
export class MetricRelativeValuePipe<T> implements PipeTransform {
  transform(metric: Metric<T>, d: T | number, total: T | number, locale?: string, formatSmall?: boolean): string {
    if (typeof d !== "number") d = metric.value(d)!;
    if (typeof total !== "number") total = metric.value(total)!;
    if (
      !total ||
      !locale ||
      isNaN(d) ||
      isNaN(total) ||
      total === -Infinity ||
      total === Infinity ||
      total === 0 ||
      d === -Infinity ||
      d === Infinity
    )
      return "";
    return formatPercent(d / total, locale, formatSmall === true ? "1.0-0" : "1.2-2");
  }
}

@Pipe({ name: "metricBucketPipe" })
export class MetricBucketPipe<T> implements PipeTransform {
  transform(metric: Metric<T>, d: Map<string, T>, hour: string): string {
    const val = metric.value(d.get(hour)!);
    if (val == undefined || !isFinite(val)) return "white";

    const level = metric.getGradientBucket(d, hour);
    return level?.toString() ?? "";
  }
}

@Pipe({ name: "metricHasValuePipe" })
export class MetricHasValuePipe<T> implements PipeTransform {
  transform(metric: Metric<T>, d: T): boolean {
    const value = metric.value(d);
    return value !== undefined && value != 0 && isFinite(value);
  }
}

export function isSupportedAccountType(accountType: AccountType, supportedAccountType: SupportedAccountType): boolean {
  return (
    supportedAccountType === SupportedAccountType.VENDOR_AND_SELLER ||
    (supportedAccountType === SupportedAccountType.VENDOR && accountType === AccountType.VENDOR) ||
    (supportedAccountType === SupportedAccountType.SELLER && accountType === AccountType.SELLER)
  );
}

export class SumMetric<T> extends RegisteredMetric<T> {
  public readonly metric1: Metric<T>;
  public readonly metric2: Metric<T>;
  public readonly precision: string;

  constructor(params: {
    id: string;
    metric1: Metric<T>;
    metric2: Metric<T>;
    title: string;
    titleSmall?: string;
    category: MetricCategory;
    color: string;
    precision?: string;
    supportedAccountType?: SupportedAccountType;
    tooltip: string;
    inverseColors?: boolean;
    graphTension?: number;
    graphBorderDash?: Array<number>;
    requireSellingPartnerAccess?: boolean;
  }) {
    super({
      ...params,
      type: MetricType.SUMMABLE,
    });
    this.metric1 = params.metric1;
    this.metric2 = params.metric2;
    this.precision = params.precision ?? "1.0-0";
  }

  public value(d: T): number {
    return this.metric1.value(d)! + this.metric2.value(d)!;
  }

  public valueForCsv(d: T): string {
    const value = this.value(d);
    if (isFinite(value)) {
      return this.precision == "1.0-0" ? value.toFixed(0) : toFixedIfNecessary(value.toString(), 2).toString();
    }
    return "-";
  }

  public format(d: number | T, locale: string, currency: string): string {
    const value = d === undefined ? 0 : typeof d === "number" ? d : this.value(d);
    return this.metric1.format(value, locale, currency);
  }

  public formatSmall(d: number | T, locale: string, currency: string): string {
    const value = d === undefined ? 0 : typeof d === "number" ? d : this.value(d);
    return this.metric1.formatSmall(value, locale, currency);
  }

  public compare(d1: T, d2: T): number {
    return this.value(d2) - this.value(d1);
  }
}

export abstract class ProductMetric extends RegisteredMetric<ProductEx> {
  constructor(params: {
    id: string;
    title: string;
    color: string;
    tooltip: string;
    reverseAxis?: boolean;
    maxAt?: number;
    minAtZero?: boolean;
  }) {
    super({
      ...params,
      type: MetricType.RATIO,
      category: MetricCategory.PRODUCT,
      supportedAccountType: SupportedAccountType.VENDOR_AND_SELLER,
      requireSellingPartnerAccess: false,
    });
  }

  public abstract value(d: ProductEx): number | undefined;

  public valueForCsv(d: ProductEx): string {
    const value = this.value(d);
    if (value !== undefined && isFinite(value)) {
      return value.toFixed(0);
    }
    return "-";
  }

  public abstract format(d: number | ProductEx, locale?: string, currency?: string): string;

  public formatSmall(d: ProductEx, locale: string) {
    return this.format(d, locale);
  }

  public abstract compare(d1: ProductEx, d2: ProductEx): number;

  getGradientBucket(): number {
    return 0; // do nothing
  }

  // fix it when it will be used
  public getEvolution(a: ProductEx, b: ProductEx): number | undefined {
    return undefined;
  }

  // fix it when it will be used
  public formatMetricEvolution(evolution: number, locale: string): string {
    return "-";
  }
}

@NgModule({
  imports: [],
  exports: [MetricFormatPipe, MetricRelativeValuePipe, MetricBucketPipe, MetricHasValuePipe],
  declarations: [MetricFormatPipe, MetricRelativeValuePipe, MetricBucketPipe, MetricHasValuePipe],
})
export class MetricModule {}
