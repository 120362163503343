import { Component, OnInit } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { BrandAnalyticsService } from "@front/m19-services";
import { BrandAnalyticsMarketplaces } from "@m19-board/brand-analytics/BrandAnalyticsAvailability";
import { Marketplace } from "@front/m19-api-client";
import { toSignal } from "@angular/core/rxjs-interop";
import { MarketplaceSelectorComponent } from "@m19-board/account-selector/marketplace-selector.component";

@UntilDestroy()
@Component({
  selector: "app-search-trends-marketplace-selector",
  template: `@if (selectedMarketplace(); as selectedMarketplace) {
    <app-marketplace-selector
      [marketplaces]="availableMarketplaces"
      [marketplaceSelected]="selectedMarketplace"
      (marketplaceSelectedChange)="selectMarketplace($event)"
    ></app-marketplace-selector>
  }`,
  standalone: true,
  imports: [MarketplaceSelectorComponent],
})
export class SearchTrendsMarketplaceSelectorComponent implements OnInit {
  constructor(private brandAnalyticsService: BrandAnalyticsService) {}

  availableMarketplaces = BrandAnalyticsMarketplaces;
  selectedMarketplace = toSignal(this.brandAnalyticsService.selectedMarketplace$);

  ngOnInit(): void {
    const initMarketplace = Marketplace[localStorage.getItem("currentMarketplace") as Marketplace];
    if (initMarketplace && this.availableMarketplaces.includes(initMarketplace)) {
      this.brandAnalyticsService.selectMarketplace(initMarketplace);
    }
  }

  selectMarketplace(marketplace: Marketplace): void {
    this.brandAnalyticsService.selectMarketplace(marketplace);
    localStorage.setItem("currentMarketplace", marketplace);
  }
}
