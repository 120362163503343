import { Component, OnInit } from "@angular/core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest } from "rxjs";
import { NotificationEx } from "@front/m19-models";
import { AccountMarketplace, AccountState, NotificationType } from "@front/m19-api-client";
import { AccountSelectionService, NotificationService } from "@front/m19-services";

@UntilDestroy()
@Component({
  selector: "app-notifications-center",
  templateUrl: "./notifications-center.component.html",
  styleUrls: ["./notifications-center.component.scss"],
})
export class NotificationsCenterComponent implements OnInit {
  notifications: NotificationEx[] = [];
  notificationsCountPerType: Map<NotificationType, number> = new Map();
  notificationTypeSelection?: NotificationType = undefined;
  notificationTypes: NotificationType[] = [];
  notificationsCount = 0;
  regExp = new RegExp("");

  isLoading = true;

  readonly faSearch = faSearch;

  constructor(
    private notificationService: NotificationService,
    private accountSelectionService: AccountSelectionService,
  ) {}

  ngOnInit(): void {
    combineLatest<[NotificationEx[], AccountMarketplace[]]>([
      this.notificationService.getNotifications$,
      this.accountSelectionService.accountMarketplacesSelection$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([notifications, accountMarketplaceSelection]) => {
        if (!notifications) return;
        this.notificationsCountPerType.clear();
        this.notificationsCount = 0;
        this.notifications = notifications.filter(
          (n) =>
            (n.isCritical ||
              (!n.marketplace && accountMarketplaceSelection.some((am) => am.accountId == n.accountId)) ||
              accountMarketplaceSelection.some(
                (am) => am.accountId == n.accountId && am.marketplace == n.marketplace,
              )) &&
            (!n.isRelatedToBidder() ||
              (accountMarketplaceSelection as AccountMarketplace[]).find(
                (am) => am.accountId === n.accountId && am.marketplace === n.marketplace,
              )?.state === AccountState.BIDDER_ON),
        );
        for (const notif of this.notifications) {
          if (!this.notificationsCountPerType.has(notif.type)) {
            this.notificationsCountPerType.set(notif.type, 0);
          }
          if (!notif.dismissed) {
            this.notificationsCountPerType.set(notif.type, this.notificationsCountPerType.get(notif.type)! + 1);
            this.notificationsCount += 1;
          }
        }
        if (this.notificationTypeSelection && !this.notificationsCountPerType.has(this.notificationTypeSelection)) {
          this.notificationTypeSelection = undefined;
        }
        this.notificationTypes = Array.from(this.notificationsCountPerType.keys());
        this.notificationService.sortNotificationTypes(this.notificationTypes);
        this.isLoading = false;
      });
  }

  getLabel(type: NotificationType): string {
    return this.notificationService.getLabel(type) ?? "";
  }

  selectType(type?: NotificationType): void {
    this.notificationTypeSelection = type;
  }

  setFilter(event: EventTarget | null): void {
    const filter = (event as HTMLInputElement).value;
    this.regExp = new RegExp(filter, "i");
  }
}
