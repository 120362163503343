<ng-container *transloco="let t">
  <app-strategy-overlay>
    <div>
      <div class="mb-2 flex justify-between p-4">
        <div class="mb-4 flex items-center">
          <mat-slide-toggle
            color="primary"
            [checked]="enabled"
            (change)="toggleActivate($event)"
            [disabled]="isReadOnly"
          ></mat-slide-toggle>
          <span class="activate-span ml-2">{{ t("strategy-boost.activate_promo_days") }}</span>
        </div>
      </div>
      @if (enabled && strategies && strategies.length > 0) {
        <div class="flex w-full items-center justify-end pr-8">
          <div class="mr-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"><fa-icon [icon]="faCalendar"></fa-icon></span>
              </div>
              <input
                class="simple-input-prepend form-control"
                autocomplete="off"
                [disabled]="!enabled || isReadOnly"
                type="button"
                #drp="bsDaterangepicker"
                bsDaterangepicker
                [(bsValue)]="bsRangeValue"
                container=".date-range-picker-boost-container"
                [bsConfig]="datePickerConfig"
                (bsValueChange)="update($event)"
              />
            </div>
            <div class="date-range-picker-boost-container"></div>
          </div>
          <div class="flex justify-end gap-1">
            <IButton
              [disabled]="isReadOnly"
              tooltipValue="{{ t('strategy-boost.upload_promo_days_settings') }}"
              [icon]="ICON_IMPORT"
              (onClick)="uploadPromoDays()"
              color="white"
              label="{{ t('common.import') }}"
            />
            <app-export-button
              (export)="exportAsCsv()"
              tooltipValue="{{ t('strategy-boost.export_promo_days_settings_as_csv') }}"
            ></app-export-button>
          </div>
        </div>
      }
      <div class="row card-perso-body bottom-child card-body">
        <div class="marketplace col">
          @if (enabled && strategies && strategies.length > 0) {
            <app-strategy-boost-grid
              [data]="strategies"
              [accountMarketplace]="accountMarketplace!"
              [dates]="bsRangeValue ?? []"
              [isReadOnly]="isReadOnly"
            ></app-strategy-boost-grid>
          }
          @if (!strategies || strategies.length === 0) {
            <div class="alert alert-danger">{{ t("strategy-boost.no_live_strategies_on_this_marketplace") }}</div>
          }
        </div>
      </div>
    </div>
  </app-strategy-overlay>
</ng-container>
