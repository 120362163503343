<div class="flex h-full flex-col" style="max-height: inherit" *transloco="let t">
  <div class="flex items-center justify-between p-2 pl-3">
    <div class="flex flex-1 items-center gap-x-2">
      @if (selectable) {
        <div class="flex items-center">
          <ICheckbox
            [value]="selected.length > 0 && selected.length === _asins().length"
            (valueChange)="
              selected.length > 0 && selected.length === _asins().length ? unselectAllproducts() : selectAllProducts()
            "
            [tooltipValue]="
              selected.length > 0 && selected.length === _asins().length
                ? t('strategy-asins.unselect_all_products')
                : t('strategy-asins.select_all_products')
            "
            [disabled]="isReadOnly"
          />
        </div>
      }
      <IInput
        autocomplete="off"
        placeholder="{{ t('store-spotlight-pages.search_by_asin') }}"
        [icon]="ICON_SEARCH"
        [control]="queryControl"
        size="sm"
        class="mr-2 max-w-lg flex-1"
      />

      @if (withCustomFieldFilter && !this.groupByParentAsin() && !withBulkFilter) {
        <IMultiSelect
          class="min-w-0 max-w-44"
          popperWidth="200px"
          [options]="customFieldsOptions"
          [selected]="selectedCustomFields()"
          (selectedChange)="customFieldFilter($event)"
          placeholder="{{ t('strategy-asins.custom_fields') }}"
          searchable="true"
          rounded
        >
          <ng-template #optionSlot let-o>
            <div class="flex w-full items-center justify-between gap-2">
              <div class="truncate">{{ o.label }}</div>
              <IBadge [label]="o.value.field" color="gray" size="xs" />
            </div>
          </ng-template>
        </IMultiSelect>
      }

      @if (addProductsBtn) {
        <IButton
          label="{{ t('sp-substrategy.add_products') }}"
          testid="btn-add-products"
          color="white"
          icon="icon-[material-symbols--add-rounded]"
          [disabled]="isReadOnly"
          (onClick)="addProducts.emit()"
        />
      }
    </div>
    <div class="flex items-center gap-2">
      @if (withDisplayMode) {
        <app-switch-button
          [type]="SwitchButtonType.ICON"
          [options]="[ViewTypeEnum.CARD, ViewTypeEnum.LIST]"
          [buttonIcons]="['icon-[mdi--view-grid]', 'icon-[mdi--format-list-bulleted]']"
          [selected]="displayMode"
          [buttonTooltips]="[t('strategy-asins.display_as_cards'), t('strategy-asins.display_as_list')]"
          (buttonClicked)="displayBy($event)"
        />
      }
      @if (csvExport) {
        <app-export-button (export)="exportCsv()" tooltipValue="{{ t('strategy-asins.export_asin_list_as_csv') }}" />
      }
      @if (allowGroupByParentAsin) {
        <span class="float-right mx-2">
          <button
            type="button"
            class="btn btn-ghost-secondary icon-only"
            (click)="toggleGroupByParentAsin()"
            [matTooltip]="
              groupByParentAsin() ? t('strategy-asins.split_by_child_asin') : t('strategy-asins.group_by_parent_asin')
            "
          >
            <img
              [src]="
                groupByParentAsin()
                  ? 'assets/img/parent_icons/parent_child.svg'
                  : 'assets/img/parent_icons/child_parent.svg'
              "
            />
          </button>
        </span>
      }
      @if (movable && selected.length > 0) {
        <IButton
          label="{{ t('common.move') }}"
          color="white"
          tooltipValue="{{ t('strategy-asins.move_selected_product_s_to_another_strategy') }}"
          icon="icon-[material-symbols--keyboard-double-arrow-right-rounded]"
          (onClick)="moveAsins()"
        />
      }
      @if (deletable && selected.length > 0) {
        <IButton
          color="red"
          variant="ghost"
          tooltipValue="{{ t('strategy-asins.delete_selected_products', { count: selected.length }) }}"
          icon="icon-[material-symbols--delete-outline]"
          (onClick)="deleteAsins()"
          data-testid="btn-delete-products"
        />
      }
    </div>
  </div>
  @if (withBulkFilter) {
    <app-filter class="m-2" [filters]="filters" (filterChange)="setFilterValues($event)" />
  }
  @if (this.bulkFiltering()) {
    <div class="flex flex-col gap-2 p-2">
      <textarea
        data-testid="bulk-asin-textarea"
        [(ngModel)]="pendingBulkAsins"
        class="relative block h-20 w-full rounded-lg border-0 bg-white text-sm text-gray-900 placeholder-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-300 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-75"
        placeholder="{{ t('strategy-asins.enter_a_list_of_asins_to_search') }}"
      ></textarea>
      <div>
        <IButton
          color="white"
          size="sm"
          [label]="t('common.filter')"
          icon="icon-[material-symbols--filter-alt-outline]"
          (onClick)="filterAsins()"
        />
      </div>
    </div>
  }
  <div class="min-h-0 flex-1 overflow-auto">
    <div
      class="w-full"
      data-testid="from-catalog-list"
      [ngClass]="displayMode === ViewTypeEnum.LIST ? 'min-h-full' : ' flex flex-wrap gap-3 p-2'"
    >
      @for (asin of shownAsins(); track asin.asin) {
        <app-asin-item
          [class.w-80]="displayMode === ViewTypeEnum.CARD"
          [asin]="asin.asin"
          [isReadOnly]="isReadOnly"
          [marketplace]="marketplace!"
          [selected]="productSelection[asin.asin] || isAsinSelected(asin)"
          [disabled]="isAsinSelected(asin) || !!disabledAsins.has(asin.asin)"
          [actionsButtons]="true"
          [isLast]="$last"
          [isSelectable]="true"
          [isIneligible]="asinEligibility.size > 0 && isAsinIneligible(asin)"
          [ineligibilityReason]="reason(asin)"
          [activeStrategies]="strategiesPerAsin?.get(asin.asin)"
          (asinSelected)="selectProduct(asin.asin, !productSelection[asin.asin])"
          [viewMode]="displayMode"
        />
      } @empty {
        <div class="mt-5 text-center text-gray-500">{{ t("strategy-asins.no_asin_found") }}</div>
      }
    </div>
  </div>
  <div class="flex flex-col items-end border-t">
    @if (filteredAsins().length > itemsShown) {
      <mat-paginator
        class="[&_*]:!text-sm"
        [pageSizeOptions]="[]"
        [hidePageSize]="true"
        [pageSize]="itemsShown"
        [pageIndex]="pageEvent().pageIndex"
        [length]="filteredAsins().length"
        (page)="pageEvent.set($event)"
        showFirstLastButtons
        #strategyCardPaginator
      />
    }
    <ng-content select="[withAsins]"></ng-content>
  </div>
</div>
