import { Component, OnInit, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AuthService, OrganizationAccountGroupService, OrganizationUsersService } from "@front/m19-services";
import { AgGridAngular } from "@ag-grid-community/angular";
import {
  ColDef,
  FirstDataRenderedEvent,
  GridOptions,
  ICellRendererParams,
  IRowNode,
  RowGroupOpenedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from "@ag-grid-community/core";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { combineLatest } from "rxjs";
import { ActionButtonsComponent } from "../../insights/overview/action-buttons/action-buttons.component";
import { AddAdminModalComponent } from "./organization-card/add-admin-modal/add-admin-modal.component";
import { ManageAuthorizedAccessModalComponent } from "./organization-card/manage-authorized-user-modal/manage-authorized-access-modal.component";
import { UserLimitationModalComponent } from "./organization-card/user-limitation-modal/user-limitation-modal.component";
import { ICON_TRASH_O, ICON_USER_KEY } from "@m19-board/utils/iconsLabels";
import { AccessLevel, AuthorizedAccess, OrganizationUser } from "@front/m19-api-client";
import { getBasicGridOptions } from "@front/m19-grid-config";
import { OrganizationAccountGroups } from "@front/m19-models";
import { TranslocoService } from "@jsverse/transloco";

export type GridData = OrganizationUser & AuthorizedAccess;

export interface UserManagementData extends GridData {
  rowId: string;
  gridSection?: "admins" | "authorizedUsers";
  organizationName?: string;
  role?: "Admin" | "Owner"; // For "Admins" section
  access?: number; // For "Authorized users" parts
  account?: string;
  isAdminOrOwner?: boolean; // Is the logged use admin or owner of the organization related to this object
}

@UntilDestroy()
@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"],
})
export class UserManagementComponent implements OnInit {
  private readonly GRID_KEY = "userManagementGrid";

  private readonly AUTHORIZED_USERS_NAME = this.translocoService.translate("user-management.authorized_users");
  private readonly ADMINS_NAME = this.translocoService.translate("user-management.admins");

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  private columnDefs: ColDef<UserManagementData>[] = [
    {
      field: "organizationId",
      filter: "agTextColumnFilter",
      colId: "organization",
      rowGroup: true,
      hide: true,
      valueFormatter: (params) => {
        return params.node?.allLeafChildren[0].data?.organizationName ?? "";
      },
    },
    {
      field: "gridSection",
      headerName: this.translocoService.translate("dsp-stats.creativeType"),
      colId: "gridSectionCol",
      rowGroup: true,
      hide: true,
      valueFormatter: (params) => (params.value === "admins" ? this.ADMINS_NAME : this.AUTHORIZED_USERS_NAME),
    },

    {
      field: "email",
      headerName: this.translocoService.translate("manage-authorized-access-modal.email"),
      filter: "agTextColumnFilter",
    },
    { field: "role", headerName: this.translocoService.translate("user-management.role") },
    {
      headerName: this.translocoService.translate("user-management.access"),
      filter: "agNumberColumnFilter",

      valueGetter: (params: ValueGetterParams<UserManagementData>) => {
        if (!params.node?.group && params.node?.data?.gridSection === "authorizedUsers") {
          const data: UserManagementData = params.node?.data;
          return this.authorizedAccessUserMap.get(data.organizationId + "_" + data.m19UserId)?.length;
        }
        return undefined;
      },
      valueFormatter: (params: ValueFormatterParams<UserManagementData>) => {
        if (params.value) {
          return params.value + " account" + (params.value > 1 ? "s" : "");
        }
        return "";
      },
    },
    {
      pinned: "right",
      colId: "actionCol",
      suppressHeaderMenuButton: true,
      filter: false,
      floatingFilter: false,
      cellRendererSelector: (params: ICellRendererParams<UserManagementData>) => {
        if (
          params.node.group &&
          params.node.rowGroupColumn?.getColId() === "gridSectionCol" &&
          this.orgAccessLevel.get(+params!.node.parent!.key!) === AccessLevel.ADMIN
        ) {
          const orgActiveSub: boolean = !!this.orgHasActiveSub.get(+params!.node.parent!.key!);
          return {
            component: ActionButtonsComponent,
            params: {
              actionButtons: [
                {
                  text:
                    params.node.key === "admins"
                      ? this.translocoService.translate("user-management.add_admins")
                      : this.translocoService.translate("user-management.add_authorized_users"),
                  tooltip:
                    params.node.key === "authorizedUsers" || orgActiveSub
                      ? ""
                      : this.translocoService.translate("user-management.upgrade_to_add_admins"),
                  disabled: !orgActiveSub,
                  onClick: (params: any) => {
                    if (params.node.key === "admins")
                      this.openAddAdminModal(+params.node.parent.key, params.node.parent.allLeafChildren.length);
                    else if (params.node.key === "authorizedUsers")
                      this.openAddNewAuthorizedUserModal(
                        +params.node.parent.key,
                        params.node.allLeafChildren.map((node: IRowNode) => node.data),
                      );
                  },
                },
              ],
            },
          };
        }

        if (
          params.node.group &&
          params.node.rowGroupColumn?.getColId() === "organization" &&
          this.orgAccessLevel.get(+params.node.key!) === AccessLevel.ADMIN &&
          this.orgHasActiveSub.get(+params.node.key!)
        ) {
          return {
            component: ActionButtonsComponent,
            params: {
              actionButtons: [
                {
                  text: this.translocoService.translate("user-management.add_authorized_users"),
                  onClick: (params: any) =>
                    this.openAddNewAuthorizedUserModal(
                      +params.node.key,
                      params.node.allLeafChildren.map((node: IRowNode) => node.data),
                    ),
                },
              ],
            },
          };
        }

        const nodeData: UserManagementData | undefined = params.node.data;
        if (nodeData && nodeData.gridSection === "admins" && nodeData.ownerId !== nodeData.m19UserId) {
          return {
            component: ActionButtonsComponent,
            params: {
              actionButtons: [
                {
                  icon: ICON_TRASH_O,
                  tooltip: "Revoke admin access",
                  onClick: () => {
                    this.revokeAdminAccess(nodeData);
                  },
                },
              ],
            },
          };
        } else if (nodeData && nodeData.gridSection === "authorizedUsers") {
          return {
            component: ActionButtonsComponent,
            params: {
              actionButtons: [
                {
                  icon: ICON_TRASH_O,
                  tooltip: "Revoke all access",
                  disabled: !this.orgHasActiveSub.get(params.node.data!.organizationId!),
                  onClick: (params: any) => {
                    this.revokeAuthorizedAccess(params.node.data.organizationId, params.node.data.m19UserId);
                  },
                },
                {
                  icon: ICON_USER_KEY,
                  tooltip: "Manage access",
                  disabled: !this.orgHasActiveSub.get(params.node.data!.organizationId!),
                  onClick: (params: ICellRendererParams<UserManagementData>) => {
                    this.openManageAuthorizedUserModal(params.node.data!.organizationId!, params.node.data!.m19UserId!);
                  },
                },
              ],
            },
          };
        }
        return undefined;
      },
    },
  ];

  private autoGroupColDef: ColDef = {
    field: "userName",
    headerName: this.translocoService.translate("user-management.organization_role_name"),
    filter: "agTextColumnFilter",
    filterValueGetter: (params: ValueGetterParams<UserManagementData>) => {
      // concat organization, role, user name to enable filtering on all column values
      return params.data?.organizationName + " " + params.data?.gridSection + " " + params.data?.userName;
    },
  };
  readonly defaultColDefs: ColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
    flex: 1,
    suppressMovable: true,
    menuTabs: ["filterMenuTab"],
  };

  gridOptions: GridOptions = {
    ...getBasicGridOptions(this.GRID_KEY),
    sideBar: false,
    autoGroupColumnDef: this.autoGroupColDef,
    defaultColDef: this.defaultColDefs,
    columnDefs: this.columnDefs,
    enableRangeSelection: false,
    showOpenedGroup: false,
    groupDisplayType: "singleColumn",
    context: { parentComponent: this },
    onFirstDataRendered: (event: FirstDataRenderedEvent) => {
      // expand first row
      const firstNode: IRowNode = event.api.getDisplayedRowAtIndex(0)!;
      event.api.setRowNodeExpanded(firstNode, true);

      // and its first child if exists
      const children: IRowNode[] = firstNode.childrenAfterFilter!;
      if (children.length > 0) event.api.setRowNodeExpanded(children[0], true);
    },
    onRowGroupOpened: (event: RowGroupOpenedEvent) => {
      event.api.autoSizeColumns(["actionCol", "ag-Grid-AutoColumn-gridSectionCol"]);
    },
    getRowId: (params) => params.data.rowId,
  };

  allOrganizations: OrganizationAccountGroups[] = [];
  private board?: string;
  private orgAccessLevel!: Map<number, AccessLevel | undefined>;
  private orgHasActiveSub!: Map<number, boolean>;
  private authorizedAccessUserMap = new Map<string, AuthorizedAccess[]>();
  private orgAdminEmails = new Map<number, string[]>();
  private orgUserEmails = new Map<number, string[]>();

  constructor(
    private accountGroupService: OrganizationAccountGroupService,
    private authService: AuthService,
    private organizationUsersService: OrganizationUsersService,
    private toasterService: ToastrService,
    private modalService: BsModalService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.board = user.board;
    });

    combineLatest(
      this.organizationUsersService.listOrganizations(),
      this.organizationUsersService.listAuthorizedAccess(),
      this.accountGroupService.allOrganizationAccountGroups$,
      this.authService.loggedUser$,
    )
      .pipe(untilDestroyed(this))
      .subscribe(([orgsUsers, authAccess, allOrgs, loggedUser]) => {
        this.orgAccessLevel = new Map();
        this.orgHasActiveSub = new Map();
        this.authorizedAccessUserMap = new Map();
        this.allOrganizations = allOrgs ?? [];
        const userManagementData: UserManagementData[] = [];

        const orgIds = new Set<number>();

        orgsUsers.forEach((user: OrganizationUser) => {
          const org: OrganizationAccountGroups | undefined = allOrgs?.find((org) => org.id === user.organizationId);
          if (!org) {
            // remove useless Personal Free Plan
            return;
          }

          if (!this.orgAdminEmails.has(user.organizationId!)) this.orgAdminEmails.set(user.organizationId!, []);
          this.orgAdminEmails.get(user.organizationId!)!.push(user.email!);

          const adminData: UserManagementData = {
            ...user,
            rowId: org.id + "_" + "Admins" + "_" + user.m19UserId,
            gridSection: "admins",
            organizationName: org?.organizationName,
            role: user.m19UserId === user.ownerId ? "Owner" : "Admin",
            isAdminOrOwner: user.m19UserId === loggedUser.userId,
          };

          if (!orgIds.has(user.organizationId!)) {
            orgIds.add(user.organizationId!);

            authAccess
              .filter((a) => a.organizationId === org?.id)
              .forEach((a: AuthorizedAccess) => {
                if (!this.authorizedAccessUserMap.has(a.organizationId + "_" + a.m19UserId)) {
                  this.authorizedAccessUserMap.set(a.organizationId + "_" + a.m19UserId, []);
                  userManagementData.push({
                    ...a,
                    rowId: org.id + "_" + "AuthUser_" + a.m19UserId,
                    organizationName: org?.organizationName,
                    gridSection: "authorizedUsers",
                  });
                }
                this.authorizedAccessUserMap.get(a.organizationId + "_" + a.m19UserId)!.push(a);
                if (this.orgUserEmails.has(a.organizationId!)) {
                  this.orgUserEmails.get(a.organizationId!)!.push(a.email!);
                } else {
                  this.orgUserEmails.set(a.organizationId!, [a.email!]);
                }
              });
          }
          this.orgAccessLevel.set(org?.id, org?.accessLevel);
          this.orgHasActiveSub.set(org?.id, org?.hasActiveSubscription());
          userManagementData.push(adminData);
        });
        this.agGrid?.api.setRowData(userManagementData);
      });
  }

  private revokeAdminAccess(admin: UserManagementData): void {
    this.organizationUsersService.revokeAdminAccess(
      admin.organizationId!,
      admin.m19UserId!,
      () => {
        this.toasterService.success(
          "Successfully revoked admin access from " + admin.email + " in " + admin.name,
          "Admin access revoked",
        );
      },
      (error) => this.toasterService.error(error.response.message, "Admin access revocation error"),
    );
  }

  openManageAuthorizedUserModal(orgId: number, authorizedUserId: number): void {
    const relatedOrg: OrganizationAccountGroups | undefined = this.allOrganizations.find((o) => o.id === orgId);

    if (!relatedOrg || !this.orgHasActiveSub.get(relatedOrg.id)) return;

    const email = this.authorizedAccessUserMap.get(orgId + "_" + authorizedUserId)![0].email;
    const userName = this.authorizedAccessUserMap.get(orgId + "_" + authorizedUserId)![0].userName;
    const authorizedAccess = new Map(
      this.authorizedAccessUserMap.get(orgId + "_" + authorizedUserId)!.map((x) => [x.profileId, x]),
    );
    const modalOptions: ModalOptions = {
      initialState: {
        isNewUser: false,
        defaultEmail: email,
        userName: userName,
        board: this.board,
        initialAuthorizedOrganizationProfileIds: authorizedAccess,
        organizationId: orgId,
        organizationName: relatedOrg.organizationName,
      },
      class: "modal-plain",
    };
    this.modalService.show(ManageAuthorizedAccessModalComponent, modalOptions);
  }

  private revokeAuthorizedAccess(orgId: string, authorizedUserId: number): void {
    const email = this.authorizedAccessUserMap.get(orgId + "_" + authorizedUserId)![0].email;
    const access = this.authorizedAccessUserMap.get(orgId + "_" + authorizedUserId)!;

    this.organizationUsersService.revokeAuthorizedAccessAsync(access).subscribe({
      next: () => {
        this.toasterService.success(
          "Successfully revoked access from " + email + " to " + access.length + " accounts",
          "Admin access revoked",
        );
      },
      error: (error) => {
        this.toasterService.error(error, "Admin access revocation error");
      },
    });
  }

  openAddAdminModal(orgId: number, nbUsers: number): void {
    const relatedOrg: OrganizationAccountGroups | undefined = this.allOrganizations.find((o) => o.id === orgId);

    if (!relatedOrg || (!relatedOrg.hasActiveSubscription() && !relatedOrg.parentOrganizationWhenOnwer)) return;
    if (this.showUserLimitationModal(relatedOrg, nbUsers)) {
      const modalOptions: ModalOptions = {
        initialState: {
          userLimit: relatedOrg.getBillingPlan()?.userLimit,
        },
      };
      this.modalService.show(UserLimitationModalComponent, modalOptions);
      return;
    }
    const modalOptions: ModalOptions = {
      initialState: {
        organizationId: relatedOrg.id,
        organizationName: relatedOrg.organizationName,
      },
    };
    this.modalService.show(AddAdminModalComponent, modalOptions);
  }

  private openAddNewAuthorizedUserModal(orgId: number, orgUsers: OrganizationUser[]): void {
    const relatedOrg: OrganizationAccountGroups | undefined = this.allOrganizations.find((o) => o.id === orgId);
    const orgUserLength: number = orgUsers.length;

    if (!relatedOrg || (!relatedOrg.hasActiveSubscription() && !relatedOrg.parentOrganizationWhenOnwer)) return;
    if (this.showUserLimitationModal(relatedOrg, orgUserLength)) {
      const modalOptions: ModalOptions = {
        initialState: {
          userLimit: relatedOrg.getBillingPlan()?.userLimit,
        },
      };
      this.modalService.show(UserLimitationModalComponent, modalOptions);
      return;
    }
    const userEmails = orgUsers.map((user) => user.email);
    const adminsEmails = this.orgAdminEmails.get(orgId) ?? [];
    const modalOptions: ModalOptions = {
      initialState: {
        isNewUser: true,
        board: this.board,
        organizationId: relatedOrg.id,
        organizationName: relatedOrg.organizationName,
        adminsEmails: adminsEmails,
        authorizedUserEmails: userEmails,
      },
      class: "modal-plain",
    };
    this.modalService.show(ManageAuthorizedAccessModalComponent, modalOptions);
  }

  private showUserLimitationModal(org: OrganizationAccountGroups, orgUserLength: number): boolean {
    return !!(
      !org.parentOrganizationWhenOnwer &&
      org.getBillingPlan()!.userLimit! &&
      orgUserLength >= org.getBillingPlan()!.userLimit!
    );
  }
}
