@if (wrapped() || isSmallScreen()) {
  <ISelect
    [options]="dropdownOptions"
    [selected]="selected"
    (selectedChange)="toggleMarketplaceOption($event)"
    class="w-52"
    searchable
    popperWidth="230px"
  />
} @else {
  <div class="flex flex-wrap gap-2">
    @for (entry of selection | keyvalue; track entry) {
      <IButton
        [label]="entry.key"
        [variant]="entry.value ? 'solid' : 'outline'"
        (onClick)="toggleMarketplace(entry.key)"
      />
    }
  </div>
}
