import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { AlgoMode, Currency, Strategy } from "@front/m19-api-client";
import { AlgoTarget, StrategyUpdateParams } from "@front/m19-models";
import { CurrencyService, StrategyService, TacosStrategiesService } from "@front/m19-services";
import { currencyRateToEuro, Utils } from "@front/m19-utils";
import { TranslocoService } from "@jsverse/transloco";
import { ICON_SYNC, ICON_TRASH_O } from "@m19-board/utils/iconsLabels";
import moment from "moment-timezone";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { take, tap } from "rxjs";

@Component({
  selector: "app-switch-target-algo-modal",
  templateUrl: "./switch-target-algo-modal.component.html",
  styleUrls: ["./switch-target-algo-modal.component.scss"],
  providers: [DatePipe],
})
export class SwitchTargetAlgoModalComponent implements OnInit {
  @Input({ required: true }) locale!: string;

  @Input({ required: true }) algoTarget!: AlgoTarget;
  @Input({ required: true }) minBid!: number;
  @Input({ required: true }) organizationId!: number;

  updateLoading = false;
  currencySymbol!: string;
  currencyCode!: Currency;

  tacosTarget?: number;
  acosTarget?: number;
  suggestedBid?: number;
  dailyBudget?: number | undefined;
  monthlyBudget?: number;
  nextMonthlyBudget?: number;
  nextMonth?: Date;
  endOfCurrentMonth?: Date;
  currentMonthSpend?: number;
  protected readonly AlgoMode = AlgoMode;
  protected readonly Math = Math;

  readonly ICON_TRASH = ICON_TRASH_O;
  readonly ICON_SYNC = ICON_SYNC;

  constructor(
    public bsModalRef: BsModalRef,
    private toasterService: ToastrService,
    private currencyService: CurrencyService,
    private translocoService: TranslocoService,
    private strategyService: StrategyService,
    private datePipe: DatePipe,
    private tacosStrategyService: TacosStrategiesService,
  ) {}

  ngOnInit(): void {
    this.acosTarget = this.algoTarget.acosTarget;
    this.dailyBudget = this.algoTarget.dailyBudget;
    this.suggestedBid = this.algoTarget.suggestedBid;
    this.monthlyBudget = this.algoTarget.monthlyBudget;
    this.nextMonthlyBudget = this.algoTarget.nextMonthlyBudget;
    this.tacosTarget = this.algoTarget.tacosTarget;

    this.strategyService
      .getStrategyCurrentMonthSpend(this.algoTarget.accountId, this.algoTarget.marketplace, this.algoTarget.strategyId)
      .pipe(take(1))
      .subscribe((spend) => {
        this.currentMonthSpend = spend;
      });
    const today = moment(this.algoTarget.today, "YYYY-MM-DD");
    this.endOfCurrentMonth = today.endOf("month").toDate();
    this.nextMonth = today.startOf("month").add(1, "M").toDate();

    this.currencySymbol = this.currencyService.getCurrencySymbolFromMarketplace(this.algoTarget.marketplace);
    this.currencyCode = this.currencyService.getCurrencyCode(this.algoTarget.marketplace);
  }

  getTargetName(): string {
    switch (this.algoTarget.algorithm) {
      case AlgoMode.ACOS_TARGET:
        return this.translocoService.translate("algo-target-renderer.acos_target");
      case AlgoMode.TACOS_TARGET:
        return this.translocoService.translate("algo-target-renderer.tacos_target");
      case AlgoMode.PRODUCT_LAUNCH:
        return this.translocoService.translate("algo-target-renderer.suggested_bid");
      case AlgoMode.MONTHLY_BUDGET_TARGET:
        return this.translocoService.translate("metrics.MONTHLY_BUDGET_tooltip");
    }
  }

  deleteDailyBudget() {
    this.dailyBudget = undefined;
    this.update();
  }

  update(): void {
    const updateParams: StrategyUpdateParams = {
      accountId: this.algoTarget.accountId,
      marketplace: this.algoTarget.marketplace,
      organizationId: this.organizationId,
      strategyId: this.algoTarget.strategyId,
      asinsToAdd: [],
      asinsToDelete: [],
    };

    if (this.algoTarget.algorithm === AlgoMode.ACOS_TARGET) {
      updateParams.acosTarget = this.acosTarget;
      if (this.dailyBudget && this.dailyBudget < this.getDailyBudgetLowerBound()) return;

      updateParams.dailyBudget = this.dailyBudget;
      this.updateLoading = true;
      this.updateStrategy(updateParams);
    }
    if (this.algoTarget.algorithm === AlgoMode.PRODUCT_LAUNCH) {
      if (!this.suggestedBid || this.suggestedBid < this.minBid) return;
      updateParams.suggestedBid = this.suggestedBid;
      updateParams.dailyBudget = this.dailyBudget;
      this.updateLoading = true;
      this.updateStrategy(updateParams);
    }
    if (this.algoTarget.algorithm === AlgoMode.MONTHLY_BUDGET_TARGET) {
      this.updateLoading = true;
      this.updateMonthlyBudget();
    }
    if (this.algoTarget.algorithm === AlgoMode.TACOS_TARGET) {
      this.updateLoading = true;
      this.updateTacosTarget(this.algoTarget.strategyId!, this.tacosTarget!);
    }
  }

  updateTacosTarget(strategyId: number, tacosTarget: number) {
    this.tacosStrategyService
      .updateTacosStrategyGroup({
        accountId: this.algoTarget.accountId,
        marketplace: this.algoTarget.marketplace,
        tacosTarget,
        tacosStrategyGroupId: strategyId!,
      })
      .subscribe({
        next: (ts) => {
          this.toasterService.success(`TACOS target updated`);
          this.bsModalRef.hide();
          this.updateLoading = false;
        },
        error: (e) => {
          this.toasterService.error(`Error updating TACOS target: ${e}`);
          this.bsModalRef.hide();
          this.updateLoading = false;
        },
      });
  }

  updateStrategy(updateParams: StrategyUpdateParams) {
    this.strategyService
      .updateStrategy(updateParams)
      .pipe(tap(() => (this.updateLoading = true)))
      .subscribe({
        next: (strategy: Strategy) => {
          this.toasterService.success(`${this.getTargetName()} updated for strategy ${strategy.name}`);

          // prevent close on daily budget deletion
          if (!this.algoTarget.dailyBudget || updateParams.dailyBudget) {
            this.bsModalRef.hide();
          }
          this.updateLoading = false;
        },
        error: (e) => {
          this.toasterService.error(`Error updating ${this.getTargetName()}: ${e}`);
          this.bsModalRef.hide();
          this.updateLoading = false;
        },
      });
  }

  updateMonthlyBudget() {
    this.strategyService
      .updateStrategyMonthlyBudget(
        this.algoTarget.accountId,
        this.algoTarget.marketplace,
        this.algoTarget.strategyId,
        this.monthlyBudget!,
        this.nextMonthlyBudget!,
        Utils.formatMonthForApi(this.endOfCurrentMonth!),
      )
      .pipe(tap(() => (this.updateLoading = true)))
      .subscribe({
        next: (strategy: Strategy) => {
          this.toasterService.success(`Monthly budget updated for strategy ${strategy.name}`, "Strategy updated");
          this.bsModalRef.hide();
          this.updateLoading = false;
        },
        error: (e) => {
          this.toasterService.error(`Error when updating Monthly budget: ${e}`, "Strategy update error");
          this.bsModalRef.hide();
          this.updateLoading = false;
        },
      });
  }

  minAllowedBid() {
    const result = 0.1 / currencyRateToEuro(this.currencyCode as Currency);
    return Math.floor(result * 100 + Number.EPSILON) / 100;
  }

  getDailyBudgetLowerBound(): number {
    return Math.ceil(1 / currencyRateToEuro(this.currencyCode as Currency));
  }

  getMaxSuggestedBid(dailyBudget: number) {
    return Math.floor((dailyBudget / 5) * 100 + Number.EPSILON) / 100;
  }

  getEndOfMonth() {
    return this.translocoService.translate("switch-target-algo-modal.until_end_of", [
      this.datePipe.transform(
        this.endOfCurrentMonth,
        "longDate",
        undefined,
        this.translocoService.getActiveLang() != "dev" ? this.translocoService.getActiveLang() : "en",
      ),
    ]);
  }

  getNextMonth() {
    return this.translocoService.translate("switch-target-algo-modal.for_next_month", [
      this.datePipe.transform(
        this.nextMonth,
        "MMMM y",
        undefined,
        this.translocoService.getActiveLang() != "dev" ? this.translocoService.getActiveLang() : "en",
      ),
    ]);
  }
}
