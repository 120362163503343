import { Component, computed, inject, OnInit, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StatsComponent, StatsComponentGridConfig, StatsComponentMetricConfig } from "@m19-board/stats/stats.component";
import { AdStatsEx, BrandingFilter, ProductGroupEx, StrategyEx } from "@front/m19-models";
import {
  AccountSelectionService,
  AsinService,
  AuthService,
  CatalogBrandService,
  DataSet,
  ProductGroupService,
  SbStrategiesService,
  SdStrategiesService,
  SpStrategiesService,
  StatsApiClientService,
  StrategyService,
  UserSelectionService,
} from "@front/m19-services";
import { BehaviorSubject, combineLatest, forkJoin, map, of, switchMap, tap } from "rxjs";
import {
  AggregationFunction,
  convertToCurrency,
  DateAggregation,
  emptyAdStatEx,
  emptyVendorAdStatEx,
  mergeSeveralDates,
  mergeVendorAdStatsExByAsin,
  MetricsSelectorLocalStorageKey,
} from "@front/m19-utils";
import { toSignal } from "@angular/core/rxjs-interop";
import {
  VENDOR_AGED90P_SELLABLE_COST,
  VENDOR_AGED90P_SELLABLE_UNITS,
  VENDOR_OPEN_PURCHASE_ORDER_UNITS,
  VENDOR_OUT_OF_STOCK_VIEWS,
  VENDOR_SELLABLE_COST,
  VENDOR_SELLABLE_UNITS,
  VENDOR_SOROOS,
  VENDOR_UNSELLABLE_COST,
  VENDOR_UNSELLABLE_UNITS,
} from "@m19-board/models/MetricsDef";
import {
  ColDef,
  ColGroupDef,
  GridOptions,
  ICellRendererParams,
  IRowNode,
  ITooltipParams,
} from "@ag-grid-community/core";
import { CurrencyColumn, ImageColumn } from "@m19-board/grid-config/grid-columns";
import { getMetricsColDef } from "@m19-board/grid-config/grid-config";
import { Metric, MetricRegistry } from "@front/m19-metrics";
import { MetricEvoComponent } from "@m19-board/metric-evo/metric-evo.component";
import { TranslocoService } from "@jsverse/transloco";
import {
  getBasicGridOptions,
  getFilteredLeafNodes,
  PARENT_ASIN_COL_NAME,
  ProductGridData,
} from "@front/m19-grid-config";
import { AccountMarketplace, Currency, Marketplace, Strategy } from "@front/m19-api-client";
import { ProductThumbnailComponent } from "@m19-board/product-view/product-thumbnail.component";
import { AsinLinkComponent } from "@m19-board/product-view/asin-link.component";
import { ActionButtonsComponent } from "@m19-board/insights/overview/action-buttons/action-buttons.component";
import { ICON_CHART_LINE } from "@m19-board/utils/iconsLabels";
import { ChartData, ChartRendererComponent } from "@m19-board/shared/chart-renderer/chart-renderer.component";
import { AgGridAngular } from "@ag-grid-community/angular";
import { BsModalService } from "ngx-bootstrap/modal";
import { Option } from "@front/m19-ui";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AsinFilter } from "@m19-board/shared/filter/asinFilter";
import { Filter } from "@m19-board/shared/filter/filter.component";

@Component({
  selector: "app-vendor-inventory",
  standalone: true,
  imports: [CommonModule, StatsComponent],
  templateUrl: "./vendor-inventory.component.html",
})
@UntilDestroy()
export class VendorInventoryComponent implements OnInit {
  private readonly statsApiClient = inject(StatsApiClientService);
  private readonly userSelectionService = inject(UserSelectionService);
  private readonly accountSelection = inject(AccountSelectionService);
  private readonly authService = inject(AuthService);
  private readonly asinService = inject(AsinService);
  private readonly translocoService = inject(TranslocoService);
  private readonly modalService = inject(BsModalService);

  private readonly METRICS = [
    VENDOR_SELLABLE_COST,
    VENDOR_SELLABLE_UNITS,
    VENDOR_UNSELLABLE_UNITS,
    VENDOR_UNSELLABLE_COST,
    VENDOR_OPEN_PURCHASE_ORDER_UNITS,
    VENDOR_AGED90P_SELLABLE_UNITS,
    VENDOR_AGED90P_SELLABLE_COST,
    VENDOR_SOROOS,
    VENDOR_OUT_OF_STOCK_VIEWS,
  ];
  protected readonly localStorageKey: MetricsSelectorLocalStorageKey = MetricsSelectorLocalStorageKey.vendorInventory;
  loading = signal(true);
  selectedMetrics = signal<Metric<AdStatsEx>[]>([]);
  selectedDateRange = toSignal(this.userSelectionService.dateRange$, { requireSync: true });

  grid = signal<AgGridAngular | undefined>(undefined);

  private readonly _dataSet = this.initDataset();
  private readonly asinDataset = this.initDataset();

  productGroups: ProductGroupEx[] = [];
  strategies: StrategyEx[] = [];
  customField1Values: Option<string>[] = [];
  customField2Values: Option<string>[] = [];
  customFiel1dMap: Map<string, string[]> = new Map();
  customField2Map: Map<string, string[]> = new Map();
  brandTrafficOptions: Option<BrandingFilter>[] = [];
  stratAsinMap: Map<number, string[]> = new Map();
  asinStratMap: Map<string, Strategy[]> = new Map();

  filter$ = new BehaviorSubject<Set<string> | null>(null);
  filterSignal = toSignal(this.filter$, { requireSync: true });
  filter: Set<string> | null = null;
  asinFilterTags = new AsinFilter(this.translocoService);
  filters = this.asinFilterTags.getFilterTags();

  private dailyInventory$ = combineLatest([
    this.accountSelection.singleAccountMarketplaceSelection$,
    this.userSelectionService.dateRange$,
    this.userSelectionService.selectedCurrency$,
  ]).pipe(
    tap(() => this.loading.set(true)),
    switchMap(([am, dr, currency]) =>
      forkJoin([
        this.statsApiClient.getDailyVendorInventory({
          accountId: am.accountId,
          marketplace: am.marketplace,
          minDate: dr[0],
          maxDate: dr[1],
          useSourcingMetrics: !!am.useSourcingMetrics,
        }),
        of(currency),
        of(am.marketplace),
      ]),
    ),
    map(([data, currency, marketplace]) => convertToCurrency(data, currency, marketplace) ?? []),
    tap(() => this.loading.set(false)),
  );
  dailyInventory = toSignal<AdStatsEx[]>(this.dailyInventory$);
  dailyInventoryByAsin = computed(() => mergeVendorAdStatsExByAsin(this.dailyInventory() ?? []));

  totalInventorySales = computed(() => {
    this.filterSignal();
    if (!this.dailyInventory()?.length) return emptyVendorAdStatEx();
    return this.dailyInventory()
      ?.filter((data) => AsinFilter.asinMatchesFilter(data.asin ?? "", this.filter$.value))
      .reduce<AdStatsEx>((acc, curr) => mergeSeveralDates(acc, curr), emptyAdStatEx());
  });
  dataSet = computed(() => {
    this.filterSignal();
    this._dataSet.currency = this.currency();

    this._dataSet.buildDataSet(
      this.dailyInventory()?.filter((data) => AsinFilter.asinMatchesFilter(data.asin ?? "", this.filter$.value)) ?? [],
      this.selectedMetrics(),
      this.dateAggregation(),
      {
        minDate: this.selectedDateRange()[0],
        maxDate: this.selectedDateRange()[1],
      },
    );
    return this._dataSet;
  });

  currency = toSignal(this.userSelectionService.selectedCurrency$, { requireSync: true });
  accountMarketplace = toSignal(this.accountSelection.singleAccountMarketplaceSelection$);
  locale = toSignal(this.authService.loggedUser$.pipe(map((u) => u.locale)), { initialValue: "" });
  catalog = toSignal(
    this.accountSelection.singleAccountMarketplaceSelection$.pipe(
      switchMap((am) => this.asinService.getCatalog(am.accountId, am.marketplace)),
    ),
  );

  dateAggregation = signal<DateAggregation>(DateAggregation.daily);
  metricsConfig = computed<StatsComponentMetricConfig<AdStatsEx>>(() => {
    return {
      data: this.totalInventorySales(),
      metrics: this.METRICS,
    };
  });
  gridConfig = computed<StatsComponentGridConfig<AdStatsEx>>(() => {
    return {
      gridData: this.dailyInventoryByAsin(),
      columnDefs: this.getColumnDefs(this.currency(), this.locale(), this.accountMarketplace()?.marketplace),
      gridOptions: this.getGridOptions(),
    };
  });

  constructor(
    private productGroupService: ProductGroupService,
    private strategyService: StrategyService,
    private catalogBrandService: CatalogBrandService,
    private spStrategiesService: SpStrategiesService,
    private sbStrategiesService: SbStrategiesService,
    private sdStrategiesService: SdStrategiesService,
  ) {}

  ngOnInit() {
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am: AccountMarketplace) =>
          combineLatest([
            this.productGroupService.getProductGroups(am.accountId, am.marketplace),
            this.strategyService.getStrategyIndex(am.accountId, am.marketplace),
            this.catalogBrandService.getBrandingFilters(am.accountId, am.marketplace),
          ]),
        ),
        untilDestroyed(this),
      )
      .subscribe(([pgs, strategies, brandingFilters]) => {
        this.strategies = Array.from(strategies.values()).map((s) => new StrategyEx(s));
        this.productGroups = pgs;
        this.brandTrafficOptions = brandingFilters.map((b) => ({ label: b.name ?? "", value: b }));
        this.asinFilterTags.setProductGroups(this.productGroups);
        this.asinFilterTags.setStrategies(this.strategies);
        this.asinFilterTags.setBrandTrafficOptions(this.brandTrafficOptions);
      });
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am: AccountMarketplace) => {
          return combineLatest([
            this.spStrategiesService.getSPStrategiesPerAsin(am.accountId, am.marketplace),
            this.sbStrategiesService.getSBStrategiesPerAsin(am.accountId, am.marketplace),
            this.sdStrategiesService.getSDStrategiesPerAsin(am.accountId, am.marketplace),
            this.asinService.getCatalog(am.accountId, am.marketplace),
          ]);
        }),
        switchMap(([spAsinStratIndex, sbAsinStratIndex, sdAsinStratIndex, catalog]) => {
          const customField1Values = new Set<string>();
          const customField2Values = new Set<string>();
          for (const product of catalog.products) {
            if (product.customField1) customField1Values.add(product.customField1);
            if (product.customField2) customField2Values.add(product.customField2);
          }
          this.customField1Values = Array.from(customField1Values).map((v) => ({ label: v, value: v }));
          this.customField2Values = Array.from(customField2Values).map((v) => ({ label: v, value: v }));
          // map custom field values to asin
          this.customFiel1dMap.clear();
          this.customField2Map.clear();
          for (const product of catalog.products) {
            if (product.customField1) {
              if (!this.customFiel1dMap.has(product.customField1)) {
                this.customFiel1dMap.set(product.customField1, []);
              }
              this.customFiel1dMap.get(product.customField1)!.push(product.asin!);
            }

            if (product.customField2) {
              if (!this.customField2Map.has(product.customField2)) {
                this.customField2Map.set(product.customField2, []);
              }
              this.customField2Map.get(product.customField2)!.push(product.asin!);
            }
          }
          this.asinFilterTags.setCustomField1Values(this.customField1Values);
          this.asinFilterTags.setCustomField2Values(this.customField2Values);
          return of([spAsinStratIndex, sbAsinStratIndex, sdAsinStratIndex]);
        }),
      )
      .subscribe(([spAsinStratIndex, sbAsinStratIndex, sdAsinStratIndex]) => {
        this.asinStratMap.clear();
        this.stratAsinMap = new Map();
        for (const [asin, strats] of [...spAsinStratIndex, ...sbAsinStratIndex, ...sdAsinStratIndex]) {
          if (this.asinStratMap.has(asin)) {
            this.asinStratMap.get(asin)!.push(...strats);
          } else {
            this.asinStratMap.set(asin, [...strats]);
          }
          for (const s of strats) {
            if (!this.stratAsinMap.has(s.strategyId!)) {
              this.stratAsinMap.set(s.strategyId!, []);
            }
            this.stratAsinMap.get(s.strategyId!)!.push(asin);
          }
        }
      });
  }

  private initDataset(): DataSet<AdStatsEx> {
    return new DataSet<AdStatsEx>(
      3,
      [VENDOR_SELLABLE_COST, VENDOR_SELLABLE_UNITS],
      [AggregationFunction.sumAdStats],
      this.translocoService,
    );
  }

  private getColumnDefs(currency?: Currency, locale?: string, marketplace?: Marketplace): (ColDef | ColGroupDef)[] {
    if (!currency || !locale || !marketplace) return [];

    return [
      {
        headerName: this.translocoService.translate("sales-advertising.product_info", {}, "en"),
        headerValueGetter: () => this.translocoService.translate("sales-advertising.product_info"),
        marryChildren: true, // prevent column group break
        pinned: "left",
        lockPinned: true,
        children: [
          {
            ...ImageColumn,
            headerName: this.translocoService.translate("catalog-page.image", {}, "en"),
            headerValueGetter: () => this.translocoService.translate("catalog-page.image"),
            cellRendererSelector: (params) => {
              if (!params.value) return undefined;
              else {
                return {
                  component: ProductThumbnailComponent,
                  params: {
                    asin: params.value,
                    smallImg: true,
                    marketplace: marketplace,
                    customSizeClass: "size-16",
                  },
                };
              }
            },
          },
          {
            headerName: this.translocoService.translate("common.product", {}, "en"),
            headerValueGetter: () => this.translocoService.translate("common.product"),
            pinned: "left",
            colId: "asin_col",
            field: "asin",
            floatingFilter: true,
            filter: "agTextColumnFilter",
            cellRendererSelector: (params) => {
              if (params.node.group) return undefined;
              return {
                component: AsinLinkComponent,
                params: {
                  asin: params.value,
                  marketplace: marketplace,
                },
              };
            },
          },
          {
            headerName: this.translocoService.translate("common.title", {}, "en"),
            headerValueGetter: (params) => this.translocoService.translate("common.title"),
            pinned: "left",
            maxWidth: 400,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            valueGetter: (params) =>
              this.catalog()
                ?.getProductTitles()
                ?.get(params.node?.group ? params.node.key : params.data.asin) ?? "-",
            tooltipValueGetter: (p: ITooltipParams) => p.value,
            cellClass: "sensitive-data",
          },
          {
            field: "parentAsin",
            colId: PARENT_ASIN_COL_NAME,
            headerName: this.translocoService.translate("product360.parent_asin", {}, "en"),
            headerValueGetter: () => this.translocoService.translate("product360.parent_asin"),
            hide: true,
            pinned: "left",
            enableRowGroup: true,
            valueGetter: (params) => {
              return (
                this.catalog()?.getParentAsin(params.node?.group ? params.node.key : params.data.asin) ??
                params.data?.asin
              );
            },
            cellClass: "sensitive-data",
          },
        ],
      },
      ...getMetricsColDef(this.METRICS, true).map((def) => ({
        ...def,
        headerName: this.translocoService.translate(`${def.headerName}`, {}, "en"),
        headerValueGetter: () => {
          return this.translocoService.translate(`${def.headerName}`);
        },
        children: def.children.map((c: ColDef) => {
          const m = MetricRegistry.get(c.colId!)!;

          return {
            ...c,
            headerName: this.translocoService.translate(`metrics.${c.colId}_title`, {}, "en"),
            headerValueGetter: () => this.translocoService.translate(`metrics.${m.id}_title`),
            cellRenderer: MetricEvoComponent,
            cellRendererParams: (params: ICellRendererParams) => {
              return {
                ...c.cellRendererParams(params),
                currency: currency,
                locale: locale,
              };
            },
          };
        }),
      })),
      {
        cellRenderer: ActionButtonsComponent,
        cellRendererParams: (params: any) => {
          const actionButtons = [
            {
              icon: ICON_CHART_LINE,
              tooltipValue: `Display ${params.node.isRowPinned() ? "total" : ""} ASIN chart`,
              color: "white",
              onClick: () => {
                this.openChartModal(params.node);
              },
            },
          ];

          return { actionButtons };
        },
      },
      {
        ...CurrencyColumn,
        valueGetter: () => this.currency(),
      },
    ];
  }

  private openChartModal(node: IRowNode<ProductGridData>) {
    const modalData = this.getModalData(node);

    this.modalService.show(ChartRendererComponent<AdStatsEx>, {
      initialState: {
        marketplace: this.accountMarketplace()?.marketplace,
        dataset: this.asinDataset,
        asin: (node.group ? node.key : node.data?.asin) ?? "",
        metrics: this.METRICS,
        chartData$: of(modalData),
        localStorageKey: MetricsSelectorLocalStorageKey.salesAndAdvertising,
      },
      class: "modal-xxl modal-dialog-centered",
    });
  }

  private getModalData(node: IRowNode<ProductGridData>): ChartData<AdStatsEx> {
    const targetedNodes = getFilteredLeafNodes(node, this.grid()?.api);

    const data: AdStatsEx[] = [];

    targetedNodes.forEach((n) => {
      data.push(...this.dailyInventory()!.filter((a) => a.asin === n.data.asin)!);
    });

    const totalData = data.reduce((acc, curr) => mergeSeveralDates(acc, curr), emptyAdStatEx());
    return { data, totalData };
  }

  private getGridOptions(): GridOptions {
    return {
      ...getBasicGridOptions("vendorInventoryGrid", true),
      autoGroupColumnDef: { pinned: "left" },
      isExternalFilterPresent: () => {
        return true;
      },
      doesExternalFilterPass: (node) => {
        return AsinFilter.asinMatchesFilter(node.data.asin, this.filter$.value);
      },
    };
  }

  filterChanged(filters: Filter<string | ProductGroupEx | BrandingFilter | StrategyEx>[]) {
    this.filter$.next(AsinFilter.filterChanged(filters, this.stratAsinMap, this.customFiel1dMap, this.customField2Map));
    if (!this.grid()?.api.isDestroyed()) {
      this.grid()?.api.onFilterChanged();
      this.grid()?.api.redrawRows();
      this.grid()?.api.refreshCells({ force: true });
      this.grid()?.api.hideOverlay();
    }
  }

  metricsChanged(metrics: Metric<AdStatsEx>[]) {
    this.selectedMetrics.set(metrics);
  }

  dateAggregationChanged(dateAggregation: DateAggregation) {
    this.dateAggregation.set(dateAggregation);
  }
}
