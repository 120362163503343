import { CommonModule, NgClass, NgTemplateOutlet } from "@angular/common";
import { Component, computed, inject, input, model, OnInit, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import { MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrandingFilter, Catalog, ProductGroupEx, StrategyEx } from "@front/m19-models";
import { AccountSelectionService, AsinService, ProductGroupService } from "@front/m19-services";
import { IButtonComponent, ICheckboxComponent, IInputComponent, ModalService } from "@front/m19-ui";
import { TranslocoModule, TranslocoService } from "@jsverse/transloco";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";
import { AsinFilter, FilterType } from "@m19-board/shared/filter/asinFilter";
import { ICON_CHEVRON_DOWN, ICON_SEARCH } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { filter, switchMap } from "rxjs";
import { BulkAsinModalComponent } from "../bulk-asin-modal/bulk-asin-modal.component";
import { Filter, FilterComponent } from "@m19-board/shared/filter/filter.component";

interface AsinSelectionProduct {
  asin: string;
  title: string | undefined;
  brand: string | undefined;
  childAsins: string[] | undefined;
  image: string | undefined;
  tag1?: string | undefined;
  tag2?: string | undefined;
  ineligibleReason?: string | undefined;
  isDisabled: boolean;
  isSelected: boolean;
  isOtherCatalog: boolean;
  isParent: boolean;
  isIndeterminate: boolean; // used for parent checkbox state
}

@UntilDestroy()
@Component({
  selector: "app-asin-selection-v2",
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    NgTemplateOutlet,
    NgClass,
    ICheckboxComponent,
    IInputComponent,
    SpinnerComponent,
    IButtonComponent,
    FilterComponent,
    TranslocoModule,
    MatPaginatorModule,
    FormsModule,
  ],
  templateUrl: "./asin-selection-v2.component.html",
})
export class AsinSelectionV2ComponentComponent implements OnInit {
  // instead of catalog (default), provide custom list of ASINs to select from
  customList = input<string[] | undefined>(undefined);

  disabledAsins = input<Set<string> | undefined>(undefined);

  selectedAsins = model<string[]>([]);

  // allow other catalog ASINs to be selected (used in bulk mode)
  allowOtherCatalog = input<boolean>(false);

  maxSelectedProducts = input<number | undefined>(undefined);

  csvExport = input<boolean>(true);

  // constants
  readonly DEFAULT_PAGE_SIZE = 25;
  readonly NO_IMAGE_URL_PART = "no-img-sm.";
  readonly SEARCH_ICON = ICON_SEARCH;
  readonly CHEVRON_RIGHT_ICON = ICON_CHEVRON_DOWN;

  // services
  private readonly asinService = inject(AsinService);
  private readonly accountService = inject(AccountSelectionService);
  private readonly productGroupService = inject(ProductGroupService);
  private readonly translocoService = inject(TranslocoService);
  private readonly modalService = inject(ModalService);

  // filters
  readonly searchFilter = signal<string>("");
  readonly onlySelectedFilter = signal<boolean>(false);
  readonly productTag1Filter = signal<Set<string> | null>(null);
  readonly productTag2Filter = signal<Set<string> | null>(null);
  readonly brandFilter = signal<Set<string> | null>(null);
  readonly productGroupFilter = signal<ProductGroupEx[] | null>(null);

  readonly asinFilterTags = new AsinFilter(this.translocoService);
  readonly filters = signal<Filter<StrategyEx | ProductGroupEx | BrandingFilter | string>[]>(
    this.asinFilterTags.getFilterTags(),
  );

  // state
  readonly isLoading = computed<boolean>(() => this.catalog() === undefined);
  readonly noCatalog = computed<boolean>(() => this.catalog()?.asinOffers.size === 0);

  readonly groupByParentAsin = signal<boolean>(false);
  readonly openParentAsin = signal<string | undefined>(undefined);

  readonly pagination = signal<PageEvent>({
    pageIndex: 0,
    pageSize: this.DEFAULT_PAGE_SIZE,
    length: 0,
  });

  private readonly catalog = toSignal(
    this.accountService.singleAccountMarketplaceSelection$.pipe(
      untilDestroyed(this),
      switchMap((am) => this.asinService.getCatalog(am.accountId, am.marketplace)),
    ),
  );

  private readonly baseAsinList = computed<string[]>(() => {
    if (this.catalog()) {
      if (this.customList()) {
        return this.customList()!.filter((asin) => this.catalog()!.contains(asin));
      }

      return Array.from(this.catalog()!.asinOffers.keys());
    }
    return [];
  });

  readonly parentProductMap = computed<Map<AsinSelectionProduct, AsinSelectionProduct[]>>(() => {
    if (!this.catalog()) return new Map();
    const res: Map<string, AsinSelectionProduct[]> = new Map();
    for (const asin of this.baseAsinList()) {
      const parent = this.catalog()!.getParentAsin(asin) ?? asin;
      if (parent === asin) continue;

      if (res.has(parent)) {
        res.get(parent)!.push(this.getProduct(asin, this.disabledAsins(), this.selectedAsins(), this.catalog()));
      } else {
        res.set(parent, [this.getProduct(asin, this.disabledAsins(), this.selectedAsins(), this.catalog())]);
      }
    }

    const productList = new Map<AsinSelectionProduct, AsinSelectionProduct[]>();
    res.forEach((value, key) => {
      productList.set(this.getProduct(key, this.disabledAsins(), this.selectedAsins(), this.catalog()), value);
    });
    return productList;
  });

  // actual product list to display
  readonly filteredProductList = computed<Map<AsinSelectionProduct, AsinSelectionProduct[]>>(() => {
    let res = new Map(this.parentProductMap());

    if (this.onlySelectedFilter()) {
      res = new Map(Array.from(res.entries()).map(([key, value]) => [key, value.filter((p) => p.isSelected)]));
    }

    if (this.searchFilter()) {
      res = new Map(
        Array.from(res.entries()).map(([key, value]) => [
          key,
          value.filter((p) => this.isPassingFilter(p, this.searchFilter())),
        ]),
      );
    }

    if (this.productGroupFilter() && this.productGroupFilter()!.length > 0) {
      res = new Map(
        Array.from(res.entries()).map(([key, value]) => [
          key,
          value.filter((p) => this.productGroupFilter()!.some((pg) => pg.containsAsin(p.asin))),
        ]),
      );
    }

    // if (this.brandFilter() && this.brandFilter()!.size > 0) {
    //   res = res.filter((p) => this.brandFilter()!.has(p.brand ?? ""));
    // }

    // if (this.productTag1Filter() && this.productTag1Filter()!.size > 0) {
    //   res = res.filter((p) => this.productTag1Filter()!.has(p.tag1 ?? ""));
    // }

    // if (this.productTag2Filter() && this.productTag2Filter()!.size > 0) {
    //   res = res.filter((p) => this.productTag2Filter()!.has(p.tag2 ?? ""));
    // }

    // return res;

    return new Map(Array.from(res.entries()).filter(([_, value]) => value.length > 0));
  });

  readonly paginatedProductList = computed<Map<AsinSelectionProduct, AsinSelectionProduct[]>>(() => {
    const list = Array.from(this.filteredProductList().values()).flat();

    if (this.groupByParentAsin()) {
      if (this.filteredProductList().size <= this.DEFAULT_PAGE_SIZE) return this.filteredProductList();
    } else {
      if (list.length <= this.DEFAULT_PAGE_SIZE) return this.filteredProductList();
    }

    const start = this.pagination().pageIndex * this.pagination().pageSize;
    const end = start + this.pagination().pageSize;

    if (this.groupByParentAsin()) {
      return new Map(Array.from(this.filteredProductList()).slice(start, end));
    }
    return new Map([[list[0], list.slice(start, end)]]);
  });

  readonly filteredProductLength = computed<number>(() => {
    if (this.groupByParentAsin()) {
      return this.filteredProductList().size;
    }
    return Array.from(this.filteredProductList().values()).flat().length;
  });

  readonly selectedProductList = computed<AsinSelectionProduct[]>(() => {
    return Array.from(this.filteredProductList().values())
      .flat()
      .filter((p) => p.isSelected);
  });

  // take the rendered list into account
  readonly isAllSelected = computed<boolean>(() => {
    return (
      this.filteredProductList().size > 0 &&
      Array.from(this.filteredProductList().values())
        .flat()
        .filter((p) => !p.isDisabled)
        .every((product) => product.isSelected)
    );
  });

  readonly isCheckboxIndeterminate = computed<boolean>(() => {
    return this.selectedAsins().length > 0 && !this.isAllSelected();
  });

  readonly checkboxAllTooltip = computed<string>(() => {
    if (this.filteredProductList().size === 0) {
      return "";
    }
    return this.isAllSelected() ? "Unselect all" : "Select all";
  });

  ngOnInit() {
    this.accountService.singleAccountMarketplaceSelection$
      .pipe(switchMap((am) => this.productGroupService.getProductGroups(am.accountId, am.marketplace)))
      .subscribe((productGroups) => {
        this.asinFilterTags.setProductGroups(productGroups);
      });

    // add other filters
  }

  onProductClick(product: AsinSelectionProduct) {
    if (product.isDisabled) return;
    if (product.isSelected) {
      if (product.isParent) {
        this.selectedAsins.update((s) => s.filter((asin) => !product.childAsins!.includes(asin)));
      } else {
        this.selectedAsins.update((s) => s.filter((asin) => asin !== product.asin));
      }
    } else {
      if (product.isParent) {
        this.selectedAsins.update((s) => [...s, ...product.childAsins!]);
      } else {
        this.selectedAsins.update((s) => [...s, product.asin]);
      }
    }
  }

  selectAllChange() {
    if (this.isAllSelected()) {
      this.unselectAll();
    } else {
      this.selectAll();
    }
  }

  openBulkAsinModal() {
    const ref = this.modalService.openModal<any, string[]>(BulkAsinModalComponent, {
      modalTitle: "Bulk ASIN selection",
      data: {
        catalog: this.catalog(),
        disabledAsins: this.disabledAsins(),
        selectedAsins: this.selectedAsins(),
        customList: this.customList(),
      },
    });

    ref
      .afterClosed()
      .pipe(
        filter((asinList): asinList is string[] => !!asinList),
        untilDestroyed(this),
      )
      .subscribe((asinList) => this.addSelection(asinList));
  }

  private selectAll() {
    const asinToAdd = Array.from(this.filteredProductList().values())
      .flat()
      .filter((p) => !p.isDisabled && !p.isSelected)
      .map((p) => p.asin);
    this.addSelection(asinToAdd);
  }

  private addSelection(asin: string[]) {
    this.selectedAsins.update((s) => [...s, ...asin]);
  }

  private unselectAll() {
    this.selectedAsins.set([]);
  }

  onFilterChange(filter: Filter<StrategyEx | ProductGroupEx | BrandingFilter | string>[]) {
    this.productGroupFilter.set(null);
    this.brandFilter.set(null);
    this.productTag1Filter.set(null);
    this.productTag2Filter.set(null);

    filter.forEach((f) => {
      if (f.type === FilterType.ProductGroup) {
        this.productGroupFilter.set(f.selectedOptions.map((v) => v.value as ProductGroupEx));
      } else if (f.type === FilterType.BrandProducts) {
        this.brandFilter.set(new Set(f.selectedOptions.map((v) => v.value as string)));
      } else if (f.type === FilterType.CustomField1) {
        this.productTag1Filter.set(new Set(f.selectedOptions.map((v) => v.value as string)));
      } else if (f.type === FilterType.CustomField2) {
        this.productTag2Filter.set(new Set(f.selectedOptions.map((v) => v.value as string)));
      }
    });
  }

  private getIneligibleReason(asin: string): string | undefined {
    if (!this.catalog()?.getSBEligibility().get(asin)?.status) {
      return this.catalog()?.getSBEligibility().get(asin)?.reason;
    }
    if (!this.catalog()?.getSPEligibility().get(asin)?.status) {
      return this.catalog()?.getSPEligibility().get(asin)?.reason;
    }
    return undefined;
  }

  private getImageUrl(imageUrl: string | undefined): string | undefined {
    if (imageUrl) {
      if (imageUrl.includes(this.NO_IMAGE_URL_PART)) {
        return undefined;
      }
      return imageUrl;
    }
    return undefined;
  }

  private isPassingFilter(product: AsinSelectionProduct, text: string) {
    const query = text.toLowerCase().trim();
    if (query.length === 0) {
      return true;
    }
    return (
      product.asin.toLowerCase().includes(query) ||
      product.title?.toLowerCase().includes(query) ||
      product.brand?.toLowerCase().includes(query)
    );
  }

  setOpenParentAsin(asin: string) {
    this.openParentAsin.set(this.openParentAsin() === asin ? undefined : asin);
  }

  private getProduct(
    asin: string,
    disabledAsins: Set<string> | undefined,
    selectedAsins: string[],
    catalog: Catalog | undefined,
  ): AsinSelectionProduct {
    const product = catalog!.asinOffers.get(asin);
    const res: Partial<AsinSelectionProduct> = {
      asin,
      title: product?.title,
      brand: product?.brand,
      image: this.getImageUrl(product?.imageUrl),
      isOtherCatalog: false,
      ineligibleReason: this.getIneligibleReason(asin),
      tag1: product?.customField1,
      tag2: product?.customField2,
      isParent: catalog!.isParentAsin(asin),
      childAsins: catalog!.getChildAsins(asin),
    };

    if (res.isParent) {
      res.isSelected = res.childAsins!.every((childAsin) => selectedAsins.includes(childAsin));
      res.isDisabled = res.childAsins!.some((childAsin) => disabledAsins!.has(childAsin));
      res.isIndeterminate = res.childAsins!.some((childAsin) => selectedAsins.includes(childAsin)) && !res.isSelected;
    } else {
      res.isSelected = selectedAsins.includes(asin);
      res.isDisabled = !!disabledAsins?.has(asin);
    }

    return res as AsinSelectionProduct;
  }
}
