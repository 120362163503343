<ng-container *transloco="let t">
  <div class="flex h-[40vh] max-h-[70vh] w-[30vw] flex-col">
    <p class="border-b border-gray-200 pb-4 text-sm text-gray-500">
      {{ t("tacos-sb-asins-check.impacted_ad_lines") }}
      <br />
      {{ t("tacos-sb-asins-check.please_review_changes") }}
    </p>

    <div class="flex w-full flex-1 flex-col gap-4 overflow-auto py-4">
      @for (adLine of adLinesResult(); track adLine) {
        <h5 class="font-semibold text-gray-600">{{ t("sb-form-asins.ad_line", { index: $index + 1 }) }}</h5>
        <div class="flex items-end gap-2">
          <div class="flex-1 rounded-lg border border-gray-200 p-3">
            <div [ngClass]="{ 'bg-red-100 line-through': adLine.asin1.deleted }">{{ adLine.asin1.asin }}</div>
            <div [ngClass]="{ 'bg-red-100 line-through': adLine.asin2.deleted }">{{ adLine.asin2.asin }}</div>
            <div [ngClass]="{ 'bg-red-100 line-through': adLine.asin3.deleted }">{{ adLine.asin3.asin }}</div>
          </div>
          <span class="icon-[mdi--arrow-right] size-8 text-gray-300"></span>
          <div class="flex w-full flex-1 flex-col">
            <div class="mb-1 flex justify-end">
              @if (adLine.isEmpty) {
                <span class="text-xs font-medium text-red-500">{{ t("common.empty") }}</span>
              } @else if (adLine.isDuplicate) {
                <span class="text-xs font-medium text-red-500">{{ t("common.duplicate") }}</span>
              }
            </div>
            <div class="flex-1 rounded-lg border border-gray-200 p-3">
              <div [ngClass]="{ invisible: adLine.asin1.deleted, 'bg-red-100 line-through': adLine.isDuplicate }">
                {{ adLine.asin1.asin }}
              </div>
              <div [ngClass]="{ invisible: adLine.asin2.deleted, 'bg-red-100 line-through': adLine.isDuplicate }">
                {{ adLine.asin2.asin }}
              </div>
              <div [ngClass]="{ invisible: adLine.asin3.deleted, 'bg-red-100 line-through': adLine.isDuplicate }">
                {{ adLine.asin3.asin }}
              </div>
            </div>
          </div>
        </div>
      }
    </div>

    <div class="flex justify-end gap-2 border-t border-gray-200 pt-4">
      <IButton [label]="t('common.cancel')" variant="ghost" color="gray" (onClick)="onCancel()" />
      <IButton
        [label]="t('common.delete')"
        color="red"
        (onClick)="confirmDeletion()"
        [disabled]="!hasAtLeastOneAdLine()"
        [tooltipValue]="noAdLineTooltip()"
      />
    </div>
  </div>
</ng-container>
