import { Component, inject, output, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Catalog } from "@front/m19-models";
import { IAlertComponent, IButtonComponent, MODAL_DATA, ModalRef } from "@front/m19-ui";
import { Utils } from "@front/m19-utils";
import { TranslocoModule, TranslocoService } from "@jsverse/transloco";

@Component({
  selector: "bulk-asin-modal",
  standalone: true,
  imports: [IButtonComponent, FormsModule, IAlertComponent, TranslocoModule],
  templateUrl: "./bulk-asin-modal.component.html",
})
export class BulkAsinModalComponent {
  protected readonly modalRef = inject(ModalRef);
  protected readonly data = inject<BulkAsinModalData>(MODAL_DATA);
  protected readonly translocoService = inject(TranslocoService);
  onAsinList = output<string[]>();

  bulkInput = signal<string>("");
  bulkErrors = signal<{ asin: string | undefined; error: string }[] | undefined>(undefined);
  selectedAsins = signal<string[]>([]);

  processBulkInput() {
    this.bulkErrors.set([]);
    const asins = this.bulkInput()
      .trim()
      .split(/[\s,;\n]+/)
      .map((asin) => asin.trim().toUpperCase());
    const errors: { asin: string | undefined; error: string }[] = [];
    const toAdd: string[] = [];

    for (const asin of asins) {
      if (this.data.disabledAsins?.has(asin)) continue;
      if (!Utils.isValidAsin(asin)) {
        errors.push({ asin, error: this.translocoService.translate("seeds-edition-modal.invalid_asin") });
      } else if (this.data.customList && !this.data.customList.includes(asin)) {
        errors.push({ asin, error: this.translocoService.translate("strategy-asins.asin_not_allowed") });
      } else if (!this.data.allowOtherCatalog && !this.data.catalog!.contains(asin)) {
        errors.push({ asin, error: this.translocoService.translate("strategy-asins.asin_not_in_catalog") });
      } else if (!this.data.allowOtherCatalog && !this.data.catalog!.childAsins.has(asin)) {
        errors.push({ asin, error: this.translocoService.translate("strategy-asins.not_a_child_asin") });
      } else if (this.data.selectedAsins.includes(asin)) {
        errors.push({ asin, error: this.translocoService.translate("strategy-asins.asin_already_selected") });
      } else if (!toAdd.includes(asin)) {
        toAdd.push(asin);
      }
    }

    if (errors.length === 0) {
      this.selectedAsins.set(toAdd);
      this.emitBulkSelection();
    }
    this.bulkErrors.set(errors);
  }

  emitBulkSelection() {
    this.modalRef.close(this.selectedAsins());
  }
}

export interface BulkAsinModalData {
  catalog: Catalog;
  disabledAsins: Set<string>;
  selectedAsins: string[];
  customList: string[] | undefined;
  allowOtherCatalog: boolean;
}
