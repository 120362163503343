@if (loading) {
  <div class="mt-5 flex w-full justify-center">
    <app-spinner [display]="loading"></app-spinner>
  </div>
}
<div
  class="mt-4"
  [style.visibility]="(marketplaceCount !== undefined && marketplaceCount < 2) || loading ? 'hidden' : 'visible'"
  *transloco="let t"
>
  <div class="flex justify-between">
    <h2>{{ t("common.marketplaces") }}</h2>
    <span>
      <app-export-button tooltipValue="{{ t('common.export_as_csv') }}" (export)="downloadFile()"></app-export-button>
    </span>
  </div>
  <div class="flex flex-row gap-2">
    <div class="flex w-2/5 flex-col">
      <div class="flex flex-auto flex-col items-center justify-center rounded-lg bg-white shadow-sm">
        <h5 class="mb-4">{{ mainMetric ? t("marketplaces.by_marketplaces", [mainMetric.title]) : "" }}</h5>
        <canvas
          baseChart
          class="chart"
          [datasets]="donutDataSet.chartDataSet"
          [labels]="donutDataSet.chartLabels"
          [options]="
            mainMetric && mainMetric.type === RATIO ? donutDataSet.barChartOptions : donutDataSet.donutChartOptions
          "
        ></canvas>
      </div>
    </div>
    <div class="flex-auto">
      <div class="ag-theme-quartz">
        <ag-grid-angular
          class="w-full"
          style="height: 42rem"
          [rowData]="gridData"
          [gridOptions]="gridOptions"
        ></ag-grid-angular>
      </div>
    </div>
  </div>
</div>
