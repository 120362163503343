<ng-container *transloco="let t">
  <div class="flex h-[50vh] w-[40vw] flex-col gap-4">
    @if (bulkErrors() && bulkErrors()!.length > 0) {
      <div>
        <IAlert type="warning" [title]="t('strategy-asins.fix_errors')">
          <ul class="mt-2 pl-3 text-sm">
            @for (error of bulkErrors(); track error) {
              <li>
                <span class="font-medium">{{ error.asin }}</span> - {{ error.error }}
              </li>
            }
          </ul>
        </IAlert>
      </div>
    }
    <div class="w-full flex-1">
      <textarea
        data-testid="bulk-asin-textarea"
        [(ngModel)]="bulkInput"
        class="relative block h-full w-full resize-none rounded-lg border-0 bg-white px-3 py-2 text-sm text-gray-900 placeholder-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-300 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-75"
        [placeholder]="t('strategy-asins.enter_list_of_asins')"
      ></textarea>
    </div>

    <div class="flex justify-end gap-4">
      <IButton label="Cancel" color="white" (onClick)="modalRef.close()" />
      <IButton label="Confirm bulk selection" (onClick)="processBulkInput()" [disabled]="bulkInput().length === 0" />
    </div>
  </div>
</ng-container>
