<div class="w-full" *transloco="let t">
  <h4 class="mb-5 text-center">{{ t("billing-registration-payment.payment_checkout") }}</h4>
  <div class="checkout-wrapper">
    <h5>{{ t("billing-registration-payment.credit_card") }}</h5>
    <div class="label mb-2">{{ t("billing-registration-payment.all_transactions_are_secure_and_encrypted") }}</div>
    <div class="card-perso border-shadow left-child right-child card mr-2 w-full">
      <div class="StripeElement m-3">
        <ngx-stripe-elements [stripe]="stripe" [elementsOptions]="elementsOptions">
          <ngx-stripe-card [options]="cardOptions" (load)="emitCard($event)"></ngx-stripe-card>
        </ngx-stripe-elements>
      </div>
    </div>

    @if (billingPlan && billingPlan.plan) {
      <div>
        <h5>{{ t("billing-registration-payment.payment_details") }}</h5>
        <div class="payment-details w-60">
          <div class="payment-details__subtotal">
            <div class="w-full">
              <span class="label">{{ t("billing-registration-payment.plan_subscription", [billingPlan.plan]) }}</span>
              <span class="value">{{ formatAmount(getAmount()) }}</span>
            </div>
            @if (taxRate() > 0) {
              <div class="w-full">
                <span class="label">{{ t("billing-registration-payment.subtotal") }}</span>
                <span class="value">{{ formatAmount(getAmount()) }}</span>
              </div>
            }
            @if (taxRate() > 0) {
              <div class="w-full">
                <span class="label">{{ t("billing-registration-payment.vat") }}</span>
                <span class="value">{{ formatAmount(getAmount()! * taxRate()) }}</span>
              </div>
            }
          </div>
          <div class="w-full">
            <span class="payment-details__total">{{ t("common.total") }}</span>
            <span class="value">{{ formatAmount(getAmount()! * (1 + taxRate())) }}</span>
          </div>
        </div>
      </div>
    }
  </div>
</div>
