<div class="flex h-full gap-x-2">
  @for (a of actionButtons; track a) {
    <div [matTooltip]="a.tooltip" matTooltipPosition="above" class="flex items-center">
      <IButton
        (onClick)="a.subItems && a.subItems.length ? $event.preventDefault() : a.onClick?.(params)"
        [color]="a.color ?? 'white'"
        size="xs"
        [label]="a.text"
        [matMenuTriggerFor]="a.subItems && a.subItems.length ? menu : null"
        [icon]="a.icon"
        [square]="!!a.icon && !a.text"
        [disabled]="!!a.disabled"
        [testid]="a.testid ?? undefined"
      />

      <mat-menu #menu="matMenu" class="mat-menu-custom">
        @for (s of a.subItems; track s) {
          <div class="pointer menu-item-custom" (click)="s.onClick?.(params)">
            {{ s.title }}
          </div>
        }
      </mat-menu>
      <mat-menu #placeholder="matMenu" style="display: none !important"></mat-menu>
    </div>
  }
</div>
