import { Component } from "@angular/core";
import { AccountMarketplace, AccountType } from "@front/m19-api-client";
import { AccountSelectionService } from "@front/m19-services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SellerInventoryComponent } from "./seller-inventory/seller-inventory.component";
import { VendorInventoryComponent } from "./vendor-inventory/vendor-inventory.component";

@UntilDestroy()
@Component({
  selector: "app-inventory",
  template: `@if (accountMarketplace?.accountType !== AccountType.SELLER) {
      <app-vendor-inventory></app-vendor-inventory>
    } @else {
      <app-seller-inventory></app-seller-inventory>
    }`,
  standalone: true,
  imports: [SellerInventoryComponent, VendorInventoryComponent],
})
export class InventoryComponent {
  accountMarketplace: AccountMarketplace | undefined;

  readonly AccountType = AccountType;

  constructor(private accountSelection: AccountSelectionService) {
    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(untilDestroyed(this))
      .subscribe((am: AccountMarketplace) => {
        this.accountMarketplace = am;
      });
  }
}
