import { Injectable } from '@angular/core';
import { CampaignType, Marketplace, StatsApi, Strategy, StrategyApi } from '@front/m19-api-client';
import { AccountMarketplaceWritableCache, Utils } from '@front/m19-utils';
import { combineLatest, map, Observable } from 'rxjs';

/**
 * Cache for strategies. This cache should only by used in services and not in components.
 * We should create a module for that
 */
@Injectable({
  providedIn: 'root',
})
export class StrategyCache {
  // package visibility
  readonly strategyListCache = new AccountMarketplaceWritableCache<Map<number, Strategy>>((accountId, marketplace) =>
    this.loadAllStrategies(accountId, marketplace),
  );

  constructor(
    private strategyApi: StrategyApi,
    private statsApi: StatsApi,
  ) {}

  // cache loader
  private loadAllStrategies(accountId: string, marketplace: Marketplace): Observable<Map<number, Strategy>> {
    return combineLatest([
      this.strategyApi.listStrategies({ accountId, marketplace }),
      this.statsApi.getStrategyConfigurationHistory({
        accountId: accountId,
        marketplace: marketplace,
        minDate: Utils.formatDateForApiFromToday(-1),
        maxDate: Utils.formatDateForApiFromToday(0),
      }),
    ]).pipe(
      map(([strategies, strategyHistory]) => {
        const isVendor = accountId.startsWith('ENTITY');
        let defaultStrategy: Strategy | undefined = undefined;
        let hasOtherSpStrategies = false;
        const result = new Map<number, Strategy>();
        for (const strategy of strategies) {
          if (strategy.defaultStrategy) {
            if (isVendor) {
              continue;
            }
            defaultStrategy = strategy;
          } else if (strategy.campaignType === CampaignType.SP) {
            hasOtherSpStrategies = true;
          }
          result.set(strategy.strategyId!, strategy);
        }
        if (defaultStrategy) {
          defaultStrategy.name = hasOtherSpStrategies ? 'All other products' : 'All products';
        }
        for (const stat of strategyHistory) {
          if (result.has(stat.strategyId!)) {
            result.get(stat.strategyId!)!.constraint = stat.constraint;
          }
        }
        return result;
      }),
    );
  }
}
