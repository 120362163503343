<div class="flex flex-col items-start p-3" *transloco="let t">
  @if (isEditionMode()) {
    <h4 class="mx-auto">{{ t("billing-customer.billing_information") }}</h4>
  }

  <div class="form-wrapper mx-auto py-5">
    <div class="input-block">
      <label>{{ t("common.company") }}</label>
      <input
        type="text"
        maxlength="80"
        class="simple-input form-control w-full"
        placeholder="{{ t('billing-customer.please_enter_your_name') }}"
        [(ngModel)]="_customer!.name"
        [readonly]="!isEditionMode()"
      />
      <div class="input-block__hint">{{ _customer!.name ? _customer!.name.length : 0 }}/80</div>
    </div>
    <div class="input-block">
      <label>{{ t("billing-customer.address_line_1") }}</label>
      <input
        type="text"
        maxlength="100"
        class="simple-input form-control"
        placeholder="{{ t('billing-customer.address_line_1') }}"
        [(ngModel)]="_customer!.addressLine1"
        [readonly]="!isEditionMode()"
      />
      <div class="input-block__hint">{{ _customer!.addressLine1 ? _customer!.addressLine1.length : 0 }}/100</div>
    </div>
    <div class="input-block">
      <label>{{ t("billing-customer.address_line_2") }}</label>
      <input
        type="text"
        maxlength="100"
        class="simple-input form-control"
        [placeholder]="isEditionMode() ? t('billing-customer.address_line_2') : ''"
        [(ngModel)]="_customer!.addressLine2"
        [readonly]="!isEditionMode()"
      />
      <div class="input-block__hint">{{ _customer!.addressLine2 ? _customer!.addressLine2.length : 0 }}/100</div>
    </div>
    <div class="input-block mb-4">
      <label>{{ t("billing-customer.postal_code") }}</label>
      <input
        type="text"
        maxlength="10"
        class="simple-input form-control w-full"
        placeholder="{{ t('billing-customer.zip_code') }}"
        [(ngModel)]="_customer!.postalCode"
        [readonly]="!isEditionMode()"
      />
    </div>
    <div class="mb-4 flex items-end">
      <div class="input-block flex-2 mr-2">
        <label>{{ t("billing-customer.city") }}</label>
        <input
          type="text"
          maxlength="100"
          class="simple-input form-control"
          placeholder="{{ t('billing-customer.city') }}"
          [(ngModel)]="_customer!.city"
          [readonly]="!isEditionMode()"
        />
      </div>

      @if (!canEditCountry()) {
        <div class="input-block">
          <label>{{ t("billing-customer.country") }}</label>
          <input
            type="text"
            maxlength="80"
            class="simple-input form-control"
            [(ngModel)]="_customer!.country"
            matTooltipPosition="above"
            [matTooltip]="isEditionMode() ? t('billing-customer.contact_us_to_update_your_country') : ''"
            readonly
          />
        </div>
      }

      @if (canEditCountry()) {
        <div class="input-block">
          <label>{{ t("billing-customer.country") }}</label>
          <ISelect
            [options]="countriesOptions"
            [selected]="selectedCountry()"
            (selectedChange)="selectCountry($event)"
            placeholder="{{ t('billing-customer.select_your_billing_country') }}"
            searchable
          />
        </div>
      }
    </div>
    @if (hasState()) {
      <div class="input-block mb-4">
        <label>{{ t("billing-customer.state") }}</label>
        <input
          type="text"
          maxlength="2"
          class="simple-input form-control w-full"
          placeholder="state"
          [(ngModel)]="_customer!.state"
          [readonly]="!isEditionMode()"
        />
      </div>
    }
    @if (vatNumberRequired()) {
      <div class="input-block mb-4">
        <label>{{ t("billing-registration-payment.vat") }}</label>
        <input
          type="text"
          maxlength="20"
          class="simple-input form-control w-full"
          placeholder="{{ vatExByCountryCode.get(_customer!.country!)! }}"
          [(ngModel)]="_customer!.vat"
          [readonly]="!isEditionMode()"
        />
      </div>
    }
    @if (isEditionMode()) {
      <div class="flex w-full justify-end">
        <button class="btn btn-ghost-secondary mr-2" (click)="cancel()">
          {{ wizard ? t("common.previous") : t("common.cancel") }}
        </button>
        <button class="btn btn-primary" (click)="save()" [class.disabled]="disableSave()">
          {{ wizard ? t("common.next") : t("common.save") }}
        </button>
      </div>
    }
  </div>
</div>
