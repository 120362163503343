import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { faSquare, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faArrowRight, faCheckSquare, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ProductSegmentModalComponent } from "@m19-board/segments/product-segment-modal.component";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { SegmentService } from "@front/m19-services";
import { Marketplace, ProductTrackerConfig } from "@front/m19-api-client";
import { Catalog, SegmentConfigType, SegmentEx } from "@front/m19-models";
import { Utils } from "@front/m19-utils";

enum ProductSelectionMode {
  Bulk,
  FromProductSegment,
}

@UntilDestroy()
@Component({
  templateUrl: "./add-product-tracking-modal.component.html",
  styleUrls: ["./add-product-tracking-modal.component.scss"],
})
export class AddProductTrackingModalComponent implements OnInit {
  readonly displayedColumns = ["product"];
  readonly faTrash = faTrashAlt;
  readonly faArrowRight = faArrowRight;
  readonly SelectionModes = ProductSelectionMode;
  readonly faSquare = faSquare;
  readonly faCheckedSquare = faCheckSquare;
  readonly faSearch = faSearch;
  readonly faTimes = faTimes;

  @Input()
  accountId!: string;
  @Input()
  marketplace!: Marketplace;
  @Input()
  catalog!: Catalog;
  @Input()
  productTrackerConfig!: ProductTrackerConfig[];

  @Input()
  set products(value: string[]) {
    this.asinList = value;
  }

  bulkAsins = "";
  selectionMode = ProductSelectionMode.Bulk;
  asinList: string[] = [];
  productSegments?: SegmentEx[];
  selectedProductSegments: SegmentEx[] = [];
  filteredProductSegments: SegmentEx[] = [];
  productSegmentItems = new Map<number, string[]>();
  productSegmentFilter = new RegExp("", "i");
  datasourceProduct = new MatTableDataSource<string>([]);
  errors?: string[];
  productFilter?: string;

  @Output() public save = new EventEmitter<ProductTrackerConfig[]>();

  constructor(
    public bsModalRef: BsModalRef,
    private segmentService: SegmentService,
    private modalService: BsModalService,
  ) {}

  ngOnInit(): void {
    this.datasourceProduct.data = this.asinList;
    this.segmentService
      .getSegments(this.accountId, this.marketplace)
      .pipe(untilDestroyed(this))
      .subscribe((segmentsMap: Map<number, SegmentEx>) => {
        this.productSegments = Array.from(segmentsMap.values()).filter(
          (seg) => seg.segmentType === SegmentConfigType.ProductSegment,
        );
        this.selectedProductSegments = [];
        this.filteredProductSegments = this.productSegments;
        this.productSegmentFilter = new RegExp("", "i");

        for (const segment of this.productSegments) {
          this.productSegmentItems.set(
            segment.segmentId,
            segment.items
              .map((item) => item.targetingValue)
              .filter(
                (asin) => !this.catalog.contains(asin) && !this.productTrackerConfig.find((c) => c.asin === asin),
              ),
          );
        }
      });
  }

  addProduct(): void {
    if (this.asinToAddInvalid()) {
      return;
    }
    this.errors = [];
    const bulk = this.bulkAsins
      .split("\n")
      .map((x) => x.trim())
      .filter((x) => x !== "");
    const asinsToAdd: string[] = [];
    for (const asinToAdd of bulk) {
      if (!Utils.isValidAsin(asinToAdd)) {
        this.errors.push(`${asinToAdd} is not a valid ASIN`);
        continue;
      }
      if (this.catalog.contains(asinToAdd)) {
        this.errors.push(`ASIN ${asinToAdd} is in your catalog (it is already tracked)`);
        continue;
      }
      if (this.productTrackerConfig.findIndex((c) => c.asin == asinToAdd) > 0 || this.asinList.includes(asinToAdd)) {
        this.errors.push(`ASIN ${asinToAdd} is already tracked`);
        continue;
      }
      if (asinsToAdd.includes(asinToAdd)) {
        this.errors.push(`ASIN ${asinToAdd} is duplicated`);
        continue;
      }
      asinsToAdd.push(asinToAdd);
    }
    this.asinList.push(...asinsToAdd);
    this.datasourceProduct.data = this.asinList;
    this.datasourceProduct.filterPredicate = (data, filter) => new RegExp(filter, "i").test(data);

    this.bulkAsins = "";
  }

  asinToAddInvalid(): boolean {
    return this.bulkAsins === "";
  }

  deleteAsin(asin: string) {
    this.asinList.splice(this.asinList.indexOf(asin), 1);
    this.datasourceProduct.data = this.asinList;
  }

  deleteAllAsins() {
    this.asinList = [];
    this.datasourceProduct.data = this.asinList;
  }

  canSave() {
    return this.asinList.length > 0;
  }

  onSave(): void {
    const terms: ProductTrackerConfig[] = [];
    this.asinList.forEach((asin) => {
      terms.push({
        accountId: this.accountId,
        marketplace: this.marketplace,
        asin: asin,
      });
    });
    this.save.emit(terms);
    this.bsModalRef.hide();
  }

  changeProductSelectionMode(mode: ProductSelectionMode) {
    this.selectedProductSegments = [];
    this.bulkAsins = "";
    this.selectionMode = mode;
    this.errors = [];
  }

  isProductSegmentAddButtonDisabled() {
    return !this.selectedProductSegments || this.selectedProductSegments.length === 0;
  }

  selectAllProductSegments() {
    for (const segment of this.filteredProductSegments) {
      if (this.selectedProductSegments.findIndex((seg) => seg.segmentId == segment.segmentId)) {
        this.selectedProductSegments.push(segment);
      }
    }
  }

  unselectAllProductSegments() {
    this.selectedProductSegments = [];
  }

  setProductSegmentFilter(elt: EventTarget | null) {
    const filter = (elt as HTMLInputElement).value;
    this.productSegmentFilter = new RegExp(filter, "i");
    this.filteredProductSegments =
      this.productSegments?.filter((seg) => this.productSegmentFilter.test(seg.name)) ?? [];
  }

  selectProductSegment(productSegment: SegmentEx) {
    const index = this.selectedProductSegments.findIndex((seg) => seg.segmentId === productSegment.segmentId);
    if (index < 0) {
      this.selectedProductSegments.push(productSegment);
    } else {
      this.selectedProductSegments.splice(index, 1);
    }
  }

  getProductSegmentItems(productSegment: SegmentEx) {
    return this.productSegmentItems.get(productSegment.segmentId) ?? [];
  }

  isProductSegmentSelected(productSegment: SegmentEx) {
    return this.selectedProductSegments.findIndex((seg) => seg.segmentId === productSegment.segmentId) >= 0;
  }

  productSegmentAlreadySelected(productSegment: SegmentEx) {
    return this.getProductSegmentItems(productSegment).every((kw) => this.asinList.includes(kw));
  }

  addProductsByProductSegment() {
    for (const productSegment of this.selectedProductSegments) {
      for (const asin of this.getProductSegmentItems(productSegment)) {
        if (this.asinList.findIndex((x) => x === asin) < 0) this.asinList.push(asin);
      }
    }
    this.datasourceProduct.data = this.asinList;
  }

  openProductSegmentInfo(segment: SegmentEx) {
    const modalOptions: ModalOptions = {
      initialState: {
        accountId: this.accountId,
        marketplace: this.marketplace,
        isReadOnly: true,
        segment: segment,
      },
      class: "modal-xl",
    };
    this.modalService.show(ProductSegmentModalComponent, modalOptions);
  }

  setProductFilter(elt: EventTarget | null) {
    const filter = (elt as HTMLInputElement).value;
    this.productFilter = filter;
    this.datasourceProduct.filter = filter;
  }
}
