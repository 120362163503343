import { Injectable } from '@angular/core';
import { Marketplace, NotificationApi, NotificationType, Response } from '@front/m19-api-client';
import { NotificationBidderIssueEx, NotificationEx } from '@front/m19-models/NotificationEx';
import { OrganizationAccountGroups } from '@front/m19-models/OrganizationAccountGroups';
import { accountMarketplaceKey, ByAccountMarketplace } from '@front/m19-utils';
import { BehaviorSubject, combineLatest, Observable, of, timer } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import {
  AccountSelectionService,
  AuthService,
  OrganizationAccountGroupService,
  SbStrategiesService,
  StrategyService,
} from '.';

export type NotificationMetadata = {
  label: string;
  critical: boolean;
  supported: boolean;
  priority: number;
};

type NotificationInfo = {
  [key in NotificationType]?: NotificationMetadata;
};

export const NotificationInfo: NotificationInfo = {
  [NotificationType.SELLING_PARTNER_API_ACCESS_LOST]: {
    label: 'notification-service.selling_partner_api_access_lost',
    critical: true,
    supported: true,
    priority: 1,
  },
  [NotificationType.ADS_API_ACCESS_LOST]: {
    label: 'notification-service.amazon_advertising_api_access_lost',
    critical: true,
    supported: true,
    priority: 2,
  },
  [NotificationType.OVERLAPPING_STRATEGIES]: {
    label: 'notification-service.overlapping_strategies',
    critical: false,
    supported: true,
    priority: 5,
  },
  [NotificationType.OVERLAPPING_WITH_NON_M19_STRATEGIES]: {
    label: 'notification-service.overlapping_non-operated_campaign',
    critical: false,
    supported: true,
    priority: 6,
  },
  [NotificationType.DELETED_BRAND_ASSET]: {
    label: 'notification-service.strategy_containing_deleted_brand_asset',
    critical: false,
    supported: true,
    priority: 7,
  },
  [NotificationType.BIDDER_ISSUE]: {
    label: 'notification-service.strategy_issue',
    critical: true,
    supported: true,
    priority: 8,
  },
  [NotificationType.UNKNOWN]: {
    label: 'notification-service.unknown',
    critical: false,
    supported: false,
    priority: 10,
  },
};

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public getNotifications$: BehaviorSubject<NotificationEx[]> = new BehaviorSubject<NotificationEx[]>([]);
  private notifications: NotificationEx[] = [];

  private accountsNames: ByAccountMarketplace<string> = new Map();
  public globalWarning$: Observable<string>;

  constructor(
    private notificationApi: NotificationApi,
    private accountGroupService: OrganizationAccountGroupService,
    private authService: AuthService,
    private sbStrategyService: SbStrategiesService,
    private accountSelectionService: AccountSelectionService,
    private strategyService: StrategyService,
  ) {
    this.accountGroupService.allOrganizationAccountGroups$
      .pipe(
        filter((organizations): organizations is OrganizationAccountGroups[] => !!organizations),
        map((organizations) => organizations.flatMap((o) => o.accountGroups)),
        filter((accountsGroups) => accountsGroups.length > 0),
        switchMap((accountsGroups) => {
          this.accountsNames.clear();
          accountsGroups.forEach((accountGroup) =>
            accountGroup
              .getAccountMarketplaces()
              .forEach((account) =>
                this.accountsNames.set(
                  accountMarketplaceKey(account.accountId, account.marketplace),
                  account.accountName,
                ),
              ),
          );
          return timer(0, 1_800_000); // reload notification every 30 minutes
        }),
        switchMap(() => this.authService.isLogged$),
        filter((logged) => logged), // prevent calls to notification if user is not logged
        switchMap(() =>
          combineLatest([
            this.notificationApi.getNotifications(),
            this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(
              switchMap((am) =>
                combineLatest([
                  this.strategyService.getStrategyIndex(am.accountId, am.marketplace),
                  this.sbStrategyService.getSbCreativesPerStrategy(am.accountId, am.marketplace),
                ]),
              ),
            ),
          ]),
        ),
        switchMap(([notifications, [strategyIndex, creatives]]) => {
          const notificationsEx: NotificationEx[] = [];

          notifications.forEach((notification) => {
            const info = NotificationInfo[notification.type];
            if (!info || !info.supported) {
              return;
            }

            const newNotifEx = new NotificationEx(notification);
            newNotifEx.setNotificationMetadata(info);
            const key = accountMarketplaceKey(notification.accountId, notification.marketplace);
            if (this.accountsNames.has(key)) {
              newNotifEx.setAccountName(this.accountsNames.get(key)!);
            }

            if (newNotifEx.type === NotificationType.BIDDER_ISSUE) {
              const bidderIssue = new NotificationBidderIssueEx(newNotifEx, strategyIndex, creatives);
              if (!bidderIssue.notApplicable) {
                notificationsEx.push(bidderIssue);
              }
            } else {
              notificationsEx.push(newNotifEx);
            }
          });
          NotificationService.sortNotifications(notificationsEx);
          return of(notificationsEx);
        }),
      )
      .subscribe((n) => {
        this.getNotifications$.next(n);
      });

    this.getNotifications$.subscribe((notifications) => {
      this.notifications = notifications;
    });
    this.globalWarning$ = timer(0, 300_000).pipe(
      // reload warning every 5 minutes
      switchMap(() => this.notificationApi.getGlobalWarning()),
      map((m) => m.message!),
      shareReplay(1),
    );
  }

  toggleNotificationDismissed(notification: NotificationEx): Observable<Response> {
    return this.notificationApi
      .dismissNotification({
        dismiss: notification.dismissed ? 0 : 1,
        accountId: notification.accountId,
        type: notification.type,
        marketplace: notification?.marketplace,
        strategyId: notification?.strategyId,
        subStrategyId: notification?.subStrategyId,
      })
      .pipe(
        tap((_) => {
          notification.dismissed = !notification.dismissed;
          NotificationService.sortNotifications(this.notifications);
          this.getNotifications$.next(this.notifications);
        }),
      );
  }

  deleteSbRejectedCreative(strategyId: number, creativeId: number): Observable<Response> {
    const index = this.notifications.findIndex(
      (n) => n.strategyId === strategyId && n.subStrategyId === creativeId && n.type === NotificationType.BIDDER_ISSUE,
    );
    if (index > -1) {
      const n = this.notifications[index];
      return this.notificationApi
        .deleteNotification({
          accountId: n.accountId,
          type: NotificationType.BIDDER_ISSUE,
          marketplace: n.marketplace,
          strategyId: strategyId,
          subStrategyId: creativeId,
        })
        .pipe(
          tap((_) => {
            this.notifications.splice(index, 1);
            this.getNotifications$.next(this.notifications);
          }),
        );
    } else {
      return of({} as Response);
    }
  }

  deleteNotification(accountId: string, type: NotificationType, marketplace?: Marketplace, strategyId?: number) {
    const notifToDeleteIndex = this.notifications.findIndex(
      (n) =>
        n.accountId == accountId &&
        n.type == type &&
        (!marketplace || n.marketplace == marketplace) &&
        (!strategyId || n.strategyId == strategyId),
    );
    if (notifToDeleteIndex > -1) {
      const notifToDelete = this.notifications[notifToDeleteIndex];
      this.notificationApi
        .deleteNotification({
          accountId: accountId,
          type: type,
          marketplace: marketplace,
          strategyId: strategyId ?? undefined,
          subStrategyId: undefined,
        })
        .subscribe((_) => {
          this.notifications.splice(notifToDeleteIndex, 1);
          this.getNotifications$.next(this.notifications);
        });
    }
  }

  getLabel(type: NotificationType) {
    return NotificationInfo[type]?.label;
  }

  sortNotificationTypes(types: NotificationType[]) {
    types.sort((a, b) => {
      return (NotificationInfo[a]?.priority ?? +Infinity) - (NotificationInfo[b]?.priority ?? +Infinity);
    });
  }

  public static sortNotifications(notifications: NotificationEx[]): void {
    notifications.sort((a, b) => {
      if (a.dismissed != b.dismissed) {
        return a.dismissed ? 1 : -1;
      }

      if (a.priority != b.priority) {
        return a.priority - b.priority;
      }

      return b.creationTimestamp.localeCompare(a.creationTimestamp);
    });
  }
}
