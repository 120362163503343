<app-stats-overlay>
  <div class="mb-2 flex w-full justify-end" *transloco="let t">
    <app-export-button tooltipValue="{{ t('brand-alias.export_brand_alias_as_csv') }}" (export)="exportAsCsv()" />
  </div>
  <div class="ag-theme-quartz">
    <ag-grid-angular
      style="width: 100%; height: 70vh"
      class="grid-height"
      [rowData]="brandAliases"
      [gridOptions]="gridOptions"
    ></ag-grid-angular>
  </div>
</app-stats-overlay>
