import { Component, Input, ViewChild } from "@angular/core";
import { SbCreativeStatsGridComponent } from "@m19-board/strategies/sb-creative-stats-grid/sb-creative-stats-grid.component";
import { StrategyPlacementStatsGridComponent } from "@m19-board/strategies/strategy-placement-stats/strategy-placement-stats-grid.component";
import { BsModalRef } from "ngx-bootstrap/modal";
import { StrategyEx } from "@front/m19-models";
import { CampaignType, Currency } from "@front/m19-api-client";

@Component({
  selector: "app-strategy-detail-stats-modal",
  template: ` <div class="modal-header bg-primary" *transloco="let t">
      <h4 class="modal-title">
        <span>{{ t("strategy-detail-stats-modal.stategy_stats", [strategy.strategyName]) }}</span>
      </h4>
      <h4 class="text-secondary pointer" (click)="close()">&times;</h4>
    </div>
    <div class="modal-body" *transloco="let t">
      <div class="px-4">
        <div class="my-3 flex w-full justify-end gap-1">
          <app-export-button
            tooltipValue="{{ t('strategy-detail-stats-modal.export_startegy_csv') }}"
            (export)="exportStrategyStatsCsv()"
          />
        </div>
        @if (strategy.campaignType === CampaignType.SB) {
          <mat-tab-group
            animationDuration="0ms"
            class="mt-2"
            [selectedIndex]="selectedStatsTab"
            (selectedIndexChange)="selectedStatsTab = $event"
          >
            <mat-tab label="{{ t('strategy-detail-stats-modal.by_creative') }}">
              <div class="mt-2 h-[40rem]">
                <app-sb-creative-stats-grid
                  #creativeGrid
                  [strategy]="strategy"
                  [currency]="currency"
                  [locale]="locale"
                />
              </div>
            </mat-tab>
            <mat-tab label="{{ t('strategy-detail-stats-modal.by_tactic_placement') }}">
              <div class="mt-2 h-[40rem]">
                <app-strategy-placement-stats-grid
                  #tacticPlacementGrid
                  [strategy]="strategy"
                  [currency]="currency"
                  [locale]="locale"
                />
              </div>
            </mat-tab>
          </mat-tab-group>
        } @else {
          <div class="mt-2 h-[45rem]">
            <app-strategy-placement-stats-grid
              #tacticPlacementGrid
              [strategy]="strategy"
              [currency]="currency"
              [locale]="locale"
            />
          </div>
        }
      </div>
    </div>`,
})
export class StrategyDetailStatsModalComponent {
  @Input({ required: true })
  strategy!: StrategyEx;
  @Input({ required: true })
  currency!: Currency;
  @Input({ required: true })
  locale!: string;

  readonly CampaignType = CampaignType;

  selectedStatsTab = 0;

  @ViewChild("tacticPlacementGrid", { static: false }) tacticPlacementGrid!: StrategyPlacementStatsGridComponent;
  @ViewChild("creativeGrid", { static: false }) creativeGrid!: SbCreativeStatsGridComponent;

  constructor(private modalRef: BsModalRef) {}

  close() {
    this.modalRef.hide();
  }

  exportStrategyStatsCsv() {
    if (this.strategy.campaignType == CampaignType.SB && this.selectedStatsTab === 0) {
      this.creativeGrid.exportCsv();
    } else {
      this.tacticPlacementGrid.exportCsv();
    }
  }
}
