import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Marketplace, StrategyAsin } from "@front/m19-api-client";
import {
  AsinsSelectionComponent,
  StrategyAsinSelectionMode,
} from "@m19-board/strategies/strategy-asins/asins-selection.component";
import { ICON_CLOSE } from "@m19-board/utils/iconsLabels";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-strategy-product-targetings-modal",
  template: ` <div class="modal-header flex items-center justify-between">
      <h4 class="modal-title pull-left">{{ title }}</h4>
      <span (click)="modalRef.hide()" [class]="[ICON_CLOSE, 'text-white', 'text-lg', 'cursor-pointer']"></span>
    </div>
    <div class="modal-body p-3">
      <app-asins-selection
        #asinsSelection
        [asins]="asins"
        [accountId]="accountId"
        [marketplace]="marketplace"
        [allowAsinFromOtherCatalog]="allowAsinFromOtherCatalog"
        [csvExport]="false"
        [bulkPlaceholderText]="bulkPlaceholderText"
        [maxProducts]="maxProducts"
        [selectionModes]="selectionModes"
        height="50vh"
        (onAdd)="onAdd.emit($event)"
        (onDelete)="onDelete.emit($event)"
      ></app-asins-selection>
    </div>
    <div class="modal-footer flex justify-end p-3">
      <IButton label="Close" color="gray" variant="soft" (onClick)="modalRef.hide()" />
    </div>`,
})
export class StrategyProductTargetingsModalComponent {
  @Input()
  title!: string;

  @Input()
  asins!: StrategyAsin[];

  @Input()
  accountId!: string;

  @Input()
  marketplace!: Marketplace;

  @Input() allowAsinFromOtherCatalog = false;

  @Input() bulkPlaceholderText = "";

  @Input() maxProducts!: number;

  @Input() selectionModes!: { selectionMode: StrategyAsinSelectionMode; label: string }[];

  @Output()
  onAdd = new EventEmitter<StrategyAsin[]>();

  @Output()
  onDelete = new EventEmitter<StrategyAsin[]>();

  @ViewChild("asinsSelection")
  asinsSelection!: AsinsSelectionComponent;

  readonly ICON_CLOSE = ICON_CLOSE;

  constructor(public modalRef: BsModalRef) {}

  reloadAsins() {
    this.asinsSelection.asins = this.asins;
  }
}
