import { NgClass } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatTooltip } from "@angular/material/tooltip";
import { Metric, MetricFormatPipe } from "@front/m19-metrics";
import { IBadgeComponent } from "@front/m19-ui";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { Currency } from "@front/m19-api-client";
import { CurrencyStat } from "@front/m19-models";

export enum METRIC_EVO_DISPLAY {
  GRID, // display an arrow followed by the current value
  BADGE, // display badge with arrow and evolution value
}

@Component({
  selector: "app-metric-evo",
  standalone: true,
  templateUrl: "./metric-evo.component.html",
  imports: [NgClass, MatTooltip, IBadgeComponent],
})
export class MetricEvoComponent implements ICellRendererAngularComp {
  readonly METRIC_EVO_DISPLAY = METRIC_EVO_DISPLAY;
  metricFormatPipe = new MetricFormatPipe<CurrencyStat>();

  @Input() displayMode: METRIC_EVO_DISPLAY = METRIC_EVO_DISPLAY.GRID;
  @Input({ required: true }) metric!: Metric<CurrencyStat>;

  @Input() locale: string = "";
  @Input() currency: Currency = Currency.USD;

  currentData_!: CurrencyStat;

  @Input({ required: true }) set currentData(currentData: CurrencyStat) {
    this.currentData_ = currentData;
    this.computeSettings();
  }

  previousData_!: CurrencyStat;

  @Input({ required: true }) set previousData(previousData: CurrencyStat) {
    this.previousData_ = previousData;
    this.computeSettings();
  }

  evoChanged?: boolean;
  isFiniteEvo?: boolean;
  evoValue?: number;
  evoColor: "main" | "red" | "orange" | "gray" | "black" | "green" | "purple" = "main";
  evoIcon: string = "";
  evoTooltip: string = "";
  evoFormatted: string = "";
  previousMetricFormatted: string = "";
  metricFormatted: string = "";
  metricTooltip: string = "";

  agInit(
    params: ICellRendererParams & {
      metric: Metric<CurrencyStat>;
      locale: string;
      currentData: CurrencyStat;
      previousData: CurrencyStat;
      currency: Currency;
    },
  ): void {
    this.metric = params.metric;
    this.locale = params.locale;
    this.currency = params.currency;
    this.currentData_ = params.currentData;
    this.previousData_ = params.previousData;

    this.computeSettings();
  }

  refresh(
    params: ICellRendererParams & {
      metric: Metric<CurrencyStat>;
      locale: string;
      currentData: CurrencyStat;
      previousData: CurrencyStat;
      currency: Currency;
    },
  ): boolean {
    this.metric = params.metric;
    this.locale = params.locale;
    this.currency = params.currency;
    this.currentData_ = params.currentData;
    this.previousData_ = params.previousData;

    this.computeSettings();
    return true;
  }

  private computeSettings() {
    this.evoValue = getMetricEvolution(this.metric, this.currentData_, this.previousData_);
    this.isFiniteEvo = isFinite(this.evoValue);
    this.evoChanged = this.previousData_ && metricEvolved(this.metric, this.currentData_, this.previousData_);

    this.evoColor = this.metric.mustApplyEvolutionStyle
      ? isEvolutionPositive(this.metric, this.evoValue)
        ? "green"
        : "red"
      : "gray";
    this.evoIcon =
      this.evoValue > 0 ? "icon-[material-symbols--trending-up]" : "icon-[material-symbols--trending-down]";
    this.metricFormatted = this.getMetricFormat();
    this.metricTooltip = this.getMetricFormat(false, "1.0-2");

    this.previousMetricFormatted = this.getMetricFormat(true);
    this.evoFormatted = `${this.evoValue > 0 ? "+" : ""}${formatMetricEvolution(
      this.metric,
      this.evoValue,
      this.locale,
    )}`;
    this.evoTooltip = `${this.evoFormatted} (${this.previousMetricFormatted})`;
  }

  private getMetricFormat(previousPeriod = false, precision?: string) {
    const format = this.metricFormatPipe.transform(
      this.metric,
      previousPeriod ? this.previousData_ : this.currentData_,
      this.locale,
      this.currency,
      precision,
      false,
    );
    return format === "N/A" ? "-" : format;
  }
}

export function formatMetricEvolution(metric: Metric<CurrencyStat>, evolution: number, locale: string): string {
  return metric.formatMetricEvolution(evolution, locale);
}

export function getMetricEvolution(
  metric: Metric<CurrencyStat>,
  data: CurrencyStat,
  previousPeriodData: CurrencyStat,
): number {
  return metric.getEvolution(data, previousPeriodData) ?? 0;
}

export function metricEvolved(
  metric: Metric<CurrencyStat>,
  data: CurrencyStat,
  previousPeriodData: CurrencyStat,
): boolean {
  return metric.value(data) != metric.value(previousPeriodData);
}

export function isEvolutionPositive(metric: Metric<any>, value: number): boolean {
  if (metric.higherIsBetter) return value > 0;
  return value < 0;
}
