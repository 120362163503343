<nav
  data-testid="sidebar-nav"
  class="scrollbar-sm relative z-40 h-full shrink-0 items-center overflow-y-auto overflow-x-hidden border-r border-gray-200 bg-white px-3 pt-3 text-sm transition-all lg:px-4"
  [ngClass]="{ 'w-60': expanded, 'w-auto': !expanded }"
  *transloco="let t"
>
  <div class="flex w-full" [ngClass]="expanded ? 'justify-end' : 'justify-center'">
    <IButton
      color="white"
      (onClick)="toggleSidebar()"
      [icon]="expanded ? 'icon-[mdi--chevron-double-left]' : 'icon-[mdi--chevron-double-right]'"
      size="xs"
      tooltipValue="{{ expanded ? t('v2-sidebar.collapse_sidebar') : t('v2-sidebar.expand_sidebar') }}"
    />
  </div>

  <ul class="m-0 mb-32 flex list-none flex-col pl-0" [ngClass]="expanded ? 'pb-2' : 'py-2'">
    @for (navItem of navItems; track navItem) {
      @if (isNavItem(navItem)) {
        @if (!navItem.hidden) {
          <li
            class="flex items-center"
            [class.ml-2]="expanded"
            [attr.data-testid]="'sidebar-nav-' + navItem.testid"
            [matTooltip]="!expanded ? t(navItem.tooltip) : ''"
            matTooltipPosition="right"
          >
            <a
              [routerLink]="[navItem.url]"
              queryParamsHandling="merge"
              class="group flex w-full items-center justify-between rounded-md p-1 !no-underline duration-75"
              [ngClass]="{
                ' [&_span]:text-main [&_span]:font-semibold': router.url.startsWith(navItem.url),
                'pointer-events-none': navItem.disabled,
                'hover:bg-gray-100': !navItem.disabled,
              }"
            >
              <div
                class="flex items-center"
                [ngClass]="{
                  'opacity-50': navItem.disabled,
                }"
              >
                <span
                  [class]="navItem.icon"
                  class="flex shrink-0 text-2xl text-gray-400"
                  [ngClass]="{
                    'group-hover:text-gray-500': !navItem.disabled && !router.url.startsWith(navItem.url),
                  }"
                ></span>
                @if (expanded) {
                  <span class="ml-2 text-gray-700">{{ t(navItem.name) }}</span>
                }
              </div>
              @if (expanded && navItem.badge) {
                <IBadge
                  [variant]="navItem.badge.variant"
                  [color]="navItem.badge.color"
                  [label]="t(navItem.badge.text)"
                  size="xs"
                />
              }
            </a>
          </li>
        }
      } @else {
        @if (expanded && navItem["category"]) {
          <span class="my-2 text-xs text-gray-600">{{ t(navItem["category"]) }}</span>
        } @else if (navItem["drawline"]) {
          <hr class="my-2 h-px w-full border-0 bg-gray-200" />
        }
      }
    }
    @if (expanded) {
      <li class="ml-2 mt-7 flex items-center">
        <a
          class="text-main group flex w-full items-center justify-between rounded-md p-1 font-semibold !no-underline duration-75 hover:bg-gray-100"
          (click)="useOldUi(); $event.preventDefault()"
        >
          <div class="flex items-center">
            <span class="icon-[mdi--restore] shrink-0 text-2xl text-gray-400"></span>

            <span class="ml-2 text-gray-700">{{ t("v2-sidebar.back_to_old_ui") }}</span>
          </div>
        </a>
      </li>
    }
  </ul>
</nav>
