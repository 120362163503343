import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatchType } from "@front/m19-api-client";
import { IBadgeComponent, ICheckboxComponent } from "@front/m19-ui";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { TargetingItemSelection } from "../../strategies/keywords-list/keywords-list.component";

@Component({
  selector: "app-keyword-item",
  standalone: true,
  templateUrl: "./keyword-item.component.html",
  imports: [IBadgeComponent, ICheckboxComponent, TranslocoRootModule],
})
export class KeywordItemComponent {
  @Input({ required: true }) keyword!: TargetingItemSelection;
  @Input({ required: true }) selected!: boolean;
  @Input() isReadOnly = false;
  @Input() isSelectable = false;
  @Input() isLast = false;

  @Output() keywordSelected = new EventEmitter<TargetingItemSelection>();

  toggleKeyword() {
    if (this.isReadOnly) return;
    this.keywordSelected.emit(this.keyword);
  }

  protected readonly MatchType = MatchType;
}
