import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

export interface SlideToggleParams {
  isHidden?: boolean;
  isChecked?: boolean;
  isDisabled?: boolean;
  onChange?: (event: MatSlideToggleChange) => void;
  tooltip?: string;
}

@Component({
  selector: "app-slide-toggle-renderer",
  template: ` <div>
    <mat-slide-toggle
      color="primary"
      [matTooltip]="params.tooltip ?? ''"
      [hidden]="params.isHidden"
      [checked]="params.isChecked"
      [disabled]="params.isDisabled"
      (change)="params.onChange && params.onChange($event)"
    >
    </mat-slide-toggle>
  </div>`,
  styleUrls: ["./slide-toggle-renderer.component.scss"],
})
export class SlideToggleRendererComponent implements ICellRendererAngularComp {
  params!: SlideToggleParams;

  agInit(params: ICellRendererParams & SlideToggleParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  onChange(event: MatSlideToggleChange) {
    if (this.params?.onChange) this.params?.onChange(event);
  }
}
