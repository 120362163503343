import { Strategy } from "@front/m19-api-client";
import { PALETTE } from "@front/m19-metrics";
import { BrandingFilter, ProductGroupEx, StrategyEx } from "@front/m19-models";
import { Option } from "@front/m19-ui";
import { TranslocoService } from "@jsverse/transloco";
import { Filter } from "@m19-board/shared/filter/filter.component";

export enum FilterType {
  KeywordSegment = "KeywordSegment",
  ProductSegment = "ProductSegment",
  ProductGroup = "ProductGroup",
  BrandTraffic = "BrandTraffic",
  Strategy = "Strategy",
  BrandProducts = "BrandProducts",
  CustomField1 = "CustomField1",
  CustomField2 = "CustomField2",
  DspCampaign = "DspCampaign",
  DspCreative = "DspCreative",
  Bulk = "Bulk",
}

export class AsinFilter {
  filters: any;

  productGroups: ProductGroupEx[] = [];
  strategies: StrategyEx[] = [];
  customField1Values: Option<string>[] = [];
  customField2Values: Option<string>[] = [];
  brandTrafficOptions: Option<BrandingFilter>[] = [];

  constructor(private translocoService: TranslocoService) {}

  setProductGroups(productGroups: ProductGroupEx[]) {
    this.productGroups = productGroups;
  }

  setStrategies(strategies: StrategyEx[]) {
    this.strategies = strategies;
  }

  setCustomField1Values(customField1Values: Option<string>[]) {
    this.customField1Values = customField1Values;
  }

  setCustomField2Values(customField2Values: Option<string>[]) {
    this.customField2Values = customField2Values;
  }

  setBrandTrafficOptions(brandTrafficOptions: Option<BrandingFilter>[]) {
    this.brandTrafficOptions = brandTrafficOptions;
  }

  static filterChanged(
    filters: Filter<string | ProductGroupEx | BrandingFilter | StrategyEx | Strategy>[],
    stratAsinMap: Map<number, string[]>,
    customFiel1dMap: Map<string, string[]>,
    customField2Map: Map<string, string[]>,
  ) {
    if (filters.length === 0 || filters.every((f) => f.selectedOptions.length === 0)) {
      return null;
    }
    let asins: Set<string> = new Set();
    for (const filter of filters) {
      const values = filter.selectedOptions.map((v) => v.value);
      // do not consider empty filters
      if (filter.selectedOptions.length === 0) continue;
      if (filter.type === FilterType.ProductGroup) {
        const productGroupAsins = new Set((values as ProductGroupEx[]).flatMap((pg) => pg.items));
        asins = !asins.size ? productGroupAsins : new Set([...asins].filter((x) => productGroupAsins.has(x)));
      } else if (filter.type === FilterType.Strategy) {
        const strategyAsins = new Set((values as Strategy[]).flatMap((s) => stratAsinMap.get(s.strategyId!)!));
        asins = !asins.size ? strategyAsins : new Set([...asins].filter((x) => strategyAsins.has(x)));
      } else if (filter.type === FilterType.BrandProducts) {
        const brandAsins = new Set((values as BrandingFilter[]).flatMap((b) => b.asins));
        asins = !asins.size ? brandAsins : new Set([...asins].filter((x) => brandAsins.has(x)));
      } else if (filter.type === FilterType.CustomField1) {
        const asinsWithCustomField = new Set((values as string[]).flatMap((v) => customFiel1dMap.get(v)!));
        asins = !asins.size ? asinsWithCustomField : new Set([...asins].filter((x) => asinsWithCustomField.has(x)));
      } else if (filter.type === FilterType.CustomField2) {
        const asinsWithCustomField = new Set((values as string[]).flatMap((v) => customField2Map.get(v)!));
        asins = !asins.size ? asinsWithCustomField : new Set([...asins].filter((x) => asinsWithCustomField.has(x)));
      }
    }
    return asins;
  }

  static asinMatchesFilter(asin: string, filter: Set<string> | null): boolean {
    if (!filter) return true;
    return filter.has(asin);
  }

  getFilterTags(): Filter<StrategyEx | ProductGroupEx | BrandingFilter | string>[] {
    return [
      {
        type: FilterType.ProductGroup,
        label: this.translocoService.translate("v2-sidebar.product_groups"),
        tooltip: this.translocoService.translate("sales-advertising.filter_stats_on_products_of_a_product_group"),
        noValuePlaceholder: "No product group available",
        options: () => this.productGroups.map((pg) => ({ label: pg.name, value: pg })),
        color: PALETTE[0],
        unique: true,
        excludeModeAllowed: false,
        selectedOptions: [],
      },
      {
        type: FilterType.Strategy,
        label: this.translocoService.translate("sales-advertising.strategy_products"),
        tooltip: "Filter stats on products advertised by a strategy",
        noValuePlaceholder: "No strategy available",
        options: () => this.strategies.map((s) => ({ label: s.name ?? "", value: s })),
        color: PALETTE[1],
        unique: true,
        excludeModeAllowed: false,
        selectedOptions: [],
      },
      {
        type: FilterType.BrandProducts,
        label: this.translocoService.translate("sov-main-table.brands"),
        tooltip: "Filter stats on products with a specific brand",
        noValuePlaceholder: "No brands available",
        options: () => this.brandTrafficOptions,
        color: PALETTE[2],
        unique: true,
        excludeModeAllowed: false,
        selectedOptions: [],
      },
      {
        type: FilterType.CustomField1,
        label: this.translocoService.translate("custom-field-edition.custom_field_1"),
        tooltip: "Filter stats on product with a specific value in Custom Field 1",
        noValuePlaceholder: "No custom field value available",
        options: () => this.customField1Values,
        color: PALETTE[3],
        unique: true,
        excludeModeAllowed: false,
        selectedOptions: [],
      },
      {
        type: FilterType.CustomField2,
        label: this.translocoService.translate("custom-field-edition.custom_field_2"),
        tooltip: "Filter stats on product with a specific value in Custom Field 2",
        noValuePlaceholder: "No custom field value available",
        options: () => this.customField2Values,
        color: PALETTE[4],
        unique: true,
        excludeModeAllowed: false,
        selectedOptions: [],
      },
    ];
  }
}
