import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { faSquare, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faArrowRight, faCheckSquare, faTimes } from "@fortawesome/free-solid-svg-icons";
import { TranslocoService } from "@jsverse/transloco";
import { CsvExportService, simpleField } from "@m19-board/services/csv-export.service";
import { ICON_SEARCH } from "@m19-board/utils/iconsLabels";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { CatalogBrandEx } from "@front/m19-models";
import { CatalogBrandExtraSegment, Marketplace } from "@front/m19-api-client";
import { CatalogBrandService } from "@front/m19-services";

interface Selectable<T> {
  selected: boolean;
  item: T;
}

@Component({
  selector: "app-brand-alias-modal",
  templateUrl: "./brand-alias-modal.component.html",
  styleUrls: ["./brand-alias-modal.component.scss"],
})
export class BrandAliasModalComponent implements OnInit {
  @Input()
  catalogBrand!: CatalogBrandEx;
  @Input()
  accountId!: string;
  @Input()
  marketplace!: Marketplace;
  @Input() isReadOnly = false;

  name!: string;
  bulkKeywords!: string;
  datasourceKeyword = new MatTableDataSource<Selectable<CatalogBrandExtraSegment>>();
  importErrors!: string[];

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  readonly faArrowRight = faArrowRight;
  readonly faTrash = faTrashAlt;
  readonly faSquare = faSquare;
  readonly faCheckedSquare = faCheckSquare;
  readonly faTimes = faTimes;
  readonly ICON_SEARCH = ICON_SEARCH;

  constructor(
    public bsModalRef: BsModalRef,
    private catalogBrandService: CatalogBrandService,
    private toastrService: ToastrService,
    private csvExportService: CsvExportService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.datasourceKeyword.paginator = this.paginator;
    this.datasourceKeyword.sort = this.sort;
    this.datasourceKeyword.sortingDataAccessor = (item, property): string | number => {
      if (property == "keyword") return item.item.keyword ?? "";
      return "";
    };
    this.datasourceKeyword.filterPredicate = (row, filter) => {
      if (filter) {
        const regexp = new RegExp(filter, "i");
        return regexp.test(row.item.keyword!);
      }
      return true;
    };
    this.datasourceKeyword.data = this.catalogBrand.extraSegments?.map((s) => ({ selected: false, item: s })) ?? [];
  }

  close() {
    this.bsModalRef.hide();
  }

  addKeyword() {
    if (this.keywordToAddInvalid()) {
      return;
    }
    const kw: CatalogBrandExtraSegment[] = this.bulkKeywords.split("\n").map((k) => {
      return { keyword: k };
    });
    const { errors, keywords, addedKeywords } = this.catalogBrandService.checkKeywords(
      kw,
      this.datasourceKeyword.data.map((s) => s.item),
    );
    this.importErrors = errors;
    if (addedKeywords.length == 0) {
      return;
    }
    this.catalogBrandService
      .addKeywordToCatalogBrand(this.accountId, this.marketplace, this.catalogBrand.brandId!, addedKeywords)
      .subscribe({
        next: () => {
          this.toastrService.success(this.translocoService.translate("brand-alias-modal.name_add_success"));
          this.datasourceKeyword.data = keywords.map((s) => ({ selected: false, item: s }));
          this.bulkKeywords = "";
        },
        error: (e) => {
          this.toastrService.error(e, this.translocoService.translate("brand-alias-modal.name_add_error"));
        },
      });
  }

  deleteSelectedKeywords() {
    this.deleteKeywords(this.datasourceKeyword.data.filter((s) => s.selected).map((s) => s.item));
  }

  deleteKeyword(keyword: CatalogBrandExtraSegment) {
    this.deleteKeywords([keyword]);
  }

  private deleteKeywords(toDelete: CatalogBrandExtraSegment[]) {
    if (toDelete.length == 0) {
      return;
    }
    this.catalogBrandService
      .deleteKeywordFromCatalogBrand(this.accountId, this.marketplace, this.catalogBrand.brandId!, toDelete)
      .subscribe({
        next: () => {
          this.toastrService.success(this.translocoService.translate("brand-alias-modal.name_delete_success"));
          this.datasourceKeyword.data = this.datasourceKeyword.data.filter(
            (s) => !toDelete.some((d) => s.item.keyword == d.keyword),
          );
        },
        error: (e) => {
          this.toastrService.error(e, this.translocoService.translate("brand-alias-modal.name_add_error"));
        },
      });
  }

  setFilter(filter: string) {
    this.datasourceKeyword.filter = filter.trim();
  }

  select(keyword: Selectable<CatalogBrandExtraSegment>) {
    keyword.selected = !keyword.selected;
  }

  toggleSelectAll() {
    const selected = this.allSelected();
    this.datasourceKeyword.filteredData.forEach((d) => (d.selected = !selected));
  }

  nbSelectedKw() {
    return this.datasourceKeyword.filteredData.filter((s) => s.selected).length;
  }

  allSelected() {
    return this.datasourceKeyword.filteredData.every((s) => s.selected);
  }

  keywordToAddInvalid() {
    return !this.bulkKeywords || this.bulkKeywords.trim() === "";
  }

  clearErrors() {
    this.importErrors = [];
  }

  downloadFile() {
    if (this.datasourceKeyword.data.length == 0) {
      return;
    }
    this.csvExportService.exportCsv(
      {
        prefix: this.catalogBrand.brandAlias + "_other_brand_names",
      },
      this.datasourceKeyword.data.map((s) => s.item),
      [simpleField("keyword", "OtherBrandName")],
    );
  }
}
