<ng-container *transloco="let t">
  <div class="my-auto flex flex-row">
    <app-fbl-mode [strategy]="strat" [size]="FblModeSize.small" />
    @if (strat.strategyId > 0 && getTooltip(strat) !== "") {
      <div
        class="m-1 pb-0 pr-0 pt-0"
        [matTooltipPosition]="'above'"
        *transloco="let t"
        matTooltip="{{ getTooltip(strat) }}"
      >
        <fa-icon [icon]="faWarning" class="warning align-center" size="lg"></fa-icon>
      </div>
      @if (isDayPartingEnabled(strat)) {
        <div class="pr-2">
          <fa-icon
            class="activated align-center ml-2"
            [icon]="faClock"
            size="lg"
            [matTooltip]="getDayPartingToolTip(strat)"
          ></fa-icon>
        </div>
      }
      <!-- Other Boost -->
      @if (strat.primeDayBoost && (promoBoostActivated$ | async)) {
        <span
          [class]="ICON_BOOST"
          class="text-main text-xl"
          matTooltip="{{ t('strategy-card.boost_has_been_activated_on_this_strategy') }}"
        ></span>
      }
    }
  </div>
</ng-container>
