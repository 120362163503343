<app-stats
  [metricsConfig]="metricsConfig()"
  [gridConfig]="gridConfig()"
  [dataset]="dataSet()"
  [withDateAggregation]="false"
  [dateAggregation]="dateAggregation()"
  [localStorageKey]="localStorageKey"
  [dataLoading]="loading()"
  (metricsSelect)="metricsChanged($event)"
  (dateAggregationChange)="dateAggregationChanged($event)"
  (grid)="grid.set($event)"
  [filters]="filters"
  (filterChange)="filterChanged($event)"
></app-stats>
