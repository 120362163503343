@for (organization of organizations$ | async; track organization) {
  <div class="card card-perso collapse-card border-shadow left-child right-child p-2" *transloco="let t">
    <div
      class="collapse-card-header card-perso-header card-header"
      (click)="organizationVisible.set(organization.id, !organizationVisible.get(organization.id))"
    >
      <span class="icon-prepend float-left">
        <app-dropdown-toggle-icon
          [expand]="organizationVisible.get(organization.id) ?? false"
          [iconClass]="'font-xl'"
        ></app-dropdown-toggle-icon>
      </span>
      <div class="row">
        <div class="col">
          <h5 class="card-name mb-0">{{ organization.organizationName }}</h5>
        </div>
        @if (organization?.hasActiveSubscription() && organization?.organization?.billingPlan?.plan) {
          <div class="col">
            {{ organization.planTitle }}
          </div>
        }
        @if (organization.customer) {
          <div class="col float-right">
            <h5 class="card-name mb-0">
              {{ t("billing-settings.billing_owner") }} {{ organization.customer.m19Email }}
            </h5>
          </div>
        }
      </div>
    </div>
    <IAccordion [isOpen]="!!organizationVisible.get(organization.id)" [animated]="false">
      <div class="card-perso-body bottom-child card-body">
        @if (!canDisplayBilling(organization)) {
          <div class="flex flex-row items-end">
            {{ t("billing-settings.not_available_contact_your_billing_owner") }}
          </div>
        }
        @if (canDisplayBilling(organization)) {
          <span>
            @if (organization?.organization?.billingPlan?.plan) {
              <div class="relative">
                <app-subscription-card
                  [organization]="organization"
                  [dailyAdSpendFees]="(dailyAdSpendFeeMap$ | async)?.get(organization.id) ?? []"
                  [locale]="(locale$ | async) ?? ''"
                  [dailyHourlyKeywords]="organizationKeywordMap.get(organization.id)"
                  class="w-full"
                ></app-subscription-card>
                @if (canUpgrade(organization)) {
                  <div
                    class="upgrade-button absolute m-3"
                    [matTooltip]="
                      !isOrganizationOwner(organization)
                        ? t('billing-settings.only_organization_owner_can_upgrade_to_a_new_plan')
                        : ''
                    "
                  >
                    <button
                      type="button"
                      class="btn btn-primary"
                      (click)="createSubscription()"
                      [disabled]="!isOrganizationOwner(organization)"
                    >
                      <fa-icon [icon]="faRocket" class="font-2xl mr-1 align-middle"></fa-icon>
                      {{ t("common.upgrade") }}
                    </button>
                  </div>
                }
              </div>
            }
            @if (!organization?.organization?.billingPlan?.plan) {
              <button type="button" class="btn btn-ghost-secondary" (click)="createSubscription()">
                <fa-icon [icon]="faPlusCircle" class="font-2xl mr-1 align-middle"></fa-icon>
                {{ t("billing-settings.setup_your_subscription") }}
              </button>
            }
            @if (organization.customer) {
              <div class="card-perso collapse-card border-shadow left-child right-child card mb-4 w-full">
                <div
                  class="collapse-card-header card-perso-header top-child card-header"
                  (click)="creditCardVisible.set(organization.id, !creditCardVisible.get(organization.id))"
                >
                  <span class="icon-prepend float-left">
                    <app-dropdown-toggle-icon
                      [expand]="creditCardVisible.get(organization.id) ?? false"
                      [iconClass]="'font-xl'"
                    ></app-dropdown-toggle-icon>
                  </span>
                  <h5 class="card-name mb-0">{{ t("billing-settings.credit_cards") }}</h5>
                </div>
                <IAccordion [isOpen]="!!creditCardVisible.get(organization.id)" [animated]="false">
                  <div class="card-perso-body bottom-child card-body">
                    @if (!hasACreditCard(organization)) {
                      <button type="button" class="btn btn-ghost-secondary" (click)="updateCreditCard()">
                        <fa-icon [icon]="faPlusCircle" class="font-2xl mr-1 align-middle"></fa-icon>
                        {{ t("billing-settings.setup_a_credit_card") }}
                      </button>
                    }
                    <div class="align-items-middle flex justify-center">
                      <app-credit-card-info [customer]="organization.customer"></app-credit-card-info>
                    </div>
                    @if (hasACreditCard(organization)) {
                      <div class="modal-footer">
                        <div
                          [matTooltip]="
                            !isOrganizationOwner(organization)
                              ? t('billing-settings.only_organization_owner_can_update_credit_card_info')
                              : ''
                          "
                        >
                          <button
                            type="button"
                            class="btn btn-primary"
                            [disabled]="!isOrganizationOwner(organization)"
                            (click)="updateCreditCard()"
                          >
                            {{ t("billing-settings.add_a_credit_card") }}
                          </button>
                        </div>
                      </div>
                    }
                  </div>
                </IAccordion>
              </div>
            }
            @if (organization.customer) {
              <div class="card-perso collapse-card border-shadow left-child right-child card mb-4 w-full">
                <div
                  class="collapse-card-header card-perso-header top-child card-header"
                  (click)="invoiceVisible.set(organization.id, !invoiceVisible.get(organization.id))"
                >
                  <span class="icon-prepend float-left">
                    <app-dropdown-toggle-icon
                      [expand]="invoiceVisible.get(organization.id) ?? false"
                      [iconClass]="'font-xl'"
                    ></app-dropdown-toggle-icon>
                  </span>
                  <h5 class="card-name mb-0">{{ t("billing-settings.invoices_history") }}</h5>
                </div>
                <IAccordion [isOpen]="!!invoiceVisible.get(organization.id)" [animated]="false">
                  <div class="card-perso-body bottom-child card-body">
                    <app-billing-invoices
                      [invoices]="organization.customer.invoices ?? []"
                      [dailyAdSpendFees]="(dailyAdSpendFeeMap$ | async)?.get(organization.id) ?? []"
                    ></app-billing-invoices>
                  </div>
                </IAccordion>
              </div>
            }
            @if (
              organization?.customer ||
              (organization?.isParentPpcBoard && isOrganizationOwner(organization)) ||
              !whiteLabel
            ) {
              <div class="card-perso collapse-card border-shadow left-child right-child card mb-4 w-full">
                <div
                  class="collapse-card-header card-perso-header top-child card-header"
                  (click)="infoVisible.set(organization.id, !infoVisible.get(organization.id))"
                >
                  <span class="icon-prepend float-left">
                    <app-dropdown-toggle-icon
                      [expand]="infoVisible.get(organization.id) ?? false"
                      [iconClass]="'font-xl'"
                    ></app-dropdown-toggle-icon>
                  </span>
                  <h5 class="card-name mb-0">{{ t("billing-settings.billing_info") }}</h5>
                </div>
                <IAccordion [isOpen]="!!infoVisible.get(organization.id)" [animated]="false">
                  <div class="card-perso-body bottom-child card-body">
                    @if (!organization?.customer) {
                      <button type="button" class="btn btn-ghost-secondary" (click)="createCustomer(organization)">
                        <fa-icon [icon]="faPlusCircle" class="font-2xl mr-1 align-middle"></fa-icon>
                        {{ t("billing-settings.setup_your_billing_info") }}
                      </button>
                    }
                    @if (organization?.customer) {
                      <app-billing-customer
                        [customer]="organization.customer"
                        [settingStatus]="SettingStatus.DISPLAY"
                      ></app-billing-customer>
                    }
                    @if (organization?.customer) {
                      <div class="modal-footer">
                        <div
                          [matTooltip]="
                            !isOrganizationOwner(organization)
                              ? t('billing-settings.only_organization_owner_can_update_customer_info')
                              : ''
                          "
                        >
                          <button
                            type="button"
                            class="btn btn-primary"
                            (click)="editCustomer(organization)"
                            [disabled]="!isOrganizationOwner(organization)"
                          >
                            {{ t("billing-settings.update_billing_info") }}
                          </button>
                        </div>
                      </div>
                    }
                  </div>
                </IAccordion>
              </div>
            }
          </span>
        }
      </div>
    </IAccordion>
  </div>
}
