import { Component } from "@angular/core";
import { ICellRendererParams } from "@ag-grid-community/core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { InventoryStats } from "@front/m19-models";
import { Marketplace, Strategy, StrategyStateEnum } from "@front/m19-api-client";
import { ProductDetailsComponent } from "@m19-board/sales-advertising/product-details/product-details.component";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
@Component({
  selector: "app-strategy-count-renderer",
  templateUrl: "./strategy-count-renderer.component.html",
  styleUrls: ["./seller-inventory.component.scss"],
})
export class StrategyCountRendererComponent implements ICellRendererAngularComp {
  params: any;
  product!: InventoryStats;
  strategies!: Map<string, Strategy[]>;
  marketplace?: Marketplace;

  constructor(private modalService: BsModalService) {}
  agInit(
    params: ICellRendererParams & {
      product: InventoryStats;
      marketplace: Marketplace;
      strategies: Map<string, Strategy[]>;
    },
  ): void {
    this.product = params.product;
    this.marketplace = params.marketplace;
    this.strategies = params.strategies;
  }
  refresh(params: ICellRendererParams) {
    return true;
  }
  getActiveStrategies(asin: string) {
    return (this.strategies.get(asin) ?? []).filter((s) => s.state == StrategyStateEnum.ENABLED).length;
  }

  openInfo(asin: string) {
    const modalOptions: ModalOptions = {
      initialState: {
        strategies: this.strategies.get(asin) ?? [],
        asinnumber: asin,
        marketplace: this.marketplace,
      },
    };
    this.modalService.show(ProductDetailsComponent, modalOptions);
  }
}
