<ng-container *transloco="let t">
  @if (strat.strategyId > 0 || strat.strategies?.length === 1) {
    <div class="tactics flex flex-col justify-start">
      @if (getTactics(strat).length > 0) {
        {{ getTacticsLabel(strat) }}
      }
    </div>
  } @else {
    <div>
      @if (strat.brandStrategies.length > 0) {
        <span class="mr-1">{{ t("strategy-table.strat_n_brand_defense", [strat.brandStrategies.length]) }} |</span>
      }
      @if (strat.keywordStrategies.length > 0) {
        <span class="mr-1">{{ t("strategy-table.strat_n_focus", [strat.keywordStrategies.length]) }} |</span>
      }
      <span>{{ t("strategy-table.strat_n_main", [strat.productStrategies.length]) }}</span>
    </div>
  }
</ng-container>
