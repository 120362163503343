<div class="flex h-full w-full flex-col items-center justify-center">
  <app-spinner type="default" size="s" [display]="(loading$ | async) !== false"></app-spinner>
  @if ((loading$ | async) === false) {
    <div class="chart-wrapper">
      <div class="mb-3 flex justify-end">
        <app-date-aggreation-switch-button
          class="aggregation-switch mr-2"
          [options]="dateAggregations"
          [selected]="_dateAggregation"
          (dateAggSelected)="selectDateAggregation($event)"
        ></app-date-aggreation-switch-button>
      </div>
      <canvas
        baseChart
        class="chart"
        type="line"
        [labels]="dataset.labels"
        [options]="dataset.lineChartOptions"
        [datasets]="dataset.chartDataSet"
      >
      </canvas>
    </div>
  }
</div>
