import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'IAccordion',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './iaccordion.component.html',
})
export class IAccordionComponent {
  isOpen = input.required<boolean>();
  animated = input<boolean>(true);
}
