<div class="modal-header">
  <h5 class="modal-title">ASIN Usage</h5>
  <span class="icon-[mdi--close] cursor-pointer text-gray-500 hover:text-gray-700" (click)="ref.hide()"></span>
</div>
<div class="modal-body flex h-[70vh] flex-col overflow-auto">
  @if (am) {
    <div class="flex flex-col gap-2 divide-y divide-gray-200">
      @for (asin of usedStrategies() | keyvalue; track asin) {
        <div class="flex gap-4 py-2">
          <app-product-thumbnail
            [asin]="asin.key"
            [marketplace]="am.marketplace"
            [smallImg]="true"
            [withTitle]="true"
          />
          <div class="mt-3 flex flex-col gap-2">
            @for (strategy of asin.value; track strategy) {
              <app-strategy-link [strategyId]="strategy.strategyId!" />
            }
          </div>
        </div>
      }
    </div>
  }
</div>
