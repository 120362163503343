@if (display) {
  <div class="flex items-center gap-2" *transloco="let t">
    <div class="product-image select-none" [ngClass]="customSizeClass ?? (smallImg ? 'size-10' : 'size-20')">
      @if (productUrl()) {
        <img
          class="h-full"
          [matTooltip]="_product()?.imageUrl ? '' : _product()?.asin"
          [ngSrc]="productUrl()"
          [alt]="_product()?.asin"
          fill="contains"
        />
      }
      @if (notBelongCatalogBadge) {
        <span class="catalog-badge" matTooltip="{{ t('product-thumbnail.this_asin_is_not_in_your_catalog') }}">
          <fa-icon [icon]="faStoreSlash"></fa-icon>
        </span>
      }
      @if (fbaBadge && fbmBadge) {
        <span class="fbm-badge fb-badge fb-badge-first" matTooltip="{{ t('product-thumbnail.fulfilled_by_merchant') }}"
          ><fa-icon [icon]="faMerchant"></fa-icon
        ></span>
      }
      @if (fbaBadge) {
        <span class="fba-badge fb-badge" matTooltip="{{ t('product-thumbnail.fulfilled_by_amazon') }}"
          ><fa-icon [icon]="faAmazon"></fa-icon
        ></span>
      }
      @if (!fbaBadge && fbmBadge) {
        <span class="fbm-badge fb-badge" matTooltip="{{ t('product-thumbnail.fulfilled_by_merchant') }}"
          ><fa-icon [icon]="faMerchant"></fa-icon
        ></span>
      }
      @if (childAsins && _product()?.childAsins) {
        <span
          class="child-asin-badge number"
          matTooltip="{{ t('product-thumbnail.child_asins', { count: _product()!.childAsins!.length }) }}"
          >{{ _product()!.childAsins!.length }}</span
        >
      }
    </div>

    @if (withTitle) {
      <span>{{ _product()!.asin }}</span>
    }
  </div>
}
