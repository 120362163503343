<ng-container *transloco="let t">
  <app-stats-overlay>
    <div class="box card border-shadow px-2 py-2">
      <app-traffic-stats-filter
        class="w-full"
        [initSegment]="initSegment!"
        (filterChange)="updateTrafficStatsFilter($event)"
      >
        <!-- TODO: Move button outside ng-content -->
        <app-export-button
          (export)="exportGridCsv()"
          tooltipValue="{{ t('common.export_as_csv') }}"
        ></app-export-button>
        @if (!chartDisplayed) {
          <IButton
            [icon]="ICON_CHART"
            color="white"
            (onClick)="toggleChartDisplay(true)"
            tooltipValue="{{ t('common.show_charts') }}"
          />
        }
      </app-traffic-stats-filter>
    </div>
    <div class="metrics-header mb-4 mt-4">
      <app-metric-selector
        [data]="aggregatedData!"
        [previousPeriodData]="previousAggregatedData"
        (chartMetricsChanges)="selectMetric($event)"
        [pageMetrics]="ChartMetrics"
        [localStorageKey]="localStorageKey"
        [singleMetricMode]="true"
      ></app-metric-selector>
    </div>
    <div class="cards" [hidden]="!chartDisplayed">
      <div class="top-cards">
        <div class="border-shadow top-kw-card card mb-4 mr-2 mt-4 w-1/2 px-2 py-2">
          <h3 class="text-center">{{ t("metrics." + mainMetric.id + "_title") }}</h3>
          <h3 class="text-center">{{ t("traffic-stats.top_kw_searched_term") }}</h3>
          <app-top-queries-list
            [loadingStatus]="(loader$ | async) || topKwLoadingStatus"
            [data]="topKeywords"
            [metric]="mainMetric"
            [locale]="locale"
            [currency]="currency"
            [marketplace]="accountMarketplace?.marketplace"
          ></app-top-queries-list>
        </div>
        <div class="border-shadow top-product-card card mb-4 mr-2 mt-4 w-1/2 px-2 py-2">
          <div class="flex">
            <h3 class="flex-auto text-center">{{ t("metrics." + mainMetric.id + "_title") }}</h3>
            <IButton
              [icon]="ICON_CLOSE"
              color="gray"
              variant="ghost"
              size="xs"
              [square]="true"
              (onClick)="toggleChartDisplay(false)"
              tooltipValue="{{ t('overview.hide_charts') }}"
            />
          </div>
          <h3 class="text-center">{{ t("traffic-stats.top_targeted_product") }}</h3>
          <app-top-queries-list
            [loadingStatus]="(loader$ | async) || topProductLoadingStatus"
            [data]="topProducts"
            [metric]="mainMetric"
            [locale]="locale"
            [currency]="currency"
            [marketplace]="accountMarketplace?.marketplace"
          ></app-top-queries-list>
        </div>
      </div>
      <div class="border-shadow share-chart-card card mb-4 mr-2 mt-4 w-1/2 px-2 py-2">
        <div class="flex">
          <h3 class="flex-auto text-center">
            {{ t("traffic-stats.metric_share", [t("metrics." + mainMetric.id + "_title")]) }}
          </h3>
          <div>
            <IButton
              [icon]="ICON_CLOSE"
              color="gray"
              variant="ghost"
              size="xs"
              (onClick)="toggleChartDisplay(false)"
              tooltipValue="{{ t('overview.hide_charts') }}"
            />
          </div>
        </div>
        <h3>&nbsp;</h3>
        <app-traffic-stats-chart
          [loader]="(loader$ | async) ?? false"
          [barChartData]="barChartData"
          [pieChartData]="pieChartData"
          [metric]="mainMetric"
          [locale]="locale"
          [currency]="currency"
        ></app-traffic-stats-chart>
      </div>
    </div>

    <div class="ag-theme-quartz flex h-5/6 flex-col">
      <ag-grid-angular
        #grid
        class="flex-1"
        [rowData]="gridData"
        [statusBar]="STATUS_BAR"
        [gridOptions]="gridOptions"
        [getGroupRowAgg]="getGroupRowAgg"
      />
    </div>
  </app-stats-overlay>
</ng-container>
