import { AsyncPipe, NgClass } from "@angular/common";
import { Component, computed, inject, input, Input, OnInit, output, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import {
  AccountMarketplace,
  AccountType,
  AlgoMode,
  BrandAsset,
  CampaignType,
  Currency,
  MediaType,
  SbCreative,
  SbCreativeType,
  State,
  Strategy,
  StrategyStateEnum,
  TacosStrategyGroup,
} from "@front/m19-api-client";
import { StrategyEx } from "@front/m19-models";
import {
  AccountSelectionService,
  AsinService,
  AuthService,
  NotificationService,
  SbStrategiesService,
  StrategyService,
  TacosStrategiesService,
  UserSelectionService,
} from "@front/m19-services";
import { IBadgeComponent, IButtonComponent } from "@front/m19-ui";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { ICON_ARROW_LEFT, ICON_CHEVRON_DOWN, ICON_CLOSE } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { combineLatest, map, Observable, of, switchMap, tap } from "rxjs";
import { SbAdFormat, SbFormAdFormatComponent } from "./sb-form-ad-format/sb-form-ad-format.component";
import { SbAlgo, SbFormAlgoComponent } from "./sb-form-algo/sb-form-algo.component";
import { SbAsinGroup, SbFormAsinsComponent } from "./sb-form-asins/sb-form-asins.component";
import { SbFormCreaPreviewComponent } from "./sb-form-crea-preview/sb-form-crea-preview.component";
import { SbCrea, SbFormCreativeComponent } from "./sb-form-creative/sb-form-creative.component";
import { SbFormInfoComponent, SbInfo } from "./sb-form-info/sb-form-info.component";

export enum FormMode {
  EDIT_CREATIVE,
  NEW_CREATIVE,
  NEW_STRATEGY,
}

export enum LandingPage {
  ProductListing = "Product Listing",
  Store = "Amazon Store",
}

// Amazon brand name max length policy
// https://advertising.amazon.com/API/docs/en-us/sponsored-brands/3-0/openapi/prod#tag/Ad-creatives/operation/CreateBrandVideoCreative
export const BRAND_NAME_MAX_LENGTH = 30;
export const AMAZON_HOME_STORE_PAGE_PATH = "Home";

export enum SbFormStep {
  INFO = "Strategy Information",
  ALGO = "Strategy Algorithm",
  AD_FORMAT = "Ad Format",
  CREATIVE = "Creative",
  ASINS = "ASINs",
  REVIEW = "Review",
}

enum StepStatus {
  VALID,
  INVALID,
  PENDING,
  CURRENT,
}

export interface FormStepEvent<T extends SbInfo | SbAlgo | SbCrea | SbAsinGroup | SbAdFormat> {
  formData: T;
  step: SbFormStep;
  goNext: boolean;
}

export interface SbBrandAssets {
  brandLogos: BrandAsset[];
  customImages: BrandAsset[];
  videos: BrandAsset[];
  storePages: BrandAsset[];
}

@UntilDestroy()
@Component({
  selector: "app-sb-strategy-form",
  templateUrl: "./sb-strategy-form.component.html",
  standalone: true,
  imports: [
    SbFormInfoComponent,
    SbFormAlgoComponent,
    SbFormAdFormatComponent,
    SbFormCreativeComponent,
    SbFormAsinsComponent,
    SbFormCreaPreviewComponent,
    NgClass,
    IBadgeComponent,
    IButtonComponent,
    AsyncPipe,
    TranslocoRootModule,
  ],
})
export class SbStrategyFormComponent implements OnInit {
  private readonly accountSelection = inject(AccountSelectionService);
  private readonly userSelectionService = inject(UserSelectionService);
  private readonly sbStrategyService = inject(SbStrategiesService);
  private readonly tacosStrategiesService = inject(TacosStrategiesService);
  private readonly notificationService = inject(NotificationService);
  private readonly asinService = inject(AsinService);
  private readonly toasterService = inject(ToastrService);
  private readonly router = inject(Router);
  readonly bsModalRef = inject(BsModalRef);
  private readonly authService = inject(AuthService);
  private readonly strategyService = inject(StrategyService);

  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;
  readonly ICON_ARROW_LEFT = ICON_ARROW_LEFT;
  readonly ICON_CLOSE = ICON_CLOSE;

  @Input() set formMode(mode: FormMode) {
    this._formMode.set(mode);
  }

  _formMode = signal(FormMode.NEW_STRATEGY);
  formTitle = computed(() => {
    if (this._formMode() === FormMode.NEW_CREATIVE) return `New Ad Line`;
    if (this._formMode() === FormMode.EDIT_CREATIVE) return `Edit Ad Line - ${this.creative?.headline}`;
    if (this.tacosStrategy()) return "Add SB to Tacos Strategy";
    return "New Sponsored Brand Strategy";
  });
  formStep = signal(SbFormStep.INFO);
  submitLabel = computed(() => {
    if (this._formMode() === FormMode.NEW_CREATIVE) return "Create Ad Line";
    if (this._formMode() === FormMode.EDIT_CREATIVE) return "Update Ad Line";
    return "Create Strategy";
  });

  // creative edition
  @Input() creative: SbCreative | undefined;
  @Input() strategy: StrategyEx | undefined;

  // @Input is set for new creative, default to product collection
  @Input() sbCreativeType: SbCreativeType = SbCreativeType.productCollection;

  // new SB for tacos strategy
  tacosStrategy = input<TacosStrategyGroup | undefined>();
  tacosStrategyId = computed(() => this.tacosStrategy()?.tacosStrategyGroupId);
  tacosStrategyName = input<string | undefined>();

  onStrategyCreated = output<void>();
  onCancel = output<void>();

  am: AccountMarketplace | undefined;
  isVendor = false;
  currency$: Observable<Currency> = this.userSelectionService.selectedCurrency$;
  previewHidden = true;
  brandAssets: SbBrandAssets | undefined;

  loadingStatus = signal<string | null>(null);
  steps = signal([
    SbFormStep.INFO,
    SbFormStep.ALGO,
    SbFormStep.AD_FORMAT,
    SbFormStep.CREATIVE,
    SbFormStep.ASINS,
    SbFormStep.REVIEW,
  ]);

  readonly editionSteps = [SbFormStep.CREATIVE, SbFormStep.ASINS, SbFormStep.REVIEW];
  readonly newCreativeSteps = [SbFormStep.AD_FORMAT, SbFormStep.CREATIVE, SbFormStep.ASINS, SbFormStep.REVIEW];

  stepDisabled = new Map<SbFormStep, boolean>([
    [SbFormStep.INFO, false],
    [SbFormStep.ALGO, true],
    [SbFormStep.AD_FORMAT, true],
    [SbFormStep.CREATIVE, true],
    [SbFormStep.ASINS, true],
    [SbFormStep.REVIEW, true],
  ]);
  // form sections
  formInfo: SbInfo | undefined; // SbFormStep.INFO
  formAlgo: SbAlgo | undefined; // SbFormStep.ALGO
  formCrea: SbCrea | undefined; // SbFormStep.CREATIVE
  formAdFormat: SbAdFormat | undefined; // SbFormStep.AD_FORMAT
  formAsins: SbAsinGroup | undefined; // SbFormStep.ASINS

  $sbStrategyUrl = toSignal(
    this.authService.loggedUser$.pipe(
      map((user) => {
        return (user?.uiVersion ?? 0) > 0 ? "/advertising/sponsored-brands" : "/strategies/sponsored-brands";
      }),
    ),
    { initialValue: "/strategies/sponsored-brands" },
  );

  ngOnInit(): void {
    if (this.tacosStrategyId()) {
      // remove algo step
      this.steps.set(this.steps().filter((step) => step !== SbFormStep.ALGO && step !== SbFormStep.INFO));
      this.formAlgo = {
        tacosTarget: 0.25,
        algoMode: AlgoMode.TACOS_TARGET,
      } as SbAlgo;
      this.formInfo = {
        brand: null,
        name: "",
      };
    }
    // Setup forms if this is a creative edition
    if (this._formMode() === FormMode.EDIT_CREATIVE) {
      this.setupEditionForm();
    }
    if (this._formMode() === FormMode.NEW_CREATIVE) {
      this.setupNewCreativeForm();
    }

    const sbEligibility$ = this.accountSelection.singleAccountMarketplaceSelection$.pipe(
      switchMap((am) => this.asinService.getCatalog(am.accountId, am.marketplace)),
      map((catalog) => catalog.getSBEligibility()),
    );

    // Fetch brand assets
    combineLatest([
      this.accountSelection.singleAccountMarketplaceSelection$.pipe(
        switchMap((am) => this.sbStrategyService.getBrandAssets(am.accountId, am.marketplace)),
      ),
      sbEligibility$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([brandAssets, sbEligibility]) => {
        this.brandAssets = {
          brandLogos: brandAssets.filter((ba) => ba.mediaType === MediaType.brandLogo),
          customImages: brandAssets.filter((ba) => ba.mediaType === MediaType.customImage),
          videos: brandAssets.filter((ba) => ba.mediaType === MediaType.videoAsset),
          storePages: brandAssets
            .filter((ba) => ba.mediaType === MediaType.storePage)
            .map((s) => this.filterIneligibleAsins(s, sbEligibility)),
        };
        // Setup existing brand assets for creative edition
        if (this._formMode() === FormMode.EDIT_CREATIVE && this.formCrea && this.creative) {
          this.formCrea.brandLogo = brandAssets.find((ba) => ba.assetId === this.creative!.logoAssetId)!;
          this.formCrea.customImage = this.getCustomImageFromBrandAssets(
            brandAssets,
            this.creative!.customImageAssetId!,
          )!;
          this.formCrea.customImage2 = this.getCustomImageFromBrandAssets(
            brandAssets,
            this.creative.customImageAssetId2!,
          )!;
          this.formCrea.customImage3 = this.getCustomImageFromBrandAssets(
            brandAssets,
            this.creative.customImageAssetId3!,
          )!;
          this.formCrea.customImage4 = this.getCustomImageFromBrandAssets(
            brandAssets,
            this.creative.customImageAssetId4!,
          )!;
          this.formCrea.customImage5 = this.getCustomImageFromBrandAssets(
            brandAssets,
            this.creative.customImageAssetId5!,
          )!;
          this.formCrea.video = brandAssets.find((ba) => ba.assetId === this.creative!.videoAssetId)!;
          this.formAdFormat = {
            ...this.formAdFormat!,
            store: brandAssets.find((ba) => ba.assetId === this.creative!.storePageId)!,
          };
        }
      });

    this.accountSelection.singleAccountMarketplaceSelection$
      // pipe switch map to get brands
      .pipe(
        switchMap((am) => {
          this.am = am;
          this.isVendor = am.accountType === AccountType.VENDOR;
          return this.sbStrategyService.getBrands(am.accountId, am.marketplace);
        }),
      )
      .subscribe((brands) => {
        if (this._formMode() === FormMode.NEW_CREATIVE || this._formMode() === FormMode.EDIT_CREATIVE) {
          // setup brand name
          this.formInfo = {
            brand: this.isVendor ? null : brands.find((brand) => brand.brandEntityId === this.strategy!.brandEntityId)!,
            name: this.strategy!.name!,
          };
        }
      });
  }

  private filterIneligibleAsins(
    storePage: BrandAsset,
    sbEligibility: Map<
      string,
      {
        status: boolean;
        reason: string;
      }
    >,
  ): BrandAsset {
    storePage.asinList = storePage.asinList?.filter((a) => sbEligibility.get(a)?.status);
    return storePage;
  }

  private getCustomImageFromBrandAssets(brandAssets: BrandAsset[], assetId: string): BrandAsset | undefined {
    return brandAssets.filter((ba) => ba.assetId === assetId)[0];
  }

  private setupNewCreativeForm() {
    this.steps.set(this.newCreativeSteps);
    this.formStep.set(this.steps()[0]);
    // set first step as available
    this.stepDisabled.set(this.steps()[0], false);
  }

  private setupEditionForm() {
    this.steps.set(this.editionSteps);
    this.formStep.set(this.steps()[0]);
    // set all steps as available
    this.steps().forEach((step) => {
      this.stepDisabled.set(step, false);
    });

    this.formCrea = {
      brandName: this.creative!.brandName!,
      headline: this.creative!.headline!,
      brandLogo: null,
      customImage: null,
      customImage2: null,
      customImage3: null,
      customImage4: null,
      customImage5: null,
      video: null,
    };
    this.formAsins = {
      asins: this.creative!.creativeAsins!,
    };

    this.sbCreativeType = this.creative!.creativeType!;
    // for old creative without custom images, force to set one
    if (
      (this.sbCreativeType === SbCreativeType.brandVideo || this.sbCreativeType === SbCreativeType.productCollection) &&
      !this.creative!.customImageAssetId
    ) {
      this.stepDisabled.set(SbFormStep.ASINS, true);
      this.stepDisabled.set(SbFormStep.REVIEW, true);
    }
  }

  handleStepperClick(step: SbFormStep) {
    if (this.stepDisabled.get(step) || step === this.formStep()) return;
    this.formStep.set(step);
  }

  onFormChange(event: FormStepEvent<SbInfo | SbAlgo | SbCrea | SbAdFormat | SbAsinGroup>) {
    // if going next, no need to update the form data
    // we know that the form data is valid as the button is enabled
    if (event.goNext) {
      this.formStep.set(this.steps()[this.steps().indexOf(event.step) + 1]);
      this.stepDisabled.set(this.formStep(), false);

      // first time we visit the ad format step, we need to initialize the form data
      // this step is the only one which does not require any user input to be valid
      if (this.formStep() === SbFormStep.AD_FORMAT && !this.formAdFormat) {
        this.formAdFormat = {
          creativeType: SbCreativeType.productCollection,
          landingPage: LandingPage.ProductListing,
          store: null,
        } as SbAdFormat;
      }
    } else {
      switch (event.step) {
        case SbFormStep.INFO:
          this.onFormInfo(event as FormStepEvent<SbInfo>);
          break;
        case SbFormStep.ALGO:
          this.formAlgo = event.formData as SbAlgo;
          break;
        case SbFormStep.AD_FORMAT:
          this.onAdFormatFormChange(event as FormStepEvent<SbAdFormat>);
          break;
        case SbFormStep.CREATIVE:
          this.setFormDataFromSbCrea(event.formData as SbCrea);
          break;
        case SbFormStep.ASINS:
          this.formAsins = event.formData as SbAsinGroup;
          break;
      }
    }
  }

  private setFormDataFromSbCrea(sbCrea: SbCrea) {
    this.formCrea = {
      ...sbCrea,
      customImage: sbCrea.customImage,
      customImage2: sbCrea.customImage2,
      customImage3: sbCrea.customImage3,
      customImage4: sbCrea.customImage4,
      customImage5: sbCrea.customImage5,
    };
  }

  private onFormInfo(event: FormStepEvent<SbInfo>) {
    // if creative step has not been visited or brand has changed, reset creative form
    if (!this.formCrea || this.brandHasChanged(this.formInfo!, event.formData as SbInfo)) {
      // prevent setting brand name if account is vendor
      if (this.am!.accountType !== AccountType.VENDOR)
        this.formCrea = {
          brandName: (event.formData as SbInfo).brand!.brandName!.substring(0, BRAND_NAME_MAX_LENGTH),
          headline: "",
          brandLogo: null,
          customImage: null,
          customImage2: null,
          customImage3: null,
          customImage4: null,
          customImage5: null,
          video: null,
        };
      // if changing brand, creative for is reseted
      // force the user to go through the Ad Format step again
      this.formAsins = undefined;
      this.formAdFormat = undefined;
      this.stepDisabled.set(SbFormStep.CREATIVE, true);
      this.stepDisabled.set(SbFormStep.ASINS, true);
      this.stepDisabled.set(SbFormStep.REVIEW, true);
    }
    this.formInfo = event.formData as SbInfo;
  }

  private onAdFormatFormChange(event: FormStepEvent<SbAdFormat>) {
    // if changing ad format, reset all the next steps
    if (this.am!.accountType !== AccountType.VENDOR)
      this.formCrea = {
        brandName: this.formInfo!.brand!.brandName!.substring(0, BRAND_NAME_MAX_LENGTH),
        headline: "",
        brandLogo: null,
        customImage: null,
        customImage2: null,
        customImage3: null,
        customImage4: null,
        customImage5: null,
        video: null,
      };
    else this.formCrea = undefined;

    this.formAsins = undefined;
    this.stepDisabled.set(SbFormStep.CREATIVE, true);
    this.stepDisabled.set(SbFormStep.ASINS, true);
    this.stepDisabled.set(SbFormStep.REVIEW, true);

    this.formAdFormat = event.formData as SbAdFormat;
    this.sbCreativeType = this.formAdFormat.creativeType;
  }

  private brandHasChanged(current: SbInfo, next: SbInfo): boolean {
    if (this.isVendor) return false;
    return current?.brand!.brandEntityId !== next.brand!.brandEntityId;
  }

  handleSubmit() {
    if (this._formMode() === FormMode.NEW_STRATEGY) this.createStrategy();
    else if (this._formMode() === FormMode.EDIT_CREATIVE) this.updateCreative();
    else this.makeCreative();
  }

  private createStrategy() {
    // should not happen
    if (!this.formInfo || !this.formAlgo || !this.formCrea || !this.formAsins || !this.am) return;
    if (this.tacosStrategyName()) {
      this.formInfo.name = this.tacosStrategyName()!;
    }
    const toCreate: Strategy = {
      accountId: this.am!.accountId,
      marketplace: this.am!.marketplace,
      campaignType: CampaignType.SB,
      tactics: [],
      audienceTargetings: [],
      defaultStrategy: false,
      state: StrategyStateEnum.ENABLED,
      brandEntityId: this.am!.accountType === AccountType.VENDOR ? undefined : this.formInfo.brand!.brandEntityId,
      ...this.formInfo,
      ...this.formAlgo,
      acosTarget: this.formAlgo.acosTarget ? +this.formAlgo.acosTarget / 100 : undefined,
    };

    this.loadingStatus.set("Creating strategy...");

    // create strategy
    this.strategyService
      .createStrategy(toCreate, this.am.resourceOrganizationId!)
      .pipe(
        tap((_) => this.loadingStatus.set("Setup creative...")),
        switchMap((s: Strategy) => {
          const creative = this.getCreative(s);
          // attach creative
          return this.sbStrategyService.createSbCreative(creative);
        }),
        tap((_) => this.loadingStatus.set("Add ASINs...")),
        switchMap((creative: SbCreative) => {
          // add ASINs to creative
          return this.sbStrategyService
            .updateSbCreativeClusterAsync(creative, this.formAsins!.asins)
            .pipe(map(() => creative));
        }),
        tap({
          next: () => {
            this.toasterService.success("SB Strategy Created");
            this.loadingStatus.set(null);
          },
          error: (e) => {
            this.loadingStatus.set(null);
            this.toasterService.error(e);
          },
        }),
        switchMap((creative: SbCreative) => {
          if (this.tacosStrategyId()) {
            return this.tacosStrategiesService.updateTacosStrategyGroup({
              accountId: this.am!.accountId,
              marketplace: this.am!.marketplace,
              tacosStrategyGroupId: this.tacosStrategyId()!,
              sbStrategyId: creative.strategyId,
            });
          }
          return of(creative);
        }),
      )
      .subscribe((res: TacosStrategyGroup | SbCreative) => {
        if (this.tacosStrategyId()) {
          this.onStrategyCreated.emit();
        } else {
          this.router.navigate([this.$sbStrategyUrl() + "/" + (res as SbCreative).strategyId]);
        }
      });
  }

  private updateCreative() {
    const currentCreative = this.creative;
    const newCreative = this.getCreative(this.strategy!);

    this.loadingStatus.set("Updating creative...");
    const creativeObservable = this.sbStrategyService.updateSbCreative(currentCreative!, newCreative).pipe(
      switchMap(() => this.sbStrategyService.updateSbCreativeClusterAsync(currentCreative!, this.formAsins!.asins)),
      switchMap(() =>
        this.notificationService.deleteSbRejectedCreative(newCreative.strategyId, currentCreative!.creativeId),
      ),
      map((_) => newCreative),
    );

    this.processCreative(creativeObservable, "SB Creative Updated", "SB Creative Update Error");
  }

  private makeCreative() {
    this.loadingStatus.set("Creating Ad Line...");
    const newCreative = this.getCreative(this.strategy!);
    const creativeObservable = this.sbStrategyService
      .createSbCreative(newCreative)
      .pipe(
        switchMap((creative: SbCreative) =>
          this.sbStrategyService
            .updateSbCreativeClusterAsync(creative, this.formAsins!.asins)
            .pipe(map(() => creative)),
        ),
      );

    this.processCreative(creativeObservable, "SB Creative Created", "SB Creative Creation Error");
  }

  private processCreative(creativeObservable: Observable<SbCreative>, successMessage: string, errorMessage: string) {
    creativeObservable.subscribe({
      next: () => this.handleCreativeResponse(successMessage),
      error: (err) => this.handleCreativeError(err, errorMessage),
    });
  }

  private handleCreativeResponse(successMessage: string) {
    this.toasterService.success(successMessage);
    this.loadingStatus.set(null);
    this.bsModalRef.hide();
  }

  private handleCreativeError(error: any, errorMessage: string) {
    this.toasterService.error(error, errorMessage);
    this.loadingStatus.set(null);
    this.bsModalRef.hide();
  }

  private getCreative(strategy: Strategy): SbCreative {
    return {
      creativeId: -1,
      strategyId: strategy.strategyId!,
      accountId: strategy.accountId,
      marketplace: strategy.marketplace,
      brandEntityId: strategy.brandEntityId,
      brandName: this.formCrea!.brandName,
      logoAssetId: this.formCrea!.brandLogo?.assetId,
      customImageAssetId: this.formCrea!.customImage?.assetId,
      customImageAssetId2: this.formCrea!.customImage2?.assetId,
      customImageAssetId3: this.formCrea!.customImage3?.assetId,
      customImageAssetId4: this.formCrea!.customImage4?.assetId,
      customImageAssetId5: this.formCrea!.customImage5?.assetId,
      headline: this.formCrea!.headline!,
      storePageId: this.formAdFormat!.store?.assetId,
      videoAssetId: this.formCrea!.video?.assetId,
      creativeAsins: [],
      state: State.ON,
      creativeType: this.formAdFormat!.creativeType!,
    };
  }

  getStepStatus(step: SbFormStep): StepStatus {
    if (this.stepDisabled.get(step)) return StepStatus.PENDING;
    if (step === this.formStep()) return StepStatus.CURRENT;
    switch (step) {
      case SbFormStep.INFO:
        return this.formInfo ? StepStatus.VALID : StepStatus.INVALID;
      case SbFormStep.ALGO:
        return this.formAlgo ? StepStatus.VALID : StepStatus.INVALID;
      case SbFormStep.AD_FORMAT:
        return this.formAdFormat ? StepStatus.VALID : StepStatus.INVALID;
      case SbFormStep.CREATIVE:
        return this.formCrea ? StepStatus.VALID : StepStatus.INVALID;
      case SbFormStep.ASINS:
        return this.formAsins ? StepStatus.VALID : StepStatus.INVALID;
      case SbFormStep.REVIEW:
        return StepStatus.VALID;
    }
  }

  readonly SbFormStep = SbFormStep;
  readonly StepStatus = StepStatus;
  readonly SbCreativeType = SbCreativeType;
  readonly FormMode = FormMode;
}
