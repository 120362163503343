import { Component, OnInit, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { StripeCardElementOptions, StripeElementsOptions } from "@stripe/stripe-js";
import { AuthService, BillingService } from "@front/m19-services";
import { environment } from "../../../../environments/environment";
import { BsModalRef } from "ngx-bootstrap/modal";
import { injectStripe, StripeCardComponent } from "ngx-stripe";
import { ToastrService } from "ngx-toastr";
import { AjaxError } from "rxjs/ajax";
import { Customer, Response, User } from "@front/m19-api-client";

@UntilDestroy()
@Component({
  selector: "app-modal-content",
  templateUrl: "stripe-create-token.component.html",
  styleUrls: ["./stripe-create-token.component.scss"],
})
export class ModalStripeCreateTokenComponent implements OnInit {
  @ViewChild(StripeCardComponent) card!: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: "#666EE8",
        color: "#31325F",
        fontWeight: "300",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: "16px",
        "::placeholder": {
          color: "#CFD7E0",
        },
      },
    },
    hideIcon: false, // enable car brand choice https://docs.stripe.com/co-badged-cards-compliance?type=web-elements&ui=card-element
  };

  elementsOptions: StripeElementsOptions = {
    locale: "auto",
  };
  stripe = injectStripe(environment.stripePublicKey);

  user?: User;
  customer?: Customer;

  constructor(
    public bsModalRef: BsModalRef,
    private authService: AuthService,
    private billingService: BillingService,
    private toasterService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((x: User) => {
      this.user = { ...x };
    });
    this.billingService.organizationOnwer$.pipe(untilDestroyed(this)).subscribe((x) => (this.customer = x!.customer));
  }

  createToken(): void {
    this.stripe
      .createPaymentMethod({
        type: "card",
        card: this.card.element,
      })
      .subscribe((result) => {
        if (result.paymentMethod) {
          this.customer!.m19Email = this.user!.email;
          this.billingService.linkCreditCard(
            this.customer!,
            result.paymentMethod.id,
            (response: Response) => {
              this.toasterService.success("Credit card successfully registered");
            },
            (error: AjaxError) => {
              this.toasterService.error(error.response ? error.response.message : "Unknown error", error.name);
            },
          );
        } else {
          this.toasterService.error(result.error.message ? result.error.message : "Unknown error", result.error.type);
        }
      });
  }
}
