import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { AccountSelectionService, AuthService, UserSelectionService } from "@front/m19-services";
import { Metric, MetricCategory, RatioMetric, SummableMetric, SupportedAccountType } from "@front/m19-metrics";
import { getMetricsColDef, STATUS_BAR } from "@m19-board/grid-config/grid-config";
import { PALETTE } from "@m19-board/models/Metric";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { map, Observable } from "rxjs";
import { ProfitDetail } from "../../product360/product360.service";
import { AgGridAngular } from "@ag-grid-community/angular";
import { ColDef, GetGroupRowAggParams, GridOptions, ICellRendererParams } from "@ag-grid-community/core";
import { AccountMarketplace, AccountType, Currency } from "@front/m19-api-client";
import { getBasicGridOptions } from "@front/m19-grid-config";
import { BaseWidgetComponent, BaseWidgetParams } from "@m19-board/widget/base-widget/base-widget.component";
import { CurrencyStat } from "@front/m19-models";

export const PROFIT_DETAIL_AMOUNT = new SummableMetric<ProfitDetail>({
  id: "PROFIT_DETAIL_AMOUNT",
  field: "value",
  title: "Amount",
  category: MetricCategory.PROFIT_STATS,
  color: PALETTE[0],
  currency: true,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Profit",
});

export const PROFIT_DETAIL_TOTAL_REVENUE = new SummableMetric<ProfitDetail>({
  id: "PROFIT_DETAIL_TOTAL_REVENUE",
  field: "totalRevenue",
  title: "Amount",
  category: MetricCategory.PROFIT_STATS,
  color: PALETTE[1],
  currency: true,
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Total Revenue",
});

export const PROFIT_DETAIL_SHARE = new RatioMetric<ProfitDetail>({
  id: "PROFIT_DETAIL_SHARE",
  title: "% of Revenue",
  isPercent: true,
  numerator: PROFIT_DETAIL_AMOUNT,
  denominator: PROFIT_DETAIL_TOTAL_REVENUE,
  category: MetricCategory.PROFIT_STATS,
  color: PALETTE[2],
  supportedAccountType: SupportedAccountType.SELLER,
  tooltip: "Profit share",
});

@UntilDestroy()
@Component({
  templateUrl: "./profit-details-widget.component.html",
  styleUrls: ["../widget.scss"],
})
export class ProfitDetailsWidgetComponent extends BaseWidgetComponent<ProfitDetail> implements OnInit {
  @Input() data$!: Observable<ProfitDetail[] | undefined>;

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  gridOptions!: GridOptions<ProfitDetail>;

  metrics: Metric<CurrencyStat>[] = [];

  // state
  loading = true;
  locale!: string;
  currency!: Currency;
  overlayMessage$!: Observable<string>;

  readonly columnDefs: ColDef[] = [
    {
      field: "category",
      headerName: this.translocoService.translate("common.category", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("common.category"),
      valueFormatter: (params) => this.translocoService.translate(`${params.value}`),
      colId: "category",
      rowGroup: true,
      hide: true,
    },
    ...getMetricsColDef([PROFIT_DETAIL_AMOUNT]).map(({ headerName, ...def }: ColDef) => ({
      ...def,
      headerName: this.translocoService.translate(`metrics.${def.colId}_title`, {}, "en"),
      headerValueGetter: () => this.translocoService.translate(`metrics.${def.colId}_title`),
      cellRendererParams: (params: ICellRendererParams) => ({
        ...def.cellRendererParams(params),
        currency: this.currency,
        locale: this.locale,
      }),
    })),
    this.shareColumn(),
  ];

  constructor(
    private authService: AuthService,
    private userSelectionService: UserSelectionService,
    private accountSelectionService: AccountSelectionService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loading = true;
    this.data$.pipe(untilDestroyed(this)).subscribe(() => {
      this.loading = false;
    });
    this.overlayMessage$ = this.accountSelectionService.singleAccountMarketplaceSelection$.pipe(
      map((am: AccountMarketplace) =>
        am.accountType === AccountType.VENDOR
          ? this.translocoService.translate("profit-details-widget.profit_details_are_not_available_for_vendor")
          : "",
      ),
    );

    this.gridOptions = {
      ...getBasicGridOptions("p360_profit_details" + (this.expandedOptions ? "_exp" : ""), false, true),
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      columnDefs: this.columnDefs,
      autoGroupColumnDef: {
        headerName: this.translocoService.translate("profit-details-widget.revenue_cost", {}, "en"),
        headerValueGetter: () => this.translocoService.translate("profit-details-widget.revenue_cost"),
        field: "field",
        valueFormatter: (params) => this.translocoService.translate(`${params.value}`),
      },
      getGroupRowAgg: (params: GetGroupRowAggParams<ProfitDetail>) => {
        const result: ProfitDetail = {} as ProfitDetail;
        for (const n of params.nodes) {
          if (n.data) {
            result.value = n.data.aggValue;
            result.totalRevenue = n.data.totalRevenue;
            break;
          }
        }
        return result;
      },
    };
    if (!this.expandedOptions) {
      this.gridOptions.sideBar = false;
    }
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.locale = user.locale;
    });
    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((currency) => {
      this.currency = currency;
    });
  }

  private shareColumn(): ColDef {
    return {
      headerName: this.translocoService.translate(`metrics.${PROFIT_DETAIL_SHARE.id}_title`, {}, "en"),
      headerValueGetter: () => this.translocoService.translate(`metrics.${PROFIT_DETAIL_SHARE.id}_title`),
      headerTooltip: this.translocoService.translate(`metrics.${PROFIT_DETAIL_SHARE.id}_title`),
      colId: PROFIT_DETAIL_SHARE.id,
      enableValue: true,
      enablePivot: true,
      type: "numericColumn",
      filter: "agNumberColumnFilter", // Authorize filter on values (greater, equal...)
      valueGetter: (params) => {
        // Here, we set the intrinsic value of the cell.
        // We can get non summable metrics computed by metric.value() method
        // In case of grouping, this is the same process, we just pass "node.aggData" instead of "data"
        const val = PROFIT_DETAIL_SHARE.value(params.data ?? params.node!.aggData)!;
        return isNaN(val) ? 0 : val * 100;
      },
      valueFormatter: (params) => {
        let val = PROFIT_DETAIL_SHARE.value(params.data ?? params.node!.aggData)!;
        val = isNaN(val) ? 0 : val < 0 ? -val : val;
        return PROFIT_DETAIL_SHARE.format(val, this.locale, this.currency);
      },
      cellClassRules: {
        "text-green-500": (params) => params.value >= 0,
        "text-red-500": (params) => params.value < 0,
      },
    };
  }

  override initialize(profitDetailsWidgetParams: ProfitDetailsWidgetParams<ProfitDetail[]>) {
    super.initialize(profitDetailsWidgetParams);
    return this;
  }

  protected readonly STATUS_BAR = STATUS_BAR;
}

export interface ProfitDetailsWidgetParams<T> extends BaseWidgetParams<T, T> {}
