import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import { AfterViewInit, Component, computed, ElementRef, EventEmitter, input, Output, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { twJoin, twMerge } from 'tailwind-merge';
import { booleanInput } from '../ibutton/ibutton.component';

@Component({
  selector: 'IInput',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, NgClass],
  templateUrl: './iinput.component.html',
  animations: [
    trigger('reveal', [
      state(
        'void',
        style({
          height: '0px',
        }),
      ),
      transition(':enter', [
        animate(
          '100ms ease-in-out',
          style({
            height: '*',
          }),
        ),
      ]),
      transition(':leave', [
        animate(
          '100ms ease-in-out',
          style({
            height: '0px',
          }),
        ),
      ]),
    ]),
  ],
})
export class IInputComponent implements AfterViewInit {
  @ViewChild('inputElement', { static: false })
  input!: ElementRef<HTMLInputElement>;

  control = input<FormControl<any>>(new FormControl<string>(''));
  value = input<string | number | null>(null);

  variant = input<'outline' | 'none'>('outline');
  size = input<'xs' | 'sm' | 'md' | 'lg' | 'xl'>('md');
  label = input<string>();
  description = input<string>();
  error = input<string | null | undefined>(null);
  autocomplete = input<'on' | 'off'>('off');
  placeholder = input<string>('');
  disabled = input(false);
  type = input<'text' | 'number' | 'password' | 'email'>('text');
  icon = input<string>();
  prefix = input<string>();
  id = input<string>();
  testid = input<string>();
  counter = input<number>();
  maxLength = input<number>(10_000);

  // when type is number
  min = input(0);
  max = input(Number.POSITIVE_INFINITY);
  step = input(1);

  autoFocus = input(false, { transform: booleanInput });
  trailing = input(false, { transform: booleanInput });

  @Output() valueChange = new EventEmitter<string>();

  idValue = computed(() => {
    return this.id() ?? Math.random().toString(36).substring(2, 15);
  });

  inputValue = computed(() => {
    if (this.value()) {
      this.control().setValue(this.value());
    }
    return this.control().value;
  });

  sizeConfig = computed(() => {
    switch (this.size()) {
      case 'xs':
        return 'text-xs gap-x-1.5 px-2.5 py-1.5';
      case 'sm':
        return 'text-sm  gap-x-1.5 px-2.5 py-1.5';
      case 'md':
        return 'text-sm gap-x-2 px-3 py-2';
      case 'lg':
        return 'text-sm gap-x-2.5 px-3.5 py-2.5';
      case 'xl':
        return 'text-base gap-x-2.5  px-3.5 py-2.5';
    }
  });

  inputClass = computed(() => {
    let conf =
      'relative block w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0 bg-transparent text-gray-900 dark:text-white';
    if (this.variant() === 'outline') {
      conf = twMerge(
        conf,
        'shadow-sm bg-white dark:bg-gray-900 ring-1 ring-gray-200 dark:ring-gray-700 focus:ring-2 focus:ring-main-300 dark:focus:ring-main-400 rounded-lg',
      );
    }
    return twJoin(conf, this.sizeConfig(), this.iconPadding(), 'placeholder-gray-400 dark:placeholder-gray-500');
  });

  iconWrapperClass = computed(() => {
    if (!this.icon()) return undefined;
    let conf = '';
    conf = 'absolute inset-y-0 flex items-center pointer-events-none';
    if (this.trailing()) {
      conf = twMerge(conf, 'end-0');
    } else {
      conf = twMerge(conf, 'start-0');
    }
    switch (this.size()) {
      case 'xs':
        return twMerge(conf, 'px-2.5');
      case 'sm':
        return twMerge(conf, 'px-2.5');
      case 'md':
        return twMerge(conf, 'px-3');
      case 'lg':
        return twMerge(conf, 'px-3.5');
      case 'xl':
        return twMerge(conf, 'px-3.5');
    }
  });

  iconClass = computed(() => {
    if (!this.icon()) return undefined;
    const conf = twMerge('flex-shrink-0 text-gray-400 dark:text-gray-500', this.icon());
    switch (this.size()) {
      case 'xs':
        return twMerge(conf, 'size-4');
      case 'sm':
        return twMerge(conf, 'size-5');
      case 'md':
        return twMerge(conf, 'size-5');
      case 'lg':
        return twMerge(conf, 'size-5');
      case 'xl':
        return twMerge(conf, 'size-6');
    }
  });

  labelClass = computed<string | undefined>(() => {
    if (!this.label()) return undefined;
    const conf = 'block font-medium text-gray-700 dark:text-gray-200';

    switch (this.size()) {
      case 'xs':
        return twMerge(conf, 'text-xs');
      case 'sm':
        return twMerge(conf, 'text-sm');
      case 'md':
        return twMerge(conf, 'text-sm');
      case 'lg':
        return twMerge(conf, 'text-sm');
      case 'xl':
        return twMerge(conf, 'text-base');
    }
  });

  iconPadding = computed(() => {
    if (this.icon()) {
      switch (this.size()) {
        case 'xs':
          return this.trailing() ? 'pe-8' : 'ps-8';
        case 'sm':
          return this.trailing() ? 'pe-9' : 'ps-9';
        case 'md':
          return this.trailing() ? 'pe-10' : 'ps-10';
        case 'lg':
          return this.trailing() ? 'pe-11' : 'ps-11';
        case 'xl':
          return this.trailing() ? 'pe-12' : 'ps-12';
      }
    }
    return '';
  });

  ngAfterViewInit(): void {
    this.focusInput();
  }

  private focusInput() {
    if (this.input && this.autoFocus()) {
      setTimeout(() => {
        this.input.nativeElement.focus();
      });
    }
  }

  onInput(e: Event) {
    this.valueChange.emit((<HTMLInputElement>e.target).value);
  }
}
