@if (floating()) {
  <button
    type="button"
    #popperToggle
    class="focus:ring-main-300 relative inline-flex w-full cursor-pointer items-center gap-x-1.5 truncate border-0 bg-white px-2.5 py-1.5 pe-9 text-left text-sm shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 disabled:cursor-not-allowed disabled:opacity-75 dark:bg-gray-800"
    [ngClass]="rounded() ? 'rounded-full' : 'rounded-md'"
  >
    @if (leadingSlot) {
      <ng-container [ngTemplateOutlet]="leadingSlot"></ng-container>
    }
    @if (!hasSelectedOptions()) {
      <span class="block truncate text-gray-500">{{ placeholder() }}</span>
    } @else {
      @if (labelSlot) {
        <ng-container [ngTemplateOutlet]="labelSlot"></ng-container>
      } @else {
        <span class="block truncate text-sm text-gray-900 dark:text-gray-200">{{ label() }}</span>
      }
    }
    <span class="pointer-events-none absolute inset-y-0 end-0 flex items-center px-2.5">
      <span class="icon-[mdi--chevron-down] size-6 flex-shrink-0 text-gray-500"></span>
    </span>
  </button>
} @else {
  <ng-container [ngTemplateOutlet]="selectContent"></ng-container>
}

<div
  #popper
  id="select-menu"
  class="invisible absolute z-[1000] scale-90 scroll-py-1 rounded-md bg-white p-1 opacity-0 shadow-lg ring-1 ring-gray-200 duration-100 ease-in-out focus:outline-none dark:bg-gray-800"
  *transloco="let t"
>
  <ng-container [ngTemplateOutlet]="selectContent"></ng-container>
</div>

<ng-template #selectContent>
  <div *transloco="let t" class="max-h-96 overflow-y-auto">
    @if (headerSlot) {
      <ng-container [ngTemplateOutlet]="headerSlot"></ng-container>
    }
    @if (searchable()) {
      <input
        type="text"
        class="sticky -top-1.5 z-10 -mt-1.5 mb-1 w-full truncate border-b px-3 py-1.5 text-gray-800 focus:outline-none focus:ring-0"
        [class.border-t]="headerSlot"
        [class.mt-0]="headerSlot"
        [placeholder]="searchPlaceholder()"
        (input)="setSearch($event)"
      />
    }
    <ul
      class="m-0 list-none p-0 focus:outline-none"
      (keydown)="handleKeyDown($event)"
      tabindex="0"
      (click)="$event.stopPropagation()"
    >
      @if (withSelectAll() && options().length !== 0 && filteredOptions().length !== 0) {
        <ng-container
          [ngTemplateOutlet]="optionTemplate"
          [ngTemplateOutletContext]="{ index: { i: -1, j: -1 }, isAllOption: true }"
        ></ng-container>
      }
      @for (c of filteredOptionsWithCategories(); track c.category; let i = $index) {
        @if (c.category && withCategories()) {
          <li
            class="group sticky left-0 top-8 z-10 my-1 cursor-pointer select-none rounded-sm bg-gray-100 px-1.5 py-1 text-sm font-semibold text-gray-700 hover:bg-gray-200"
            (click)="selectCategory(c)"
          >
            <div class="flex w-full min-w-0 items-center justify-between gap-x-1.5">
              <div class="flex w-full items-center gap-x-1.5">
                @if (isCategorySelected(c)) {
                  <span class="icon-[mdi--checkbox-outline] h-4 w-4 flex-shrink-0" aria-hidden="true"></span>
                } @else {
                  <span class="icon-[mdi--checkbox-blank-outline] h-4 w-4 flex-shrink-0" aria-hidden="true"></span>
                }
                <span class="truncate">
                  {{ c.category }}
                </span>
              </div>
              <div class="invisible absolute end-0 right-0 top-0 flex h-full items-center group-hover:visible">
                <button
                  class="mr-1 flex items-center rounded-md bg-gray-300 p-1 hover:bg-gray-400"
                  (click)="selectOnlyCategory(c); $event.stopPropagation()"
                >
                  <span class="mx-1 text-xs">{{ selectOnlyText }}</span>
                </button>
              </div>
            </div>
          </li>
        }
        @for (option of c.options; track option[_by()!]; let j = $index) {
          <ng-container
            [ngTemplateOutlet]="optionTemplate"
            [ngTemplateOutletContext]="{ option: option, index: { i, j } }"
          ></ng-container>
        }
      } @empty {
        @if (options().length === 0) {
          <p class="m-0 cursor-default select-none px-1.5 py-1.5">{{ t("iselect-menu.no_option_available") }}</p>
        } @else {
          <p class="m-0 cursor-default select-none px-1.5 py-1.5">
            {{ t("imultiselect.no_results_found_for_search", [search()]) }}
          </p>
        }
      }
    </ul>
  </div>
</ng-template>

<ng-template #optionTemplate let-option="option" let-index="index" let-isAllOption="isAllOption">
  <li
    [attr.data-option]="index.i + '_' + index.j"
    (click)="isAllOption ? selectAll() : selectOption(option)"
    (mouseenter)="activeIndex.set(index)"
    (mouseleave)="activeIndex.set({ i: -1, j: -1 })"
    class="relative flex min-w-0 cursor-pointer select-none scroll-mt-9 items-center gap-x-1.5 rounded-md px-1.5 py-1.5 text-gray-800 dark:text-gray-300"
    [ngClass]="activeIndex().i === index.i && activeIndex().j === index.j ? 'bg-gray-100 dark:bg-gray-700' : ''"
  >
    <div class="flex w-full min-w-0 items-center justify-between gap-x-1.5">
      <div class="flex w-full items-center gap-x-1.5 truncate">
        @if (isAllOption ? isAllSelected() : isSelected(option)) {
          <span class="icon-[mdi--checkbox-outline] h-4 w-4 flex-shrink-0" aria-hidden="true"></span>
        } @else {
          <span class="icon-[mdi--checkbox-blank-outline] h-4 w-4 flex-shrink-0" aria-hidden="true"></span>
        }

        @if (optionSlot && !isAllOption) {
          <ng-container
            [ngTemplateOutlet]="optionSlot"
            [ngTemplateOutletContext]="{ $implicit: option }"
          ></ng-container>
        } @else {
          <span class="truncate text-sm">{{ isAllOption ? selectAllPlaceholder() : option[optionAttribute()] }}</span>
        }
      </div>

      @if (activeIndex().i === index.i && activeIndex().j === index.j && !isAllOption) {
        <button
          class="absolute end-0 mr-1 flex items-center rounded-md bg-gray-200 p-1 hover:bg-gray-300"
          (click)="selectOnly(option); $event.stopPropagation()"
        >
          <span class="mx-1 text-xs">{{ selectOnlyText }}</span>
        </button>
      }
    </div>
  </li>
</ng-template>
