import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { EventManager } from "@angular/platform-browser";
import { EMPTY, Observable } from "rxjs";

type Options = {
  element: HTMLElement | Document;
  altKey: string;
};

@Injectable({ providedIn: "root" })
export class Hotkeys {
  defaults: Partial<Options> = {
    element: this.document,
  };

  constructor(
    private eventManager: EventManager,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  addShortcut(options: Partial<Options>) {
    const merged = { ...this.defaults, ...options };
    if (merged.altKey) {
      const event = `keydown`;
      return new Observable((observer) => {
        const handler = (e: KeyboardEvent) => {
          if (e.code === merged.altKey && e.altKey) {
            e.preventDefault();
            observer.next(e);
          }
        };

        const dispose = this.eventManager.addEventListener(merged.element as HTMLElement, event, handler);

        return () => {
          dispose();
        };
      });
    }
    return EMPTY;
  }
}
