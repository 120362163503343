<div *transloco="let t">
  <div>{{ data.message }}</div>
  <div
    class="mt-8 flex justify-end"
    [ngClass]="{
      'justify-between': data.footerLayout === 'space-between',
    }"
  >
    <IButton (onClick)="close()" label="{{ t('common.cancel') }}" color="gray" variant="ghost" class="mr-2" />
    <IButton
      (onClick)="confirm()"
      color="red"
      label="{{ data.confirmLabel ?? t('common.confirm') }}"
      testid="btn-confirm"
    />
  </div>
</div>
