import { CommonModule } from "@angular/common";
import { Component, inject, signal, Signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import {
  AccountMarketplace,
  CreateTacosStrategyGroupRequest,
  StrategyAsin,
  TacosStrategyGroup,
  TacosStrategyGroupApi,
  TacosStrategyGroupStateEnum,
} from "@front/m19-api-client";
import { AccountSelectionService, SpStrategiesService, StrategyService } from "@front/m19-services";
import { IAlertComponent, IButtonComponent, IInputComponent } from "@front/m19-ui";
import { AsinStrategyUsageComponent } from "@m19-board/shared/asin-strategy-usage/asin-strategy-usage-modal.component";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { TacosStrategiesService } from "@front/m19-services";
import { BsModalService } from "ngx-bootstrap/modal";
import { map } from "rxjs";
import { AsinsSelectionComponent } from "../strategy-asins/asins-selection.component";

@Component({
  selector: "create-tacos-strategy",
  standalone: true,
  imports: [
    CommonModule,
    IInputComponent,
    IButtonComponent,
    AsinsSelectionComponent,
    RouterModule,
    IAlertComponent,
    TranslocoRootModule,
  ],
  templateUrl: "./create-tacos-strategy.component.html",
})
export class CreateTacosStrategyComponent {
  form = new FormGroup({
    name: new FormControl<string | undefined>(undefined, [Validators.required]),
    tacosTarget: new FormControl<number | undefined>(undefined, [
      Validators.required,
      Validators.min(1),
      Validators.max(100),
    ]),
    asins: new FormControl<StrategyAsin[]>([], [Validators.required, Validators.minLength(1)]),
  });

  loading = signal(false);

  private accountService = inject(AccountSelectionService);
  am: Signal<AccountMarketplace | undefined> = toSignal(this.accountService.singleAccountMarketplaceSelection$);

  private router = inject(Router);
  tacosStrategiesService = inject(TacosStrategiesService);
  tacosStrategyGroupApi = inject(TacosStrategyGroupApi);
  strategyService = inject(StrategyService);
  spStrategyService = inject(SpStrategiesService);
  modalService = inject(BsModalService);

  unavailableAsins: Signal<Set<string> | undefined> = toSignal(
    this.tacosStrategiesService.strategyByAsins$.pipe(
      map((strategyByAsins) => {
        if (!strategyByAsins) return new Set();
        return new Set(strategyByAsins.keys());
      }),
    ),
  );

  submit() {
    if (this.form.invalid) {
      return;
    }
    this.loading.set(true);

    const requestBody: CreateTacosStrategyGroupRequest = {
      name: this.form.controls.name.value!,
      tacosTarget: this.form.controls.tacosTarget.value! / 100,
      accountId: this.am()!.accountId,
      marketplace: this.am()!.marketplace,
      state: TacosStrategyGroupStateEnum.ENABLED,
    };

    this.tacosStrategiesService
      .createTacosStrategyGroup(
        requestBody,
        this.form.controls.asins.value!.map((a) => a.asin!),
      )
      .subscribe((s: TacosStrategyGroup) => {
        this.router.navigate(["/advertising/tacos-strategies/", s.tacosStrategyGroupId]);
        this.loading.set(false);
      });
  }

  addAsins(asins: StrategyAsin[]) {
    const newAsins = [...(this.form.controls.asins.value ?? []), ...asins];
    this.form.controls.asins.setValue(newAsins);
  }

  removeAsins(asins: StrategyAsin[]) {
    const newAsins = this.form.controls.asins.value!.filter((a) => !asins.map((a) => a.asin).includes(a.asin));
    this.form.controls.asins.setValue(newAsins);
  }

  openAsinUsageModal() {
    this.modalService.show(AsinStrategyUsageComponent, {
      class: "modal-primary modal-dialog-centered",
      initialState: { am: this.am()!, isTacosStrategy: true },
    });
  }
}
