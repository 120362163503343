import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { Router } from "@angular/router";
import { faSquare, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare, faPencilAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import {
  AccountSelectionService,
  AdStatsWithTargetHistory,
  AuthService,
  CurrencyService,
  DataSet,
  NotificationService,
  OrganizationAccountGroupService,
  SbStrategiesService,
  SegmentService,
  StatsApiClientService,
  StatsService,
  StrategyService,
  UserSelectionService,
} from "@front/m19-services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { ActivityEventType, ActivityService } from "@m19-board/activities/activity.service";
import { HourlyDataUnsupportedMarketplaces } from "@m19-board/overlay/hourly-stats-overlay.component";
import { KeywordSegmentModalComponent } from "@m19-board/segments/keyword-segment-modal.component";
import { ProductSegmentModalComponent } from "@m19-board/segments/product-segment-modal.component";
import { FblModeComponent, FblModeSize } from "@m19-board/strategies/fbl-mode/fbl-mode.component";
import {
  ICON_ADD,
  ICON_BOOST,
  ICON_CHART_LINE,
  ICON_CHEVRON_DOWN,
  ICON_CLOSE,
  ICON_EDIT_O,
  ICON_PAUSE,
  ICON_PLAY,
  ICON_SYNC,
  ICON_TRASH_O,
} from "@m19-board/utils/iconsLabels";
import moment from "moment-timezone";
import { BaseChartDirective } from "ng2-charts";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from "rxjs";
import { filter, map, shareReplay, switchMap } from "rxjs/operators";
import { SwitchTargetAlgoModalComponent } from "../../insights/overview/switch-target-algo-modal/switch-target-algo-modal.component";

import {
  AccountMarketplace,
  AccountType,
  AlgoMode,
  CampaignType,
  Currency,
  EntityIdType,
  HistoryActionEnum,
  HistoryApi,
  M19Status,
  NotificationType,
  SbCreative,
  Strategy,
  StrategyStateEnum,
  StrategyTactic,
  StrategyType,
  TacticType,
} from "@front/m19-api-client";
import {
  ACOS,
  AD_CONVERSIONS,
  AD_SALES,
  CLICK_THROUGH_RATE,
  CLICKS,
  CONVERSION_RATE,
  COST,
  CPC,
  IMPRESSIONS,
  Metric,
  ROAS,
} from "@front/m19-metrics";
import {
  AdStatsEx,
  AlgoModeStr,
  Marketplaces,
  NotificationBidderIssueEx,
  OrganizationAccountGroups,
  ProductGroupEx,
  SegmentConfigType,
  SegmentEx,
  singleStrategyInStrategyGroup,
  StrategyEx,
  StrategyGroupEx,
  StrategyTypeStr,
} from "@front/m19-models";
import { Option } from "@front/m19-ui";
import {
  AggregationFunction,
  convertToCurrency,
  currencyRateToEuro,
  DateAggregation,
  mergeSeveralDates,
  MetricsSelectorLocalStorageKey,
  Utils,
} from "@front/m19-utils";
import { TranslocoService } from "@jsverse/transloco";
import { DAILY_BUDGET, MIN_DAILY_SPEND, MONTHLY_BUDGET, TARGET_ACOS, TOTAL_SALES } from "@m19-board/models/MetricsDef";
import { SbCreativeStatsGridComponent } from "../sb-creative-stats-grid/sb-creative-stats-grid.component";
import { primeDayConfig } from "../strategy-boost/strategy-boost-table/strategy-boost-table.component";
import { StrategyPlacementStatsGridComponent } from "../strategy-placement-stats/strategy-placement-stats-grid.component";
import { TargetingLimit } from "../target-utils";
import { SwitchAlgoModeComponent } from "./switch-algo-mode-popup/switch-algo-mode.component";
import { TacticAddPopupComponent } from "./tactic-add-popup.component";

type AdvancedSettings =
  | "strategyLabel"
  | "targetMinDailySpend"
  | "autoCampaign"
  | "productTargeting"
  | "aiPoweredTargeting"
  | "disableProductTargeting"
  | "dayparting"
  | "strategyGroup";

@UntilDestroy()
@Component({
  selector: "app-strategy-page",
  templateUrl: "./strategy-page.component.html",
  styleUrls: ["./strategy-page.component.scss"],
})
export class StrategyPageComponent implements OnInit {
  @Input({ required: true })
  campaignType!: CampaignType;
  @Input({ required: true })
  strategy$!: Observable<StrategyEx | undefined>;
  @Input()
  withTacticSection = true;
  @Input()
  withStatsSection = true;
  @Input()
  withActivitiesSection = true;
  @Input()
  withAutoCampaignOption = true;
  @Input()
  withProductTargetingOption = true;
  _strategyGroup?: StrategyGroupEx;
  @Input()
  set strategyGroup(value: StrategyGroupEx | undefined) {
    this._strategyGroup = value;
    if (value) {
      this.isSingleStrategyInStrategyGroup = singleStrategyInStrategyGroup(value);
    }
  }

  get strategyGroup() {
    return this._strategyGroup;
  }

  @ViewChild("strategyChart") strategyChart!: BaseChartDirective;
  @ViewChild("search") search!: ElementRef;

  @ViewChild("updateMinDailySpendModal", { static: false }) updateMinDailySpendModal!: TemplateRef<any>;
  @ViewChild("updateLabelModal", { static: false }) updateLabelModal!: TemplateRef<any>;
  openedModalRef?: BsModalRef;

  @ViewChild("tacticPlacementGrid", { static: false }) tacticPlacementGrid!: StrategyPlacementStatsGridComponent;
  @ViewChild("creativeGrid", { static: false }) creativeGrid!: SbCreativeStatsGridComponent;

  allOrganizationAccountGroups!: OrganizationAccountGroups[];

  loading = true;
  isReadOnly = false;
  strategy?: StrategyEx;
  isSingleStrategyInStrategyGroup?: boolean;

  singleStrategyDataSet?: DataSet<AdStatsEx>;
  selectedMetric$?: BehaviorSubject<Metric<AdStatsWithTargetHistory>[]>;
  dateRange?: string[];
  maxActivitiesDate?: string;

  globalData?: AdStatsEx;
  previousPeriodGlobalData: AdStatsEx = {};
  buildChart$: Subject<void> = new Subject();

  tacticsvisible?: boolean;
  strategyLabel = "";
  infoMessageLastUpdate: string | undefined = undefined;
  isStrategyBrandDeleted = false;

  readonly AlgoModes = AlgoMode;
  readonly FblModeSize = FblModeSize;
  readonly TacticType = TacticType;
  readonly ICON_ADD = ICON_ADD;
  readonly ICON_TRASH = ICON_TRASH_O;
  readonly ICON_EDIT = ICON_EDIT_O;
  readonly ICON_CLOSE = ICON_CLOSE;
  readonly ICON_CHART = ICON_CHART_LINE;
  readonly ICON_SYNC = ICON_SYNC;
  readonly ICON_PLAY = ICON_PLAY;
  readonly ICON_PAUSE = ICON_PAUSE;
  readonly ICON_BOOST = ICON_BOOST;
  readonly ICON_CHEVRON_DOWN = ICON_CHEVRON_DOWN;
  suggestedBid?: number;

  minAllowedBid?: number;
  locale?: string;
  accountType?: AccountType;
  dailyBudgetVisible?: boolean;
  dailyBudget?: number;
  monthlyBudget?: number;
  endOfMonth?: Date;
  currentMonthSpend?: number;
  oldDailyBudget?: number;
  minDailySpend?: number;
  maxMinDailySpend?: number;
  minDailySpendLimit?: number;
  displayAdvancedSettingsSection?: boolean;
  advancedSettingVisible?: boolean;
  advancedSettings: Record<AdvancedSettings, boolean> = {
    strategyLabel: false,
    targetMinDailySpend: false,
    autoCampaign: false,
    productTargeting: false,
    aiPoweredTargeting: false,
    disableProductTargeting: false,
    dayparting: false,
    strategyGroup: false,
  };
  daypartingPauseHour = 22;
  daypartingReactivationHour = 8;
  activitiesVisible?: boolean;
  statsVisible?: boolean;
  currencySymbol?: string;
  currencyCode?: string;
  currency?: Currency;
  targetLimit?: TargetingLimit;
  isStatsChartHidden = false;
  selectedStatsTab = 0; // for SB strategy stats

  newStrategyName: string = "";

  bidderIssue?: NotificationBidderIssueEx;

  additionalMetrics = new Set<Metric<AdStatsWithTargetHistory>>();

  readonly localStorageKey?: MetricsSelectorLocalStorageKey.strategy;

  readonly faPencil = faPencilAlt;
  readonly faSquare = faSquare;
  readonly faCheckedSquare = faCheckSquare;
  readonly faTrash = faTrashAlt;
  readonly faPlusCircle = faPlusCircle;
  readonly fblMode = FblModeComponent.fblMode;
  readonly CampaignType = CampaignType;
  readonly StrategyEx = StrategyEx;
  readonly StrategyTypeStr = StrategyTypeStr;
  readonly StrategyType = StrategyType;
  readonly MetricsSelectorLocalStorageKey = MetricsSelectorLocalStorageKey;
  readonly AlgoModeStr = AlgoModeStr;
  AlgoModeStrKey = {
    [AlgoMode.PRODUCT_LAUNCH]: {
      description: "algo-mode-selection.force_product_visibility",
      shortDescription: "algo-mode-selection.constant_bid",
    },
    [AlgoMode.ACOS_TARGET]: {
      description: "algo-mode-selection.optimize_sales_with_acos_target",
      shortDescription: "algo-mode-selection.acos_target",
    },
    [AlgoMode.MONTHLY_BUDGET_TARGET]: {
      description: "algo-mode-selection.optimize_sales_with_monthly_budget_target",
      shortDescription: "algo-mode-selection.monthly_budget_target",
    },
    [AlgoMode.TACOS_TARGET]: {
      description: "algo-mode-selection.optimize_sales_with_tacos_target",
      shortDescription: "algo-mode-selection.tacos_target",
    },
  };
  primeDayPeriod?: string;
  primeDayAfterPeriod?: string;
  primeDayOverDate?: string;
  boostActivated$: Observable<boolean>;
  displayEventAnnotation$ = new BehaviorSubject(false);
  disableEventAnnotation = false;
  dateAggregation$: BehaviorSubject<DateAggregation> = new BehaviorSubject<DateAggregation>(DateAggregation.daily);
  organizationId?: number;

  strategyTitleModal?: BsModalRef;
  dailyBudgetModal?: BsModalRef;

  readonly CHART_METRICS: Metric<AdStatsEx>[] = [
    AD_SALES,
    AD_CONVERSIONS,
    COST,
    ACOS,
    CLICKS,
    IMPRESSIONS,
    CLICK_THROUGH_RATE,
    CONVERSION_RATE,
    CPC,
    ROAS,
  ];

  readonly MaxAsins = ProductGroupEx.MaxProductGroupItems;
  readonly allEventAnnotationTypes: Option<ActivityEventType>[] = this.activityService.allActivityEventTypesOptions;

  strategyList?: StrategyEx[];
  strategyPrefixPageUrl = "/strategies/";
  strategyGroupPageUrl = "/strategies/strategy-group/sponsored-product/";
  segmentIndex: Map<number, SegmentEx> = new Map();
  creatives: SbCreative[] = [];
  private readonly dailyPlacementStats$: Observable<AdStatsEx[]>;
  private readonly previousDailyPlacementStats$: Observable<AdStatsEx[]>;

  constructor(
    private sbStrategyService: SbStrategiesService,
    private accountMarketplaceSelection: AccountSelectionService,
    private statsApliClientService: StatsApiClientService,
    private router: Router,
    private modalService: BsModalService,
    private currencyService: CurrencyService,
    private userSelectionService: UserSelectionService,
    private authService: AuthService,
    private organizationAccountGroupService: OrganizationAccountGroupService,
    private toastrService: ToastrService,
    private notificationService: NotificationService,
    private activityService: ActivityService,
    private historyService: HistoryApi,
    private translocoService: TranslocoService,
    private strategyService: StrategyService,
    private segmentService: SegmentService,
    private statsService: StatsService,
  ) {
    this.boostActivated$ = accountMarketplaceSelection.promoBoostActivated$;
    this.accountMarketplaceSelection.singleAccountMarketplaceSelection$.pipe(untilDestroyed(this)).subscribe((am) => {
      this.segmentService.getSegments(am.accountId, am.marketplace).subscribe((segments) => {
        this.segmentIndex = segments;
      });
    });
    this.dailyPlacementStats$ = combineLatest([
      this.accountMarketplaceSelection.singleAccountMarketplaceSelection$,
      this.userSelectionService.dateRange$,
    ]).pipe(
      switchMap(([am, dr]) =>
        combineLatest([
          this.userSelectionService.selectedCurrency$,
          this.statsService.getDailyPlacementStats(am.accountId, am.marketplace, dr[0], dr[1]),
        ]),
      ),
      map(([currency, data]) => convertToCurrency(data, currency)!),
      shareReplay(1),
    );
    this.previousDailyPlacementStats$ = combineLatest([
      this.accountMarketplaceSelection.singleAccountMarketplaceSelection$,
      this.userSelectionService.periodComparison$,
    ]).pipe(
      switchMap(([am, pc]) => {
        if (!pc?.period) {
          return of([]);
        }
        return combineLatest([
          this.userSelectionService.selectedCurrency$,
          this.statsService.getDailyPlacementStats(am.accountId, am.marketplace, pc.period[0], pc.period[1]),
        ]).pipe(
          map(([currency, data]) => {
            return convertToCurrency(data, currency)!;
          }),
        );
      }),
      shareReplay(1),
    );
  }

  ngOnInit(): void {
    // TODO: only do this for SB - get the list of creatives for the strategy
    this.strategy$
      .pipe(
        switchMap((s) => {
          if (s?.campaignType === CampaignType.SB) {
            return this.sbStrategyService
              .getSbCreativesPerStrategy(s.accountId, s.marketplace)
              .pipe(map((c) => c.get(s.strategyId) ?? []));
          }
          return of([]);
        }),
        untilDestroyed(this),
      )
      .subscribe((creatives) => {
        this.creatives = creatives;
      });
    this.accountMarketplaceSelection.singleAccountMarketplaceSelection$
      .pipe(
        switchMap((am) => this.strategyService.getStrategyIndex(am.accountId, am.marketplace)),
        untilDestroyed(this),
      )
      .subscribe((stratIndex: Map<number, Strategy>) => {
        this.strategyList = [];
        for (const [id, strat] of stratIndex) {
          if (
            strat.campaignType === this.campaignType &&
            strat.strategyGroupId == this.strategyGroup?.strategyGroupId
          ) {
            this.strategyList.push(new StrategyEx(strat));
          }
        }
      });
    this.isStatsChartHidden = !this.userSelectionService.getUserChartDisplayedPreference(this.localStorageKey!);

    this.selectedMetric$ = new BehaviorSubject<Metric<AdStatsWithTargetHistory>[]>([AD_SALES, COST]);
    this.singleStrategyDataSet = new DataSet<AdStatsWithTargetHistory>(
      3,
      this.selectedMetric$.value,
      [AggregationFunction.mergeAdStatsWithTargetHistory],
      this.translocoService,
    );
    this.singleStrategyDataSet.metricsOnSameScale = [
      [TOTAL_SALES, AD_SALES, COST],
      [ACOS, TARGET_ACOS],
      [COST, MIN_DAILY_SPEND, DAILY_BUDGET, MONTHLY_BUDGET],
    ];

    this.accountMarketplaceSelection.readOnlyMode$.pipe(untilDestroyed(this)).subscribe((b) => (this.isReadOnly = b));

    this.setupChartBuild();

    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.locale = user.locale;
      this.singleStrategyDataSet!.locale = user.locale;
      if ((user.uiVersion ?? 0) > 0) {
        this.strategyPrefixPageUrl = "/advertising/";
        this.strategyGroupPageUrl = "/advertising/sponsored-product/strategy-group/";
      }
    });

    this.userSelectionService.selectedCurrency$.pipe(untilDestroyed(this)).subscribe((currency) => {
      this.singleStrategyDataSet!.currency = currency;
      this.currency = currency;
    });

    this.organizationAccountGroupService.allOrganizationAccountGroups$
      .pipe(untilDestroyed(this))
      .subscribe((groups) => {
        this.allOrganizationAccountGroups = groups ?? [];
      });

    this.dailyBudgetVisible = false;
    this.strategy$
      .pipe(
        untilDestroyed(this),
        filter((s): s is StrategyEx => !!s),
        switchMap((s) => this.strategyService.getStrategyCurrentMonthSpend(s.accountId, s.marketplace, s.strategyId)),
      )
      .subscribe((spend) => {
        this.currentMonthSpend = spend;
      });

    combineLatest([
      this.strategy$,
      this.accountMarketplaceSelection.singleAccountMarketplaceSelection$,
      this.notificationService.getNotifications$,
      this.accountMarketplaceSelection.singleAccountMarketplaceSelection$.pipe(
        switchMap((am: AccountMarketplace) => this.sbStrategyService.getBrands(am.accountId, am.marketplace)),
      ),
      this.accountMarketplaceSelection.singleAccountMarketplaceSelection$.pipe(
        switchMap((am) => this.segmentService.getSegments(am.accountId, am.marketplace)),
      ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([x, accountMarketplace, notifs, brands, segmentIndex]) => {
        this.loading = false;
        this.minAllowedBid = accountMarketplace.minBid;
        this.accountType = accountMarketplace.accountType;
        this.organizationId = accountMarketplace.resourceOrganizationId;
        this.strategy = x;
        if (!this.strategy) {
          this.backToStrategyManagerPage();
          return;
        }

        this.isStrategyBrandDeleted =
          this.accountType !== AccountType.VENDOR &&
          this.strategy.campaignType === CampaignType.SB &&
          !brands.find((b) => b.brandEntityId === this.strategy?.brandEntityId);
        this.bidderIssue = notifs
          .filter((n) => n.type === NotificationType.BIDDER_ISSUE)
          .find((n) => n.strategyId === this.strategy?.strategyId) as NotificationBidderIssueEx;
        if (this.bidderIssue && this.bidderIssue.warningType === M19Status.IGNORED_REJECTED) {
          // don't display issue due to rejected ads here
          this.bidderIssue = undefined;
        }

        const now = moment(Utils.getNow(accountMarketplace.marketplace).format("YYYY-MM-DD"));
        this.endOfMonth = now.endOf("month").toDate();

        this.suggestedBid = this.strategy.suggestedBid;
        this.dailyBudget = this.strategy.dailyBudget;
        this.monthlyBudget = this.strategy.monthlyBudget;
        this.minDailySpend = this.strategy.minDailySpend;
        this.oldDailyBudget = this.strategy.dailyBudget;
        this.currencySymbol = this.currencyService.getCurrencySymbolFromMarketplace(this.strategy.marketplace);
        this.currencyCode = this.currencyService.getCurrencyCode(this.strategy.marketplace);
        this.buildChart$.next();
        this.dailyBudgetVisible = this.dailyBudget! > 0;

        this.minDailySpendLimit = accountMarketplace?.minDailyBudgetLimit;
        this.maxMinDailySpend = this.dailyBudget
          ? Math.floor(
              Math.min(
                this.minDailySpendLimit! / currencyRateToEuro(this.currencyCode as Currency),
                0.5 * this.dailyBudget,
              ),
            )
          : Math.floor(this.minDailySpendLimit! / currencyRateToEuro(this.currencyCode as Currency));
        const segments = this.strategy.tactics
          .filter((t) => t.tacticType === TacticType.LEGACY)
          .map((t) => segmentIndex.get(t.segmentId)!);
        const blacklist = this.strategy.tactics
          .filter((t) => t.tacticType === TacticType.BLACKLIST)
          .map((t) => segmentIndex.get(t.segmentId)!);
        this.targetLimit = new TargetingLimit(segments, blacklist);

        const hasAccessHourlyData: boolean =
          this.allOrganizationAccountGroups!.find((org) => org.id === accountMarketplace.resourceOrganizationId)!
            .organization!.billingPlan!.hourlyDataSupport! > 0;

        if (accountMarketplace.promoStartDate && accountMarketplace.promoEndDate) {
          const primeDayConf = primeDayConfig([
            new Date(accountMarketplace.promoStartDate),
            new Date(accountMarketplace.promoEndDate),
          ]);
          this.primeDayPeriod = primeDayConf.primeDayPeriod;
          this.primeDayAfterPeriod = primeDayConf.primeDayAfterPeriod;
          this.primeDayOverDate = primeDayConf.primeDayOverDate;
        }

        // compute advanced settings option display
        this.advancedSettings.strategyLabel = accountMarketplace?.customCampaignName != null;
        this.advancedSettings.targetMinDailySpend = this.strategy.algoMode === AlgoMode.ACOS_TARGET;
        this.advancedSettings.autoCampaign =
          (this.campaignType === CampaignType.SP || this.campaignType === CampaignType.SB) &&
          this.withAutoCampaignOption;
        this.advancedSettings.productTargeting =
          this.withProductTargetingOption && this.campaignType !== CampaignType.SD;
        this.advancedSettings.aiPoweredTargeting = this.showAutoAlgoExploration();
        this.advancedSettings.dayparting =
          this.campaignType === CampaignType.SP &&
          !HourlyDataUnsupportedMarketplaces.has(accountMarketplace.marketplace) &&
          hasAccessHourlyData;
        this.advancedSettings.strategyGroup = !!this.strategyGroup && !!this.isSingleStrategyInStrategyGroup;
        this.displayAdvancedSettingsSection = Object.values(this.advancedSettings).some((v) => v);
      });

    combineLatest([
      this.strategy$.pipe(
        untilDestroyed(this),
        filter((s): s is StrategyEx => !!s),
        switchMap((s) =>
          this.historyService.getHistory({
            accountId: s.accountId,
            marketplace: s.marketplace,
            minDate: Utils.formatDateForApiFromToday(-2),
            maxDate: Utils.formatDateForApiFromToday(0),
            historyKey: this.activityService.getStrategyActivityFilter(s, this.creatives),
          }),
        ),
      ),
      this.accountMarketplaceSelection.singleAccountMarketplaceSelection$,
    ]).subscribe(([history, accountMarketplace]) => {
      if (history.length == 0) {
        this.infoMessageLastUpdate = undefined;
        return;
      }
      const lastUpdate = history
        .filter((a) => a && a.timestamp)
        .sort((a, b) => b.timestamp!.localeCompare(a.timestamp!))[0];
      const lastUpdateIsStrategyCreation =
        lastUpdate.action === HistoryActionEnum.create && lastUpdate.primaryType === EntityIdType.strategyId;
      const nbHoursBeforeNextPush = Utils.estimatedNbHoursBeforeNextPush(
        Marketplaces[accountMarketplace.marketplace].platform,
        accountMarketplace.bidderRuns ? accountMarketplace.bidderRuns[this.campaignType]?.bidderRequestTime : undefined,
        accountMarketplace.bidderRuns ? accountMarketplace.bidderRuns[this.campaignType]?.lastBidderStart : undefined,
        accountMarketplace.bidderRuns ? accountMarketplace.bidderRuns[this.campaignType]?.lastBidderEnd : undefined,
        lastUpdate!.timestamp!,
      );

      if (nbHoursBeforeNextPush && nbHoursBeforeNextPush > 0) {
        if (lastUpdateIsStrategyCreation) {
          this.infoMessageLastUpdate = this.translocoService.translate("strategy-page.update_hours", {
            count: nbHoursBeforeNextPush,
          });
        } else {
          this.infoMessageLastUpdate = this.translocoService.translate("sp-strategy-group-page.time_for_update", {
            count: nbHoursBeforeNextPush,
          });
        }
      } else {
        this.infoMessageLastUpdate = undefined;
      }
    });

    this.advancedSettingVisible = false;
    this.activitiesVisible = false;
    this.statsVisible = false;
    this.tacticsvisible = true;
  }

  // Use for filter
  accessValue(s: StrategyEx) {
    return s.name;
  }

  setupChartBuild(): void {
    this.buildChart$
      .pipe(
        switchMap((_) =>
          combineLatest([
            this.dailyPlacementStats$,
            this.previousDailyPlacementStats$,
            this.selectedMetric$!,
            this.userSelectionService.selectedCurrency$.pipe(
              switchMap((currency) =>
                this.statsApliClientService.getStrategyTargetHistory(this.strategy!.strategyId, currency),
              ),
            ),
            this.userSelectionService.periodComparison$.pipe(map((x) => x?.period)),
            this.userSelectionService.dateRange$,
            this.dateAggregation$,
            combineLatest([this.dateAggregation$, this.displayEventAnnotation$]).pipe(
              switchMap(([agg, b]) => {
                if (agg == DateAggregation.daily && b) {
                  return this.activityService.getStrategyActivityEventAnnotation(
                    this.strategy!.accountId,
                    this.strategy!.marketplace,
                    this.strategy!,
                  );
                }
                return of([]);
              }),
            ),
          ]),
        ),
        untilDestroyed(this),
      )
      .subscribe(
        ([adStatsData, previousAdStats, metric, configHistory, comp, dateRange, dateAggregation, annotations]) => {
          if (!this.strategy) return;
          this.dateRange = dateRange;
          // if max date is yesterday, activity data will also include today activities
          if (dateRange[1] == moment().subtract(1, "days").format("YYYY-MM-DD")) {
            this.maxActivitiesDate = moment().format("YYYY-MM-DD");
          } else {
            this.maxActivitiesDate = dateRange[1];
          }

          const data = adStatsData.filter((x) => x.strategyId == this.strategy!.strategyId);
          const previousPeriodData = previousAdStats.filter((x) => x.strategyId == this.strategy!.strategyId);

          for (const target of configHistory) {
            if (target.dailyBudget !== undefined && !isNaN(target.dailyBudget))
              this.additionalMetrics.add(DAILY_BUDGET);
            if (target.minDailySpend !== undefined && target.minDailySpend !== 0)
              this.additionalMetrics.add(MIN_DAILY_SPEND);
            if (target.monthlyBudget !== undefined && !isNaN(target.monthlyBudget))
              this.additionalMetrics.add(MONTHLY_BUDGET);
          }

          const displayedData = [...configHistory, ...data];

          // TODO: filter strategy events
          if (comp && previousPeriodData.length) {
            this.singleStrategyDataSet!.buildDataSet(
              data.concat(configHistory),
              metric,
              dateAggregation,
              { minDate: dateRange[0], maxDate: dateRange[1] },
              { data: previousPeriodData, period: comp },
              annotations,
            );
          } else {
            this.singleStrategyDataSet!.buildDataSet(
              displayedData,
              metric,
              dateAggregation,
              {
                minDate: dateRange[0],
                maxDate: dateRange[1],
              },
              undefined,
              annotations,
            );
          }

          this.globalData = {};
          for (const d of data) mergeSeveralDates(this.globalData, d);

          this.previousPeriodGlobalData = {};
          for (const d of previousPeriodData) mergeSeveralDates(this.previousPeriodGlobalData, d);
        },
      );
    this.dateAggregation$.pipe(untilDestroyed(this)).subscribe((agg) => {
      this.disableEventAnnotation = agg !== DateAggregation.daily;
    });
  }

  backToStrategyManagerPage() {
    this.router.navigate([this.getStrategyManagerPage()]);
  }

  getStrategyManagerPage() {
    switch (this.campaignType) {
      case CampaignType.SP:
        return this.strategyPrefixPageUrl + "sponsored-product";
      case CampaignType.SB:
        return this.strategyPrefixPageUrl + "sponsored-brands";
      case CampaignType.SD:
        return this.strategyPrefixPageUrl + "sponsored-display";
    }
    return "";
  }

  statusChangeDisabled(): boolean {
    if (
      this.strategy &&
      this.strategy.state == StrategyStateEnum.PAUSED &&
      this.strategyService.isStrategyLimitReached(
        this.strategy.accountId,
        this.strategy.marketplace,
        this.organizationId!,
        this.strategyList!,
        this.strategy.campaignType,
      )
    ) {
      return true;
    }
    // disable activation for all other product for vendors
    return !!(
      this.strategy &&
      this.strategy.state &&
      this.strategy.accountId.startsWith("ENTITY") &&
      this.strategy.campaignType == CampaignType.SP &&
      this.strategy.defaultStrategy
    );
  }

  changeStatus(): void {
    const newState =
      this.strategy!.state == StrategyStateEnum.ENABLED ? StrategyStateEnum.PAUSED : StrategyStateEnum.ENABLED;
    this.strategyService
      .updateStrategyState(
        this.strategy!.accountId,
        this.strategy!.marketplace,
        this.organizationId!,
        this.strategy!.strategyId,
        newState,
      )
      .subscribe({
        next: () => {
          this.toastrService.success(
            newState == StrategyStateEnum.ENABLED
              ? this.translocoService.translate("strategy-grid.strategy_activated")
              : this.translocoService.translate("strategy-grid.strategy_paused"),
            this.translocoService.translate("strategy-page.strategy_status"),
          );
        },
        error: (error) => {
          this.toastrService.error(error, this.translocoService.translate("strategy-page.strategy_status_error"));
        },
      });
  }

  showAutoAlgoExploration(): boolean {
    return (
      !!this.strategy &&
      this.campaignType != CampaignType.SD && // AI-powered targeting is set via targetings section on Sponsored Display
      this.strategy.tactics.length != 0 &&
      (!this.strategy.autoAlgoExplorationEnabled ||
        this.strategy.tactics.some((x) => x.tacticType != TacticType.BLACKLIST))
    );
  }

  // AI-powered targeting
  switchAutoAlgoExploration() {
    this.strategyService
      .updateStrategyAutoAlgoExploration(
        this.strategy!.accountId,
        this.strategy!.marketplace,
        this.organizationId!,
        this.strategy!.strategyId,
        !this.strategy!.autoAlgoExplorationEnabled,
      )
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.strategy!.autoAlgoExplorationEnabled
              ? this.translocoService.translate("strategy-page.ai-powered_targeting_enabled")
              : this.translocoService.translate("strategy-page.ai-powered_targeting_disabled"),
            this.translocoService.translate("advanced-settings-modal.ai-powered_targeting"),
          );
        },
        error: (error) => {
          this.toastrService.error(error, this.translocoService.translate("strategy-page.ai-powered_targeting_error"));
        },
      });
  }

  // Automated targeting campaign
  switchAutoTargetCampain() {
    this.strategyService
      .updateStrategyTargetCampain(
        this.strategy!.accountId,
        this.strategy!.marketplace,
        this.organizationId!,
        this.strategy!.strategyId,
        !this.strategy!.autoTargetCampainEnabled,
      )
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.strategy!.autoTargetCampainEnabled
              ? this.translocoService.translate("strategy-page.auto_campaign_enabled")
              : this.translocoService.translate("strategy-page.auto_campaign_disabled"),
            this.translocoService.translate("strategy-page.auto_campaign"),
          );
        },
        error: (error) => {
          this.toastrService.error(error, this.translocoService.translate("strategy-page.auto_campaign_error"));
        },
      });
  }

  // Product targeting
  switchProductTargeting() {
    this.strategyService
      .updateStrategyProductTargeting(
        this.strategy!.accountId,
        this.strategy!.marketplace,
        this.organizationId!,
        this.strategy!.strategyId,
        !this.strategy!.productTargetingEnabled,
      )
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.strategy!.productTargetingEnabled
              ? this.translocoService.translate("strategy-page.product_targeting_enabled")
              : this.translocoService.translate("strategy-page.product_targeting_disabled"),
            this.translocoService.translate("advanced-settings-modal.product_targeting"),
          );
        },
        error: (error) => {
          this.toastrService.error(error, this.translocoService.translate("strategy-page.product_targeting_error"));
        },
      });
  }

  disableProductTargeting() {
    // see https://github.com/m19-dev/main-repo/issues/4237
    // disable product targeting if AI-powered targeting is disabled and only product tactics
    return (
      !this.strategy!.autoAlgoExplorationEnabled &&
      this.strategy!.tactics.length > 0 &&
      this.strategy!.tactics.every(
        (t) => this.segmentIndex.get(t.segmentId)?.segmentType == SegmentConfigType.ProductSegment,
      )
    );
  }

  disableAITargetingToggle() {
    // see https://github.com/m19-dev/main-repo/issues/4237
    // if product targeting is disabled and we have only product tactics, AI-powered targeting cannot be disabled
    return (
      this.strategy!.autoAlgoExplorationEnabled &&
      !this.strategy!.productTargetingEnabled &&
      this.strategy!.tactics.length > 0 &&
      this.strategy!.tactics.every(
        (t) => this.segmentIndex.get(t.segmentId)?.segmentType == SegmentConfigType.ProductSegment,
      )
    );
  }

  createTactic(): void {
    const allowedSegmentTacticTypes: [SegmentConfigType, TacticType][] = [];
    if (this.strategy!.campaignType != CampaignType.SD) {
      allowedSegmentTacticTypes.push([SegmentConfigType.KeywordSegment, TacticType.LEGACY]);
      allowedSegmentTacticTypes.push([SegmentConfigType.KeywordSegment, TacticType.BLACKLIST]);
    }
    if (this.strategy!.productTargetingEnabled && this.strategy!.campaignType != CampaignType.SB) {
      allowedSegmentTacticTypes.push([SegmentConfigType.ProductSegment, TacticType.LEGACY]);
      allowedSegmentTacticTypes.push([SegmentConfigType.ProductSegment, TacticType.BLACKLIST]);
    }

    const modalOptions: ModalOptions = {
      initialState: {
        strategy: this.strategy,
        allowedSegmentTacticTypes: allowedSegmentTacticTypes,
      },
      class: "modal-lg",
    };
    const modalRef = this.modalService.show(TacticAddPopupComponent, modalOptions);
    const subscription = modalRef
      .content!.segmentCreationRequested.pipe(untilDestroyed(this))
      .subscribe((segmentCreationRequest) => {
        const segmentCreationModalOption: ModalOptions = {
          initialState: {
            accountId: this.strategy!.accountId,
            marketplace: this.strategy!.marketplace,
          },
          class: "modal-xl",
        };
        if (segmentCreationRequest.segmentType == SegmentConfigType.ProductSegment) {
          const segmentCreationRef = this.modalService.show(ProductSegmentModalComponent, segmentCreationModalOption);
          this.createTacticAfterSegmentCreation(
            subscription,
            segmentCreationRequest.tacticType,
            segmentCreationRef.content!.segmentCreated,
            segmentCreationRef.content!.segmentEditionCanceled,
          );
        } else {
          const segmentCreationRef = this.modalService.show(KeywordSegmentModalComponent, segmentCreationModalOption);
          this.createTacticAfterSegmentCreation(
            subscription,
            segmentCreationRequest.tacticType,
            segmentCreationRef.content!.segmentCreated,
            segmentCreationRef.content!.segmentEditionCanceled,
          );
        }
      });
    subscription.add(
      modalRef.content!.tacticCreationCancelled.pipe(untilDestroyed(this)).subscribe(() => subscription.unsubscribe()),
    );
  }

  private createTacticAfterSegmentCreation(
    subscription: Subscription,
    tacticType: TacticType,
    segmentCreated: Observable<SegmentEx>,
    segmentCreationCancelled: Observable<void>,
  ) {
    subscription.add(
      segmentCreated
        .pipe(
          untilDestroyed(this),
          switchMap((s) => {
            return this.strategyService.addTacticToStrategy(
              this.strategy!.accountId,
              this.strategy!.marketplace,
              this.strategy!.strategyId,
              s.segmentId,
              tacticType,
            );
          }),
        )
        .subscribe({
          next: () => {
            this.toastrService.success(
              this.translocoService.translate("sp-strategy-group-page.tactic_sucessfully_added_to_strategy"),
              this.translocoService.translate("sp-strategy-group-page.tactic_created"),
            );
            subscription.unsubscribe();
          },
          error: (error) => {
            this.toastrService.error(
              error,
              this.translocoService.translate("sp-strategy-group-page.tactic_creation_error"),
            );
            subscription.unsubscribe();
          },
        }),
    );
    subscription.add(segmentCreationCancelled.pipe(untilDestroyed(this)).subscribe(() => subscription.unsubscribe()));
  }

  isInValidDailyBudget(): boolean {
    return !this.dailyBudget || this.dailyBudget < 1 || this.dailyBudget < this.getDailyBudgetLowerBound();
  }

  changeDailyBudget(): void {
    if (this.isInValidDailyBudget()) return;
    this.strategyService
      .updateStrategyDailyBudget(
        this.strategy!.accountId,
        this.strategy!.marketplace,
        this.organizationId!,
        this.strategy!.strategyId,
        Math.round(this.dailyBudget!),
      )
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("sp-strategy-group-page.daily_budget_updated"),
            this.translocoService.translate("strategy-page.success"),
          );
          this.oldDailyBudget = this.dailyBudget;
          this.dailyBudgetModal!.hide();
        },
        error: (error) => {
          this.toastrService.error(error, this.translocoService.translate("common.error"));
        },
      });
  }

  deleteDailyBudget(): void {
    this.strategyService
      .updateStrategyDailyBudget(
        this.strategy!.accountId,
        this.strategy!.marketplace,
        this.organizationId!,
        this.strategy!.strategyId,
        undefined,
      )
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("sp-strategy-group-page.daily_budget_removed"),
            this.translocoService.translate("strategy-page.success"),
          );
          this.dailyBudgetVisible = false;
          this.dailyBudgetModal!.hide();
        },
        error: (error) => {
          this.toastrService.error(error, this.translocoService.translate("common.error"));
        },
      });
  }

  openMinDailySpendModal() {
    if (this.isReadOnly) return;
    this.openedModalRef = this.modalService.show(this.updateMinDailySpendModal, {
      backdrop: "static", // Force clicking on cancel to reset min daily spend
      keyboard: false,
      class: "modal-primary modal-dialog-centered",
    });
  }

  cancelMinDailySpend() {
    this.minDailySpend = this.strategy!.minDailySpend;
    this.openedModalRef!.hide();
  }

  resetMinDailySpend() {
    this.minDailySpend = 0;
    this.updateMinDailySpend();
    this.openedModalRef!.hide();
  }

  updateMinDailySpend(): void {
    this.strategyService
      .updateStrategyMinDailySpend(
        this.strategy!.accountId,
        this.strategy!.marketplace,
        this.organizationId!,
        this.strategy!.strategyId,
        this.minDailySpend!,
      )
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("strategy-page.min_daily_spend_updated"),
            this.translocoService.translate("strategy-page.success"),
          );
          this.openedModalRef!.hide();
        },
        error: (error) => {
          this.toastrService.error(error, this.translocoService.translate("common.error"));
          this.openedModalRef!.hide();
        },
      });
  }

  changeName(newName: string) {
    this.strategyService
      .updateStrategyName(
        this.strategy!.accountId,
        this.strategy!.marketplace,
        this.organizationId!,
        this.strategy!.strategyId,
        newName,
      )
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("sp-strategy-group-page.strategy_name_updated"),
            this.translocoService.translate("strategy-page.success"),
          );
        },
        error: (error) => {
          this.toastrService.error(error, this.translocoService.translate("common.error"));
        },
      });

    this.strategyTitleModal!.hide();
  }

  getUserMaxMinDailyBudgetInEuros(): number {
    return Math.round(this.minDailySpendLimit! / currencyRateToEuro(this.currencyCode as Currency));
  }

  getDailyBudgetLowerBound(): number {
    if (this.strategy?.algoMode == AlgoMode.PRODUCT_LAUNCH) {
      if (this.suggestedBid) {
        return this.suggestedBid * 5;
      }
    }
    if (this.strategy?.algoMode == AlgoMode.ACOS_TARGET) {
      if (this.minDailySpend) {
        return this.minDailySpend * 2;
      }
    }
    return Math.ceil(1 / currencyRateToEuro(this.currencyCode as Currency));
  }

  getMinDailyBudgetLowerBound(): number {
    return Math.ceil(1 / currencyRateToEuro(this.currencyCode as Currency));
  }

  openStrategyLabelModal() {
    this.openedModalRef = this.modalService.show(this.updateLabelModal, {
      class: "modal-primary modal-dialog-centered",
    });
  }

  removeStrategyLabel() {
    this.strategyLabel = "";
    this.strategyService
      .updateStrategyLabel(
        this.strategy!.accountId,
        this.strategy!.marketplace,
        this.organizationId!,
        this.strategy!.strategyId,
        "",
      )
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("strategy-page.strategy_label_removed"),
            this.translocoService.translate("strategy-page.success"),
          );
          this.openedModalRef!.hide();
        },
        error: (error) => {
          this.toastrService.error(error, this.translocoService.translate("common.error"));
          this.openedModalRef!.hide();
        },
      });
  }

  changeStrategyLabel(): void {
    this.strategyService
      .updateStrategyLabel(
        this.strategy!.accountId,
        this.strategy!.marketplace,
        this.organizationId!,
        this.strategy!.strategyId,
        this.strategyLabel,
      )
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translocoService.translate("strategy-page.strategy_label_updated"),
            this.translocoService.translate("strategy-page.success"),
          );
          this.openedModalRef!.hide();
        },
        error: (error) => {
          this.toastrService.error(error, this.translocoService.translate("common.error"));
          this.openedModalRef!.hide();
        },
      });
  }

  changeAlgoMode() {
    if (this.isReadOnly || this.strategy?.primeDayBoost || this.strategy?.algoMode === AlgoMode.TACOS_TARGET) return;
    const modalOptions: ModalOptions = {
      initialState: {
        strategy: this.strategy,
      },
      class: "modal-primary modal-lg modal-dialog-centered",
    };
    this.modalService.show(SwitchAlgoModeComponent, modalOptions);
  }

  openAlgoTargetModal() {
    if (this.strategy?.algoMode === AlgoMode.TACOS_TARGET) return;
    const options: ModalOptions = {
      initialState: {
        algoTarget: { ...this.strategy, strategyName: this.strategy?.name },
        locale: this.locale,
        minBid: this.minAllowedBid,
        organizationId: this.organizationId,
      },
      class: "modal-primary modal-dialog-centered",
    };
    this.modalService.show(SwitchTargetAlgoModalComponent, options);
  }

  editStrategyNameModal(template: TemplateRef<any>) {
    if (this.isReadOnly || this.strategy?.defaultStrategy) {
      return;
    }
    this.newStrategyName = this.strategy?.name ?? "";
    this.strategyTitleModal = this.modalService.show(template, { class: "modal-primary modal-dialog-centered" });
  }

  editDailyBudget(template: TemplateRef<any>) {
    if (this.isReadOnly || this.strategy?.primeDayBoost) return;
    this.dailyBudgetModal = this.modalService.show(template, { class: "modal-primary modal-dialog-centered" });
  }

  selectMetrics(metrics: Metric<AdStatsWithTargetHistory>[]) {
    if ([ACOS, COST].every((i) => metrics.includes(i))) {
      this.selectedMetric$?.next([...metrics, TARGET_ACOS, ...this.additionalMetrics]);
    } else if (metrics.some((m) => m === COST)) {
      this.selectedMetric$?.next([...metrics, ...this.additionalMetrics]);
    } else if (metrics.some((m) => m === ACOS)) {
      this.selectedMetric$?.next([...metrics, TARGET_ACOS]);
    } else {
      this.selectedMetric$?.next(metrics);
    }
  }

  getFileName() {
    if (this.strategy) {
      return this.strategy.name + "_" + this.strategy.marketplace;
    }
    return `strategy`;
  }

  public selectAggregation(selection: DateAggregation): void {
    this.dateAggregation$.next(selection);
  }

  toggleEventAnnotation(change: MatSlideToggleChange): void {
    this.displayEventAnnotation$.next(change.checked);
  }

  getCampaignTypeName(t: CampaignType) {
    switch (t) {
      case CampaignType.SP:
        return "strategy-page.sponsored_product";
      case CampaignType.SB:
        return "strategy-page.sponsored_brand";
      case CampaignType.SD:
        return "v2-sidebar.sponsored_display";
      default:
        return "";
    }
  }

  getStrategyAlgoValues(): { title: string; value: number | undefined; unit: string } {
    switch (this.strategy?.algoMode) {
      case AlgoMode.MONTHLY_BUDGET_TARGET:
        return {
          title: "overview-grid.monthly_budget",
          value: this.strategy.monthlyBudget,
          unit: this.currencySymbol!,
        };
      case AlgoMode.PRODUCT_LAUNCH:
        return {
          title: "switch-target-algo-modal.suggested_bid",
          value: this.strategy.suggestedBid,
          unit: this.currencySymbol!,
        };
      case AlgoMode.ACOS_TARGET:
        return { title: "algo-mode-selection.acos_target", value: this.strategy.acosTarget, unit: "%" };
      case AlgoMode.TACOS_TARGET:
        return { title: "algo-mode-selection.tacos_target", value: this.strategy.tacosTarget, unit: "%" };
      default:
        return { title: "", value: undefined, unit: "" };
    }
  }

  openSubStrategyCreationPage(strategyType: StrategyType) {
    this.router.navigate([`${this.strategyGroupPageUrl}${this.strategyGroup!.strategyGroupId}/create-strategy`], {
      queryParamsHandling: "merge",
      queryParams: {
        strategyType,
      },
    });
  }

  toggleStatsChartDisplay(hide: boolean) {
    this.isStatsChartHidden = hide;
    this.userSelectionService.setUserChartDisplayedPreference(this.localStorageKey!, !this.isStatsChartHidden);
  }

  exportStrategyStatsCsv() {
    if (this.strategy?.campaignType == CampaignType.SB && this.selectedStatsTab === 0) {
      this.creativeGrid.exportCsv();
    } else {
      this.tacticPlacementGrid.exportCsv();
    }
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  getTacticSegment(tactic: StrategyTactic): SegmentEx | undefined {
    return this.segmentIndex.get(tactic.segmentId!);
  }
}
