import { AdStatsEx } from '@front/m19-models/AdStatsEx';
import { addAdStats, emptyAdStatEx, emptyVendorAdStatEx } from './statsUtils';
import { AdStats } from '@front/m19-api-client';
import { AggregationFunction, aggregationMethods } from './aggregation.utils';

export enum GroupBy {
  AsinDate,
  Asin,
}

export const groupByAsinDate = (adStats: AdStatsEx): string => {
  return `${adStats.asin}_${adStats.date}`;
};

export const groupByAsin = (stat: { asin: string }): string => {
  return stat.asin!;
};

export const groupers: Record<GroupBy, (stat: any) => string> = {
  [GroupBy.AsinDate]: groupByAsinDate,
  [GroupBy.Asin]: groupByAsin,
};

export function merge2dAdStats(data: Array<AdStats[]>, groupBy: GroupBy): AdStats[] {
  const mergedData: Map<string, AdStats> = new Map();
  const getHash = groupers[groupBy];

  data.forEach((adStats) => {
    adStats.forEach((adStat) => {
      const hash = getHash(adStat);
      mergedData.set(hash, addAdStats(adStat, mergedData.get(hash) ?? emptyAdStatEx()));
    });
  });

  return Array.from(mergedData.values());
}

function mergeData<T>(data: T[], groupBy: GroupBy, aggFunction: AggregationFunction, emptyObjGetter: () => T): T[] {
  const mergedData: Map<string, T> = new Map();
  const getHash = groupers[groupBy];
  const agg = aggregationMethods[aggFunction];

  data.forEach((stat) => {
    const hash = getHash(stat);
    mergedData.set(hash, agg({ ...stat }, mergedData.get(hash) ?? emptyObjGetter()));
  });

  return Array.from(mergedData.values());
}

export function mergeVendorAdStatsEx(adStats: AdStatsEx[], groupBy: GroupBy): AdStatsEx[] {
  return mergeData(adStats, groupBy, AggregationFunction.sumAdStats, emptyVendorAdStatEx);
}

export function mergeVendorAdStatsExByAsin(adStats: AdStatsEx[]): AdStatsEx[] {
  return mergeData(adStats, GroupBy.Asin, AggregationFunction.sumVendorInventoryAdStats, emptyAdStatEx);
}
