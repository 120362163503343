<ng-container *transloco="let t">
  <div class="flex w-full">
    @if (strategy.strategyType !== StrategyType.LEGACY) {
      <div class="mr-2">
        <IBadge [label]="$any(StrategyTypeStr)[strategy.strategyType]" size="xs" />
      </div>
    }
    <app-strategy-link
      [strategyId]="strategy.strategyId"
      [withCampaignType]="true"
      class="w-full truncate"
      [color]="strategy.algoMode !== AlgoMode.ACOS_TARGET ? 'black' : ''"
    ></app-strategy-link>
  </div>

  @if (strategy.strategyType !== StrategyType.LEGACY) {
    <div class="w-full">
      {{ t("strategy-boost-table.strategy_group") }}&nbsp;<a
        [routerLink]="'/strategies/strategy-group/sponsored-product/' + this.strategyGroupId"
        queryParamsHandling="merge"
        class="text-dark"
        >{{ this.strategyGroupName }}
      </a>
    </div>
  }
</ng-container>
