import { Component, computed, EventEmitter, Input, Output, signal } from "@angular/core";
import { MatRadioModule } from "@angular/material/radio";
import { MatTooltipModule } from "@angular/material/tooltip";
import { IButtonComponent, IDropdownComponent, IMultiSelectComponent, Option } from "@front/m19-ui";
import { TranslocoRootModule } from "@m19-board/transloco-root.module";
import { FilterType } from "@m19-board/shared/filter/asinFilter";
import { ICON_CLOSE } from "@m19-board/utils/iconsLabels";
import { NgClass } from "@angular/common";

export interface Filter<T, K = FilterType> {
  type: K;
  exclude?: boolean;
  label: string;
  noValuePlaceholder: string;
  category?: string;
  tooltip?: string;
  color?: string;
  selectedOptions: Option<T>[];
  options: () => Option<T>[];
  unique?: boolean;
  excludeModeAllowed: boolean;
  singleValue?: boolean;
  oneShotSelect?: boolean;
}

@Component({
  selector: "app-filter",
  standalone: true,
  imports: [
    IMultiSelectComponent,
    MatTooltipModule,
    MatRadioModule,
    TranslocoRootModule,
    IDropdownComponent,
    IButtonComponent,
    NgClass,
  ],
  templateUrl: "./filter.component.html",
})
export class FilterComponent<T, K> {
  readonly ICON_CLOSE = ICON_CLOSE;

  _filters = signal<Filter<T, K>[]>([]);
  hasSelectedFilters = computed(() => this._filters().some((f) => f.selectedOptions.length > 0));

  selectedFilter = signal<Filter<T, K> | undefined>(undefined);
  _filtersOptions: Option<Filter<T, K>>[] = [];

  @Input() set filters(filters: Filter<T, K>[]) {
    this._filters.set(filters);
    this._filtersOptions = filters
      .filter((f) => !f.unique || !this.isOptionSelected(f))
      .map((f) => ({
        label: f.label,
        value: f,
        category: f.category,
      }));
  }

  @Output() filterChange = new EventEmitter<Filter<T, K>[]>();

  selectFilterV2(filter: Filter<T, K>, event: MouseEvent) {
    if (filter.oneShotSelect) {
      if (filter.selectedOptions.length) filter.selectedOptions = [];
      else filter.selectedOptions = [filter.options()[0]];
      this.filterChange.emit([...this._filters()]);
    } else {
      this.selectedFilter.set(filter);
    }

    event.stopPropagation();
  }

  selectValue(filterTag: Filter<T, K>, value: Option<T>[]) {
    const filter = this.findFilter(filterTag);
    if (!filter) return;
    filter.selectedOptions = value;
    this.filterChange.emit([...this._filters()]);
    this.selectedFilter.set(filter);
  }

  clearAllFilters() {
    let hadSelectedFilters = false;
    const unselectedFilters = this._filters().map((f) => {
      hadSelectedFilters ||= f.selectedOptions.length > 0;
      return { ...f, selectedOptions: [] };
    });
    if (!hadSelectedFilters) return;

    this._filters.set(unselectedFilters);
    this.filterChange.emit([...this._filters()]);
  }

  excludeFilter(filterTag: Filter<T, K>, exclude: boolean) {
    const filter = this.findFilter(filterTag);
    if (filter) {
      filter.exclude = exclude;
      this.filterChange.emit([...this._filters()]);
    }
  }

  backToFiltersMenu(event?: MouseEvent) {
    this.selectedFilter.set(undefined);
    event?.stopPropagation();
  }

  private findFilter(filter: Filter<T, K>) {
    return this._filters().find((f) => this.filterEquals(f, filter));
  }

  private filterEquals(filter1: Filter<T, K>, filter2: Filter<T, K>) {
    return filter1.type === filter2.type && filter1.exclude === filter2.exclude;
  }

  private isOptionSelected(filter: Filter<T, K>) {
    return filter.selectedOptions.length > 0;
  }
}
