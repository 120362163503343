@if ((loading$ | async) === false) {
  <div class="dates-row table-line flex items-center px-2">
    @for (day of dateRange; track day; let i = $index) {
      <div class="header table-unit shrink-0">
        <p class="m-0 text-gray-700">{{ formatDate(day) }}</p>
      </div>
    }
  </div>
  @for (p of tableData; track p) {
    <div class="table-line flex items-center px-2" [class.both-row]="_rankOption === RankOption.BOTH">
      <ng-container *ngTemplateOutlet="tableRow; context: { data: p }"></ng-container>
    </div>
  }
  <ng-template #tableRow let-d="data">
    @for (day of dateRange; track day) {
      <div
        [ngClass]="'gradient-' + (d.timeline![day] | getRank: _rankOption)?.bucket"
        [class.both-cell]="_rankOption === RankOption.BOTH"
        class="table-unit shrink-0"
        [matTooltip]="formatDate(day, true)"
      >
        @if (_rankOption !== RankOption.BOTH) {
          <div>
            {{ (d.timeline![day] | getRank: _rankOption)?.rank | number: "1.0-0" }}
          </div>
        } @else {
          <span class="org-rank">
            {{ ((d.timeline![day] | getRank: RankOption.ORGANIC)?.rank | number: "1.0-0") ?? "-" }}
          </span>
          <span class="sp-rank ml-2">{{
            ((d.timeline![day] | getRank: RankOption.SPONSORED)?.rank | number: "1.0-0") ?? "-"
          }}</span>
        }
      </div>
    }
  </ng-template>
}

@if (loading$ | async) {
  <div class="flex h-full w-full items-center justify-center">
    <app-spinner type="default" size="s" [display]="true"></app-spinner>
  </div>
}
