<ng-container *transloco="let t">
  <div [style.height]="height ?? '100%'">
    <div class="flex h-full min-h-0 flex-col">
      @if (isManualManaging) {
        <div class="alert alert-warning -mb-1 mt-1 w-fit p-1 text-sm">
          {{ t("asins-selection.missing_an_asin") }}
          <a routerLink="/product-center/catalog" queryParamsHandling="merge" target="_blank">{{
            t("asins-selection.go_to_your_catalog_to_see_if_it_is_a_managed_asin")
          }}</a
          >.
        </div>
      }
      @if (selectionModes.length > 1) {
        <ul class="nav-wrapper nav-sm">
          @for (mode of selectionModes; track mode) {
            <li
              [attr.data-testid]="
                mode.selectionMode === SelectionModes.FromProductGroups
                  ? 'tab-pg'
                  : mode.selectionMode === SelectionModes.FromCatalog
                    ? 'tab-catalog'
                    : 'tab-bulk'
              "
              class="nav-element"
              (click)="changeSelectionMode(mode.selectionMode)"
              [class.active]="selectionMode === mode.selectionMode"
            >
              <a>{{ t(mode.label) }}</a>
            </li>
          }
        </ul>
      }

      <div class="flex h-[1px] flex-auto gap-x-2">
        <div class="flex h-full max-h-full min-w-0 flex-1 flex-col gap-y-2" data-testid="selection-from">
          @if (selectionMode === SelectionModes.FromCatalog) {
            @if (catalog) {
              <app-strategy-asins
                [onlyAddParent]="onlyAddParent"
                [withCustomFieldFilter]="true"
                [asins]="catalogAsins!"
                [disabledAsins]="disabledAsins"
                [isReadOnly]="isReadOnly"
                [alreadySelectedAsins]="_asins!"
                [accountId]="accountId"
                [marketplace]="marketplace"
                [selectable]="true"
                [deletable]="false"
                [movable]="false"
                [allowGroupByParentAsin]="true"
                [csvExport]="csvExport"
                [csvExportFileName]="csvExportFileName!"
                [asinEligibility]="asinEligibility"
                (selectedProducts)="selectCatalogAsins($event)"
              >
                <div class="flex justify-end gap-x-2 p-2" withAsins>
                  <IButton
                    label="{{ t('asins-selection.add_selection') }}"
                    data-testid="btn-add-asin"
                    tooltipValue="{{ t('asins-selection.add_selection') }}"
                    [disabled]="isAddButtonDisabled()"
                    (onClick)="addStrategyAsin()"
                  />
                  <ng-content select="[catalog]"></ng-content>
                </div>
              </app-strategy-asins>
            }
          } @else if (selectionMode === SelectionModes.Bulk) {
            @if (errors && errors.length > 0) {
              <div
                class="relative w-full rounded-lg bg-orange-50 p-4 text-orange-500 ring-1 ring-inset ring-orange-500 ring-opacity-25"
                data-testid="bulk-upload-error"
              >
                <div class="flex items-start gap-3">
                  <span class="icon-[mdi--warning-circle-outline]"></span>
                  <div class="w-0 flex-1">
                    <p class="m-0 text-sm font-medium leading-4 text-inherit">
                      {{ t("catalog-upload.asins_ignored") }}
                    </p>
                    <div class="mt-1 text-sm leading-5 opacity-90">
                      <ul class="mb-0 list-inside space-y-1 p-0">
                        @for (e of errors; track e) {
                          <li>
                            {{ e }}
                          </li>
                        }
                      </ul>
                    </div>
                  </div>
                  <IButton
                    color="orange"
                    size="xs"
                    variant="ghost"
                    tooltipValue="{{ t('sb-strategy-form.hide') }}"
                    (onClick)="errors = []"
                    [icon]="ICON_CLOSE"
                  />
                </div>
              </div>
            }
            <div class="relative h-full">
              <textarea
                data-testid="bulk-asin-textarea"
                [(ngModel)]="bulkAsins"
                class="relative block h-full w-full gap-x-2 rounded-lg border-0 bg-white px-3 py-2 text-sm text-gray-900 placeholder-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-300 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-75"
                [placeholder]="bulkPlaceholderText"
                aria-label="ASIN list text area for bulk import"
              >
              </textarea>
              @if (bulkAsins.length > 0) {
                <IButton
                  label="{{ t('asins-selection.clear') }}"
                  color="gray"
                  size="xs"
                  variant="ghost"
                  class="absolute right-2 top-2"
                  tooltipValue="{{ t('asins-selection.clear') }}"
                  (onClick)="bulkAsins = ''"
                />
              }
            </div>
            <div>
              <div class="flex justify-end">
                <IButton
                  label="{{ t('asins-selection.add_selection') }}"
                  testid="btn-add-bulk-asin"
                  tooltipValue="{{ t('asins-selection.add_asins_by_bulk') }}"
                  [disabled]="isBulkAddButtonDisabled()"
                  (onClick)="addStrategyAsinByBulk()"
                />
                <ng-content select="[bulk]"></ng-content>
              </div>
            </div>
          } @else if (selectionMode === SelectionModes.FromCustomAsinList) {
            @if (catalog) {
              <app-strategy-asins
                class="h-full"
                [isReadOnly]="disabled"
                [onlyAddParent]="onlyAddParent"
                [asins]="customAsins!"
                [alreadySelectedAsins]="_asins!"
                [accountId]="accountId"
                [marketplace]="marketplace"
                [selectable]="true"
                [deletable]="false"
                [movable]="false"
                [allowGroupByParentAsin]="true"
                [csvExport]="csvExport"
                [csvExportFileName]="csvExportFileName!"
                [asinEligibility]="asinEligibility"
                (selectedProducts)="selectCatalogAsins($event)"
              >
                <div withAsins class="flex justify-end gap-x-2 p-2">
                  <IButton
                    label="{{ t('common.add') }}"
                    data-testid="btn-add-asin"
                    tooltipValue="{{ t('asins-selection.add_selection') }}"
                    [disabled]="isAddButtonDisabled()"
                    (onClick)="addStrategyAsin()"
                  />
                  <ng-content select="[customAsin]"></ng-content>
                </div>
              </app-strategy-asins>
            }
          } @else if (selectionMode === SelectionModes.FromProductGroups) {
            <div class="flex h-full w-full flex-col">
              <div class="flex items-center justify-between p-2 pl-3">
                <div class="flex flex-1 items-center gap-x-2">
                  <ICheckbox
                    class="ml-1"
                    [value]="isAllProductGroupSelected()"
                    (valueChange)="isAllProductGroupSelected() ? unselectAllProductGroups() : selectAllProductGroups()"
                    [tooltipValue]="
                      isAllProductGroupSelected()
                        ? t('product-group.unselect_all_product_groups')
                        : t('product-group.select_all_product_groups')
                    "
                  />

                  <IInput
                    [control]="pgSearchControl"
                    [icon]="ICON_SEARCH"
                    placeholder="{{ t('asins-selection.search_by_product_group_name') }}"
                    class="flex-1"
                  />
                </div>
              </div>
              <div class="min-h-0 flex-1 basis-0 overflow-auto" data-testid="from-pg">
                @for (productGroup of filteredProductGroups; track productGroup.productGroupId) {
                  <div
                    class="asin-card position-relative group flex items-center justify-between p-3"
                    (click)="selectProductGroup(productGroup)"
                    [class.disabled]="
                      getProductGroupItems(productGroup).length === 0 || productGroupAlreadySelected(productGroup)
                    "
                  >
                    <div class="flex min-w-0 items-center">
                      <ICheckbox
                        class="mr-2"
                        [value]="
                          isProductGroupSelected(productGroup) ||
                          (productGroupAlreadySelected(productGroup) && getProductGroupItems(productGroup).length > 0)
                        "
                      />

                      <app-product-group-thumbnail
                        [asins]="getProductGroupItems(productGroup)"
                        [marketplace]="marketplace"
                        class="product-group-thumbnail"
                      >
                      </app-product-group-thumbnail>
                      <div class="ml-3 min-w-0 flex-1 truncate">
                        <div
                          class="truncate text-gray-800"
                          [matTooltip]="
                            t('asins-selection.product_group_tooltip', {
                              name: productGroup.productGroupName,
                              count: getProductGroupItems(productGroup).length,
                            })
                          "
                          style="font-weight: 500"
                        >
                          {{ productGroup.productGroupName }}
                        </div>
                      </div>
                    </div>
                    <IButton
                      class="opacity-0 group-hover:opacity-100"
                      color="white"
                      tooltipValue="{{ t('asins-selection.open_product_group_info') }}"
                      (onClick)="openProductGroupInfo(productGroup); $event.stopPropagation()"
                      icon="icon-[material-symbols--info-outline-rounded]"
                    />
                  </div>
                }
              </div>
              <div class="flex items-center justify-end border-t p-2">
                <IButton
                  label="{{ t('asins-selection.add_selection') }}"
                  testid="btn-add-pg-asin"
                  tooltipValue="{{ t('asins-selection.add_selection') }}"
                  [disabled]="isProductGroupAddButtonDisabled()"
                  (onClick)="addStrategyAsinByProductGroup()"
                />
                <ng-content select="[productGroup]"></ng-content>
              </div>
            </div>
          }
        </div>
        <div class="selection-to flex h-full max-h-full min-w-0 flex-1">
          <app-strategy-asins
            #asinsSelection
            [isReadOnly]="isReadOnly"
            [asins]="_asins!"
            [accountId]="accountId"
            [marketplace]="marketplace"
            [selectable]="true"
            [deletable]="true"
            [movable]="false"
            [allowGroupByParentAsin]="false"
            [csvExport]="csvExport"
            [csvExportFileName]="csvExportFileName!"
            [asinEligibility]="asinEligibility"
            (onDelete)="removeAsins($event)"
          />
        </div>
      </div>
    </div>
  </div>
</ng-container>
