import { Marketplace } from '@front/m19-api-client';
import moment from 'moment-timezone';
import { catchError, forkJoin, map, Observable, of, ReplaySubject, Subject, Subscription, take } from 'rxjs';

export type ByMarketplace<T> = Map<Marketplace, T>;
export type ByAsin<T> = Map<string, T>;
export type BySearchTerm<T> = Map<string, T>;
export type ByAccountMarketplace<T> = Map<string, T>;
export type AccountMarketplaceKey = string;
export type AccountMarketplaceOrganizationIdKey = string;
export type CogsByAsin = ByAsin<Array<[string, number]>>;

export function accountMarketplaceKey(accountId: string, marketplace: Marketplace | string, useSourcing?: boolean) {
  return `${accountId}:${marketplace}` + (useSourcing ? ':src' : '');
}

export function accountMarketplaceOrganizationKey(
  accountId: string,
  marketplace: Marketplace | string,
  organizationId: number,
) {
  return `${accountId}:${marketplace}:${organizationId}`;
}

export type ByAccountMarketplaceOrganization<T> = Map<string, T>;

type AccountMarketplaceCacheKey = { accountId: string; marketplace: Marketplace };
type AccountMarketplaceOrganizationCacheKey = {
  accountId: string;
  marketplace: Marketplace;
  organizationId: number;
};

/**
 * A convenient class to cache data
 * It holds a "context" state which will invalidate the whole cache when it changes
 */
export abstract class DataCache<K, T, C> {
  private readonly cache: Map<string, Subject<T>> = new Map();
  private readonly values: Map<string, T> = new Map();
  private context?: C = undefined;
  private subscriptions: Map<string, Subscription> = new Map();

  protected abstract serializeKey(key: K): string;

  protected abstract loadKey(key: K, context: C): Observable<T>;

  protected abstract compareContext(c1: C, c2: C): boolean;

  private sameContext(context: C) {
    return this.context && this.compareContext(this.context, context);
  }

  protected getFromCache(key: K, context: C): Observable<T> {
    const keyStr = this.serializeKey(key);
    if (this.sameContext(context) && this.cache.has(keyStr)) {
      return this.cache.get(keyStr)!;
    }
    // clear the cache if context has changed
    if (!this.sameContext(context)) {
      this.cache.clear();
      this.values.clear();
      this.context = context;
      this.subscriptions.forEach((s) => s.unsubscribe());
      this.subscriptions.clear();
    }
    // put value observable in the cache
    const subject = new ReplaySubject<T>(1);
    subject.subscribe((val) => {
      this.values.set(keyStr, val);
    });
    this.cache.set(keyStr, subject);
    if (this.subscriptions.has(keyStr)) {
      this.subscriptions.get(keyStr)!.unsubscribe();
    }
    this.subscriptions.set(
      keyStr,
      this.loadKey(key, context).subscribe((data) => {
        if (!this.cache.has(keyStr)) {
          return;
        }
        this.cache.get(keyStr)!.next(data);
      }),
    );
    return subject;
  }

  public reloadKey(key: K) {
    const keyStr = this.serializeKey(key);
    if (!this.cache.has(keyStr)) {
      return;
    }
    if (this.subscriptions.has(keyStr)) {
      this.subscriptions.get(keyStr)!.unsubscribe();
    }
    this.subscriptions.set(
      keyStr,
      this.loadKey(key, this.context!).subscribe((data) => {
        if (!this.cache.has(keyStr)) {
          return;
        }
        this.cache.get(keyStr)!.next(data);
      }),
    );
  }

  public updateValue(key: K, newValue: (oldValue: T) => T) {
    const keyStr = this.serializeKey(key);
    if (!this.cache.has(keyStr) || !this.values.has(keyStr)) {
      return;
    }
    if (this.subscriptions.has(keyStr)) {
      this.subscriptions.get(keyStr)!.unsubscribe();
    }
    this.cache.get(keyStr)!.next(newValue(this.values.get(keyStr)!));
  }
}

/**
 * A convenient class to cache data per account marketplace.
 * It holds a "context" state which will invalidate the whole cache when it changes
 */
export class AccountMarketplaceDataCache<T, C> extends DataCache<AccountMarketplaceCacheKey, T, C> {
  constructor(
    private keyLoader: (account: string, marketplace: Marketplace, context: C) => Observable<T>,
    private contextComparer: (c1: C, c2: C) => boolean,
  ) {
    super();
  }

  protected serializeKey({ accountId, marketplace }: AccountMarketplaceCacheKey): string {
    return accountMarketplaceKey(accountId, marketplace);
  }

  protected loadKey({ accountId, marketplace }: AccountMarketplaceCacheKey, context: C): Observable<T> {
    return this.keyLoader(accountId, marketplace, context);
  }

  protected compareContext(c1: C, c2: C): boolean {
    return this.contextComparer(c1, c2);
  }

  public get(accountId: string, marketplace: Marketplace, context: C): Observable<T> {
    return super.getFromCache({ accountId, marketplace }, context);
  }
}

/**
 * A convenient class to cache data per account, marketplace, organization.
 * It holds a "context" state which will invalidate the whole cache when it changes
 */
class AccountMarketplaceOrganizationDataCache<T, C> extends DataCache<AccountMarketplaceOrganizationCacheKey, T, C> {
  constructor(
    private keyLoader: (account: string, marketplace: Marketplace, organizationId: number, context: C) => Observable<T>,
    private contextComparer: (c1: C, c2: C) => boolean,
  ) {
    super();
  }

  protected serializeKey({ accountId, marketplace, organizationId }: AccountMarketplaceOrganizationCacheKey): string {
    return accountMarketplaceOrganizationKey(accountId, marketplace, organizationId);
  }

  protected loadKey(
    { accountId, marketplace, organizationId }: AccountMarketplaceOrganizationCacheKey,
    context: C,
  ): Observable<T> {
    return this.keyLoader(accountId, marketplace, organizationId, context);
  }

  protected compareContext(c1: C, c2: C): boolean {
    return this.contextComparer(c1, c2);
  }

  public get(accountId: string, marketplace: Marketplace, organizationId: number, context: C): Observable<T> {
    return super.getFromCache({ accountId, marketplace, organizationId }, context);
  }

  public update(accountId: string, marketplace: Marketplace, organizationId: any, newValue: (oldValue: T) => T) {
    this.updateValue({ accountId, marketplace, organizationId }, newValue);
  }
}

const NoneContext = 'None';
type NoneContext = typeof NoneContext;

export class AccountMarketplaceOrganizationDataCacheNoContext<T> extends AccountMarketplaceOrganizationDataCache<
  T,
  NoneContext
> {
  constructor(keyLoader: (account: string, marketplace: Marketplace, organizationId: number) => Observable<T>) {
    super(
      (accountId: string, markeplace: Marketplace, organizationId) => keyLoader(accountId, markeplace, organizationId),
      () => true,
    );
  }

  public override get(accountId: string, marketplace: Marketplace, organizationId: number): Observable<T> {
    return super.getFromCache({ accountId, marketplace, organizationId }, NoneContext);
  }

  public reload(accountId: string, marketplace: Marketplace, organizationId: number) {
    super.reloadKey({ accountId, marketplace, organizationId });
  }
}

export class AccountMarketplaceDataCacheNoContext<T> extends AccountMarketplaceDataCache<T, NoneContext> {
  constructor(keyLoader: (account: string, marketplace: Marketplace) => Observable<T>) {
    super(
      (accountId: string, markeplace: Marketplace) => keyLoader(accountId, markeplace),
      () => true,
    );
  }

  public override get(accountId: string, marketplace: Marketplace): Observable<T> {
    return super.getFromCache({ accountId, marketplace }, NoneContext);
  }

  public reload(accountId: string, marketplace: Marketplace) {
    super.reloadKey({ accountId, marketplace });
  }
}

/**
 * A class used to cache data per key
 * The cache is a map of ReplaySubject so that the recent value can be replayed to new subscribers.
 * The cache value can be updated, deleted or errored. Subscribers will be notified of the change.
 * If cacheSize = 0, the cache has an unlimited size
 * If cacheSize > 0, the cache is limited in size and will evict the oldest key when the limit is reached
 */
export abstract class WritableCache<K, V> {
  private readonly cache = new Map<string, ReplaySubject<V>>();

  private readonly keys: string[] = [];

  protected abstract serializeKey(key: K): string;

  protected abstract loadKey(key: K): Observable<V>;

  constructor(private readonly cacheSize = 0) {}

  public get(key: K): Observable<V> {
    const keyStr = this.serializeKey(key);
    if (this.cache.has(keyStr)) {
      return this.cache.get(keyStr)!;
    }
    const subject = new ReplaySubject<V>(1);
    this.setInCache(keyStr, subject);
    this.keys.push(keyStr);
    this.loadKey(key)
      .pipe(take(1))
      .subscribe({
        next: (value) => {
          subject.next(value);
        },
        error: (error) => {
          subject.error(error);
          // delete the subject from the cache to force a reload
          this.cache.delete(keyStr);
          this.keys.splice(this.keys.indexOf(keyStr), 1);
        },
      });
    return subject;
  }

  private setInCache(keyStr: string, value: ReplaySubject<V>) {
    this.cache.set(keyStr, value);
    const newLength = this.keys.push(keyStr);
    // eviction policy here: remove the oldest key
    if (this.cacheSize > 0 && newLength > this.cacheSize) {
      const keyToDelete = this.keys.shift();
      if (keyToDelete) {
        this.cache.get(keyToDelete)!.complete();
        this.cache.delete(keyToDelete);
      }
    }
  }

  update(key: K, updateFn: (previous: V) => V) {
    const keyStr = this.serializeKey(key);
    if (!this.cache.has(keyStr)) {
      // nothing to update
      return;
    }
    this.cache
      .get(keyStr)!
      .pipe(take(1))
      .subscribe((value) => {
        try {
          const newValue = updateFn(value);
          this.cache.get(keyStr)!.next(newValue);
        } catch (e) {
          this.cache.get(keyStr)!.error(e);
          // delete the subject from the cache to force a reload
          this.cache.delete(keyStr);
          this.keys.splice(this.keys.indexOf(keyStr), 1);
        }
      });
  }

  error(key: K, error: any) {
    const keyStr = this.serializeKey(key);
    if (this.cache.has(keyStr)) {
      this.cache.get(keyStr)!.error(error);
      // delete the subject from the cache to force a reload
      this.cache.delete(keyStr);
      this.keys.splice(this.keys.indexOf(keyStr), 1);
    }
  }

  delete(key: K): boolean {
    const keyStr = this.serializeKey(key);
    if (this.cache.has(keyStr)) {
      this.cache.get(keyStr)!.complete();
      this.keys.splice(this.keys.indexOf(keyStr), 1);
      return this.cache.delete(keyStr);
    }
    return false;
  }
}

export class AccountMarketplaceWritableCache<V> extends WritableCache<AccountMarketplaceCacheKey, V> {
  constructor(
    private readonly loader: (accountId: string, markeplace: Marketplace) => Observable<V>,
    cacheSize = 0,
  ) {
    super(cacheSize);
  }

  protected serializeKey(key: AccountMarketplaceCacheKey): string {
    return accountMarketplaceKey(key.accountId, key.marketplace);
  }

  protected loadKey(key: AccountMarketplaceCacheKey): Observable<V> {
    return this.loader(key.accountId, key.marketplace);
  }
}

/**
 * A class used to cache data per key
 * When there is a cache miss, the loader function is called to load the data
 * A max size can be set to limit the number of keys in the cache.
 * The cache can also be preloaded with a list of key->value
 */
export class LazyReadOnlyCache<K, V> {
  private readonly cache = new Map<string, V>();
  private readonly keys: string[] = [];
  constructor(
    private readonly loader: (key: K) => V,
    private readonly keySerializer: (key: K) => string,
    private readonly size: number = 0,
    preload: (() => IterableIterator<[K, V]>) | undefined = undefined,
  ) {
    if (preload) {
      for (const [key, value] of preload()) {
        const keyStr = this.keySerializer(key);
        this.cache.set(keyStr, value);
        if (this.size > 0) {
          this.keys.push(keyStr);
          if (this.keys.length > this.size) {
            break;
          }
        }
      }
    }
  }

  public get(key: K): V {
    const keyStr = this.keySerializer(key);
    if (!this.cache.has(keyStr)) {
      this.cache.set(keyStr, this.loader(key));
      if (this.size > 0) {
        this.keys.push(keyStr);
        if (this.keys.length > this.size) {
          const keyToDelete = this.keys.shift();
          if (keyToDelete) {
            this.cache.delete(keyToDelete);
          }
        }
      }
    }
    return this.cache.get(keyStr)!;
  }

  public getCacheSize() {
    return this.cache.size;
  }
}

export class AccountMarketplaceLazyReadOnlyCache<V> extends LazyReadOnlyCache<AccountMarketplaceCacheKey, V> {
  constructor(
    loader: (accountId: string, markeplace: Marketplace) => V,
    size = 0,
    preload: (() => IterableIterator<[AccountMarketplaceCacheKey, V]>) | undefined = undefined,
  ) {
    super(
      ({ accountId, marketplace }) => loader(accountId, marketplace),
      (key) => accountMarketplaceKey(key.accountId, key.marketplace),
      size,
      preload,
    );
  }

  public getValue(accountId: string, marketplace: Marketplace): V {
    return this.get({ accountId, marketplace });
  }
}

export class AggregatedLazyReadOnlyCache<V> extends LazyReadOnlyCache<
  { accountId: string; marketplace: Marketplace; useSourcingMetrics?: boolean }[],
  V
> {
  constructor(
    loader: (accountMarketplaces: { accountId: string; marketplace: Marketplace; useSourcingMetrics?: boolean }[]) => V,
  ) {
    super(
      loader,
      (key) => {
        const keys = key.map((k) => accountMarketplaceKey(k.accountId, k.marketplace, k.useSourcingMetrics)).sort();
        return keys.join(',');
      },
      1, // cache only one value by default
      undefined, // no preload
    );
  }
}

type TimeSeriesCacheConfig = {
  maxTimeSpan: number;
  maxChunkSize: number;
};

const DefaultTimeSeriesCacheConfig: TimeSeriesCacheConfig = {
  maxTimeSpan: 365 + 366, // two years
  maxChunkSize: 15, // 15d chunks by default
};

export class TimeSeriesCache<V extends object> {
  private static datesIndex: Map<string, number> = new Map(); // map a date to an index in the cache
  private static dates: string[] = []; // cache the dates

  private cache: (V | null)[]; // null means there is no data for this date
  public readonly config: TimeSeriesCacheConfig;

  constructor(
    private readonly loader: (start: string, end: string) => Observable<Map<string, V>>, // load data for a date range and return a date->data map
    config: Partial<TimeSeriesCacheConfig> = DefaultTimeSeriesCacheConfig,
  ) {
    this.config = { ...DefaultTimeSeriesCacheConfig, ...config };
    this.cache = new Array(this.config.maxTimeSpan);
  }

  public static dateToIndex(date: string): number {
    if (!TimeSeriesCache.datesIndex.has(date)) {
      const s = moment(date);
      const today = moment().startOf('day');
      if (s.isAfter(today, 'day')) {
        return -1; // future date are not allowed for the moment
      }
      let diff = today.diff(s, 'day');
      while (s.isSameOrBefore(today, 'day')) {
        const formatted = s.format('YYYY-MM-DD');
        TimeSeriesCache.datesIndex.set(formatted, diff);
        TimeSeriesCache.dates[diff] = formatted;
        s.add(1, 'day');
        diff -= 1;
        if (TimeSeriesCache.datesIndex.has(s.format('YYYY-MM-DD'))) {
          break;
        }
      }
    }
    return TimeSeriesCache.datesIndex.get(date)!;
  }

  public clear() {
    this.cache = new Array(this.config.maxTimeSpan);
  }

  public get(start: string, end: string): Observable<V[]> {
    const startIndex = TimeSeriesCache.dateToIndex(end);
    let endIndex = TimeSeriesCache.dateToIndex(start);
    if (endIndex >= this.cache.length) {
      endIndex = this.cache.length - 1;
    }
    if (
      startIndex < 0 ||
      endIndex < 0 ||
      startIndex > endIndex ||
      startIndex >= this.cache.length ||
      endIndex >= this.cache.length
    ) {
      // invalid date range
      return of([]);
    }
    const aux: ({ start: number; end: number } | V[])[] = [];
    let missingStart: number | undefined = undefined;
    for (let i = startIndex; i <= endIndex; i++) {
      if (this.cache[i] === undefined) {
        if (missingStart === undefined) {
          missingStart = i;
        } else if (this.config.maxChunkSize > 0 && i - missingStart >= this.config.maxChunkSize) {
          aux.unshift({ start: missingStart, end: i - 1 });
          missingStart = i;
        }
      } else {
        if (missingStart !== undefined) {
          aux.unshift({ start: missingStart, end: i - 1 });
          missingStart = undefined;
          if (this.cache[i] !== null) {
            aux.unshift([this.cache[i]!]);
          } else {
            aux.unshift([]);
          }
        } else {
          if (aux.length === 0) {
            aux.unshift([]);
          }
          if (this.cache[i] !== null) {
            (aux[0] as V[]).unshift(this.cache[i]!); // add at the beginning of the array
          }
        }
      }
    }
    if (missingStart !== undefined) {
      aux.unshift({ start: missingStart, end: endIndex });
    }
    return forkJoin(
      aux.map((rangeOrData) => {
        if (Array.isArray(rangeOrData)) {
          return of(rangeOrData);
        } else {
          const startStr = TimeSeriesCache.dates[rangeOrData.end];
          const endStr = TimeSeriesCache.dates[rangeOrData.start];
          return this.loader(startStr, endStr).pipe(
            take(1),
            catchError((err) => {
              // on error, return an empty array, but do not cache the error result
              // let the cache as is on error
              // eslint-disable-next-line no-console
              console.error(`Error loading time series for data span: ${startStr}-${endStr}` + err);
              return of(null);
            }),
            map((data) => {
              if (!data) {
                return [];
              }
              for (let i = rangeOrData.start; i <= rangeOrData.end; i++) {
                this.cache[i] = null;
              }
              const res: V[] = [];
              for (const [date, d] of data.entries()) {
                this.cache[TimeSeriesCache.dateToIndex(date)] = d;
                res.push(d);
              }
              return res;
            }),
          );
        }
      }),
    ).pipe(map((data) => data.flat()));
  }
}
